var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "emergency-container" },
    [
      _vm.isShow
        ? _c(
            "base-dialog",
            { attrs: { title: "Emergency" }, on: { close: _vm.close } },
            [
              _c("a", {
                ref: "contact",
                style: { transform: "translate(10000px)" },
                attrs: {
                  href: _vm.isMobile
                    ? "callto:+972543929404"
                    : "mailto:support@geteat.co.il",
                  target: "_blank",
                },
              }),
              _vm.iconsMode
                ? _c(
                    "ul",
                    { staticClass: "emergency-icon-list" },
                    [
                      _vm._l(_vm.emergencyIcons, function (icon, i) {
                        return [
                          icon.visit
                            ? _c(
                                "li",
                                {
                                  key: i,
                                  class: { sos: icon.imgUrl.includes("sos") },
                                  on: { click: icon.callback },
                                },
                                [
                                  _c("img", {
                                    attrs: { src: icon.imgUrl, alt: "Icon" },
                                  }),
                                  icon.imgUrl.includes("sos")
                                    ? _c("countdown", {
                                        attrs: { miliseconds: _vm.rangeTime },
                                      })
                                    : _vm._e(),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(icon.label),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  )
                : _c(
                    "form",
                    {
                      staticClass: "popup grid-row",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.save.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "page__back",
                          style: {
                            display: "flex",
                            "margin-top": "-115px",
                            "margin-right": "-30px",
                            "margin-left": "-17px",
                          },
                          attrs: { type: "button" },
                          on: { click: _vm.toggleIconMode },
                        },
                        [
                          _c("svg-icon", {
                            attrs: {
                              name: "ic-close-table-arrow",
                              width: "34",
                              height: "34",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("checkbox-select", {
                        staticClass: "grid-col fill-mode checkbox-select",
                        attrs: {
                          options: _vm.modeOptions,
                          optionsSelected: [_vm.modeActive],
                        },
                        on: {
                          "set-active": function (val) {
                            return (_vm.modeActive = val)
                          },
                        },
                      }),
                      _c("checkbox-select", {
                        staticClass: "grid-col icon-mode checkbox-select",
                        attrs: {
                          options: _vm.typeOptions,
                          optionsSelected: _vm.optionsSelected,
                        },
                        on: { "set-active": _vm.setOptionsSelected },
                      }),
                      _c(
                        "section",
                        {
                          staticClass: "delay-mode-container",
                          class:
                            _vm.modeActive === "delay"
                              ? "grid-col"
                              : "flex a-md-center j-md-center",
                        },
                        [
                          _c("transition", { attrs: { name: "fade" } }, [
                            _vm.modeActive === "delay"
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "flex col a-center",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        _vm.numericKeyboardIsOpenAmount = true
                                      },
                                    },
                                  },
                                  [
                                    _c("h5", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Add extra")
                                        ),
                                      },
                                    }),
                                    _c(
                                      "section",
                                      { staticClass: "min-btn flex a-center" },
                                      [
                                        _c("span", {
                                          domProps: {
                                            textContent: _vm._s(_vm.$t("min")),
                                          },
                                        }),
                                        _c("div", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.change_amount
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c(
                            "div",
                            {
                              class: {
                                "t-md-center":
                                  _vm.modeActive === "close-emergency",
                              },
                            },
                            [
                              _c("h5", {
                                domProps: {
                                  textContent: _vm._s(_vm.$t("For how long")),
                                },
                              }),
                              _c(
                                "button",
                                {
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.numericKeyboardIsOpen = true
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "section",
                                    { staticClass: "min-btn flex a-center" },
                                    [
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(_vm.$t("min")),
                                        },
                                      }),
                                      _c("div", {
                                        domProps: {
                                          textContent: _vm._s(_vm.ForHowLong),
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _vm.modeActive === "close-emergency"
                                ? _c(
                                    "div",
                                    { staticClass: "branches-backups" },
                                    [
                                      _vm.branchesOptions.length > 1
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "sidebar-menu__list",
                                              style: {
                                                display: "block",
                                                width: "100%",
                                                "margin-top": "30px",
                                              },
                                            },
                                            _vm._l(_vm.zones, function (zone) {
                                              return _c(
                                                "div",
                                                { key: zone.id },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "backup_container",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "select__zone",
                                                        },
                                                        [
                                                          _c("span", {
                                                            staticClass:
                                                              "zone-name",
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    zone.name
                                                                  )
                                                                ),
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "select__branch",
                                                        },
                                                        [
                                                          _c("select-box", {
                                                            key: zone.id,
                                                            staticClass:
                                                              "select-Branch",
                                                            style: {
                                                              "margin-bottom":
                                                                "15px",
                                                            },
                                                            attrs: {
                                                              widthHeader:
                                                                "100%",
                                                              width: "100%",
                                                              maxHeight:
                                                                "300px",
                                                              placeholder:
                                                                _vm.$t(
                                                                  "select"
                                                                ),
                                                              unsort: "",
                                                              showSearch: "",
                                                              options:
                                                                _vm.branchesOptions,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.input(
                                                                  zone
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .backup_branch_ids[
                                                                  zone.id
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.backup_branch_ids,
                                                                    zone.id,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "backup_branch_ids[zone.id]",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }),
                                            0
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "section",
                        {
                          staticClass:
                            "submit-btn-wrap d-md-grid d-none grid-col",
                        },
                        [
                          _vm.isActive
                            ? _c(
                                "section",
                                {
                                  staticClass: "btn cancel-btn",
                                  on: { click: _vm.save },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Save")),
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm.isActive
                            ? _c("button", {
                                staticClass: "submit-btn",
                                class: { disabled: _vm.type === -1 },
                                attrs: {
                                  type: "button",
                                  disabled: _vm.type === -1,
                                },
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Stop")),
                                },
                                on: { click: _vm.stopEmergency },
                              })
                            : _vm._e(),
                          !_vm.isActive
                            ? _c("button", {
                                staticClass: "submit-btn",
                                class: { disabled: _vm.type === -1 },
                                attrs: {
                                  type: "submit",
                                  disabled: _vm.type === -1,
                                },
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Save")),
                                },
                              })
                            : _vm._e(),
                        ]
                      ),
                      _vm.isActive && _vm.mobile
                        ? _c(
                            "div",
                            {
                              style: {
                                display: "flex",
                                "justify-content": "end",
                              },
                            },
                            [
                              _c(
                                "button",
                                {
                                  style: { "margin-top": "-10px" },
                                  attrs: { type: "button" },
                                  on: { click: _vm.stopEmergency },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "ic-edit2",
                                      attrs: {
                                        width: "50",
                                        height: "50",
                                        viewBox: "0 0 32 32",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M23.0503 8.94932C22.6934 8.59242 22.121 8.59242 21.7641 8.94932L15.9998 14.7136L10.2355 8.94932C9.87861 8.59242 9.30622 8.59242 8.94932 8.94932C8.59242 9.30622 8.59242 9.87861 8.94932 10.2355L14.7136 15.9998L8.94932 21.7641C8.59242 22.121 8.59242 22.6934 8.94932 23.0503C9.1244 23.2254 9.36009 23.3197 9.58905 23.3197C9.818 23.3197 10.0537 23.2321 10.2288 23.0503L15.9931 17.286L21.7574 23.0503C21.9325 23.2254 22.1681 23.3197 22.3971 23.3197C22.6328 23.3197 22.8618 23.2321 23.0368 23.0503C23.3937 22.6934 23.3937 22.121 23.0368 21.7641L17.286 15.9998L23.0503 10.2355C23.4072 9.87861 23.4072 9.30622 23.0503 8.94932Z",
                                          fill: "#fff",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "submit-btn",
                                  attrs: { type: "submit" },
                                },
                                [
                                  _c("svg-icon", {
                                    staticClass: "ic-edit",
                                    attrs: {
                                      name: "ic-save-btn",
                                      width: "93",
                                      height: "93",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "new-item__btn d-flex d-md-none" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "save-btn",
                                  attrs: { type: "submit" },
                                },
                                [
                                  _c("svg-icon", {
                                    staticClass: "ic-edit",
                                    attrs: {
                                      name: "ic-save-btn",
                                      width: "93",
                                      height: "93",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
            ]
          )
        : _vm._e(),
      _vm.numericKeyboardIsOpen
        ? _c("numeric-keyboard", {
            on: {
              input: function ($event) {
                _vm.numericKeyboardIsOpen = false
              },
            },
            model: {
              value: _vm.ForHowLong,
              callback: function ($$v) {
                _vm.ForHowLong = $$v
              },
              expression: "ForHowLong",
            },
          })
        : _vm._e(),
      _vm.numericKeyboardIsOpenAmount
        ? _c("numeric-keyboard", {
            on: {
              input: function ($event) {
                _vm.numericKeyboardIsOpenAmount = false
              },
            },
            model: {
              value: _vm.change_amount,
              callback: function ($$v) {
                _vm.change_amount = $$v
              },
              expression: "change_amount",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }