var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.categoryItems.length
    ? _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isHideCategory,
              expression: "isHideCategory",
            },
          ],
          staticClass: "select-category-items",
        },
        [
          _c(
            "div",
            { staticClass: "coupon-buy-drop", class: { open: _vm.isOpen } },
            [
              _c("div", { staticClass: "coupon-buy-drop__head" }, [
                _c(
                  "div",
                  {
                    staticClass: "input-check flex",
                    on: {
                      click: function ($event) {
                        _vm.isOpen = !_vm.isOpen
                      },
                    },
                  },
                  [
                    !_vm.uniItem
                      ? _c(
                          "label",
                          { staticClass: "input-check__label" },
                          [
                            _c("checkbox", {
                              attrs: {
                                disabled: _vm.disabled,
                                inline: "",
                                partial: _vm.someItemsSelectedFromThisCategory,
                                name:
                                  "" +
                                  (_vm.isTag ? _vm.tag.id : _vm.category.id),
                              },
                              on: { input: _vm.mainCheckboxInput },
                              model: {
                                value: _vm.mainCheckbox,
                                callback: function ($$v) {
                                  _vm.mainCheckbox = $$v
                                },
                                expression: "mainCheckbox",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "input-check__title" }, [
                      _vm.icon
                        ? _c("div", {
                            staticClass: "input-check__img icon",
                            style: "background-image: url(" + _vm.icon + ")",
                          })
                        : _vm._e(),
                      _c("div", { staticClass: "input-check__text" }, [
                        _vm.isTag
                          ? _c("div", { staticClass: "title-category" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    (_vm.he ||
                                    !_vm.tag.name_en ||
                                    _vm.tag.name_en === "undefined"
                                      ? _vm.tag.name
                                      : _vm.tag.name_en) +
                                      " (" +
                                      _vm.$t("Tag") +
                                      ")"
                                  ) +
                                  "\n              "
                              ),
                              _c("small", [
                                _vm._v("(" + _vm._s(_vm.tag.id) + ")"),
                              ]),
                            ])
                          : _c("div", { staticClass: "title-category" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.he ||
                                      !_vm.category.name_en ||
                                      _vm.category.name_en === "undefined"
                                      ? _vm.category.name
                                      : _vm.category.name_en
                                  ) +
                                  "\n              "
                              ),
                              _c("small", [
                                _vm._v("(" + _vm._s(_vm.category.id) + ")"),
                              ]),
                            ]),
                        !_vm.categoryonly
                          ? _c("div", { staticClass: "selected" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.selectedItemsFromThisCategory) +
                                  "/" +
                                  _vm._s(_vm.categoryItems.length) +
                                  " " +
                                  _vm._s(_vm.$t("selected")) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                ),
                !_vm.categoryonly
                  ? _c(
                      "button",
                      {
                        staticClass: "coupon-buy-drop__arr",
                        on: {
                          click: function ($event) {
                            _vm.isOpen = !_vm.isOpen
                          },
                        },
                      },
                      [
                        _c("svg-icon", {
                          attrs: {
                            name: "ic-arrow-down",
                            width: "14",
                            height: "10",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              !_vm.categoryonly
                ? _c("div", { staticClass: "coupon-buy-drop__content" }, [
                    _c(
                      "div",
                      { staticClass: "coupon-buy-drop__list" },
                      _vm._l(_vm.categoryItems, function (item) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.hideItem(item),
                                expression: "hideItem(item)",
                              },
                            ],
                            key: item.id,
                            staticClass: "input-check",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "input-check__label",
                                on: {
                                  click: function ($event) {
                                    return _vm.selectItem(item)
                                  },
                                },
                              },
                              [
                                !_vm.uniItem
                                  ? _c("checkbox", {
                                      attrs: {
                                        disabled: _vm.disabled,
                                        inline: "",
                                        value: _vm.getValItem(item.id),
                                        name: "" + item.id,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.setValItem($event, item.id)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _c(
                                  "span",
                                  { staticClass: "input-check__text" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.he ||
                                          !item.name_en ||
                                          item.name_en === "undefined"
                                          ? item.name
                                          : item.name_en
                                      ) +
                                        _vm._s(
                                          item.makat
                                            ? "(" + item.makat + ")"
                                            : ""
                                        )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }