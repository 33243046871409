var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    { staticClass: "edit-business-client-member", on: { close: _vm.close } },
    [
      _c("div", { staticClass: "popup__content" }, [
        _c("div", { staticClass: "form-new-user" }, [
          _c(
            "form",
            {
              staticClass: "form-site-wrap",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.save.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "form-site" }, [
                _c("div", { staticClass: "form-site__content" }, [
                  _c(
                    "div",
                    { staticClass: "form-site__field-wrap" },
                    [
                      _c("form-site-field-text", {
                        attrs: { title: "Name" },
                        model: {
                          value: _vm.memberCopy.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.memberCopy, "name", $$v)
                          },
                          expression: "memberCopy.name",
                        },
                      }),
                      _c("form-site-field-text", {
                        attrs: { title: "Phone number" },
                        model: {
                          value: _vm.memberCopy.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.memberCopy, "phone", $$v)
                          },
                          expression: "memberCopy.phone",
                        },
                      }),
                      _c("div", { staticClass: "form-site__field" }, [
                        _c("div", {
                          staticClass: "form-site__label",
                          domProps: {
                            textContent: _vm._s(_vm.$t("Credit Type")),
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "form-site__input mt20 flex bet" },
                          [
                            _c("div", { staticClass: "input-radio" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.memberCopy.type,
                                    expression: "memberCopy.type",
                                  },
                                ],
                                attrs: {
                                  id: "radio1",
                                  type: "radio",
                                  name: "radio",
                                },
                                domProps: {
                                  value: 0,
                                  checked: _vm._q(_vm.memberCopy.type, 0),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(_vm.memberCopy, "type", 0)
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "input-radio__label",
                                  attrs: { for: "radio1" },
                                },
                                [
                                  _c("span", {
                                    staticClass: "input-radio__mark",
                                  }),
                                  _c("span", {
                                    staticClass: "input-radio__text",
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Daily")),
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "input-radio" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.memberCopy.type,
                                    expression: "memberCopy.type",
                                  },
                                ],
                                attrs: {
                                  id: "radio2",
                                  type: "radio",
                                  name: "radio",
                                },
                                domProps: {
                                  value: 1,
                                  checked: _vm._q(_vm.memberCopy.type, 1),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(_vm.memberCopy, "type", 1)
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "input-radio__label",
                                  attrs: { for: "radio2" },
                                },
                                [
                                  _c("span", {
                                    staticClass: "input-radio__mark",
                                  }),
                                  _c("span", {
                                    staticClass: "input-radio__text",
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Monthly")),
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _vm.restaurantCurrencyInfo == null
                        ? _c("form-site-field-text", {
                            attrs: {
                              placeholder: "0",
                              type: "number",
                              title: "Credit amount",
                              sign: "₪",
                            },
                            model: {
                              value: _vm.memberCopy.credit,
                              callback: function ($$v) {
                                _vm.$set(_vm.memberCopy, "credit", $$v)
                              },
                              expression: "memberCopy.credit",
                            },
                          })
                        : _vm._e(),
                      _vm.restaurantCurrencyInfo !== null
                        ? _c("form-site-field-text", {
                            attrs: {
                              placeholder: "0",
                              type: "number",
                              title: "Credit amount",
                              sign: _vm.restaurantCurrencySymbol,
                            },
                            model: {
                              value: _vm.memberCopy.credit,
                              callback: function ($$v) {
                                _vm.$set(_vm.memberCopy, "credit", $$v)
                              },
                              expression: "memberCopy.credit",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "form-site__btn md-none" },
                [
                  _vm.memberCopy.id
                    ? _c("remove-btn", { on: { click: _vm.remove } })
                    : _c("div", {}),
                  _c("button", {
                    staticClass: "btn",
                    attrs: { type: "submit" },
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(_vm.memberCopy.id ? "Save" : "Add")
                      ),
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
                _c(
                  "button",
                  { staticClass: "save-btn", attrs: { type: "submit" } },
                  [
                    _c("svg-icon", {
                      staticClass: "ic-edit",
                      attrs: { name: "ic-save-btn", width: "93", height: "93" },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }