<template>
  <base-dialog
    v-if="opened"
    @close="close"
    :title="$t('Produce delivery note')"
    width="550px"
  >
    <div class="popup__content pb40">
      <div class="selected-delivery-notes-summery">
        <div class="total-count">
          {{ $t("Selected delivery notes") }}: {{ totalCount }}
        </div>
        <div class="payment-sum">{{ $t("Payment sum") }}: {{ paymentSum }}₪</div>
        <template v-if="discountPercent > 0">
          <div class="discount-amount">
            {{ $t("Discount amount") }}: {{ discountAmount }}₪
          </div>
          <div class="total-amount">{{ $t("Total amount") }}: {{ totalAmount }}₪</div>
        </template>
      </div>
      <div class="form-site">
        <div class="form-site__label d-md-block d-none" v-text="$t('Payment methods')" />

        <div class="form-site__field invoice">
          <div
            class="coupon-buy__list coupon-buy__list_full"
            :style="{ 'justify-content': 'space-around', width: '100%' }"
          >
            <div class="input-radio">
              <input
                type="radio"
                name="buyradio"
                id="radio210"
                :value="1"
                v-model="paymentMethod"
              />
              <label class="input-radio__label" for="radio210"
                ><span class="input-radio__mark"></span
                ><span class="input-radio__text" v-text="$t('Credit')"
              /></label>
            </div>
            <div class="input-radio">
              <input
                type="radio"
                name="buyradio"
                id="radio2101"
                :value="2"
                v-model="paymentMethod"
              />
              <label class="input-radio__label" for="radio2101"
                ><span class="input-radio__mark"></span
                ><span class="input-radio__text" v-text="$t('Cash')"
              /></label>
            </div>
            <div class="input-radio">
              <input
                type="radio"
                name="buyradio"
                id="radio211"
                :value="3"
                v-model="paymentMethod"
              />
              <label class="input-radio__label" for="radio211"
                ><span class="input-radio__mark"></span
                ><span class="input-radio__text" v-text="$t('Cheque')"
              /></label>
            </div>
            <div class="input-radio">
              <input
                type="radio"
                name="buyradio"
                id="radio212"
                :value="4"
                v-model="paymentMethod"
              />
              <label class="input-radio__label" for="radio212"
                ><span class="input-radio__mark"></span
                ><span class="input-radio__text" v-text="$t('Bank transfer')"
              /></label>
            </div>
          </div>
        </div>
        <div>
          <div class="form-site__field" v-if="paymentMethod == 1">
            <div
              class="form-site__label d-md-block"
              v-text="$t('4 last digits of credit card')"
            />
            <div class="form-site__input">
              <input type="number" min="0" max="100" step="1" v-model="lastDigits" />
            </div>
          </div>

          <div class="form-site__field" v-if="paymentMethod == 3">
            <div class="form-site__label d-md-block" v-text="$t('Number of cheque')" />
            <div class="form-site__input">
              <input type="number" min="0" max="100" step="1" v-model="chequeNumber" />
            </div>
          </div>
          <div class="form-site__field">
            <div class="form-site__label d-md-block" v-text="$t('Discount Percent')" />
            <div class="form-site__input">
              <input type="number" min="0" max="100" step="1" v-model="discountPercent" />
            </div>
          </div>
        </div>
      </div>
      <div class="form-site__btn flex j-center">
        <button @click="pay" class="btn" type="button">
          <span v-text="$t('Pay')" />
        </button>
      </div>
    </div>
  </base-dialog>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import BaseDialog from "@/dialogs/base-dialog.vue";
import checkbox from "@/components/checkbox";
export default defineComponent({
  name: "delivery-notes-payment",
  components: { BaseDialog, checkbox },
  props: {
    deliveryNotes: {
      required: false,
      default: [],
    },
    show: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show(newVal) {
      this.opened = newVal;
    },
  },
  computed: {
    totalCount() {
      if (this.deliveryNotes && this.deliveryNotes.length > 0) {
        return this.deliveryNotes.length;
      }
      return 0;
    },
    mobile() {
      return document.body.offsetWidth < 767;
    },
    paymentSum() {
      if (this.deliveryNotes && this.deliveryNotes.length > 0) {
        return this.deliveryNotes.reduce((sum, note) => sum + note.amount, 0).toFixed(2);
      }
      return 0;
    },
    discountAmount() {
      if (this.discountPercent > 0) {
        return (this.paymentSum * (this.discountPercent / 100)).toFixed(2);
      }
      return 0;
    },
    totalAmount() {
      return (this.paymentSum - this.discountAmount).toFixed(2);
    },
  },
  data() {
    return {
      paymentMethod: 0,
      lastDigits: 0,
      opened: false,
      discountPercent: 0,
      chequeNumber: 0,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    pay() {
      this.$emit(
        "pay",
        { discountPercent: this.discountPercent },
        { paymentMethod: this.paymentMethod },
        { lastDigits: this.lastDigits },
        { chequeNumber: this.chequeNumber }
      );
    },
  },
});
</script>
<style lang="scss">
.invoice {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.selected-delivery-notes-summery {
  text-align: center;
  font-size: 1.3rem;
  margin-bottom: 25px;
}
</style>
<style scoped>
@media (max-width: 767px) {
  .invoice {
    display: grid;
    justify-content: space-around;
  }
}
</style>
