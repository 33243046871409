var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    { attrs: { width: "840px", title: "Add Role" }, on: { close: _vm.close } },
    [
      _c("div", { staticClass: "popup__content" }, [
        _c("div", { staticClass: "form-new-user" }, [
          _c(
            "form",
            {
              staticClass: "edit-permissions-wrap",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.save.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "edit-permissions" },
                [
                  _c("div", { staticClass: "edit-permissions__top" }, [
                    _c(
                      "div",
                      { staticClass: "edit-permissions__field" },
                      [
                        _c("div", {
                          staticClass: "edit-permissions__label",
                          domProps: { textContent: _vm._s(_vm.$t("Role")) },
                        }),
                        _c("select-box", {
                          attrs: {
                            viewId: "",
                            searchByValue: "",
                            showVal: "",
                            options: _vm.policiesOptions,
                            showSearch: true,
                            unsort: "",
                          },
                          on: { input: _vm.policySelected },
                          model: {
                            value: _vm.policyId,
                            callback: function ($$v) {
                              _vm.policyId = $$v
                            },
                            expression: "policyId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm.branchesOptions && _vm.branchesOptions.length
                    ? _c("div", { staticClass: "edit-permissions__top" }, [
                        _c(
                          "div",
                          { staticClass: "edit-permissions__field" },
                          [
                            _c("div", {
                              staticClass: "edit-permissions__label",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Branch")),
                              },
                            }),
                            _c("select-box", {
                              attrs: {
                                showVal: "",
                                searchByValue: "",
                                firstTop: "",
                                options: _vm.branchesOptions,
                                showSearch: true,
                              },
                              model: {
                                value: _vm.branchId,
                                callback: function ($$v) {
                                  _vm.branchId = $$v
                                },
                                expression: "branchId",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.showCheckboxes
                    ? [
                        _vm._l(_vm.keywords, function (keyword) {
                          return [
                            _c("policy-checkboxes", {
                              attrs: {
                                policy: _vm.policyOverride,
                                "original-policy": _vm.originalPolicy,
                                reports: _vm.reports,
                                keyword: keyword,
                              },
                              on: { "change-policy": _vm.changePolicy },
                            }),
                          ]
                        }),
                      ]
                    : _c("div", [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "User policy is empty, change policy or set permissions for the default policy"
                              )
                            )
                          ),
                        ]),
                      ]),
                ],
                2
              ),
              _c("div", { staticClass: "edit-permissions__btn" }, [
                _c(
                  "button",
                  { staticClass: "btn", attrs: { type: "submit" } },
                  [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.$t("Save")) },
                    }),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }