var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stock-management" },
    [
      _c(
        "table-list",
        _vm._b(
          {
            ref: "stockTable",
            on: {
              "on-click-line": _vm.openPopup,
              changeOptions: _vm.changeOptions,
            },
            scopedSlots: _vm._u(
              [
                _vm.mobile
                  ? {
                      key: "above-tabel",
                      fn: function () {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "mobile-btn-plus",
                              on: { click: _vm.addToStock },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-plus",
                                  width: "16",
                                  height: "16",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : {
                      key: "btn",
                      fn: function () {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn_pink",
                              on: { click: _vm.addToStock },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-plus",
                                  width: "16",
                                  height: "16",
                                },
                              }),
                              _c("span", {
                                staticClass: "d-md-block d-none",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Add To Stock")),
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
              ],
              null,
              true
            ),
          },
          "table-list",
          _vm.dataTable,
          false
        )
      ),
      _vm.add ? _c("add-stock", { on: { close: _vm.closeAndLoad } }) : _vm._e(),
      _vm.popup && _vm.stockInEdit
        ? _c("edit-stock", {
            attrs: { stockInEdit: _vm.stockInEdit },
            on: { close: _vm.closePopup, load: _vm.closeAndLoad },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }