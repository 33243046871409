var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "address-to-span" }, [
    _vm.address.street_name
      ? _c("span", { staticClass: "mie8" }, [
          _vm._v(_vm._s(_vm.address.street_name) + ","),
        ])
      : _vm._e(),
    _vm.address.number
      ? _c("span", {
          domProps: {
            textContent: _vm._s(
              _vm.$t("home number") + ": " + _vm.address.number
            ),
          },
        })
      : _vm._e(),
    _vm.address.number && _vm.address.apartment
      ? _c("span", { staticClass: "mie8" })
      : _vm._e(),
    _vm.address.apartment
      ? _c("span", [
          _vm._v(
            _vm._s(_vm.$t("apartment number") + ": " + _vm.address.apartment)
          ),
        ])
      : _vm._e(),
    _vm.address.apartment && _vm.address.entrance
      ? _c("span", { staticClass: "mie8" })
      : _vm._e(),
    _vm.address.entrance
      ? _c("span", [
          _vm._v(
            _vm._s(_vm.$t("entrance number") + ": " + _vm.address.entrance)
          ),
        ])
      : _vm._e(),
    _vm.address.city_name
      ? _c("span", [_vm._v(", " + _vm._s(_vm.address.city_name) + "  ")])
      : _vm._e(),
    _vm.address.name
      ? _c("span", [_vm._v("(" + _vm._s(_vm.address.name) + ")")])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }