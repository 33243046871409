var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-dialog-tabs", {
    staticClass: "edit-combination-add-item",
    attrs: { width: "700px", tabList: ["Items", "Tags"] },
    on: { close: _vm.close },
    scopedSlots: _vm._u([
      {
        key: "tab0",
        fn: function () {
          return [
            _c("div", { staticClass: "popup-top" }, [
              _c("div", {
                staticClass: "h1",
                domProps: { textContent: _vm._s(_vm.$t("Add items")) },
              }),
            ]),
            _c(
              "div",
              { staticClass: "popup-main" },
              [
                _c("select-category-items-list", {
                  attrs: {
                    tags: _vm.tagsCopy,
                    includeTags: "",
                    valueCategories: _vm.categories,
                    combination: "",
                  },
                  model: {
                    value: _vm.itemsCopy,
                    callback: function ($$v) {
                      _vm.itemsCopy = $$v
                    },
                    expression: "itemsCopy",
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "tab1",
        fn: function () {
          return [
            _c("div", { staticClass: "popup-top" }, [
              _c("div", {
                staticClass: "h1",
                domProps: { textContent: _vm._s(_vm.$t("Step Tags")) },
              }),
            ]),
            _c(
              "div",
              { staticClass: "popup-main" },
              [
                _c("filter-by", {
                  attrs: { filterBy: _vm.filter },
                  on: {
                    emitFilter: function (e) {
                      return (_vm.filter = e)
                    },
                  },
                }),
                _vm._l(_vm.allTagsWithItemsFilter, function (tag) {
                  return _c(
                    "div",
                    {
                      key: tag.tag.id,
                      staticClass: "coupon-buy-drop",
                      class: { open: tag.isOpen },
                    },
                    [
                      _c("div", { staticClass: "coupon-buy-drop__head" }, [
                        _c(
                          "div",
                          {
                            staticClass: "input-check flex",
                            on: {
                              click: function ($event) {
                                tag.isOpen = !tag.isOpen
                              },
                            },
                          },
                          [
                            _c(
                              "label",
                              { staticClass: "input-check__label" },
                              [
                                _c("checkbox", {
                                  attrs: {
                                    value: _vm.tagsCopy.includes(tag.tag.id),
                                    inline: "",
                                    name: "" + tag.tag.id,
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.checkTag($event, tag.tag.id)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "input-check__title" }, [
                              _c(
                                "div",
                                { staticClass: "input-check__text flex" },
                                [
                                  _c("div", {
                                    staticClass: "title-category",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.he ||
                                          !tag.tag.name_en ||
                                          tag.tag.name_en === "undefined"
                                          ? tag.tag.name
                                          : tag.tag.name_en
                                      ),
                                    },
                                  }),
                                  _c("div", {
                                    staticClass: "selected",
                                    domProps: {
                                      textContent: _vm._s(
                                        "(" + tag.items.length + ")"
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "coupon-buy-drop__arr",
                            on: {
                              click: function ($event) {
                                tag.isOpen = !tag.isOpen
                              },
                            },
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-arrow-down",
                                width: "14",
                                height: "10",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "coupon-buy-drop__content" }, [
                        _c(
                          "div",
                          { staticClass: "coupon-buy-drop__list" },
                          _vm._l(tag.items, function (extra) {
                            return _c(
                              "div",
                              { key: extra.id, staticClass: "input-check" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "input-check__label" },
                                  [
                                    _c("span", {
                                      staticClass: "input-check__text",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.he ||
                                            !extra.name_en ||
                                            extra.name_en === "undefined"
                                            ? extra.name
                                            : extra.name_en +
                                                (extra.makat
                                                  ? "(" + extra.makat + ")"
                                                  : "")
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  )
                }),
              ],
              2
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "btns",
        fn: function () {
          return [
            _c("div", { staticClass: "btn-container" }, [
              _c("button", {
                staticClass: "btn",
                domProps: { textContent: _vm._s(_vm.$t("Add")) },
                on: { click: _vm.selectItems },
              }),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }