var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "create-survey-dialog" },
    [
      _c(
        "base-dialog",
        { attrs: { width: "1017px" }, on: { close: _vm.close } },
        [
          _c("div", { staticClass: "popup__top" }, [
            _c("div", { staticClass: "popup__head" }, [
              _c("div", { staticClass: "survey-top" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editSurvey.title,
                      expression: "editSurvey.title",
                    },
                  ],
                  ref: "surveyTitle",
                  attrs: { placeholder: _vm.$t("Survey Title"), type: "text" },
                  domProps: { value: _vm.editSurvey.title },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.editSurvey, "title", $event.target.value)
                    },
                  },
                }),
                _c(
                  "button",
                  {
                    staticClass: "survey-top__preview md-none",
                    on: {
                      click: function ($event) {
                        _vm.preview = true
                      },
                    },
                  },
                  [
                    _c("svg-icon", {
                      attrs: { name: "orange-eye", width: "20", height: "12" },
                    }),
                    _c("span", {
                      staticClass: "mis12",
                      domProps: { textContent: _vm._s(_vm.$t("Preview")) },
                    }),
                  ],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass: "survey-top__preview share",
                    on: { click: _vm.copyPreviewLink },
                  },
                  [
                    _c("svg-icon", {
                      staticClass: "shadow",
                      attrs: { name: "ic-share", width: "24", height: "24" },
                    }),
                    _c("span", {
                      staticClass: "mis12",
                      domProps: { textContent: _vm._s(_vm.$t("Copy link")) },
                    }),
                  ],
                  1
                ),
                _c("div"),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "popup__content" }, [
            _c("div", { staticClass: "choose-steps" }, [
              _c(
                "div",
                { staticClass: "num-step" },
                _vm._l(4, function (i) {
                  return _c("div", {
                    key: i,
                    staticClass: "num-step__item",
                    class: { active: _vm.step === i },
                    domProps: { textContent: _vm._s(i) },
                  })
                }),
                0
              ),
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _vm.step === 1
                    ? _c(
                        "div",
                        {},
                        [
                          _c("div", {
                            staticClass: "h5",
                            domProps: {
                              textContent: _vm._s(_vm.$t("General")),
                            },
                          }),
                          _c("div", { staticClass: "survey-general" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "survey-general__description form-site",
                              },
                              [
                                _c("div", {
                                  staticClass: "survey-general__title",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("Description (optional)")
                                    ),
                                  },
                                }),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.editSurvey.description,
                                      expression: "editSurvey.description",
                                    },
                                  ],
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "Write your description"
                                    ),
                                  },
                                  domProps: {
                                    value: _vm.editSurvey.description,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.editSurvey,
                                        "description",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "survey-general__image" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "survey-general__description",
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "survey-general__title",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Image (optional)")
                                        ),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "coupon-info__img",
                                        class: {
                                          "coupon-info__empty": !_vm.image,
                                        },
                                        style:
                                          "background-image: url(" +
                                          _vm.image +
                                          ")",
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "coupon-info__img-change",
                                          },
                                          [
                                            _vm.image
                                              ? _c(
                                                  "button",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.clearImage()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      attrs: {
                                                        name: "ic-plus",
                                                        width: "22",
                                                        height: "22",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : [
                                                  _c("input", {
                                                    attrs: {
                                                      type: "file",
                                                      accept: "image/*",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.enterImage(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      name: "ic-plus",
                                                      width: "22",
                                                      height: "22",
                                                    },
                                                  }),
                                                ],
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "survey-general__start form-site",
                              },
                              [
                                _c("div", {
                                  staticClass: "survey-general__title",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("Text to start")
                                    ),
                                  },
                                }),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.editSurvey.start_text,
                                      expression: "editSurvey.start_text",
                                    },
                                  ],
                                  attrs: {
                                    placeholder: _vm.$t("Write your text"),
                                  },
                                  domProps: {
                                    value: _vm.editSurvey.start_text,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.editSurvey,
                                        "start_text",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "survey-general__end form-site" },
                              [
                                _c("div", {
                                  staticClass: "survey-general__title",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Text to end")),
                                  },
                                }),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.editSurvey.end_text,
                                      expression: "editSurvey.end_text",
                                    },
                                  ],
                                  attrs: {
                                    placeholder: _vm.$t("Write your text"),
                                  },
                                  domProps: { value: _vm.editSurvey.end_text },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.editSurvey,
                                        "end_text",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _c("div", {
                            staticClass: "h5",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Questions")),
                            },
                          }),
                          _c("div", { staticClass: "test" }),
                          _c(
                            "draggable",
                            _vm._b(
                              {
                                staticClass: "question-list",
                                model: {
                                  value: _vm.editSurvey.questions,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.editSurvey, "questions", $$v)
                                  },
                                  expression: "editSurvey.questions",
                                },
                              },
                              "draggable",
                              _vm.darggableQuestionBind,
                              false
                            ),
                            _vm._l(
                              _vm.editSurvey.questions,
                              function (question, i) {
                                return _c(
                                  "div",
                                  {
                                    key: i,
                                    staticClass: "question",
                                    class: { active: i === _vm.openedQuestion },
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "question__open-btn",
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeOpenedQuestion(i)
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          class: {
                                            active: i === _vm.openedQuestion,
                                          },
                                          attrs: {
                                            name: "ic-arrow-down",
                                            width: "18",
                                            height: "10",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "form-site" }, [
                                      _c(
                                        "div",
                                        { staticClass: "question__main" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "form-site__content",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-site__group",
                                                },
                                                [
                                                  _c("form-site-field-text", {
                                                    attrs: {
                                                      title:
                                                        _vm.$t("Question") +
                                                        " " +
                                                        (i + 1),
                                                    },
                                                    model: {
                                                      value: question.question,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          question,
                                                          "question",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "question.question",
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "members-club__field radio mt0",
                                                    },
                                                    [
                                                      _c("div", {
                                                        staticClass:
                                                          "form-site__label mb16",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t("Type")
                                                          ),
                                                        },
                                                      }),
                                                      _c(
                                                        "form",
                                                        {
                                                          staticClass:
                                                            "members-club__radio m0",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input-radio",
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      question.type,
                                                                    expression:
                                                                      "question.type",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.unEdit,
                                                                  id:
                                                                    "radioQ" +
                                                                    i +
                                                                    "2",
                                                                  type: "radio",
                                                                  name: "radio",
                                                                },
                                                                domProps: {
                                                                  value: 1,
                                                                  checked:
                                                                    _vm._q(
                                                                      question.type,
                                                                      1
                                                                    ),
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$set(
                                                                        question,
                                                                        "type",
                                                                        1
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "input-radio__label",
                                                                  attrs: {
                                                                    for:
                                                                      "radioQ" +
                                                                      i +
                                                                      "2",
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "input-radio__mark",
                                                                  }),
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "input-radio__text",
                                                                    domProps: {
                                                                      textContent:
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "Rate"
                                                                          )
                                                                        ),
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input-radio",
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      question.type,
                                                                    expression:
                                                                      "question.type",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.unEdit,
                                                                  id:
                                                                    "radioQ" +
                                                                    i +
                                                                    "1",
                                                                  type: "radio",
                                                                  name: "radio",
                                                                },
                                                                domProps: {
                                                                  value: 0,
                                                                  checked:
                                                                    _vm._q(
                                                                      question.type,
                                                                      0
                                                                    ),
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$set(
                                                                        question,
                                                                        "type",
                                                                        0
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "input-radio__label",
                                                                  attrs: {
                                                                    for:
                                                                      "radioQ" +
                                                                      i +
                                                                      "1",
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "input-radio__mark",
                                                                  }),
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "input-radio__text",
                                                                    domProps: {
                                                                      textContent:
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "Answers"
                                                                          )
                                                                        ),
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input-radio",
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      question.type,
                                                                    expression:
                                                                      "question.type",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.unEdit,
                                                                  id:
                                                                    "radioQ" +
                                                                    i +
                                                                    "3",
                                                                  type: "radio",
                                                                  name: "radio",
                                                                },
                                                                domProps: {
                                                                  value: 2,
                                                                  checked:
                                                                    _vm._q(
                                                                      question.type,
                                                                      2
                                                                    ),
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$set(
                                                                        question,
                                                                        "type",
                                                                        2
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "input-radio__label",
                                                                  attrs: {
                                                                    for:
                                                                      "radioQ" +
                                                                      i +
                                                                      "3",
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "input-radio__mark",
                                                                  }),
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "input-radio__text",
                                                                    domProps: {
                                                                      textContent:
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "Open question"
                                                                          )
                                                                        ),
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              i === _vm.openedQuestion
                                                ? [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-site__group",
                                                      },
                                                      [
                                                        _c(
                                                          "form-site-field-select-box",
                                                          {
                                                            attrs: {
                                                              maxWidth: "100%",
                                                              isDisabled:
                                                                _vm.unEdit,
                                                              title: "Segment",
                                                              options:
                                                                _vm.segmentOpts,
                                                            },
                                                            model: {
                                                              value:
                                                                question.segment_id,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    question,
                                                                    "segment_id",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "question.segment_id",
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              question.type ===
                                                              0,
                                                            expression:
                                                              "question.type === 0",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "question__answer-list",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "question__answer-list_title",
                                                          },
                                                          [
                                                            _c("div", {
                                                              staticClass:
                                                                "question__answer-list_title-text",
                                                              domProps: {
                                                                textContent:
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "Answers"
                                                                    )
                                                                  ),
                                                              },
                                                            }),
                                                            _c("div", {
                                                              staticClass:
                                                                "question__answer-list_title-percent",
                                                              domProps: {
                                                                textContent:
                                                                  _vm._s("%"),
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _c(
                                                          "draggable",
                                                          {
                                                            attrs: {
                                                              handle:
                                                                ".draggable-handle2",
                                                            },
                                                            model: {
                                                              value:
                                                                question.answers,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    question,
                                                                    "answers",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "question.answers",
                                                            },
                                                          },
                                                          _vm._l(
                                                            question.answers,
                                                            function (
                                                              answer,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: index,
                                                                  staticClass:
                                                                    "question__answer-list_answer",
                                                                },
                                                                [
                                                                  !_vm.unEdit
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "draggable-handle2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "svg",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  width:
                                                                                    "24",
                                                                                  height:
                                                                                    "24",
                                                                                  viewBox:
                                                                                    "0 0 24 24",
                                                                                  fill: "none",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "circle",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      cx: "12",
                                                                                      cy: "12",
                                                                                      r: "11",
                                                                                      fill: "white",
                                                                                      stroke:
                                                                                        "#E9E9E9",
                                                                                      "stroke-width":
                                                                                        "2",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "form",
                                                                    {
                                                                      staticClass:
                                                                        "question__answer-list_answer-text",
                                                                      on: {
                                                                        submit:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            $event.preventDefault()
                                                                            return _vm.addAnswer.apply(
                                                                              null,
                                                                              arguments
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  answer.text,
                                                                                expression:
                                                                                  "answer.text",
                                                                              },
                                                                            ],
                                                                          ref: "answerText",
                                                                          refInFor: true,
                                                                          attrs:
                                                                            {
                                                                              tabindex:
                                                                                "1",
                                                                              placeholder:
                                                                                _vm.$t(
                                                                                  "Variant"
                                                                                ) +
                                                                                " " +
                                                                                (index +
                                                                                  1),
                                                                              type: "text",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                answer.text,
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  $event
                                                                                    .target
                                                                                    .composing
                                                                                ) {
                                                                                  return
                                                                                }
                                                                                _vm.$set(
                                                                                  answer,
                                                                                  "text",
                                                                                  $event
                                                                                    .target
                                                                                    .value
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "button",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              hidden:
                                                                                "",
                                                                            },
                                                                        }
                                                                      ),
                                                                      !_vm.unEdit
                                                                        ? _c(
                                                                            "button",
                                                                            {
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    $event.preventDefault()
                                                                                    return _vm.removeAnswer(
                                                                                      index
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "svg",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      width:
                                                                                        "14",
                                                                                      height:
                                                                                        "14",
                                                                                      viewBox:
                                                                                        "0 0 14 14",
                                                                                      fill: "none",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "path",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          d: "M13.7424 0.256009C13.401 -0.0853364 12.8536 -0.0853364 12.5122 0.256009L6.99919 5.76906L1.48614 0.256009C1.1448 -0.0853364 0.597354 -0.0853364 0.256009 0.256009C-0.0853363 0.597355 -0.0853363 1.1448 0.256009 1.48614L5.76906 6.9992L0.256009 12.5122C-0.0853363 12.8536 -0.0853363 13.401 0.256009 13.7424C0.423461 13.9098 0.648878 14 0.867854 14C1.08683 14 1.31225 13.9163 1.4797 13.7424L6.99275 8.22933L12.5058 13.7424C12.6732 13.9098 12.8987 14 13.1176 14C13.3431 14 13.562 13.9163 13.7295 13.7424C14.0708 13.401 14.0708 12.8536 13.7295 12.5122L8.22932 6.9992L13.7424 1.48614C14.0837 1.1448 14.0837 0.597355 13.7424 0.256009Z",
                                                                                          fill: "#666666",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  ),
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            answer.weight,
                                                                          expression:
                                                                            "answer.weight",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "question__answer-list_answer-percent",
                                                                    attrs: {
                                                                      tabindex:
                                                                        "2",
                                                                      disabled:
                                                                        _vm.unEdit,
                                                                      type: "text",
                                                                    },
                                                                    domProps: {
                                                                      value:
                                                                        answer.weight,
                                                                    },
                                                                    on: {
                                                                      input:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            $event
                                                                              .target
                                                                              .composing
                                                                          ) {
                                                                            return
                                                                          }
                                                                          _vm.$set(
                                                                            answer,
                                                                            "weight",
                                                                            $event
                                                                              .target
                                                                              .value
                                                                          )
                                                                        },
                                                                    },
                                                                  }),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                        !_vm.unEdit
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "question__answer-list_add",
                                                              },
                                                              [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    on: {
                                                                      click:
                                                                        _vm.addAnswer,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "svg",
                                                                      {
                                                                        attrs: {
                                                                          width:
                                                                            "32",
                                                                          height:
                                                                            "32",
                                                                          viewBox:
                                                                            "0 0 32 32",
                                                                          fill: "none",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "circle",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cx: "16",
                                                                                cy: "16",
                                                                                r: "16",
                                                                                fill: "#F6F6F6",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "rect",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                x: "15.25",
                                                                                y: "10",
                                                                                width:
                                                                                  "1.5",
                                                                                height:
                                                                                  "12",
                                                                                rx: "0.75",
                                                                                fill: "#ACACAC",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "rect",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                x: "10",
                                                                                y: "16.75",
                                                                                width:
                                                                                  "1.5",
                                                                                height:
                                                                                  "12",
                                                                                rx: "0.75",
                                                                                transform:
                                                                                  "rotate(-90 10 16.75)",
                                                                                fill: "#ACACAC",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c("span", {
                                                                      staticClass:
                                                                        "mis8",
                                                                      domProps:
                                                                        {
                                                                          textContent:
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "Add answer"
                                                                              )
                                                                            ),
                                                                        },
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                          !_vm.unEdit
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "draggable-handle",
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      name: "ic-double-more",
                                                      width: "8",
                                                      height: "14",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: i === _vm.openedQuestion,
                                              expression:
                                                "i === openedQuestion",
                                            },
                                          ],
                                          staticClass: "question__btm",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "question__btm_required",
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "mie12",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t("Required")
                                                  ),
                                                },
                                              }),
                                              _c("swich-button", {
                                                model: {
                                                  value: question.required,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      question,
                                                      "required",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "question.required",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          !_vm.unEdit
                                            ? _c("bin-btn", {
                                                attrs: { size: 40 },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeQuestion(i)
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                          !_vm.unEdit
                            ? _c("div", { staticClass: "line-more" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "line-more__content",
                                    on: { click: _vm.addQuestion },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "line-more__icon" },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            name: "ic-plus",
                                            width: "12",
                                            height: "12",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("div", {
                                      staticClass: "line-more__text",
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Add")),
                                      },
                                    }),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "choose-promo__btn btn-btm" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn",
                                  on: { click: _vm.nextStep },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Next")),
                                    },
                                  }),
                                  _c("svg-icon", {
                                    staticClass: "ic-white",
                                    attrs: {
                                      name: "ic-arrow-right",
                                      width: "16",
                                      height: "12",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.step === 2
                    ? _c("div", {}, [
                        !_vm.unEdit
                          ? _c(
                              "label",
                              { staticClass: "split-equally" },
                              [
                                _c("checkbox", {
                                  attrs: { name: "split-equally" },
                                  model: {
                                    value: _vm.splitsEqually,
                                    callback: function ($$v) {
                                      _vm.splitsEqually = $$v
                                    },
                                    expression: "splitsEqually",
                                  },
                                }),
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("Split equally")
                                    ),
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("div", {
                          staticClass: "h5",
                          domProps: {
                            textContent: _vm._s(_vm.$t("Questions")),
                          },
                        }),
                        _c(
                          "div",
                          _vm._b(
                            { staticClass: "question-list mt12" },
                            "div",
                            _vm.darggableQuestionBind,
                            false
                          ),
                          [
                            _vm._l(_vm.questionsFilter, function (question, i) {
                              return [
                                _c(
                                  "div",
                                  {
                                    key: i,
                                    staticClass: "split-question",
                                    class: { active: i === _vm.openedQuestion },
                                  },
                                  [
                                    _c("div", {
                                      domProps: {
                                        textContent: _vm._s(question.question),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "members-club__input" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "input-label" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "input-label__note",
                                              },
                                              [_vm._v("%")]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model.number",
                                                  value: question.weight,
                                                  expression: "question.weight",
                                                  modifiers: { number: true },
                                                },
                                              ],
                                              attrs: {
                                                disabled:
                                                  !!_vm.splitsEqually ||
                                                  _vm.unEdit,
                                                type: "number",
                                              },
                                              domProps: {
                                                value: question.weight,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    question,
                                                    "weight",
                                                    _vm._n($event.target.value)
                                                  )
                                                },
                                                blur: function ($event) {
                                                  return _vm.$forceUpdate()
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            }),
                          ],
                          2
                        ),
                        _c("div", { staticClass: "coupon-buy__btn btn-btm" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn_border",
                              attrs: { href: "#" },
                              on: { click: _vm.backStep },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-arrow-left",
                                  width: "16",
                                  height: "12",
                                },
                              }),
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Back")),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "button",
                            { staticClass: "btn", on: { click: _vm.nextStep } },
                            [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Next")),
                                },
                              }),
                              _c("svg-icon", {
                                staticClass: "ic-white",
                                attrs: {
                                  name: "ic-arrow-right",
                                  width: "16",
                                  height: "12",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.step === 3
                    ? _c(
                        "div",
                        { staticClass: "branches-step" },
                        [
                          _c("div", { staticClass: "coupon-info__field" }, [
                            _c("div", {
                              staticClass: "coupon-info__label",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("Can be redeemed in")
                                ),
                              },
                            }),
                            _c("div", {
                              staticClass: "coupon-info__label",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Source")),
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "availability-check-list rp5",
                                style: {
                                  width: "100%",
                                  display: "flex",
                                  "justify-content": "space-between",
                                },
                              },
                              _vm._l(_vm.sourceEnumValues, function (key, i) {
                                return _c(
                                  "label",
                                  {
                                    key: _vm.sourceEnumToStr(key),
                                    staticClass: "availability-check",
                                    class: {
                                      active: _vm.hook.sources.includes(
                                        _vm.sourceEnumToNumber(key)
                                      ),
                                    },
                                    style: { width: "90%" },
                                  },
                                  [
                                    _c("input", {
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        value: _vm.hook.sources.includes(
                                          _vm.sourceEnumToNumber(key)
                                        ),
                                      },
                                      on: {
                                        input: function ($event) {
                                          _vm.inputHook(
                                            $event,
                                            "sources",
                                            _vm.hook,
                                            _vm.sourceEnumToNumber(key)
                                          )
                                        },
                                      },
                                    }),
                                    _c("svg-icon", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.hook.sources.includes(
                                            _vm.sourceEnumToNumber(key)
                                          ),
                                          expression:
                                            "hook.sources.includes(sourceEnumToNumber(key))",
                                        },
                                      ],
                                      attrs: {
                                        name: "availability-check-v",
                                        width: "16",
                                        height: "12",
                                      },
                                    }),
                                    _c("svg-icon", {
                                      attrs: {
                                        name:
                                          "ic-availability-" +
                                          _vm.sourceEnumToLowerStr(key),
                                        width: "24",
                                        height: "24",
                                      },
                                    }),
                                    _c("span", {
                                      staticClass: "input-check__text",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t(_vm.sourceEnumToStr(key))
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                            _c("div", {
                              staticClass: "coupon-info__label",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Method")),
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "availability-check-list rp4",
                                style: {
                                  width: "100%",
                                  display: "flex",
                                  "justify-content": "space-between",
                                },
                              },
                              _vm._l(_vm.typesEnumValues, function (key, i) {
                                return _c(
                                  "label",
                                  {
                                    key: _vm.typeEnumToStr(key),
                                    staticClass: "availability-check",
                                    class: {
                                      active: _vm.hook.types.includes(
                                        _vm.typeEnumToNumber(key)
                                      ),
                                    },
                                    style: { width: "90%" },
                                  },
                                  [
                                    _c("input", {
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        value: _vm.hook.types.includes(
                                          _vm.typeEnumToNumber(key)
                                        ),
                                      },
                                      on: {
                                        input: function ($event) {
                                          _vm.inputHook(
                                            $event,
                                            "types",
                                            _vm.hook,
                                            _vm.typeEnumToNumber(key)
                                          )
                                        },
                                      },
                                    }),
                                    _c("svg-icon", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.hook.types.includes(
                                            _vm.typeEnumToNumber(key)
                                          ),
                                          expression:
                                            "hook.types.includes(typeEnumToNumber(key))",
                                        },
                                      ],
                                      attrs: {
                                        name: "availability-check-v",
                                        width: "16",
                                        height: "12",
                                      },
                                    }),
                                    _c("svg-icon", {
                                      attrs: {
                                        name:
                                          "ic-availability-" +
                                          _vm.typeEnumToLowerStr(key),
                                        width: "30",
                                        height: "25",
                                      },
                                    }),
                                    _c("span", {
                                      staticClass: "input-check__text",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t(_vm.typeEnumToStr(key))
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                          ]),
                          _c("branches-tab", {
                            attrs: { availability: _vm.editSurvey },
                          }),
                          _c(
                            "div",
                            { staticClass: "coupon-buy__btn btn-btm" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn_border",
                                  attrs: { href: "#" },
                                  on: { click: _vm.backStep },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      name: "ic-arrow-left",
                                      width: "16",
                                      height: "12",
                                    },
                                  }),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Back")),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn",
                                  on: { click: _vm.nextStep },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Next")),
                                    },
                                  }),
                                  _c("svg-icon", {
                                    staticClass: "ic-white",
                                    attrs: {
                                      name: "ic-arrow-right",
                                      width: "16",
                                      height: "12",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.step === 4
                    ? _c("div", {}, [
                        _c("div", {
                          staticClass: "h5",
                          domProps: { textContent: _vm._s(_vm.$t("Hooks")) },
                        }),
                        _c(
                          "div",
                          { staticClass: "hook-list form-site" },
                          _vm._l(_vm.editSurvey.hooks, function (hook, i) {
                            return _c(
                              "div",
                              {
                                key: i,
                                staticClass: "hook",
                                class: { active: i === _vm.openedHook },
                              },
                              [
                                _c("div", { staticClass: "hook__main" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-site__content" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-site__group" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "members-club__field radio mt0",
                                            },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "form-site__label mb16",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t("Target")
                                                  ),
                                                },
                                              }),
                                              _c(
                                                "form",
                                                {
                                                  staticClass:
                                                    "members-club__radio m0",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "input-radio",
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value: hook.target,
                                                            expression:
                                                              "hook.target",
                                                          },
                                                        ],
                                                        attrs: {
                                                          id:
                                                            "radioT" + i + "1",
                                                          type: "radio",
                                                          name: "radio",
                                                        },
                                                        domProps: {
                                                          value: 0,
                                                          checked: _vm._q(
                                                            hook.target,
                                                            0
                                                          ),
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.$set(
                                                              hook,
                                                              "target",
                                                              0
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "input-radio__label",
                                                          attrs: {
                                                            for:
                                                              "radioT" +
                                                              i +
                                                              "1",
                                                          },
                                                        },
                                                        [
                                                          _c("span", {
                                                            staticClass:
                                                              "input-radio__mark",
                                                          }),
                                                          _c("span", {
                                                            staticClass:
                                                              "input-radio__text",
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "Client"
                                                                  )
                                                                ),
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "input-radio",
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value: hook.target,
                                                            expression:
                                                              "hook.target",
                                                          },
                                                        ],
                                                        attrs: {
                                                          id:
                                                            "radioT" + i + "2",
                                                          type: "radio",
                                                          name: "radio",
                                                        },
                                                        domProps: {
                                                          value: 1,
                                                          checked: _vm._q(
                                                            hook.target,
                                                            1
                                                          ),
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.$set(
                                                              hook,
                                                              "target",
                                                              1
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "input-radio__label",
                                                          attrs: {
                                                            for:
                                                              "radioT" +
                                                              i +
                                                              "2",
                                                          },
                                                        },
                                                        [
                                                          _c("span", {
                                                            staticClass:
                                                              "input-radio__mark",
                                                          }),
                                                          _c("span", {
                                                            staticClass:
                                                              "input-radio__text",
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  _vm.$t("User")
                                                                ),
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          i === _vm.openedHook
                                            ? [
                                                hook.target
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "members-club__field radio mt0",
                                                      },
                                                      [
                                                        _c("div", {
                                                          staticClass:
                                                            "form-site__label mb16",
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t("Score")
                                                            ),
                                                          },
                                                        }),
                                                        _c(
                                                          "form",
                                                          {
                                                            staticClass:
                                                              "members-club__radio m0",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "input-radio",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        hook.score_trigger,
                                                                      expression:
                                                                        "hook.score_trigger",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    id:
                                                                      "radioS" +
                                                                      i +
                                                                      "1",
                                                                    type: "radio",
                                                                    name: "radio",
                                                                  },
                                                                  domProps: {
                                                                    value: 0,
                                                                    checked:
                                                                      _vm._q(
                                                                        hook.score_trigger,
                                                                        0
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          hook,
                                                                          "score_trigger",
                                                                          0
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "input-radio__label",
                                                                    attrs: {
                                                                      for:
                                                                        "radioS" +
                                                                        i +
                                                                        "1",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("span", {
                                                                      staticClass:
                                                                        "input-radio__mark",
                                                                    }),
                                                                    _c("span", {
                                                                      staticClass:
                                                                        "input-radio__text",
                                                                      domProps:
                                                                        {
                                                                          textContent:
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "Below"
                                                                              )
                                                                            ),
                                                                        },
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "input-radio",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        hook.score_trigger,
                                                                      expression:
                                                                        "hook.score_trigger",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    id:
                                                                      "radioS" +
                                                                      i +
                                                                      "2",
                                                                    type: "radio",
                                                                    name: "radio",
                                                                  },
                                                                  domProps: {
                                                                    value: 1,
                                                                    checked:
                                                                      _vm._q(
                                                                        hook.score_trigger,
                                                                        1
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          hook,
                                                                          "score_trigger",
                                                                          1
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "input-radio__label",
                                                                    attrs: {
                                                                      for:
                                                                        "radioS" +
                                                                        i +
                                                                        "2",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("span", {
                                                                      staticClass:
                                                                        "input-radio__mark",
                                                                    }),
                                                                    _c("span", {
                                                                      staticClass:
                                                                        "input-radio__text",
                                                                      domProps:
                                                                        {
                                                                          textContent:
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "Above"
                                                                              )
                                                                            ),
                                                                        },
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                hook.target
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-site__field flex a-center",
                                                      },
                                                      [
                                                        _c("div", {
                                                          staticClass: "mie16",
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              hook.phones
                                                                .length +
                                                                " " +
                                                                _vm.$t("Users")
                                                            ),
                                                          },
                                                        }),
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-more__content",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.popup = true
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "btn-more__icon",
                                                              },
                                                              [
                                                                _c("svg-icon", {
                                                                  attrs: {
                                                                    name: "ic-plus",
                                                                    width: "12",
                                                                    height:
                                                                      "12",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                hook.target
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-site__field mt0",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-site__input",
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    hook.score,
                                                                  expression:
                                                                    "hook.score",
                                                                },
                                                              ],
                                                              attrs: {
                                                                type: "number",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  hook.score,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      hook,
                                                                      "score",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                !hook.target
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "members-club__field mt0",
                                                      },
                                                      [
                                                        _c("div", {
                                                          staticClass:
                                                            "form-site__label",
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "When to send?"
                                                              )
                                                            ),
                                                          },
                                                        }),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "members-club__input",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "input-label",
                                                              },
                                                              [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "input-label__note left_minutes",
                                                                  domProps: {
                                                                    textContent:
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "min"
                                                                        )
                                                                      ),
                                                                  },
                                                                }),
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        hook.delay,
                                                                      expression:
                                                                        "hook.delay",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    type: "number",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      hook.delay,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        ) {
                                                                          return
                                                                        }
                                                                        _vm.$set(
                                                                          hook,
                                                                          "delay",
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                      i === _vm.openedHook
                                        ? [
                                            !hook.target
                                              ? _c(
                                                  "div",
                                                  { staticClass: "form-site" },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "form-site__label",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t("Message")
                                                        ),
                                                      },
                                                    }),
                                                    _c(
                                                      "ul",
                                                      {
                                                        staticClass:
                                                          "flex mb12",
                                                      },
                                                      _vm._l(
                                                        [
                                                          "link",
                                                          "user_name",
                                                          "date",
                                                          "restaurant_name",
                                                          "branch_name",
                                                          "order_id",
                                                        ],
                                                        function (key) {
                                                          return _c(
                                                            "li",
                                                            {
                                                              key: key,
                                                              staticClass:
                                                                "mie16",
                                                            },
                                                            [
                                                              _c("button", {
                                                                staticClass:
                                                                  "btn-add-text",
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        _vm.capitalize(
                                                                          key
                                                                        )
                                                                      )
                                                                    ),
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.addTextToHook(
                                                                        i,
                                                                        key,
                                                                        hook
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _c("textarea", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: hook.message,
                                                          expression:
                                                            "hook.message",
                                                        },
                                                      ],
                                                      ref: "texthook" + i,
                                                      refInFor: true,
                                                      staticClass: "input",
                                                      attrs: { rows: "10" },
                                                      domProps: {
                                                        value: hook.message,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            hook,
                                                            "message",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "hook__btms" },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "hook__open-btn",
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeOpenedHook(i)
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          class: {
                                            active: i === _vm.openedHook,
                                          },
                                          attrs: {
                                            name: "ic-arrow-down",
                                            width: "18",
                                            height: "10",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("bin-btn", {
                                      staticClass: "hook__bin",
                                      on: {
                                        click: function ($event) {
                                          return _vm.rmHook(i)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                        _c("div", { staticClass: "line-more" }, [
                          _c(
                            "button",
                            {
                              staticClass: "line-more__content",
                              on: { click: _vm.addHook },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "line-more__icon" },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      name: "ic-plus",
                                      width: "12",
                                      height: "12",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", {
                                staticClass: "line-more__text",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Add")),
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "coupon-info__btn btn-btm" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn_border",
                              attrs: { href: "#" },
                              on: { click: _vm.backStep },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-arrow-left",
                                  width: "16",
                                  height: "12",
                                },
                              }),
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Back")),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "button",
                            { staticClass: "btn", on: { click: _vm.save } },
                            [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Save")),
                                },
                              }),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _vm.preview
        ? _c("survey-preview", {
            attrs: { survey: _vm.editSurvey },
            on: {
              close: function ($event) {
                _vm.preview = false
              },
            },
          })
        : _vm._e(),
      _vm.popup
        ? _c("edit-phons-dialog", {
            attrs: {
              hook: _vm.editSurvey.hooks[_vm.openedHook],
              surveyBranches: _vm.editSurvey.branches,
            },
            on: {
              close: function ($event) {
                _vm.popup = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }