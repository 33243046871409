var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "compensation" },
    [
      _c(
        "table-list",
        _vm._b(
          {
            ref: "compensationsTable",
            on: {
              changeOptions: _vm.changeOptions,
              "on-click-line": _vm.openPopup,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "filter-by-date",
                  fn: function () {
                    return [
                      _c("filter-by-date", {
                        model: {
                          value: _vm.dateFilter,
                          callback: function ($$v) {
                            _vm.dateFilter = $$v
                          },
                          expression: "dateFilter",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
                _vm.mobile && _vm.checkPermissionPath("compensation.create")
                  ? {
                      key: "above-tabel",
                      fn: function () {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "mobile-btn-plus",
                              on: { click: _vm.startCreateCompensation },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-plus",
                                  width: "16",
                                  height: "16",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : _vm.checkPermissionPath("compensation.create")
                  ? {
                      key: "btn",
                      fn: function () {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn_pink",
                              on: { click: _vm.startCreateCompensation },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-plus",
                                  width: "16",
                                  height: "16",
                                },
                              }),
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("New compensation")
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
                {
                  key: "filter-by-properties",
                  fn: function () {
                    return [
                      _c("filter-by-properties", {
                        attrs: {
                          properties: _vm.propertiesFilterOptions,
                          radio: "",
                        },
                        model: {
                          value: _vm.propertiesFilter,
                          callback: function ($$v) {
                            _vm.propertiesFilter = $$v
                          },
                          expression: "propertiesFilter",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "filter-text",
                  fn: function () {
                    return [
                      _vm.filtersText
                        ? _c("div", {
                            staticClass: "mt12 mb12",
                            domProps: { textContent: _vm._s(_vm.filtersText) },
                          })
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          },
          "table-list",
          _vm.tableData,
          false
        )
      ),
      _vm.popup === 1
        ? _c("compensation-edit-dialog", {
            attrs: { compensation: _vm.compensationInEdit },
            on: {
              save: _vm.save,
              cancel: _vm.cancel,
              close: _vm.close,
              closeload: _vm.closeAndLoad,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }