var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "base-dialog",
        {
          staticClass: "technician-code-generate-dialog",
          on: { close: _vm.close },
        },
        [
          _c("div", { staticClass: "popup__content" }, [
            _c("div", { staticClass: "popup__top" }, [
              _c("div", { staticClass: "popup__head" }, [
                _c("div", {
                  staticClass: "h2 t-center",
                  domProps: {
                    textContent: _vm._s(_vm.$t("generate technician code")),
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "popup__body" }, [
              _vm.code
                ? _c(
                    "div",
                    {
                      staticClass: "form-site__field",
                      on: { click: _vm.copyToClipboard },
                    },
                    [
                      _c("div", { staticClass: "form-site__field__label" }, [
                        _c("span", [_vm._v(_vm._s(_vm.$t("technician code")))]),
                      ]),
                      _c("div", { staticClass: "form-site__field__input" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.code,
                              expression: "code",
                            },
                          ],
                          attrs: { type: "text", readonly: "" },
                          domProps: { value: _vm.code },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.code = $event.target.value
                            },
                          },
                        }),
                      ]),
                      _c("small", [_vm._v(_vm._s(_vm.$t("click to copy")))]),
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "form-site__btn" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn_border-grad",
                    attrs: { type: "button" },
                    on: { click: _vm.generateTechnicianCode },
                  },
                  [
                    _c("span", {
                      staticClass: "btn btn_border",
                      domProps: {
                        textContent: _vm._s(_vm.$t("generate technician code")),
                      },
                    }),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }