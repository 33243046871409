var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "survey-answer-sidebar" },
    [
      _c("backdrop", { on: { emitClose: _vm.close } }),
      _c(
        "div",
        { staticClass: "page-details", class: { opened: _vm.opened } },
        [
          _c("div", { staticClass: "page-details__main" }, [
            _c("div", { staticClass: "page-details__top" }, [
              _c(
                "button",
                { staticClass: "page-details-back", on: { click: _vm.close } },
                [
                  _c("svg-icon", {
                    staticClass: "ic-white",
                    attrs: { name: "ic-arrow-left", width: "16", height: "12" },
                  }),
                  _c("span", {
                    staticClass: "page-details-back__text",
                    domProps: { textContent: _vm._s(_vm.$t("Back")) },
                  }),
                ],
                1
              ),
              _c(
                "button",
                { staticClass: "page-details-back", on: { click: _vm.unread } },
                [
                  _c("span", {
                    staticClass: "page-details-back__text orange",
                    domProps: { textContent: _vm._s(_vm.$t("Mark as unread")) },
                  }),
                ]
              ),
            ]),
            _c("div", { staticClass: "page-det-company" }, [
              _c("div", { staticClass: "page-det-company__main" }, [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c("score-shower", {
                      staticClass: "mie16",
                      attrs: {
                        total: 5,
                        score: _vm.answeredSurvey.feedback
                          ? _vm.answeredSurvey.feedback.score
                          : 0,
                      },
                    }),
                    _c("div", { staticClass: "red t-start w100" }, [
                      _c("button", {
                        staticClass: "page-det-company__name mb8 t-start",
                        domProps: {
                          textContent: _vm._s(_vm.answeredSurvey.name),
                        },
                        on: { click: _vm.openUser },
                      }),
                      _c("div", { staticClass: "flex bet" }, [
                        _c("div", [
                          _c("div", [
                            _c("a", {
                              staticClass: "red",
                              attrs: {
                                href: "tel:" + _vm.answeredSurvey.phone,
                              },
                              domProps: {
                                textContent: _vm._s(_vm.answeredSurvey.phone),
                              },
                            }),
                          ]),
                          _vm.answeredSurvey.order_id &&
                          _vm.answeredSurvey.order_id !== 1
                            ? _c("button", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("Order") +
                                      " " +
                                      _vm.answeredSurvey.order_id
                                  ),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit(
                                      "openOrder",
                                      _vm.answeredSurvey.order_id
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                          _c("div", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("Branch") + " " + _vm.branchName
                              ),
                            },
                          }),
                        ]),
                        _c(
                          "a",
                          {
                            staticClass: "btn-whatsapp",
                            attrs: {
                              href:
                                "https://wa.me/972" +
                                _vm.answeredSurvey.phone.substr(1),
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/img/WhatsApp.svg"),
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "t-end" }, [
                        _c("button", { on: { click: _vm.copyLink } }, [
                          _c("span", {
                            staticClass: "page-details-back__text orange",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Copy link")),
                            },
                          }),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "page-details__content" }, [
            _c(
              "div",
              { staticClass: "page-details__segments" },
              _vm._l(
                _vm.answeredSurvey.feedback
                  ? _vm.answeredSurvey.feedback.segments
                  : [],
                function (segment) {
                  return _c(
                    "div",
                    {
                      key: segment.segment_id,
                      staticClass: "page-details__segment",
                    },
                    [
                      _c("div", {
                        staticClass: "page-details__segment_name",
                        domProps: { textContent: _vm._s(segment.score) },
                      }),
                      _c("div", {
                        staticClass: "page-details__segment_score",
                        domProps: { textContent: _vm._s(segment.segment_name) },
                      }),
                    ]
                  )
                }
              ),
              0
            ),
            _c(
              "div",
              { staticClass: "page-details__answers" },
              _vm._l(
                _vm.answeredSurvey.feedback
                  ? _vm.answeredSurvey.feedback.answers
                  : [],
                function (question, i) {
                  return _c(
                    "div",
                    { key: i, staticClass: "page-details__answer" },
                    [
                      _c("div", { staticClass: "page-details__answer_top" }, [
                        _c("div", {
                          staticClass: "page-details__answer_question",
                          domProps: { textContent: _vm._s(question.question) },
                        }),
                        _c("div", {
                          staticClass: "page-details__answer_segment",
                          domProps: {
                            textContent: _vm._s(question.segment_name),
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "page-details__answer_btm" }, [
                        _c("div", {
                          staticClass: "page-details__answer_answer",
                          domProps: {
                            innerHTML: _vm._s(
                              (question.answer_text || "").replaceAll(
                                "\n",
                                "<br>"
                              )
                            ),
                          },
                        }),
                        question.score
                          ? _c("div", {
                              staticClass: "page-details__answer_score",
                              domProps: { textContent: _vm._s(question.score) },
                            })
                          : _vm._e(),
                      ]),
                    ]
                  )
                }
              ),
              0
            ),
            _c("div", { staticClass: "page-details__comment form-site" }, [
              _c("div", {
                staticClass: "page-details__comment_title",
                domProps: { textContent: _vm._s(_vm.$t("Comment")) },
              }),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.comment,
                    expression: "comment",
                  },
                ],
                attrs: { placeholder: _vm.$t("Write your comment") },
                domProps: { value: _vm.comment },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.comment = $event.target.value
                  },
                },
              }),
              _c("button", {
                staticClass: "page-details__comment_btn btn",
                domProps: { textContent: _vm._s(_vm.$t("Save")) },
                on: { click: _vm.saveComment },
              }),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }