var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: { width: "880px", title: _vm.dialogTitle },
      on: { close: _vm.close },
    },
    [
      _c("div", { staticClass: "popup__content" }, [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.save.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "form-sms" }, [
              _vm.smsMessage
                ? _c("div", { staticClass: "form-sms__fields" }, [
                    _c("div", { staticClass: "form-sms__field" }, [
                      _c(
                        "div",
                        { staticClass: "form-sms__input" },
                        [
                          _c("div", {
                            staticClass: "form-sms__label",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Schedule")),
                            },
                          }),
                          _c("select-box", {
                            attrs: { options: _vm.timingOptions },
                            model: {
                              value: _vm.smsMessage.timing,
                              callback: function ($$v) {
                                _vm.$set(_vm.smsMessage, "timing", $$v)
                              },
                              expression: "smsMessage.timing",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm.smsMessage.timing !== _vm.smsMessageTiming.Once
                      ? _c("div", { staticClass: "form-sms__field" }, [
                          _c(
                            "div",
                            { staticClass: "form-sms__input" },
                            [
                              _c("div", {
                                staticClass: "form-sms__label",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("Allowed sending days")
                                  ),
                                },
                              }),
                              _c("select-box", {
                                attrs: {
                                  unsort: true,
                                  multiple: true,
                                  options: _vm.allowedDaysOptions,
                                },
                                model: {
                                  value: _vm.smsMessage.allowed_days,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.smsMessage,
                                      "allowed_days",
                                      $$v
                                    )
                                  },
                                  expression: "smsMessage.allowed_days",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.smsMessage.timing === _vm.smsMessageTiming.Once
                      ? _c("div", { staticClass: "form-sms__field" }, [
                          _c("div", {
                            staticClass: "form-sms__label",
                            domProps: { textContent: _vm._s(_vm.$t("Date")) },
                          }),
                          _c(
                            "div",
                            { staticClass: "input-date flex a-center h53" },
                            [
                              _c("date-picker", {
                                staticClass: "date-picker",
                                attrs: {
                                  name: "a2",
                                  "min-date": _vm.today,
                                  locale: _vm.locale,
                                  popover: { visibility: "click" },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var inputValue = ref.inputValue
                                        var inputEvents = ref.inputEvents
                                        return [
                                          _c(
                                            "input",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "bg-white border px-2 py-1 rounded",
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value: inputValue.replaceAll(
                                                    ".",
                                                    "/"
                                                  ),
                                                },
                                              },
                                              inputEvents
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  52154283
                                ),
                                model: {
                                  value: _vm.smsMessage.scheduled_message_date,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.smsMessage,
                                      "scheduled_message_date",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "smsMessage.scheduled_message_date",
                                },
                              }),
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-calendar",
                                  width: "20",
                                  height: "20",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "form-sms__field" }, [
                      _c(
                        "div",
                        { staticClass: "form-sms__input" },
                        [
                          _c("div", { staticClass: "form-sms__input" }, [
                            _c("div", { staticClass: "form-sms__label" }, [
                              _vm._v(_vm._s(_vm.$t("Time"))),
                            ]),
                          ]),
                          _c("select-box", {
                            staticClass: "time",
                            attrs: {
                              autocomplete: "",
                              options: _vm.timeOfDayOptions,
                              placeholder: "00:00",
                            },
                            model: {
                              value: _vm.smsMessage.time_of_day,
                              callback: function ($$v) {
                                _vm.$set(_vm.smsMessage, "time_of_day", $$v)
                              },
                              expression: "smsMessage.time_of_day",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-sms__field" }, [
                      _c(
                        "div",
                        { staticClass: "form-sms__input" },
                        [
                          _c("div", {
                            staticClass: "form-sms__label",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Condition")),
                            },
                          }),
                          _c("select-box", {
                            attrs: { options: _vm.conditionOptions },
                            model: {
                              value: _vm.smsMessage.condition,
                              callback: function ($$v) {
                                _vm.$set(_vm.smsMessage, "condition", $$v)
                              },
                              expression: "smsMessage.condition",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm.conditionIsIncludedInAudiences
                      ? _c("div", { staticClass: "form-sms__field" }, [
                          _c(
                            "div",
                            { staticClass: "form-sms__input" },
                            [
                              _c("div", {
                                staticClass: "form-sms__label",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Audience")),
                                },
                              }),
                              _c("select-box", {
                                attrs: {
                                  multiple: true,
                                  options: _vm.audiencesOptions,
                                  placeholder: _vm.audiencesPlaceholder,
                                },
                                model: {
                                  value: _vm.smsMessage.audiences,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.smsMessage, "audiences", $$v)
                                  },
                                  expression: "smsMessage.audiences",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "form-sms__field form-sms__field_full" },
                      [
                        _c("div", { staticClass: "form-sms__input" }, [
                          _c("div", {
                            staticClass: "form-sms__label",
                            domProps: { textContent: _vm._s(_vm.$t("Title")) },
                          }),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.smsMessage.title,
                                expression: "smsMessage.title",
                              },
                            ],
                            attrs: {
                              type: "text",
                              name: "title",
                              title: _vm.$t("Title"),
                              placeholder: _vm.$t("Type your title"),
                              required: "",
                            },
                            domProps: { value: _vm.smsMessage.title },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.smsMessage,
                                  "title",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "form-sms__field form-sms__field_full" },
                      [
                        _vm.smsMessage && _vm.smsMessage.message != undefined
                          ? _c("div", { staticClass: "form-sms__label-wrap" }, [
                              _c("div", {
                                staticClass: "form-sms__label",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Message")),
                                },
                              }),
                              _c("div", {
                                staticClass: "form-sms__msg-count",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.smsMessage.message.length
                                  ),
                                },
                              }),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "variable-buttons" },
                          _vm._l(_vm.variables, function (variable) {
                            return _c(
                              "button",
                              {
                                staticClass: "btn-add-text",
                                attrs: { type: "button" },
                                on: {
                                  mousedown: function ($event) {
                                    return _vm.addVariableAtCursorPosition(
                                      variable.value
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(variable.label))]
                            )
                          }),
                          0
                        ),
                        _vm.smsMessage
                          ? _c(
                              "div",
                              { staticClass: "form-sms-msg" },
                              [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.smsMessage.message,
                                      expression: "smsMessage.message",
                                    },
                                  ],
                                  ref: "messageTextArea",
                                  attrs: {
                                    name: "message",
                                    title: _vm.$t("Message"),
                                    placeholder: _vm.$t("Type your message"),
                                    required: "",
                                  },
                                  domProps: { value: _vm.smsMessage.message },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.smsMessage,
                                        "message",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _c("cs-emoji-picker", {
                                  model: {
                                    value: _vm.smsMessage.message,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.smsMessage, "message", $$v)
                                    },
                                    expression: "smsMessage.message",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "form-sms__btn" }, [
                _vm.smsMessage && _vm.smsMessage.id
                  ? _c("button", {
                      staticClass: "btn btn_border btn_no_border",
                      attrs: { type: "button" },
                      domProps: { textContent: _vm._s(_vm.$t("Delete")) },
                      on: { click: _vm.deleteMessage },
                    })
                  : _vm._e(),
                _c("button", {
                  staticClass: "btn btn_border",
                  attrs: { type: "button" },
                  domProps: { textContent: _vm._s(_vm.$t("Show analytics")) },
                  on: { click: _vm.getAnalytics },
                }),
                _c("button", {
                  staticClass: "btn btn_border",
                  attrs: { type: "button" },
                  domProps: { textContent: _vm._s(_vm.$t("Preview")) },
                  on: { click: _vm.sendPreviewSms },
                }),
                _c("button", {
                  staticClass: "btn",
                  attrs: { type: "submit" },
                  domProps: { textContent: _vm._s(_vm.$t("Save")) },
                }),
              ]),
            ]),
          ]
        ),
      ]),
      _vm.analyticsToShow
        ? _c("sms-message-analytics-view", {
            attrs: {
              analytics: _vm.analyticsToShow,
              confirmation: _vm.confirmAnalytics,
            },
            on: {
              close: function ($event) {
                _vm.analyticsToShow = null
              },
              confirm: _vm.commitSave,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }