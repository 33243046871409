var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "lang lang_grey" }, [
    _c("div", {
      staticClass: "lang__item",
      class: { active: _vm.active },
      domProps: { textContent: _vm._s(_vm.positiveLabel) },
      on: { click: _vm.toggle },
    }),
    _c("div", {
      staticClass: "lang__item",
      class: { active: !_vm.active },
      domProps: { textContent: _vm._s(_vm.negativeLabel) },
      on: { click: _vm.toggle },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }