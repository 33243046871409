var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "working-hours-container full-screen" },
    [
      _c("section", { staticClass: "container" }, [
        _c("div", { staticClass: "page" }, [
          _c(
            "div",
            { staticClass: "page__main" },
            [
              _c(
                "router-link",
                { staticClass: "page__back", attrs: { to: "../settings" } },
                [
                  _c("svg-icon", {
                    attrs: {
                      name: "ic-close-table-arrow",
                      width: "34",
                      height: "34",
                    },
                  }),
                ],
                1
              ),
              _c("h1", {
                staticClass: "mb2rem",
                domProps: { textContent: _vm._s(_vm.$t("Working hours")) },
              }),
              _c("div", { staticClass: "working-days" }, [
                _c("div", { staticClass: "working-days__all" }, [
                  _c("div", { staticClass: "filter-btn" }, [
                    _c("button", {
                      staticClass: "filter-btn__item",
                      class: { active: _vm.mode === "delivery" },
                      domProps: { textContent: _vm._s(_vm.$t("Deliveries")) },
                      on: {
                        click: function ($event) {
                          return _vm.setMode("delivery")
                        },
                      },
                    }),
                    _c("button", {
                      staticClass: "filter-btn__item",
                      class: { active: _vm.mode === "pickup" },
                      domProps: { textContent: _vm._s(_vm.$t("Pickup")) },
                      on: {
                        click: function ($event) {
                          return _vm.setMode("pickup")
                        },
                      },
                    }),
                    _c("button", {
                      staticClass: "filter-btn__item",
                      class: { active: _vm.mode === "restaurant" },
                      domProps: {
                        textContent: _vm._s(_vm.$t("Restaurant / Kiosks")),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.setMode("restaurant")
                        },
                      },
                    }),
                    _c("button", {
                      staticClass: "filter-btn__item",
                      class: { active: _vm.mode === "scheduled" },
                      domProps: { textContent: _vm._s(_vm.$t("Scheduleds")) },
                      on: {
                        click: function ($event) {
                          return _vm.setMode("scheduled")
                        },
                      },
                    }),
                  ]),
                  _vm.mode !== "scheduled"
                    ? _c("div", { staticClass: "input-check" }, [
                        _c(
                          "label",
                          [
                            _c("check-box", {
                              attrs: { name: "Always open" },
                              model: {
                                value: _vm.always,
                                callback: function ($$v) {
                                  _vm.always = $$v
                                },
                                expression: "always",
                              },
                            }),
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(_vm.$t("Always open")),
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ],
            1
          ),
          _vm.mode === "scheduled"
            ? _c(
                "div",
                { staticClass: "scheduled-list" },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "center",
                        width: "100%",
                        "margin-bottom": "30px",
                      },
                    },
                    [
                      !_vm.mobile
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn_pink",
                              on: {
                                click: function ($event) {
                                  _vm.scheduledOpened = true
                                },
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-plus",
                                  width: "16",
                                  height: "16",
                                },
                              }),
                              _c("span", {
                                staticClass: "d-md-block d-none",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("Add new schedule")
                                  ),
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.mobile
                        ? _c(
                            "button",
                            {
                              staticClass: "line-more__content",
                              on: {
                                click: function ($event) {
                                  _vm.scheduledOpened = true
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "line-more__icon" },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      name: "ic-plus",
                                      width: "12",
                                      height: "12",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("span", {
                                staticClass: "line-more__text",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("Add new schedule")
                                  ),
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._l(_vm.schedules, function (schedule) {
                    return _c(
                      "div",
                      {
                        key: schedule.id,
                        staticClass: "scheduled",
                        class: { active: schedule.active },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "flex bet a-center",
                            staticStyle: { gap: "10px" },
                          },
                          [
                            _c("div", { staticClass: "flex" }, [
                              _c("div", {
                                staticClass: "mie16",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      ["close-emergency", "Delay"][
                                        schedule.schedule_type
                                      ]
                                    )
                                  ),
                                },
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "mie16 d-md-inline-block d-none",
                                },
                                [_vm._v("|")]
                              ),
                              _c("div", {
                                staticClass: "mie16",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      [
                                        "Delivery",
                                        "Pickup",
                                        "Pickup & Delivery",
                                      ][schedule.order_type]
                                    )
                                  ),
                                },
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "mie16 d-md-inline-block d-none",
                                },
                                [_vm._v("|")]
                              ),
                              _c("div", { staticClass: "mie16" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("From")) +
                                    ":\n                " +
                                    _vm._s(
                                      _vm.filters.date(
                                        schedule.from_time,
                                        "DD/MM/YYYY HH:mm"
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]),
                              _c("div", { staticClass: "mie16" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("To")) +
                                    ":\n                " +
                                    _vm._s(
                                      _vm.filters.date(
                                        schedule.to,
                                        "DD/MM/YYYY HH:mm"
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]),
                              schedule.schedule_type
                                ? _c("div", {}, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("For")) +
                                        ": " +
                                        _vm._s(schedule.add_extra) +
                                        "\n                " +
                                        _vm._s(_vm.$t("Minutes")) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c(
                              "div",
                              { staticClass: "flex" },
                              [
                                _c("edit-btn", {
                                  attrs: { size: 48 },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editSchedule(schedule)
                                    },
                                  },
                                }),
                                _c(
                                  "button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeSchedule(schedule.id)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "icon-btn",
                                        attrs: {
                                          viewBox: "0 0 48 48",
                                          fill: "none",
                                          xmlns: "http://www.w3.org/2000/svg",
                                        },
                                      },
                                      [
                                        _c("circle", {
                                          attrs: {
                                            cx: "24",
                                            cy: "24",
                                            r: "24",
                                            fill: "#FFE0E4",
                                          },
                                        }),
                                        _c("path", {
                                          attrs: {
                                            d: "M16 33.332C16 34.8054 17.1933 35.9987 18.6667 35.9987H29.3334C30.8067 35.9987 32 34.8054 32 33.332V17.332H16V33.332Z",
                                            fill: "#FF465C",
                                          },
                                        }),
                                        _c("path", {
                                          attrs: {
                                            d: "M28.6667 13.3333L27.3334 12H20.6667L19.3334 13.3333H14.6667V16H33.3334V13.3333H28.6667Z",
                                            fill: "#FF465C",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                ],
                2
              )
            : _c(
                "div",
                { staticClass: "working-days__list" },
                _vm._l(_vm.days, function (day) {
                  return _c("day-working-hours", {
                    key: day.dayName + _vm.mode,
                    attrs: {
                      always: _vm.always,
                      props: day,
                      isWorkingHours: true,
                      enterTimeShabat: _vm.enterTimeShabat,
                      leaveTimeShabat: _vm.leaveTimeShabat,
                    },
                    on: { save: _vm.saveDay },
                  })
                }),
                1
              ),
        ]),
      ]),
      _vm.scheduledOpened
        ? _c("schedule-dialog", {
            ref: "scheduleEditDialog",
            on: { save: _vm.saveSchedule },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }