var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "atmos-promotions" },
    [
      _c(
        "table-list",
        _vm._b(
          {
            ref: "stockTable",
            on: {
              changeOptions: _vm.changeOptions,
              "on-click-line": _vm.editPromotion,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "btn-back",
                  fn: function () {
                    return [
                      _c(
                        "router-link",
                        {
                          staticClass: "page__back",
                          attrs: { to: "/atmos-club" },
                        },
                        [
                          _c("svg-icon", {
                            attrs: {
                              name: "ic-close-table-arrow",
                              width: "34",
                              height: "34",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                _vm.mobile
                  ? {
                      key: "above-tabel",
                      fn: function () {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "mobile-btn-plus",
                              on: {
                                click: function ($event) {
                                  _vm.addPromo = true
                                },
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-plus",
                                  width: "16",
                                  height: "16",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : {
                      key: "btn",
                      fn: function () {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn_pink",
                              on: {
                                click: function ($event) {
                                  _vm.addPromo = true
                                },
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-plus",
                                  width: "16",
                                  height: "16",
                                },
                              }),
                              _c("span", {
                                staticClass: "d-md-block d-none",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("New promotion")),
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
              ],
              null,
              true
            ),
          },
          "table-list",
          _vm.dataTable,
          false
        )
      ),
      _vm.addPromo
        ? _c("create-atmos-promotion-dialog", {
            on: {
              close: function ($event) {
                _vm.addPromo = false
              },
              closeSuccess: _vm.showSuccessMsg,
            },
          })
        : _vm._e(),
      _vm.editPopup
        ? _c("atmos-promotion-edit-dialog", {
            attrs: { promotion: _vm.promotionInEdit },
            on: {
              closeSuccess: _vm.closeEditPopup,
              close: function ($event) {
                _vm.editPopup = false
              },
            },
          })
        : _vm._e(),
      _vm.succeessMsg
        ? _c("atmos-promotions-success-msg", {
            on: {
              close: function ($event) {
                _vm.succeessMsg = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }