var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "laber-cost-statistics-box flex col relative" },
    [
      _c(
        "span",
        { class: "to-end" },
        [
          _c("header", { staticClass: "flex bet" }, [
            _c("span", { staticClass: "flex" }, [
              _c("span", {
                staticClass: "mie4",
                domProps: { textContent: _vm._s(_vm.$t("Costs")) },
              }),
            ]),
          ]),
          [
            _c("div", { staticClass: "row-orders" }, [
              _c("div", { staticClass: "dataa" }, [
                _c("div", { staticClass: "all_line" }, [
                  _c("div", { staticClass: "text_line" }, [
                    _c("div", { staticClass: "statistic-text" }, [
                      _c("span", {
                        staticStyle: { "font-weight": "500" },
                        domProps: { textContent: _vm._s(_vm.$t("laber cost")) },
                      }),
                    ]),
                    _c(
                      "div",
                      { staticClass: "statistic-amount" },
                      [
                        _c("format-currency", {
                          staticClass: "total",
                          attrs: { data: _vm.statistic.laber_cost_tmp },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm.pastDates && _vm.pastDates.length > 0
                    ? _c("div", { staticClass: "tooltip" }, [
                        _c(
                          "div",
                          {
                            staticClass: "tooltiptext",
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "column",
                            },
                          },
                          [
                            _c("div", [
                              _c("span", {
                                staticStyle: { "font-weight": "bold" },
                                domProps: {
                                  textContent: _vm._s(_vm.$t("compare dates")),
                                },
                              }),
                            ]),
                            _vm._l(_vm.pastDates, function (date) {
                              return _c("div", [
                                _c(
                                  "span",
                                  { staticStyle: { color: "black" } },
                                  [_vm._v(" " + _vm._s(date))]
                                ),
                              ])
                            }),
                          ],
                          2
                        ),
                        _c("div", { staticClass: "perstage" }, [
                          _vm.statistic.percentage_laber_tmp
                            ? _c(
                                "span",
                                {
                                  staticClass: "traffic flex a-center",
                                  class: _vm.statistic.percentage_laber_tmp
                                    ? _vm.statistic.percentage_laber_tmp > 0
                                      ? "traffic-up"
                                      : "traffic-down"
                                    : "zero",
                                },
                                [
                                  _c("span", {
                                    staticClass: "mie4",
                                    staticStyle: { color: "#e34836" },
                                    domProps: {
                                      textContent: _vm._s(
                                        Math.abs(
                                          _vm.statistic.percentage_laber_tmp.toFixed(
                                            1
                                          )
                                        ) + "%"
                                      ),
                                    },
                                  }),
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "12",
                                        height: "12",
                                        viewBox: "0 0 12 12",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M2.76785 4.71258C2.52372 4.9567 2.13939 4.9567 1.89527 4.71258C1.65939 4.46845 1.65939 4.08412 1.89527 3.84L5.55163 0.183093C5.66489 0.069828 5.82214 0 5.99644 0C6.17073 0 6.32798 0.069828 6.4418 0.183093L10.0982 3.84C10.3423 4.08412 10.3423 4.46845 10.0982 4.71258C9.85403 4.9567 9.4697 4.9567 9.22558 4.71258L6.61609 2.0943L6.61609 11.3891C6.61664 11.7295 6.33733 12 5.99699 12C5.65664 12 5.38613 11.7295 5.38613 11.3891L5.38613 2.09485L2.76785 4.71258Z",
                                          fill:
                                            _vm.statistic.traffic > 0
                                              ? "#369B58"
                                              : "#dd4a36",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "all_line" }, [
                  _c("div", { staticClass: "text_line" }, [
                    _c("div", { staticClass: "statistic-text" }, [
                      _c("span", {
                        staticStyle: { "font-weight": "500" },
                        domProps: {
                          textContent: _vm._s(_vm.$t("laber_cost_tmp")),
                        },
                      }),
                    ]),
                    _c(
                      "div",
                      { staticClass: "statistic-amount" },
                      [
                        _vm.statistic.laber_cost !== null
                          ? _c("format-currency", {
                              staticClass: "total",
                              attrs: { data: _vm.statistic.laber_cost },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm.pastDates && _vm.pastDates.length > 0
                    ? _c("div", { staticClass: "tooltip" }, [
                        _c(
                          "div",
                          {
                            staticClass: "tooltiptext",
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "column",
                            },
                          },
                          [
                            _c("div", [
                              _c("span", {
                                staticStyle: { "font-weight": "bold" },
                                domProps: {
                                  textContent: _vm._s(_vm.$t("compare dates")),
                                },
                              }),
                            ]),
                            _vm._l(_vm.pastDates, function (date) {
                              return _c("div", [
                                _c(
                                  "span",
                                  { staticStyle: { color: "black" } },
                                  [_vm._v(" " + _vm._s(date))]
                                ),
                              ])
                            }),
                          ],
                          2
                        ),
                        _c("div", { staticClass: "perstage" }, [
                          _vm.statistic.percentage_laber
                            ? _c(
                                "span",
                                {
                                  staticClass: "traffic flex a-center",
                                  class: _vm.statistic.percentage_laber
                                    ? _vm.statistic.percentage_laber > 0
                                      ? "traffic-up"
                                      : "traffic-down"
                                    : "zero",
                                },
                                [
                                  _c("span", {
                                    staticClass: "mie4",
                                    staticStyle: { color: "#e34836" },
                                    domProps: {
                                      textContent: _vm._s(
                                        Math.abs(
                                          _vm.statistic.percentage_laber.toFixed(
                                            1
                                          )
                                        ) + "%"
                                      ),
                                    },
                                  }),
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "12",
                                        height: "12",
                                        viewBox: "0 0 12 12",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M2.76785 4.71258C2.52372 4.9567 2.13939 4.9567 1.89527 4.71258C1.65939 4.46845 1.65939 4.08412 1.89527 3.84L5.55163 0.183093C5.66489 0.069828 5.82214 0 5.99644 0C6.17073 0 6.32798 0.069828 6.4418 0.183093L10.0982 3.84C10.3423 4.08412 10.3423 4.46845 10.0982 4.71258C9.85403 4.9567 9.4697 4.9567 9.22558 4.71258L6.61609 2.0943L6.61609 11.3891C6.61664 11.7295 6.33733 12 5.99699 12C5.65664 12 5.38613 11.7295 5.38613 11.3891L5.38613 2.09485L2.76785 4.71258Z",
                                          fill:
                                            _vm.statistic.traffic > 0
                                              ? "#369B58"
                                              : "#dd4a36",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "all_line" }, [
                  _c("div", { staticClass: "text_line" }, [
                    _c("div", { staticClass: "statistic-text" }, [
                      _c("span", {
                        staticStyle: { "font-weight": "500" },
                        domProps: {
                          textContent: _vm._s(_vm.$t("branch_food_expenses")),
                        },
                      }),
                    ]),
                    _c(
                      "div",
                      { staticClass: "statistic-amount" },
                      [
                        _vm.statistic.branch_food_expenses !== null
                          ? _c("format-currency", {
                              staticClass: "total",
                              attrs: {
                                data: _vm.statistic.branch_food_expenses,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "all_line" }, [
                  _c("div", { staticClass: "text_line" }, [
                    _c("div", { staticClass: "statistic-text" }, [
                      _c("span", {
                        staticStyle: { "font-weight": "500" },
                        domProps: {
                          textContent: _vm._s(_vm.$t("branch_expenses")),
                        },
                      }),
                    ]),
                    _c(
                      "div",
                      { staticClass: "statistic-amount" },
                      [
                        _vm.statistic.branch_expenses !== null
                          ? _c("format-currency", {
                              staticClass: "total",
                              attrs: { data: _vm.statistic.branch_expenses },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "all_line" }, [
                  _c("div", { staticClass: "text_line" }, [
                    _c("div", { staticClass: "statistic-text" }, [
                      _c("span", {
                        staticStyle: { "font-weight": "500" },
                        domProps: { textContent: _vm._s(_vm.$t("expenses")) },
                      }),
                    ]),
                    _c(
                      "div",
                      { staticClass: "statistic-amount" },
                      [
                        _vm.statistic.expenses !== null
                          ? _c("format-currency", {
                              staticClass: "total",
                              attrs: { data: _vm.statistic.expenses },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }