var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "users-policies" },
    [
      _c(
        "table-list",
        _vm._b(
          {
            on: { "on-click-line": _vm.tableItemClick },
            scopedSlots: _vm._u(
              [
                {
                  key: "btn-back",
                  fn: function () {
                    return [
                      _c(
                        "router-link",
                        { staticClass: "page__back", attrs: { to: "/users" } },
                        [
                          _c("svg-icon", {
                            attrs: {
                              name: "ic-close-table-arrow",
                              width: "34",
                              height: "34",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                _vm.mobile
                  ? {
                      key: "above-tabel",
                      fn: function () {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "mobile-btn-plus",
                              on: { click: _vm.createPolicy },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-plus",
                                  width: "16",
                                  height: "16",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : {
                      key: "btn",
                      fn: function () {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn_pink",
                              on: {
                                click: function ($event) {
                                  return _vm.createPolicy()
                                },
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-plus",
                                  width: "16",
                                  height: "16",
                                },
                              }),
                              _c("span", {
                                staticClass: "xl-none",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("New Policy")),
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
              ],
              null,
              true
            ),
          },
          "table-list",
          _vm.dataTable,
          false
        )
      ),
      _vm.showPolicyEditDialog
        ? _c(
            "base-dialog",
            {
              attrs: { width: "840px", title: "Edit policy" },
              on: { close: _vm.closePolicyEditDialog },
            },
            [
              _c(
                "div",
                { staticClass: "popup__content" },
                [
                  _c("div", { staticClass: "form-site" }, [
                    _c("div", { staticClass: "form-site__field" }, [
                      _c(
                        "label",
                        {
                          staticClass: "form-site__label",
                          attrs: { for: "name" },
                        },
                        [
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Name")) },
                          }),
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentPolicy.name,
                            expression: "currentPolicy.name",
                          },
                        ],
                        staticClass: "form-site__input",
                        attrs: { type: "text", id: "name" },
                        domProps: { value: _vm.currentPolicy.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.currentPolicy,
                              "name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._l(_vm.keywords, function (keyword) {
                    return [
                      _c("policy-checkboxes", {
                        attrs: {
                          policy: _vm.currentPolicy.policy,
                          reports: _vm.reports,
                          keyword: keyword,
                        },
                        on: { "change-policy": _vm.changePolicy },
                      }),
                    ]
                  }),
                  _c("div", { staticClass: "edit-permissions__btn" }, [
                    _vm.isAdmin && _vm.currentPolicy.id
                      ? _c("button", {
                          staticClass: "btn btn_border",
                          domProps: { textContent: _vm._s(_vm.$t("Delete")) },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.deleteCurrentPolicy.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        })
                      : _c("div"),
                    _c(
                      "button",
                      {
                        staticClass: "btn",
                        attrs: { type: "button" },
                        on: { click: _vm.saveCurrentPolicy },
                      },
                      [
                        _c("span", {
                          domProps: { textContent: _vm._s(_vm.$t("Save")) },
                        }),
                      ]
                    ),
                  ]),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }