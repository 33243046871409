var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    [
      !!_vm.previewData
        ? _c("preview-report-dialog", {
            attrs: { data: _vm.previewData },
            on: {
              close: function ($event) {
                _vm.previewData = null
              },
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "reports" }, [
        _c("section", { staticClass: "container" }, [
          _c("div", { staticClass: "page" }, [
            _c("div", { staticClass: "page__main" }, [
              _c("div", { staticClass: "page__top" }, [
                _c("h1", {
                  domProps: { textContent: _vm._s(_vm.$t("Reports")) },
                }),
              ]),
              _c("div", { staticClass: "page__content1" }, [
                _c("div", { staticClass: "page__form-wrap" }, [
                  _c(
                    "div",
                    {
                      staticClass: "page__form",
                      staticStyle: {
                        "max-height": "100%",
                        position: "relative",
                      },
                    },
                    [
                      _vm.restaurant
                        ? _c(
                            "form",
                            {
                              staticClass: "form-site-wrap",
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "form-site",
                                  staticStyle: {
                                    "max-height": "100%",
                                    position: "relative",
                                    "padding-bottom": "100px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "form-site__content" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-site__field" },
                                        [
                                          _c("div", {
                                            staticClass: "form-site__label",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t("Select report type")
                                              ),
                                            },
                                          }),
                                          _c("select-box", {
                                            staticClass: "mie",
                                            attrs: {
                                              showSearch: "",
                                              widthHeader: "100%",
                                              width: "100%",
                                              options: _vm.getAttrs,
                                            },
                                            on: { input: _vm.loadReport },
                                            model: {
                                              value:
                                                _vm.selectedReport[
                                                  "requested_report"
                                                ],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.selectedReport,
                                                  "requested_report",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "selectedReport['requested_report']",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "columns-is-multiline" },
                                        [
                                          _vm._l(
                                            _vm.getSelectedFilters,
                                            function (reportFilter) {
                                              return reportFilter.type !==
                                                "bool" &&
                                                reportFilter.title !==
                                                  "export" &&
                                                reportFilter.title !== "view"
                                                ? _c(
                                                    "div",
                                                    {
                                                      key: reportFilter.title,
                                                      staticClass:
                                                        "form-site__field",
                                                    },
                                                    [
                                                      reportFilter.type !==
                                                        "button" &&
                                                      reportFilter.type !==
                                                        "bool" &&
                                                      reportFilter.title !==
                                                        "branches_ids" &&
                                                      reportFilter.title !==
                                                        "branches_groups"
                                                        ? _c("div", {
                                                            staticClass:
                                                              "form-site__label",
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    _vm.capitalize(
                                                                      reportFilter.title
                                                                    )
                                                                  )
                                                                ),
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      reportFilter.type ===
                                                        "select" &&
                                                      reportFilter.title ===
                                                        "frequency_type" &&
                                                      _vm.reload >= 1
                                                        ? _c("select-box", {
                                                            key: reportFilter.title,
                                                            staticClass:
                                                              "combo-box",
                                                            style: {
                                                              "margin-right":
                                                                "0px",
                                                            },
                                                            attrs: {
                                                              widthHeader:
                                                                "100%",
                                                              width: "100%",
                                                              error:
                                                                _vm.errors.includes(
                                                                  reportFilter.title
                                                                ),
                                                              unsort: "",
                                                              placeholder:
                                                                _vm.$t(
                                                                  "תדירות שלילית"
                                                                ),
                                                              options:
                                                                _vm.getFieldSelectAttrs(
                                                                  reportFilter.title,
                                                                  true
                                                                ),
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .selectedParams[
                                                                  reportFilter
                                                                    .title
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.selectedParams,
                                                                    reportFilter.title,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "selectedParams[reportFilter.title]",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      reportFilter.type ===
                                                        "select" &&
                                                      reportFilter.title !==
                                                        "frequency_type" &&
                                                      reportFilter.title !==
                                                        "employees_codes" &&
                                                      reportFilter.title !==
                                                        "payment_types" &&
                                                      reportFilter.title !==
                                                        "owners_codes" &&
                                                      reportFilter.title !==
                                                        "delivery_guys" &&
                                                      reportFilter.title !==
                                                        "report_type" &&
                                                      reportFilter.title !==
                                                        "refunding_employee_codes" &&
                                                      reportFilter.title !==
                                                        "company" &&
                                                      reportFilter.title !==
                                                        "branches_ids" &&
                                                      _vm.reload >= 1
                                                        ? _c("select-box", {
                                                            key: reportFilter.title,
                                                            staticClass:
                                                              "combo-box",
                                                            style: {
                                                              "margin-right":
                                                                "0px",
                                                            },
                                                            attrs: {
                                                              widthHeader:
                                                                "100%",
                                                              width: "100%",
                                                              error:
                                                                _vm.errors.includes(
                                                                  reportFilter.title
                                                                ),
                                                              unsort: "",
                                                              placeholder:
                                                                _vm.$t("All"),
                                                              options:
                                                                _vm.getFieldSelectAttrs(
                                                                  reportFilter.title,
                                                                  true
                                                                ),
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .selectedParams[
                                                                  reportFilter
                                                                    .title
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.selectedParams,
                                                                    reportFilter.title,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "selectedParams[reportFilter.title]",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      reportFilter.type ===
                                                        "select" &&
                                                      reportFilter.title !==
                                                        "frequency_type" &&
                                                      reportFilter.title ===
                                                        "report_type" &&
                                                      _vm.reload >= 1
                                                        ? _c("select-box", {
                                                            key: reportFilter.title,
                                                            staticClass:
                                                              "combo-box",
                                                            style: {
                                                              "margin-right":
                                                                "0px",
                                                            },
                                                            attrs: {
                                                              widthHeader:
                                                                "100%",
                                                              width: "100%",
                                                              error:
                                                                _vm.errors.includes(
                                                                  reportFilter.title
                                                                ),
                                                              unsort: "",
                                                              placeholder:
                                                                _vm.$t("All"),
                                                              options:
                                                                _vm.getFieldSelectAttrs(
                                                                  reportFilter.title,
                                                                  true
                                                                ),
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .selectedParams[
                                                                  reportFilter
                                                                    .title
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.selectedParams,
                                                                    reportFilter.title,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "selectedParams[reportFilter.title]",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      reportFilter.type ===
                                                        "select" &&
                                                      reportFilter.title !==
                                                        "frequency_type" &&
                                                      reportFilter.title ===
                                                        "company" &&
                                                      _vm.reload >= 1
                                                        ? _c("select-box", {
                                                            key: reportFilter.title,
                                                            staticClass:
                                                              "combo-box",
                                                            attrs: {
                                                              widthHeader:
                                                                "100%",
                                                              width: "100%",
                                                              error:
                                                                _vm.errors.includes(
                                                                  reportFilter.title
                                                                ),
                                                              unsort: "",
                                                              placeholder:
                                                                _vm.$t("All"),
                                                              options:
                                                                _vm.getFieldSelectAttrs(
                                                                  reportFilter.options_in,
                                                                  true
                                                                ),
                                                            },
                                                            on: {
                                                              input: _vm.input,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .selectedParams[
                                                                  reportFilter
                                                                    .options_in
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.selectedParams,
                                                                    reportFilter.options_in,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "selectedParams[reportFilter.options_in]",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      reportFilter.type ===
                                                        "multi_select" &&
                                                      reportFilter.title !==
                                                        "branches_groups" &&
                                                      reportFilter.title !==
                                                        "payment_types" &&
                                                      reportFilter.title !==
                                                        "branches_ids" &&
                                                      reportFilter.title !==
                                                        "employees_codes" &&
                                                      reportFilter.title !==
                                                        "owners_codes" &&
                                                      reportFilter.title !==
                                                        "delivery_guys" &&
                                                      reportFilter.title !==
                                                        "frequency_type" &&
                                                      reportFilter.title !==
                                                        "refunding_employee_codes" &&
                                                      _vm.reload >= 1
                                                        ? _c("select-box", {
                                                            key: reportFilter.title,
                                                            staticClass:
                                                              "combo-box",
                                                            attrs: {
                                                              widthHeader:
                                                                "100%",
                                                              width: "100%",
                                                              error:
                                                                _vm.errors.includes(
                                                                  reportFilter.title
                                                                ),
                                                              showSearch: "",
                                                              unsort: "",
                                                              placeholder:
                                                                _vm.$t("All"),
                                                              options:
                                                                _vm.getFieldSelectAttrs(
                                                                  reportFilter.options_in,
                                                                  true
                                                                ),
                                                            },
                                                            on: {
                                                              input: _vm.input,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .selectedParams[
                                                                  reportFilter
                                                                    .title
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.selectedParams,
                                                                    reportFilter.title,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "selectedParams[reportFilter.title]",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      reportFilter.title ===
                                                      "branches_ids"
                                                        ? _c("div", [
                                                            _vm.selectedParams
                                                              .branches === 1
                                                              ? _c(
                                                                  "div",
                                                                  [
                                                                    _c("div", {
                                                                      staticClass:
                                                                        "form-site__label",
                                                                      domProps:
                                                                        {
                                                                          textContent:
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                _vm.capitalize(
                                                                                  reportFilter.title
                                                                                )
                                                                              )
                                                                            ),
                                                                        },
                                                                    }),
                                                                    reportFilter.type ===
                                                                      "select" &&
                                                                    reportFilter.title !==
                                                                      "frequency_type"
                                                                      ? _c(
                                                                          "select-box",
                                                                          {
                                                                            staticClass:
                                                                              "combo-box",
                                                                            attrs:
                                                                              {
                                                                                widthHeader:
                                                                                  "100%",
                                                                                width:
                                                                                  "100%",
                                                                                error:
                                                                                  _vm.errors.includes(
                                                                                    reportFilter.title
                                                                                  ),
                                                                                placeholder:
                                                                                  _vm.$t(
                                                                                    "All"
                                                                                  ),
                                                                                showSearch:
                                                                                  "",
                                                                                options:
                                                                                  _vm.getFieldSelectAttrs(
                                                                                    reportFilter.options_in,
                                                                                    true
                                                                                  ),
                                                                              },
                                                                            on: {
                                                                              input:
                                                                                _vm.input,
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .selectedParams[
                                                                                    reportFilter
                                                                                      .title
                                                                                  ],
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.selectedParams,
                                                                                      reportFilter.title,
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "selectedParams[reportFilter.title]",
                                                                              },
                                                                          }
                                                                        )
                                                                      : reportFilter.type ===
                                                                        "multi_select"
                                                                      ? _c(
                                                                          "select-box",
                                                                          {
                                                                            staticClass:
                                                                              "combo-box",
                                                                            attrs:
                                                                              {
                                                                                widthHeader:
                                                                                  "100%",
                                                                                width:
                                                                                  "100%",
                                                                                error:
                                                                                  _vm.errors.includes(
                                                                                    reportFilter.title
                                                                                  ),
                                                                                placeholder:
                                                                                  _vm.$t(
                                                                                    "All"
                                                                                  ),
                                                                                showSearch:
                                                                                  "",
                                                                                options:
                                                                                  _vm.getFieldSelectAttrs(
                                                                                    reportFilter.options_in,
                                                                                    true
                                                                                  ),
                                                                              },
                                                                            on: {
                                                                              input:
                                                                                _vm.input,
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .selectedParams[
                                                                                    reportFilter
                                                                                      .title
                                                                                  ],
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.selectedParams,
                                                                                      reportFilter.title,
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "selectedParams[reportFilter.title]",
                                                                              },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ])
                                                        : reportFilter.title ===
                                                          "branches_groups"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              style: {
                                                                "margin-inline-start":
                                                                  "-15px",
                                                              },
                                                            },
                                                            [
                                                              _vm.selectedParams
                                                                .branches === 2
                                                                ? _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "form-site__label",
                                                                          domProps:
                                                                            {
                                                                              textContent:
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    _vm.capitalize(
                                                                                      reportFilter.title
                                                                                    )
                                                                                  )
                                                                                ),
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "select-box",
                                                                        {
                                                                          staticClass:
                                                                            "combo-box",
                                                                          attrs:
                                                                            {
                                                                              widthHeader:
                                                                                "100%",
                                                                              width:
                                                                                "100%",
                                                                              error:
                                                                                _vm.errors.includes(
                                                                                  reportFilter.title
                                                                                ),
                                                                              placeholder:
                                                                                _vm.$t(
                                                                                  "All"
                                                                                ),
                                                                              options:
                                                                                _vm.getFieldSelectAttrs(
                                                                                  reportFilter.options_in,
                                                                                  true
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              _vm.input,
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .selectedParams[
                                                                                  reportFilter
                                                                                    .title
                                                                                ],
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.selectedParams,
                                                                                    reportFilter.title,
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "selectedParams[reportFilter.title]",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      reportFilter.type ===
                                                      "date"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "dates",
                                                            },
                                                            [
                                                              reportFilter.type ===
                                                                "date" &&
                                                              reportFilter.title ===
                                                                "start_date"
                                                                ? _c(
                                                                    "form-site-field-date",
                                                                    {
                                                                      staticClass:
                                                                        "date-field",
                                                                      attrs: {
                                                                        error:
                                                                          _vm.errors.includes(
                                                                            reportFilter.title
                                                                          ),
                                                                        format:
                                                                          reportFilter.format,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .selectedParams[
                                                                            reportFilter
                                                                              .title
                                                                          ],
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.selectedParams,
                                                                              reportFilter.title,
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "selectedParams[reportFilter.title]",
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              reportFilter.type ===
                                                                "date" &&
                                                              reportFilter.title ===
                                                                "end_date"
                                                                ? _c(
                                                                    "form-site-field-date",
                                                                    {
                                                                      staticClass:
                                                                        "date-field",
                                                                      attrs: {
                                                                        error:
                                                                          _vm.errors.includes(
                                                                            reportFilter.title
                                                                          ),
                                                                        format:
                                                                          reportFilter.format,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .selectedParams[
                                                                            reportFilter
                                                                              .title
                                                                          ],
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.selectedParams,
                                                                              reportFilter.title,
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "selectedParams[reportFilter.title]",
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      reportFilter.type ===
                                                        "time" &&
                                                      reportFilter.title ===
                                                        "start_hour"
                                                        ? _c("select-box", {
                                                            ref: "timepicker",
                                                            refInFor: true,
                                                            staticClass:
                                                              "t-center timepicker1",
                                                            attrs: {
                                                              width: "100%",
                                                              autocomplete: "",
                                                              options:
                                                                _vm.listHours,
                                                              placeholder:
                                                                "00:00",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .selectedParams[
                                                                  reportFilter
                                                                    .title
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.selectedParams,
                                                                    reportFilter.title,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "selectedParams[reportFilter.title]",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      reportFilter.type ===
                                                        "time" &&
                                                      reportFilter.title ===
                                                        "end_hour"
                                                        ? _c("select-box", {
                                                            ref: "timepicker2",
                                                            refInFor: true,
                                                            staticClass:
                                                              "t-center timepicker2",
                                                            attrs: {
                                                              width: "100%",
                                                              autocomplete: "",
                                                              options:
                                                                _vm.listHours,
                                                              placeholder:
                                                                "00:00",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .selectedParams[
                                                                  reportFilter
                                                                    .title
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.selectedParams,
                                                                    reportFilter.title,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "selectedParams[reportFilter.title]",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      reportFilter.title ===
                                                        "employees_codes" ||
                                                      (reportFilter.title ===
                                                        "delivery_guys" &&
                                                        reportFilter.title !==
                                                          "payment_types" &&
                                                        reportFilter.title !==
                                                          "frequency_type" &&
                                                        _vm.reload >= 1)
                                                        ? _c("select-box", {
                                                            key: reportFilter.title,
                                                            staticClass:
                                                              "combo-box",
                                                            attrs: {
                                                              widthHeader:
                                                                "100%",
                                                              width: "100%",
                                                              error:
                                                                _vm.errors.includes(
                                                                  reportFilter.title
                                                                ),
                                                              unsort: "",
                                                              placeholder:
                                                                _vm.$t("All"),
                                                              showSearch: "",
                                                              options:
                                                                _vm.getFieldSelectAttrs(
                                                                  reportFilter.options_in,
                                                                  true
                                                                ),
                                                            },
                                                            on: {
                                                              input: _vm.input,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .selectedParams[
                                                                  reportFilter
                                                                    .title
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.selectedParams,
                                                                    reportFilter.title,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "selectedParams[reportFilter.title]",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      reportFilter.title ===
                                                        "payment_types" &&
                                                      reportFilter.title !==
                                                        "frequency_type" &&
                                                      _vm.reload >= 1
                                                        ? _c("select-box", {
                                                            key: reportFilter.title,
                                                            staticClass:
                                                              "combo-box",
                                                            attrs: {
                                                              widthHeader:
                                                                "100%",
                                                              width: "100%",
                                                              error:
                                                                _vm.errors.includes(
                                                                  reportFilter.title
                                                                ),
                                                              unsort: "",
                                                              placeholder:
                                                                _vm.$t("All"),
                                                              options:
                                                                _vm.getFieldSelectAttrs(
                                                                  reportFilter.title,
                                                                  true
                                                                ),
                                                            },
                                                            on: {
                                                              input: _vm.input,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .selectedParams[
                                                                  reportFilter
                                                                    .title
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.selectedParams,
                                                                    reportFilter.title,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "selectedParams[reportFilter.title]",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      reportFilter.type ===
                                                        "multi_select" &&
                                                      reportFilter.title ===
                                                        "owners_codes" &&
                                                      reportFilter.title !==
                                                        "refunding_employee_codes" &&
                                                      reportFilter.title !==
                                                        "payment_types" &&
                                                      reportFilter.title !==
                                                        "frequency_type" &&
                                                      _vm.reload >= 1
                                                        ? _c("select-box", {
                                                            key: reportFilter.title,
                                                            staticClass:
                                                              "combo-box",
                                                            attrs: {
                                                              widthHeader:
                                                                "100%",
                                                              width: "100%",
                                                              error:
                                                                _vm.errors.includes(
                                                                  reportFilter.title
                                                                ),
                                                              unsort: "",
                                                              placeholder:
                                                                _vm.$t("All"),
                                                              options:
                                                                _vm.getFieldSelectAttrs(
                                                                  reportFilter.options_in,
                                                                  true
                                                                ),
                                                            },
                                                            on: {
                                                              input: _vm.input,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .selectedParams[
                                                                  reportFilter
                                                                    .title
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.selectedParams,
                                                                    reportFilter.title,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "selectedParams[reportFilter.title]",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      reportFilter.type ===
                                                        "multi_select" &&
                                                      reportFilter.title ===
                                                        "refunding_employee_codes" &&
                                                      reportFilter.title !==
                                                        "owners_codes" &&
                                                      reportFilter.title !==
                                                        "payment_types" &&
                                                      reportFilter.title !==
                                                        "frequency_type" &&
                                                      _vm.reload >= 1
                                                        ? _c("select-box", {
                                                            key: reportFilter.title,
                                                            staticClass:
                                                              "combo-box",
                                                            attrs: {
                                                              widthHeader:
                                                                "100%",
                                                              width: "100%",
                                                              error:
                                                                _vm.errors.includes(
                                                                  reportFilter.title
                                                                ),
                                                              unsort: "",
                                                              placeholder:
                                                                _vm.$t("All"),
                                                              options:
                                                                _vm.getFieldSelectAttrs(
                                                                  reportFilter.options_in,
                                                                  true
                                                                ),
                                                            },
                                                            on: {
                                                              input: _vm.input,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .selectedParams[
                                                                  reportFilter
                                                                    .title
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.selectedParams,
                                                                    reportFilter.title,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "selectedParams[reportFilter.title]",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      reportFilter.type ===
                                                        "input" &&
                                                      reportFilter.title ==
                                                        "from_amount"
                                                        ? _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .selectedParams[
                                                                    reportFilter
                                                                      .title
                                                                  ],
                                                                expression:
                                                                  "selectedParams[reportFilter.title]",
                                                              },
                                                            ],
                                                            key: reportFilter.title,
                                                            staticClass:
                                                              "salary",
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm
                                                                  .selectedParams[
                                                                  reportFilter
                                                                    .title
                                                                ],
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                ) {
                                                                  return
                                                                }
                                                                _vm.$set(
                                                                  _vm.selectedParams,
                                                                  reportFilter.title,
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      reportFilter.type ===
                                                        "input" &&
                                                      reportFilter.title ==
                                                        "min_salary"
                                                        ? _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .selectedParams[
                                                                    reportFilter
                                                                      .title
                                                                  ],
                                                                expression:
                                                                  "selectedParams[reportFilter.title]",
                                                              },
                                                            ],
                                                            key: reportFilter.title,
                                                            staticClass:
                                                              "salary",
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm
                                                                  .selectedParams[
                                                                  reportFilter
                                                                    .title
                                                                ],
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                ) {
                                                                  return
                                                                }
                                                                _vm.$set(
                                                                  _vm.selectedParams,
                                                                  reportFilter.title,
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      reportFilter.type ===
                                                        "input" &&
                                                      reportFilter.title ==
                                                        "max_salary"
                                                        ? _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .selectedParams[
                                                                    reportFilter
                                                                      .title
                                                                  ],
                                                                expression:
                                                                  "selectedParams[reportFilter.title]",
                                                              },
                                                            ],
                                                            key: reportFilter.title,
                                                            staticClass:
                                                              "salary",
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm
                                                                  .selectedParams[
                                                                  reportFilter
                                                                    .title
                                                                ],
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                ) {
                                                                  return
                                                                }
                                                                _vm.$set(
                                                                  _vm.selectedParams,
                                                                  reportFilter.title,
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      reportFilter.type ===
                                                        "input" &&
                                                      reportFilter.title ==
                                                        "to_amount"
                                                        ? _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .selectedParams[
                                                                    reportFilter
                                                                      .title
                                                                  ],
                                                                expression:
                                                                  "selectedParams[reportFilter.title]",
                                                              },
                                                            ],
                                                            key: reportFilter.title,
                                                            staticClass:
                                                              "salary",
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm
                                                                  .selectedParams[
                                                                  reportFilter
                                                                    .title
                                                                ],
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                ) {
                                                                  return
                                                                }
                                                                _vm.$set(
                                                                  _vm.selectedParams,
                                                                  reportFilter.title,
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _vm.errors.includes(
                                                        reportFilter.options_in
                                                      )
                                                        ? _c("span", {
                                                            staticStyle: {
                                                              color: "red",
                                                              "font-size":
                                                                "14px",
                                                              "margin-inline-start":
                                                                "8px",
                                                            },
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "Required field"
                                                                  )
                                                                ),
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            }
                                          ),
                                          _vm._l(
                                            _vm.getSelectedFilters,
                                            function (reportFilter) {
                                              return reportFilter.type ===
                                                "bool"
                                                ? _c(
                                                    "div",
                                                    {
                                                      key: reportFilter.title,
                                                      staticClass:
                                                        "form-site__field field_checkboxes",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-site__field_checkboxes",
                                                        },
                                                        [
                                                          reportFilter.type ===
                                                          "bool"
                                                            ? _c(
                                                                "checkbox",
                                                                {
                                                                  staticClass:
                                                                    "checkboxes",
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .selectedParams[
                                                                        reportFilter
                                                                          .title
                                                                      ],
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.selectedParams,
                                                                          reportFilter.title,
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "selectedParams[reportFilter.title]",
                                                                  },
                                                                },
                                                                [
                                                                  reportFilter.type ===
                                                                  "bool"
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "form-site__label",
                                                                          domProps:
                                                                            {
                                                                              textContent:
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    _vm.capitalize(
                                                                                      reportFilter.title
                                                                                    )
                                                                                  )
                                                                                ),
                                                                            },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e()
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "buttons" }, [
                                    _vm.showExportButton
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn btn_red",
                                            attrs: { id: "b1" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.save()
                                              },
                                            },
                                          },
                                          [
                                            _c("span", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    _vm.capitalize("Export")
                                                  )
                                                ),
                                              },
                                            }),
                                            _c("svg-icon", {
                                              staticClass: "white",
                                              style: { "margin-top": "0px" },
                                              attrs: {
                                                name: "ic-arrow-right",
                                                width: "16",
                                                height: "12",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.showViewButton
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn btn_border",
                                            on: {
                                              click: function ($event) {
                                                return _vm.preview()
                                              },
                                            },
                                          },
                                          [
                                            _c("span", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(_vm.capitalize("View"))
                                                ),
                                              },
                                            }),
                                            _c("svg-icon", {
                                              staticClass: "ic-white",
                                              style: { "margin-top": "0px" },
                                              attrs: {
                                                name: "ic-arrow-right",
                                                width: "16",
                                                height: "12",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.restaurant === null &&
                      _vm.$store.getters.isAdmin === true
                        ? _c(
                            "form",
                            {
                              staticClass: "form-site-wrap",
                              style: {
                                display: "flex",
                                "flex-direction": "column",
                                gap: "30px",
                                width: "100%",
                                "justify-content": "center",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "operationals-report-title" },
                                [
                                  _c("div", {
                                    staticClass: "select__head",
                                    style: {
                                      "max-width": "100%",
                                      "justify-content": "center",
                                      display: "flex",
                                    },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Internal operational report")
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _c("div", { staticClass: "form-site__field" }, [
                                _c("div", {
                                  staticClass: "form-site__label",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Select month")),
                                  },
                                }),
                                _c(
                                  "div",
                                  [
                                    _c("select-box", {
                                      key: _vm.monthsForReport,
                                      staticClass: "select_month",
                                      attrs: {
                                        unsort: "",
                                        showSearch: "",
                                        widthHeader: "100%",
                                        width: "100%",
                                        options: _vm.monthsToShow,
                                      },
                                      model: {
                                        value: _vm.monthsForReport,
                                        callback: function ($$v) {
                                          _vm.monthsForReport = $$v
                                        },
                                        expression: "monthsForReport",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "buttons" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn",
                                    attrs: { id: "b1" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.export_internal_report()
                                      },
                                    },
                                  },
                                  [
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t(_vm.capitalize("Export"))
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }