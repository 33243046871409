var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.show
        ? _c(
            "base-dialog",
            {
              attrs: { width: "500px", title: "New order" },
              on: { close: _vm.close },
            },
            [
              _c("div", { staticClass: "popup__content" }, [
                _c("div", [
                  _vm.order && _vm.order.unique_id
                    ? _c("p", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("Order") + "" + _vm.order.unique_id
                          ),
                        },
                      })
                    : _vm._e(),
                  _vm.order && _vm.order.user
                    ? _c("p", {
                        domProps: { textContent: _vm._s(_vm.order.user.name) },
                      })
                    : _vm._e(),
                  _vm.order && _vm.order.user
                    ? _c("p", {
                        domProps: { textContent: _vm._s(_vm.order.user.phone) },
                      })
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "form-site__btn" }, [
                  _c("button", {
                    staticClass: "btn",
                    attrs: { type: "button" },
                    domProps: { textContent: _vm._s(_vm.$t("Ok")) },
                    on: { click: _vm.close },
                  }),
                  _c("span", {
                    staticStyle: { width: "20px", display: "inline-block" },
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn_border-grad",
                      attrs: { type: "button" },
                      on: { click: _vm.openOrderDetails },
                    },
                    [
                      _c("span", {
                        staticClass: "btn__grad-content",
                        domProps: { textContent: _vm._s(_vm.$t("Show order")) },
                      }),
                    ]
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm.show_order_details
        ? _c("order-details-popup", {
            attrs: { order: _vm.order, show: _vm.show_order_details },
            on: { closed: _vm.closeOrderDetails },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }