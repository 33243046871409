var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "btn-icon btn-icon_light-red",
      class: { "only-icon": _vm.onlyIcon },
      style: _vm.btnStyle,
      attrs: { type: "button" },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.$emit("click")
        },
      },
    },
    [
      _vm.onlyIcon
        ? [
            _c("svg-icon", {
              attrs: { name: "ic-bin", width: "16", height: "16" },
            }),
          ]
        : [
            _c(
              "span",
              { staticClass: "btn-icon__note" },
              [
                _c("svg-icon", {
                  attrs: { name: "ic-bin", width: "18", height: "20" },
                }),
              ],
              1
            ),
            _c("span", {
              staticClass: "btn-icon__text flex a-center",
              domProps: { textContent: _vm._s(_vm.$t(_vm.text)) },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }