var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "call-logs" }, [
    !_vm.mobile
      ? _c(
          "div",
          [
            _c(
              "table-list",
              _vm._b(
                {
                  on: { changeOptions: _vm.changeOptions },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "filter-by-date",
                        fn: function () {
                          return [
                            _vm.isManagerOrAdmin
                              ? _c("select-box", {
                                  attrs: {
                                    options: _vm.users,
                                    "empty-option": _vm.$t("Select"),
                                    maxWidth: "200px",
                                  },
                                  model: {
                                    value: _vm.selectedUser,
                                    callback: function ($$v) {
                                      _vm.selectedUser = $$v
                                    },
                                    expression: "selectedUser",
                                  },
                                })
                              : _vm._e(),
                            _c("filter-by-date", {
                              model: {
                                value: _vm.dateFilter,
                                callback: function ($$v) {
                                  _vm.dateFilter = $$v
                                },
                                expression: "dateFilter",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2409804083
                  ),
                },
                "table-list",
                _vm.tableData,
                false
              )
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.mobile
      ? _c(
          "div",
          [
            _c(
              "table-list",
              _vm._b(
                {
                  on: { changeOptions: _vm.changeOptions },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "filter-by-date",
                        fn: function () {
                          return [
                            _c("filter-by-date", {
                              model: {
                                value: _vm.dateFilter,
                                callback: function ($$v) {
                                  _vm.dateFilter = $$v
                                },
                                expression: "dateFilter",
                              },
                            }),
                            _vm.isManagerOrAdmin
                              ? _c("select-box", {
                                  attrs: {
                                    options: _vm.users,
                                    "empty-option": _vm.$t("Select"),
                                    maxWidth: "200px",
                                  },
                                  model: {
                                    value: _vm.selectedUser,
                                    callback: function ($$v) {
                                      _vm.selectedUser = $$v
                                    },
                                    expression: "selectedUser",
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    4015492819
                  ),
                },
                "table-list",
                _vm.tableData,
                false
              )
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }