var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    { attrs: { overflowVisible: "" }, on: { close: _vm.close } },
    [
      _c(
        "div",
        { staticClass: "popup__icon" },
        [
          _c("svg-icon", {
            staticClass: "piggy-bank",
            attrs: { width: "72", height: "72", name: "piggy-bank" },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "popup__content" }, [
        _c(
          "div",
          { staticClass: "form-choose" },
          [
            _c("refund-delivery-details", {
              attrs: {
                delivery_guy_name: _vm.user.name,
                deliveries: _vm.unresolvedDeliveries(),
              },
              on: { goBack: _vm.goBack },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }