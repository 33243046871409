var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    { attrs: { title: "POS Test", width: "840px" }, on: { close: _vm.close } },
    [
      _c("div", { staticClass: "popup__content" }, [
        _c("div", { staticClass: "form-new-user" }, [
          _c(
            "form",
            {
              staticClass: "edit-permissions-wrap",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "edit-permissions" },
                [
                  _c("order-category-items-list", {
                    model: {
                      value: _vm.items,
                      callback: function ($$v) {
                        _vm.items = $$v
                      },
                      expression: "items",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "edit-permissions__btn flex bet" }, [
                _c("button", {
                  staticClass: "btn btn_border",
                  domProps: { textContent: _vm._s(_vm.$t("Cancel")) },
                  on: { click: _vm.close },
                }),
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    attrs: { type: "submit" },
                    on: { click: _vm.send },
                  },
                  [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.$t("Send")) },
                    }),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }