var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("base-dialog", { on: { close: _vm.close } }, [
        _vm.profile
          ? _c("div", { staticClass: "popup__content pb40" }, [
              _c("div", { staticClass: "user-info" }, [
                _c("div", { staticClass: "btn-actives d-flex d-md-none" }, [
                  _c(
                    "button",
                    { staticClass: "mb16", on: { click: _vm.logout } },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "40",
                            height: "40",
                            viewBox: "0 0 40 40",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("circle", {
                            attrs: {
                              cx: "20",
                              cy: "20",
                              r: "20",
                              fill: "#F6DBE4",
                            },
                          }),
                          _c("g", [
                            _c("path", {
                              staticClass: "second-fill",
                              attrs: {
                                d: "M26.0416 11.25H17.2916C16.4862 11.25 15.8333 11.9029 15.8333 12.7083V15.2604C15.8333 15.4618 15.9965 15.625 16.1978 15.625H16.927C17.1284 15.625 17.2916 15.4618 17.2916 15.2604V12.7083H26.0416V27.2917H17.2916V24.7396C17.2916 24.5382 17.1284 24.375 16.927 24.375H16.1978C15.9965 24.375 15.8333 24.5382 15.8333 24.7396V27.2917C15.8333 28.0971 16.4862 28.75 17.2916 28.75H26.0416C26.847 28.75 27.4999 28.0971 27.4999 27.2917V12.7083C27.4999 11.9029 26.847 11.25 26.0416 11.25Z",
                              },
                            }),
                            _c("path", {
                              staticClass: "second-fill",
                              attrs: {
                                d: "M18.1415 23.0102C18.0646 23.0792 18.0208 23.1778 18.0208 23.2811V24.0103C18.0208 24.1548 18.1063 24.2858 18.2387 24.3438C18.2857 24.3649 18.3359 24.3748 18.3854 24.3748C18.4748 24.3748 18.5631 24.3417 18.6318 24.279L23.0068 20.2686C23.0823 20.1996 23.125 20.102 23.125 19.9999C23.125 19.8977 23.0823 19.8001 23.0068 19.731L18.6318 15.7206C18.525 15.6234 18.3701 15.5967 18.2387 15.6558C18.1063 15.7139 18.0208 15.8449 18.0208 15.9894V16.7186C18.0208 16.8219 18.0646 16.9205 18.1415 16.9895L20.676 19.2707H10.3646C10.1631 19.2707 10 19.4338 10 19.6353V20.3644C10 20.5659 10.1631 20.729 10.3646 20.729H20.676L18.1415 23.0102Z",
                              },
                            }),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.changeLocale(
                            _vm.$i18n.locale === "he" ? "en" : "he"
                          )
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "pointer opacity-hover-8",
                        attrs: { src: _vm.imgFlagUrl, alt: "Change language" },
                      }),
                    ]
                  ),
                ]),
              ]),
              _c(
                "form",
                {
                  staticClass: "form-site-wrap",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "form-site" }, [
                    _c("div", { staticClass: "form-site__content" }, [
                      _c(
                        "div",
                        { staticClass: "form-site__field-wrap" },
                        [
                          _c("form-site-field-text", {
                            attrs: { title: "First name" },
                            model: {
                              value: _vm.profileCopy.first_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.profileCopy, "first_name", $$v)
                              },
                              expression: "profileCopy.first_name",
                            },
                          }),
                          _c("form-site-field-text", {
                            attrs: { title: "Last name" },
                            model: {
                              value: _vm.profileCopy.last_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.profileCopy, "last_name", $$v)
                              },
                              expression: "profileCopy.last_name",
                            },
                          }),
                          _c("form-site-field-text", {
                            attrs: { title: "Email" },
                            model: {
                              value: _vm.profileCopy.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.profileCopy, "email", $$v)
                              },
                              expression: "profileCopy.email",
                            },
                          }),
                          _c("form-site-field-text", {
                            attrs: { title: "Phone number", disabled: "" },
                            model: {
                              value: _vm.profileCopy.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.profileCopy, "phone", $$v)
                              },
                              expression: "profileCopy.phone",
                            },
                          }),
                          _c("div", { staticClass: "form-site__field" }, [
                            _c(
                              "button",
                              {
                                staticClass: "view-pass-btn mie8",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                  },
                                  mousedown: _vm.viewPass,
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    name: "eye",
                                    width: "22",
                                    height: "22",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("span", {
                              staticClass: "form-site__label",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Password")),
                              },
                            }),
                            _c("div", { staticClass: "form-site__input" }, [
                              _vm.isPass
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.profileCopy.password,
                                        expression: "profileCopy.password",
                                      },
                                    ],
                                    staticClass: "text-center",
                                    attrs: { dir: "ltr", type: "password" },
                                    domProps: {
                                      value: _vm.profileCopy.password,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.profileCopy,
                                          "password",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  })
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.profileCopy.password,
                                        expression: "profileCopy.password",
                                      },
                                    ],
                                    staticClass: "text-center",
                                    attrs: { dir: "ltr", type: "text" },
                                    domProps: {
                                      value: _vm.profileCopy.password,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.profileCopy,
                                          "password",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "form-site__field form-site__field_mob-full",
                            },
                            [
                              _c("label", {
                                staticClass: "form-site__label",
                                attrs: { for: "notification_sound" },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("New order sound")
                                  ),
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "form-site__input" },
                                [
                                  _c("select-box", {
                                    attrs: {
                                      id: "notification_sound",
                                      fixed: true,
                                      fixedleft: true,
                                      maxHeight: "300px",
                                      placeholder: _vm.$t("None sound"),
                                      options: _vm.notificationSounds,
                                    },
                                    on: { input: _vm.notificationSoundChange },
                                    model: {
                                      value: _vm.notificationSoundFile,
                                      callback: function ($$v) {
                                        _vm.notificationSoundFile = $$v
                                      },
                                      expression: "notificationSoundFile",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "label",
                            { staticClass: "form-site__field checkbox-label" },
                            [
                              _c("checkbox", {
                                attrs: { name: "showClientOnIncomingCall" },
                                model: {
                                  value: _vm.showClientOnIncomingCall,
                                  callback: function ($$v) {
                                    _vm.showClientOnIncomingCall = $$v
                                  },
                                  expression: "showClientOnIncomingCall",
                                },
                              }),
                              _c("span", {
                                staticClass: "form-site__label",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("Auto show client on incoming call")
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "form-site__btn md-none flex j-end" },
                      [
                        _c("button", {
                          staticClass: "btn",
                          attrs: { type: "submit" },
                          domProps: { textContent: _vm._s(_vm.$t("Save")) },
                        }),
                      ]
                    ),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "save-btn d-none md-flex",
                      on: { click: _vm.save },
                    },
                    [
                      _c("svg-icon", {
                        staticClass: "ic-edit",
                        attrs: {
                          name: "ic-save-btn",
                          width: "93",
                          height: "93",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ])
          : _c("p", {
              domProps: { textContent: _vm._s(_vm.$t("Profile not loaded")) },
            }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }