var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.disabled
    ? _c(
        "button",
        {
          staticClass: "edit-btn",
          class: _vm.className,
          style: _vm.styleBtn,
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.$emit("click")
            },
          },
        },
        [
          _c("svg-icon", {
            attrs: {
              name: "ic-edit",
              width: _vm.size / 2.5,
              height: _vm.size / 2.5,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }