var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    { attrs: { title: "Positions" }, on: { close: _vm.close } },
    [
      _c(
        "div",
        { staticClass: "popup__content", staticStyle: { height: "800px" } },
        [
          _c("div", { staticClass: "form-new-user" }, [
            _c(
              "form",
              {
                staticClass: "form-site-wrap",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.save.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "form-site" },
                  [
                    _c("div", { staticClass: "new-position" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newPositionText,
                            expression: "newPositionText",
                          },
                        ],
                        ref: "inp",
                        attrs: {
                          placeholder: _vm.$t("E.g. Chef"),
                          type: "text",
                        },
                        domProps: { value: _vm.newPositionText },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.newPositionText = $event.target.value
                          },
                        },
                      }),
                      _vm.canAddPositions
                        ? _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.addPosition.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "12",
                                    height: "12",
                                    viewBox: "0 0 12 12",
                                  },
                                },
                                [
                                  _c("rect", {
                                    attrs: {
                                      x: "5.25",
                                      width: "1.5",
                                      height: "12",
                                      rx: "0.75",
                                    },
                                  }),
                                  _c("rect", {
                                    attrs: {
                                      y: "6.75",
                                      width: "1.5",
                                      height: "12",
                                      rx: "0.75",
                                      transform: "rotate(-90 0 6.75)",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._l(_vm.positionsCopy, function (position, i) {
                      return _c(
                        "form",
                        {
                          key: position.id || "i" + i,
                          staticClass: "position",
                          on: {
                            click: function ($event) {
                              return _vm.setPositionInEdit(i)
                            },
                          },
                        },
                        [
                          position.inEdit
                            ? [
                                _c("div", { staticClass: "flex col p0" }, [
                                  _c("div", { staticClass: "flex bet w100" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: position.textInEdit,
                                          expression: "position.textInEdit",
                                        },
                                      ],
                                      ref: "refPosition" + i,
                                      refInFor: true,
                                      attrs: { type: "text" },
                                      domProps: { value: position.textInEdit },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            position,
                                            "textInEdit",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _vm.canEditPositions
                                      ? _c("button", {
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.saveposition(position)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "flex a-center j-end" },
                                      [
                                        _vm.canEditPositions
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn-green-small m0",
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.saveposition(
                                                      position
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "icon",
                                                    attrs: {
                                                      width: "16",
                                                      height: "12",
                                                      viewBox: "0 0 16 12",
                                                      fill: "none",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        d: "M15.2913 0.73686C14.9931 0.438625 14.5096 0.438625 14.2113 0.73686L5.06248 9.88579L1.54581 6.36913C1.24761 6.07089 0.76413 6.07092 0.465864 6.36913C0.167629 6.66733 0.167629 7.15081 0.465864 7.44904L4.5225 11.5056C4.82062 11.8038 5.30445 11.8036 5.60245 11.5056L15.2913 1.81681C15.5895 1.5186 15.5895 1.0351 15.2913 0.73686Z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "edit-fixed-permissions" },
                                    _vm._l(
                                      Object.keys(
                                        _vm.employeePermissionsTranslation
                                      ),
                                      function (index) {
                                        return _c(
                                          "label",
                                          {
                                            key: _vm
                                              .employeePermissionsTranslation[
                                              index
                                            ].value,
                                            staticClass:
                                              "permissions-position__item",
                                          },
                                          [
                                            _c("checkbox", {
                                              attrs: {
                                                name: _vm
                                                  .employeePermissionsTranslation[
                                                  index
                                                ].value,
                                                value: _vm.permissionValue(
                                                  +index,
                                                  position
                                                ),
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.inputPremission(
                                                    $event,
                                                    +index,
                                                    position
                                                  )
                                                },
                                              },
                                            }),
                                            _c("div", {
                                              staticClass:
                                                "permissions-position__text",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    _vm
                                                      .employeePermissionsTranslation[
                                                      index
                                                    ].translation
                                                  )
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ]
                            : [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(position.name),
                                  },
                                }),
                              ],
                        ],
                        2
                      )
                    }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }