var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      staticClass: "edit-barrage_gates-dialog",
      attrs: { "max-width": "700px", "min-height": "700px" },
      on: { close: _vm.close },
    },
    [
      _c("h1", {
        style: { display: "flex", "justify-content": "center" },
        domProps: { textContent: _vm._s(_vm.barrage.name) },
      }),
      _c("div", { staticClass: "edit-gates", style: { padding: "20px" } }, [
        _c("div", { staticClass: "gate-data", style: { display: "grid" } }, [
          _c("form", { staticClass: "form-site-wrap" }, [
            _c("div", { staticClass: "form-site" }, [
              _c("div", { staticClass: "form-site__content" }, [
                _c("div", { staticClass: "form-site__field-wrap" }, [
                  _c("div", { staticClass: "form-site__field" }, [
                    _c("div", {
                      staticClass: "form-site__label",
                      domProps: { textContent: _vm._s(_vm.$t("iso")) },
                    }),
                    _c("div", { staticClass: "form-site__input" }, [
                      _c("input", {
                        attrs: { type: "text", readonly: "" },
                        domProps: { value: _vm.barrage.iso },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-site__field" }, [
                    _c("div", {
                      staticClass: "form-site__label",
                      domProps: { textContent: _vm._s(_vm.$t("Name")) },
                    }),
                    _c("div", { staticClass: "form-site__input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.barrage.name,
                            expression: "barrage.name",
                          },
                        ],
                        attrs: { type: "text", readonly: "" },
                        domProps: { value: _vm.barrage.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.barrage, "name", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-site__field" }, [
                    _c("div", {
                      staticClass: "form-site__label",
                      domProps: { textContent: _vm._s(_vm.$t("branch_rate")) },
                    }),
                    _c("div", { staticClass: "form-site__input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.barrage.branch_rate,
                            expression: "barrage.branch_rate",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.barrage.branch_rate },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.barrage,
                              "branch_rate",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-site__field" }, [
                    _c("div", {
                      staticClass: "form-site__label",
                      domProps: { textContent: _vm._s(_vm.$t("rate")) },
                    }),
                    _c("div", { staticClass: "form-site__input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.barrage.rate,
                            expression: "barrage.rate",
                          },
                        ],
                        attrs: { type: "text", readonly: "" },
                        domProps: { value: _vm.barrage.rate },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.barrage, "rate", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "md-none",
                  style: {
                    display: "flex",
                    "justify-content": "space-around",
                    padding: "25px",
                  },
                },
                [
                  _c("button", {
                    staticClass: "btn btn_border",
                    domProps: { textContent: _vm._s(_vm.$t("Back")) },
                    on: { click: _vm.close },
                  }),
                  _c("button", {
                    staticClass: "btn",
                    domProps: { textContent: _vm._s(_vm.$t("save")) },
                    on: { click: _vm.save },
                  }),
                ]
              ),
              _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
                _c(
                  "button",
                  { staticClass: "save-btn", on: { click: _vm.save } },
                  [
                    _c("svg-icon", {
                      staticClass: "ic-edit",
                      attrs: { name: "ic-save-btn", width: "93", height: "93" },
                    }),
                  ],
                  1
                ),
                _c(
                  "button",
                  { staticClass: "bin-btn", on: { click: _vm.close } },
                  [
                    _c("svg-icon", {
                      attrs: { name: "ic-bin", width: "18", height: "20" },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }