var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      staticClass: "promotion-create-dialog",
      attrs: { title: _vm.addPromotionsTitle, width: "850px" },
      on: { close: _vm.close },
    },
    [
      _c("div", { staticClass: "popup__content" }, [
        _c("div", { staticClass: "choose-steps" }, [
          _c("div", { staticClass: "num-step" }, [
            _c(
              "div",
              {
                staticClass: "num-step__item",
                class: { active: _vm.step === 1 },
              },
              [_vm._v("1")]
            ),
            _c(
              "div",
              {
                staticClass: "num-step__item",
                class: { active: _vm.step === 2 },
              },
              [_vm._v("2")]
            ),
            _c(
              "div",
              {
                staticClass: "num-step__item",
                class: { active: _vm.step === 3 },
              },
              [_vm._v("3")]
            ),
            _c(
              "div",
              {
                staticClass: "num-step__item",
                class: { active: _vm.step === 4 },
              },
              [_vm._v("4")]
            ),
            _c(
              "div",
              {
                staticClass: "num-step__item",
                class: { active: _vm.step === 5 },
              },
              [_vm._v("5")]
            ),
            _c(
              "div",
              {
                staticClass: "num-step__item",
                class: { active: _vm.step === 6 },
              },
              [_vm._v("6")]
            ),
          ]),
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _vm.step === 1
                ? _c("div", { staticClass: "choose-promo flex col a-center" }, [
                    _c("div", { staticClass: "pic-check-list" }, [
                      _c("div", { staticClass: "pic-check" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.editPromotion.type,
                              expression: "editPromotion.type",
                            },
                          ],
                          attrs: { name: "choose", type: "radio", id: "deals" },
                          domProps: {
                            value: 0,
                            checked: _vm._q(_vm.editPromotion.type, 0),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.editPromotion, "type", 0)
                            },
                          },
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "pic-check__label",
                            style: {
                              backgroundImage:
                                "url(" +
                                require("@/assets/img/choose-promo1.jpg") +
                                ")",
                            },
                            attrs: { for: "deals", alt: "" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "pic-check__mark" },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    name: "ic-check",
                                    width: "16",
                                    height: "12",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "svg",
                              {
                                staticClass: "shape",
                                attrs: {
                                  width: "100",
                                  height: "62",
                                  viewBox: "0 0 100 62",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  staticClass: "main-fill",
                                  attrs: {
                                    d: "M0 0H100C100 0 89 6.98217 76 43.8879C63 80.7936 0 49.8726 0 49.8726V0Z",
                                  },
                                }),
                              ]
                            ),
                            _c("div", {
                              staticClass: "pic-check__title",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Deals")),
                              },
                            }),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "pic-check" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.editPromotion.type,
                              expression: "editPromotion.type",
                            },
                          ],
                          attrs: {
                            name: "choose",
                            type: "radio",
                            id: "coupons",
                          },
                          domProps: {
                            value: 1,
                            checked: _vm._q(_vm.editPromotion.type, 1),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.editPromotion, "type", 1)
                            },
                          },
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "pic-check__label",
                            style: {
                              backgroundImage:
                                "url(" +
                                require("@/assets/img/choose-promo2.jpg") +
                                ")",
                            },
                            attrs: { for: "coupons", alt: "" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "pic-check__mark" },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    name: "ic-check",
                                    width: "16",
                                    height: "12",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "svg",
                              {
                                staticClass: "shape",
                                attrs: {
                                  width: "112",
                                  height: "72",
                                  viewBox: "0 0 112 72",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  staticClass: "main-fill",
                                  attrs: {
                                    d: "M0 0H103.609C103.609 0 119.473 33.9718 107.575 45.1268C92.4327 59.3239 64.0305 49.5608 47.0951 52.7324C11.8977 59.3239 0 72 0 72V0Z",
                                  },
                                }),
                              ]
                            ),
                            _c("div", {
                              staticClass: "pic-check__title",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Coupons")),
                              },
                            }),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "pic-check" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.editPromotion.type,
                              expression: "editPromotion.type",
                            },
                          ],
                          attrs: {
                            name: "choose",
                            type: "radio",
                            id: "upsell",
                          },
                          domProps: {
                            value: 2,
                            checked: _vm._q(_vm.editPromotion.type, 2),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.editPromotion, "type", 2)
                            },
                          },
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "pic-check__label",
                            style: {
                              backgroundImage:
                                "url(" +
                                require("@/assets/img/choose-promo3.jpg") +
                                ")",
                            },
                            attrs: { for: "upsell", alt: "" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "pic-check__mark" },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    name: "ic-check",
                                    width: "16",
                                    height: "12",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "svg",
                              {
                                staticClass: "shape",
                                attrs: {
                                  width: "100",
                                  height: "62",
                                  viewBox: "0 0 100 62",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  staticClass: "main-fill",
                                  attrs: {
                                    d: "M0 0H100C100 0 89 6.98217 76 43.8879C63 80.7936 0 49.8726 0 49.8726V0Z",
                                  },
                                }),
                              ]
                            ),
                            _c("div", {
                              staticClass: "pic-check__title",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Upsell")),
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]),
                    _vm.editPromotion.type === 2
                      ? _c("div", { staticClass: "choose-promo__content" }, [
                          _c("div", { staticClass: "choose-promo__field" }, [
                            _c("div", {
                              staticClass: "choose-promo__label",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Appear")),
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "coupon-info-check" },
                              _vm._l(
                                [
                                  "Order start",
                                  "Meets buying conditions",
                                  "Payment page",
                                ],
                                function (name, i) {
                                  return _c(
                                    "div",
                                    {
                                      key: name,
                                      staticClass:
                                        "coupon-info-check__item wunset",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "input-check" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "input-radio" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.editPromotion
                                                        .appears_at,
                                                    expression:
                                                      "editPromotion.appears_at",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "radio",
                                                  name: "buyradio",
                                                  id: "radio" + name,
                                                },
                                                domProps: {
                                                  value: i,
                                                  checked: _vm._q(
                                                    _vm.editPromotion
                                                      .appears_at,
                                                    i
                                                  ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.$set(
                                                      _vm.editPromotion,
                                                      "appears_at",
                                                      i
                                                    )
                                                  },
                                                },
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "input-radio__label",
                                                  attrs: {
                                                    for: "radio" + name,
                                                  },
                                                },
                                                [
                                                  _c("span", {
                                                    staticClass:
                                                      "input-radio__mark",
                                                  }),
                                                  _c("span", {
                                                    staticClass:
                                                      "input-radio__text",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t(name)
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "choose-promo__btn" }, [
                      _c(
                        "button",
                        { staticClass: "btn", on: { click: _vm.nextStep } },
                        [
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Next")) },
                          }),
                          _c("svg-icon", {
                            staticClass: "ic-white",
                            attrs: {
                              name: "ic-arrow-right",
                              width: "16",
                              height: "12",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.step === 2
                ? _c("div", { staticClass: "coupon-info" }, [
                    _c("div", { staticClass: "coupon-info__content-wrap" }, [
                      _c(
                        "div",
                        {
                          staticClass: "coupon-info__field coupon-info__images",
                        },
                        [
                          _c("div", { staticClass: "coupon-info__img" }, [
                            _c("div", {
                              staticClass: "coupon-info__label",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Desktop picture")),
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "coupon-info__img",
                                class: {
                                  "coupon-info__empty": !_vm.desktop_picture,
                                },
                                style:
                                  "background-image: url(" +
                                  _vm.desktop_picture +
                                  ")",
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "coupon-info__img-change" },
                                  [
                                    _vm.desktop_picture
                                      ? _c(
                                          "button",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.removePicture(
                                                  "desktop_picture"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                name: "ic-plus",
                                                width: "22",
                                                height: "22",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : [
                                          _c("input", {
                                            attrs: { type: "file" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.enterImage(
                                                  $event,
                                                  "desktop_picture"
                                                )
                                              },
                                            },
                                          }),
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "ic-plus",
                                              width: "22",
                                              height: "22",
                                            },
                                          }),
                                        ],
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "coupon-info__img" }, [
                            _c("div", {
                              staticClass: "coupon-info__label",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Mobile picture")),
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "coupon-info__img",
                                class: {
                                  "coupon-info__empty": !_vm.mobile_picture,
                                },
                                style:
                                  "background-image: url(" +
                                  _vm.mobile_picture +
                                  ")",
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "coupon-info__img-change" },
                                  [
                                    _vm.mobile_picture
                                      ? _c(
                                          "button",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.removePicture(
                                                  "mobile_picture"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                name: "ic-plus",
                                                width: "22",
                                                height: "22",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : [
                                          _c("input", {
                                            attrs: { type: "file" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.enterImage(
                                                  $event,
                                                  "mobile_picture"
                                                )
                                              },
                                            },
                                          }),
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "ic-plus",
                                              width: "22",
                                              height: "22",
                                            },
                                          }),
                                        ],
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "coupon-info__top" }, [
                        _c("div", { staticClass: "coupon-info__field" }, [
                          _c("div", {
                            staticClass: "coupon-info__label",
                            domProps: { textContent: _vm._s(_vm.$t("Name")) },
                          }),
                          _c("div", { staticClass: "coupon-info__input" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.english,
                                  expression: "english",
                                },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.editPromotion.name_en,
                                  expression: "editPromotion.name_en",
                                },
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.editPromotion.name_en },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.editPromotion,
                                    "name_en",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("input", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.english,
                                  expression: "!english",
                                },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.editPromotion.name_he,
                                  expression: "editPromotion.name_he",
                                },
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.editPromotion.name_he },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.editPromotion,
                                    "name_he",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "lang" }, [
                          _c("button", {
                            staticClass: "lang__item",
                            class: { active: _vm.english },
                            domProps: {
                              textContent: _vm._s(_vm.$t("English")),
                            },
                            on: {
                              click: function ($event) {
                                _vm.english = true
                              },
                            },
                          }),
                          _c("button", {
                            staticClass: "lang__item",
                            class: { active: !_vm.english },
                            domProps: { textContent: _vm._s(_vm.$t("Hebrew")) },
                            on: {
                              click: function ($event) {
                                _vm.english = false
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "coupon-info__field" }, [
                        _c("div", { staticClass: "coupon-info__label-wrap" }, [
                          _c("div", {
                            staticClass: "coupon-info__label",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Description")),
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "coupon-info__msg" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.english,
                                expression: "english",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.editPromotion.description_en,
                                expression: "editPromotion.description_en",
                              },
                            ],
                            attrs: { placeholder: _vm.$t("Description") },
                            domProps: {
                              value: _vm.editPromotion.description_en,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.editPromotion,
                                  "description_en",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _c("textarea", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.english,
                                expression: "!english",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.editPromotion.description_he,
                                expression: "editPromotion.description_he",
                              },
                            ],
                            attrs: { placeholder: _vm.$t("Description") },
                            domProps: {
                              value: _vm.editPromotion.description_he,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.editPromotion,
                                  "description_he",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "coupon-info__field flex fields" },
                        [
                          _c("form-site-field-text", {
                            staticClass: "mie4",
                            attrs: {
                              sub: "",
                              type: "number",
                              title: "Items maximum get",
                            },
                            model: {
                              value: _vm.editPromotion.items_maximum_get,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editPromotion,
                                  "items_maximum_get",
                                  $$v
                                )
                              },
                              expression: "editPromotion.items_maximum_get",
                            },
                          }),
                          _c("form-site-field-text", {
                            staticClass: "mie4",
                            attrs: {
                              sub: "",
                              type: "number",
                              title: "Times per client",
                            },
                            model: {
                              value: _vm.editPromotion.times_per_client,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editPromotion,
                                  "times_per_client",
                                  $$v
                                )
                              },
                              expression: "editPromotion.times_per_client",
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "coupon-info__field" },
                            [
                              _c("form-site-field-select-box", {
                                staticClass: "select-box-audiences w200",
                                attrs: {
                                  title: "Audience",
                                  firstTop: "",
                                  placeholder: _vm.$t("Audience"),
                                  widthHeader: "200px",
                                  width: "200px",
                                  showSearch: "",
                                  options: _vm.audiencesSelectOptions,
                                },
                                model: {
                                  value:
                                    _vm.editPromotion.availability.audiences,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.editPromotion.availability,
                                      "audiences",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "editPromotion.availability.audiences",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "coupon-info__field",
                          staticStyle: { "margin-top": "-20px" },
                        },
                        [
                          _c("div", {
                            staticClass: "coupon-info__label",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Order type")),
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "coupon-info-check" },
                            _vm._l(
                              ["Delivery", "Takeaway", "Future delivery"],
                              function (name, i) {
                                return _c(
                                  "div",
                                  {
                                    key: name,
                                    staticClass: "coupon-info-check__item",
                                  },
                                  [
                                    _c("div", { staticClass: "input-check" }, [
                                      _c(
                                        "label",
                                        { staticClass: "input-check__label" },
                                        [
                                          _c("checkbox", {
                                            attrs: {
                                              name: name,
                                              value: _vm.valueCB(
                                                "order_type",
                                                i
                                              ),
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.inputCB(
                                                  $event,
                                                  "order_type",
                                                  i
                                                )
                                              },
                                            },
                                          }),
                                          _c("span", {
                                            staticClass: "input-check__text",
                                            domProps: {
                                              textContent: _vm._s(_vm.$t(name)),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "coupon-info__field" }, [
                        _c("div", {
                          staticClass: "coupon-info__label",
                          domProps: { textContent: _vm._s(_vm.$t("Settings")) },
                        }),
                        _c(
                          "div",
                          { staticClass: "coupon-info-check" },
                          [
                            _vm._l(
                              [
                                "Double deal",
                                "Collecting coins",
                                "Free delivery",
                                "Minimum order",
                                "once_per_order",
                              ],
                              function (name, i) {
                                return _c(
                                  "div",
                                  {
                                    key: name,
                                    staticClass: "coupon-info-check__item",
                                  },
                                  [
                                    _c("div", { staticClass: "input-check" }, [
                                      name === "once_per_order"
                                        ? _c(
                                            "label",
                                            {
                                              staticClass: "input-check__label",
                                            },
                                            [
                                              _c("checkbox", {
                                                attrs: {
                                                  name: "once-per-order",
                                                },
                                                model: {
                                                  value:
                                                    _vm.editPromotion
                                                      .once_per_order,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.editPromotion,
                                                      "once_per_order",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editPromotion.once_per_order",
                                                },
                                              }),
                                              _c("span", {
                                                staticClass:
                                                  "input-check__text",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t("once_per_order")
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      name != "Free delivery" &&
                                      name != "Minimum order" &&
                                      name !== "once_per_order"
                                        ? _c(
                                            "label",
                                            {
                                              staticClass: "input-check__label",
                                            },
                                            [
                                              _c("checkbox", {
                                                attrs: {
                                                  name: name,
                                                  value: _vm.valueCB(
                                                    "settings",
                                                    i
                                                  ),
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.inputCB(
                                                      $event,
                                                      "settings",
                                                      i
                                                    )
                                                  },
                                                },
                                              }),
                                              _c("span", {
                                                staticClass:
                                                  "input-check__text",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(name)
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      name == "Free delivery"
                                        ? _c(
                                            "label",
                                            {
                                              staticClass: "input-check__label",
                                              style: { width: "100%" },
                                            },
                                            [
                                              _c("checkbox", {
                                                attrs: {
                                                  name: name,
                                                  value: _vm.valueCB(
                                                    "settings",
                                                    i
                                                  ),
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.inputCB(
                                                      $event,
                                                      "settings",
                                                      i
                                                    )
                                                  },
                                                },
                                              }),
                                              _c("span", {
                                                staticClass:
                                                  "input-check__text",
                                                style: { width: "100%" },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(name)
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      name == "Minimum order" &&
                                      !_vm.valueCB("settings", 2)
                                        ? _c(
                                            "label",
                                            {
                                              staticClass: "input-check__label",
                                            },
                                            [
                                              _c("checkbox", {
                                                attrs: {
                                                  name: name,
                                                  value: _vm.valueCB(
                                                    "settings",
                                                    i
                                                  ),
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.inputCB(
                                                      $event,
                                                      "settings",
                                                      i
                                                    )
                                                  },
                                                },
                                              }),
                                              _c("span", {
                                                staticClass:
                                                  "input-check__text",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(name)
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "coupon-info-check__item",
                                style: { display: "flex", width: "100%" },
                              },
                              [
                                _vm.valueCB("settings", 2)
                                  ? _c("label", [
                                      _c("span", {
                                        staticClass: "input-check__text",
                                        style: { width: "100%" },
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "לאזורי חלוקה בעלות משלוח עד"
                                            )
                                          ),
                                        },
                                      }),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.number",
                                            value:
                                              _vm.editPromotion
                                                .minimum_delivery_amount,
                                            expression:
                                              "editPromotion.minimum_delivery_amount",
                                            modifiers: { number: true },
                                          },
                                        ],
                                        style: {
                                          "margin-inline-start": "10px",

                                          width: "30%",
                                        },
                                        attrs: { type: "number", min: "0" },
                                        domProps: {
                                          value:
                                            _vm.editPromotion
                                              .minimum_delivery_amount,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.editPromotion,
                                              "minimum_delivery_amount",
                                              _vm._n($event.target.value)
                                            )
                                          },
                                          blur: function ($event) {
                                            return _vm.$forceUpdate()
                                          },
                                        },
                                      }),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          2
                        ),
                      ]),
                      _c("div", { staticClass: "coupon-info__field" }, [
                        _c("div", { staticClass: "coupon-info__label-wrap" }, [
                          _c("div", {
                            staticClass: "coupon-info__label",
                            domProps: { textContent: _vm._s(_vm.$t("Notes")) },
                          }),
                        ]),
                        _c("div", { staticClass: "coupon-info__msg" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.english,
                                expression: "english",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.editPromotion.notes_en,
                                expression: "editPromotion.notes_en",
                              },
                            ],
                            attrs: {
                              placeholder: _vm.$t("Write some special notes"),
                            },
                            domProps: { value: _vm.editPromotion.notes_en },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.editPromotion,
                                  "notes_en",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _c("textarea", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.english,
                                expression: "!english",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.editPromotion.notes,
                                expression: "editPromotion.notes",
                              },
                            ],
                            attrs: {
                              placeholder: _vm.$t("Write some special notes"),
                            },
                            domProps: { value: _vm.editPromotion.notes },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.editPromotion,
                                  "notes",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "coupon-info__field" }, [
                        _c("div", {
                          staticClass: "coupon-info__label",
                          domProps: {
                            textContent: _vm._s(_vm.$t("Can be redeemed in")),
                          },
                        }),
                        _c("div", {
                          staticClass: "coupon-info__label",
                          domProps: { textContent: _vm._s(_vm.$t("Source")) },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "availability-check-list rp5",
                            style: { width: "100%" },
                          },
                          _vm._l(_vm.sourceEnumValues, function (key, i) {
                            return _c(
                              "label",
                              {
                                key: _vm.sourceEnumToStr(key),
                                staticClass: "availability-check",
                                class: {
                                  active:
                                    _vm.editPromotion.availability.order_sources.includes(
                                      _vm.sourceEnumToNumber(key)
                                    ),
                                },
                                style: { width: "100px" },
                              },
                              [
                                _c("input", {
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    value:
                                      _vm.editPromotion.availability.order_sources.includes(
                                        _vm.sourceEnumToNumber(key)
                                      ),
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.setAvailabitySources(key)
                                    },
                                  },
                                }),
                                _c("svg-icon", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.editPromotion.availability.order_sources.includes(
                                          _vm.sourceEnumToNumber(key)
                                        ),
                                      expression:
                                        "\n                      editPromotion.availability.order_sources.includes(\n                        sourceEnumToNumber(key)\n                      )\n                    ",
                                    },
                                  ],
                                  attrs: {
                                    name: "availability-check-v",
                                    width: "16",
                                    height: "12",
                                  },
                                }),
                                _c("svg-icon", {
                                  attrs: {
                                    name:
                                      "ic-availability-" +
                                      _vm.sourceEnumToLowerStr(key),
                                    width: "24",
                                    height: "24",
                                  },
                                }),
                                _c("span", {
                                  staticClass: "input-check__text",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(_vm.sourceEnumToStr(key))
                                    ),
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                        _c("div", {
                          staticClass: "coupon-info__label",
                          domProps: { textContent: _vm._s(_vm.$t("Method")) },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "availability-check-list rp4",
                            style: { width: "100%" },
                          },
                          _vm._l(_vm.typesEnumValues, function (key, i) {
                            return _c(
                              "label",
                              {
                                key: _vm.typeEnumToStr(key),
                                staticClass: "availability-check",
                                class: {
                                  active:
                                    _vm.editPromotion.availability.order_types.includes(
                                      _vm.typeEnumToNumber(key)
                                    ),
                                },
                                style: { width: "100px" },
                              },
                              [
                                _c("input", {
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    value:
                                      _vm.editPromotion.availability.order_types.includes(
                                        _vm.typeEnumToNumber(key)
                                      ),
                                  },
                                  on: {
                                    input: function ($event) {
                                      _vm.setAvailabityTypes(
                                        _vm.typeEnumToNumber(key)
                                      )
                                    },
                                  },
                                }),
                                _c("svg-icon", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.editPromotion.availability.order_types.includes(
                                          _vm.typeEnumToNumber(key)
                                        ),
                                      expression:
                                        "\n                      editPromotion.availability.order_types.includes(\n                        typeEnumToNumber(key)\n                      )\n                    ",
                                    },
                                  ],
                                  attrs: {
                                    name: "availability-check-v",
                                    width: "16",
                                    height: "12",
                                  },
                                }),
                                _c("svg-icon", {
                                  attrs: {
                                    name:
                                      "ic-availability-" +
                                      _vm.typeEnumToLowerStr(key),
                                    width: "30",
                                    height: "25",
                                  },
                                }),
                                _c("span", {
                                  staticClass: "input-check__text",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(_vm.typeEnumToStr(key))
                                    ),
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]),
                      _c("div", { staticClass: "coupon-info__field" }, [
                        _c("div", {
                          staticClass: "coupon-info__label",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("Redemption limit per client")
                            ),
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "coupon-info-period" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: _vm.editPromotion.redemption_limit_n,
                                  expression:
                                    "editPromotion.redemption_limit_n",
                                  modifiers: { number: true },
                                },
                              ],
                              attrs: {
                                disabled: _vm.noLimit,
                                type: "number",
                                min: "0",
                              },
                              domProps: {
                                value: _vm.editPromotion.redemption_limit_n,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.editPromotion,
                                    "redemption_limit_n",
                                    _vm._n($event.target.value)
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                            _c("div", {
                              staticClass: "coupon-info-period__text",
                              domProps: {
                                textContent: _vm._s(_vm.$t("times in")),
                              },
                            }),
                            _c("select-box", {
                              attrs: {
                                widthHeader: "100%",
                                width: "100%",
                                isDisabled: _vm.noLimit,
                                unsort: "",
                                openToUp: "",
                                options: _vm.periodOfTimeOptions,
                              },
                              model: {
                                value: _vm.editPromotion.redemption_limit_p,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.editPromotion,
                                    "redemption_limit_p",
                                    $$v
                                  )
                                },
                                expression: "editPromotion.redemption_limit_p",
                              },
                            }),
                            _c(
                              "label",
                              { staticClass: "input-check__label" },
                              [
                                _c("checkbox", {
                                  attrs: { name: "on-limit" },
                                  model: {
                                    value: _vm.noLimit,
                                    callback: function ($$v) {
                                      _vm.noLimit = $$v
                                    },
                                    expression: "noLimit",
                                  },
                                }),
                                _c("span", {
                                  staticClass: "input-check__text",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("No limit")),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "coupon-info__btn" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn_border",
                          attrs: { href: "#" },
                          on: { click: _vm.backStep },
                        },
                        [
                          _c("svg-icon", {
                            attrs: {
                              name: "ic-arrow-left",
                              width: "16",
                              height: "12",
                            },
                          }),
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Back")) },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "button",
                        { staticClass: "btn", on: { click: _vm.nextStep } },
                        [
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Next")) },
                          }),
                          _c("svg-icon", {
                            staticClass: "ic-white",
                            attrs: {
                              name: "ic-arrow-right",
                              width: "16",
                              height: "12",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.step === 3
                ? _c("div", { staticClass: "coupon-buy" }, [
                    _c("div", { staticClass: "coupon-buy__content-wrap" }, [
                      _c("div", { staticClass: "coupon-buy__content" }, [
                        _c("div", { staticClass: "coupon-buy__item" }, [
                          _c(
                            "div",
                            { staticClass: "coupon-buy__info" },
                            [
                              _c("div", {
                                staticClass: "coupon-buy__head",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Buy")),
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "coupon-buy__list coupon-buy__list_full",
                                },
                                [
                                  _c("div", { staticClass: "input-radio" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.editPromotion.condition_type,
                                          expression:
                                            "editPromotion.condition_type",
                                        },
                                      ],
                                      attrs: {
                                        type: "radio",
                                        name: "buyradio",
                                        id: "radio210",
                                      },
                                      domProps: {
                                        value: 4,
                                        checked: _vm._q(
                                          _vm.editPromotion.condition_type,
                                          4
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.editPromotion,
                                            "condition_type",
                                            4
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-radio__label",
                                        attrs: { for: "radio210" },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "input-radio__mark",
                                        }),
                                        _c("span", {
                                          staticClass: "input-radio__text",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Didn’t buy item")
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "input-radio" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.editPromotion.condition_type,
                                          expression:
                                            "editPromotion.condition_type",
                                        },
                                      ],
                                      attrs: {
                                        type: "radio",
                                        name: "buyradio",
                                        id: "radio2101",
                                      },
                                      domProps: {
                                        value: 5,
                                        checked: _vm._q(
                                          _vm.editPromotion.condition_type,
                                          5
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.editPromotion,
                                            "condition_type",
                                            5
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-radio__label",
                                        attrs: { for: "radio2101" },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "input-radio__mark",
                                        }),
                                        _c("span", {
                                          staticClass: "input-radio__text",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Didn’t buy category")
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "input-radio" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.editPromotion.condition_type,
                                          expression:
                                            "editPromotion.condition_type",
                                        },
                                      ],
                                      attrs: {
                                        type: "radio",
                                        name: "buyradio",
                                        id: "radio211",
                                      },
                                      domProps: {
                                        value: 0,
                                        checked: _vm._q(
                                          _vm.editPromotion.condition_type,
                                          0
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.editPromotion,
                                            "condition_type",
                                            0
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-radio__label",
                                        attrs: { for: "radio211" },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "input-radio__mark",
                                        }),
                                        _c("span", {
                                          staticClass: "input-radio__text",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("No conditions")
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "input-radio" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.editPromotion.condition_type,
                                          expression:
                                            "editPromotion.condition_type",
                                        },
                                      ],
                                      attrs: {
                                        type: "radio",
                                        name: "buyradio",
                                        id: "radio212",
                                      },
                                      domProps: {
                                        value: 3,
                                        checked: _vm._q(
                                          _vm.editPromotion.condition_type,
                                          3
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.editPromotion,
                                            "condition_type",
                                            3
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-radio__label",
                                        attrs: { for: "radio212" },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "input-radio__mark",
                                        }),
                                        _c("span", {
                                          staticClass: "input-radio__text",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("From amount")
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "input-radio" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.editPromotion.condition_type,
                                          expression:
                                            "editPromotion.condition_type",
                                        },
                                      ],
                                      attrs: {
                                        type: "radio",
                                        name: "buyradio",
                                        id: "radio213",
                                      },
                                      domProps: {
                                        value: 2,
                                        checked: _vm._q(
                                          _vm.editPromotion.condition_type,
                                          2
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.editPromotion,
                                            "condition_type",
                                            2
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-radio__label",
                                        attrs: { for: "radio213" },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "input-radio__mark",
                                        }),
                                        _c("span", {
                                          staticClass: "input-radio__text",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Category")
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "input-radio" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.editPromotion.condition_type,
                                          expression:
                                            "editPromotion.condition_type",
                                        },
                                      ],
                                      attrs: {
                                        type: "radio",
                                        name: "buyradio",
                                        id: "radio214",
                                      },
                                      domProps: {
                                        value: 1,
                                        checked: _vm._q(
                                          _vm.editPromotion.condition_type,
                                          1
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.editPromotion,
                                            "condition_type",
                                            1
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-radio__label",
                                        attrs: { for: "radio214" },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "input-radio__mark",
                                        }),
                                        _c("span", {
                                          staticClass: "input-radio__text",
                                          domProps: {
                                            textContent: _vm._s(_vm.$t("Item")),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: [1, 2].includes(
                                        _vm.editPromotion.condition_type
                                      ),
                                      expression:
                                        "[1, 2].includes(editPromotion.condition_type)",
                                    },
                                  ],
                                  staticClass: "input-label items-minimum",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.editPromotion.items_minimum_buy,
                                        expression:
                                          "editPromotion.items_minimum_buy",
                                      },
                                    ],
                                    attrs: { type: "number" },
                                    domProps: {
                                      value:
                                        _vm.editPromotion.items_minimum_buy,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.editPromotion,
                                          "items_minimum_buy",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c("div", {
                                    staticClass: "input-label__note-end",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Items minimum")
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _vm.editPromotion.condition_type == 3
                                ? [
                                    _c("div", {
                                      staticClass: "coupon-info__label",
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("How much")),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "coupon-buy-sale" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "input-label" },
                                          [
                                            _vm.restaurantCurrencyInfo === null
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "input-label__note",
                                                  },
                                                  [_vm._v("₪")]
                                                )
                                              : _vm._e(),
                                            _vm.restaurantCurrencyInfo !== null
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "input-label__note",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.restaurantCurrencySymbol
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.editPromotion
                                                      .condition_amount,
                                                  expression:
                                                    "editPromotion.condition_amount",
                                                },
                                              ],
                                              attrs: { type: "text" },
                                              domProps: {
                                                value:
                                                  _vm.editPromotion
                                                    .condition_amount,
                                              },
                                              on: {
                                                input: [
                                                  function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.editPromotion,
                                                      "condition_amount",
                                                      $event.target.value
                                                    )
                                                  },
                                                  _vm.validPrice,
                                                ],
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              _vm.editPromotion.condition_type == 4
                                ? [
                                    _c("div", {
                                      staticClass: "coupon-info__label",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("How much items")
                                        ),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "coupon-buy-sale how-much-items",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "input-label" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.editPromotion
                                                      .condition_amount,
                                                  expression:
                                                    "editPromotion.condition_amount",
                                                },
                                              ],
                                              attrs: { type: "text" },
                                              domProps: {
                                                value:
                                                  _vm.editPromotion
                                                    .condition_amount,
                                              },
                                              on: {
                                                input: [
                                                  function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.editPromotion,
                                                      "condition_amount",
                                                      $event.target.value
                                                    )
                                                  },
                                                  _vm.validPrice,
                                                ],
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              _c("select-category-items-list", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: [1, 2, 4, 5].includes(
                                      _vm.editPromotion.condition_type
                                    ),
                                    expression:
                                      "\n                      [1, 2, 4, 5].includes(editPromotion.condition_type)\n                    ",
                                  },
                                ],
                                model: {
                                  value: _vm.editPromotion.relations,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.editPromotion,
                                      "relations",
                                      $$v
                                    )
                                  },
                                  expression: "editPromotion.relations",
                                },
                              }),
                            ],
                            2
                          ),
                        ]),
                        _c("div", { staticClass: "coupon-buy__item" }, [
                          _c(
                            "div",
                            { staticClass: "coupon-buy__info" },
                            [
                              _c("div", {
                                staticClass: "coupon-buy__head",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Get")),
                                },
                              }),
                              _c("div", { staticClass: "coupon-buy__list" }, [
                                _c("div", { staticClass: "input-radio" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.editPromotion.get_type,
                                        expression: "editPromotion.get_type",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "radio2",
                                      id: "radio20",
                                    },
                                    domProps: {
                                      value: 0,
                                      checked: _vm._q(
                                        _vm.editPromotion.get_type,
                                        0
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.editPromotion,
                                          "get_type",
                                          0
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "input-radio__label",
                                      attrs: { for: "radio20" },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "input-radio__mark",
                                      }),
                                      _c("span", {
                                        staticClass: "input-radio__text",
                                        domProps: {
                                          textContent: _vm._s(
                                            "%" + _vm.$t("OFF")
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "input-radio" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.editPromotion.get_type,
                                        expression: "editPromotion.get_type",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "radio2",
                                      id: "radio21",
                                    },
                                    domProps: {
                                      value: 1,
                                      checked: _vm._q(
                                        _vm.editPromotion.get_type,
                                        1
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.editPromotion,
                                          "get_type",
                                          1
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "input-radio__label",
                                      attrs: { for: "radio21" },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "input-radio__mark",
                                      }),
                                      _vm.restaurantCurrencyInfo === null
                                        ? _c("span", {
                                            staticClass: "input-radio__text",
                                            domProps: {
                                              textContent: _vm._s(
                                                "₪" + _vm.$t("OFF")
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.restaurantCurrencyInfo !== null
                                        ? _c("span", {
                                            staticClass: "input-radio__text",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.restaurantCurrencySymbol +
                                                  _vm.$t("OFF")
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "input-radio" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.editPromotion.get_type,
                                        expression: "editPromotion.get_type",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "radio2",
                                      id: "radio22",
                                    },
                                    domProps: {
                                      value: 2,
                                      checked: _vm._q(
                                        _vm.editPromotion.get_type,
                                        2
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.editPromotion,
                                          "get_type",
                                          2
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "input-radio__label",
                                      attrs: { for: "radio22" },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "input-radio__mark",
                                      }),
                                      _c("span", {
                                        staticClass: "input-radio__text",
                                        domProps: {
                                          textContent: _vm._s(_vm.$t("Gift")),
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "input-radio" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.editPromotion.get_type,
                                        expression: "editPromotion.get_type",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "radio3",
                                      id: "radio23",
                                    },
                                    domProps: {
                                      value: 4,
                                      checked: _vm._q(
                                        _vm.editPromotion.get_type,
                                        4
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.editPromotion,
                                          "get_type",
                                          4
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "input-radio__label",
                                      attrs: { for: "radio23" },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "input-radio__mark",
                                      }),
                                      _c("span", {
                                        staticClass: "input-radio__text",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("Static price")
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                                [4].includes(_vm.editPromotion.get_type)
                                  ? _c(
                                      "div",
                                      { staticClass: "coupon-buy-sale" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "input-label" },
                                          [
                                            _vm.restaurantCurrencyInfo === null
                                              ? _c("div", {
                                                  staticClass:
                                                    "input-label__note",
                                                  domProps: {
                                                    textContent: _vm._s("₪"),
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm.restaurantCurrencyInfo !== null
                                              ? _c("div", {
                                                  staticClass:
                                                    "input-label__note",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.restaurantCurrencySymbol
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.editPromotion
                                                      .get_amount,
                                                  expression:
                                                    "editPromotion.get_amount",
                                                },
                                              ],
                                              attrs: { type: "text" },
                                              domProps: {
                                                value:
                                                  _vm.editPromotion.get_amount,
                                              },
                                              on: {
                                                input: [
                                                  function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.editPromotion,
                                                      "get_amount",
                                                      $event.target.value
                                                    )
                                                  },
                                                  _vm.validPrice,
                                                ],
                                              },
                                            }),
                                          ]
                                        ),
                                        _c("div", {
                                          staticClass: "coupon-buy-sale__text",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Price")
                                            ),
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                              [0, 1].includes(_vm.editPromotion.get_type)
                                ? [
                                    _c("div", {
                                      staticClass: "coupon-info__label",
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Sale")),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "coupon-buy-sale" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "input-label" },
                                          [
                                            _vm.restaurantCurrencyInfo === null
                                              ? _c("div", {
                                                  staticClass:
                                                    "input-label__note",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.editPromotion.get_type
                                                        ? "₪"
                                                        : "%"
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm.restaurantCurrencyInfo !== null
                                              ? _c("div", {
                                                  staticClass:
                                                    "input-label__note",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.editPromotion.get_type
                                                        ? _vm.restaurantCurrencySymbol
                                                        : "%"
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.editPromotion
                                                      .get_amount,
                                                  expression:
                                                    "editPromotion.get_amount",
                                                },
                                              ],
                                              attrs: { type: "text" },
                                              domProps: {
                                                value:
                                                  _vm.editPromotion.get_amount,
                                              },
                                              on: {
                                                input: [
                                                  function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.editPromotion,
                                                      "get_amount",
                                                      $event.target.value
                                                    )
                                                  },
                                                  _vm.validPrice,
                                                ],
                                              },
                                            }),
                                          ]
                                        ),
                                        _c("div", {
                                          staticClass: "coupon-buy-sale__text",
                                          domProps: {
                                            textContent: _vm._s(_vm.$t("off")),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: [0, 1].includes(
                                        _vm.editPromotion.get_type
                                      ),
                                      expression:
                                        "[0, 1].includes(editPromotion.get_type)",
                                    },
                                  ],
                                  staticClass:
                                    "coupon-buy__list coupon-buy__list_full",
                                },
                                [
                                  _c("div", { staticClass: "input-check" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.editPromotion.get_condition,
                                          expression:
                                            "editPromotion.get_condition",
                                        },
                                      ],
                                      attrs: {
                                        type: "radio",
                                        name: "get_condition",
                                        id: "radio223",
                                      },
                                      domProps: {
                                        value: 0,
                                        checked: _vm._q(
                                          _vm.editPromotion.get_condition,
                                          0
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.editPromotion,
                                            "get_condition",
                                            0
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-check__label",
                                        attrs: { for: "radio223" },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "input-check__mark",
                                        }),
                                        _c("span", {
                                          staticClass: "input-check__text",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("On every order")
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "input-check" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.editPromotion.get_condition,
                                          expression:
                                            "editPromotion.get_condition",
                                        },
                                      ],
                                      attrs: {
                                        type: "radio",
                                        name: "get_condition",
                                        id: "radio224",
                                      },
                                      domProps: {
                                        value: 1,
                                        checked: _vm._q(
                                          _vm.editPromotion.get_condition,
                                          1
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.editPromotion,
                                            "get_condition",
                                            1
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-check__label",
                                        attrs: { for: "radio224" },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "input-check__mark",
                                        }),
                                        _c("span", {
                                          staticClass: "input-check__text",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "On specific item/category"
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: [4].includes(
                                        _vm.editPromotion.get_type
                                      ),
                                      expression:
                                        "[4].includes(editPromotion.get_type)",
                                    },
                                  ],
                                  staticClass:
                                    "coupon-buy__list coupon-buy__list_full",
                                },
                                [
                                  _c("div", { staticClass: "input-check" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.checked,
                                          expression: "checked",
                                        },
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        name: "get_condition",
                                        id: "radio77",
                                      },
                                      domProps: {
                                        checked: Array.isArray(_vm.checked)
                                          ? _vm._i(_vm.checked, null) > -1
                                          : _vm.checked,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.checked,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.checked = $$a.concat([
                                                  $$v,
                                                ]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.checked = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.checked = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-check__label",
                                        attrs: { for: "radio224" },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "input-check__mark",
                                        }),
                                        _c("span", {
                                          staticClass: "input-check__text",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "On specific item/category"
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _c("select-category-items-list", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.editPromotion.get_type === 2 ||
                                      _vm.editPromotion.get_condition === 1,
                                    expression:
                                      "\n                      editPromotion.get_type === 2 ||\n                      editPromotion.get_condition === 1\n                    ",
                                  },
                                ],
                                model: {
                                  value: _vm.editPromotion.gift_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.editPromotion, "gift_id", $$v)
                                  },
                                  expression: "editPromotion.gift_id",
                                },
                              }),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "coupon-buy__btn" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn_border",
                          on: { click: _vm.backStep },
                        },
                        [
                          _c("svg-icon", {
                            attrs: {
                              name: "ic-arrow-left",
                              width: "16",
                              height: "12",
                            },
                          }),
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Back")) },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "button",
                        { staticClass: "btn", on: { click: _vm.nextStep } },
                        [
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Next")) },
                          }),
                          _c("svg-icon", {
                            staticClass: "ic-white",
                            attrs: {
                              name: "ic-arrow-right",
                              width: "16",
                              height: "12",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.step === 4
                ? _c("div", { staticClass: "coupon-days" }, [
                    _c("div", { staticClass: "working-days__all" }, [
                      _c("div", { staticClass: "input-radio" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.editPromotion.availability.times
                                  .always_open,
                              expression:
                                "editPromotion.availability.times.always_open",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "radio40",
                            id: "radio40",
                          },
                          domProps: {
                            value: false,
                            checked: _vm._q(
                              _vm.editPromotion.availability.times.always_open,
                              false
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.editPromotion.availability.times,
                                "always_open",
                                false
                              )
                            },
                          },
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "input-radio__label",
                            attrs: { for: "radio40" },
                          },
                          [
                            _c("span", { staticClass: "input-radio__mark" }),
                            _c("span", {
                              staticClass: "input-radio__text",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Days & times")),
                              },
                            }),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "input-radio" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.editPromotion.availability.times
                                  .always_open,
                              expression:
                                "editPromotion.availability.times.always_open",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "radio40",
                            id: "radio41",
                          },
                          domProps: {
                            value: true,
                            checked: _vm._q(
                              _vm.editPromotion.availability.times.always_open,
                              true
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.editPromotion.availability.times,
                                "always_open",
                                true
                              )
                            },
                          },
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "input-radio__label",
                            attrs: { for: "radio41" },
                          },
                          [
                            _c("span", { staticClass: "input-radio__mark" }),
                            _c("span", {
                              staticClass: "input-radio__text",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Always available")),
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "working-days__list" },
                      [
                        _c("availability-tab", {
                          staticClass: "toHide",
                          attrs: {
                            availability: _vm.editPromotion.availability,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "coupon-info__btn" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn_border",
                          attrs: { href: "#" },
                          on: { click: _vm.backStep },
                        },
                        [
                          _c("svg-icon", {
                            attrs: {
                              name: "ic-arrow-left",
                              width: "16",
                              height: "12",
                            },
                          }),
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Back")) },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "button",
                        { staticClass: "btn", on: { click: _vm.nextStep } },
                        [
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Next")) },
                          }),
                          _c("svg-icon", {
                            staticClass: "ic-white",
                            attrs: {
                              name: "ic-arrow-right",
                              width: "16",
                              height: "12",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.step === 5
                ? _c(
                    "div",
                    { staticClass: "coupon-branches" },
                    [
                      _c("branches-tab", {
                        attrs: {
                          activity: "",
                          availability: _vm.editPromotion.availability,
                          page: "promotions",
                        },
                      }),
                      _c("div", { staticClass: "coupon-info__btn" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn_border",
                            attrs: { href: "#" },
                            on: { click: _vm.backStep },
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-arrow-left",
                                width: "16",
                                height: "12",
                              },
                            }),
                            _c("span", {
                              domProps: { textContent: _vm._s(_vm.$t("Back")) },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "button",
                          { staticClass: "btn", on: { click: _vm.nextStep } },
                          [
                            _c("span", {
                              domProps: { textContent: _vm._s(_vm.$t("Next")) },
                            }),
                            _c("svg-icon", {
                              staticClass: "ic-white",
                              attrs: {
                                name: "ic-arrow-right",
                                width: "16",
                                height: "12",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.step === 6
                ? _c("div", { staticClass: "coupon-statistic" }, [
                    _c(
                      "div",
                      { staticClass: "coupon-statistic__content col" },
                      [
                        _c(
                          "div",
                          { staticClass: "coupon-statistic__content" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "coupon-statistic__choose",
                                class: { active: _vm.publishNow },
                              },
                              [
                                _c("div", { staticClass: "input-check" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.publishNow,
                                        expression: "publishNow",
                                      },
                                    ],
                                    attrs: { type: "radio" },
                                    domProps: {
                                      value: true,
                                      checked: _vm._q(_vm.publishNow, true),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.publishNow = true
                                      },
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "input-check__label" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "input-check__mark non-after",
                                        },
                                        [
                                          _vm.publishNow
                                            ? _c("svg-icon", {
                                                attrs: {
                                                  name: "ic-check",
                                                  width: "21",
                                                  height: "16",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c("span", {
                                        staticClass: "input-check__text",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("Publish now")
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "label",
                              {
                                staticClass: "coupon-statistic__choose",
                                class: { active: !_vm.publishNow },
                              },
                              [
                                _c("div", { staticClass: "input-check" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.publishNow,
                                        expression: "publishNow",
                                      },
                                    ],
                                    attrs: { type: "radio" },
                                    domProps: {
                                      value: false,
                                      checked: _vm._q(_vm.publishNow, false),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.publishNow = false
                                      },
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "input-check__label" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "input-check__mark non-after",
                                        },
                                        [
                                          !_vm.publishNow
                                            ? _c("svg-icon", {
                                                attrs: {
                                                  name: "ic-check",
                                                  width: "21",
                                                  height: "16",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c("span", {
                                        staticClass: "input-check__text",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("Schedule Publish")
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "input-check m12" }, [
                          _c(
                            "label",
                            { staticClass: "input-check__label" },
                            [
                              _c("checkbox", {
                                attrs: { name: "include-char" },
                                on: {
                                  input: function ($event) {
                                    return _vm.setCoupons($event)
                                  },
                                },
                                model: {
                                  value: _vm.includeChar,
                                  callback: function ($$v) {
                                    _vm.includeChar = $$v
                                  },
                                  expression: "includeChar",
                                },
                              }),
                              _c("span", {
                                staticClass: "input-check__text",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Include chars")),
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.publishNow,
                                expression: "!publishNow",
                              },
                            ],
                            staticClass: "coupon-statistic__field",
                          },
                          [
                            _c("div", {
                              staticClass: "coupon-statistic__label",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Publish at")),
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "coupon-statistic__input" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "input-date" },
                                  [
                                    _c("date-picker", {
                                      staticClass: "date-picker",
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var inputValue = ref.inputValue
                                              var inputEvents = ref.inputEvents
                                              return [
                                                _c(
                                                  "input",
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "bg-white border px-2 py-1 rounded",
                                                      attrs: { type: "text" },
                                                      domProps: {
                                                        value:
                                                          inputValue.replaceAll(
                                                            ".",
                                                            "/"
                                                          ),
                                                      },
                                                    },
                                                    inputEvents
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        52154283
                                      ),
                                      model: {
                                        value: _vm.publishDate,
                                        callback: function ($$v) {
                                          _vm.publishDate = $$v
                                        },
                                        expression: "publishDate",
                                      },
                                    }),
                                    _c("svg-icon", {
                                      attrs: {
                                        name: "ic-calendar",
                                        width: "20",
                                        height: "20",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "input-date" },
                                  [
                                    _c("select-box", {
                                      ref: "timepicker2",
                                      staticClass: "t-center",
                                      attrs: {
                                        width: "100px",
                                        autocomplete: "",
                                        options: _vm.listHours,
                                        placeholder: "00:00",
                                      },
                                      model: {
                                        value: _vm.publishTime,
                                        callback: function ($$v) {
                                          _vm.publishTime = $$v
                                        },
                                        expression: "publishTime",
                                      },
                                    }),
                                    _c("svg-icon", {
                                      attrs: {
                                        name: "ic-watch",
                                        width: "20",
                                        height: "20",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "coupon-statistic__field" }, [
                          _c("div", {
                            staticClass: "coupon-statistic__label",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Expires at")),
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "coupon-statistic__input" },
                            [
                              _c(
                                "div",
                                { staticClass: "input-date" },
                                [
                                  _c("date-picker", {
                                    staticClass: "date-picker",
                                    attrs: {
                                      "disabled-dates": _vm.never ? {} : "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var inputValue = ref.inputValue
                                            var inputEvents = ref.inputEvents
                                            return [
                                              _c(
                                                "input",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "bg-white border px-2 py-1 rounded pis36 pie0",
                                                    attrs: { type: "text" },
                                                    domProps: {
                                                      value:
                                                        inputValue.replaceAll(
                                                          ".",
                                                          "/"
                                                        ),
                                                    },
                                                  },
                                                  inputEvents
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      4183268264
                                    ),
                                    model: {
                                      value: _vm.expiresDate,
                                      callback: function ($$v) {
                                        _vm.expiresDate = $$v
                                      },
                                      expression: "expiresDate",
                                    },
                                  }),
                                  _c("svg-icon", {
                                    attrs: {
                                      name: "ic-calendar",
                                      width: "20",
                                      height: "20",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "input-date" },
                                [
                                  _c("select-box", {
                                    ref: "timepicker2",
                                    staticClass: "t-center",
                                    attrs: {
                                      width: "100px",
                                      autocomplete: "",
                                      options: _vm.listHours,
                                      placeholder: "00:00",
                                    },
                                    model: {
                                      value: _vm.expiresTime,
                                      callback: function ($$v) {
                                        _vm.expiresTime = $$v
                                      },
                                      expression: "expiresTime",
                                    },
                                  }),
                                  _c("svg-icon", {
                                    attrs: {
                                      name: "ic-watch",
                                      width: "20",
                                      height: "20",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "input-check" }, [
                            _c(
                              "label",
                              { staticClass: "input-check__label" },
                              [
                                _c("checkbox", {
                                  attrs: { name: "naver" },
                                  model: {
                                    value: _vm.never,
                                    callback: function ($$v) {
                                      _vm.never = $$v
                                    },
                                    expression: "never",
                                  },
                                }),
                                _c("span", {
                                  staticClass: "input-check__text",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Never")),
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm.editPromotion.type === 1
                          ? _c(
                              "div",
                              { staticClass: "coupon-statistic__field" },
                              [
                                _c("div", {
                                  staticClass: "coupon-statistic__label",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("Quantity coupon")
                                    ),
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "coupon-statistic__check",
                                    staticStyle: { "margin-top": "34px" },
                                  },
                                  [
                                    _c("div", { staticClass: "input-check" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.editPromotion.coupon_type,
                                            expression:
                                              "editPromotion.coupon_type",
                                          },
                                        ],
                                        attrs: { id: "limited", type: "radio" },
                                        domProps: {
                                          value: 0,
                                          checked: _vm._q(
                                            _vm.editPromotion.coupon_type,
                                            0
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.editPromotion,
                                              "coupon_type",
                                              0
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "input-check__label",
                                          attrs: {
                                            for: "limited",
                                            checked: "checked",
                                          },
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "input-check__mark",
                                          }),
                                          _c("span", {
                                            staticClass: "input-check__text",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t("Limited")
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                    ]),
                                    _c("div", { staticClass: "input-check" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.editPromotion.coupon_type,
                                            expression:
                                              "editPromotion.coupon_type",
                                          },
                                        ],
                                        attrs: {
                                          id: "unlimited",
                                          type: "radio",
                                        },
                                        domProps: {
                                          value: 1,
                                          checked: _vm._q(
                                            _vm.editPromotion.coupon_type,
                                            1
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.editPromotion,
                                              "coupon_type",
                                              1
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "input-check__label",
                                          attrs: { for: "unlimited" },
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "input-check__mark",
                                          }),
                                          _c("span", {
                                            staticClass: "input-check__text",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t("Unlimited")
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.editPromotion.type === 1 &&
                                  _vm.editPromotion.coupon_type === 1,
                                expression:
                                  "\n                editPromotion.type === 1 && editPromotion.coupon_type === 1\n              ",
                              },
                            ],
                            staticClass: "coupon-statistic__field",
                          },
                          [
                            _c("div", {
                              staticClass: "coupon-statistic__label",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("redeem conditions")
                                ),
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "coupon-statistic__input" },
                              [
                                _c("div", { staticClass: "input-date" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.editPromotion.times_per_client,
                                        expression:
                                          "editPromotion.times_per_client",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.editPromotion.times_per_client,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.editPromotion,
                                          "times_per_client",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "coupon-statistic-times" },
                                  [
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("time per client")
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm.editPromotion.type === 1 &&
                    _vm.editPromotion.coupon_type === 1
                      ? _c("div", { staticClass: "coupon-code" }, [
                          _c("div", { staticClass: "hr" }),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.coupon_code,
                                expression: "coupon_code",
                              },
                            ],
                            staticClass: "edit-copon",
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t("coupon code"),
                            },
                            domProps: { value: _vm.coupon_code },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.coupon_code = $event.target.value
                              },
                            },
                          }),
                          _c("div", { staticClass: "hr" }),
                        ])
                      : _vm._e(),
                    _vm.editPromotion.type === 1 &&
                    _vm.editPromotion.coupon_type === 0
                      ? _c(
                          "div",
                          { staticClass: "coupon-statistic__num-wrap" },
                          [
                            _c("div", {
                              staticClass: "coupon-statistic__label",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("number of coupons")
                                ),
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "coupon-statistic__num" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: _vm.numberOfCoupons,
                                      expression: "numberOfCoupons",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  attrs: { type: "number" },
                                  domProps: { value: _vm.numberOfCoupons },
                                  on: {
                                    change: _vm.setCoupons,
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.numberOfCoupons = _vm._n(
                                        $event.target.value
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "coupon-statistic__action" },
                                  [
                                    _c("label", { staticClass: "btn-icon" }, [
                                      _c("input", {
                                        attrs: {
                                          type: "file",
                                          accept: ".xlsx",
                                        },
                                        on: { change: _vm.importXlsx },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "btn-icon__note" },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "ic-import",
                                              width: "20",
                                              height: "20",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("div", {
                                        staticClass: "btn-icon__text",
                                        domProps: {
                                          textContent: _vm._s(_vm.$t("Import")),
                                        },
                                      }),
                                    ]),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn-icon",
                                        on: { click: _vm.exportXlsx },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "btn-icon__note" },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                name: "ic-export",
                                                width: "20",
                                                height: "20",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("div", {
                                          staticClass: "btn-icon__text",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Export")
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.editPromotion.type === 1 &&
                    _vm.editPromotion.coupon_type === 0
                      ? _c("div", { staticClass: "coupon-codes" }, [
                          _c(
                            "div",
                            { staticClass: "coupon-codes__list" },
                            _vm._l(_vm.coupon_codes, function (coupon_code) {
                              return _c("div", {
                                key: coupon_code,
                                staticClass: "coupon-codes__item",
                                domProps: { textContent: _vm._s(coupon_code) },
                              })
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "coupon-statistic__btn" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn_border",
                          attrs: { href: "#" },
                          on: { click: _vm.backStep },
                        },
                        [
                          _c("svg-icon", {
                            attrs: {
                              name: "ic-arrow-left",
                              width: "16",
                              height: "12",
                            },
                          }),
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Back")) },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "button",
                        { staticClass: "btn", on: { click: _vm.save } },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(_vm.$t("Publish")),
                            },
                          }),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }