var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "catalog-editor-edit-item-printing-lines" },
    [
      _c("div", { staticClass: "new-item-info__top" }, [
        _c("div", {
          staticClass: "new-item-info__head",
          domProps: {
            textContent: _vm._s(
              _vm.he ||
                !_vm.itemCopy.name_en ||
                _vm.itemCopy.name_en === "undefined"
                ? _vm.itemCopy.name
                : _vm.itemCopy.name_en
            ),
          },
        }),
      ]),
      _vm.productionLines.length
        ? [
            _c(
              "div",
              { staticClass: "availability-check-list rp4" },
              _vm._l(_vm.typesEnumValues, function (k) {
                return _c(
                  "label",
                  {
                    key: _vm.typeEnumToStr(k).replace(" ", "_"),
                    staticClass: "availability-check",
                    class: { active: _vm.key === k },
                    on: {
                      click: function ($event) {
                        _vm.key = k
                      },
                    },
                  },
                  [
                    _c("svg-icon", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.key ===
                            _vm
                              .typeEnumToStr(k)
                              .toLowerCase()
                              .replace(" ", "_"),
                          expression:
                            "key === typeEnumToStr(k).toLowerCase().replace(' ', '_')",
                        },
                      ],
                      attrs: {
                        name: "availability-check-v",
                        width: "16",
                        height: "12",
                      },
                    }),
                    _c("svg-icon", {
                      staticClass: "icon",
                      attrs: {
                        name:
                          "ic-availability-" +
                          _vm.typeEnumToStr(k).toLowerCase().replace(" ", "_"),
                        width: "30",
                        height: "25",
                      },
                    }),
                    _c("span", {
                      staticClass: "input-check__text",
                      domProps: {
                        textContent: _vm._s(_vm.$t(_vm.typeEnumToStr(k))),
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
            _c("div", { staticClass: "table-container" }, [
              _c("table", [
                _c(
                  "thead",
                  _vm._l(
                    [
                      "",
                      "open",
                      "in_preparation",
                      "ready",
                      "on_delivery",
                      "closed",
                      "canceled",
                    ],
                    function (k) {
                      return _c("td", {
                        key: k,
                        domProps: {
                          textContent: _vm._s(_vm.$t(_vm.capitalize(k))),
                        },
                      })
                    }
                  ),
                  0
                ),
                _c(
                  "tbody",
                  _vm._l(_vm.productionLines, function (line) {
                    return _c(
                      "tr",
                      { key: line.id },
                      [
                        _c("td", [
                          _c("div", {
                            staticClass: "shadow",
                            domProps: { textContent: _vm._s(line.name) },
                          }),
                          _c("div", {
                            staticClass: "first",
                            domProps: { textContent: _vm._s(line.name) },
                          }),
                        ]),
                        _vm._l(
                          [
                            "open",
                            "in_preparation",
                            "ready",
                            "on_delivery",
                            "closed",
                            "canceled",
                          ],
                          function (k) {
                            return _c("td", { key: k }, [
                              _c(
                                "label",
                                [
                                  _c("checkbox", {
                                    attrs: {
                                      name: k + line.id,
                                      value: _vm.checkboxValue(k, line.id),
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.checkboxInput(
                                          $event,
                                          k,
                                          line.id
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          }
                        ),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]
        : _c("button", {
            staticClass: "new-production-line",
            domProps: { textContent: _vm._s(_vm.$t("Create new line")) },
            on: {
              click: function ($event) {
                _vm.props.popup = true
              },
            },
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }