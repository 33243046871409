var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "messengers" },
    [
      _c(
        "table-list",
        _vm._b(
          { on: { changeOptions: _vm.changeOptions } },
          "table-list",
          _vm.dataTable,
          false
        )
      ),
      _vm.changeMoneyboxIsOpen
        ? _c("change-moneybox-dialog", {
            staticClass: "change-moneybox-dialog",
            attrs: { user: _vm.messengerInEdit },
            on: { save: _vm.settleup },
          })
        : _vm._e(),
      _vm.addSurplusIsOpen
        ? _c("add-surplus-dialog", {
            staticClass: "add-surplus-dialog",
            attrs: { user: _vm.messengerInEdit },
            on: { save: _vm.settleup2 },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }