var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: { width: "850px" },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "popup__content pb40" },
        [
          _c(
            "popup-table-list",
            _vm._b(
              { staticClass: "popup_table" },
              "popup-table-list",
              _vm.dataTable,
              false
            )
          ),
        ],
        1
      ),
      _c("div", { staticClass: "coupon_nav_btn" }, [
        _c("button", { staticClass: "btn", on: { click: _vm.nav } }, [
          _c("span", {
            domProps: { textContent: _vm._s(_vm.$t("Go to coupon page")) },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }