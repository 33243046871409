var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      staticClass: "dialog-data-per-branch",
      staticStyle: { width: "100%" },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-tabs",
            {
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c("v-tab", [
                _c("span", {
                  staticClass: "total",
                  staticStyle: { color: "black" },
                  domProps: { textContent: _vm._s(_vm.$t("General analysis")) },
                }),
              ]),
              _c("v-tab", [
                _c("span", {
                  staticClass: "total",
                  staticStyle: { color: "black" },
                  domProps: {
                    textContent: _vm._s(_vm.$t("Financial analysis")),
                  },
                }),
              ]),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("v-data-table", {
                            staticClass: "admin-table tableAllInfo",
                            attrs: {
                              headers: _vm.fields,
                              "items-per-page": _vm.items.length,
                              items: _vm.itemsOfAllInfo,
                              "hide-default-footer": true,
                              hideDefaultHeader: false,
                              "fixed-header": true,
                              height: 800,
                              "mobile-breakpoint": 0,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("v-data-table", {
                            staticClass: "admin-table tableAllInfo",
                            attrs: {
                              headers: _vm.paymentsfields,
                              "items-per-page": _vm.itemsOfAllPayments.length,
                              items: _vm.itemsOfAllPayments,
                              "hide-default-footer": true,
                              hideDefaultHeader: false,
                              "fixed-header": true,
                              height: 800,
                              "mobile-breakpoint": 0,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }