var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "catalog-editor-edit-item" },
    [
      _vm.show
        ? _c(
            "base-dialog",
            { attrs: { width: "1117px" }, on: { close: _vm.exit } },
            [
              _c("div", { staticClass: "wrapper open-sidebar" }, [
                _c("div", { staticClass: "new-item-wrap" }, [
                  _c(
                    "div",
                    {
                      staticClass: "popup-wrap",
                      staticStyle: { width: "1117px" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "popup" },
                        [
                          _c("close-popup-btn", {
                            attrs: { top: "12px", end: "20px" },
                            on: { close: _vm.exit },
                          }),
                          _c("div", { staticClass: "new-item" }, [
                            _c(
                              "div",
                              { staticClass: "new-item__content" },
                              [
                                _c("div", { staticClass: "new-item__main" }, [
                                  _c("div", { staticClass: "tabs-page" }, [
                                    _c(
                                      "div",
                                      { staticClass: "tabs-page__list" },
                                      [
                                        _c("button", {
                                          staticClass: "tabs-page__item",
                                          class: {
                                            active: _vm.tab === "General info",
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("General info")
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.tab = "General info"
                                            },
                                          },
                                        }),
                                        _c("button", {
                                          staticClass: "tabs-page__item",
                                          class: {
                                            active: _vm.tab === "Steps",
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("catalogeditor Steps")
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.tab = "Steps"
                                            },
                                          },
                                        }),
                                        _c("button", {
                                          staticClass: "tabs-page__item",
                                          class: {
                                            active: _vm.tab === "Pricing",
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Pricing")
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.tab = "Pricing"
                                            },
                                          },
                                        }),
                                        _c("button", {
                                          staticClass: "tabs-page__item",
                                          class: {
                                            active: _vm.tab === "Availability",
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Availability")
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.tab = "Availability"
                                            },
                                          },
                                        }),
                                        _c("button", {
                                          staticClass: "tabs-page__item",
                                          class: {
                                            active: _vm.tab === "branches",
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Branches")
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.tab = "branches"
                                            },
                                          },
                                        }),
                                        _c("button", {
                                          staticClass: "tabs-page__item",
                                          class: {
                                            active: _vm.tab === "promotions",
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Promotions")
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.tab = "promotions"
                                            },
                                          },
                                        }),
                                        _c("button", {
                                          staticClass: "tabs-page__item",
                                          class: {
                                            active: _vm.tab === "make lines",
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Make lines")
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.tab = "make lines"
                                            },
                                          },
                                        }),
                                        !!_vm.item.kds_settings
                                          ? _c("button", {
                                              staticClass: "tabs-page__item",
                                              class: {
                                                active: _vm.tab === "kds",
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t("KDS")
                                                ),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.tab = "kds"
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _c("button", {
                                          staticClass: "tabs-page__item",
                                          class: {
                                            active: _vm.tab === "Images",
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Images")
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.tab = "Images"
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "tabs-page__content" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tabs-page__content-item general-info",
                                            class: {
                                              active:
                                                _vm.tab === "General info",
                                            },
                                          },
                                          [
                                            _c(
                                              "catalog-editor-edit-item-general",
                                              {
                                                attrs: {
                                                  itemCopy: _vm.itemCopy,
                                                },
                                                on: {
                                                  openSetting: function (
                                                    $event
                                                  ) {
                                                    _vm.settingIsOpen = true
                                                  },
                                                },
                                              }
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tabs-page__content-item groups",
                                            class: {
                                              active: _vm.tab === "Steps",
                                            },
                                          },
                                          [
                                            _vm.itemCopy.item_type === 2
                                              ? _c(
                                                  "catalog-editor-edit-item-combination",
                                                  {
                                                    attrs: {
                                                      props:
                                                        _vm.combinationProps,
                                                      itemCopy: _vm.itemCopy,
                                                    },
                                                    on: {
                                                      setOrdersCombinations:
                                                        _vm.setOrdersCombinations,
                                                      extrasDiscount: function (
                                                        $event
                                                      ) {
                                                        _vm.popupExtrasDiscount = true
                                                      },
                                                    },
                                                  }
                                                )
                                              : _c(
                                                  "catalog-editor-edit-item-extra-categories",
                                                  {
                                                    attrs: {
                                                      props:
                                                        _vm.extraCategoryProps,
                                                      itemCopy: _vm.itemCopy,
                                                    },
                                                  }
                                                ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tabs-page__content-item pricing",
                                            class: {
                                              active: _vm.tab === "Pricing",
                                            },
                                          },
                                          [
                                            _c(
                                              "catalog-editor-edit-item-pricing",
                                              {
                                                attrs: {
                                                  itemCopy: _vm.itemCopy,
                                                  tax: _vm.tax,
                                                },
                                                on: {
                                                  "update:itemCopy": function (
                                                    $event
                                                  ) {
                                                    _vm.itemCopy = $event
                                                  },
                                                },
                                              }
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tabs-page__content-item availability",
                                            class: {
                                              active:
                                                _vm.tab === "Availability",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "new-item-info__top",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "new-item-info__head",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.he ||
                                                        !_vm.itemCopy.name_en ||
                                                        _vm.itemCopy.name_en ===
                                                          "undefined"
                                                        ? _vm.itemCopy.name
                                                        : _vm.itemCopy.name_en
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c("availability-tab", {
                                              attrs: {
                                                availability:
                                                  _vm.itemCopy.availability,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tabs-page__content-item",
                                            class: {
                                              active: _vm.tab === "branches",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "new-item-info__top",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "new-item-info__head",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.he ||
                                                        !_vm.itemCopy.name_en ||
                                                        _vm.itemCopy.name_en ===
                                                          "undefined"
                                                        ? _vm.itemCopy.name
                                                        : _vm.itemCopy.name_en
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c("branches-tab", {
                                              attrs: {
                                                activity: "",
                                                showOnlyThisBranch:
                                                  !!_vm.branchId,
                                                availability:
                                                  _vm.itemCopy.availability,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tabs-page__content-item promotions",
                                            class: {
                                              active: _vm.tab === "promotions",
                                            },
                                          },
                                          [
                                            _c(
                                              "catalog-editor-edit-item-promotions",
                                              {
                                                attrs: {
                                                  itemCopy: _vm.itemCopy,
                                                  props: _vm.propsPromotions,
                                                },
                                              }
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tabs-page__content-item make-lines",
                                            class: {
                                              active: _vm.tab === "make lines",
                                            },
                                          },
                                          [
                                            _vm.tab === "make lines"
                                              ? _c(
                                                  "catalog-editor-edit-item-make-lines",
                                                  {
                                                    attrs: {
                                                      props: _vm.propsMakeLines,
                                                      itemCopy: _vm.itemCopy,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tabs-page__content-item kds",
                                            class: {
                                              active: _vm.tab === "kds",
                                            },
                                          },
                                          [
                                            _vm.tab === "kds"
                                              ? _c("kds", {
                                                  key: "kds",
                                                  attrs: {
                                                    extras: true,
                                                    kdsSettings:
                                                      _vm.itemCopy.kds_settings,
                                                  },
                                                })
                                              : _vm._e(),
                                            _c("kds-names", {
                                              model: {
                                                value: _vm.itemCopy.kds_names,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.itemCopy,
                                                    "kds_names",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "itemCopy.kds_names",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tabs-page__content-item images",
                                            class: {
                                              active: _vm.tab === "Images",
                                            },
                                          },
                                          [
                                            _c(
                                              "catalog-editor-edit-item-setting-images",
                                              {
                                                attrs: {
                                                  itemCopy: _vm.itemCopy,
                                                },
                                              }
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-site__btn d-none d-md-flex",
                                      },
                                      [
                                        _vm.itemCopy.id
                                          ? _c("remove-btn", {
                                              on: { click: _vm.remove },
                                            })
                                          : _c("div", {}),
                                        _vm.itemCopy.id
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn-icon btn-icon_yellow",
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.duplicate.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "btn-icon__note",
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      attrs: {
                                                        name: "ic-document",
                                                        width: "20",
                                                        height: "20",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c("span", {
                                                  staticClass:
                                                    "btn-icon__text flex a-center",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t("Duplicate")
                                                    ),
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn",
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.save.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t("save")
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                                _c("catalog-editor-edit-item-sidebar", {
                                  attrs: { itemCopy: _vm.itemCopy },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "new-item__btn d-flex d-md-none",
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "save-btn",
                                        on: { click: _vm.save },
                                      },
                                      [
                                        _c("svg-icon", {
                                          staticClass: "ic-edit",
                                          attrs: {
                                            name: "ic-save-btn",
                                            width: "93",
                                            height: "93",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.itemCopy.id
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "duplicate-btn",
                                            on: { click: _vm.duplicate },
                                          },
                                          [
                                            _c("svg-icon", {
                                              staticClass: "ic-edit",
                                              attrs: {
                                                name: "ic-document",
                                                width: "24",
                                                height: "24",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "bin-btn",
                                        on: { click: _vm.remove },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            name: "ic-bin",
                                            width: "18",
                                            height: "20",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm.combinationProps.editCombinationIsOpened
        ? _c("edit-combination", {
            attrs: {
              combinationInEdit: _vm.combinationProps.combinationInEdit,
            },
            on: {
              save: _vm.combinationProps.saveCombination,
              remove: _vm.combinationProps.removeCombination,
            },
          })
        : _vm._e(),
      _vm.extraCategoryProps.editExtraCategoryIsOpened
        ? _c("catalog-editor-edit-extra-category", {
            attrs: {
              extraCategory: _vm.extraCategoryProps.extraCategoryInEdit,
            },
            on: {
              remove: _vm.extraCategoryProps.removeExtraCategory,
              save: _vm.extraCategoryProps.saveExtraCategory,
            },
          })
        : _vm._e(),
      _vm.settingIsOpen
        ? _c("catalog-editor-edit-item-setting-mobile", {
            attrs: { item: _vm.itemCopy },
            on: {
              save: _vm.saveSetting,
              exit: function ($event) {
                _vm.settingIsOpen = false
              },
            },
          })
        : _vm._e(),
      _vm.propsPromotions.promotionInEdit
        ? _c("promotion-edit-dialog", {
            attrs: { promotionInEdit: _vm.propsPromotions.promotionInEdit },
            on: {
              save: _vm.propsPromotions.savePromotion,
              removePromotion: _vm.propsPromotions.removePromotion,
            },
          })
        : _vm._e(),
      _vm.popupExtrasDiscount
        ? _c("extras-discount-dialog", {
            attrs: { itemCopy: _vm.itemCopy },
            on: { save: _vm.saveExtrasDiscount },
          })
        : _vm._e(),
      _vm.propsMakeLines.popup
        ? _c("edit-make-line", {
            attrs: { makeLine: _vm.propsMakeLines.newMakeLine },
            on: {
              save: _vm.propsMakeLines.saveMakeLine,
              close: _vm.propsMakeLines.saveMakeLine,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }