var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "backdrop",
    style: { "z-index": _vm.zIndex },
    on: { click: _vm.onClose },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }