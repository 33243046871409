var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.profile
    ? _c(
        "div",
        {
          staticClass: "header-profile-container flex col",
          class: { opened: _vm.opened, viewSearch: _vm.viewSearch },
          on: {
            mouseenter: function ($event) {
              _vm.opened = true
            },
            mouseleave: function ($event) {
              _vm.opened = false
            },
          },
        },
        [
          _c("div", { staticClass: "header-profile-container-opened" }, [
            _c(
              "div",
              {
                staticClass: "flex a-center bet w100 pointer",
                attrs: { role: "button", tabindex: "0" },
                on: { click: _vm.profileEdit },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "header-profile flex a-center j-end",
                    on: { touchend: _vm.toggle },
                  },
                  [
                    _c("span", {
                      staticClass: "header-profile__name",
                      domProps: { textContent: _vm._s(_vm.userName) },
                    }),
                    _c("user-avatar", {
                      attrs: {
                        avatarClass: "avatar-header",
                        user: _vm.profile,
                        "img-class": "header-profile__img",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm.opened
              ? _c(
                  "div",
                  { staticClass: "profile-dropdown" },
                  [
                    0
                      ? _c(
                          "transition",
                          { attrs: { name: "fade" } },
                          [_c("time-clock")],
                          1
                        )
                      : _vm._e(),
                    _c("transition", { attrs: { name: "fade" } }, [
                      _c("div", { staticClass: "flex bet a-center" }, [
                        _c("img", {
                          staticClass: "pointer opacity-hover-8",
                          attrs: {
                            src: _vm.imgFlagUrl,
                            alt: "Change language",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changeLocale(
                                _vm.$i18n.locale === "he" ? "en" : "he"
                              )
                            },
                          },
                        }),
                        _c(
                          "button",
                          {
                            staticClass: "mis16",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.logout.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "m--end-1ch",
                              attrs: {
                                src: require("../assets/img/svg/logout.svg"),
                                alt: "⇒",
                              },
                            }),
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(_vm.$t("Logout")),
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }