var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      staticClass: "other-pay-wrap",
      attrs: { width: "676px", title: "Payment methods" },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("div", { staticClass: "popup__content" }, [
        _c("div", { staticClass: "other-pay" }, [
          _c(
            "div",
            { staticClass: "other-pay__list" },
            _vm._l(_vm.paymentMethods, function (key) {
              return _c(
                "label",
                { key: key, staticClass: "other-pay__item" },
                [
                  _c("span", { staticClass: "other-pay__info" }, [
                    _c("span", {
                      staticClass: "other-pay__title",
                      domProps: {
                        textContent: _vm._s(_vm.$t(_vm.capitalize(key))),
                      },
                    }),
                    key === "cash"
                      ? _c("span", { staticClass: "form-site" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.settingsCopy.max_cash_pay,
                                expression: "settingsCopy.max_cash_pay",
                              },
                            ],
                            attrs: {
                              type: "number",
                              placeholder: _vm.$t("Max cash payment"),
                              title: _vm.$t("Max cash payment"),
                            },
                            domProps: { value: _vm.settingsCopy.max_cash_pay },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.settingsCopy,
                                  "max_cash_pay",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ])
                      : _vm._e(),
                  ]),
                  _c("swich-button", {
                    staticClass: "switch_small",
                    model: {
                      value: _vm.settingsCopy[key],
                      callback: function ($$v) {
                        _vm.$set(_vm.settingsCopy, key, $$v)
                      },
                      expression: "settingsCopy[key]",
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "popup__btn" }, [
          _c("button", {
            staticClass: "btn",
            domProps: { textContent: _vm._s(_vm.$t("Save")) },
            on: { click: _vm.save },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }