var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      staticClass: "create-atmos-promotion-dialog",
      attrs: {
        title: _vm.addPromotionsTitle,
        overflowAuto: "",
        width: "800px",
      },
      on: { close: _vm.close },
    },
    [
      _c("div", { staticClass: "popup__content" }, [
        _c("div", { staticClass: "choose-steps" }, [
          _vm.step < 7
            ? _c("div", { staticClass: "num-step_newpromo" }, [
                _c("div", {
                  staticClass: "num-step_newpromo__item",
                  class: { active: _vm.step === 1, checked: _vm.step > 1 },
                }),
                _c("div", {
                  staticClass: "num-step_newpromo__item",
                  class: { active: _vm.step === 2, checked: _vm.step > 2 },
                }),
                _c("div", {
                  staticClass: "num-step_newpromo__item",
                  class: { active: _vm.step === 3, checked: _vm.step > 3 },
                }),
                _c("div", {
                  staticClass: "num-step_newpromo__item",
                  class: { active: _vm.step === 4, checked: _vm.step > 4 },
                }),
                _c("div", {
                  staticClass: "num-step_newpromo__item",
                  class: { active: _vm.step === 5, checked: _vm.step > 5 },
                }),
                _c("div", {
                  staticClass: "num-step_newpromo__item",
                  class: { active: _vm.step === 6 },
                }),
              ])
            : _vm._e(),
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _vm.step === 1
                ? _c("div", { staticClass: "choose-promo flex col a-center" }, [
                    _c(
                      "div",
                      { staticClass: "top_header_p" },
                      [
                        _c("form-site-field-text", {
                          staticClass: "promotion_name",
                          attrs: { title: "Promotion name" },
                          model: {
                            value: _vm.promotion.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.promotion, "name", $$v)
                            },
                            expression: "promotion.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "top_header_p" },
                      [
                        _vm.english
                          ? _c("form-site-field-text", {
                              staticClass: "promotion_name",
                              attrs: { title: "Promotion title" },
                              model: {
                                value: _vm.promotion.title.en,
                                callback: function ($$v) {
                                  _vm.$set(_vm.promotion.title, "en", $$v)
                                },
                                expression: "promotion.title.en",
                              },
                            })
                          : _c("form-site-field-text", {
                              staticClass: "promotion_name",
                              attrs: { title: "Promotion title" },
                              model: {
                                value: _vm.promotion.title.he,
                                callback: function ($$v) {
                                  _vm.$set(_vm.promotion.title, "he", $$v)
                                },
                                expression: "promotion.title.he",
                              },
                            }),
                        _c(
                          "div",
                          {
                            staticClass: "lang lang_grey md-none",
                            staticStyle: { "max-height": "50px" },
                          },
                          [
                            _c("button", {
                              staticClass: "lang__item",
                              class: { active: _vm.english },
                              domProps: {
                                textContent: _vm._s(_vm.$t("English")),
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.english = true
                                },
                              },
                            }),
                            _c("button", {
                              staticClass: "lang__item",
                              class: { active: !_vm.english },
                              domProps: {
                                textContent: _vm._s(_vm.$t("Hebrew")),
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.english = false
                                },
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "description_promotion" }, [
                      _c("div", { staticClass: "form-site__field" }, [
                        _c("div", {
                          staticClass: "form-site__label",
                          domProps: {
                            textContent: _vm._s(_vm.$t("description")),
                          },
                        }),
                        _c("div", { staticClass: "form-site__input" }, [
                          _vm.english
                            ? _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.promotion.description.en,
                                    expression: "promotion.description.en",
                                  },
                                ],
                                attrs: {
                                  placeholder: _vm.$t("Write your description"),
                                },
                                domProps: {
                                  value: _vm.promotion.description.en,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.promotion.description,
                                      "en",
                                      $event.target.value
                                    )
                                  },
                                },
                              })
                            : _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.promotion.description.he,
                                    expression: "promotion.description.he",
                                  },
                                ],
                                attrs: {
                                  placeholder: _vm.$t("Write your description"),
                                },
                                domProps: {
                                  value: _vm.promotion.description.he,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.promotion.description,
                                      "he",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                        ]),
                      ]),
                    ]),
                    _c("div", {
                      staticStyle: {
                        border: "1px solid #eaeaea",
                        width: "100%",
                        "margin-top": "20px",
                        "margin-bottom": "35px",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "main-settings__imgs",
                        staticStyle: {
                          display: "flex",
                          width: "100%",
                          "justify-content": "center",
                          gap: "20px",
                        },
                      },
                      [
                        _c("div", { staticClass: "main-settings__img-item" }, [
                          _c("div", {
                            staticClass: "main-settings__label",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Desktop image")),
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "edit-logo",
                              class: {
                                "edit-img__content_empty":
                                  !_vm.image_desktopToShow,
                              },
                              style: {
                                backgroundImage:
                                  "url(" + _vm.image_desktopToShow + ")",
                              },
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "edit-logo__icon" },
                                [
                                  _vm.image_desktopToShow
                                    ? _c(
                                        "button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeImage(
                                                "image_desktop"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "ic-add_button_red",
                                              width: "60",
                                              height: "60",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : [
                                        _c("input", {
                                          attrs: {
                                            type: "file",
                                            name: "logo",
                                            accept: "image/*",
                                          },
                                          on: { change: _vm.enterPhotodesktop },
                                        }),
                                        _c("svg-icon", {
                                          attrs: {
                                            name: "ic-add_button_red",
                                            width: "60",
                                            height: "60",
                                          },
                                        }),
                                      ],
                                ],
                                2
                              ),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "main-settings__img-item" }, [
                          _c("div", {
                            staticClass: "main-settings__label",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Mobile image")),
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "edit-logo",
                              class: {
                                "edit-img__content_empty":
                                  !_vm.image_mobileToShow,
                              },
                              style: {
                                backgroundImage:
                                  "url(" + _vm.image_mobileToShow + ")",
                              },
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "edit-logo__icon" },
                                [
                                  _vm.image_mobileToShow
                                    ? _c(
                                        "button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeImage(
                                                "image_mobile"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "ic-add_button_red",
                                              width: "60",
                                              height: "60",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : [
                                        _c("input", {
                                          attrs: {
                                            type: "file",
                                            name: "logo",
                                            accept: "image/*",
                                          },
                                          on: { change: _vm.enterPhotoMobail },
                                        }),
                                        _c("svg-icon", {
                                          attrs: {
                                            name: "ic-add_button_red",
                                            width: "60",
                                            height: "60",
                                          },
                                        }),
                                      ],
                                ],
                                2
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _c("div", {
                      staticStyle: {
                        border: "1px solid #eaeaea",
                        width: "100%",
                        "margin-top": "20px",
                        "margin-bottom": "35px",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "coupon-buy__info",
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                          gap: "10px",
                          width: "100%",
                        },
                      },
                      [
                        _c("div", {
                          staticClass: "main-settings__label",
                          domProps: { textContent: _vm._s(_vm.$t("Audience")) },
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "coupon-buy__list coupon-buy__list_full times",
                            staticStyle: {
                              display: "flex",
                              "justify-content": "space-between",
                              width: "100%",
                              gap: "10px",
                            },
                          },
                          [
                            _c("div", { staticClass: "input-radio" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.promotion.condition_type,
                                    expression: "promotion.condition_type",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "buyradio",
                                  id: "radio1",
                                },
                                domProps: {
                                  value: 1,
                                  checked: _vm._q(
                                    _vm.promotion.condition_type,
                                    1
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.promotion,
                                      "condition_type",
                                      1
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "input-radio__label",
                                  attrs: { for: "radio1" },
                                },
                                [
                                  _c("span", {
                                    staticClass: "input-radio__mark",
                                  }),
                                  _c("span", {
                                    staticClass: "input-radio__text",
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("All")),
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "input-radio" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.promotion.condition_type,
                                    expression: "promotion.condition_type",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "buyradio",
                                  id: "radio2",
                                },
                                domProps: {
                                  value: 2,
                                  checked: _vm._q(
                                    _vm.promotion.condition_type,
                                    2
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.promotion,
                                      "condition_type",
                                      2
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "input-radio__label",
                                  attrs: { for: "radio2" },
                                },
                                [
                                  _c("span", {
                                    staticClass: "input-radio__mark",
                                  }),
                                  _c("span", {
                                    staticClass: "input-radio__text",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Member`s club")
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "input-radio" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.promotion.condition_type,
                                    expression: "promotion.condition_type",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "buyradio",
                                  id: "radio3",
                                },
                                domProps: {
                                  value: 3,
                                  checked: _vm._q(
                                    _vm.promotion.condition_type,
                                    3
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.promotion,
                                      "condition_type",
                                      3
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "input-radio__label",
                                  attrs: { for: "radio3" },
                                },
                                [
                                  _c("span", {
                                    staticClass: "input-radio__mark",
                                  }),
                                  _c("span", {
                                    staticClass: "input-radio__text",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Custom audience")
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _vm.promotion.condition_type == 3
                      ? _c(
                          "div",
                          {
                            staticClass: "coupon-info__field",
                            staticStyle: {
                              display: "flex",
                              "justify-content": "start",
                              width: "100%",
                              "margin-top": "30px",
                            },
                          },
                          [
                            _c("form-site-field-select-box", {
                              staticClass: "select-box-audiences w200",
                              attrs: {
                                title: "Select custom audience",
                                widthHeader: "200px",
                                width: "200px",
                                firstTop: "",
                                placeholder: _vm.$t("Audience"),
                                showSearch: "",
                                options: _vm.audiencesSelectOptions,
                              },
                              model: {
                                value: _vm.promotion.audience_ids,
                                callback: function ($$v) {
                                  _vm.$set(_vm.promotion, "audience_ids", $$v)
                                },
                                expression: "promotion.audience_ids",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", {
                      staticStyle: {
                        border: "1px solid #eaeaea",
                        width: "100%",
                        "margin-bottom": "35px",
                        "margin-top": "20px",
                      },
                    }),
                    _c("div", { staticClass: "info__footer" }, [
                      _c(
                        "div",
                        {
                          staticClass: "coupon-buy__info",
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                            gap: "10px",
                            width: "100%",
                          },
                        },
                        [
                          _c("div", {
                            staticClass: "main-settings__label",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Addition settings")),
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "coupon-buy__list coupon-buy__list_full",
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "column",
                                width: "100%",
                              },
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "input-check__label mis12" },
                                [
                                  _c("checkbox-second-color", {
                                    attrs: { name: "Multiple-promotions" },
                                    model: {
                                      value: _vm.promotion.multiple_promotions,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.promotion,
                                          "multiple_promotions",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "promotion.multiple_promotions",
                                    },
                                  }),
                                  _c("span", {
                                    staticClass: "input-check__text",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Multiple promotions")
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "label",
                                {
                                  staticClass: "input-check__label mis12",
                                  staticStyle: { "margin-top": "10px" },
                                },
                                [
                                  _c("checkbox-second-color", {
                                    attrs: { name: "points_collection" },
                                    model: {
                                      value: _vm.promotion.points_collection,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.promotion,
                                          "points_collection",
                                          $$v
                                        )
                                      },
                                      expression: "promotion.points_collection",
                                    },
                                  }),
                                  _c("span", {
                                    staticClass: "input-check__text",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Disable points collection")
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "label",
                                {
                                  staticClass: "input-check__label mis12",
                                  staticStyle: { "margin-top": "10px" },
                                },
                                [
                                  _c("checkbox-second-color", {
                                    attrs: { name: "valid_from_second_order" },
                                    model: {
                                      value:
                                        _vm.promotion.valid_from_second_order,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.promotion,
                                          "valid_from_second_order",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "promotion.valid_from_second_order",
                                    },
                                  }),
                                  _c("span", {
                                    staticClass: "input-check__text",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Valid from second order only")
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "coupon-info-period",
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                            width: "100%",
                            "align-items": "flex-start",
                          },
                        },
                        [
                          _c("div", {
                            staticClass: "main-settings__label",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("Maximum times per order")
                              ),
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "maximum_timer_per_order" },
                            [
                              _c("form-site-field-text", {
                                ref: "name",
                                staticClass: "maximum_per_order",
                                attrs: { disabled: _vm.noLimit },
                                model: {
                                  value: _vm.promotion.maximum_per_order,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.promotion,
                                      "maximum_per_order",
                                      $$v
                                    )
                                  },
                                  expression: "promotion.maximum_per_order",
                                },
                              }),
                              _c(
                                "label",
                                { staticClass: "input-check__label mis12" },
                                [
                                  _c("checkbox-second-color", {
                                    attrs: { name: "on-limit" },
                                    on: { input: _vm.setNoLimit },
                                    model: {
                                      value: _vm.noLimit,
                                      callback: function ($$v) {
                                        _vm.noLimit = $$v
                                      },
                                      expression: "noLimit",
                                    },
                                  }),
                                  _c("span", {
                                    staticClass: "input-check__text",
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Unlimited")),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.step === 2
                ? _c("div", { staticClass: "choose-promo flex col a-center" }, [
                    _c(
                      "div",
                      {
                        staticClass: "main-settings__imgs",
                        staticStyle: {
                          display: "flex",
                          width: "100%",
                          "justify-content": "center",
                          gap: "20px",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "main-settings__img-item" },
                          [
                            _c("div", {
                              staticClass: "main-settings__label",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Trigger")),
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "trigger_btns",
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "row",
                                  gap: "15px",
                                  width: "100%",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "edit-logo logo2 l1",
                                    class: {
                                      "edit-img__content_empty2":
                                        !_vm.image_desktopToShow,
                                    },
                                    attrs: { id: "l1" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeTrigger(1)
                                      },
                                    },
                                  },
                                  [
                                    _c("label", [
                                      _c("button", { staticClass: "btn_a" }, [
                                        _c("span", {
                                          staticClass: "trigger-text",
                                          attrs: { id: "au1" },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Automatically")
                                            ),
                                          },
                                        }),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "edit-logo logo2 l2",
                                    class: {
                                      "edit-img__content_empty2":
                                        !_vm.image_desktopToShow,
                                    },
                                    attrs: { id: "l2" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeTrigger(2)
                                      },
                                    },
                                  },
                                  [
                                    _c("label", [
                                      _c("button", { staticClass: "btn_a" }, [
                                        _c("span", {
                                          staticClass: "trigger-text",
                                          attrs: { id: "au2" },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Coupon")
                                            ),
                                          },
                                        }),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "edit-logo logo2 l3",
                                    class: {
                                      "edit-img__content_empty2":
                                        !_vm.image_desktopToShow,
                                    },
                                    attrs: { id: "l3" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeTrigger(3)
                                      },
                                    },
                                  },
                                  [
                                    _c("label", [
                                      _c("button", { staticClass: "btn_a" }, [
                                        _c("span", {
                                          staticClass: "trigger-text",
                                          attrs: { id: "au3" },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Selection")
                                            ),
                                          },
                                        }),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "edit-logo logo2 l4",
                                    class: {
                                      "edit-img__content_empty2":
                                        !_vm.image_desktopToShow,
                                    },
                                    attrs: { id: "l4" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeTrigger(4)
                                      },
                                    },
                                  },
                                  [
                                    _c("label", [
                                      _c("button", { staticClass: "btn_a" }, [
                                        _c("span", {
                                          staticClass: "trigger-text",
                                          attrs: { id: "au4" },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Upsell")
                                            ),
                                          },
                                        }),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _vm.promotion.trigger == 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "coupon-buy__info",
                                    staticStyle: {
                                      display: "flex",
                                      "flex-direction": "column",
                                      gap: "10px",
                                      width: "100%",
                                      "margin-top": "30px",
                                      "margin-bottom": "30px",
                                    },
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "main-settings__label",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Using a coupon")
                                        ),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "coupon-buy__list coupon-buy__list_full",
                                        staticStyle: {
                                          display: "flex",
                                          "justify-content": "start",
                                          width: "100%",
                                          gap: "10px",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "input-radio" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.promotion.using_coupon,
                                                  expression:
                                                    "promotion.using_coupon",
                                                },
                                              ],
                                              attrs: {
                                                type: "radio",
                                                name: "buyradio",
                                                id: "radio1",
                                              },
                                              domProps: {
                                                value: 1,
                                                checked: _vm._q(
                                                  _vm.promotion.using_coupon,
                                                  1
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.$set(
                                                    _vm.promotion,
                                                    "using_coupon",
                                                    1
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "input-radio__label",
                                                attrs: { for: "radio1" },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "input-radio__mark",
                                                }),
                                                _c("span", {
                                                  staticClass:
                                                    "input-radio__text",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t("Reusable coupon")
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "input-radio" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.promotion.using_coupon,
                                                  expression:
                                                    "promotion.using_coupon",
                                                },
                                              ],
                                              attrs: {
                                                type: "radio",
                                                name: "buyradio",
                                                id: "radio2",
                                              },
                                              domProps: {
                                                value: 2,
                                                checked: _vm._q(
                                                  _vm.promotion.using_coupon,
                                                  2
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.$set(
                                                    _vm.promotion,
                                                    "using_coupon",
                                                    2
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "input-radio__label",
                                                attrs: { for: "radio2" },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "input-radio__mark",
                                                }),
                                                _c("span", {
                                                  staticClass:
                                                    "input-radio__text",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t("Coupons banks")
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.promotion.banks.length == 0
                              ? _c("div", {
                                  staticStyle: {
                                    border: "1px solid #eaeaea",
                                    width: "100%",
                                    "margin-top": "30px",
                                    "margin-bottom": "30px",
                                  },
                                })
                              : _vm._e(),
                            _vm.promotion.trigger == 2 &&
                            _vm.promotion.using_coupon == 2
                              ? _vm._l(_vm.promotion.banks, function (bank, i) {
                                  return _c(
                                    "div",
                                    { key: i, staticClass: "hook" },
                                    [
                                      i > 0
                                        ? _c("div", {
                                            staticStyle: {
                                              border: "1px solid #eaeaea",
                                              width: "100%",
                                              "margin-top": "30px",
                                              "margin-bottom": "30px",
                                            },
                                          })
                                        : _vm._e(),
                                      _c("div", { staticClass: "bank_main" }, [
                                        _c("div", { staticClass: "bank_top" }, [
                                          _c("h3", {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t("Bank") + " " + (i + 1)
                                              ),
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "bank_actions" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticStyle: {
                                                    "border-radius": "100px",
                                                    width: "120px",
                                                    height: "40px",
                                                    background: "#f28f160f",
                                                    display: "flex",
                                                    "align-items": "center",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "btn-icon__note",
                                                      staticStyle: {
                                                        display: "flex",
                                                        "justify-content":
                                                          "center",
                                                        "align-items": "center",
                                                        position: "relative",
                                                      },
                                                    },
                                                    [
                                                      _c("svg-icon", {
                                                        attrs: {
                                                          name: "ic-import",
                                                          width: "18",
                                                          height: "20",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c("span", {
                                                    staticStyle: {
                                                      color: "#f28f16",
                                                    },
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t("Import")
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "button",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteBank(i)
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass: "icon-btn",
                                                      attrs: {
                                                        viewBox: "0 0 48 48",
                                                        fill: "none",
                                                        height: "40px",
                                                        width: "40px",
                                                      },
                                                    },
                                                    [
                                                      _c("circle", {
                                                        attrs: {
                                                          cx: "24",
                                                          cy: "24",
                                                          r: "24",
                                                          fill: "#FFE0E4",
                                                        },
                                                      }),
                                                      _c("path", {
                                                        attrs: {
                                                          d: "M16 33.332C16 34.8054 17.1933 35.9987 18.6667 35.9987H29.3334C30.8067 35.9987 32 34.8054 32 33.332V17.332H16V33.332Z",
                                                          fill: "#FF465C",
                                                        },
                                                      }),
                                                      _c("path", {
                                                        attrs: {
                                                          d: "M28.6667 13.3333L27.3334 12H20.6667L19.3334 13.3333H14.6667V16H33.3334V13.3333H28.6667Z",
                                                          fill: "#FF465C",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "bank_middle" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "flex-direction": "row",
                                                  "justify-content":
                                                    "space-between",
                                                  width: "100%",
                                                },
                                              },
                                              [
                                                _c("form-site-field-text", {
                                                  ref: "name",
                                                  refInFor: true,
                                                  staticStyle: { width: "60%" },
                                                  attrs: { title: "Name" },
                                                  model: {
                                                    value: bank.name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        bank,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "bank.name",
                                                  },
                                                }),
                                                _c("form-site-field-text", {
                                                  ref: "name",
                                                  refInFor: true,
                                                  attrs: {
                                                    short: "",
                                                    title: "Amount",
                                                  },
                                                  model: {
                                                    value: bank.amount,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        bank,
                                                        "amount",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "bank.amount",
                                                  },
                                                }),
                                                _c("form-site-field-text", {
                                                  ref: "name",
                                                  refInFor: true,
                                                  attrs: {
                                                    short: "",
                                                    title: "Value",
                                                  },
                                                  model: {
                                                    value: bank.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        bank,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "bank.value",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "bank_coupons" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "coupon-buy__info",
                                                    staticStyle: {
                                                      display: "flex",
                                                      "flex-direction":
                                                        "column",
                                                      gap: "20px",
                                                      "margin-top": "10px",
                                                    },
                                                  },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "main-settings__label",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t(
                                                            "Coupon composition"
                                                          )
                                                        ),
                                                      },
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "coupon-buy__list coupon-buy__list_full",
                                                        staticStyle: {
                                                          display: "flex",
                                                          "justify-content":
                                                            "space-between",
                                                          width: "100%",
                                                          gap: "10px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "input-radio",
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    bank.composition,
                                                                  expression:
                                                                    "bank.composition",
                                                                },
                                                              ],
                                                              attrs: {
                                                                type: "radio",
                                                                name: "buyradio",
                                                                id: "radio332",
                                                                value: "num",
                                                              },
                                                              domProps: {
                                                                checked: _vm._q(
                                                                  bank.composition,
                                                                  "num"
                                                                ),
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      bank,
                                                                      "composition",
                                                                      "num"
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "input-radio__label",
                                                                attrs: {
                                                                  for: "radio332",
                                                                },
                                                              },
                                                              [
                                                                _c("span", {
                                                                  staticClass:
                                                                    "input-radio__mark",
                                                                }),
                                                                _c("span", {
                                                                  staticClass:
                                                                    "input-radio__text",
                                                                  domProps: {
                                                                    textContent:
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "Only numbers"
                                                                        )
                                                                      ),
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "input-radio",
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    bank.composition,
                                                                  expression:
                                                                    "bank.composition",
                                                                },
                                                              ],
                                                              attrs: {
                                                                type: "radio",
                                                                name: "buyradio",
                                                                id: "radio331",
                                                                value:
                                                                  "alphanum",
                                                              },
                                                              domProps: {
                                                                checked: _vm._q(
                                                                  bank.composition,
                                                                  "alphanum"
                                                                ),
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      bank,
                                                                      "composition",
                                                                      "alphanum"
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "input-radio__label",
                                                                attrs: {
                                                                  for: "radio331",
                                                                },
                                                              },
                                                              [
                                                                _c("span", {
                                                                  staticClass:
                                                                    "input-radio__mark",
                                                                }),
                                                                _c("span", {
                                                                  staticClass:
                                                                    "input-radio__text",
                                                                  domProps: {
                                                                    textContent:
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "Numbers with letters"
                                                                        )
                                                                      ),
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "coupon-info__field",
                                                    staticStyle: {
                                                      display: "flex",
                                                      "justify-content": "end",
                                                      "margin-top": "10px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "form-site-field-select-box",
                                                      {
                                                        staticClass:
                                                          "select-box-coupon-length w200",
                                                        attrs: {
                                                          title:
                                                            "Coupon length",
                                                          widthHeader: "200px",
                                                          width: "200px",
                                                          firstTop: "",
                                                          placeholder:
                                                            _vm.$t(
                                                              "9(Default)"
                                                            ),
                                                          showSearch: "",
                                                          options:
                                                            _vm.couponLenghtSelectOptions,
                                                        },
                                                        model: {
                                                          value:
                                                            bank.code_length,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              bank,
                                                              "code_length",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "bank.code_length",
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  )
                                })
                              : _vm._e(),
                            _vm.promotion.trigger == 2 &&
                            _vm.promotion.using_coupon == 2
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "center",
                                      width: "100%",
                                      "flex-direction": "column",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c("div", {
                                      staticStyle: {
                                        border: "1px solid #eaeaea",
                                        width: "100%",
                                        "margin-top": "30px",
                                        "margin-bottom": "30px",
                                      },
                                    }),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn create_bank",
                                        on: { click: _vm.addBank },
                                      },
                                      [
                                        _c("svg-icon", {
                                          staticStyle: { fill: "#d60b52" },
                                          attrs: {
                                            name: "ic-plus",
                                            width: "10",
                                            height: "10",
                                          },
                                        }),
                                        _c("span", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Create bank")
                                            ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.promotion.trigger == 2 &&
                            _vm.promotion.using_coupon == 1
                              ? _c("form-site-field-text", {
                                  ref: "name",
                                  staticStyle: { width: "40%" },
                                  attrs: { title: "Name" },
                                  model: {
                                    value: _vm.promotion.reusable_coupon_name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.promotion,
                                        "reusable_coupon_name",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "promotion.reusable_coupon_name",
                                  },
                                })
                              : _vm._e(),
                            _vm.promotion.trigger == 4
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "coupon-buy__info",
                                    staticStyle: {
                                      display: "flex",
                                      "flex-direction": "column",
                                      gap: "10px",
                                      width: "100%",
                                      "margin-top": "30px",
                                      "margin-bottom": "30px",
                                    },
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "main-settings__label",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("When to prompt")
                                        ),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "coupon-buy__list coupon-buy__list_full",
                                        staticStyle: {
                                          display: "flex",
                                          "justify-content": "space-between",
                                          width: "100%",
                                          gap: "10px",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "input-radio" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.promotion
                                                      .when_to_prompt,
                                                  expression:
                                                    "promotion.when_to_prompt",
                                                },
                                              ],
                                              attrs: {
                                                type: "radio",
                                                name: "buyradio",
                                                id: "radio1",
                                              },
                                              domProps: {
                                                value: 1,
                                                checked: _vm._q(
                                                  _vm.promotion.when_to_prompt,
                                                  1
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.$set(
                                                    _vm.promotion,
                                                    "when_to_prompt",
                                                    1
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "input-radio__label",
                                                attrs: { for: "radio1" },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "input-radio__mark",
                                                }),
                                                _c("span", {
                                                  staticClass:
                                                    "input-radio__text",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t("Order start")
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "input-radio" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.promotion
                                                      .when_to_prompt,
                                                  expression:
                                                    "promotion.when_to_prompt",
                                                },
                                              ],
                                              attrs: {
                                                type: "radio",
                                                name: "buyradio",
                                                id: "radio2",
                                              },
                                              domProps: {
                                                value: 2,
                                                checked: _vm._q(
                                                  _vm.promotion.when_to_prompt,
                                                  2
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.$set(
                                                    _vm.promotion,
                                                    "when_to_prompt",
                                                    2
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "input-radio__label",
                                                attrs: { for: "radio2" },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "input-radio__mark",
                                                }),
                                                _c("span", {
                                                  staticClass:
                                                    "input-radio__text",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t("Meet conditions")
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "input-radio" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.promotion
                                                      .when_to_prompt,
                                                  expression:
                                                    "promotion.when_to_prompt",
                                                },
                                              ],
                                              attrs: {
                                                type: "radio",
                                                name: "buyradio",
                                                id: "radio3",
                                              },
                                              domProps: {
                                                value: 3,
                                                checked: _vm._q(
                                                  _vm.promotion.when_to_prompt,
                                                  3
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.$set(
                                                    _vm.promotion,
                                                    "when_to_prompt",
                                                    3
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "input-radio__label",
                                                attrs: { for: "radio3" },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "input-radio__mark",
                                                }),
                                                _c("span", {
                                                  staticClass:
                                                    "input-radio__text",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t("Go to payment")
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c("div", {
                              staticStyle: {
                                border: "1px solid #eaeaea",
                                width: "100%",
                                "margin-top": "40px",
                              },
                            }),
                          ],
                          2
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.step === 3 && _vm.promotion.trigger != 0
                ? _c("div", { staticClass: "choose-promo flex col a-center" }, [
                    _c(
                      "form",
                      {
                        staticClass: "conditions_tab",
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "coupon-buy" }, [
                          _c(
                            "div",
                            {
                              staticClass: "header-back back d-flex d-md-none",
                              style: {
                                "margin-right": "-25px",
                                width: "103vw",
                              },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "header__back",
                                  on: { click: _vm.close },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "header-company" },
                                    [
                                      _c("svg-icon", {
                                        staticClass: "ic-white",
                                        attrs: {
                                          name: "ic-arrow-left",
                                          width: "16",
                                          height: "12",
                                        },
                                      }),
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(_vm.$t("Back")),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "coupon-buy__content-wrap" },
                            [
                              _c(
                                "div",
                                { staticClass: "coupon-buy__content" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "coupon-buy__item" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "coupon-buy__info" },
                                        [
                                          _c("div", {
                                            staticClass: "coupon-buy__head",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t("Buy")
                                              ),
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "coupon-buy__list coupon-buy__list_full",
                                              staticStyle: {
                                                display: "flex",
                                                "flex-direction": "column",
                                                gap: "5px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "input-radio" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.promotion
                                                            .buy_condition,
                                                        expression:
                                                          "promotion.buy_condition",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "radio",
                                                      name: "buyradio",
                                                      id: "radio1",
                                                    },
                                                    domProps: {
                                                      value: 1,
                                                      checked: _vm._q(
                                                        _vm.promotion
                                                          .buy_condition,
                                                        1
                                                      ),
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.promotion,
                                                          "buy_condition",
                                                          1
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "input-radio__label",
                                                      attrs: { for: "radio1" },
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "input-radio__mark",
                                                      }),
                                                      _c("span", {
                                                        staticClass:
                                                          "input-radio__text",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t(
                                                              "No conditions"
                                                            )
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "input-radio" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.promotion
                                                            .buy_condition,
                                                        expression:
                                                          "promotion.buy_condition",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "radio",
                                                      name: "buyradio",
                                                      id: "radio3",
                                                    },
                                                    domProps: {
                                                      value: 2,
                                                      checked: _vm._q(
                                                        _vm.promotion
                                                          .buy_condition,
                                                        2
                                                      ),
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.promotion,
                                                          "buy_condition",
                                                          2
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "input-radio__label",
                                                      attrs: { for: "radio3" },
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "input-radio__mark",
                                                      }),
                                                      _c("span", {
                                                        staticClass:
                                                          "input-radio__text",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t(
                                                              "Buy an item/category"
                                                            )
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "input-radio" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.promotion
                                                            .buy_condition,
                                                        expression:
                                                          "promotion.buy_condition",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "radio",
                                                      name: "buyradio",
                                                      id: "radio2",
                                                    },
                                                    domProps: {
                                                      value: 3,
                                                      checked: _vm._q(
                                                        _vm.promotion
                                                          .buy_condition,
                                                        3
                                                      ),
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.promotion,
                                                          "buy_condition",
                                                          3
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "input-radio__label",
                                                      attrs: { for: "radio2" },
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "input-radio__mark",
                                                      }),
                                                      _c("span", {
                                                        staticClass:
                                                          "input-radio__text",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t(
                                                              "Didn’t buy an item/category"
                                                            )
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "input-radio" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.promotion
                                                            .buy_condition,
                                                        expression:
                                                          "promotion.buy_condition",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "radio",
                                                      name: "buyradio",
                                                      id: "radio4",
                                                    },
                                                    domProps: {
                                                      value: 4,
                                                      checked: _vm._q(
                                                        _vm.promotion
                                                          .buy_condition,
                                                        4
                                                      ),
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.promotion,
                                                          "buy_condition",
                                                          4
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "input-radio__label",
                                                      attrs: { for: "radio4" },
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "input-radio__mark",
                                                      }),
                                                      _c("span", {
                                                        staticClass:
                                                          "input-radio__text",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t(
                                                              "From amount"
                                                            )
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm.promotion.buy_condition == 2 ||
                                          _vm.promotion.buy_condition == 3
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "10px",
                                                  },
                                                },
                                                [
                                                  _c("form-site-field-text", {
                                                    ref: "name",
                                                    attrs: {
                                                      title: "Items minimum",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.promotion
                                                          .items_minimum,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.promotion,
                                                          "items_minimum",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "promotion.items_minimum",
                                                    },
                                                  }),
                                                  _c(
                                                    "select-category-items-list",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.promotion
                                                              .buy_condition ==
                                                              2 ||
                                                            _vm.promotion
                                                              .buy_condition ==
                                                              3,
                                                          expression:
                                                            "promotion.buy_condition == 2 || promotion.buy_condition == 3",
                                                        },
                                                      ],
                                                      attrs: { inEdit: "" },
                                                      model: {
                                                        value:
                                                          _vm.promotion.items,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.promotion,
                                                            "items",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "promotion.items",
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.promotion.buy_condition == 4
                                            ? _c("div", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "coupon-buy-sale",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "input-label",
                                                      },
                                                      [
                                                        _vm.restaurantCurrencyInfo ==
                                                        null
                                                          ? _c("div", {
                                                              staticClass:
                                                                "input-label__note",
                                                              domProps: {
                                                                textContent:
                                                                  _vm._s("₪"),
                                                              },
                                                            })
                                                          : _vm._e(),
                                                        _vm.restaurantCurrencyInfo !==
                                                        null
                                                          ? _c("div", {
                                                              staticClass:
                                                                "input-label__note",
                                                              domProps: {
                                                                textContent:
                                                                  _vm._s(
                                                                    _vm.restaurantCurrencySymbol
                                                                  ),
                                                              },
                                                            })
                                                          : _vm._e(),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.promotion
                                                                  .from_amount,
                                                              expression:
                                                                "promotion.from_amount",
                                                            },
                                                          ],
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm.promotion
                                                                .from_amount,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.$set(
                                                                _vm.promotion,
                                                                "from_amount",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "coupon-buy__item" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "coupon-buy__info" },
                                        [
                                          _c("div", {
                                            staticClass: "coupon-buy__head",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t("Get")
                                              ),
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "coupon-buy__list" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "input-radio" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.promotion
                                                            .get_condition,
                                                        expression:
                                                          "promotion.get_condition",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "radio",
                                                      name: "radio2",
                                                      id: "radio20",
                                                    },
                                                    domProps: {
                                                      value: 1,
                                                      checked: _vm._q(
                                                        _vm.promotion
                                                          .get_condition,
                                                        1
                                                      ),
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.promotion,
                                                          "get_condition",
                                                          1
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "input-radio__label",
                                                      attrs: { for: "radio20" },
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "input-radio__mark",
                                                      }),
                                                      _c("span", {
                                                        staticClass:
                                                          "input-radio__text",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t("Discount")
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "input-radio" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.promotion
                                                            .get_condition,
                                                        expression:
                                                          "promotion.get_condition",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "radio",
                                                      name: "radio2",
                                                      id: "radio21",
                                                    },
                                                    domProps: {
                                                      value: 2,
                                                      checked: _vm._q(
                                                        _vm.promotion
                                                          .get_condition,
                                                        2
                                                      ),
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.promotion,
                                                          "get_condition",
                                                          2
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "input-radio__label",
                                                      attrs: { for: "radio21" },
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "input-radio__mark",
                                                      }),
                                                      _c("span", {
                                                        staticClass:
                                                          "input-radio__text",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t("Gift")
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "input-radio" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.promotion
                                                            .get_condition,
                                                        expression:
                                                          "promotion.get_condition",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "radio",
                                                      name: "radio2",
                                                      id: "radio23",
                                                    },
                                                    domProps: {
                                                      value: 3,
                                                      checked: _vm._q(
                                                        _vm.promotion
                                                          .get_condition,
                                                        3
                                                      ),
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.promotion,
                                                          "get_condition",
                                                          3
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "input-radio__label",
                                                      attrs: { for: "radio23" },
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "input-radio__mark",
                                                      }),
                                                      _c("span", {
                                                        staticClass:
                                                          "input-radio__text",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t("New price")
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "additional_get" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "input-check__label mis12",
                                                },
                                                [
                                                  _c("checkbox-second-color", {
                                                    attrs: {
                                                      name: "free-delivery",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.promotion
                                                          .freeDelivery,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.promotion,
                                                          "freeDelivery",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "promotion.freeDelivery",
                                                    },
                                                  }),
                                                  _c("span", {
                                                    staticClass:
                                                      "input-check__text",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t("Free delivery")
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "input-check__label mis12",
                                                },
                                                [
                                                  _c("checkbox-second-color", {
                                                    attrs: {
                                                      name: "cancels-minimum",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.promotion
                                                          .cancelsMinimumOrder,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.promotion,
                                                          "cancelsMinimumOrder",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "promotion.cancelsMinimumOrder",
                                                    },
                                                  }),
                                                  _c("span", {
                                                    staticClass:
                                                      "input-check__text",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t(
                                                          "Cancels minimum order"
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm.promotion.freeDelivery == true
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "free-delivery",
                                                  staticStyle: {
                                                    "margin-top": "20px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "flex-direction":
                                                          "column",
                                                        "align-items": "start",
                                                      },
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "main-settings__label",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t(
                                                              "For delivery areas with lower price than"
                                                            )
                                                          ),
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        { staticClass: "cs" },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input-label",
                                                            },
                                                            [
                                                              _vm.restaurantCurrencyInfo ==
                                                              null
                                                                ? _c("div", {
                                                                    staticClass:
                                                                      "input-label__note",
                                                                    domProps: {
                                                                      textContent:
                                                                        _vm._s(
                                                                          "₪"
                                                                        ),
                                                                    },
                                                                  })
                                                                : _vm._e(),
                                                              _vm.restaurantCurrencyInfo !==
                                                              null
                                                                ? _c("div", {
                                                                    staticClass:
                                                                      "input-label__note",
                                                                    domProps: {
                                                                      textContent:
                                                                        _vm._s(
                                                                          _vm.restaurantCurrencySymbol
                                                                        ),
                                                                    },
                                                                  })
                                                                : _vm._e(),
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .promotion
                                                                        .free_delivery,
                                                                    expression:
                                                                      "promotion.free_delivery",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  type: "text",
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    _vm
                                                                      .promotion
                                                                      .free_delivery,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      ) {
                                                                        return
                                                                      }
                                                                      _vm.$set(
                                                                        _vm.promotion,
                                                                        "free_delivery",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.promotion.cancelsMinimumOrder
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "select-category-items-list",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.promotion
                                                              .cancelsMinimumOrder,
                                                          expression:
                                                            "promotion.cancelsMinimumOrder",
                                                        },
                                                      ],
                                                      attrs: { inEdit: "" },
                                                      model: {
                                                        value:
                                                          _vm.promotion
                                                            .get_items,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.promotion,
                                                            "get_items",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "promotion.get_items",
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.promotion.get_condition == 1
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "currency-type",
                                                  staticStyle: {
                                                    display: "flex",
                                                    "justify-content": "start",
                                                    gap: "10px",
                                                    width: "100%",
                                                    "margin-top": "20px",
                                                  },
                                                },
                                                [
                                                  _c("button", {
                                                    staticClass:
                                                      "btn currency_btn",
                                                    class: {
                                                      active:
                                                        _vm.currencyBtn === 1,
                                                    },
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.restaurantCurrencySymbol
                                                      ),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.currencyBtn = 1
                                                      },
                                                    },
                                                  }),
                                                  _c("button", {
                                                    staticClass:
                                                      "btn currency_btn",
                                                    class: {
                                                      active:
                                                        _vm.currencyBtn === 2,
                                                    },
                                                    domProps: {
                                                      textContent: _vm._s("%"),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.currencyBtn = 2
                                                      },
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.currencyBtn == 1 &&
                                          _vm.promotion.get_condition == 1
                                            ? _c(
                                                "div",
                                                { staticClass: "sale" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "sale-text-field",
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "main-settings__label",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t("Sale")
                                                          ),
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "coupon-buy-sale",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input-label",
                                                            },
                                                            [
                                                              _vm.restaurantCurrencyInfo ==
                                                              null
                                                                ? _c("div", {
                                                                    staticClass:
                                                                      "input-label__note",
                                                                    domProps: {
                                                                      textContent:
                                                                        _vm._s(
                                                                          "₪"
                                                                        ),
                                                                    },
                                                                  })
                                                                : _vm._e(),
                                                              _vm.restaurantCurrencyInfo !==
                                                              null
                                                                ? _c("div", {
                                                                    staticClass:
                                                                      "input-label__note",
                                                                    domProps: {
                                                                      textContent:
                                                                        _vm._s(
                                                                          _vm.restaurantCurrencySymbol
                                                                        ),
                                                                    },
                                                                  })
                                                                : _vm._e(),
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .promotion
                                                                        .sale,
                                                                    expression:
                                                                      "promotion.sale",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  type: "text",
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    _vm
                                                                      .promotion
                                                                      .sale,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      ) {
                                                                        return
                                                                      }
                                                                      _vm.$set(
                                                                        _vm.promotion,
                                                                        "sale",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                          _c("div", {
                                                            staticClass:
                                                              "coupon-buy-sale__text",
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  _vm.$t("OFF")
                                                                ),
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "sale-checkboxes",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "coupon-buy__list coupon-buy__list_full",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input-check",
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .promotion
                                                                        .sale_checkbox,
                                                                    expression:
                                                                      "promotion.sale_checkbox",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  type: "radio",
                                                                  name: "get_condition",
                                                                  checked:
                                                                    "checked",
                                                                  id: "radio223",
                                                                },
                                                                domProps: {
                                                                  value: 1,
                                                                  checked:
                                                                    _vm._q(
                                                                      _vm
                                                                        .promotion
                                                                        .sale_checkbox,
                                                                      1
                                                                    ),
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$set(
                                                                        _vm.promotion,
                                                                        "sale_checkbox",
                                                                        1
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "input-check__label",
                                                                  attrs: {
                                                                    for: "radio223",
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "input-check__mark",
                                                                  }),
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "input-check__text",
                                                                    domProps: {
                                                                      textContent:
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "On all order"
                                                                          )
                                                                        ),
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input-check",
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .promotion
                                                                        .sale_checkbox,
                                                                    expression:
                                                                      "promotion.sale_checkbox",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  type: "radio",
                                                                  name: "get_condition",
                                                                  id: "radio224",
                                                                },
                                                                domProps: {
                                                                  value: 2,
                                                                  checked:
                                                                    _vm._q(
                                                                      _vm
                                                                        .promotion
                                                                        .sale_checkbox,
                                                                      2
                                                                    ),
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$set(
                                                                        _vm.promotion,
                                                                        "sale_checkbox",
                                                                        2
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "input-check__label",
                                                                  attrs: {
                                                                    for: "radio224",
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "input-check__mark",
                                                                  }),
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "input-check__text",
                                                                    domProps: {
                                                                      textContent:
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "On specific item/category"
                                                                          )
                                                                        ),
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "select-category-items-list",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.promotion
                                                                  .sale_checkbox ==
                                                                  2 &&
                                                                _vm.promotion
                                                                  .get_condition ==
                                                                  1,
                                                              expression:
                                                                "promotion.sale_checkbox == 2 && promotion.get_condition == 1",
                                                            },
                                                          ],
                                                          attrs: { inEdit: "" },
                                                          model: {
                                                            value:
                                                              _vm.promotion
                                                                .get_items,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.promotion,
                                                                "get_items",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "promotion.get_items",
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.currencyBtn == 2 &&
                                          _vm.promotion.get_condition == 1
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "coupon-buy__list coupon-buy__list_full",
                                                  staticStyle: {
                                                    "margin-top": "20px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "flex-direction":
                                                          "column",
                                                      },
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "main-settings__label",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t("Sale")
                                                          ),
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "coupon-buy-sale",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input-label",
                                                            },
                                                            [
                                                              _vm.restaurantCurrencyInfo ==
                                                              null
                                                                ? _c("div", {
                                                                    staticClass:
                                                                      "input-label__note",
                                                                    domProps: {
                                                                      textContent:
                                                                        _vm._s(
                                                                          "₪"
                                                                        ),
                                                                    },
                                                                  })
                                                                : _vm._e(),
                                                              _vm.restaurantCurrencyInfo !==
                                                              null
                                                                ? _c("div", {
                                                                    staticClass:
                                                                      "input-label__note",
                                                                    domProps: {
                                                                      textContent:
                                                                        _vm._s(
                                                                          _vm.restaurantCurrencySymbol
                                                                        ),
                                                                    },
                                                                  })
                                                                : _vm._e(),
                                                              _c("div", {
                                                                staticClass:
                                                                  "input-label__note",
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s("%"),
                                                                },
                                                              }),
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .promotion
                                                                        .amount,
                                                                    expression:
                                                                      "promotion.amount",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  type: "text",
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    _vm
                                                                      .promotion
                                                                      .amount,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      ) {
                                                                        return
                                                                      }
                                                                      _vm.$set(
                                                                        _vm.promotion,
                                                                        "amount",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                          _c("div", {
                                                            staticClass:
                                                              "coupon-buy-sale__text",
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  _vm.$t("off")
                                                                ),
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "coupon-buy__list coupon-buy__list_full",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input-check",
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.promotion
                                                                    .sale_persent_checkbox,
                                                                expression:
                                                                  "promotion.sale_persent_checkbox",
                                                              },
                                                            ],
                                                            attrs: {
                                                              type: "radio",
                                                              name: "get_condition",
                                                              checked:
                                                                "checked",
                                                              id: "radio223",
                                                            },
                                                            domProps: {
                                                              value: 1,
                                                              checked: _vm._q(
                                                                _vm.promotion
                                                                  .sale_persent_checkbox,
                                                                1
                                                              ),
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm.promotion,
                                                                  "sale_persent_checkbox",
                                                                  1
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "input-check__label",
                                                              attrs: {
                                                                for: "radio223",
                                                              },
                                                            },
                                                            [
                                                              _c("span", {
                                                                staticClass:
                                                                  "input-check__mark",
                                                              }),
                                                              _c("span", {
                                                                staticClass:
                                                                  "input-check__text",
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "On all order"
                                                                      )
                                                                    ),
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input-check",
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.promotion
                                                                    .sale_persent_checkbox,
                                                                expression:
                                                                  "promotion.sale_persent_checkbox",
                                                              },
                                                            ],
                                                            attrs: {
                                                              type: "radio",
                                                              name: "get_condition",
                                                              id: "radio224",
                                                            },
                                                            domProps: {
                                                              value: 2,
                                                              checked: _vm._q(
                                                                _vm.promotion
                                                                  .sale_persent_checkbox,
                                                                2
                                                              ),
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm.promotion,
                                                                  "sale_persent_checkbox",
                                                                  2
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "input-check__label",
                                                              attrs: {
                                                                for: "radio224",
                                                              },
                                                            },
                                                            [
                                                              _c("span", {
                                                                staticClass:
                                                                  "input-check__mark",
                                                              }),
                                                              _c("span", {
                                                                staticClass:
                                                                  "input-check__text",
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "On specific item/category"
                                                                      )
                                                                    ),
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "select-category-items-list",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.promotion
                                                              .sale_persent_checkbox ==
                                                              2 &&
                                                            _vm.promotion
                                                              .get_condition ==
                                                              1,
                                                          expression:
                                                            "promotion.sale_persent_checkbox == 2 && promotion.get_condition == 1",
                                                        },
                                                      ],
                                                      attrs: { inEdit: "" },
                                                      model: {
                                                        value:
                                                          _vm.promotion
                                                            .get_items,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.promotion,
                                                            "get_items",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "promotion.get_items",
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.promotion.get_condition == 2
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "select-category-items-list",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.promotion
                                                              .get_condition ==
                                                            2,
                                                          expression:
                                                            "promotion.get_condition == 2",
                                                        },
                                                      ],
                                                      attrs: { inEdit: "" },
                                                      model: {
                                                        value:
                                                          _vm.promotion
                                                            .get_items,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.promotion,
                                                            "get_items",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "promotion.get_items",
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.promotion.get_condition == 3
                                            ? _c("div", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "coupon-buy-sale",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "input-label",
                                                      },
                                                      [
                                                        _c("div", {
                                                          staticClass:
                                                            "input-label__note",
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.restaurantCurrencySymbol
                                                            ),
                                                          },
                                                        }),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.promotion
                                                                  .amount,
                                                              expression:
                                                                "promotion.amount",
                                                            },
                                                          ],
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm.promotion
                                                                .amount,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.$set(
                                                                _vm.promotion,
                                                                "amount",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.step === 4
                ? _c(
                    "div",
                    { staticClass: "choose-promo flex col a-center" },
                    [
                      _c("availability-tab", {
                        attrs: { availability: _vm.promotion.availability },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.step === 5
                ? _c(
                    "div",
                    { staticClass: "choose-promo flex col a-center" },
                    [
                      _c("atmos-promos-branches-tab", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          availability: _vm.promotion.availability,
                          showOnlyThisBranch: false,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.step == 6
                ? _c("div", { staticClass: "choose-promo flex col a-center" }, [
                    _c("div", { staticClass: "time_promo" }, [
                      _c("h3", {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "start",
                        },
                        domProps: { textContent: _vm._s(_vm.$t("Start")) },
                      }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                            gap: "10px",
                            width: "50%",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "coupon-buy__list coupon-buy__list_full times",
                            },
                            [
                              _c("div", { staticClass: "input-radio" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.promotion.start,
                                      expression: "promotion.start",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    name: "buyradio",
                                    id: "radio1",
                                  },
                                  domProps: {
                                    value: 1,
                                    checked: _vm._q(_vm.promotion.start, 1),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(_vm.promotion, "start", 1)
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "input-radio__label",
                                    attrs: { for: "radio1" },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "input-radio__mark",
                                    }),
                                    _c("span", {
                                      staticClass: "input-radio__text",
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Now")),
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "input-radio" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.promotion.start,
                                      expression: "promotion.start",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    name: "buyradio",
                                    id: "radio2",
                                  },
                                  domProps: {
                                    value: 2,
                                    checked: _vm._q(_vm.promotion.start, 2),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(_vm.promotion, "start", 2)
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "input-radio__label",
                                    attrs: { for: "radio2" },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "input-radio__mark",
                                    }),
                                    _c("span", {
                                      staticClass: "input-radio__text",
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Sheduled")),
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _vm.promotion.start == 2
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-direction": "row",
                                      "justify-content": "space-between",
                                      width: "100%",
                                      gap: "10px",
                                      "margin-top": "20px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-date" },
                                      [
                                        _c("date-picker", {
                                          staticClass: "date-picker",
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var inputValue =
                                                    ref.inputValue
                                                  var inputEvents =
                                                    ref.inputEvents
                                                  return [
                                                    _c(
                                                      "input",
                                                      _vm._g(
                                                        {
                                                          staticClass:
                                                            "bg-white border px-2 py-1 rounded",
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          domProps: {
                                                            value:
                                                              inputValue.replaceAll(
                                                                ".",
                                                                "/"
                                                              ),
                                                          },
                                                        },
                                                        inputEvents
                                                      )
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            52154283
                                          ),
                                          model: {
                                            value: _vm.promotion.publishDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.promotion,
                                                "publishDate",
                                                $$v
                                              )
                                            },
                                            expression: "promotion.publishDate",
                                          },
                                        }),
                                        _c("svg-icon", {
                                          attrs: {
                                            name: "ic-calendar",
                                            width: "20",
                                            height: "20",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "input-date" },
                                      [
                                        _c("select-box", {
                                          ref: "timepicker2",
                                          staticClass: "t-center",
                                          attrs: {
                                            width: "100%",
                                            autocomplete: "",
                                            options: _vm.listHours,
                                            placeholder: "00:00",
                                          },
                                          model: {
                                            value: _vm.promotion.publishTime,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.promotion,
                                                "publishTime",
                                                $$v
                                              )
                                            },
                                            expression: "promotion.publishTime",
                                          },
                                        }),
                                        _c("svg-icon", {
                                          attrs: {
                                            name: "ic-watch",
                                            width: "20",
                                            height: "20",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]),
                    _c("div", {
                      staticStyle: {
                        border: "1px solid #eaeaea",
                        width: "100%",
                        "margin-top": "30px",
                        "margin-bottom": "35px",
                      },
                    }),
                    _c("div", { staticClass: "time_promo" }, [
                      _c("h3", {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "start",
                        },
                        domProps: { textContent: _vm._s(_vm.$t("End")) },
                      }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                            gap: "10px",
                            width: "50%",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "coupon-buy__list coupon-buy__list_full times",
                            },
                            [
                              _c("div", { staticClass: "input-radio" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.promotion.end,
                                      expression: "promotion.end",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    name: "buyradio2",
                                    id: "radio222",
                                  },
                                  domProps: {
                                    value: 1,
                                    checked: _vm._q(_vm.promotion.end, 1),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(_vm.promotion, "end", 1)
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "input-radio__label",
                                    attrs: { for: "radio222" },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "input-radio__mark",
                                    }),
                                    _c("span", {
                                      staticClass: "input-radio__text",
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Never")),
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "input-radio" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.promotion.end,
                                      expression: "promotion.end",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    name: "buyradio2",
                                    id: "radio223",
                                  },
                                  domProps: {
                                    value: 2,
                                    checked: _vm._q(_vm.promotion.end, 2),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(_vm.promotion, "end", 2)
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "input-radio__label",
                                    attrs: { for: "radio223" },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "input-radio__mark",
                                    }),
                                    _c("span", {
                                      staticClass: "input-radio__text",
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Sheduled")),
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _vm.promotion.end == 2
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-direction": "row",
                                      "justify-content": "space-between",
                                      width: "100%",
                                      gap: "10px",
                                      "margin-top": "20px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-date" },
                                      [
                                        _c("date-picker", {
                                          staticClass: "date-picker",
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var inputValue =
                                                    ref.inputValue
                                                  var inputEvents =
                                                    ref.inputEvents
                                                  return [
                                                    _c(
                                                      "input",
                                                      _vm._g(
                                                        {
                                                          staticClass:
                                                            "bg-white border px-2 py-1 rounded",
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          domProps: {
                                                            value:
                                                              inputValue.replaceAll(
                                                                ".",
                                                                "/"
                                                              ),
                                                          },
                                                        },
                                                        inputEvents
                                                      )
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            52154283
                                          ),
                                          model: {
                                            value: _vm.promotion.expiresDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.promotion,
                                                "expiresDate",
                                                $$v
                                              )
                                            },
                                            expression: "promotion.expiresDate",
                                          },
                                        }),
                                        _c("svg-icon", {
                                          attrs: {
                                            name: "ic-calendar",
                                            width: "20",
                                            height: "20",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "input-date" },
                                      [
                                        _c("select-box", {
                                          ref: "timepicker2",
                                          staticClass: "t-center",
                                          attrs: {
                                            width: "100%",
                                            autocomplete: "",
                                            options: _vm.listHours,
                                            placeholder: "00:00",
                                          },
                                          model: {
                                            value: _vm.promotion.expiresTime,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.promotion,
                                                "expiresTime",
                                                $$v
                                              )
                                            },
                                            expression: "promotion.expiresTime",
                                          },
                                        }),
                                        _c("svg-icon", {
                                          attrs: {
                                            name: "ic-watch",
                                            width: "20",
                                            height: "20",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.step == 1
                ? _c(
                    "div",
                    {
                      staticClass: "coupon-statistic__btn",
                      staticStyle: {
                        display: "flex",
                        "justify-content": "end",
                        width: "100%",
                      },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn_promo_next",
                          on: { click: _vm.nextStep },
                        },
                        [
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Next")) },
                          }),
                          _c("svg-icon", {
                            staticClass: "ic-white",
                            attrs: {
                              name: "ic-arrow-right",
                              width: "16",
                              height: "12",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.step > 1 && _vm.step != 7
                ? _c(
                    "div",
                    {
                      staticClass: "coupon-statistic__btn",
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        width: "100%",
                      },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn_promo_back",
                          on: { click: _vm.backStep },
                        },
                        [
                          _c("svg-icon", {
                            staticClass: "ic-red mie8",
                            attrs: {
                              name: "ic-arrow-left",
                              width: "16",
                              height: "12",
                            },
                          }),
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Back")) },
                          }),
                        ],
                        1
                      ),
                      _vm.step != 6
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn_promo_next",
                              on: { click: _vm.nextStep },
                            },
                            [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Next")),
                                },
                              }),
                              _c("svg-icon", {
                                staticClass: "ic-white",
                                attrs: {
                                  name: "ic-arrow-right",
                                  width: "16",
                                  height: "12",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.step == 6
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn_promo_next",
                              on: { click: _vm.nextStep },
                            },
                            [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Finish")),
                                },
                              }),
                              _c("svg-icon", {
                                staticClass: "ic-white",
                                attrs: {
                                  name: "ic-arrow-right",
                                  width: "16",
                                  height: "12",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }