var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-site__field" }, [
    _c("span", {
      staticClass: "form-site__label",
      domProps: { textContent: _vm._s(_vm.$t(_vm.title)) },
    }),
    _vm.required
      ? _c("span", {
          staticClass: "t-red form-site__label mis4",
          domProps: { textContent: _vm._s("*") },
        })
      : _vm._e(),
    _vm.disabled
      ? _c("input", {
          attrs: { type: _vm.type, disabled: "" },
          domProps: { value: _vm.formatValue },
        })
      : _c(
          "div",
          { staticClass: "form-site__input" },
          [
            _c("date-picker", {
              attrs: {
                popover: { visibility: "click" },
                locale: _vm.locale,
                "min-date": _vm.minDate,
                "max-date": _vm.maxDate,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var inputValue = ref.inputValue
                    var inputEvents = ref.inputEvents
                    return [
                      _c(
                        "input",
                        _vm._g(
                          {
                            staticClass: "bg-white border px-2 py-1 rounded",
                            class: { errrequired: _vm.errrequired },
                            style: _vm.error ? "border: 1px solid red;" : "",
                            attrs: {
                              placeholder: _vm.placeholder,
                              disabled: _vm.disabled,
                              type: "text",
                            },
                            domProps: {
                              value: inputValue.replaceAll(".", "/"),
                            },
                          },
                          inputEvents
                        )
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.data,
                callback: function ($$v) {
                  _vm.data = $$v
                },
                expression: "data",
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }