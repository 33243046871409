var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "members" },
    [
      _c(
        "table-list",
        _vm._b(
          {
            ref: "membersTable",
            on: {
              changeOptions: _vm.changeOptions,
              "on-click-line": _vm.openPopup,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "btn-back",
                  fn: function () {
                    return [
                      _c(
                        "router-link",
                        {
                          staticClass: "page__back",
                          attrs: { to: "../member-club" },
                        },
                        [
                          _c("svg-icon", {
                            attrs: {
                              name: "ic-close-table-arrow",
                              width: "34",
                              height: "34",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                _vm.mobile
                  ? {
                      key: "above-tabel",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "header-back back d-flex d-md-none",
                              style: {
                                "margin-right": "-25px",
                                width: "103vw",
                              },
                            },
                            [
                              _c("button", { staticClass: "header__back" }, [
                                _c(
                                  "div",
                                  { staticClass: "header-company" },
                                  [
                                    _c(
                                      "router-link",
                                      { attrs: { to: "../member-club" } },
                                      [
                                        _c("svg-icon", {
                                          staticClass: "ic-white",
                                          attrs: {
                                            name: "ic-arrow-left",
                                            width: "16",
                                            height: "12",
                                          },
                                        }),
                                        _c("span", {
                                          staticStyle: { color: "white" },
                                          domProps: {
                                            textContent: _vm._s(_vm.$t("Back")),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "mobile-btn-plus",
                              on: { click: _vm.startCreateMember },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-plus",
                                  width: "16",
                                  height: "16",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : {
                      key: "btn",
                      fn: function () {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn_pink",
                              on: { click: _vm.startCreateMember },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-plus",
                                  width: "16",
                                  height: "16",
                                },
                              }),
                              _c("span", {
                                staticClass: "xl-none",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("New user")),
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
              ],
              null,
              true
            ),
          },
          "table-list",
          _vm.tableData,
          false
        )
      ),
      _vm.popup === 1
        ? _c("club-member-details-popup", {
            attrs: { member: _vm.memberInEdit, isNewMember: _vm.isNewMember },
            on: { save: _vm.saveDetailsMember },
          })
        : _vm._e(),
      _vm.showPointsDialog
        ? _c(
            "div",
            { staticClass: "popup-add disable-scrollbars" },
            [
              _c(
                "base-dialog",
                {
                  staticStyle: { "text-align": "-webkit-center" },
                  attrs: { width: "50%", title: "Enter points" },
                  on: { close: _vm.hidePointsDialog },
                },
                [
                  _c(
                    "div",
                    { staticClass: "points-container j-start t-start mb8" },
                    [
                      _c("span", {
                        staticClass: "check-box-title",
                        staticStyle: { color: "red" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("Current points amount") +
                              ": " +
                              _vm.currentPoints
                          ),
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "form-site",
                      staticStyle: {
                        "margin-bottom": "40px",
                        "text-align": "center",
                      },
                    },
                    [
                      _c("div", { staticClass: "form-site__input" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "row",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.points,
                                  expression: "points",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t("New points amount"),
                                type: "number",
                              },
                              domProps: { value: _vm.points },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.points = $event.target.value
                                },
                              },
                            }),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "check-box-input m12 d-flex row" },
                          [
                            _c(
                              "div",
                              { staticClass: "input-check pie8 t-start" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.push,
                                      expression: "push",
                                    },
                                  ],
                                  attrs: { type: "checkbox", id: "check4" },
                                  domProps: {
                                    checked: Array.isArray(_vm.push)
                                      ? _vm._i(_vm.push, null) > -1
                                      : _vm.push,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.push,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.push = $$a.concat([$$v]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.push = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.push = $$c
                                      }
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "input-check__label",
                                    attrs: { for: "check4" },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "input-check__mark m0",
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _c("span", {
                              staticClass: "check-box-title j-center t-center",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("Send push to member")
                                ),
                              },
                            }),
                          ]
                        ),
                        _c("div", [
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              on: { click: _vm.enterPoints },
                            },
                            [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Update points")),
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showSendPushDialog
        ? _c("create-new-push-notification", {
            attrs: { memberId: { id: _vm.memberId } },
            on: { close: _vm.closeDialog },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }