var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contacts full-screen" },
    [
      _c(
        "table-list",
        _vm._b(
          {
            on: {
              changeOptions: _vm.changeOptions,
              "on-click-line": _vm.openPopup,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "btn-back",
                  fn: function () {
                    return [
                      _c(
                        "router-link",
                        {
                          staticClass: "page__back",
                          attrs: { to: "../settings" },
                        },
                        [
                          _c("svg-icon", {
                            attrs: {
                              name: "ic-close-table-arrow",
                              width: "34",
                              height: "34",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                _vm.mobile
                  ? {
                      key: "above-tabel",
                      fn: function () {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "mobile-btn-plus",
                              on: { click: _vm.createContact },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-plus",
                                  width: "16",
                                  height: "16",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : {
                      key: "btn",
                      fn: function () {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn_pink",
                              on: { click: _vm.createContact },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-plus",
                                  width: "16",
                                  height: "16",
                                },
                              }),
                              _c("span", {
                                staticClass: "md-none",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("New contact")),
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
              ],
              null,
              true
            ),
          },
          "table-list",
          _vm.dataTable,
          false
        )
      ),
      _vm.popup == 1
        ? _c("edit-contact-dialog", {
            attrs: { contact: _vm.contactInEdit },
            on: {
              save: _vm.saveContact,
              remove: _vm.removeContact,
              close: _vm.closePopup,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }