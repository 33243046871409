var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      staticClass: "close-zed-force-dialog",
      attrs: { width: "500px", height: "800px" },
      on: { close: _vm.close },
    },
    [
      _c("div", [
        _c("div", { staticClass: "popup__top" }, [
          _c("div", { staticClass: "popup__head" }, [
            _c("div", {
              staticClass: "h2 t-center",
              domProps: { textContent: _vm._s(_vm.$t("close zed force")) },
            }),
          ]),
          !_vm.mobile
            ? _c("div", { staticClass: "form-site__btn md-none" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn_border-grad",
                    attrs: { type: "button" },
                    on: { click: _vm.closeZed },
                  },
                  [
                    _c("span", {
                      staticClass: "btn btn_border",
                      domProps: {
                        textContent: _vm._s(_vm.$t("Close Zed Through")),
                      },
                    }),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.mobile
            ? _c("div", { staticClass: "form-site__btn md-none" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn_border-grad",
                    attrs: { type: "button" },
                    on: { click: _vm.closeZed },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("Close Zed Through")) +
                        "\n        "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }