var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-dialog-tabs", {
    staticClass: "edit-business-dialog",
    attrs: { width: "676px", tabList: ["General info", "Language", "Options"] },
    on: { close: _vm.close },
    scopedSlots: _vm._u([
      {
        key: "tab0",
        fn: function () {
          return [
            _c("div", { staticClass: "form-lang" }, [
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "form-site" }, [
                    _c(
                      "div",
                      {
                        staticClass: "form-site__lang d-none d-md-inline-flex",
                      },
                      [
                        _c("div", { staticClass: "lang lang_grey" }, [
                          _c("button", {
                            staticClass: "lang__item",
                            class: { active: _vm.english },
                            domProps: {
                              textContent: _vm._s(_vm.$t("English")),
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.english = true
                              },
                            },
                          }),
                          _c("button", {
                            staticClass: "lang__item",
                            class: { active: !_vm.english },
                            domProps: { textContent: _vm._s(_vm.$t("Hebrew")) },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.english = false
                              },
                            },
                          }),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "form-site__head form-site__head_grey d-none d-md-block",
                      },
                      [
                        _vm.english
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.business.name_en,
                                  expression: "business.name_en",
                                },
                              ],
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t("Business name"),
                              },
                              domProps: { value: _vm.business.name_en },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.business,
                                    "name_en",
                                    $event.target.value
                                  )
                                },
                              },
                            })
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.business.name,
                                  expression: "business.name",
                                },
                              ],
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t("Business name"),
                              },
                              domProps: { value: _vm.business.name },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.business,
                                    "name",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                      ]
                    ),
                    _c("div", { staticClass: "form-site__content" }, [
                      _c("div", { staticClass: "form-site__group" }, [
                        _c("div", { staticClass: "form-site__field" }, [
                          _c("div", { staticClass: "form-site__input" }, [
                            _c("div", { staticClass: "edit-img" }, [
                              _c("div", {
                                staticClass: "edit-img__label",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Logo")),
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "edit-img__content",
                                  class: {
                                    "edit-img__content_empty": !_vm.logo,
                                  },
                                  style:
                                    "background-image: url(" + _vm.logo + ")",
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "edit-img__icon" },
                                    [
                                      _vm.logo
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "edit-img__icon",
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.removeImage("logo")
                                                },
                                              },
                                            },
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  name: "ic-plus",
                                                  width: "22",
                                                  height: "22",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : [
                                            _c("input", {
                                              attrs: {
                                                type: "file",
                                                accept: "image/*",
                                                name: "logo",
                                              },
                                              on: { change: _vm.enterImage },
                                            }),
                                            _c("svg-icon", {
                                              attrs: {
                                                name: "ic-photo",
                                                width: "22",
                                                height: "22",
                                              },
                                            }),
                                          ],
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-site__field" }, [
                          _c("div", { staticClass: "form-site__input" }, [
                            _c("div", { staticClass: "edit-img" }, [
                              _c("div", {
                                staticClass: "edit-img__label",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Icon")),
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "edit-img__content",
                                  class: {
                                    "edit-img__content_empty": !_vm.icon,
                                  },
                                  style:
                                    "background-image: url(" + _vm.icon + ")",
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "edit-img__icon" },
                                    [
                                      _vm.icon
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "edit-img__icon",
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.removeImage("icon")
                                                },
                                              },
                                            },
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  name: "ic-plus",
                                                  width: "22",
                                                  height: "22",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : [
                                            _c("input", {
                                              attrs: {
                                                type: "file",
                                                accept: "image/*",
                                                name: "icon",
                                              },
                                              on: { change: _vm.enterImage },
                                            }),
                                            _c("svg-icon", {
                                              attrs: {
                                                name: "ic-photo",
                                                width: "22",
                                                height: "22",
                                              },
                                            }),
                                          ],
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-site__field" }, [
                        _c("div", {
                          staticClass: "form-site__label",
                          domProps: {
                            textContent: _vm._s(_vm.$t("Primary phone")),
                          },
                        }),
                        _c("div", { staticClass: "form-site__input" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.business.phone,
                                expression: "business.phone",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.business.phone },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.business,
                                  "phone",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-site__field" }, [
                        _c("div", {
                          staticClass: "form-site__label",
                          domProps: { textContent: _vm._s(_vm.$t("SMS name")) },
                        }),
                        _c("div", { staticClass: "form-site__input" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.business.name_for_sms,
                                expression: "business.name_for_sms",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.business.name_for_sms },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.business,
                                  "name_for_sms",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-site__field" }, [
                        _c("div", {
                          staticClass: "form-site__label",
                          domProps: {
                            textContent: _vm._s(_vm.$t("Default call area")),
                          },
                        }),
                        _c("div", { staticClass: "form-site__input" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.business.default_calling_code,
                                expression: "business.default_calling_code",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.business.default_calling_code,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.business,
                                  "default_calling_code",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-site__field" }, [
                        _c(
                          "label",
                          { staticClass: "input-check__label" },
                          [
                            _c("checkbox", {
                              attrs: { name: "on-limit" },
                              model: {
                                value: _vm.business.has_round_discount,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.business,
                                    "has_round_discount",
                                    $$v
                                  )
                                },
                                expression: "business.has_round_discount",
                              },
                            }),
                            _c("span", {
                              staticClass: "input-check__text",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Round discount")),
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "form-site__field" }, [
                        _c("div", { staticClass: "sms_currencires__drops" }, [
                          _c(
                            "div",
                            { style: { width: "100%" } },
                            [
                              _c("div", {
                                staticClass: "form-site__label",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Default sms")),
                                },
                              }),
                              _c("select-box", {
                                attrs: {
                                  width: "100%",
                                  widthHeader: "100%",
                                  placeholder: _vm.$t("select"),
                                  options: _vm.defaultSmsOptions,
                                },
                                model: {
                                  value: _vm.business.default_sms_provider,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.business,
                                      "default_sms_provider",
                                      $$v
                                    )
                                  },
                                  expression: "business.default_sms_provider",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { style: { width: "100%" } },
                            [
                              _c("div", {
                                staticClass: "form-site__label",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("Defaul currency for restaurant")
                                  ),
                                },
                              }),
                              _c("select-box", {
                                attrs: {
                                  width: "100%",
                                  widthHeader: "100%",
                                  placeholder: _vm.$t("select"),
                                  options: _vm.defaultCurrenciesOptions,
                                },
                                model: {
                                  value: _vm.business.default_currency,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.business,
                                      "default_currency",
                                      $$v
                                    )
                                  },
                                  expression: "business.default_currency",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-site__field" }, [
                        _c("div", {
                          staticClass: "form-site__label",
                          domProps: {
                            textContent: _vm._s(_vm.$t("Description")),
                          },
                        }),
                        _c("div", { staticClass: "form-site__input" }, [
                          _vm.english
                            ? _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.business.description_en,
                                    expression: "business.description_en",
                                  },
                                ],
                                attrs: {
                                  placeholder: _vm.$t("Write your description"),
                                },
                                domProps: {
                                  value: _vm.business.description_en,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.business,
                                      "description_en",
                                      $event.target.value
                                    )
                                  },
                                },
                              })
                            : _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.business.description,
                                    expression: "business.description",
                                  },
                                ],
                                attrs: {
                                  placeholder: _vm.$t("Write your description"),
                                },
                                domProps: { value: _vm.business.description },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.business,
                                      "description",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "tab1",
        fn: function () {
          return [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.save.apply(null, arguments)
                  },
                },
              },
              [
                _vm.business.languages
                  ? _c("div", { staticClass: "lang-list" }, [
                      _c(
                        "label",
                        { staticClass: "lang-list__item" },
                        [
                          _c("span", { staticClass: "lang-list__text" }, [
                            _c("span", { staticClass: "lang-list__text1" }, [
                              _vm._v("English"),
                            ]),
                            _c("span", {
                              staticClass: "lang-list__text2",
                              domProps: {
                                textContent: _vm._s(_vm.$t("English")),
                              },
                            }),
                          ]),
                          _c("checkbox", {
                            attrs: { name: "english" },
                            model: {
                              value: _vm.business.languages.en,
                              callback: function ($$v) {
                                _vm.$set(_vm.business.languages, "en", $$v)
                              },
                              expression: "business.languages.en",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "label",
                        { staticClass: "lang-list__item" },
                        [
                          _c("span", { staticClass: "lang-list__text" }, [
                            _c("span", { staticClass: "lang-list__text1" }, [
                              _vm._v("עברית"),
                            ]),
                            _c("span", {
                              staticClass: "lang-list__text2",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Hebrew")),
                              },
                            }),
                          ]),
                          _c("checkbox", {
                            attrs: { name: "hebrew" },
                            model: {
                              value: _vm.business.languages.he,
                              callback: function ($$v) {
                                _vm.$set(_vm.business.languages, "he", $$v)
                              },
                              expression: "business.languages.he",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "label",
                        { staticClass: "lang-list__item" },
                        [
                          _c("span", { staticClass: "lang-list__text" }, [
                            _c("span", { staticClass: "lang-list__text1" }, [
                              _vm._v("عربى"),
                            ]),
                            _c("span", {
                              staticClass: "lang-list__text2",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Arabic")),
                              },
                            }),
                          ]),
                          _c("checkbox", {
                            attrs: { name: "arabic" },
                            model: {
                              value: _vm.business.languages.ar,
                              callback: function ($$v) {
                                _vm.$set(_vm.business.languages, "ar", $$v)
                              },
                              expression: "business.languages.ar",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "label",
                        { staticClass: "lang-list__item" },
                        [
                          _c("span", { staticClass: "lang-list__text" }, [
                            _c("span", { staticClass: "lang-list__text1" }, [
                              _vm._v("Русский"),
                            ]),
                            _c("span", {
                              staticClass: "lang-list__text2",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Russian")),
                              },
                            }),
                          ]),
                          _c("checkbox", {
                            attrs: { name: "russian" },
                            model: {
                              value: _vm.business.languages.ru,
                              callback: function ($$v) {
                                _vm.$set(_vm.business.languages, "ru", $$v)
                              },
                              expression: "business.languages.ru",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "label",
                        { staticClass: "lang-list__item" },
                        [
                          _c("span", { staticClass: "lang-list__text" }, [
                            _c("span", { staticClass: "lang-list__text1" }, [
                              _vm._v("Français"),
                            ]),
                            _c("span", {
                              staticClass: "lang-list__text2",
                              domProps: {
                                textContent: _vm._s(_vm.$t("French")),
                              },
                            }),
                          ]),
                          _c("checkbox", {
                            attrs: { name: "russian" },
                            model: {
                              value: _vm.business.languages.fr,
                              callback: function ($$v) {
                                _vm.$set(_vm.business.languages, "fr", $$v)
                              },
                              expression: "business.languages.fr",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "tab2",
        fn: function () {
          return [
            _c("div", { staticClass: "form-lang" }, [
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "form-site" }, [
                    _c("div", { staticClass: "form-site__head-wrap" }, [
                      _c("div", {
                        staticClass: "form-site__head d-none d-md-block",
                        domProps: {
                          textContent: _vm._s(
                            _vm.he ? _vm.business.name : _vm.business.name_en
                          ),
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "form-site__content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "main-settings__sect main-settings__sect_end1",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "setting-panel" },
                            [
                              _c(
                                "div",
                                { staticClass: "setting-panel__content" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "setting-panel__img" },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/img/power.svg"),
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "setting-panel__info" },
                                    [
                                      _c("div", {
                                        staticClass: "setting-panel__title",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("Pending charge")
                                          ),
                                        },
                                      }),
                                      _c("div", {
                                        staticClass: "setting-panel__text",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "Turning this on will not charge clients directly, the payment will be pending for changes and approve in orders page"
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _c("swich-button", {
                                model: {
                                  value: _vm.business.business_billing,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.business,
                                      "business_billing",
                                      $$v
                                    )
                                  },
                                  expression: "business.business_billing",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "main-settings__sect main-settings__sect_end1",
                        },
                        [
                          _c("div", { staticClass: "setting-panel" }, [
                            _c(
                              "div",
                              { staticClass: "setting-panel__content" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "setting-panel__img" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/img/file.svg"),
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "setting-panel__info" },
                                  [
                                    _c("div", {
                                      staticClass: "setting-panel__title",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Terms & services")
                                        ),
                                      },
                                    }),
                                    _c("div", {
                                      staticClass: "setting-panel__text",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t(
                                            "Upload file with PDF/TXT/DOCX format only "
                                          )
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _vm.termsToUpload
                              ? _c(
                                  "div",
                                  { staticClass: "setting-panel__uploadedit" },
                                  [
                                    _c("div", { staticClass: "edit" }, [
                                      _c(
                                        "label",
                                        [
                                          _c("input", {
                                            attrs: {
                                              accept: "application/pdf",
                                              type: "file",
                                            },
                                            on: { change: _vm.addFile },
                                          }),
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "ic-terms-upload",
                                              width: "32",
                                              height: "32",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.termsToUpload.name,
                                            expression: "termsToUpload.name",
                                          },
                                        ],
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.termsToUpload.name,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.termsToUpload,
                                              "name",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              _vm.termsToUpload = null
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              attrs: {
                                                width: "14",
                                                height: "14",
                                                viewBox: "0 0 14 14",
                                                fill: "none",
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                              },
                                            },
                                            [
                                              _c("path", {
                                                staticClass: "second-fill",
                                                attrs: {
                                                  d: "M13.7424 0.256009C13.401 -0.0853364 12.8536 -0.0853364 12.5122 0.256009L6.99918 5.76906L1.48614 0.256009C1.14479 -0.0853364 0.597354 -0.0853364 0.256009 0.256009C-0.0853363 0.597355 -0.0853363 1.1448 0.256009 1.48614L5.76905 6.9992L0.256009 12.5122C-0.0853363 12.8536 -0.0853363 13.401 0.256009 13.7424C0.423461 13.9098 0.648878 14 0.867854 14C1.08683 14 1.31225 13.9163 1.4797 13.7424L6.99274 8.22933L12.5058 13.7424C12.6732 13.9098 12.8987 14 13.1176 14C13.343 14 13.562 13.9163 13.7295 13.7424C14.0708 13.401 14.0708 12.8536 13.7295 12.5122L8.22931 6.9992L13.7424 1.48614C14.0837 1.1448 14.0837 0.597355 13.7424 0.256009Z",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm.business.terms
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "setting-panel__upload-exist",
                                    on: { click: _vm.openTerms },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        name: "ic-terms-upload",
                                        width: "32",
                                        height: "32",
                                      },
                                    }),
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(_vm.business.terms),
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "upload-continer",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                          },
                                        },
                                      },
                                      [
                                        _c("input", {
                                          attrs: {
                                            type: "file",
                                            accept: "application/pdf",
                                          },
                                          on: { change: _vm.addFile },
                                        }),
                                        _c("svg-icon", {
                                          attrs: {
                                            name: "ic-upload",
                                            width: "20",
                                            height: "20",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "label",
                                  { staticClass: "setting-panel__upload" },
                                  [
                                    _c("input", {
                                      attrs: {
                                        type: "file",
                                        accept: "application/pdf",
                                      },
                                      on: { change: _vm.addFile },
                                    }),
                                    _c("svg-icon", {
                                      attrs: {
                                        name: "ic-upload",
                                        width: "20",
                                        height: "20",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "btns",
        fn: function () {
          return [
            _c("div", { staticClass: "form-site__btn_business md-none" }, [
              _c("div", {}),
              _c("button", { staticClass: "btn", on: { click: _vm.save } }, [
                _c("span", {
                  domProps: { textContent: _vm._s(_vm.$t("save")) },
                }),
              ]),
            ]),
            _c("div", { staticClass: "new-item__btn d-md-none md-flex" }, [
              _c(
                "button",
                { staticClass: "save-btn", on: { click: _vm.save } },
                [
                  _c("svg-icon", {
                    staticClass: "ic-edit",
                    attrs: { name: "ic-save-btn", width: "112", height: "112" },
                  }),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }