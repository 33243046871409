var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading
    ? _c(
        "section",
        { staticClass: "main-loader-container flex a-center j-center" },
        [
          _c("lottie-player", {
            staticStyle: { width: "500x", height: "500px" },
            attrs: {
              src: "https://assets5.lottiefiles.com/private_files/lf30_ydnbrnlz.json",
              background: "transparent",
              speed: "1",
              loop: "",
              autoplay: "",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }