import { render, staticRenderFns } from "./catalog-editor-edit-item-printing-lines.vue?vue&type=template&id=280a999a&scoped=true&"
import script from "./catalog-editor-edit-item-printing-lines.vue?vue&type=script&lang=ts&"
export * from "./catalog-editor-edit-item-printing-lines.vue?vue&type=script&lang=ts&"
import style0 from "./catalog-editor-edit-item-printing-lines.vue?vue&type=style&index=0&id=280a999a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "280a999a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/dorshoshan/Documents/DEV/Atmos-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('280a999a')) {
      api.createRecord('280a999a', component.options)
    } else {
      api.reload('280a999a', component.options)
    }
    module.hot.accept("./catalog-editor-edit-item-printing-lines.vue?vue&type=template&id=280a999a&scoped=true&", function () {
      api.rerender('280a999a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/apps/catalog-editor/dialogs/catalog-editor-edit-item/catalog-editor-edit-item-printing-lines.vue"
export default component.exports