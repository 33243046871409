var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("base-dialog", [
        _c("div", { staticClass: "popup__content" }, [
          _c("div", { staticClass: "icoming-call" }, [
            _vm.caller.firm
              ? _c("div", {
                  staticClass: "icoming-call__firm",
                  domProps: { textContent: _vm._s(_vm.caller.firm) },
                })
              : _vm._e(),
            _vm.caller.address
              ? _c("div", {
                  staticClass: "icoming-call__address",
                  domProps: { textContent: _vm._s(_vm.caller.address) },
                })
              : _vm._e(),
            _vm.caller.logo
              ? _c("div", { staticClass: "icoming-call__logo" }, [
                  _c("img", { attrs: { src: _vm.caller.logo, alt: "" } }),
                ])
              : _vm._e(),
            _vm.caller.name
              ? _c("div", {
                  staticClass: "icoming-call__name",
                  domProps: { textContent: _vm._s(_vm.caller.name) },
                })
              : _vm._e(),
            _vm.caller.phone
              ? _c("div", {
                  staticClass: "icoming-call__phone",
                  domProps: { textContent: _vm._s(_vm.caller.phone) },
                })
              : _vm._e(),
            _c("div", { staticClass: "icoming-call-action" }, [
              _c(
                "button",
                {
                  staticClass:
                    "icoming-call-action__item icoming-call-action__item_green",
                  on: { click: _vm.answer },
                },
                [
                  _c("svg-icon", {
                    attrs: { name: "ic-calls", width: "24", height: "24" },
                  }),
                ],
                1
              ),
              _c(
                "button",
                {
                  staticClass:
                    "icoming-call-action__item icoming-call-action__item_red",
                  on: { click: _vm.decline },
                },
                [
                  _c("svg-icon", {
                    attrs: { name: "ic-calls", width: "24", height: "24" },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }