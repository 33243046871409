var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      staticClass: "choose-coordinates",
      attrs: { width: "95vw" },
      on: { close: _vm.close },
    },
    [
      _c("div", { staticClass: "popup__content" }, [
        _c("div", { staticClass: "form-new-user" }, [
          _c("div", { staticClass: "map", attrs: { id: "map" } }),
          _c(
            "div",
            { staticClass: "client-detail__bottom flex md-none  bet a-center" },
            [
              _c("div", { staticClass: "form-site" }, [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c("form-site-field-text", {
                      staticClass: "mie12",
                      attrs: { title: "קו הרוחב" },
                      model: {
                        value: _vm.coordinatesCopy.lat,
                        callback: function ($$v) {
                          _vm.$set(_vm.coordinatesCopy, "lat", $$v)
                        },
                        expression: "coordinatesCopy.lat",
                      },
                    }),
                    _c("form-site-field-text", {
                      attrs: { title: "קו האורך" },
                      model: {
                        value: _vm.coordinatesCopy.lng,
                        callback: function ($$v) {
                          _vm.$set(_vm.coordinatesCopy, "lng", $$v)
                        },
                        expression: "coordinatesCopy.lng",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn",
                  attrs: { type: "button" },
                  on: { click: _vm.save },
                },
                [
                  _c("span", {
                    domProps: { textContent: _vm._s(_vm.$t("Save")) },
                  }),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }