var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "integrations full-screen" },
    [
      _c("main", [
        _c("section", { staticClass: "container" }, [
          _c("div", { staticClass: "page" }, [
            _c("div", { staticClass: "page__main" }, [
              _c("div", { staticClass: "top__header" }, [
                _c(
                  "div",
                  { staticClass: "back_button" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "page__back",
                        attrs: { to: "../settings" },
                      },
                      [
                        _c("svg-icon", {
                          attrs: {
                            name: "ic-close-table-arrow",
                            width: "34",
                            height: "34",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("h1", {
                      domProps: { textContent: _vm._s(_vm.$t("Integrations")) },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "create__new" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn_pink",
                      on: { click: _vm.createNewApi },
                    },
                    [
                      _c("svg-icon", {
                        attrs: { name: "ic-plus", width: "16", height: "16" },
                      }),
                      _c("span", {
                        staticClass: "d-md-block d-none",
                        domProps: {
                          textContent: _vm._s(_vm.$t("New integration")),
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div"),
              ]),
              _c("section", { staticClass: "main-settings" }, [
                _c("div", { staticClass: "main-settings__sect" }, [
                  _c("div", { staticClass: "main-settings__content" }, [
                    _c("div", { staticClass: "main-settings__form" }, [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "form-site" }, [
                            _c("div", { staticClass: "form-site__content" }, [
                              _c(
                                "div",
                                { staticClass: "club__list" },
                                [
                                  _vm.mobile
                                    ? [
                                        _vm.metaKeysLeft.length
                                          ? _c(
                                              "button",
                                              {
                                                staticClass: "mobile-btn-plus",
                                                on: { click: _vm.createNewApi },
                                              },
                                              [
                                                _c("svg-icon", {
                                                  attrs: {
                                                    name: "ic-plus",
                                                    width: "16",
                                                    height: "16",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                  _vm._l(_vm.apis, function (api) {
                                    return _c(
                                      "div",
                                      {
                                        key: api.id,
                                        staticClass: "club__item",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "club__data",
                                            staticStyle: {
                                              width: "100%",
                                              height: "100%",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editApi(api)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "club__text" },
                                              [
                                                api.meta_key != "credit"
                                                  ? _c("div", {
                                                      staticClass: "club__name",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t(
                                                            _vm.capitalize(
                                                              api.meta_key
                                                            )
                                                          )
                                                        ),
                                                      },
                                                    })
                                                  : _vm._e(),
                                                api.meta_key == "credit"
                                                  ? _c("div", {
                                                      staticClass: "club__name",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t(
                                                            "switch_credit_card"
                                                          )
                                                        ),
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "flex j-end a-center",
                                          },
                                          [
                                            _c("swich-button", {
                                              staticClass: "mie8",
                                              on: {
                                                input: function ($event) {
                                                  return _vm.setActive(
                                                    api,
                                                    $event
                                                  )
                                                },
                                              },
                                              model: {
                                                value: api.active,
                                                callback: function ($$v) {
                                                  _vm.$set(api, "active", $$v)
                                                },
                                                expression: "api.active",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm.popup && _vm.apiInEdit && _vm.apiInEdit.meta_value != null
        ? _c("edit-integration", {
            attrs: { orderIintegrations: _vm.metaKeysLeft, api: _vm.apiInEdit },
            on: { removeApi: _vm.removeApi, save: _vm.saveIintegration },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }