var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shifts" },
    [
      _c(
        "table-list",
        _vm._b(
          {
            ref: "shiftsTable",
            on: {
              changeOptions: _vm.changeOptions,
              "on-click-line": _vm.openPopup,
            },
            scopedSlots: _vm._u(
              [
                _vm.mobile &&
                _vm.checkPermissionPath("employees.add_attendance")
                  ? {
                      key: "above-tabel",
                      fn: function () {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "mobile-btn-plus",
                              on: { click: _vm.getNewPosition },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-plus",
                                  width: "16",
                                  height: "16",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
                {
                  key: "filter-by-date",
                  fn: function () {
                    return [
                      _vm.mobile
                        ? _c("filter-by-date", {
                            model: {
                              value: _vm.dateFilter,
                              callback: function ($$v) {
                                _vm.dateFilter = $$v
                              },
                              expression: "dateFilter",
                            },
                          })
                        : _vm._e(),
                      _c("div", { staticClass: "filters" }, [
                        _c(
                          "div",
                          { staticClass: "filter-wrap gap6" },
                          [
                            _c(
                              "div",
                              {
                                style: {
                                  display: "flex",
                                  "justify-content": "center",
                                  width: "100%",
                                },
                              },
                              [
                                _c("label", { staticClass: "input-check" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.notApproved,
                                        expression: "notApproved",
                                      },
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(_vm.notApproved)
                                        ? _vm._i(_vm.notApproved, null) > -1
                                        : _vm.notApproved,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.notApproved,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.notApproved = $$a.concat([
                                                $$v,
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.notApproved = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.notApproved = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "input-check__label" },
                                    [
                                      _c("span", {
                                        staticClass: "input-check__text",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("Not approved")
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                                _c("label", { staticClass: "input-check" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.overExtended,
                                        expression: "overExtended",
                                      },
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(_vm.overExtended)
                                        ? _vm._i(_vm.overExtended, null) > -1
                                        : _vm.overExtended,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.overExtended,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.overExtended = $$a.concat([
                                                $$v,
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.overExtended = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.overExtended = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "input-check__label" },
                                    [
                                      _c("span", {
                                        staticClass: "input-check__text",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("Over extended")
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            !_vm.mobile &&
                            _vm.selectedEmployee &&
                            _vm.selectedEmployee.id
                              ? _c("select-box", {
                                  staticClass: "w160 mie4",
                                  attrs: {
                                    firstTop: "",
                                    widthHeader: "160px",
                                    width: "160px",
                                    showSearch: "",
                                    value: _vm.selectedEmployee
                                      ? _vm.selectedEmployee.id
                                      : -1,
                                    options: _vm.optEmployees,
                                  },
                                  on: { input: _vm.changeEmployee },
                                })
                              : _vm._e(),
                            _vm.mobile &&
                            _vm.selectedEmployee &&
                            _vm.selectedEmployee.id
                              ? _c("select-box", {
                                  staticClass: "select-box s",
                                  attrs: {
                                    firstTop: "",
                                    width: "160px",
                                    showSearch: "",
                                    value: _vm.selectedEmployee
                                      ? _vm.selectedEmployee.id
                                      : -1,
                                    options: _vm.optEmployees,
                                  },
                                  on: { input: _vm.changeEmployee },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "filter-date-container" }),
                      !_vm.mobile
                        ? _c("filter-by-date", {
                            model: {
                              value: _vm.dateFilter,
                              callback: function ($$v) {
                                _vm.dateFilter = $$v
                              },
                              expression: "dateFilter",
                            },
                          })
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "bottom-table",
                  fn: function () {
                    return [
                      _vm.changesToSave.length
                        ? _c("div", { staticClass: "bottom-table md-none" }, [
                            _vm.selectedEmployee &&
                            _vm.changesToSave.length == 1
                              ? _c("button", {
                                  staticClass: "btn",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Edit")),
                                  },
                                  on: { click: _vm.changePosition },
                                })
                              : _vm._e(),
                            _vm.selectedEmployee
                              ? _c("button", {
                                  staticClass: "btn",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("Update salary")
                                    ),
                                  },
                                  on: { click: _vm.updateSalary },
                                })
                              : _c("div"),
                            _c("button", {
                              staticClass: "btn",
                              domProps: { textContent: _vm._s(_vm.$t("Ok")) },
                              on: { click: _vm.approve },
                            }),
                          ])
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
                !_vm.mobile &&
                _vm.checkPermissionPath("employees.add_attendance")
                  ? {
                      key: "undertitle",
                      fn: function () {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn_pink",
                              on: { click: _vm.getNewPosition },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-plus",
                                  width: "16",
                                  height: "16",
                                },
                              }),
                              _c("span", {
                                staticClass: "xl-none",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("New shift")),
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          },
          "table-list",
          _vm.dataTable,
          false
        )
      ),
      _vm.popup === 1 && !_vm.problomaticRecords
        ? _c("select-positions-dialog", {
            attrs: {
              employee:
                _vm.selectedEmployee != null
                  ? _vm.selectedEmployee
                  : _vm.employeeInEdit,
              record: _vm.currShift ? _vm.currShift : _vm.getRecord(),
            },
            on: { save: _vm.edit },
          })
        : _vm._e(),
      _vm.popup === 2 && !_vm.problomaticRecords
        ? _c("new-position-dialog", {
            attrs: {
              recordSelected: _vm.currShift ? _vm.currShift : _vm.getRecord(),
            },
            on: { save: _vm.saveNewPosition },
          })
        : _vm._e(),
      _vm.problomaticRecords
        ? _c("problomaticShiftsDialog", {
            attrs: {
              Record_conflicted: _vm.Record_conflicted,
              Current_record: _vm.Current_record,
            },
            on: { close: _vm.close },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }