var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "attendance-dialog" },
    [
      _c("base-dialog", { on: { close: _vm.close } }, [
        _c("div", { staticClass: "popup__content" }, [
          _c("div", { staticClass: "form-lang" }, [
            _c("div", { staticClass: "form-site" }, [
              _c(
                "div",
                { staticClass: "form-site__content" },
                [
                  _c("div", { staticClass: "form-site__field" }, [
                    _c("div", {
                      staticClass: "form-site__label",
                      domProps: { textContent: _vm._s(_vm.$t("Employee")) },
                    }),
                    _c(
                      "div",
                      { staticClass: "form-site__input flex bet" },
                      [
                        _c(
                          "div",
                          { staticClass: "flex maxw273" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn-move mie4",
                                on: { click: _vm.previousEmployee },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "44",
                                      height: "45",
                                      viewBox: "0 0 48 48",
                                      fill: "none",
                                    },
                                  },
                                  [
                                    _c("circle", {
                                      staticClass: "main-second-stroke",
                                      attrs: {
                                        cx: "24",
                                        cy: "24",
                                        r: "23",
                                        fill: "white",
                                      },
                                    }),
                                    _c(
                                      "g",
                                      { attrs: { "clip-path": "url(#clip0)" } },
                                      [
                                        _c("path", {
                                          staticClass: "main-second-fill",
                                          attrs: {
                                            d: "M18.2564 24.0001C18.2564 23.6928 18.3737 23.3856 18.6079 23.1514L25.979 15.7804C26.4479 15.3115 27.2081 15.3115 27.6768 15.7804C28.1455 16.2491 28.1455 17.0092 27.6768 17.4781L21.1544 24.0001L27.6766 30.5221C28.1453 30.991 28.1453 31.751 27.6766 32.2197C27.2079 32.6888 26.4476 32.6888 25.9787 32.2197L18.6076 24.8487C18.3735 24.6144 18.2564 24.3072 18.2564 24.0001Z",
                                          },
                                        }),
                                      ]
                                    ),
                                    _c("defs", [
                                      _c(
                                        "clipPath",
                                        { attrs: { id: "clip0" } },
                                        [
                                          _c("rect", {
                                            attrs: {
                                              width: "17.1428",
                                              height: "17.1428",
                                              fill: "white",
                                              transform:
                                                "translate(31.7139 15.4287) rotate(90)",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _c("select-box", {
                              staticClass: "w160 mie4",
                              attrs: {
                                widthHeader: "160px",
                                width: "160px",
                                showSearch: "",
                                value: _vm.selectedEmployee.id,
                                options: _vm.optEmployees,
                              },
                              on: { input: _vm.changeEmployee },
                            }),
                            _c(
                              "button",
                              {
                                staticClass: "btn-move mie16",
                                on: { click: _vm.nextEmployee },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "45",
                                      height: "45",
                                      viewBox: "0 0 48 48",
                                      fill: "none",
                                    },
                                  },
                                  [
                                    _c("circle", {
                                      staticClass: "main-second-stroke",
                                      attrs: {
                                        cx: "24",
                                        cy: "24",
                                        r: "23",
                                        fill: "white",
                                      },
                                    }),
                                    _c(
                                      "g",
                                      { attrs: { "clip-path": "url(#clip1)" } },
                                      [
                                        _c("path", {
                                          staticClass: "main-second-fill",
                                          attrs: {
                                            d: "M30.601 23.9999C30.601 24.3072 30.4837 24.6144 30.2496 24.8486L22.8785 32.2196C22.4096 32.6885 21.6493 32.6885 21.1806 32.2196C20.7119 31.7509 20.7119 30.9908 21.1806 30.5219L27.703 23.9999L21.1809 17.4779C20.7122 17.009 20.7122 16.249 21.1809 15.7803C21.6496 15.3112 22.4098 15.3112 22.8787 15.7803L30.2498 23.1513C30.484 23.3856 30.601 23.6928 30.601 23.9999Z",
                                          },
                                        }),
                                      ]
                                    ),
                                    _c("defs", [
                                      _c(
                                        "clipPath",
                                        { attrs: { id: "clip1" } },
                                        [
                                          _c("rect", {
                                            attrs: {
                                              width: "17.1428",
                                              height: "17.1428",
                                              fill: "white",
                                              transform:
                                                "translate(17.1436 32.5713) rotate(-90)",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("filter-by-date", {
                          model: {
                            value: _vm.dateFilter,
                            callback: function ($$v) {
                              _vm.dateFilter = $$v
                            },
                            expression: "dateFilter",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "v-data-table",
                    _vm._b(
                      {
                        on: { "update:options": _vm.changeOptions },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header.selectAll",
                              fn: function () {
                                return [
                                  _c("checkbox", {
                                    attrs: {
                                      name: "selectAll",
                                      value: !!_vm.changesToSave.length,
                                    },
                                    on: { input: _vm.inputSelectAll },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "body",
                              fn: function (ref) {
                                var items = ref.items
                                return [
                                  _c(
                                    "tbody",
                                    [
                                      items.length === 0
                                        ? _c("tr", [
                                            _c(
                                              "td",
                                              { attrs: { colspan: "42" } },
                                              [
                                                _c("div", {
                                                  staticClass: "no-data-text",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        "No data available"
                                                      )
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._l(items, function (item, i) {
                                        var _obj
                                        return _c(
                                          "tr",
                                          {
                                            key: i,
                                            staticClass: "tr",
                                            class:
                                              ((_obj = {}),
                                              (_obj[item.class] = item.class),
                                              _obj),
                                          },
                                          _vm._l(
                                            item.data || item,
                                            function (property, i) {
                                              return _c(
                                                "td",
                                                {
                                                  key: i,
                                                  class:
                                                    "td" +
                                                    (property
                                                      ? typeof property ===
                                                        "string"
                                                        ? property
                                                        : property.type
                                                      : "") +
                                                    " " +
                                                    (property
                                                      ? property.screen
                                                      : ""),
                                                },
                                                [
                                                  property &&
                                                  property.type &&
                                                  property.data !== ""
                                                    ? _c(property.type, {
                                                        tag: "component",
                                                        attrs: {
                                                          data: property.data,
                                                        },
                                                      })
                                                    : property ||
                                                      (property === 0 &&
                                                        property.data !== "")
                                                    ? _c("span", {
                                                        domProps: {
                                                          textContent:
                                                            _vm._s(property),
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      }),
                                    ],
                                    2
                                  ),
                                ]
                              },
                            },
                            {
                              key: "no-data",
                              fn: function () {
                                return [
                                  _c("tbody", [
                                    _c("tr", [
                                      _c("td", { attrs: { colspan: "42" } }, [
                                        _c("div", {
                                          staticClass: "no-data-text",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("No data available")
                                            ),
                                          },
                                        }),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      "v-data-table",
                      _vm.tableBind,
                      false
                    )
                  ),
                ],
                1
              ),
            ]),
            _vm.changesToSave.length
              ? _c(
                  "div",
                  {
                    staticClass:
                      "form-site__btn form-site__btn_sb d-md-flex d-none",
                  },
                  [
                    _c("button", {
                      staticClass: "btn",
                      domProps: {
                        textContent: _vm._s(_vm.$t("Change position")),
                      },
                      on: { click: _vm.changePosition },
                    }),
                    _c("button", {
                      staticClass: "btn",
                      domProps: {
                        textContent: _vm._s(_vm.$t("Update salary")),
                      },
                      on: { click: _vm.updateSalary },
                    }),
                    _c("button", {
                      staticClass: "btn",
                      domProps: { textContent: _vm._s(_vm.$t("Ok")) },
                      on: { click: _vm.approve },
                    }),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm.popup === 1
        ? _c("select-positions-dialog", {
            attrs: { employee: _vm.selectedEmployee },
            on: { save: _vm.choosePosition },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }