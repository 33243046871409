var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "base-dialog",
        {
          staticClass: "show-zed-dialog",
          attrs: { "max-width": "700px", "max-height": "700px" },
          on: { close: _vm.close },
        },
        [
          _vm.zed.closed != null && _vm.canCloseZed
            ? _c("h1", {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("close zed of date") +
                      " " +
                      _vm.zed.closed.slice(0, 10).replaceAll("-", "/")
                  ),
                },
              })
            : _vm._e(),
          _vm.zed.closed == null && _vm.canCloseZed
            ? _c("h1", {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("זד נוכחי לתאריך") + " " + _vm.valueDate
                  ),
                },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "popup__content pb40" },
            [
              _vm.zed.closed != null
                ? _c(
                    "popup-table-list",
                    _vm._b(
                      {
                        staticClass: "popup_table",
                        attrs: {
                          title:
                            _vm.$t("number of zed") + _vm.zed.branch_zed_id,
                        },
                      },
                      "popup-table-list",
                      _vm.dataTable,
                      false
                    )
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._l(_vm.tax, function (t, index) {
            return _c(
              "div",
              { key: index + "key", staticClass: "history-list-result" },
              [
                _vm.restaurantCurrencyInfo === null
                  ? _c("div", {
                      staticClass: "history-list-result__title",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("Gross") + " " + t.amount.toFixed(2) + "₪"
                        ),
                      },
                    })
                  : _vm._e(),
                _vm.restaurantCurrencyInfo !== null
                  ? _c("div", {
                      staticClass: "history-list-result__title",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("Gross") +
                            " " +
                            t.amount.toFixed(2) +
                            _vm.restaurantCurrencySymbol
                        ),
                      },
                    })
                  : _vm._e(),
                _c("div", {
                  staticClass: "history-list-result__title",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("VAT") + " " + t.value.toFixed(3) + "%"
                    ),
                  },
                }),
                _c("div", {
                  staticClass: "history-list-result__title",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("Net") + " " + _vm.calcVal(t.amount, t.value)
                    ),
                  },
                }),
              ]
            )
          }),
          _c("div", { staticClass: "btns" }, [
            _vm.canCloseZed
              ? _c("div", { staticClass: "close_zed_btn" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: { type: "button" },
                      on: { click: _vm.closeZed },
                    },
                    [
                      _c("span", {
                        domProps: { textContent: _vm._s(_vm.$t("close zed")) },
                      }),
                    ]
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "resend-sms" }, [
              _c(
                "button",
                {
                  staticClass: "btn",
                  attrs: { type: "button" },
                  on: { click: _vm.sendZedSMS },
                },
                [_vm._v(_vm._s(_vm.$t("Send Zed SMS")))]
              ),
            ]),
            _c("div", { staticClass: "post_erp_btn" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn_border",
                  attrs: { type: "button" },
                  on: { click: _vm.save },
                },
                [
                  _c("span", {
                    domProps: { textContent: _vm._s(_vm.$t("Send to erp")) },
                  }),
                ]
              ),
            ]),
          ]),
          _vm.hasTaxArchive
            ? _c("div", { staticClass: "btns" }, [
                _c("div", { staticClass: "download-archive" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: { type: "button", disabled: _vm.isLoading },
                      on: { click: _vm.downloadTaxArchive },
                    },
                    [_vm._v(_vm._s(_vm.$t("Download Tax Archive")))]
                  ),
                ]),
                _c("div", { staticClass: "validate-archive" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: { type: "button", disabled: _vm.isLoading },
                      on: { click: _vm.validateTaxArchive },
                    },
                    [_vm._v(_vm._s(_vm.$t("Validate Tax Archive")))]
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm.toCloseZed
        ? _c("closing-zed-dialog", {
            attrs: { toClose: true, zedToClose: _vm.zed },
            on: { close: _vm.close },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }