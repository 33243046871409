var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "catalog-editor" },
    [
      _c(
        "table-list",
        _vm._b(
          {
            attrs: {
              editTitle: _vm.canEditCategoryTitle,
              draggableOn: _vm.draggableOn,
              markRow: _vm.markRow,
              itemIndex: _vm.indexInTable,
              identifier:
                _vm.currentCategory && _vm.currentCategory.id
                  ? _vm.currentCategory.id
                  : null,
            },
            on: {
              "on-click-line": _vm.openPopup,
              changeOptions: _vm.changeOptions,
              draggableend: _vm.endMoveItem,
              draggablestart: _vm.startMoveItem,
              startEditTitle: _vm.startEditCategory,
            },
            scopedSlots: _vm._u([
              {
                key: "above-tabel",
                fn: function () {
                  return [
                    _vm.mobile && _vm.btnText
                      ? _c(
                          "button",
                          {
                            staticClass: "mobile-btn-plus",
                            on: { click: _vm.startCreateItem },
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-plus",
                                width: "16",
                                height: "16",
                              },
                            }),
                            _c("span", {
                              staticClass: "d-md-block d-none",
                              domProps: { textContent: _vm._s(_vm.btnText) },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
              {
                key: "btn",
                fn: function () {
                  return [
                    _vm.btnText
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn_pink",
                            on: { click: _vm.startCreateItem },
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-plus",
                                width: "16",
                                height: "16",
                              },
                            }),
                            _c("span", {
                              staticClass: "d-md-block d-none",
                              domProps: { textContent: _vm._s(_vm.btnText) },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isExtras
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn_pink",
                            on: { click: _vm.showExtrasArrangementDialog },
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-arrangement",
                                width: "16",
                                height: "16",
                              },
                            }),
                            _c("span", { staticClass: "d-md-block d-none" }, [
                              _vm._v(_vm._s(_vm.$t("Arrangement of extras"))),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
              {
                key: "filter-by-properties",
                fn: function () {
                  return [
                    _vm.menuOptions
                      ? _c("select-box", {
                          staticClass: "mie8 d-md-none",
                          attrs: {
                            width: "calc(50vw - 28px)",
                            widthHeader: "calc(50vw - 28px)",
                            options: _vm.menuOptions,
                          },
                          model: {
                            value: _vm.menuFilter,
                            callback: function ($$v) {
                              _vm.menuFilter = $$v
                            },
                            expression: "menuFilter",
                          },
                        })
                      : _vm._e(),
                    _vm.menuOptions
                      ? _c("select-box", {
                          staticClass: "mie8 md-none",
                          attrs: {
                            width: "200px",
                            widthHeader: "200px",
                            options: _vm.menuOptions,
                          },
                          model: {
                            value: _vm.menuFilter,
                            callback: function ($$v) {
                              _vm.menuFilter = $$v
                            },
                            expression: "menuFilter",
                          },
                        })
                      : _vm._e(),
                    _c("select-box", {
                      staticClass: "d-md-none",
                      attrs: {
                        width: "calc(50vw - 28px)",
                        widthHeader: "calc(50vw - 28px)",
                        unsort: "",
                        options: ["All", "filter-Active", "Inactive"].map(
                          function (label, value) {
                            return {
                              label: _vm.$t(label),
                              value: value,
                            }
                          }
                        ),
                      },
                      model: {
                        value: _vm.activeOnly,
                        callback: function ($$v) {
                          _vm.activeOnly = $$v
                        },
                        expression: "activeOnly",
                      },
                    }),
                    _vm._l(
                      ["All", "filter-Active", "Inactive"],
                      function (key, index) {
                        return _c(
                          "div",
                          { key: key, staticClass: "input-check md-none" },
                          [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.activeOnly,
                                    expression: "activeOnly",
                                  },
                                ],
                                attrs: { type: "radio" },
                                domProps: {
                                  value: index,
                                  checked: _vm._q(_vm.activeOnly, index),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.activeOnly = index
                                  },
                                },
                              }),
                              _c(
                                "span",
                                { staticClass: "input-check__label" },
                                [
                                  _c("span", {
                                    staticClass: "input-check__text",
                                    domProps: {
                                      textContent: _vm._s(_vm.$t(key)),
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]
                        )
                      }
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "table-list",
          _vm.dataTable,
          false
        )
      ),
      _vm.categoryPopup === true &&
      _vm.checkPermissionPath("catalog_editor.full_access")
        ? _c("catalog-editor-edit-category", {
            attrs: { item: _vm.itemInEdit, category: _vm.categoryInEdit },
            on: {
              save: function ($event) {
                return _vm.saveEditedItem($event, "category")
              },
              remove: function ($event) {
                return _vm.removeItem($event, "category")
              },
            },
          })
        : _vm._e(),
      (_vm.typeOfObj === "item" || _vm.itemPopup === true) &&
      _vm.checkPermissionPath("catalog_editor.full_access")
        ? _c("catalog-editor-edit-item", {
            attrs: { item: _vm.itemInEdit },
            on: {
              save: function ($event) {
                return _vm.saveEditedItem($event, "item")
              },
              close: _vm.closePopup,
              duplicate: function ($event) {
                return _vm.duplicate($event)
              },
              remove: function ($event) {
                return _vm.removeItem($event, "item")
              },
            },
          })
        : _vm._e(),
      (_vm.typeOfObj === "extra" || _vm.extraPopup === true) &&
      _vm.checkPermissionPath("catalog_editor.full_access")
        ? _c("catalog-editor-edit-extra", {
            attrs: { extraInEdit: _vm.itemInEdit },
            on: {
              save: function ($event) {
                return _vm.saveEditedItem($event, "extra")
              },
              remove: function ($event) {
                return _vm.removeItem($event, "extra")
              },
            },
          })
        : _vm._e(),
      (_vm.typeOfObj === "filter" || _vm.filterPopup == true) &&
      _vm.checkPermissionPath("catalog_editor.full_access")
        ? _c("catalog-editor-edit-filter", {
            attrs: { filterInEdit: _vm.itemInEdit },
            on: {
              save: function ($event) {
                return _vm.saveEditedItem($event, "filter")
              },
              remove: function ($event) {
                return _vm.removeItem($event, "filter")
              },
            },
          })
        : _vm._e(),
      (_vm.typeOfObj === "tag" || _vm.tagPopup === true) &&
      _vm.checkPermissionPath("catalog_editor.full_access")
        ? _c("catalog-editor-edit-tag", {
            attrs: { tagInEdit: _vm.itemInEdit },
            on: {
              save: function ($event) {
                return _vm.saveEditedItem($event, "tag")
              },
              remove: function ($event) {
                return _vm.removeItem($event, "tag")
              },
            },
          })
        : _vm._e(),
      (_vm.typeOfObj === "menu" || _vm.menuPopup === true) &&
      _vm.checkPermissionPath("catalog_editor.full_access")
        ? _c("catalog-editor-menu-dialog", {
            attrs: { menu: _vm.itemInEdit },
            on: {
              save: function ($event) {
                return _vm.saveEditedItem($event, "menu")
              },
              remove: function ($event) {
                return _vm.removeItem($event, "menu")
              },
            },
          })
        : _vm._e(),
      _vm.menuCopyPopup === true &&
      _vm.checkPermissionPath("catalog_editor.full_access") &&
      _vm.itemInEdit &&
      _vm.itemInEdit.id
        ? _c("copy-catalog-menu", {
            attrs: { id: _vm.itemInEdit.id },
            on: {
              close: function ($event) {
                _vm.menuCopyPopup = false
              },
            },
          })
        : _vm._e(),
      _vm.extrasArrangementDialog
        ? _c("extras-arrangement-dialog", {
            on: { close: _vm.hideExtrasArrangementDialog },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }