var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      staticClass: "add-stock",
      attrs: { overflowVisible: "" },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("div", { staticClass: "popup__content" }, [
        _c("div", { staticClass: "form-new-user" }, [
          _c("form", { staticClass: "form-site-wrap" }, [
            _c("div", { staticClass: "form-site" }, [
              _c(
                "div",
                {
                  staticClass: "form-site__content",
                  staticStyle: { display: "flex", "flex-direction": "column" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "form-site__field-wrap",
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "column",
                        "justify-content": "space-between",
                        width: "100%",
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "form-site__label",
                        domProps: {
                          textContent: _vm._s(_vm.$t("Select item")),
                        },
                      }),
                      !_vm.mobile
                        ? _c("select-box", {
                            staticClass: "selectCategory",
                            attrs: {
                              width: "280px",
                              widthHeader: "280px",
                              unsort: "",
                              placeholder: String(_vm.$t("Select item")),
                              options: _vm.itemsOptions.map(function (
                                label,
                                value
                              ) {
                                return {
                                  label: _vm.$t(label),
                                  value: _vm.$t(label),
                                }
                              }),
                              showSearch: "",
                            },
                            model: {
                              value: _vm.name,
                              callback: function ($$v) {
                                _vm.name = $$v
                              },
                              expression: "name",
                            },
                          })
                        : _vm._e(),
                      _vm.mobile
                        ? _c("select-box", {
                            attrs: {
                              width: "100%",
                              widthHeader: "100%",
                              unsort: "",
                              placeholder: String(_vm.$t("Select")),
                              options: _vm.itemsOptions.map(function (
                                label,
                                value
                              ) {
                                return {
                                  label: _vm.$t(label),
                                  value: _vm.$t(label),
                                }
                              }),
                              showSearch: "",
                            },
                            model: {
                              value: _vm.name,
                              callback: function ($$v) {
                                _vm.name = $$v
                              },
                              expression: "name",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "row",
                        "justify-content": "space-around",
                        width: "100%",
                        gap: "10px",
                        "margin-top": "10px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "form-site__field-wrap",
                          staticStyle: { width: "100%" },
                        },
                        [
                          _c("form-site-field-text", {
                            staticStyle: { width: "100%" },
                            attrs: { focus: "", title: "daily stock" },
                            model: {
                              value: _vm.daily_stock,
                              callback: function ($$v) {
                                _vm.daily_stock = $$v
                              },
                              expression: "daily_stock",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "form-site__field-wrap",
                          staticStyle: { width: "100%" },
                        },
                        [
                          _c("form-site-field-text", {
                            staticStyle: { width: "100%" },
                            attrs: { focus: "", title: "current stock" },
                            model: {
                              value: _vm.current_stock,
                              callback: function ($$v) {
                                _vm.current_stock = $$v
                              },
                              expression: "current_stock",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "form-site__btn md-none" }, [
                    _c("button", {
                      staticClass: "btn",
                      attrs: { type: "submit" },
                      domProps: { textContent: _vm._s(_vm.$t("Save")) },
                      on: { click: _vm.save },
                    }),
                  ]),
                  _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
                    _c(
                      "button",
                      {
                        staticClass: "save-btn",
                        attrs: { type: "submit" },
                        on: { click: _vm.save },
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "ic-edit",
                          attrs: {
                            name: "ic-save-btn",
                            width: "93",
                            height: "93",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }