var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.seconds < 3600 * 3
    ? _c("show-time", { class: _vm.mode, attrs: { seconds: _vm.seconds } })
    : _c("span", {
        domProps: {
          textContent: _vm._s(_vm.filters.clockTimeDate(_vm.startDate)),
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }