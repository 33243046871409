var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scaled-orders" },
    [
      _c("header", { staticClass: "scaled-orders__header" }, [
        _c("div", { staticClass: "flex" }, [
          _c(
            "button",
            {
              staticClass: "scaled-orders__header_back",
              on: {
                click: function ($event) {
                  return _vm.$emit("close", _vm.orderCopy)
                },
              },
            },
            [
              _c("svg-icon", {
                staticClass: "ic-red mie8",
                attrs: { name: "ic-arrow-left", width: "16", height: "12" },
              }),
              _c("span", { domProps: { textContent: _vm._s(_vm.$t("Back")) } }),
            ],
            1
          ),
          _c("div", {
            staticClass: "scaled-orders__header_name",
            domProps: {
              textContent: _vm._s(_vm.$t("Order") + " " + _vm.order.unique_id),
            },
          }),
        ]),
        _c("div", { staticClass: "prices-show" }, [
          _c("div", { staticClass: "prices-show__before" }, [
            _c("div", {
              staticClass: "prices-show__title",
              domProps: { textContent: _vm._s(_vm.$t("Before changes")) },
            }),
            _vm.restaurantCurrencyInfo === null
              ? _c("div", {
                  staticClass: "prices-show__count",
                  domProps: {
                    textContent: _vm._s("₪" + _vm.orderCopy.tmp_sum),
                  },
                })
              : _vm._e(),
            _vm.restaurantCurrencyInfo !== null
              ? _c("div", {
                  staticClass: "prices-show__count",
                  domProps: {
                    textContent: _vm._s(
                      _vm.restaurantCurrencySymbol + _vm.orderCopy.tmp_sum
                    ),
                  },
                })
              : _vm._e(),
          ]),
          _c("div", { staticClass: "prices-show__after" }, [
            _c("div", {
              staticClass: "prices-show__title",
              domProps: { textContent: _vm._s(_vm.$t("After changes")) },
            }),
            _vm.restaurantCurrencyInfo === null
              ? _c("div", {
                  staticClass: "prices-show__count",
                  domProps: {
                    textContent: _vm._s("₪" + _vm.orderCopy.scaled_cart_price),
                  },
                })
              : _vm._e(),
            _vm.restaurantCurrencyInfo !== null
              ? _c("div", {
                  staticClass: "prices-show__count",
                  domProps: {
                    textContent: _vm._s(
                      _vm.restaurantCurrencySymbol +
                        _vm.orderCopy.scaled_cart_price
                    ),
                  },
                })
              : _vm._e(),
          ]),
        ]),
        _vm.order.user
          ? _c(
              "button",
              {
                staticClass: "avatar flex j-end a-center",
                on: { click: _vm.goToUser },
              },
              [
                _c("user-avatar", {
                  attrs: {
                    user: _vm.order.user,
                    "img-class": "call-panel-user__photo",
                    size: 48,
                  },
                }),
                _c("div", { staticClass: "mis8" }, [
                  _c("div", { staticClass: "avatar__img" }),
                  _vm.order.user
                    ? _c("div", { staticClass: "avatar__text" }, [
                        _c("div", {
                          staticClass: "avatar__name t-start",
                          domProps: {
                            textContent: _vm._s(_vm.order.user.name),
                          },
                        }),
                        _c("div", {
                          staticClass: "avatar__tel",
                          domProps: {
                            textContent: _vm._s(_vm.order.user.phone),
                          },
                        }),
                      ])
                    : _vm._e(),
                ]),
              ],
              1
            )
          : _c("div", { staticClass: "avatar flex j-end a-center" }),
      ]),
      _c("div", { staticClass: "scaled-orders__main-top" }, [
        _c("div", { staticClass: "scaled-orders__top" }, [
          _c("div", { staticClass: "scaled-orders__top_detailes" }, [
            _vm.address
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "scaled-orders__top_detailes_img" },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "14",
                            height: "18",
                            viewBox: "0 0 14 18",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            staticClass: "main-second-fill",
                            attrs: {
                              d: "M11.9497 1.84277C9.21627 -0.614256 4.78411 -0.614256 2.05026 1.84277C-0.412865 4.05679 -0.69014 8.22556 1.4002 10.7307L7 18L12.5998 10.7307C14.6901 8.22556 14.4129 4.05679 11.9497 1.84277ZM7.06816 8.3076C5.79193 8.3076 4.75754 7.37782 4.75754 6.23066C4.75754 5.08349 5.79193 4.15371 7.06816 4.15371C8.3444 4.15371 9.37879 5.08349 9.37879 6.23066C9.37879 7.37782 8.3444 8.3076 7.06816 8.3076Z",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "scaled-orders__top_detailes_text" },
                    [_vm._v(_vm._s(_vm.address))]
                  ),
                ])
              : _vm._e(),
            _vm.orderCopy.cart.delivery_price
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "scaled-orders__top_detailes_img" },
                    [
                      _c("svg-icon", {
                        attrs: { name: "delivery", width: "30", height: "24" },
                      }),
                    ],
                    1
                  ),
                  _vm.restaurantCurrencyInfo === null
                    ? _c(
                        "div",
                        { staticClass: "scaled-orders__top_detailes_text" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("Delivery")) +
                              " ₪" +
                              _vm._s(_vm.orderCopy.cart.delivery_price) +
                              "\n          "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.restaurantCurrencyInfo !== null
                    ? _c(
                        "div",
                        { staticClass: "scaled-orders__top_detailes_text" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("Delivery")) +
                              " " +
                              _vm._s(_vm.restaurantCurrencySymbol) +
                              "\n            " +
                              _vm._s(_vm.orderCopy.cart.delivery_price) +
                              "\n          "
                          ),
                        ]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.orderCopy.payment_method
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "scaled-orders__top_detailes_img" },
                    [
                      _c("svg-icon", {
                        attrs: {
                          name: "credit-card",
                          width: "30",
                          height: "24",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", {
                    staticClass: "scaled-orders__top_detailes_text",
                    domProps: { textContent: _vm._s(_vm.$t("Credit card")) },
                  }),
                ])
              : _c("div", [
                  _c(
                    "div",
                    { staticClass: "scaled-orders__top_detailes_img" },
                    [
                      _c("svg-icon", {
                        attrs: { name: "cash", width: "30", height: "24" },
                      }),
                    ],
                    1
                  ),
                  _c("div", {
                    staticClass: "scaled-orders__top_detailes_text",
                    domProps: { textContent: _vm._s(_vm.$t("Cash")) },
                  }),
                ]),
          ]),
        ]),
        _c("div", { staticClass: "scaled-orders__main flex" }, [
          _c(
            "div",
            { staticClass: "scaled-orders__items" },
            [
              _vm._l(_vm.orderCopy.cart.items, function (item, index) {
                return [
                  index % 2 === 0
                    ? _c("scaled-order-card", {
                        key: item.id,
                        attrs: {
                          index: index,
                          theOpen: _vm.theOpen,
                          item: item,
                        },
                        on: {
                          startCancelItem: function ($event) {
                            return _vm.startCancelItem(item)
                          },
                          subtractItem: function ($event) {
                            return _vm.subtractItem(item)
                          },
                          editItem: function ($event) {
                            return _vm.editItem(item)
                          },
                          openClose: function ($event) {
                            _vm.theOpen = _vm.theOpen === index ? -1 : index
                          },
                        },
                      })
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "scaled-orders__items" },
            [
              _vm._l(_vm.orderCopy.cart.items, function (item, index) {
                return [
                  index % 2 !== 0
                    ? _c("scaled-order-card", {
                        key: item.id,
                        attrs: {
                          index: index,
                          theOpen: _vm.theOpen,
                          item: item,
                        },
                        on: {
                          startCancelItem: function ($event) {
                            return _vm.startCancelItem(item)
                          },
                          subtractItem: function ($event) {
                            return _vm.subtractItem(item)
                          },
                          editItem: function ($event) {
                            return _vm.editItem(item)
                          },
                          openClose: function ($event) {
                            _vm.theOpen = _vm.theOpen === index ? -1 : index
                          },
                        },
                      })
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ]),
      ]),
      _c("footer", { staticClass: "scaled-orders__footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn_border-grad",
            on: {
              click: function ($event) {
                _vm.showPaymentMethod = true
              },
            },
          },
          [
            _c("span", {
              staticClass: "btn__grad-content p16",
              domProps: {
                textContent: _vm._s(_vm.$t("Change payment method")),
              },
            }),
          ]
        ),
        _c("button", { staticClass: "btn", on: { click: _vm.pay } }, [
          _c("span", { domProps: { textContent: _vm._s(_vm.$t("Pay")) } }),
          _c(
            "svg",
            {
              staticClass: "arr",
              attrs: {
                width: "16",
                height: "12",
                viewBox: "0 0 16 12",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M10.7742 0.544197C10.5562 0.318601 10.1935 0.318601 9.96786 0.544197C9.74989 0.762171 9.74989 1.12495 9.96786 1.34242L14.0565 5.43108H0.564497C0.249984 5.43159 0 5.68157 0 5.99609C0 6.3106 0.249984 6.56871 0.564497 6.56871H14.0565L9.96786 10.6498C9.74989 10.8754 9.74989 11.2386 9.96786 11.4561C10.1935 11.6817 10.5567 11.6817 10.7742 11.4561L15.8308 6.39952C16.0564 6.18154 16.0564 5.81876 15.8308 5.60129L10.7742 0.544197Z",
                  fill: "white",
                },
              }),
            ]
          ),
        ]),
      ]),
      _vm.showCancelDialog
        ? _c("scaled-orders-cancel", {
            attrs: { itemName: _vm.itemInEdit.name },
            on: {
              close: function ($event) {
                _vm.showCancelDialog = false
              },
              cancel: _vm.cancelItem,
            },
          })
        : _vm._e(),
      _vm.showEditDialog
        ? _c("scaled-orders-count-editor", {
            attrs: { order: _vm.orderCopy, item: _vm.itemInEdit },
            on: {
              goToUser: _vm.goToUser,
              save: _vm.saveNewAmount,
              close: function ($event) {
                _vm.showEditDialog = false
              },
            },
          })
        : _vm._e(),
      _vm.showPaymentMethod
        ? _c("change-payment-method", {
            attrs: { orderId: _vm.orderCopy.id },
            on: {
              setPaymentMethod: _vm.setPaymentMethod,
              close: function ($event) {
                _vm.showPaymentMethod = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }