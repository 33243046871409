var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("emoji-picker", {
    attrs: { search: _vm.search },
    on: { emoji: _vm.append },
    scopedSlots: _vm._u([
      {
        key: "emoji-invoker",
        fn: function (ref) {
          var clickEvent = ref.events.click
          return _c(
            "div",
            {
              staticClass: "emoji-invoker",
              class: { "is-rtl": _vm.isRtl },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return clickEvent.apply(null, arguments)
                },
              },
            },
            [
              _c("svg-icon", {
                attrs: { name: "ic-smile", width: "24", height: "24" },
              }),
            ],
            1
          )
        },
      },
      {
        key: "emoji-picker",
        fn: function (ref) {
          var emojis = ref.emojis
          var insert = ref.insert
          var display = ref.display
          return _c("div", {}, [
            _c(
              "div",
              {
                staticClass: "emoji-picker",
                style: { top: display.y + "px", left: display.x + "px" },
              },
              [
                _c("div", { staticClass: "emoji-picker__search" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.search,
                        expression: "search",
                      },
                      { name: "focus", rawName: "v-focus" },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.search },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.search = $event.target.value
                      },
                    },
                  }),
                ]),
                _c(
                  "div",
                  _vm._l(emojis, function (emojiGroup, category) {
                    return _c("div", { key: category }, [
                      _c("h5", { domProps: { textContent: _vm._s(category) } }),
                      _c(
                        "div",
                        { staticClass: "emojis" },
                        _vm._l(emojiGroup, function (emoji, emojiName) {
                          return _c("span", {
                            key: emojiName,
                            attrs: { title: emojiName },
                            domProps: { textContent: _vm._s(emoji) },
                            on: {
                              click: function ($event) {
                                return insert(emoji)
                              },
                            },
                          })
                        }),
                        0
                      ),
                    ])
                  }),
                  0
                ),
              ]
            ),
          ])
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }