var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    { staticClass: "base-dialog-output", on: { close: _vm.close } },
    [
      _c(
        "div",
        {
          staticClass: "popup__content mb20",
          staticStyle: { padding: "unset" },
        },
        [
          [
            _c("span", {
              staticClass: "form-site__label",
              domProps: { textContent: _vm._s(_vm.$t("Branch")) },
            }),
            _c("select-box", {
              staticClass: "mie8 md-none",
              attrs: {
                fixed: true,
                fixedleft: true,
                width: "200px",
                widthHeader: "200px",
                options: _vm.branchesOptions,
              },
              model: {
                value: _vm.device.branch_id,
                callback: function ($$v) {
                  _vm.$set(_vm.device, "branch_id", $$v)
                },
                expression: "device.branch_id",
              },
            }),
            _c(
              "div",
              {
                staticClass: "button-row",
                staticStyle: { "text-align": "center" },
              },
              [
                _c("button", {
                  staticClass: "btn mt20",
                  staticStyle: { "justify-content": "center" },
                  domProps: { textContent: _vm._s(_vm.$t("Save")) },
                  on: { click: _vm.save },
                }),
              ]
            ),
          ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }