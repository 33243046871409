var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: { width: "676px" },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("div", { staticClass: "position-payment" }, [
        _c("div", { staticClass: "position-payment__inedit form-site" }, [
          _c(
            "div",
            { staticClass: "flex j-end" },
            [
              _c("span", {
                staticClass: "mie8",
                domProps: { textContent: _vm._s(_vm.$t("Active")) },
              }),
              _c("swich-button", {
                model: {
                  value: _vm.positionCopy.active,
                  callback: function ($$v) {
                    _vm.$set(_vm.positionCopy, "active", $$v)
                  },
                  expression: "positionCopy.active",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-site__group" },
            [
              _c("form-site-field-select-box", {
                attrs: {
                  title: "Position",
                  maxHeight: "200px",
                  width: "220px",
                  translate: false,
                  options: _vm.positionsOptions,
                },
                model: {
                  value: _vm.positionCopy.position_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.positionCopy, "position_id", $$v)
                  },
                  expression: "positionCopy.position_id",
                },
              }),
              _c("div", { staticClass: "form-site__field flex bet" }, [
                _c("div"),
              ]),
              _vm.restaurantCurrencyInfo == null
                ? _c("form-site-field-text", {
                    attrs: { title: "Amount", sign: "₪" },
                    model: {
                      value: _vm.rate,
                      callback: function ($$v) {
                        _vm.rate = $$v
                      },
                      expression: "rate",
                    },
                  })
                : _vm._e(),
              _vm.restaurantCurrencyInfo !== null
                ? _c("form-site-field-text", {
                    attrs: {
                      title: "Amount",
                      sign: _vm.restaurantCurrencySymbol,
                    },
                    model: {
                      value: _vm.rate,
                      callback: function ($$v) {
                        _vm.rate = $$v
                      },
                      expression: "rate",
                    },
                  })
                : _vm._e(),
              _c("form", { staticClass: "form-site__field" }, [
                _c("div", {
                  staticClass: "form-site__label mt2",
                  domProps: {
                    textContent: _vm._s(_vm.$t("Payment frequency")),
                  },
                }),
                _c("div", { staticClass: "form-site__input mt20 flex bet" }, [
                  _c("div", { staticClass: "input-radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.positionCopy.rate_period,
                          expression: "positionCopy.rate_period",
                        },
                      ],
                      attrs: { id: "radio1", type: "radio", name: "radio" },
                      domProps: {
                        value: 1,
                        checked: _vm._q(_vm.positionCopy.rate_period, 1),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(_vm.positionCopy, "rate_period", 1)
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "input-radio__label",
                        attrs: { for: "radio1" },
                      },
                      [
                        _c("span", { staticClass: "input-radio__mark" }),
                        _c("span", {
                          staticClass: "input-radio__text",
                          domProps: { textContent: _vm._s(_vm.$t("Hourly")) },
                        }),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "input-radio pie32" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.positionCopy.rate_period,
                          expression: "positionCopy.rate_period",
                        },
                      ],
                      attrs: { id: "radio2", type: "radio", name: "radio" },
                      domProps: {
                        value: 2,
                        checked: _vm._q(_vm.positionCopy.rate_period, 2),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(_vm.positionCopy, "rate_period", 2)
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "input-radio__label",
                        attrs: { for: "radio2" },
                      },
                      [
                        _c("span", { staticClass: "input-radio__mark" }),
                        _c("span", {
                          staticClass: "input-radio__text",
                          domProps: { textContent: _vm._s(_vm.$t("Daily")) },
                        }),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _vm.restaurantCurrencyInfo == null
                ? _c("form-site-field-text", {
                    attrs: {
                      placeholder: "0",
                      title: "Travels (daily)",
                      sign: "₪",
                    },
                    model: {
                      value: _vm.positionCopy.travel_rate,
                      callback: function ($$v) {
                        _vm.$set(_vm.positionCopy, "travel_rate", $$v)
                      },
                      expression: "positionCopy.travel_rate",
                    },
                  })
                : _vm._e(),
              _vm.restaurantCurrencyInfo !== null
                ? _c("form-site-field-text", {
                    attrs: {
                      placeholder: "0",
                      title: "Travels (maximum)",
                      sign: _vm.restaurantCurrencySymbol,
                    },
                    model: {
                      value: _vm.positionCopy.travel_rate_max,
                      callback: function ($$v) {
                        _vm.$set(_vm.positionCopy, "travel_rate_max", $$v)
                      },
                      expression: "positionCopy.travel_rate_max",
                    },
                  })
                : _vm._e(),
              _vm.restaurantCurrencyInfo !== null
                ? _c("form-site-field-text", {
                    attrs: {
                      placeholder: "0",
                      title: "Travels (daily)",
                      sign: _vm.restaurantCurrencySymbol,
                    },
                    model: {
                      value: _vm.positionCopy.travel_rate,
                      callback: function ($$v) {
                        _vm.$set(_vm.positionCopy, "travel_rate", $$v)
                      },
                      expression: "positionCopy.travel_rate",
                    },
                  })
                : _vm._e(),
              _vm.restaurantCurrencyInfo == null
                ? _c("form-site-field-text", {
                    attrs: {
                      placeholder: "0",
                      title: "Travels (maximum)",
                      sign: "₪",
                    },
                    model: {
                      value: _vm.positionCopy.travel_rate_max,
                      callback: function ($$v) {
                        _vm.$set(_vm.positionCopy, "travel_rate_max", $$v)
                      },
                      expression: "positionCopy.travel_rate_max",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "form",
            { staticClass: "form-site__field" },
            [
              _c("div", {
                staticClass: "form-site__label",
                domProps: { textContent: _vm._s(_vm.$t("Branch")) },
              }),
              _c("select-box", {
                attrs: {
                  maxHeight: "350px",
                  showSearch: "",
                  options: _vm.branches.map(function (ref) {
                    var name_en = ref.name_en
                    var name = ref.name
                    var id = ref.id

                    return {
                      label: _vm.he || !name_en ? name : name_en,
                      value: id,
                    }
                  }),
                },
                model: {
                  value: _vm.branchId,
                  callback: function ($$v) {
                    _vm.branchId = $$v
                  },
                  expression: "branchId",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "form-site__field" }, [
            _c(
              "label",
              {
                key: "usable_without_shift",
                staticClass: "permissions-position__item",
              },
              [
                _c("checkbox", {
                  attrs: { name: "usable_without_shift" },
                  model: {
                    value: _vm.positionCopy.usable_without_shift,
                    callback: function ($$v) {
                      _vm.$set(_vm.positionCopy, "usable_without_shift", $$v)
                    },
                    expression: "positionCopy.usable_without_shift",
                  },
                }),
                _c("div", {
                  staticClass: "permissions-position__text",
                  domProps: {
                    textContent: _vm._s(_vm.$t("Usable without shift")),
                  },
                }),
              ],
              1
            ),
            _vm.permissions
              ? _c("div", {
                  staticClass: "form-site__label",
                  domProps: { textContent: _vm._s(_vm.$t("Permissions")) },
                })
              : _vm._e(),
            _c(
              "div",
              { staticClass: "permissions-position" },
              _vm._l(
                Object.keys(_vm.employeePermissionsTranslation),
                function (index) {
                  return _c(
                    "label",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.permissionChangeAllowed(
                            _vm.employeePermissionsTranslation[index].value
                          ),
                          expression:
                            "\n              permissionChangeAllowed(\n                employeePermissionsTranslation[index].value\n              )\n            ",
                        },
                      ],
                      key: _vm.employeePermissionsTranslation[index].value,
                      staticClass: "permissions-position__item",
                    },
                    [
                      _c("checkbox", {
                        attrs: {
                          name: _vm.employeePermissionsTranslation[index].value,
                          disabled:
                            _vm.fixedPermissionValue(+index) ||
                            _vm.permissionsViewOnly,
                          value:
                            _vm.permissionValue(+index) ||
                            _vm.fixedPermissionValue(+index),
                        },
                        on: {
                          input: function ($event) {
                            return _vm.inputPremission($event, +index)
                          },
                        },
                      }),
                      _c("div", {
                        staticClass: "permissions-position__text",
                        class: { grey: _vm.fixedPermissionValue(+index) },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              _vm.employeePermissionsTranslation[index]
                                .translation
                            )
                          ),
                        },
                      }),
                    ],
                    1
                  )
                }
              ),
              0
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "form-site__btn form-site__btn_sb d-md-flex d-none" },
        [
          _c("remove-btn", {
            on: {
              click: function ($event) {
                return _vm.$emit("remove")
              },
            },
          }),
          _c("button", {
            staticClass: "btn",
            domProps: { textContent: _vm._s(_vm.$t("Save")) },
            on: { click: _vm.save },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
        _c(
          "button",
          {
            staticClass: "bin-btn",
            on: {
              click: function ($event) {
                return _vm.$emit("remove")
              },
            },
          },
          [
            _c("svg-icon", {
              attrs: { name: "ic-bin", width: "18", height: "20" },
            }),
          ],
          1
        ),
        _c(
          "button",
          { staticClass: "save-btn", on: { click: _vm.save } },
          [
            _c("svg-icon", {
              staticClass: "ic-edit",
              attrs: { name: "ic-save-btn", width: "93", height: "93" },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }