var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", [
    _c("input", {
      attrs: { type: "radio", name: _vm.name },
      domProps: { value: _vm.val, checked: _vm.isChecked },
      on: { input: _vm.change },
    }),
    _c("span", {
      staticClass: "radio-circle",
      class: { checked: _vm.isChecked },
    }),
    _c("span", [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }