import { render, staticRenderFns } from "./change-moneybox-dialog.vue?vue&type=template&id=3c120496&scoped=true&"
import script from "./change-moneybox-dialog.vue?vue&type=script&lang=ts&"
export * from "./change-moneybox-dialog.vue?vue&type=script&lang=ts&"
import style0 from "./change-moneybox-dialog.vue?vue&type=style&index=0&id=3c120496&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c120496",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/dorshoshan/Documents/DEV/Atmos-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3c120496')) {
      api.createRecord('3c120496', component.options)
    } else {
      api.reload('3c120496', component.options)
    }
    module.hot.accept("./change-moneybox-dialog.vue?vue&type=template&id=3c120496&scoped=true&", function () {
      api.rerender('3c120496', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/apps/dispatcher/dialogs/change-moneybox-dialog.vue"
export default component.exports