var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main_gr" }, [
    _c("div", { staticClass: "g" }, [
      _c("span", {
        staticClass: "mie8",
        domProps: {
          textContent: _vm._s(_vm.$t("graph of all orders by type")),
        },
      }),
      _c("canvas", { staticClass: "chart", attrs: { id: "myChart" } }),
    ]),
    _c("div", { staticClass: "g" }, [
      _c("span", {
        staticClass: "mie8",
        domProps: {
          textContent: _vm._s(_vm.$t("graph of all orders by source")),
        },
      }),
      _c("canvas", { staticClass: "chart", attrs: { id: "myChart2" } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }