var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "scaled-orders" }, [
    _c("header", { staticClass: "scaled-orders__header" }, [
      _c("div", { staticClass: "flex" }, [
        _c(
          "button",
          {
            staticClass: "scaled-orders__header_back",
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [
            _c("svg-icon", {
              staticClass: "ic-red mie8",
              attrs: { name: "ic-arrow-left", width: "16", height: "12" },
            }),
            _c("span", { domProps: { textContent: _vm._s(_vm.$t("Back")) } }),
          ],
          1
        ),
        _c("div", {
          staticClass: "scaled-orders__header_name",
          domProps: {
            textContent: _vm._s(_vm.$t("Order") + " " + _vm.order.unique_id),
          },
        }),
      ]),
      _c(
        "button",
        {
          staticClass: "avatar flex j-end a-center t-start",
          on: {
            click: function ($event) {
              return _vm.$emit("goToUser")
            },
          },
        },
        [
          _c("user-avatar", {
            attrs: {
              user: _vm.order.user,
              "img-class": "call-panel-user__photo",
              size: 48,
            },
          }),
          _c("div", { staticClass: "mis8" }, [
            _c("div", { staticClass: "avatar__img" }),
            _vm.order.user
              ? _c("div", { staticClass: "avatar__text" }, [
                  _c("div", {
                    staticClass: "avatar__name",
                    domProps: { textContent: _vm._s(_vm.order.user.name) },
                  }),
                  _c("div", {
                    staticClass: "avatar__tel",
                    domProps: { textContent: _vm._s(_vm.order.user.phone) },
                  }),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
    ]),
    _c("div", {
      staticClass: "h1",
      domProps: { textContent: _vm._s(_vm.item.name) },
    }),
    _c("div", { staticClass: "scaled-orders__main" }, [
      _c("div", { staticClass: "before pirce-box" }, [
        _c("div", {
          staticClass: "pirce-box__title",
          domProps: { textContent: _vm._s(_vm.$t("Before")) },
        }),
        _c("div", {
          staticClass: "pirce-box__amount",
          domProps: { textContent: _vm._s("₪" + _vm.item.price) },
        }),
        _c("div", {
          staticClass: "pirce-box__count",
          domProps: { textContent: _vm._s(_vm.item.amount + _vm.$t("KG")) },
        }),
      ]),
      _c("div", { staticClass: "count-edit" }, [
        _c("div", { staticClass: "count-edit__title" }, [
          _c("span", {
            staticClass: "count-edit__amount",
            domProps: { textContent: _vm._s(_vm.itemCopy.amount) },
          }),
          _c("span", {
            staticClass: "count-edit__kg",
            domProps: { textContent: _vm._s(_vm.$t("KG")) },
          }),
        ]),
        _c(
          "div",
          { staticClass: "num-pod" },
          [
            _vm._l(9, function (n) {
              return _c("button", {
                key: n,
                staticClass: "num-pod__num",
                domProps: { textContent: _vm._s(n) },
                on: {
                  click: function ($event) {
                    return _vm.sddNum(n)
                  },
                },
              })
            }),
            _c("button", {
              staticClass: "num-pod__num",
              domProps: { textContent: _vm._s(".") },
              on: {
                click: function ($event) {
                  return _vm.sddNum(".")
                },
              },
            }),
            _c("button", {
              staticClass: "num-pod__num",
              domProps: { textContent: _vm._s("0") },
              on: {
                click: function ($event) {
                  return _vm.sddNum("0")
                },
              },
            }),
            _c(
              "div",
              { staticClass: "num-pod__num", on: { click: _vm.rmoveNum } },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "34",
                      height: "24",
                      viewBox: "0 0 34 24",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      staticClass: "main-second-fill",
                      attrs: {
                        "fill-rule": "evenodd",
                        "clip-rule": "evenodd",
                        d: "M34 3.1007V20.8993C34 22.6118 32.6136 24 30.9115 24H12.3636L0 12L12.3636 0H30.9115C32.6172 0 34 1.39408 34 3.1007Z",
                      },
                    }),
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        "clip-rule": "evenodd",
                        d: "M20.1574 10.9091L15.1898 6L14.0859 7.09091L19.0535 12L14.0859 16.9091L15.1898 18L20.1574 13.0909L25.1249 18L26.2288 16.9091L21.2613 12L26.2288 7.09091L25.1249 6L20.1574 10.9091Z",
                        fill: "#f1eff5",
                      },
                    }),
                  ]
                ),
              ]
            ),
          ],
          2
        ),
      ]),
      _c("div", { staticClass: "after pirce-box" }, [
        _c("div", {
          staticClass: "pirce-box__title",
          domProps: { textContent: _vm._s(_vm.$t("After")) },
        }),
        _c("div", {
          staticClass: "pirce-box__amount",
          domProps: { textContent: _vm._s("₪" + _vm.priceAfter) },
        }),
        _c("div", {
          staticClass: "pirce-box__count",
          domProps: { textContent: _vm._s(_vm.itemCopy.amount + _vm.$t("KG")) },
        }),
      ]),
    ]),
    _c("div", { staticClass: "new-item__btn flex" }, [
      _c(
        "button",
        {
          staticClass: "save-btn",
          on: {
            click: function ($event) {
              return _vm.$emit("save", +_vm.itemCopy.amount)
            },
          },
        },
        [
          _c(
            "svg",
            {
              attrs: {
                width: "120",
                height: "120",
                viewBox: "0 0 120 120",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("circle", {
                attrs: { cx: "60", cy: "48", r: "40", fill: "#48B26B" },
              }),
              _c("path", {
                attrs: {
                  d: "M78.2282 34.8424C77.4827 34.0968 76.274 34.0968 75.5283 34.8424L52.6562 57.7147L43.8645 48.9231C43.119 48.1775 41.9103 48.1775 41.1647 48.9231C40.4191 49.6686 40.4191 50.8773 41.1647 51.6229L51.3063 61.7643C52.0515 62.5098 53.2611 62.5093 54.0061 61.7643L78.2282 37.5423C78.9738 36.7968 78.9737 35.588 78.2282 34.8424Z",
                  fill: "white",
                },
              }),
            ]
          ),
        ]
      ),
      _c(
        "button",
        {
          staticClass: "cancel-btn",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [
          _c(
            "svg",
            {
              attrs: {
                width: "120",
                height: "120",
                viewBox: "0 0 120 120",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("circle", {
                staticClass: "main-second-fill",
                attrs: { cx: "60", cy: "48", r: "40" },
              }),
              _c("path", {
                attrs: {
                  d: "M74.4479 33.5486C73.7165 32.8171 72.5434 32.8171 71.8119 33.5486L59.9983 45.3623L48.1846 33.5486C47.4531 32.8171 46.28 32.8171 45.5486 33.5486C44.8171 34.28 44.8171 35.4531 45.5486 36.1846L57.3623 47.9983L45.5486 59.812C44.8171 60.5434 44.8171 61.7165 45.5486 62.448C45.9074 62.8068 46.3905 63 46.8597 63C47.3289 63 47.812 62.8206 48.1708 62.448L59.9845 50.6343L71.7981 62.448C72.157 62.8068 72.64 63 73.1092 63C73.5923 63 74.0615 62.8206 74.4203 62.448C75.1518 61.7165 75.1518 60.5434 74.4203 59.812L62.6343 47.9983L74.4479 36.1846C75.1794 35.4531 75.1794 34.28 74.4479 33.5486Z",
                  fill: "white",
                },
              }),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }