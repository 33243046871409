var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "popup__content" }, [
    _c("div", {
      staticClass: "h1",
      style: { display: "flex", "justify-content": "center" },
      domProps: {
        textContent: _vm._s(
          _vm.$t("Refund for delivery guy") + ": " + _vm.delivery_guy_name
        ),
      },
    }),
    _c("div", { staticClass: "form-choose" }, [
      _c(
        "form",
        {
          staticClass: "form-site-wrap",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.save.apply(null, arguments)
            },
          },
        },
        [_vm._m(0)]
      ),
    ]),
    _c(
      "div",
      { staticClass: "cover-table" },
      [
        _c(
          "table-list",
          _vm._b(
            {
              staticClass: "popup_table mb8",
              scopedSlots: _vm._u([
                {
                  key: "bottom-table",
                  fn: function () {
                    return [
                      _vm.deliveries.starting_money
                        ? _c(
                            "div",
                            { staticClass: "surplus-from-pos d-flex a-center" },
                            [
                              _c("checkbox", {
                                staticClass: "checkbox-in-table m16",
                                attrs: {
                                  name: "checkbox",
                                  value: _vm.addAmount,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.input()
                                  },
                                },
                              }),
                              _vm.restaurantCurrencyInfo === null
                                ? _c("div", {
                                    staticClass: "history-list-result__title",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Surplus_pos") +
                                          ": ₪" +
                                          _vm.deliveries.starting_money
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              _vm.restaurantCurrencyInfo !== null
                                ? _c("div", {
                                    staticClass: "history-list-result__title",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Surplus_pos") +
                                          ": " +
                                          _vm.restaurantCurrencySymbol +
                                          _vm.deliveries.starting_money
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.changesToSave.length > 0 || _vm.amount > 0
                        ? _c("div", { staticClass: "d-flex j-center" }, [
                            _vm.restaurantCurrencyInfo === null
                              ? _c("button", {
                                  staticClass: "btn m20",
                                  attrs: { type: "submit" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("Delivery Guy settle up") +
                                        " ₪" +
                                        _vm.amount.toFixed(2)
                                    ),
                                  },
                                  on: { click: _vm.save },
                                })
                              : _vm._e(),
                            _vm.restaurantCurrencyInfo !== null
                              ? _c("button", {
                                  staticClass: "btn m20",
                                  attrs: { type: "submit" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("Delivery Guy settle up") +
                                        " " +
                                        _vm.restaurantCurrencySymbol +
                                        _vm.amount.toFixed(2)
                                    ),
                                  },
                                  on: { click: _vm.save },
                                })
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            "table-list",
            _vm.dataTable,
            false
          )
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-site" }, [
      _c("div", { staticClass: "form-site__content" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }