var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "phon" }, [
    _c(
      "button",
      {
        staticClass: "btn-table-phone",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.makeCall.apply(null, arguments)
          },
        },
      },
      [
        _c("svg-icon", {
          staticClass: "ic-white",
          attrs: { name: "ic-calls", width: "16", height: "16" },
        }),
      ],
      1
    ),
    _c(
      "a",
      {
        staticClass: "btn-table-phone",
        attrs: { href: "tel:" + _vm.data },
        on: {
          click: function ($event) {
            $event.stopPropagation()
          },
        },
      },
      [
        _c("svg-icon", {
          staticClass: "ic-white",
          attrs: { name: "ic-calls", width: "16", height: "16" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }