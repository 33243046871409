var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "header__content md-none" }, [
      _c("div", { staticClass: "header__company" }, [
        _c("div", { staticClass: "header-company" }, [
          _vm.restaurantIcon
            ? _c("div", { staticClass: "header-company__logo" }, [
                _c("img", { attrs: { src: _vm.restaurantIcon, alt: "" } }),
              ])
            : _vm._e(),
          _c("div", {
            staticClass: "header-company__name",
            domProps: { textContent: _vm._s(_vm.restaurantName) },
          }),
          _vm.showSelectBoxBranches
            ? _c(
                "div",
                { staticClass: "header-company_branch" },
                [
                  _c(
                    "select-box",
                    _vm._b(
                      {
                        model: {
                          value: _vm.brancheIdText,
                          callback: function ($$v) {
                            _vm.brancheIdText = $$v
                          },
                          expression: "brancheIdText",
                        },
                      },
                      "select-box",
                      _vm.bindSelectBoxBranch,
                      false
                    )
                  ),
                ],
                1
              )
            : _vm.branchesOptions &&
              _vm.branchesOptions.length === 1 &&
              _vm.branch
            ? _c("div", { staticClass: "header-company_branch-limit" }, [
                _c("span", {
                  domProps: { textContent: _vm._s(_vm.branch.name) },
                }),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "top-menu" }, [
        _c(
          "div",
          {
            staticClass: "top-menu__item",
            class: { active: _vm.itemsType === "none" },
            on: {
              click: function ($event) {
                _vm.itemsType = "none"
              },
            },
          },
          [
            _c("div", {
              staticClass: "top-menu__link",
              domProps: { textContent: _vm._s(_vm.$t("All")) },
            }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "top-menu__item",
            class: { active: _vm.itemsType === "item" },
            on: {
              click: function ($event) {
                _vm.itemsType = "item"
              },
            },
          },
          [
            _c("div", {
              staticClass: "top-menu__link",
              domProps: { textContent: _vm._s(_vm.$t("Items")) },
            }),
            _vm.inactiveItems
              ? _c("div", {
                  staticClass: "inactive",
                  domProps: { textContent: _vm._s(_vm.inactiveItems) },
                })
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "top-menu__item mie64",
            class: { active: _vm.itemsType === "extra" },
            on: {
              click: function ($event) {
                _vm.itemsType = "extra"
              },
            },
          },
          [
            _c("div", {
              staticClass: "top-menu__link",
              domProps: { textContent: _vm._s(_vm.$t("Addons")) },
            }),
            _vm.inactiveAddons
              ? _c("div", {
                  staticClass: "inactive",
                  domProps: { textContent: _vm._s(_vm.inactiveAddons) },
                })
              : _vm._e(),
          ]
        ),
        _vm.checkPermissionPath("catalog_editor.full_access")
          ? _c(
              "div",
              {
                staticClass: "top-menu__item",
                class: { active: _vm.itemsType === "menu" },
                on: {
                  click: function ($event) {
                    _vm.itemsType = "menu"
                  },
                },
              },
              [
                _c("div", {
                  staticClass: "top-menu__link",
                  domProps: { textContent: _vm._s(_vm.$t("Menus")) },
                }),
                _vm.inactiveMenus
                  ? _c("div", {
                      staticClass: "inactive",
                      domProps: { textContent: _vm._s(_vm.inactiveMenus) },
                    })
                  : _vm._e(),
              ]
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "top-menu__item",
            class: { active: _vm.itemsType === "tag" },
            on: {
              click: function ($event) {
                _vm.itemsType = "tag"
              },
            },
          },
          [
            _c("div", {
              staticClass: "top-menu__link",
              domProps: { textContent: _vm._s(_vm.$t("Tags")) },
            }),
            _vm.inactiveTags
              ? _c("div", {
                  staticClass: "inactive",
                  domProps: { textContent: _vm._s(_vm.inactiveTags) },
                })
              : _vm._e(),
          ]
        ),
        _vm.checkPermissionPath("catalog_editor.full_access")
          ? _c(
              "div",
              {
                staticClass: "top-menu__item",
                class: { active: _vm.itemsType === "filter" },
                on: {
                  click: function ($event) {
                    _vm.itemsType = "filter"
                  },
                },
              },
              [
                _c("div", {
                  staticClass: "top-menu__link",
                  domProps: { textContent: _vm._s(_vm.$t("Filters")) },
                }),
                _vm.inactiveFilters
                  ? _c("div", {
                      staticClass: "inactive",
                      domProps: { textContent: _vm._s(_vm.inactiveFilters) },
                    })
                  : _vm._e(),
              ]
            )
          : _vm._e(),
      ]),
      _c(
        "form",
        { staticClass: "search-form", class: { active: _vm.searchActive } },
        [
          _c(
            "button",
            {
              staticClass: "search-form__btn",
              attrs: { type: "button" },
              on: { click: _vm.openSearch },
            },
            [
              _c("svg-icon", {
                staticClass: "ic-stroke",
                attrs: { name: "ic-search", width: "16", height: "16" },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "search-form__field" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchQuery,
                  expression: "searchQuery",
                },
              ],
              ref: "searchDesktopRef",
              attrs: {
                type: "text",
                placeholder: _vm.$t("Search") + "...",
                name: "searchQuery",
                "aria-label": "Search",
              },
              domProps: { value: _vm.searchQuery },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchQuery = $event.target.value
                },
              },
            }),
            _c(
              "div",
              {
                staticClass: "search-form__close",
                on: { click: _vm.closeSearch },
              },
              [
                _c("svg-icon", {
                  attrs: { name: "ic-close", width: "13", height: "13" },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ]),
    _c("div", { staticClass: "header__content d-md-none" }, [
      _c("div", { staticClass: "header-company pt1" }, [
        _vm.showSelectBoxBranches
          ? _c(
              "div",
              { staticClass: "header-company_branch" },
              [
                _c(
                  "select-box",
                  _vm._b(
                    {
                      model: {
                        value: _vm.brancheIdText,
                        callback: function ($$v) {
                          _vm.brancheIdText = $$v
                        },
                        expression: "brancheIdText",
                      },
                    },
                    "select-box",
                    _vm.bindSelectBoxBranch,
                    false
                  )
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "header__profile" }, [
        _c(
          "form",
          {
            staticClass: "search-form",
            class: { active: _vm.searchActive },
            on: {
              submit: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c(
              "button",
              {
                staticClass: "search-form__btn",
                on: { click: _vm.openSearch },
              },
              [
                _c("svg-icon", {
                  staticClass: "ic-stroke",
                  attrs: { name: "ic-search", width: "16", height: "16" },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "search-form__field" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchQuery,
                    expression: "searchQuery",
                  },
                ],
                ref: "searchMobileRef",
                attrs: { type: "text", placeholder: _vm.$t("Search...") },
                domProps: { value: _vm.searchQuery },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchQuery = $event.target.value
                  },
                },
              }),
              _c(
                "button",
                {
                  staticClass: "search-form__close",
                  on: { click: _vm.closeSearch },
                },
                [
                  _c("svg-icon", {
                    attrs: { name: "ic-close", width: "13", height: "13" },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "button",
          {
            staticClass: "ic-hamburger",
            on: {
              click: function ($event) {
                return _vm.$store.commit("toggleSidebar")
              },
            },
          },
          [_c("span")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }