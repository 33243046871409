import { render, staticRenderFns } from "./permissions-checkboxes.vue?vue&type=template&id=1f447615&scoped=true&"
import script from "./permissions-checkboxes.vue?vue&type=script&lang=ts&"
export * from "./permissions-checkboxes.vue?vue&type=script&lang=ts&"
import style0 from "./permissions-checkboxes.vue?vue&type=style&index=0&id=1f447615&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f447615",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/dorshoshan/Documents/DEV/Atmos-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1f447615')) {
      api.createRecord('1f447615', component.options)
    } else {
      api.reload('1f447615', component.options)
    }
    module.hot.accept("./permissions-checkboxes.vue?vue&type=template&id=1f447615&scoped=true&", function () {
      api.rerender('1f447615', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/permissions/permissions-checkboxes.vue"
export default component.exports