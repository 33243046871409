var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "base-dialog",
        {
          attrs: { width: "676px", title: "Settings" },
          on: {
            close: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [
          _c("div", { staticClass: "popup__content" }, [
            _c("div", { staticClass: "other-pay__list" }, [
              _c("div", { staticClass: "orders-settings__item" }, [
                _c("div", {
                  staticClass: "form-orders-set__label",
                  domProps: { textContent: _vm._s(_vm.$t("Takeaway ETA")) },
                }),
                _c(
                  "button",
                  {
                    staticClass: "input-label",
                    on: {
                      click: function ($event) {
                        _vm.numericKeyboardIsOpen = true
                      },
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "input-label__note",
                      domProps: { textContent: _vm._s(_vm.$t("min")) },
                    }),
                    _c("span", {
                      staticClass: "input",
                      domProps: { textContent: _vm._s(_vm.settingsCopy.time) },
                    }),
                  ]
                ),
              ]),
              _c(
                "div",
                { staticClass: "orders-settings__item" },
                [
                  _c("div", {
                    staticClass: "form-orders-set__label",
                    domProps: {
                      textContent: _vm._s(_vm.$t("Order completed alert type")),
                    },
                  }),
                  _c("select-box", {
                    attrs: {
                      unsort: "",
                      maxWidth: "150px",
                      options: _vm.completedAlertOpt,
                    },
                    model: {
                      value: _vm.settingsCopy.order_completed_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.settingsCopy, "order_completed_type", $$v)
                      },
                      expression: "settingsCopy.order_completed_type",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "orders-settings__item flex col a-start" },
                [
                  _c("div", {
                    staticClass: "form-orders-set__label",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(_vm.capitalize("order_completed_text"))
                      ),
                    },
                  }),
                  _c("div", { staticClass: "input-label form-site w100" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.settingsCopy.order_completed_text,
                          expression: "settingsCopy.order_completed_text",
                        },
                      ],
                      domProps: {
                        value: _vm.settingsCopy.order_completed_text,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.settingsCopy,
                            "order_completed_text",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]
              ),
              _c(
                "label",
                { staticClass: "orders-settings__item" },
                [
                  _c("span", {
                    staticClass: "orders-settings__label",
                    domProps: { textContent: _vm._s(_vm.$t("Future order")) },
                  }),
                  _c("swich-button", {
                    model: {
                      value: _vm.settingsCopy.future,
                      callback: function ($$v) {
                        _vm.$set(_vm.settingsCopy, "future", $$v)
                      },
                      expression: "settingsCopy.future",
                    },
                  }),
                ],
                1
              ),
              _c(
                "label",
                { staticClass: "orders-settings__item" },
                [
                  _c("span", {
                    staticClass: "orders-settings__label",
                    domProps: { textContent: _vm._s(_vm.$t("Instant order")) },
                  }),
                  _c("swich-button", {
                    model: {
                      value: _vm.settingsCopy.instant,
                      callback: function ($$v) {
                        _vm.$set(_vm.settingsCopy, "instant", $$v)
                      },
                      expression: "settingsCopy.instant",
                    },
                  }),
                ],
                1
              ),
              _c(
                "label",
                { staticClass: "orders-settings__item" },
                [
                  _c("span", {
                    staticClass: "orders-settings__label",
                    domProps: {
                      textContent: _vm._s(_vm.$t("Require employee selection")),
                    },
                  }),
                  _c("swich-button", {
                    model: {
                      value: _vm.settingsCopy.require_employee_auth,
                      callback: function ($$v) {
                        _vm.$set(_vm.settingsCopy, "require_employee_auth", $$v)
                      },
                      expression: "settingsCopy.require_employee_auth",
                    },
                  }),
                ],
                1
              ),
              _c(
                "label",
                { staticClass: "orders-settings__item" },
                [
                  _c("span", {
                    staticClass: "orders-settings__label",
                    domProps: { textContent: _vm._s(_vm.$t("Has tip")) },
                  }),
                  _c("swich-button", {
                    model: {
                      value: _vm.settingsCopy.has_tip,
                      callback: function ($$v) {
                        _vm.$set(_vm.settingsCopy, "has_tip", $$v)
                      },
                      expression: "settingsCopy.has_tip",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "popup__btn" }, [
              _c("button", {
                staticClass: "btn",
                domProps: { textContent: _vm._s(_vm.$t("Save")) },
                on: { click: _vm.save },
              }),
            ]),
          ]),
        ]
      ),
      _vm.numericKeyboardIsOpen
        ? _c("numeric-keyboard", {
            on: {
              input: function ($event) {
                _vm.numericKeyboardIsOpen = false
              },
            },
            model: {
              value: _vm.settingsCopy.time,
              callback: function ($$v) {
                _vm.$set(_vm.settingsCopy, "time", $$v)
              },
              expression: "settingsCopy.time",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }