var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "zeds full-screen" },
    [
      _c("div", { staticClass: "h" }, [
        _vm.mobile
          ? _c("div", { staticClass: "mobile-buttons" }, [
              _c(
                "button",
                { staticClass: "btn", on: { click: _vm.getCurrentZed } },
                [
                  _c("span", {
                    domProps: { textContent: _vm._s(_vm.$t("Current zed")) },
                  }),
                ]
              ),
              _c(
                "button",
                { staticClass: "btn", on: { click: _vm.showTaxArchiveDialog } },
                [
                  _c("span", {
                    domProps: { textContent: _vm._s(_vm.$t("Tax Archive")) },
                  }),
                ]
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "table-list",
        _vm._b(
          {
            ref: "zedsTable",
            on: {
              "on-click-line": _vm.openPopup,
              changeOptions: _vm.changeOptions,
            },
            scopedSlots: _vm._u([
              {
                key: "btn-back",
                fn: function () {
                  return [
                    _c(
                      "router-link",
                      {
                        staticClass: "page__back",
                        attrs: { to: "../settings" },
                      },
                      [
                        _c("svg-icon", {
                          attrs: {
                            name: "ic-close-table-arrow",
                            width: "34",
                            height: "34",
                          },
                        }),
                      ],
                      1
                    ),
                    !_vm.mobile
                      ? _c("h1", {
                          style: { "margin-bottom": "0px" },
                          domProps: { textContent: _vm._s(_vm.$t("Zeds")) },
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
              {
                key: "btn",
                fn: function () {
                  return [
                    !_vm.mobile
                      ? _c("div", { staticClass: "desktop-buttons" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              on: { click: _vm.getCurrentZed },
                            },
                            [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Current zed")),
                                },
                              }),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              on: { click: _vm.showTaxArchiveDialog },
                            },
                            [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Tax Archive")),
                                },
                              }),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "table-list",
          _vm.dataTable,
          false
        )
      ),
      _vm.popup == 2
        ? _c("closing-zed-dialog", {
            attrs: { zedToClose: _vm.zed },
            on: { close: _vm.close, currentZed: _vm.showcurrentZed },
          })
        : _vm._e(),
      _vm.popup === 1
        ? _c("show-zed-details", {
            attrs: { zed: _vm.selectedZed, canCloseZed: _vm.toCloseZed },
            on: { save: _vm.save, close: _vm.close, sendSms: _vm.sendSms },
          })
        : _vm._e(),
      _vm.taxArchiveDialog
        ? _c(
            "base-dialog",
            {
              attrs: { width: "500px", "overflow-visible": true },
              on: { close: _vm.hideTaxArchiveDialog },
            },
            [
              _c("div", { staticClass: "dialog__content" }, [
                _c("h2", {
                  domProps: { textContent: _vm._s(_vm.$t("Tax Archive")) },
                }),
                _c("p", {
                  domProps: {
                    textContent: _vm._s(_vm.$t("Select year and months")),
                  },
                }),
                _c("div", { staticClass: "form-site" }, [
                  _c(
                    "div",
                    { staticClass: "form-site__field" },
                    [
                      _c("span", { staticClass: "form-site__label" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("Year")) +
                            "\n          "
                        ),
                      ]),
                      _c("select-box", {
                        attrs: { options: _vm.yearsOptions },
                        model: {
                          value: _vm.taxArchiveYear,
                          callback: function ($$v) {
                            _vm.taxArchiveYear = $$v
                          },
                          expression: "taxArchiveYear",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-site__field" },
                    [
                      _c("span", { staticClass: "form-site__label" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("Month")) +
                            "\n          "
                        ),
                      ]),
                      _c("select-box", {
                        attrs: { options: _vm.monthsOptions, unsort: true },
                        model: {
                          value: _vm.taxArchiveMonths,
                          callback: function ($$v) {
                            _vm.taxArchiveMonths = $$v
                          },
                          expression: "taxArchiveMonths",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "dialog__footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn--secondary",
                    attrs: { disabled: _vm.taxArchiveDownloadRequestDisabled },
                    on: { click: _vm.getTaxArchiveLink },
                  },
                  [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.$t("Download")) },
                    }),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline btn--secondary",
                    on: { click: _vm.hideTaxArchiveDialog },
                  },
                  [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.$t("Close")) },
                    }),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }