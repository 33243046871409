var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-dialog-tabs", {
    staticClass: "atmos-promotion-edit-dialog",
    attrs: {
      width: "800px",
      tabList: [
        "Statistics",
        "Information",
        "Coupons banks",
        "Availability",
        "Branches",
      ],
    },
    on: { close: _vm.close },
    scopedSlots: _vm._u([
      {
        key: "tab0",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass: "header-back back d-flex d-md-none",
                style: { "margin-right": "-25px", width: "103vw" },
              },
              [
                _c(
                  "button",
                  { staticClass: "header__back", on: { click: _vm.close } },
                  [
                    _c(
                      "span",
                      { staticClass: "header-company" },
                      [
                        _c("svg-icon", {
                          staticClass: "ic-white",
                          attrs: {
                            name: "ic-arrow-left",
                            width: "16",
                            height: "12",
                          },
                        }),
                        _c("span", {
                          domProps: { textContent: _vm._s(_vm.$t("Back")) },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c("div", { staticClass: "coupon-statistic" }, [
                  _vm.promotionEdit &&
                  _vm.promotionEdit.uses &&
                  _vm.promotionEdit.uses.length > 0
                    ? _c(
                        "div",
                        [
                          _c("div", {
                            staticClass: "used-orders red",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Realizations")),
                            },
                          }),
                          _vm._l(_vm.promotionEdit.uses, function (use) {
                            return _c("div", [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      _vm.convertBranchIdToString(use.branch_id)
                                    ) +
                                      " : " +
                                      _vm.promotionEdit.uses
                                  ),
                                },
                              }),
                            ])
                          }),
                        ],
                        2
                      )
                    : _c("span", {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "center",
                          width: "100%",
                        },
                        domProps: {
                          textContent: _vm._s(_vm.$t("No data available")),
                        },
                      }),
                ]),
              ]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "tab1",
        fn: function () {
          return [
            _vm.promotionEdit
              ? _c("div", { staticClass: "info" }, [
                  _c("div", { staticClass: "info__separate_line" }),
                  _c(
                    "div",
                    {
                      staticClass: "theme-view-menu",
                      staticStyle: { display: "flex", "margin-bottom": "10px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "theme-view-menu__item",
                          class: { active: _vm.picture_mode === "Desktop" },
                          staticStyle: { width: "50px" },
                          on: {
                            click: function ($event) {
                              _vm.picture_mode = "Desktop"
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "theme-view-menu__icon" },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-desctop",
                                  width: "20",
                                  height: "20",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "theme-view-menu__item",
                          class: { active: _vm.picture_mode === "Mobile" },
                          staticStyle: { width: "50px" },
                          on: {
                            click: function ($event) {
                              _vm.picture_mode = "Mobile"
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "theme-view-menu__icon" },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-mobile",
                                  width: "12",
                                  height: "20",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "top_info" }, [
                    _c("div", { staticClass: "promo_img" }, [
                      _c(
                        "div",
                        {
                          staticClass: "main-settings__imgs",
                          staticStyle: {
                            display: "flex",
                            width: "100%",
                            "justify-content": "center",
                            gap: "20px",
                          },
                        },
                        [
                          _vm.picture_mode === "Desktop"
                            ? _c(
                                "div",
                                { staticClass: "main-settings__img-item" },
                                [
                                  _c("div", {
                                    staticClass: "main-settings__label",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Desktop image")
                                      ),
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "edit-logo",
                                      class: {
                                        "edit-img__content_empty":
                                          !_vm.image_desktopToShow,
                                      },
                                      style: {
                                        backgroundImage:
                                          "url(" +
                                          _vm.image_desktopToShow +
                                          ")",
                                      },
                                    },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "edit-logo__icon" },
                                        [
                                          _vm.image_desktopToShow
                                            ? _c(
                                                "button",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.removeImage(
                                                        "image_desktop"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      name: "ic-bin",
                                                      width: "22",
                                                      height: "22",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : [
                                                _c("input", {
                                                  attrs: {
                                                    type: "file",
                                                    name: "logo",
                                                    accept: "image/*",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.enterPhotodesktop,
                                                  },
                                                }),
                                                _c("svg-icon", {
                                                  attrs: {
                                                    name: "ic-plus",
                                                    width: "22",
                                                    height: "22",
                                                  },
                                                }),
                                              ],
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.picture_mode === "Mobile"
                            ? _c(
                                "div",
                                { staticClass: "main-settings__img-item" },
                                [
                                  _c("div", {
                                    staticClass: "main-settings__label",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Mobile image")
                                      ),
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "edit-logo",
                                      class: {
                                        "edit-img__content_empty":
                                          !_vm.image_mobileToShow,
                                      },
                                      style: {
                                        backgroundImage:
                                          "url(" + _vm.image_mobileToShow + ")",
                                      },
                                    },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "edit-logo__icon" },
                                        [
                                          _vm.image_mobileToShow
                                            ? _c(
                                                "button",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeImage(
                                                        "image_mobile"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      name: "ic-bin",
                                                      width: "22",
                                                      height: "22",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : [
                                                _c("input", {
                                                  attrs: {
                                                    type: "file",
                                                    name: "logo",
                                                    accept: "image/*",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.enterPhotoMobail,
                                                  },
                                                }),
                                                _c("svg-icon", {
                                                  attrs: {
                                                    name: "ic-plus",
                                                    width: "22",
                                                    height: "22",
                                                  },
                                                }),
                                              ],
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "promo_name" }, [
                      _c("div", { staticClass: "choose-promo" }, [
                        _c(
                          "div",
                          { staticClass: "top_header_p" },
                          [
                            _c("form-site-field-text", {
                              staticClass: "promotion_name",
                              attrs: { title: "Promotion name" },
                              model: {
                                value: _vm.promotionEdit.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.promotionEdit, "name", $$v)
                                },
                                expression: "promotionEdit.name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "top_header_p" },
                          [
                            _vm.english
                              ? _c("form-site-field-text", {
                                  staticClass: "promotion_name",
                                  attrs: { title: "Promotion title" },
                                  model: {
                                    value: _vm.promotionEdit.title.en,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.promotionEdit.title,
                                        "en",
                                        $$v
                                      )
                                    },
                                    expression: "promotionEdit.title.en",
                                  },
                                })
                              : _c("form-site-field-text", {
                                  staticClass: "promotion_name",
                                  attrs: { title: "Promotion title" },
                                  model: {
                                    value: _vm.promotionEdit.title.he,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.promotionEdit.title,
                                        "he",
                                        $$v
                                      )
                                    },
                                    expression: "promotionEdit.title.he",
                                  },
                                }),
                            _c(
                              "div",
                              {
                                staticClass: "lang lang_grey md-none",
                                staticStyle: { "max-height": "50px" },
                              },
                              [
                                _c("button", {
                                  staticClass: "lang__item",
                                  class: { active: _vm.english },
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("English")),
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.english = true
                                    },
                                  },
                                }),
                                _c("button", {
                                  staticClass: "lang__item",
                                  class: { active: !_vm.english },
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Hebrew")),
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.english = false
                                    },
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "description_promotion" }, [
                          _c("div", { staticClass: "form-site__field" }, [
                            _c("div", {
                              staticClass: "form-site__label",
                              domProps: {
                                textContent: _vm._s(_vm.$t("description")),
                              },
                            }),
                            _c("div", { staticClass: "form-site__input" }, [
                              _vm.english
                                ? _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.promotionEdit.description.en,
                                        expression:
                                          "promotionEdit.description.en",
                                      },
                                    ],
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "Write your description"
                                      ),
                                    },
                                    domProps: {
                                      value: _vm.promotionEdit.description.en,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.promotionEdit.description,
                                          "en",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  })
                                : _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.promotionEdit.description.he,
                                        expression:
                                          "promotionEdit.description.he",
                                      },
                                    ],
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "Write your description"
                                      ),
                                    },
                                    domProps: {
                                      value: _vm.promotionEdit.description.he,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.promotionEdit.description,
                                          "he",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "info_created_by" }, [
                        _vm.promotionEdit.creator_id
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("Created by") +
                                    _vm.promotionEdit.creator_id
                                )
                              ),
                            ])
                          : _c("span", [
                              _vm._v(_vm._s(_vm.$t("Created")) + " "),
                            ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("At")) + " " + _vm._s(_vm.created_at)
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "info__separate_line2" }),
                  _c("div", { staticClass: "middle_info" }, [
                    _c("h3", {
                      staticClass: "info_settings",
                      domProps: { textContent: _vm._s(_vm.$t("Settings")) },
                    }),
                    _c("div", { staticClass: "middle_info_settings_fields" }, [
                      _c(
                        "div",
                        { staticClass: "middle_info_settings_fields__field" },
                        [
                          _c("span", {
                            staticClass:
                              "middle_info_settings_fields__field__title",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Trigger type")),
                            },
                          }),
                          _c("span", {
                            staticClass:
                              "middle_info_settings_fields__field__value",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  _vm.triggerToText(_vm.promotionEdit.trigger)
                                )
                              ),
                            },
                          }),
                        ]
                      ),
                      _vm.promotionEdit.trigger == 2
                        ? _c(
                            "div",
                            {
                              staticClass: "middle_info_settings_fields__field",
                            },
                            [
                              _c("span", {
                                staticClass:
                                  "middle_info_settings_fields__field__title",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Coupon type")),
                                },
                              }),
                              _c("span", {
                                staticClass:
                                  "middle_info_settings_fields__field__value",
                                domProps: {
                                  textContent: _vm._s(_vm.useTypeDisplay),
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm.isReusable
                        ? _c(
                            "div",
                            {
                              staticClass: "middle_info_settings_fields__field",
                            },
                            [
                              _c("span", {
                                staticClass:
                                  "middle_info_settings_fields__field__title",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Coupon name")),
                                },
                              }),
                              _c("span", {
                                staticClass:
                                  "middle_info_settings_fields__field__coupon",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.promotionEdit.coupon_settings
                                      .reusable_code
                                  ),
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "middle_info_settings_fields__field" },
                        [
                          _c("span", {
                            staticClass:
                              "middle_info_settings_fields__field__title",
                            domProps: { textContent: _vm._s(_vm.$t("Period")) },
                          }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "column",
                                gap: "10px",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "start",
                                    gap: "50px",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass:
                                      "middle_info_settings_fields__field__value",
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Start")),
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        gap: "10px",
                                        "align-items": "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "input-date" },
                                        [
                                          _c("date-picker", {
                                            staticClass: "date-picker",
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var inputValue =
                                                      ref.inputValue
                                                    var inputEvents =
                                                      ref.inputEvents
                                                    return [
                                                      _c(
                                                        "input",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "bg-white border px-2 py-1 rounded",
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            domProps: {
                                                              value:
                                                                inputValue.replaceAll(
                                                                  ".",
                                                                  "/"
                                                                ),
                                                            },
                                                          },
                                                          inputEvents
                                                        )
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              52154283
                                            ),
                                            model: {
                                              value: _vm.startDate,
                                              callback: function ($$v) {
                                                _vm.startDate = $$v
                                              },
                                              expression: "startDate",
                                            },
                                          }),
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "ic-calendar",
                                              width: "20",
                                              height: "20",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "input-date" },
                                        [
                                          _c("select-box", {
                                            ref: "timepicker2",
                                            staticClass: "t-center",
                                            attrs: {
                                              width: "100%",
                                              autocomplete: "",
                                              options: _vm.listHours,
                                              placeholder: "00:00",
                                            },
                                            model: {
                                              value: _vm.startHour,
                                              callback: function ($$v) {
                                                _vm.startHour = $$v
                                              },
                                              expression: "startHour",
                                            },
                                          }),
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "ic-watch",
                                              width: "20",
                                              height: "20",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "start",
                                    gap: "50px",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass:
                                      "middle_info_settings_fields__field__value",
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("End")),
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        gap: "10px",
                                        "align-items": "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "input-date" },
                                        [
                                          _c("date-picker", {
                                            staticClass: "date-picker",
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var inputValue =
                                                      ref.inputValue
                                                    var inputEvents =
                                                      ref.inputEvents
                                                    return [
                                                      _c(
                                                        "input",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "bg-white border px-2 py-1 rounded",
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            domProps: {
                                                              value:
                                                                inputValue.replaceAll(
                                                                  ".",
                                                                  "/"
                                                                ),
                                                            },
                                                          },
                                                          inputEvents
                                                        )
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              52154283
                                            ),
                                            model: {
                                              value: _vm.endDate,
                                              callback: function ($$v) {
                                                _vm.endDate = $$v
                                              },
                                              expression: "endDate",
                                            },
                                          }),
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "ic-calendar",
                                              width: "20",
                                              height: "20",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "input-date" },
                                        [
                                          _c("select-box", {
                                            ref: "timepicker2",
                                            staticClass: "t-center",
                                            attrs: {
                                              width: "100%",
                                              autocomplete: "",
                                              options: _vm.listHours,
                                              placeholder: "00:00",
                                            },
                                            model: {
                                              value: _vm.endHour,
                                              callback: function ($$v) {
                                                _vm.endHour = $$v
                                              },
                                              expression: "endHour",
                                            },
                                          }),
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "ic-watch",
                                              width: "20",
                                              height: "20",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "middle_info_settings_fields__field" },
                        [
                          _c("span", {
                            staticClass:
                              "middle_info_settings_fields__field__title",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Audience")),
                            },
                          }),
                          _vm.promotionEdit.club_members_only
                            ? _c("span", {
                                staticClass:
                                  "middle_info_settings_fields__field__value",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("Club members only")
                                  ),
                                },
                              })
                            : _vm._e(),
                          !_vm.promotionEdit.audience_ids
                            ? _c("span", {
                                staticClass:
                                  "middle_info_settings_fields__field__value",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("All")),
                                },
                              })
                            : _vm._e(),
                          _vm.promotionEdit.audience_ids &&
                          _vm.promotionEdit.audience_ids.length > 0
                            ? _c(
                                "div",
                                [
                                  _c("form-site-field-select-box", {
                                    staticClass: "select-box-audiences w200",
                                    attrs: {
                                      widthHeader: "200px",
                                      width: "200px",
                                      firstTop: "",
                                      placeholder: _vm.$t("Audience"),
                                      showSearch: "",
                                      options: _vm.audiencesSelectOptions,
                                    },
                                    model: {
                                      value: _vm.promotionEdit.audience_ids,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.promotionEdit,
                                          "audience_ids",
                                          $$v
                                        )
                                      },
                                      expression: "promotionEdit.audience_ids",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "middle_info_settings_fields__field" },
                        [
                          _c("span", {
                            staticClass:
                              "middle_info_settings_fields__field__title",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("Additional settings")
                              ),
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "middle_info_settings_fields__field__max_per_order",
                            },
                            [
                              _c("span", {
                                staticClass:
                                  "middle_info_settings_fields__field__value",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("Maximum times per order")
                                  ),
                                },
                              }),
                              _c("span", {
                                staticClass:
                                  "middle_info_settings_fields__field__times_per_order",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.promotionEdit.max_per_order
                                  ),
                                },
                              }),
                            ]
                          ),
                          _c("div", { staticClass: "info__separate_line" }),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "footer_info" }, [
                      _c("div", { staticClass: "footer_info__header" }, [
                        _c("h3", {
                          domProps: {
                            textContent: _vm._s(_vm.$t("Conditions")),
                          },
                        }),
                        _c("div", { staticClass: "footer_info__buyget" }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "start",
                                gap: "10px",
                                width: "100%",
                              },
                            },
                            [
                              _c("button", {
                                staticClass: "btn buy_get_btn",
                                class: { active: _vm.buyget_mode === "Buy" },
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Buy")),
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.buyget_mode = "Buy"
                                  },
                                },
                              }),
                              _c("button", {
                                staticClass: "btn buy_get_btn",
                                class: { active: _vm.buyget_mode === "Get" },
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Get")),
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.buyget_mode = "Get"
                                  },
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                      _vm.buyget_mode === "Buy"
                        ? _c(
                            "div",
                            { staticClass: "footer_info__middle" },
                            [
                              _vm.promotionEdit.buy_conditions
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "footer_info__middle__buyItem",
                                      },
                                      [
                                        _c("span", {
                                          staticStyle: {
                                            "font-size": "16px",
                                            color: "#404040",
                                            "font-weight": "500",
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Buy an item/category")
                                            ),
                                          },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "footer_info__middle__buyItem__min_items",
                                          },
                                          [
                                            _c("span", {
                                              staticClass:
                                                "middle_info_settings_fields__field__times_per_order",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.promotionEdit
                                                    .min_client_orders
                                                ),
                                              },
                                            }),
                                            _c("span", {
                                              staticStyle: {
                                                "font-size": "18px",
                                                color: "#404040",
                                                "font-weight": "400",
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t("Items minimum")
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "footer_info__middle__from_category",
                                      },
                                      [
                                        _vm.promotionEdit.buy_conditions
                                          .condition === "item_exists"
                                          ? _c("span", {
                                              staticStyle: {
                                                "font-size": "16px",
                                                color: "#404040",
                                                "font-weight": "500",
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t("Buy an item/category")
                                                ),
                                              },
                                            })
                                          : _vm.promotionEdit.buy_conditions
                                              .condition === "item_missing"
                                          ? _c("span", {
                                              staticStyle: {
                                                "font-size": "16px",
                                                color: "#404040",
                                                "font-weight": "500",
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    "Didn’t buy an item/category"
                                                  )
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.buy_items
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "footer_info__middle__from_category__categories",
                                              },
                                              _vm._l(
                                                _vm.buy_items,
                                                function (cat) {
                                                  return _vm.buy_items
                                                    ? _c("div", {
                                                        staticClass:
                                                          "footer_info__middle__from_category__categories__category",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            cat.name
                                                          ),
                                                        },
                                                      })
                                                    : _vm._e()
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                : [
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.$t("No conditions"))),
                                    ]),
                                  ],
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.buyget_mode === "Get"
                        ? _c("div", { staticClass: "footer_info__middle" }, [
                            _vm.promotionEdit.get_conditions.is_percentage
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "footer_info__middle__buyItem",
                                  },
                                  [
                                    _c("span", {
                                      staticStyle: {
                                        "font-size": "16px",
                                        color: "#404040",
                                        "font-weight": "500",
                                      },
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t(
                                            "And get on specific item/category"
                                          )
                                        ),
                                      },
                                    }),
                                    _vm.promotionEdit.get_conditions.amount
                                      ? _c("span", {
                                          staticStyle: {
                                            "font-size": "16px",
                                            color: "#404040",
                                            "font-weight": "400",
                                            "margin-top": "20px",
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.promotionEdit.get_conditions
                                                .amount +
                                                "% " +
                                                _vm.$t("OFF")
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            !_vm.promotionEdit.get_conditions.is_percentage &&
                            _vm.restaurantCurrencyInfo == null
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "footer_info__middle__buyItem",
                                  },
                                  [
                                    _c("span", {
                                      staticStyle: {
                                        "font-size": "16px",
                                        color: "#404040",
                                        "font-weight": "500",
                                      },
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t(
                                            "And get on specific item/category"
                                          )
                                        ),
                                      },
                                    }),
                                    _vm.promotionEdit.get_conditions.amount
                                      ? _c("span", {
                                          staticStyle: {
                                            "font-size": "16px",
                                            color: "#404040",
                                            "font-weight": "400",
                                            "margin-top": "20px",
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              "₪" +
                                                _vm.promotionEdit.get_conditions
                                                  .amount +
                                                _vm.$t("OFF")
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            !_vm.promotionEdit.get_conditions.is_percentage &&
                            _vm.restaurantCurrencyInfo != null
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "footer_info__middle__buyItem",
                                  },
                                  [
                                    _c("span", {
                                      staticStyle: {
                                        "font-size": "16px",
                                        color: "#404040",
                                        "font-weight": "500",
                                      },
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t(
                                            "And get on specific item/category"
                                          )
                                        ),
                                      },
                                    }),
                                    _vm.promotionEdit.get_conditions.amount
                                      ? _c("span", {
                                          staticStyle: {
                                            "font-size": "16px",
                                            color: "#404040",
                                            "font-weight": "400",
                                            "margin-top": "20px",
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.restaurantCurrencySymbol +
                                                _vm.promotionEdit.get_conditions
                                                  .amount +
                                                " " +
                                                _vm.$t("OFF")
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "footer_info__middle__from_category",
                              },
                              [
                                _c("span", {
                                  staticStyle: {
                                    "font-size": "16px",
                                    color: "#404040",
                                    "font-weight": "500",
                                  },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("From categories & items")
                                    ),
                                  },
                                }),
                                _vm.get_items
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "footer_info__middle__from_category__categories",
                                      },
                                      _vm._l(_vm.get_items, function (cat) {
                                        return _vm.get_items
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "footer_info__middle__from_category__categories__category",
                                              },
                                              [
                                                cat && cat.name
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(cat.name) +
                                                          " (" +
                                                          _vm._s(cat.id) +
                                                          ")"
                                                      ),
                                                    ])
                                                  : _c("pre", [
                                                      _vm._v(_vm._s(cat)),
                                                    ]),
                                              ]
                                            )
                                          : _vm._e()
                                      }),
                                      0
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "tab2",
        fn: function () {
          return [
            _c("div", { staticClass: "info__separate_line" }),
            _vm.promotionEdit
              ? _c("div", { staticClass: "coupons_bank" }, [
                  _vm.hasBanks
                    ? _c(
                        "div",
                        { staticClass: "coupons_bank__banks" },
                        _vm._l(
                          _vm.promotionEdit.coupon_settings.banks,
                          function (bank) {
                            return _c(
                              "div",
                              { staticClass: "coupons_bank__banks__bank" },
                              [
                                bank
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "coupons_bank__banks__bank_header",
                                        },
                                        [
                                          _c("form-site-field-text", {
                                            ref: "name",
                                            refInFor: true,
                                            staticClass: "bank_name_field",
                                            attrs: { title: _vm.$t("Bank") },
                                            model: {
                                              value: bank.name,
                                              callback: function ($$v) {
                                                _vm.$set(bank, "name", $$v)
                                              },
                                              expression: "bank.name",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "switch-wrap inline-flex a-center",
                                            },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "switch-wrap__label mie12",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t("Active")
                                                  ),
                                                },
                                              }),
                                              bank
                                                ? _c("swich-button", {
                                                    attrs: {
                                                      value: bank.active,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "coupons_bank__banks__bank_footer",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "coupons_bank__banks__bank_footer__bank_settings",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "coupons_bank__banks__bank_footer__bank_settings__form_field",
                                                },
                                                [
                                                  _c("span", {
                                                    staticStyle: {
                                                      color: "#9B9B9B",
                                                      "font-size": "12px",
                                                      "font-weight": "500",
                                                    },
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t("Redeemed")
                                                      ),
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#4A4A4A",
                                                        "font-size": "16px",
                                                        "font-weight": "400",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.usedOfTotalBankCoupons(
                                                              bank
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "coupons_bank__banks__bank_footer__bank_settings__form_field",
                                                },
                                                [
                                                  _c("span", {
                                                    staticStyle: {
                                                      color: "#9B9B9B",
                                                      "font-size": "12px",
                                                      "font-weight": "500",
                                                    },
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t("Value")
                                                      ),
                                                    },
                                                  }),
                                                  _c("span", {
                                                    staticStyle: {
                                                      color: "#4A4A4A",
                                                      "font-size": "16px",
                                                      "font-weight": "400",
                                                    },
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        bank.value
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn coupons_bank__banks__bank_footer_coupon_bank",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showBank(bank)
                                                },
                                              },
                                            },
                                            [
                                              _c("span", {
                                                staticStyle: {
                                                  color: "#D60B52",
                                                  "font-size": "18px",
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t("Coupons bank")
                                                  ),
                                                },
                                              }),
                                              _c("svg-icon", {
                                                staticClass: "ic-white right",
                                                attrs: {
                                                  name: "ic-arrow-right",
                                                  width: "16",
                                                  height: "12",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          }
                        ),
                        0
                      )
                    : _c("span", {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "center",
                          width: "100%",
                        },
                        domProps: {
                          textContent: _vm._s(_vm.$t("No data available")),
                        },
                      }),
                ])
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "popups",
        fn: function () {
          return [
            _vm.openBank
              ? _c(
                  "base-dialog",
                  {
                    attrs: { width: "800px" },
                    on: {
                      close: function ($event) {
                        _vm.openBank = null
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "popup__content" }, [
                      _c("div", { staticClass: "form-lang" }, [
                        _c("div", { staticClass: "bank_coupons" }, [
                          _c("div", { staticClass: "bank_coupons__header" }, [
                            _c(
                              "button",
                              {
                                staticStyle: {
                                  width: "110px",
                                  height: "40px",
                                  "border-radius": "60px",
                                  background: "#FAF9F9",
                                  display: "flex",
                                  "justify-content": "space-around",
                                  "align-items": "center",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.openBank = null
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  staticClass: "ic-white",
                                  staticStyle: { fill: "#4A4A4A" },
                                  attrs: {
                                    name: "ic-arrow-left",
                                    width: "16",
                                    height: "12",
                                  },
                                }),
                                _c("span", {
                                  staticStyle: {
                                    color: "#4A4A4A",
                                    "font-size": "16px",
                                  },
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Back")),
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "info__separate_line2" }),
                          _c("div", { staticClass: "bank_coupons__main" }, [
                            _c("span", {
                              staticStyle: {
                                color: "#9B9B9B",
                                "font-size": "12px",
                                "margin-top": "5px",
                              },
                              domProps: { textContent: _vm._s(_vm.$t("Bank")) },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "coupons_bank__banks__bank_header",
                              },
                              [
                                _c("span", {
                                  staticStyle: {
                                    color: "#3C3C3B",
                                    "font-size": "32px",
                                  },
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Leumi 2023")),
                                  },
                                }),
                                _c(
                                  "button",
                                  {
                                    staticStyle: {
                                      width: "125px",
                                      height: "3rem",
                                      "border-radius": "100px",
                                      background: "#F28F160F",
                                      display: "flex",
                                      "align-items": "baseline",
                                      "justify-content": "space-around",
                                    },
                                    on: { click: _vm.exportOpenBankCodesToCsv },
                                  },
                                  [
                                    _c("span", {
                                      staticStyle: { color: "#F28F16" },
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Export")),
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "btn-icon__note",
                                        staticStyle: {
                                          position: "relative",
                                          display: "flex",
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            name: "ic-export",
                                            width: "20",
                                            height: "20",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "bank_coupons__coupons" }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "space-between",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "start",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "form-site-search__field",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.bank_coupon_search,
                                              expression: "bank_coupon_search",
                                            },
                                          ],
                                          staticClass: "pis36",
                                          attrs: {
                                            type: "text",
                                            placeholder: _vm.$t("Search..."),
                                          },
                                          domProps: {
                                            value: _vm.bank_coupon_search,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.bank_coupon_search =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-site-search__btn",
                                          },
                                          [
                                            _c("svg-icon", {
                                              staticClass: "ic-stroke",
                                              attrs: {
                                                name: "ic-search",
                                                width: "19",
                                                height: "19",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "justify-content": "end",
                                      display: "flex",
                                      gap: "5px",
                                    },
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "bank_coupons__coupons__buttons",
                                        class: { active: _vm.bank_btn == 1 },
                                        staticStyle: {
                                          width: "105px",
                                          background: "#EEEEEE",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.bank_btn = 1
                                          },
                                        },
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "bank_coupons__coupons__buttons__title1",
                                          class: { active: _vm.bank_btn == 1 },
                                          domProps: {
                                            textContent: _vm._s(_vm.$t("All")),
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "bank_coupons__coupons__buttons__text1",
                                            class: {
                                              active: _vm.bank_btn == 1,
                                            },
                                          },
                                          [
                                            _c("span", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.totalBankCoupons(
                                                    _vm.openBank
                                                  )
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "bank_coupons__coupons__buttons",
                                        class: { active: _vm.bank_btn == 2 },
                                        staticStyle: {
                                          background: "#EEEEEE",
                                          width: "165px",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.bank_btn = 2
                                          },
                                        },
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "bank_coupons__coupons__buttons__title2",
                                          class: { active: _vm.bank_btn == 2 },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Redeemed")
                                            ),
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "bank_coupons__coupons__buttons__text2",
                                            class: {
                                              active: _vm.bank_btn == 2,
                                            },
                                          },
                                          [
                                            _c("span", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.usedBankCoupons(
                                                    _vm.openBank
                                                  )
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "bank_coupons__coupons__buttons",
                                        class: { active: _vm.bank_btn == 3 },
                                        staticStyle: {
                                          background: "#EEEEEE",
                                          width: "171px",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.bank_btn = 3
                                          },
                                        },
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "bank_coupons__coupons__buttons__title3",
                                          class: { active: _vm.bank_btn == 3 },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Unredeemed")
                                            ),
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "bank_coupons__coupons__buttons__text3",
                                            class: {
                                              active: _vm.bank_btn == 3,
                                            },
                                          },
                                          [
                                            _c("span", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.unUsedBankCoupons(
                                                    _vm.openBank
                                                  )
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "bank_coupons__coupons_coupons_numbers",
                              },
                              _vm._l(_vm.openBankCoupons, function (n) {
                                return _c("div", {
                                  staticClass:
                                    "bank_coupons__coupons_coupons_numbers__coupon",
                                  domProps: { textContent: _vm._s(n) },
                                })
                              }),
                              0
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "tab3",
        fn: function () {
          return [
            _vm.availability
              ? _c("availability-tab", {
                  attrs: { availability: _vm.availability },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "tab4",
        fn: function () {
          return [
            _vm.availability
              ? _c("atmos-promos-branches-tab", {
                  attrs: { availability: _vm.availability },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "btns",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass: "coupon-info__btn md-none",
                staticStyle: {
                  position: "absolute",
                  margin: "40px 82px 60px",
                  bottom: "0",
                  width: "calc(100% - 164px)",
                  height: "0",
                },
              },
              [
                _vm.isDeletable
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn_border",
                        attrs: { type: "button" },
                        on: { click: _vm.softDeletePromotion },
                      },
                      [_vm._v(_vm._s(_vm.$t("Delete")))]
                    )
                  : _vm._e(),
                _c("button", {
                  staticClass: "btn",
                  domProps: { textContent: _vm._s(_vm.$t("save")) },
                  on: { click: _vm.savePromotion },
                }),
              ]
            ),
            _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
              _c(
                "button",
                { staticClass: "save-btn", on: { click: _vm.savePromotion } },
                [
                  _c("svg-icon", {
                    staticClass: "ic-edit",
                    attrs: { name: "ic-save-btn", width: "93", height: "93" },
                  }),
                ],
                1
              ),
              _vm.isDeletable
                ? _c(
                    "button",
                    {
                      staticClass: "bin-btn",
                      attrs: { type: "button" },
                      on: { click: _vm.softDeletePromotion },
                    },
                    [
                      _c("svg-icon", {
                        attrs: { name: "ic-bin", width: "18", height: "20" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }