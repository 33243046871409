var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "member-club" },
    [
      _c("main", [
        _c("section", { staticClass: "container" }, [
          _c("div", { staticClass: "page" }, [
            _c(
              "div",
              { staticClass: "page__main" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "page__back",
                    attrs: { to: "/settings/rest-integrations" },
                  },
                  [
                    _c("svg-icon", {
                      attrs: {
                        name: "ic-close-table-arrow",
                        width: "34",
                        height: "34",
                      },
                    }),
                  ],
                  1
                ),
                _c("h1", {
                  domProps: { textContent: _vm._s(_vm.$t("Atmos club")) },
                }),
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    attrs: { type: "button" },
                    on: { click: _vm.editClub },
                  },
                  [_vm._v(_vm._s(_vm.$t("Edit Club")))]
                ),
                _c("div", { staticClass: "branches" }, [
                  _c(
                    "div",
                    { staticClass: "branches__sect" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "branches-item",
                          attrs: { to: "./atmos-club/promotions" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "branches-item__icon" },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-vouchers",
                                  width: "80",
                                  height: "80",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "branches-item__content" }, [
                            _c("div", {
                              staticClass: "branches-item__title",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Vouchers")),
                              },
                            }),
                            _c("div", {
                              staticClass: "branches-item__text",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("Benefits description")
                                ),
                              },
                            }),
                          ]),
                        ]
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "branches-item",
                          attrs: { to: "./atmos-club/members" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "branches-item__icon" },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-members",
                                  width: "80",
                                  height: "80",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "branches-item__content" }, [
                            _c("div", {
                              staticClass: "branches-item__title",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Members")),
                              },
                            }),
                            _c("div", {
                              staticClass: "branches-item__text",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("Members description")
                                ),
                              },
                            }),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm.showClubEditDialog
        ? _c("edit-atmos-club-dialog", {
            attrs: { club: _vm.club },
            on: { save: _vm.saveClub, close: _vm.saveClub },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }