var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.seconds
    ? _c("show-time", { attrs: { seconds: _vm.seconds, hides: ["seconds"] } })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }