var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "delivery-areas full-screen" },
    [
      _c(
        "table-list",
        _vm._b(
          {
            ref: "deliveryAreasTable",
            on: {
              "on-click-line": _vm.openPopup,
              changeOptions: _vm.changeOptions,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "btn-back",
                  fn: function () {
                    return [
                      _c(
                        "router-link",
                        {
                          staticClass: "page__back",
                          attrs: { to: "../settings" },
                        },
                        [
                          _c("svg-icon", {
                            attrs: {
                              name: "ic-close-table-arrow",
                              width: "34",
                              height: "34",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                _vm.mobile
                  ? {
                      key: "above-tabel",
                      fn: function () {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "mobile-btn-plus",
                              on: { click: _vm.createArea },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-plus",
                                  width: "16",
                                  height: "16",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : {
                      key: "btn",
                      fn: function () {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn_pink",
                              on: { click: _vm.createArea },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-plus",
                                  width: "16",
                                  height: "16",
                                },
                              }),
                              _c("span", {
                                staticClass: "md-none",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("New area")),
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                {
                  key: "filter-by-properties",
                  fn: function () {
                    return [
                      !_vm.mobile
                        ? _c(
                            "button",
                            {
                              staticClass: "btn",
                              style: { "min-width": "100px" },
                              on: { click: _vm.getBackupBranches },
                            },
                            [
                              _c("span", {
                                staticClass: "md-none",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Backup")),
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm.mobile
                        ? _c(
                            "button",
                            {
                              staticClass: "btn",
                              style: { "max-width": "100px" },
                              on: { click: _vm.getBackupBranches },
                            },
                            [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Backup")),
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          },
          "table-list",
          _vm.dataTable,
          false
        )
      ),
      _vm.popup === 1 && _vm.areaInEdit
        ? _c("edit-delivery-area-dialog", {
            attrs: {
              polygons: _vm.polygons,
              area: _vm.areaInEdit,
              editArea: true,
            },
            on: {
              remove: _vm.remove,
              saveMap: _vm.saveMap,
              save: _vm.saveDeliveryArea,
            },
          })
        : _vm._e(),
      _vm.popup === 5 && _vm.areaInEdit
        ? _c("edit-delivery-area-dialog", {
            attrs: {
              polygons: _vm.polygons,
              area: _vm.areaInEdit,
              editArea: false,
            },
            on: {
              remove: _vm.remove,
              saveMap: _vm.saveMap,
              save: _vm.saveDeliveryArea,
            },
          })
        : _vm._e(),
      _vm.popup === 2
        ? _c("geoman-map", {
            attrs: {
              optionsDeliveryAreas: _vm.optionsDeliveryAreas,
              orderAreas: _vm.orderAreas,
              geoJson: _vm.geoJson,
              id: _vm.areaIdPoligon,
            },
            on: { save: _vm.saveMap, changeMap: _vm.changeMap },
          })
        : _vm._e(),
      _vm.enterAreaId
        ? _c(
            "base-dialog",
            {
              staticClass: "get-backedup-branches",
              on: { close: _vm.closeBackupBranches },
            },
            [
              _c("div", { staticClass: "backedUp-branches" }, [
                _c("div", { staticClass: "popup__top" }, [
                  _c("div", { staticClass: "popup__head" }, [
                    _c("span", {
                      staticClass: "h1 mt20",
                      staticStyle: {
                        "max-width": "55%",
                        display: "flex",
                        "justify-content": "center",
                      },
                      domProps: { textContent: _vm._s(_vm.$t("Backup")) },
                    }),
                    _c(
                      "div",
                      { staticClass: "form-site" },
                      [
                        _c(
                          "table-list",
                          _vm._b(
                            {
                              on: {
                                "on-click-line":
                                  _vm.openPopupForEditBackupBranch,
                              },
                            },
                            "table-list",
                            _vm.backupedBranchesTableData,
                            false
                          )
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm.popup2 == 1 && _vm.branchInEdit
        ? _c("edit-backup-branch", {
            attrs: { branch: _vm.branchInEdit },
            on: { close: _vm.closeEditBranch },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }