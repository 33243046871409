var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      staticClass: "base-dialog-output",
      attrs: { width: _vm.width },
      on: { close: _vm.close },
    },
    [
      _c(
        "div",
        { staticClass: "popup__content", staticStyle: { padding: "unset" } },
        [
          [
            _c("div", { staticClass: "ouput-view pt20 pb50" }, [
              _vm.callTime
                ? _c("div", { staticClass: "json-view" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.CommandStatusResponse) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
            ]),
          ],
        ],
        2
      ),
      _c("div", { staticClass: "button-refresh d-flex" }, [
        _c("button", {
          staticClass: "btn",
          domProps: { textContent: _vm._s(_vm.$t("Refresh")) },
          on: { click: _vm.refreshOutput },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }