var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "new-item-info catalog-editor-edit-item-general" },
    [
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.save.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "form-site" }, [
            _c("div", { staticClass: "form-site__content d-md-pb70" }, [
              _c(
                "div",
                { staticClass: "form-site__group mie8 mis-4 mb12" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "lang_grey_contain d-md-none",
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        width: "100%",
                        "align-items": "baseline",
                      },
                    },
                    [
                      _c("div", { staticClass: "lang lang_grey" }, [
                        _c("button", {
                          staticClass: "lang__item",
                          class: { active: _vm.english },
                          domProps: { textContent: _vm._s(_vm.$t("English")) },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.english = true
                            },
                          },
                        }),
                        _c("button", {
                          staticClass: "lang__item",
                          class: { active: !_vm.english },
                          domProps: { textContent: _vm._s(_vm.$t("Hebrew")) },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.english = false
                            },
                          },
                        }),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "end",
                          },
                        },
                        [
                          _c("div", { staticClass: "form-site__lang" }, [
                            _c(
                              "div",
                              { staticClass: "switch-wrap flex a-center" },
                              [
                                _c("div", {
                                  staticClass: "switch-wrap__label mie12",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Active")),
                                  },
                                }),
                                _c("swich-button", {
                                  model: {
                                    value: _vm.itemCopy.active,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.itemCopy, "active", $$v)
                                    },
                                    expression: "itemCopy.active",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "top_header" },
                    [
                      _vm.english
                        ? _c("form-site-field-text", {
                            ref: "name",
                            attrs: { title: "Item name" },
                            model: {
                              value: _vm.itemCopy.name_en,
                              callback: function ($$v) {
                                _vm.$set(_vm.itemCopy, "name_en", $$v)
                              },
                              expression: "itemCopy.name_en",
                            },
                          })
                        : _vm._e(),
                      !_vm.english
                        ? _c("form-site-field-text", {
                            ref: "name",
                            attrs: { title: "Item name" },
                            model: {
                              value: _vm.itemCopy.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.itemCopy, "name", $$v)
                              },
                              expression: "itemCopy.name",
                            },
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "lang lang_grey md-none",
                          staticStyle: {
                            "max-height": "50px",
                            "margin-inline-end": "13px",
                          },
                        },
                        [
                          _c("button", {
                            staticClass: "lang__item",
                            class: { active: _vm.english },
                            domProps: {
                              textContent: _vm._s(_vm.$t("English")),
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.english = true
                              },
                            },
                          }),
                          _c("button", {
                            staticClass: "lang__item",
                            class: { active: !_vm.english },
                            domProps: { textContent: _vm._s(_vm.$t("Hebrew")) },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.english = false
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.english
                    ? _c("form-site-field-text", {
                        attrs: { title: "Menu name" },
                        model: {
                          value: _vm.itemCopy.name_menu_en,
                          callback: function ($$v) {
                            _vm.$set(_vm.itemCopy, "name_menu_en", $$v)
                          },
                          expression: "itemCopy.name_menu_en",
                        },
                      })
                    : _c("form-site-field-text", {
                        attrs: { title: "Menu name" },
                        model: {
                          value: _vm.itemCopy.name_menu,
                          callback: function ($$v) {
                            _vm.$set(_vm.itemCopy, "name_menu", $$v)
                          },
                          expression: "itemCopy.name_menu",
                        },
                      }),
                  _c("form-site-field-text", {
                    attrs: { title: "Invoice name" },
                    model: {
                      value: _vm.itemCopy.name_invoice,
                      callback: function ($$v) {
                        _vm.$set(_vm.itemCopy, "name_invoice", $$v)
                      },
                      expression: "itemCopy.name_invoice",
                    },
                  }),
                  _vm.english
                    ? _c("form-site-field-text", {
                        attrs: { title: "name on bon" },
                        model: {
                          value: _vm.itemCopy.name_bon_en,
                          callback: function ($$v) {
                            _vm.$set(_vm.itemCopy, "name_bon_en", $$v)
                          },
                          expression: "itemCopy.name_bon_en",
                        },
                      })
                    : _c("form-site-field-text", {
                        attrs: { title: "name on bon" },
                        model: {
                          value: _vm.itemCopy.name_bon,
                          callback: function ($$v) {
                            _vm.$set(_vm.itemCopy, "name_bon", $$v)
                          },
                          expression: "itemCopy.name_bon",
                        },
                      }),
                  _c("form-site-field-text", {
                    attrs: { title: "Stiker name" },
                    model: {
                      value: _vm.itemCopy.name_sticker,
                      callback: function ($$v) {
                        _vm.$set(_vm.itemCopy, "name_sticker", $$v)
                      },
                      expression: "itemCopy.name_sticker",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "form-site__field d-md-none" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn-setting",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.$emit("openSetting")
                      },
                    },
                  },
                  [
                    _c("div", {
                      domProps: { textContent: _vm._s(_vm.$t("Settings")) },
                    }),
                    _c("svg-icon", {
                      attrs: {
                        name: "ic-arrow-down",
                        width: "14",
                        height: "14",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "form-site__group" },
                [
                  _c("form-site-field-select-box", {
                    attrs: {
                      title: "Categories",
                      unsort: "",
                      width: "100%",
                      widthHeader: "100%",
                      placeholder: _vm.$t("Without association"),
                      showSearch: "",
                      options: _vm.categoriesOpt,
                      disabled: !_vm.checkPermissionPath(
                        "catalog_editor.full_access"
                      ),
                    },
                    model: {
                      value: _vm.itemCopy.categories,
                      callback: function ($$v) {
                        _vm.$set(_vm.itemCopy, "categories", $$v)
                      },
                      expression: "itemCopy.categories",
                    },
                  }),
                  _c("form-site-field-select-box", {
                    attrs: {
                      title: "Type",
                      width: "100%",
                      widthHeader: "100%",
                      unsort: "",
                      disable: "",
                      options: _vm.typeOptions,
                      disabled: !_vm.checkPermissionPath(
                        "catalog_editor.full_access"
                      ),
                    },
                    on: { input: _vm.changeType },
                    model: {
                      value: _vm.itemCopy.item_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.itemCopy, "item_type", $$v)
                      },
                      expression: "itemCopy.item_type",
                    },
                  }),
                  _c("form-site-field-text", {
                    attrs: { title: "SKU" },
                    on: {
                      input: function ($event) {
                        _vm.changeMakat = true
                      },
                    },
                    model: {
                      value: _vm.itemCopy.makat,
                      callback: function ($$v) {
                        _vm.$set(_vm.itemCopy, "makat", $$v)
                      },
                      expression: "itemCopy.makat",
                    },
                  }),
                  _c("form-site-field-text", {
                    attrs: { title: "External SKU" },
                    on: {
                      input: function ($event) {
                        _vm.changeMakat = true
                      },
                    },
                    model: {
                      value: _vm.itemCopy.external_makat,
                      callback: function ($$v) {
                        _vm.$set(_vm.itemCopy, "external_makat", $$v)
                      },
                      expression: "itemCopy.external_makat",
                    },
                  }),
                  _vm.english
                    ? _c("form-site-field-text", {
                        attrs: { title: "“Ribbon” text" },
                        model: {
                          value: _vm.itemCopy.ribbon_text_en,
                          callback: function ($$v) {
                            _vm.$set(_vm.itemCopy, "ribbon_text_en", $$v)
                          },
                          expression: "itemCopy.ribbon_text_en",
                        },
                      })
                    : _c("form-site-field-text", {
                        attrs: { title: "“Ribbon” text" },
                        model: {
                          value: _vm.itemCopy.ribbon_text,
                          callback: function ($$v) {
                            _vm.$set(_vm.itemCopy, "ribbon_text", $$v)
                          },
                          expression: "itemCopy.ribbon_text",
                        },
                      }),
                ],
                1
              ),
              _c("div", { staticClass: "form-site__group" }, [
                _c("div", { staticClass: "form-site__field" }, [
                  _c("div", {
                    staticClass: "form-site__label",
                    domProps: { textContent: _vm._s(_vm.$t("description")) },
                  }),
                  _c("div", { staticClass: "form-site__input" }, [
                    _vm.english
                      ? _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.itemCopy.description_en,
                              expression: "itemCopy.description_en",
                            },
                          ],
                          attrs: {
                            placeholder: _vm.$t("Write your description"),
                          },
                          domProps: { value: _vm.itemCopy.description_en },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.itemCopy,
                                "description_en",
                                $event.target.value
                              )
                            },
                          },
                        })
                      : _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.itemCopy.description,
                              expression: "itemCopy.description",
                            },
                          ],
                          attrs: {
                            placeholder: _vm.$t("Write your description"),
                          },
                          domProps: { value: _vm.itemCopy.description },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.itemCopy,
                                "description",
                                $event.target.value
                              )
                            },
                          },
                        }),
                  ]),
                ]),
                _c("div", { staticClass: "form-site__field" }, [
                  _c("div", {
                    staticClass: "form-site__label",
                    domProps: {
                      textContent: _vm._s(_vm.$t("catalog-editor Notes")),
                    },
                  }),
                  _c("div", { staticClass: "form-site__input" }, [
                    _vm.english
                      ? _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.itemCopy.notes_en,
                              expression: "itemCopy.notes_en",
                            },
                          ],
                          attrs: { placeholder: _vm.$t("Write your notes...") },
                          domProps: { value: _vm.itemCopy.notes_en },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.itemCopy,
                                "notes_en",
                                $event.target.value
                              )
                            },
                          },
                        })
                      : _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.itemCopy.notes,
                              expression: "itemCopy.notes",
                            },
                          ],
                          attrs: { placeholder: _vm.$t("Write your notes...") },
                          domProps: { value: _vm.itemCopy.notes },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.itemCopy,
                                "notes",
                                $event.target.value
                              )
                            },
                          },
                        }),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-site__group" }, [
                _c("div", { staticClass: "form-site__field" }, [
                  _c("div", {
                    staticClass: "form-site__label",
                    domProps: { textContent: _vm._s(_vm.$t("Cook notes")) },
                  }),
                  _c("div", { staticClass: "form-site__input" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.itemCopy.cook_notes,
                          expression: "itemCopy.cook_notes",
                        },
                      ],
                      attrs: {
                        placeholder: _vm.$t("Write notes for the cook..."),
                      },
                      domProps: { value: _vm.itemCopy.cook_notes },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.itemCopy,
                            "cook_notes",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }