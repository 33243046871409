var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: { title: "Preview", width: "900px" },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("div", { staticClass: "popup__main" }, [
        _c("div", { staticClass: "popup__main_chose" }, [
          _c("div", { staticClass: "input-radio" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.desktop,
                  expression: "desktop",
                },
              ],
              attrs: { id: "radioDesktop", type: "radio", name: "radio" },
              domProps: { value: true, checked: _vm._q(_vm.desktop, true) },
              on: {
                change: function ($event) {
                  _vm.desktop = true
                },
              },
            }),
            _c(
              "label",
              {
                staticClass: "input-radio__label",
                class: { active: _vm.desktop },
                attrs: { for: "radioDesktop" },
              },
              [
                _c("span", { staticClass: "input-radio__mark" }),
                _c("span", {
                  staticClass: "input-radio__text",
                  domProps: { textContent: _vm._s(_vm.$t("PC")) },
                }),
              ]
            ),
          ]),
          _c("div", { staticClass: "input-radio" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.desktop,
                  expression: "desktop",
                },
              ],
              attrs: { id: "radioMobile", type: "radio", name: "radio" },
              domProps: { value: false, checked: _vm._q(_vm.desktop, false) },
              on: {
                change: function ($event) {
                  _vm.desktop = false
                },
              },
            }),
            _c(
              "label",
              {
                staticClass: "input-radio__label",
                class: { active: !_vm.desktop },
                attrs: { for: "radioMobile" },
              },
              [
                _c("span", { staticClass: "input-radio__mark" }),
                _c("span", {
                  staticClass: "input-radio__text",
                  domProps: { textContent: _vm._s(_vm.$t("Mobile")) },
                }),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "popup__main_desktop",
            class: { mobile: !_vm.desktop },
          },
          [_c("preview-desktop", { attrs: { survey: _vm.survey } })],
          1
        ),
      ]),
      _c("div", { staticClass: "pb20 flex j-center" }, [
        _c("button", {
          staticClass: "btn",
          domProps: { textContent: _vm._s(_vm.$t("Close-compensation")) },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }