var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      staticClass: "new-position",
      attrs: { width: "460px" },
      on: {
        close: function ($event) {
          return _vm.$emit("save")
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "popup__content pb40",
          staticStyle: { height: "600px" },
        },
        [
          _c("div", { staticClass: "form-new-user" }, [
            _c("div", { staticClass: "form-site" }, [
              _c("div", { staticClass: "form-site__field" }, [
                _c(
                  "div",
                  { staticClass: "form-site__select-employee mb20" },
                  [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.$t("Employees")) },
                    }),
                    _c(
                      "select-box",
                      _vm._b(
                        {
                          attrs: {
                            showSearch: "",
                            name: "",
                            options: _vm.employeesList,
                          },
                          model: {
                            value: _vm.employee_id,
                            callback: function ($$v) {
                              _vm.employee_id = $$v
                            },
                            expression: "employee_id",
                          },
                        },
                        "select-box",
                        _vm.$attrs,
                        false
                      )
                    ),
                  ],
                  1
                ),
                _vm.allBranches
                  ? _c(
                      "div",
                      { staticClass: "form-site__select-position mb20" },
                      [
                        _c("span", {
                          domProps: { textContent: _vm._s(_vm.$t("Branch")) },
                        }),
                        _c("select-box", {
                          attrs: {
                            showSearch: "",
                            options: _vm.branchesOpt,
                            required: "",
                            translate: false,
                            "first-top": "",
                          },
                          model: {
                            value: _vm.branch_id,
                            callback: function ($$v) {
                              _vm.branch_id = $$v
                            },
                            expression: "branch_id",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "form-site__select-position" },
                  [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.$t("Position")) },
                    }),
                    _c(
                      "select-box",
                      _vm._b(
                        {
                          attrs: {
                            name: "",
                            options: _vm.positions,
                            required: "",
                          },
                          model: {
                            value: _vm.position,
                            callback: function ($$v) {
                              _vm.position = $$v
                            },
                            expression: "position",
                          },
                        },
                        "select-box",
                        _vm.$attrs,
                        false
                      )
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "switch-wrap inline-flex a-center" },
                [
                  _c("div", {
                    staticClass: "switch-wrap__label mie12",
                    domProps: { textContent: _vm._s(_vm.$t("Start shift")) },
                  }),
                  _c("swich-button", {
                    attrs: { value: true },
                    on: { change: _vm.onlyStartChanged },
                    model: {
                      value: _vm.onlyStart,
                      callback: function ($$v) {
                        _vm.onlyStart = $$v
                      },
                      expression: "onlyStart",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "filter-date-container" },
                [
                  _c("form-site-field-date", {
                    ref: "f1",
                    staticClass: "start1",
                    attrs: { id: "f1", title: _vm.$t("From") + _vm.$t("Date") },
                    model: {
                      value: _vm.dateRange.start,
                      callback: function ($$v) {
                        _vm.$set(_vm.dateRange, "start", $$v)
                      },
                      expression: "dateRange.start",
                    },
                  }),
                  _c("select-box", {
                    ref: "timepicker1",
                    staticClass: "timepicker1",
                    attrs: {
                      width: "100px",
                      autocomplete: "",
                      options: _vm.listHours,
                      placeholder: "00:00",
                    },
                    model: {
                      value: _vm.dateRange.startHour,
                      callback: function ($$v) {
                        _vm.$set(_vm.dateRange, "startHour", $$v)
                      },
                      expression: "dateRange.startHour",
                    },
                  }),
                ],
                1
              ),
              !_vm.onlyStart
                ? _c(
                    "div",
                    { staticClass: "filter-date-container" },
                    [
                      _c("form-site-field-date", {
                        ref: "f2",
                        staticClass: "end1",
                        attrs: {
                          id: "f2",
                          title: _vm.$t("to") + " " + _vm.$t("Date"),
                        },
                        model: {
                          value: _vm.dateRange.end,
                          callback: function ($$v) {
                            _vm.$set(_vm.dateRange, "end", $$v)
                          },
                          expression: "dateRange.end",
                        },
                      }),
                      _c("select-box", {
                        ref: "timepicker2",
                        staticClass: "timepicker2",
                        attrs: {
                          width: "100px",
                          autocomplete: "",
                          options: _vm.listHours,
                          placeholder: "00:00",
                        },
                        model: {
                          value: _vm.dateRange.endHour,
                          callback: function ($$v) {
                            _vm.$set(_vm.dateRange, "endHour", $$v)
                          },
                          expression: "dateRange.endHour",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "save_record" }, [
                _c("button", { staticClass: "btn", on: { click: _vm.save } }, [
                  _c("span", {
                    domProps: { textContent: _vm._s(_vm.$t("Save")) },
                  }),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }