var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      staticClass: "all-payments-cake-dialog",
      staticStyle: { "max-width": "700px", "min-height": "700px" },
      on: { close: _vm.close },
    },
    [
      _vm.paymentsCake && _vm.paymentsCake.length > 0
        ? _c("div", [
            _vm.payments
              ? _c(
                  "div",
                  [
                    _c("cake-chart", {
                      attrs: {
                        dashboard: true,
                        isPayments: _vm.payments,
                        summaryBranches: _vm.paymentsCake,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.payments
              ? _c(
                  "div",
                  { staticClass: "all_data" },
                  _vm._l(_vm.paymentsCake, function (data) {
                    return _c("div", { staticClass: "data_line" }, [
                      _c("span", {
                        staticStyle: { "font-weight": "500" },
                        domProps: {
                          textContent: _vm._s(_vm.getNamePartCke(data) + ":"),
                        },
                      }),
                      !_vm.restaurantCurrencyInfo
                        ? _c("span", {
                            domProps: {
                              textContent: _vm._s("₪" + data.amount.toFixed(1)),
                            },
                          })
                        : _vm._e(),
                      _vm.restaurantCurrencyInfo
                        ? _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.restaurantCurrencySymbol +
                                  data.amount.toFixed(1)
                              ),
                            },
                          })
                        : _vm._e(),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _c("chart"),
      (_vm.paymentsCake && _vm.paymentsCake.length === 0) ||
      _vm.paymentsCake === undefined
        ? _c("div", {
            staticClass: "no-data-text",
            staticStyle: {
              height: "200px",
              display: "flex",
              "justify-content": "center",
              "align-items": "center",
            },
            domProps: { textContent: _vm._s(_vm.$t("No data available")) },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }