var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("base-dialog", { on: { close: _vm.close } }, [
        _c("div", { staticClass: "popup__top mb16" }, [
          _c(
            "div",
            { staticClass: "popup__head" },
            [
              _vm.apiInEdit.id && _vm.apiInEdit.meta_key != "credit"
                ? _c("h2", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(_vm.capitalize(_vm.apiInEdit.meta_key))
                      ),
                    },
                  })
                : _vm._e(),
              _vm.apiInEdit.id && _vm.apiInEdit.meta_key == "credit"
                ? _c("h2", {
                    domProps: {
                      textContent: _vm._s(_vm.$t("switch_credit_card")),
                    },
                  })
                : _vm._e(),
              !_vm.apiInEdit.id
                ? _c("select-box", {
                    staticClass: "new_integration",
                    attrs: {
                      widthHeader: "100%",
                      maxWidth: "100%",
                      options: _vm.metaKeyOptions,
                      autocomplete: true,
                    },
                    model: {
                      value: _vm.apiInEdit.meta_key,
                      callback: function ($$v) {
                        _vm.$set(_vm.apiInEdit, "meta_key", $$v)
                      },
                      expression: "apiInEdit.meta_key",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "popup__content" }, [
          _c("div", { staticClass: "form-new-address" }, [
            _c(
              "form",
              {
                staticClass: "form-site-wrap",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.save.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "form-site" }, [
                  _c(
                    "div",
                    { staticClass: "form-site__content" },
                    [
                      _vm.apiInEdit.meta_key == "credit"
                        ? _c("form-site-field-text", {
                            attrs: {
                              helf: "",
                              title: _vm.$t("user"),
                              placeholder: "ltgroup",
                            },
                            model: {
                              value: _vm.apiInEdit.meta_value.user,
                              callback: function ($$v) {
                                _vm.$set(_vm.apiInEdit.meta_value, "user", $$v)
                              },
                              expression: "apiInEdit.meta_value.user",
                            },
                          })
                        : _vm._e(),
                      _vm.apiInEdit.meta_key == "credit"
                        ? _c("form-site-field-text", {
                            attrs: {
                              helf: "",
                              title: _vm.$t("password"),
                              placeholder: "zIE1@tmL",
                            },
                            model: {
                              value: _vm.apiInEdit.meta_value.password,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.apiInEdit.meta_value,
                                  "password",
                                  $$v
                                )
                              },
                              expression: "apiInEdit.meta_value.password",
                            },
                          })
                        : _vm._e(),
                      _vm.apiInEdit.meta_key == "clickeat"
                        ? _c(
                            "div",
                            {
                              staticClass: "form-site__field",
                              staticStyle: {
                                width: "100%",
                                display: "flex",
                                "flex-direction": "column",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "form-site__label-wrap" },
                                [
                                  _c("div", {
                                    staticClass: "form-site__label",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("insert_orders_key")
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _c("div", {
                                staticClass: "select__head",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.apiInEdit.meta_value.insert_orders_key
                                  ),
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm.apiInEdit.meta_key == "haat_delivery"
                        ? _c(
                            "div",
                            {
                              staticClass: "form-site__field",
                              staticStyle: {
                                width: "100%",
                                display: "flex",
                                "flex-direction": "column",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "form-site__label-wrap" },
                                [
                                  _c("div", {
                                    staticClass: "form-site__label",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("insert_orders_key")
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _c("div", {
                                staticClass: "select__head",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.apiInEdit.meta_value.insert_orders_key
                                  ),
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm.apiInEdit.meta_key == "goodi"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "form-site__field form-site__field_helf",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "form-site__label-wrap" },
                                [
                                  _c("div", {
                                    staticClass: "form-site__label",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("insert_orders_key")
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _c("div", {
                                staticClass: "select__head",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.apiInEdit.meta_value.insert_orders_key
                                  ),
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._l(_vm.metaValueKeys, function (key) {
                        return _vm.apiInEdit.meta_key == "credit" &&
                          key == "terminal_id"
                          ? _c("form-site-field-text", {
                              key: key,
                              attrs: {
                                helf: "",
                                type: _vm.test[key],
                                title: _vm.$t(key),
                              },
                              model: {
                                value: _vm.apiInEdit.meta_value[key],
                                callback: function ($$v) {
                                  _vm.$set(_vm.apiInEdit.meta_value, key, $$v)
                                },
                                expression: "apiInEdit.meta_value[key]",
                              },
                            })
                          : _vm._e()
                      }),
                      _vm._l(_vm.metaValueKeys, function (key) {
                        return _vm.apiInEdit.meta_key == "credit" &&
                          key == "secondary_terminal_id"
                          ? _c("form-site-field-text", {
                              key: key,
                              attrs: {
                                helf: "",
                                type: _vm.test[key],
                                title: _vm.$t(key),
                              },
                              model: {
                                value: _vm.apiInEdit.meta_value[key],
                                callback: function ($$v) {
                                  _vm.$set(_vm.apiInEdit.meta_value, key, $$v)
                                },
                                expression: "apiInEdit.meta_value[key]",
                              },
                            })
                          : _vm._e()
                      }),
                      _vm._l(_vm.metaValueKeys, function (key) {
                        return _vm.notExcludedFromTextField(
                          key,
                          _vm.apiInEdit.meta_key
                        )
                          ? _c("form-site-field-text", {
                              key: key,
                              attrs: {
                                helf: "",
                                type: _vm.test[key],
                                title: _vm.$t(key),
                              },
                              model: {
                                value: _vm.apiInEdit.meta_value[key],
                                callback: function ($$v) {
                                  _vm.$set(_vm.apiInEdit.meta_value, key, $$v)
                                },
                                expression: "apiInEdit.meta_value[key]",
                              },
                            })
                          : _vm._e()
                      }),
                      _vm.apiInEdit.meta_key === "wolt" &&
                      _vm.apiInEdit.meta_value.fetch_details &&
                      _vm.apiInEdit.meta_value.fetch_details.length > 0
                        ? _c("div", [
                            _c("span", {
                              staticClass: "form-site__label",
                              domProps: {
                                textContent: _vm._s(_vm.$t("fetch_details")),
                              },
                            }),
                            _c(
                              "div",
                              { staticStyle: { "margin-top": "5px" } },
                              _vm._l(
                                _vm.apiInEdit.meta_value.fetch_details,
                                function (detail, value) {
                                  return _c("form-site-field-text", {
                                    key: value,
                                    staticStyle: { width: "auto" },
                                    attrs: { helf: "" },
                                    domProps: {
                                      textContent: _vm._s(detail.sub_company),
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ])
                        : _vm._e(),
                      _c("div", [
                        _vm.apiInEdit.meta_key == "nayax"
                          ? _c("div", { staticClass: "input" }, [
                              _c("span", {
                                staticClass: "form-site__label",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("POS")),
                                },
                              }),
                              _c("br"),
                              _c(
                                "div",
                                {
                                  staticClass: "switch mie8",
                                  attrs: { "data-v-0138fa6f": "" },
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "switch__content" },
                                    [
                                      _c(
                                        "input",
                                        _vm._b(
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.apiInEdit.meta_value.pos,
                                                expression:
                                                  "apiInEdit.meta_value.pos",
                                              },
                                            ],
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.apiInEdit.meta_value.pos
                                              )
                                                ? _vm._i(
                                                    _vm.apiInEdit.meta_value
                                                      .pos,
                                                    null
                                                  ) > -1
                                                : _vm.apiInEdit.meta_value.pos,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.apiInEdit.meta_value
                                                      .pos,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.apiInEdit
                                                          .meta_value,
                                                        "pos",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.apiInEdit
                                                          .meta_value,
                                                        "pos",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.apiInEdit.meta_value,
                                                    "pos",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          },
                                          "input",
                                          _vm.fetchSelectBoxBind,
                                          false
                                        )
                                      ),
                                      _c("span", {
                                        staticClass: "switch__background",
                                      }),
                                      _c("span", {
                                        staticClass: "switch__marker",
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _c("br"),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", [
                        _vm.apiInEdit.meta_key == "nayax"
                          ? _c("div", { staticClass: "input" }, [
                              _c("span", {
                                staticClass: "form-site__label",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Website")),
                                },
                              }),
                              _c("br"),
                              _c(
                                "div",
                                {
                                  staticClass: "switch mie8",
                                  attrs: { "data-v-0138fa6f": "" },
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "switch__content" },
                                    [
                                      _c(
                                        "input",
                                        _vm._b(
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.apiInEdit.meta_value
                                                    .website,
                                                expression:
                                                  "apiInEdit.meta_value.website",
                                              },
                                            ],
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.apiInEdit.meta_value.website
                                              )
                                                ? _vm._i(
                                                    _vm.apiInEdit.meta_value
                                                      .website,
                                                    null
                                                  ) > -1
                                                : _vm.apiInEdit.meta_value
                                                    .website,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.apiInEdit.meta_value
                                                      .website,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.apiInEdit
                                                          .meta_value,
                                                        "website",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.apiInEdit
                                                          .meta_value,
                                                        "website",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.apiInEdit.meta_value,
                                                    "website",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          },
                                          "input",
                                          _vm.fetchSelectBoxBind,
                                          false
                                        )
                                      ),
                                      _c("span", {
                                        staticClass: "switch__background",
                                      }),
                                      _c("span", {
                                        staticClass: "switch__marker",
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _c("br"),
                            ])
                          : _vm._e(),
                        _vm.apiInEdit.meta_key == "credit"
                          ? _c("div", { staticClass: "input" }, [
                              _c("span", {
                                staticClass: "form-site__label",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Website")),
                                },
                              }),
                              _c("br"),
                              _c(
                                "div",
                                {
                                  staticClass: "switch mie8",
                                  attrs: { "data-v-0138fa6f": "" },
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "switch__content" },
                                    [
                                      _c(
                                        "input",
                                        _vm._b(
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.apiInEdit.meta_value
                                                    .website,
                                                expression:
                                                  "apiInEdit.meta_value.website",
                                              },
                                            ],
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.apiInEdit.meta_value.website
                                              )
                                                ? _vm._i(
                                                    _vm.apiInEdit.meta_value
                                                      .website,
                                                    null
                                                  ) > -1
                                                : _vm.apiInEdit.meta_value
                                                    .website,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.apiInEdit.meta_value
                                                      .website,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.apiInEdit
                                                          .meta_value,
                                                        "website",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.apiInEdit
                                                          .meta_value,
                                                        "website",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.apiInEdit.meta_value,
                                                    "website",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          },
                                          "input",
                                          _vm.fetchSelectBoxBind,
                                          false
                                        )
                                      ),
                                      _c("span", {
                                        staticClass: "switch__background",
                                      }),
                                      _c("span", {
                                        staticClass: "switch__marker",
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _c("br"),
                            ])
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            width: "100%",
                            "justify-content": "center",
                            "margin-top": "10px",
                            "flex-wrap": "wrap",
                            "flex-direction": "row",
                          },
                        },
                        [
                          _vm.apiInEdit.meta_key == "credit"
                            ? _c("form-site-field-text", {
                                attrs: {
                                  helf: "",
                                  width: "100%",
                                  title: _vm.$t("mid"),
                                },
                                model: {
                                  value: _vm.apiInEdit.meta_value.website_mid,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.apiInEdit.meta_value,
                                      "website_mid",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "apiInEdit.meta_value.website_mid",
                                },
                              })
                            : _vm._e(),
                          _vm.apiInEdit.meta_key == "credit"
                            ? _c("form-site-field-text", {
                                attrs: {
                                  helf: "",
                                  title: _vm.$t("website_terminal_id"),
                                },
                                model: {
                                  value:
                                    _vm.apiInEdit.meta_value
                                      .website_terminal_id,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.apiInEdit.meta_value,
                                      "website_terminal_id",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "apiInEdit.meta_value.website_terminal_id",
                                },
                              })
                            : _vm._e(),
                          _vm.apiInEdit.meta_key == "credit"
                            ? _c("form-site-field-text", {
                                attrs: {
                                  helf: "",
                                  title: _vm.$t("website_user"),
                                },
                                model: {
                                  value: _vm.apiInEdit.meta_value.website_user,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.apiInEdit.meta_value,
                                      "website_user",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "apiInEdit.meta_value.website_user",
                                },
                              })
                            : _vm._e(),
                          _vm.apiInEdit.meta_key == "credit"
                            ? _c("form-site-field-text", {
                                attrs: {
                                  helf: "",
                                  title: _vm.$t("website_password"),
                                },
                                model: {
                                  value:
                                    _vm.apiInEdit.meta_value.website_password,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.apiInEdit.meta_value,
                                      "website_password",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "apiInEdit.meta_value.website_password",
                                },
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            { staticStyle: { width: "100%", display: "flex" } },
                            [
                              _vm.apiInEdit.meta_key == "credit"
                                ? _c("form-site-field-text", {
                                    attrs: {
                                      helf: "",
                                      title: _vm.$t(
                                        "website_safe_transaction_threshold"
                                      ),
                                    },
                                    model: {
                                      value:
                                        _vm.apiInEdit.meta_value
                                          .website_safe_transaction_threshold,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.apiInEdit.meta_value,
                                          "website_safe_transaction_threshold",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "\n                        apiInEdit.meta_value.website_safe_transaction_threshold\n                      ",
                                    },
                                  })
                                : _vm._e(),
                              _vm.apiInEdit.meta_key == "credit"
                                ? _c("div", { staticClass: "input" }, [
                                    _c("span", {
                                      staticClass: "form-site__label",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t(
                                            "website_has_safe_transactions"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("br"),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "switch mie8",
                                        attrs: { "data-v-0138fa6f": "" },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "switch__content" },
                                          [
                                            _c(
                                              "input",
                                              _vm._b(
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.apiInEdit.meta_value
                                                          .website_has_safe_transactions,
                                                      expression:
                                                        "\n                              apiInEdit.meta_value.website_has_safe_transactions\n                            ",
                                                    },
                                                  ],
                                                  attrs: { type: "checkbox" },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.apiInEdit.meta_value
                                                        .website_has_safe_transactions
                                                    )
                                                      ? _vm._i(
                                                          _vm.apiInEdit
                                                            .meta_value
                                                            .website_has_safe_transactions,
                                                          null
                                                        ) > -1
                                                      : _vm.apiInEdit.meta_value
                                                          .website_has_safe_transactions,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.apiInEdit
                                                            .meta_value
                                                            .website_has_safe_transactions,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.apiInEdit
                                                                .meta_value,
                                                              "website_has_safe_transactions",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.apiInEdit
                                                                .meta_value,
                                                              "website_has_safe_transactions",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "website_has_safe_transactions",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                },
                                                "input",
                                                _vm.fetchSelectBoxBind,
                                                false
                                              )
                                            ),
                                            _c("span", {
                                              staticClass: "switch__background",
                                            }),
                                            _c("span", {
                                              staticClass: "switch__marker",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("br"),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                          ["credit", "stripe"].includes(_vm.apiInEdit.meta_key)
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "form-site__field form-site__field_helf",
                                },
                                [
                                  _c("div", { staticClass: "input" }, [
                                    _c("span", {
                                      staticClass: "form-site__label",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Apple pay")
                                        ),
                                      },
                                    }),
                                    _c("br"),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "switch mie8",
                                        attrs: { "data-v-0138fa6f": "" },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "switch__content" },
                                          [
                                            _c(
                                              "input",
                                              _vm._b(
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.apiInEdit.meta_value
                                                          .apple_pay,
                                                      expression:
                                                        "apiInEdit.meta_value.apple_pay",
                                                    },
                                                  ],
                                                  attrs: { type: "checkbox" },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.apiInEdit.meta_value
                                                        .apple_pay
                                                    )
                                                      ? _vm._i(
                                                          _vm.apiInEdit
                                                            .meta_value
                                                            .apple_pay,
                                                          null
                                                        ) > -1
                                                      : _vm.apiInEdit.meta_value
                                                          .apple_pay,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.apiInEdit
                                                            .meta_value
                                                            .apple_pay,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.apiInEdit
                                                                .meta_value,
                                                              "apple_pay",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.apiInEdit
                                                                .meta_value,
                                                              "apple_pay",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "apple_pay",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                },
                                                "input",
                                                _vm.fetchSelectBoxBind,
                                                false
                                              )
                                            ),
                                            _c("span", {
                                              staticClass: "switch__background",
                                            }),
                                            _c("span", {
                                              staticClass: "switch__marker",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("br"),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          ["credit", "stripe"].includes(_vm.apiInEdit.meta_key)
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "form-site__field form-site__field_helf",
                                },
                                [
                                  _c("div", { staticClass: "input" }, [
                                    _c("span", {
                                      staticClass: "form-site__label",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Google pay")
                                        ),
                                      },
                                    }),
                                    _c("br"),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "switch mie8",
                                        attrs: { "data-v-0138fa6f": "" },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "switch__content" },
                                          [
                                            _c(
                                              "input",
                                              _vm._b(
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.apiInEdit.meta_value
                                                          .google_pay,
                                                      expression:
                                                        "apiInEdit.meta_value.google_pay",
                                                    },
                                                  ],
                                                  attrs: { type: "checkbox" },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.apiInEdit.meta_value
                                                        .google_pay
                                                    )
                                                      ? _vm._i(
                                                          _vm.apiInEdit
                                                            .meta_value
                                                            .google_pay,
                                                          null
                                                        ) > -1
                                                      : _vm.apiInEdit.meta_value
                                                          .google_pay,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.apiInEdit
                                                            .meta_value
                                                            .google_pay,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.apiInEdit
                                                                .meta_value,
                                                              "google_pay",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.apiInEdit
                                                                .meta_value,
                                                              "google_pay",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "google_pay",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                },
                                                "input",
                                                _vm.fetchSelectBoxBind,
                                                false
                                              )
                                            ),
                                            _c("span", {
                                              staticClass: "switch__background",
                                            }),
                                            _c("span", {
                                              staticClass: "switch__marker",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("br"),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "form-site__field form-site__field_helf",
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "flex", gap: "10px" } },
                            [
                              _vm.apiInEdit.meta_key == "dragontail"
                                ? _c("div", { staticClass: "input" }, [
                                    _c("span", {
                                      staticClass: "form-site__label",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("send_only_paid")
                                        ),
                                      },
                                    }),
                                    _c("br"),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "switch mie8",
                                        attrs: { "data-v-0138fa6f": "" },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "switch__content" },
                                          [
                                            _c(
                                              "input",
                                              _vm._b(
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.apiInEdit.meta_value
                                                          .send_only_paid,
                                                      expression:
                                                        "apiInEdit.meta_value.send_only_paid",
                                                    },
                                                  ],
                                                  attrs: { type: "checkbox" },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.apiInEdit.meta_value
                                                        .send_only_paid
                                                    )
                                                      ? _vm._i(
                                                          _vm.apiInEdit
                                                            .meta_value
                                                            .send_only_paid,
                                                          null
                                                        ) > -1
                                                      : _vm.apiInEdit.meta_value
                                                          .send_only_paid,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.apiInEdit
                                                            .meta_value
                                                            .send_only_paid,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.apiInEdit
                                                                .meta_value,
                                                              "send_only_paid",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.apiInEdit
                                                                .meta_value,
                                                              "send_only_paid",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "send_only_paid",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                },
                                                "input",
                                                _vm.fetchSelectBoxBind,
                                                false
                                              )
                                            ),
                                            _c("span", {
                                              staticClass: "switch__background",
                                            }),
                                            _c("span", {
                                              staticClass: "switch__marker",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("br"),
                                  ])
                                : _vm._e(),
                              _vm.apiInEdit.meta_key == "dragontail"
                                ? _c("div", { staticClass: "input" }, [
                                    _c("span", {
                                      staticClass: "form-site__label",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("new_insert")
                                        ),
                                      },
                                    }),
                                    _c("br"),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "switch mie8",
                                        attrs: { "data-v-0138fa6f": "" },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "switch__content" },
                                          [
                                            _c(
                                              "input",
                                              _vm._b(
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.apiInEdit.meta_value
                                                          .new_insert,
                                                      expression:
                                                        "apiInEdit.meta_value.new_insert",
                                                    },
                                                  ],
                                                  attrs: { type: "checkbox" },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.apiInEdit.meta_value
                                                        .new_insert
                                                    )
                                                      ? _vm._i(
                                                          _vm.apiInEdit
                                                            .meta_value
                                                            .new_insert,
                                                          null
                                                        ) > -1
                                                      : _vm.apiInEdit.meta_value
                                                          .new_insert,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.apiInEdit
                                                            .meta_value
                                                            .new_insert,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.apiInEdit
                                                                .meta_value,
                                                              "new_insert",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.apiInEdit
                                                                .meta_value,
                                                              "new_insert",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "new_insert",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                },
                                                "input",
                                                _vm.fetchSelectBoxBind,
                                                false
                                              )
                                            ),
                                            _c("span", {
                                              staticClass: "switch__background",
                                            }),
                                            _c("span", {
                                              staticClass: "switch__marker",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("br"),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm.apiInEdit.meta_key == "better_chains_id" ||
                          _vm.apiInEdit.meta_key == "wolt_drive" ||
                          _vm.apiInEdit.meta_key == "erp"
                            ? _c("div", { staticClass: "input" }, [
                                _c("span", {
                                  staticClass: "form-site__label",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("test_mode")),
                                  },
                                }),
                                _c("br"),
                                _c(
                                  "div",
                                  {
                                    staticClass: "switch mie8",
                                    attrs: { "data-v-0138fa6f": "" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "switch__content" },
                                      [
                                        _c(
                                          "input",
                                          _vm._b(
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.apiInEdit.meta_value
                                                      .test_mode,
                                                  expression:
                                                    "apiInEdit.meta_value.test_mode",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.apiInEdit.meta_value
                                                    .test_mode
                                                )
                                                  ? _vm._i(
                                                      _vm.apiInEdit.meta_value
                                                        .test_mode,
                                                      null
                                                    ) > -1
                                                  : _vm.apiInEdit.meta_value
                                                      .test_mode,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.apiInEdit.meta_value
                                                        .test_mode,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "test_mode",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "test_mode",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.apiInEdit.meta_value,
                                                      "test_mode",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            },
                                            "input",
                                            _vm.fetchSelectBoxBind,
                                            false
                                          )
                                        ),
                                        _c("span", {
                                          staticClass: "switch__background",
                                        }),
                                        _c("span", {
                                          staticClass: "switch__marker",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("br"),
                              ])
                            : _vm._e(),
                          _vm.apiInEdit.meta_key == "sendi"
                            ? _c("div", { staticClass: "input" }, [
                                _c("span", {
                                  staticClass: "form-site__label",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Test")),
                                  },
                                }),
                                _c("br"),
                                _c(
                                  "div",
                                  {
                                    staticClass: "switch mie8",
                                    attrs: { "data-v-0138fa6f": "" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "switch__content" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.apiInEdit.meta_value.test,
                                              expression:
                                                "apiInEdit.meta_value.test",
                                            },
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.apiInEdit.meta_value.test
                                            )
                                              ? _vm._i(
                                                  _vm.apiInEdit.meta_value.test,
                                                  null
                                                ) > -1
                                              : _vm.apiInEdit.meta_value.test,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.apiInEdit.meta_value.test,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.apiInEdit.meta_value,
                                                      "test",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.apiInEdit.meta_value,
                                                      "test",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.apiInEdit.meta_value,
                                                  "test",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                        _c("span", {
                                          staticClass: "switch__background",
                                        }),
                                        _c("span", {
                                          staticClass: "switch__marker",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("br"),
                              ])
                            : _vm._e(),
                          _vm.apiInEdit.meta_key == "relay"
                            ? _c("div", { staticClass: "input" }, [
                                _c("span", {
                                  staticClass: "form-site__label",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Test")),
                                  },
                                }),
                                _c("br"),
                                _c(
                                  "div",
                                  {
                                    staticClass: "switch mie8",
                                    attrs: { "data-v-0138fa6f": "" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "switch__content" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.apiInEdit.meta_value.test,
                                              expression:
                                                "apiInEdit.meta_value.test",
                                            },
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.apiInEdit.meta_value.test
                                            )
                                              ? _vm._i(
                                                  _vm.apiInEdit.meta_value.test,
                                                  null
                                                ) > -1
                                              : _vm.apiInEdit.meta_value.test,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.apiInEdit.meta_value.test,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.apiInEdit.meta_value,
                                                      "test",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.apiInEdit.meta_value,
                                                      "test",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.apiInEdit.meta_value,
                                                  "test",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                        _c("span", {
                                          staticClass: "switch__background",
                                        }),
                                        _c("span", {
                                          staticClass: "switch__marker",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("br"),
                              ])
                            : _vm._e(),
                          _vm.apiInEdit.meta_key == "pizzahut_coupons"
                            ? _c("div", { staticClass: "input" }, [
                                _c("span", {
                                  staticClass: "form-site__label",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("amazing_refund")
                                    ),
                                  },
                                }),
                                _c("br"),
                                _c(
                                  "div",
                                  {
                                    staticClass: "switch mie8",
                                    attrs: { "data-v-0138fa6f": "" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "switch__content" },
                                      [
                                        _c(
                                          "input",
                                          _vm._b(
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.apiInEdit.meta_value
                                                      .amazing_refund,
                                                  expression:
                                                    "apiInEdit.meta_value.amazing_refund",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.apiInEdit.meta_value
                                                    .amazing_refund
                                                )
                                                  ? _vm._i(
                                                      _vm.apiInEdit.meta_value
                                                        .amazing_refund,
                                                      null
                                                    ) > -1
                                                  : _vm.apiInEdit.meta_value
                                                      .amazing_refund,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.apiInEdit.meta_value
                                                        .amazing_refund,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "amazing_refund",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "amazing_refund",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.apiInEdit.meta_value,
                                                      "amazing_refund",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            },
                                            "input",
                                            _vm.fetchSelectBoxBind,
                                            false
                                          )
                                        ),
                                        _c("span", {
                                          staticClass: "switch__background",
                                        }),
                                        _c("span", {
                                          staticClass: "switch__marker",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("br"),
                              ])
                            : _vm._e(),
                          _vm.apiInEdit.meta_key == "cibus"
                            ? _c("div", { staticClass: "input" }, [
                                _c("span", {
                                  staticClass: "form-site__label",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("fetch_includes_deals")
                                    ),
                                  },
                                }),
                                _c("br"),
                                _c(
                                  "div",
                                  {
                                    staticClass: "switch mie8",
                                    attrs: { "data-v-0138fa6f": "" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "switch__content" },
                                      [
                                        _c(
                                          "input",
                                          _vm._b(
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.apiInEdit.meta_value
                                                      .fetch_includes_deals,
                                                  expression:
                                                    "apiInEdit.meta_value.fetch_includes_deals",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.apiInEdit.meta_value
                                                    .fetch_includes_deals
                                                )
                                                  ? _vm._i(
                                                      _vm.apiInEdit.meta_value
                                                        .fetch_includes_deals,
                                                      null
                                                    ) > -1
                                                  : _vm.apiInEdit.meta_value
                                                      .fetch_includes_deals,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.apiInEdit.meta_value
                                                        .fetch_includes_deals,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "fetch_includes_deals",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "fetch_includes_deals",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.apiInEdit.meta_value,
                                                      "fetch_includes_deals",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            },
                                            "input",
                                            _vm.fetchSelectBoxBind,
                                            false
                                          )
                                        ),
                                        _c("span", {
                                          staticClass: "switch__background",
                                        }),
                                        _c("span", {
                                          staticClass: "switch__marker",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("br"),
                              ])
                            : _vm._e(),
                          _vm.apiInEdit.meta_key == "tenbis"
                            ? _c("div", { staticClass: "input" }, [
                                _c("span", {
                                  staticClass: "form-site__label",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("fetch_includes_deals")
                                    ),
                                  },
                                }),
                                _c("br"),
                                _c(
                                  "div",
                                  {
                                    staticClass: "switch mie8",
                                    attrs: { "data-v-0138fa6f": "" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "switch__content" },
                                      [
                                        _c(
                                          "input",
                                          _vm._b(
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.apiInEdit.meta_value
                                                      .fetch_includes_deals,
                                                  expression:
                                                    "apiInEdit.meta_value.fetch_includes_deals",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.apiInEdit.meta_value
                                                    .fetch_includes_deals
                                                )
                                                  ? _vm._i(
                                                      _vm.apiInEdit.meta_value
                                                        .fetch_includes_deals,
                                                      null
                                                    ) > -1
                                                  : _vm.apiInEdit.meta_value
                                                      .fetch_includes_deals,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.apiInEdit.meta_value
                                                        .fetch_includes_deals,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "fetch_includes_deals",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "fetch_includes_deals",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.apiInEdit.meta_value,
                                                      "fetch_includes_deals",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            },
                                            "input",
                                            _vm.fetchSelectBoxBind,
                                            false
                                          )
                                        ),
                                        _c("span", {
                                          staticClass: "switch__background",
                                        }),
                                        _c("span", {
                                          staticClass: "switch__marker",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("br"),
                              ])
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-around",
                                width: "100%",
                              },
                            },
                            [
                              _vm.apiInEdit.meta_key == "goodi"
                                ? _c("div", { staticClass: "input" }, [
                                    _c("span", {
                                      staticClass: "form-site__label",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("insert_orders")
                                        ),
                                      },
                                    }),
                                    _c("br"),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "switch mie8",
                                        attrs: { "data-v-0138fa6f": "" },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "switch__content" },
                                          [
                                            _c(
                                              "input",
                                              _vm._b(
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.apiInEdit.meta_value
                                                          .insert_orders,
                                                      expression:
                                                        "apiInEdit.meta_value.insert_orders",
                                                    },
                                                  ],
                                                  attrs: { type: "checkbox" },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.apiInEdit.meta_value
                                                        .insert_orders
                                                    )
                                                      ? _vm._i(
                                                          _vm.apiInEdit
                                                            .meta_value
                                                            .insert_orders,
                                                          null
                                                        ) > -1
                                                      : _vm.apiInEdit.meta_value
                                                          .insert_orders,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.apiInEdit
                                                            .meta_value
                                                            .insert_orders,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.apiInEdit
                                                                .meta_value,
                                                              "insert_orders",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.apiInEdit
                                                                .meta_value,
                                                              "insert_orders",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "insert_orders",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                },
                                                "input",
                                                _vm.fetchSelectBoxBind,
                                                false
                                              )
                                            ),
                                            _c("span", {
                                              staticClass: "switch__background",
                                            }),
                                            _c("span", {
                                              staticClass: "switch__marker",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("br"),
                                  ])
                                : _vm._e(),
                              _vm.apiInEdit.meta_key == "goodi"
                                ? _c("div", { staticClass: "input" }, [
                                    _c("span", {
                                      staticClass: "form-site__label",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("production")
                                        ),
                                      },
                                    }),
                                    _c("br"),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "switch mie8",
                                        attrs: { "data-v-0138fa6f": "" },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "switch__content" },
                                          [
                                            _c(
                                              "input",
                                              _vm._b(
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.apiInEdit.meta_value
                                                          .production,
                                                      expression:
                                                        "apiInEdit.meta_value.production",
                                                    },
                                                  ],
                                                  attrs: { type: "checkbox" },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.apiInEdit.meta_value
                                                        .production
                                                    )
                                                      ? _vm._i(
                                                          _vm.apiInEdit
                                                            .meta_value
                                                            .production,
                                                          null
                                                        ) > -1
                                                      : _vm.apiInEdit.meta_value
                                                          .production,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.apiInEdit
                                                            .meta_value
                                                            .production,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.apiInEdit
                                                                .meta_value,
                                                              "production",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.apiInEdit
                                                                .meta_value,
                                                              "production",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "production",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                },
                                                "input",
                                                _vm.fetchSelectBoxBind,
                                                false
                                              )
                                            ),
                                            _c("span", {
                                              staticClass: "switch__background",
                                            }),
                                            _c("span", {
                                              staticClass: "switch__marker",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("br"),
                                  ])
                                : _vm._e(),
                              _vm.apiInEdit.meta_key == "goodi"
                                ? _c("div", { staticClass: "input" }, [
                                    _c("span", {
                                      staticClass: "form-site__label",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("accept_payments")
                                        ),
                                      },
                                    }),
                                    _c("br"),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "switch mie8",
                                        attrs: { "data-v-0138fa6f": "" },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "switch__content" },
                                          [
                                            _c(
                                              "input",
                                              _vm._b(
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.apiInEdit.meta_value
                                                          .accept_payments,
                                                      expression:
                                                        "apiInEdit.meta_value.accept_payments",
                                                    },
                                                  ],
                                                  attrs: { type: "checkbox" },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.apiInEdit.meta_value
                                                        .accept_payments
                                                    )
                                                      ? _vm._i(
                                                          _vm.apiInEdit
                                                            .meta_value
                                                            .accept_payments,
                                                          null
                                                        ) > -1
                                                      : _vm.apiInEdit.meta_value
                                                          .accept_payments,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.apiInEdit
                                                            .meta_value
                                                            .accept_payments,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.apiInEdit
                                                                .meta_value,
                                                              "accept_payments",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.apiInEdit
                                                                .meta_value,
                                                              "accept_payments",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "accept_payments",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                },
                                                "input",
                                                _vm.fetchSelectBoxBind,
                                                false
                                              )
                                            ),
                                            _c("span", {
                                              staticClass: "switch__background",
                                            }),
                                            _c("span", {
                                              staticClass: "switch__marker",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("br"),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm.apiInEdit.meta_key == "cibus" ||
                          _vm.apiInEdit.meta_key == "tenbis"
                            ? _c("div", { staticClass: "input" }, [
                                _c("span", {
                                  staticClass: "form-site__label",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("fetch")),
                                  },
                                }),
                                _c("br"),
                                _c(
                                  "div",
                                  {
                                    staticClass: "switch mie8",
                                    attrs: { "data-v-0138fa6f": "" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "switch__content" },
                                      [
                                        _c(
                                          "input",
                                          _vm._b(
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.apiInEdit.meta_value
                                                      .fetch,
                                                  expression:
                                                    "apiInEdit.meta_value.fetch",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.apiInEdit.meta_value.fetch
                                                )
                                                  ? _vm._i(
                                                      _vm.apiInEdit.meta_value
                                                        .fetch,
                                                      null
                                                    ) > -1
                                                  : _vm.apiInEdit.meta_value
                                                      .fetch,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.apiInEdit.meta_value
                                                        .fetch,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "fetch",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "fetch",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.apiInEdit.meta_value,
                                                      "fetch",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            },
                                            "input",
                                            _vm.fetchSelectBoxBind,
                                            false
                                          )
                                        ),
                                        _c("span", {
                                          staticClass: "switch__background",
                                        }),
                                        _c("span", {
                                          staticClass: "switch__marker",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("br"),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "form-site__field form-site__field_helf",
                        },
                        [
                          _vm.apiInEdit.meta_key == "cibus" ||
                          _vm.apiInEdit.meta_key == "zester"
                            ? _c("div", { staticClass: "input" }, [
                                _c("span", {
                                  staticClass: "form-site__label",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("prod")),
                                  },
                                }),
                                _c("br"),
                                _c(
                                  "div",
                                  {
                                    staticClass: "switch mie8",
                                    attrs: { "data-v-0138fa6f": "" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "switch__content" },
                                      [
                                        _c(
                                          "input",
                                          _vm._b(
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.apiInEdit.meta_value
                                                      .prod,
                                                  expression:
                                                    "apiInEdit.meta_value.prod",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.apiInEdit.meta_value.prod
                                                )
                                                  ? _vm._i(
                                                      _vm.apiInEdit.meta_value
                                                        .prod,
                                                      null
                                                    ) > -1
                                                  : _vm.apiInEdit.meta_value
                                                      .prod,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.apiInEdit.meta_value
                                                        .prod,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "prod",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "prod",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.apiInEdit.meta_value,
                                                      "prod",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            },
                                            "input",
                                            _vm.prodSelectBoxBind,
                                            false
                                          )
                                        ),
                                        _c("span", {
                                          staticClass: "switch__background",
                                        }),
                                        _c("span", {
                                          staticClass: "switch__marker",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("br"),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _vm.apiInEdit.meta_key === "cibus"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "form-site__field form-site__field_helf cibus",
                            },
                            [
                              _c("div", { staticClass: "input" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        _vm.exportMenuPopup = true
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(_vm.$t("Export menu")) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.apiInEdit.meta_key === "deliverect"
                        ? _c("div", { staticClass: "deliverect" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-site__field form-site__field_helf",
                              },
                              [
                                _c("span", {
                                  staticClass: "form-site__label",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("Menu to export")
                                    ),
                                  },
                                }),
                                _c("select-box", {
                                  attrs: { options: _vm.menuOptions },
                                  model: {
                                    value:
                                      _vm.apiInEdit.meta_value.sync_menu_id,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.apiInEdit.meta_value,
                                        "sync_menu_id",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "apiInEdit.meta_value.sync_menu_id",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", {
                              staticClass:
                                "form-site__field form-site__field_helf",
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-site__field form-site__field_full",
                              },
                              [
                                _c("span", {
                                  staticClass: "form-site__label",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Payments map")),
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "deliverect-payment-map" },
                                  [
                                    _vm._l(
                                      _vm.apiInEdit.meta_value.payments_map,
                                      function (value, key) {
                                        return _c(
                                          "div",
                                          {
                                            key: key,
                                            staticClass:
                                              "deliverect-payment-map__item",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "form-site__field",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-site__label",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "deliverect payment"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("select-box", {
                                                  attrs: {
                                                    options:
                                                      _vm.deliverectUnusedPaymentTypesOptions(
                                                        value.type
                                                      ),
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.apiInEdit.meta_value
                                                        .payments_map[key].type,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.apiInEdit.meta_value
                                                          .payments_map[key],
                                                        "type",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "apiInEdit.meta_value.payments_map[key].type",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "form-site__field",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-site__label",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "Subtype payment"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("select-box", {
                                                  attrs: {
                                                    options:
                                                      _vm.deliverectPaymentSubtypesOptions,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.apiInEdit.meta_value
                                                        .payments_map[key]
                                                        .subtype,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.apiInEdit.meta_value
                                                          .payments_map[key],
                                                        "subtype",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "apiInEdit.meta_value.payments_map[key].subtype",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-sm deliverect-payment-map__remove-btn",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeDeliverectPayment(
                                                      key
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                          " +
                                                    _vm._s(_vm.$t("Delete")) +
                                                    "\n                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    _vm.deliverectUnusedPaymentTypesOptions(
                                      null
                                    )
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "deliverect-payment-map-footer",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-sm deliverect-payment-map__add-btn",
                                                attrs: {
                                                  disabled:
                                                    !_vm.canAddDeliverectPayment,
                                                  type: "button",
                                                },
                                                on: {
                                                  click:
                                                    _vm.addDeliverectPayment,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                          " +
                                                    _vm._s(
                                                      _vm.$t("Add payment")
                                                    ) +
                                                    "\n                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-site__field form-site__field_helf",
                              },
                              [
                                _c("span", {
                                  staticClass: "form-site__label",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("New export version")
                                    ),
                                  },
                                }),
                                _c("br"),
                                _c(
                                  "div",
                                  {
                                    staticClass: "switch mie8",
                                    attrs: { "data-v-0138fa6f": "" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "switch__content" },
                                      [
                                        _c(
                                          "input",
                                          _vm._b(
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.apiInEdit.meta_value
                                                      .new_insert,
                                                  expression:
                                                    "apiInEdit.meta_value.new_insert",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.apiInEdit.meta_value
                                                    .new_insert
                                                )
                                                  ? _vm._i(
                                                      _vm.apiInEdit.meta_value
                                                        .new_insert,
                                                      null
                                                    ) > -1
                                                  : _vm.apiInEdit.meta_value
                                                      .new_insert,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.apiInEdit.meta_value
                                                        .new_insert,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "new_insert",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "new_insert",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.apiInEdit.meta_value,
                                                      "new_insert",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            },
                                            "input",
                                            _vm.fetchSelectBoxBind,
                                            false
                                          )
                                        ),
                                        _c("span", {
                                          staticClass: "switch__background",
                                        }),
                                        _c("span", {
                                          staticClass: "switch__marker",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("br"),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-site__field form-site__field_helf",
                              },
                              [
                                _c("span", {
                                  staticClass: "form-site__label",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Ignore phone")),
                                  },
                                }),
                                _c("br"),
                                _c(
                                  "div",
                                  {
                                    staticClass: "switch mie8",
                                    attrs: { "data-v-0138fa6f": "" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "switch__content" },
                                      [
                                        _c(
                                          "input",
                                          _vm._b(
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.apiInEdit.meta_value
                                                      .ignore_phone,
                                                  expression:
                                                    "apiInEdit.meta_value.ignore_phone",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.apiInEdit.meta_value
                                                    .ignore_phone
                                                )
                                                  ? _vm._i(
                                                      _vm.apiInEdit.meta_value
                                                        .ignore_phone,
                                                      null
                                                    ) > -1
                                                  : _vm.apiInEdit.meta_value
                                                      .ignore_phone,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.apiInEdit.meta_value
                                                        .ignore_phone,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "ignore_phone",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "ignore_phone",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.apiInEdit.meta_value,
                                                      "ignore_phone",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            },
                                            "input",
                                            _vm.fetchSelectBoxBind,
                                            false
                                          )
                                        ),
                                        _c("span", {
                                          staticClass: "switch__background",
                                        }),
                                        _c("span", {
                                          staticClass: "switch__marker",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("br"),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-site__field form-site__field_helf",
                              },
                              [
                                _c("span", {
                                  staticClass: "form-site__label",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("Products include tax")
                                    ),
                                  },
                                }),
                                _c("br"),
                                _c(
                                  "div",
                                  {
                                    staticClass: "switch mie8",
                                    attrs: { "data-v-0138fa6f": "" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "switch__content" },
                                      [
                                        _c(
                                          "input",
                                          _vm._b(
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.apiInEdit.meta_value
                                                      .products_include_tax,
                                                  expression:
                                                    "apiInEdit.meta_value.products_include_tax",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.apiInEdit.meta_value
                                                    .products_include_tax
                                                )
                                                  ? _vm._i(
                                                      _vm.apiInEdit.meta_value
                                                        .products_include_tax,
                                                      null
                                                    ) > -1
                                                  : _vm.apiInEdit.meta_value
                                                      .products_include_tax,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.apiInEdit.meta_value
                                                        .products_include_tax,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "products_include_tax",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "products_include_tax",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.apiInEdit.meta_value,
                                                      "products_include_tax",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            },
                                            "input",
                                            _vm.fetchSelectBoxBind,
                                            false
                                          )
                                        ),
                                        _c("span", {
                                          staticClass: "switch__background",
                                        }),
                                        _c("span", {
                                          staticClass: "switch__marker",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("br"),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm.apiInEdit.meta_key === "atmos_kds"
                        ? _c("div", { staticClass: "atmos-kds" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-site__field form-site__field_helf",
                              },
                              [
                                _c("span", {
                                  staticClass: "form-site__label",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("send_only_paid")
                                    ),
                                  },
                                }),
                                _c("br"),
                                _c(
                                  "div",
                                  {
                                    staticClass: "switch mie8",
                                    attrs: { "data-v-0138fa6f": "" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "switch__content" },
                                      [
                                        _c(
                                          "input",
                                          _vm._b(
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.apiInEdit.meta_value
                                                      .send_only_paid,
                                                  expression:
                                                    "apiInEdit.meta_value.send_only_paid",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.apiInEdit.meta_value
                                                    .send_only_paid
                                                )
                                                  ? _vm._i(
                                                      _vm.apiInEdit.meta_value
                                                        .send_only_paid,
                                                      null
                                                    ) > -1
                                                  : _vm.apiInEdit.meta_value
                                                      .send_only_paid,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.apiInEdit.meta_value
                                                        .send_only_paid,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "send_only_paid",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "send_only_paid",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.apiInEdit.meta_value,
                                                      "send_only_paid",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            },
                                            "input",
                                            _vm.fetchSelectBoxBind,
                                            false
                                          )
                                        ),
                                        _c("span", {
                                          staticClass: "switch__background",
                                        }),
                                        _c("span", {
                                          staticClass: "switch__marker",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("br"),
                              ]
                            ),
                            _vm.apiInEdit.meta_value.prep_times
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-site__field form-site__field_full",
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "form-site__label",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Preparation times")
                                        ),
                                      },
                                    }),
                                    _vm._l(
                                      _vm.apiInEdit.meta_value.prep_times,
                                      function (prepTime, prepTimeIndex) {
                                        return _c(
                                          "div",
                                          {
                                            key: prepTimeIndex,
                                            staticClass: "prep-time-field",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "form-site__label",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    prepTime.name || prepTime.id
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.apiInEdit.meta_value
                                                      .prep_times[prepTimeIndex]
                                                      .prepTime,
                                                  expression:
                                                    "apiInEdit.meta_value.prep_times[prepTimeIndex].prepTime",
                                                },
                                              ],
                                              staticClass: "input",
                                              attrs: { type: "number" },
                                              domProps: {
                                                value:
                                                  _vm.apiInEdit.meta_value
                                                    .prep_times[prepTimeIndex]
                                                    .prepTime,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.apiInEdit.meta_value
                                                      .prep_times[
                                                      prepTimeIndex
                                                    ],
                                                    "prepTime",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v(_vm._s(_vm.$t("Minutes"))),
                                            ]),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm.apiInEdit.meta_key === "deliveroo"
                        ? _c("div", { staticClass: "atmos-kds" }, [
                            _vm.apiInEdit.id
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-site__field form-site__field_full",
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "form-site__label",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Select menu to export")
                                        ),
                                      },
                                    }),
                                    _c("select-box", {
                                      attrs: {
                                        options: _vm.menuOptions,
                                        "is-disabled":
                                          _vm.deliverooExportLoading,
                                      },
                                      on: { input: _vm.deliverooMenuExport },
                                      model: {
                                        value: _vm.deliverooExportMenuId,
                                        callback: function ($$v) {
                                          _vm.deliverooExportMenuId = $$v
                                        },
                                        expression: "deliverooExportMenuId",
                                      },
                                    }),
                                    _vm.deliverooExportLoading
                                      ? _c("p", [
                                          _vm._v(
                                            _vm._s(_vm.$t("Exporting")) + "..."
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-site__field form-site__field_helf",
                              },
                              [
                                _c("div", { staticClass: "input" }, [
                                  _c("span", {
                                    staticClass: "form-site__label",
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Test")),
                                    },
                                  }),
                                  _c("br"),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "switch mie8",
                                      attrs: { "data-v-0138fa6f": "" },
                                    },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "switch__content" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.apiInEdit.meta_value.test,
                                                expression:
                                                  "apiInEdit.meta_value.test",
                                              },
                                            ],
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.apiInEdit.meta_value.test
                                              )
                                                ? _vm._i(
                                                    _vm.apiInEdit.meta_value
                                                      .test,
                                                    null
                                                  ) > -1
                                                : _vm.apiInEdit.meta_value.test,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.apiInEdit.meta_value
                                                      .test,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.apiInEdit
                                                          .meta_value,
                                                        "test",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.apiInEdit
                                                          .meta_value,
                                                        "test",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.apiInEdit.meta_value,
                                                    "test",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _c("span", {
                                            staticClass: "switch__background",
                                          }),
                                          _c("span", {
                                            staticClass: "switch__marker",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("br"),
                                ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm.apiInEdit.meta_key === "uber_eats"
                        ? _c("div", { staticClass: "uber_eats" }, [
                            _vm.apiInEdit.id
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-site__field form-site__field_full",
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "form-site__label",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Select menu to export")
                                        ),
                                      },
                                    }),
                                    _c("select-box", {
                                      attrs: {
                                        options: _vm.menuOptions,
                                        "is-disabled":
                                          _vm.ubereatsExportLoading,
                                      },
                                      on: { input: _vm.ubereatsMenuExport },
                                      model: {
                                        value: _vm.ubereatsExportMenuId,
                                        callback: function ($$v) {
                                          _vm.ubereatsExportMenuId = $$v
                                        },
                                        expression: "ubereatsExportMenuId",
                                      },
                                    }),
                                    _vm.ubereatsExportLoading
                                      ? _c("p", [
                                          _vm._v(
                                            _vm._s(_vm.$t("Exporting")) + "..."
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-site__field form-site__field_helf",
                              },
                              [
                                _c("div", { staticClass: "input" }, [
                                  _c("span", {
                                    staticClass: "form-site__label",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Tax inclusive")
                                      ),
                                    },
                                  }),
                                  _c("br"),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "switch mie8",
                                      attrs: { "data-v-0138fa6f": "" },
                                    },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "switch__content" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.apiInEdit.meta_value
                                                    .tax_inclusive,
                                                expression:
                                                  "apiInEdit.meta_value.tax_inclusive",
                                              },
                                            ],
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.apiInEdit.meta_value
                                                  .tax_inclusive
                                              )
                                                ? _vm._i(
                                                    _vm.apiInEdit.meta_value
                                                      .tax_inclusive,
                                                    null
                                                  ) > -1
                                                : _vm.apiInEdit.meta_value
                                                    .tax_inclusive,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.apiInEdit.meta_value
                                                      .tax_inclusive,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.apiInEdit
                                                          .meta_value,
                                                        "tax_inclusive",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.apiInEdit
                                                          .meta_value,
                                                        "tax_inclusive",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.apiInEdit.meta_value,
                                                    "tax_inclusive",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _c("span", {
                                            staticClass: "switch__background",
                                          }),
                                          _c("span", {
                                            staticClass: "switch__marker",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("br"),
                                ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm.apiInEdit.meta_key === "phdv"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "form-site__field form-site__field_helf",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "form-site__field" },
                                [
                                  _c("span", {
                                    staticClass: "form-site__label",
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Menu")),
                                    },
                                  }),
                                  _c("select-box", {
                                    attrs: { options: _vm.menuOptions },
                                    model: {
                                      value: _vm.apiInEdit.meta_value.menu_id,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.apiInEdit.meta_value,
                                          "menu_id",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "apiInEdit.meta_value.menu_id",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      [
                        "prepaid",
                        "businesslogic",
                        "tenbis",
                        "cibus",
                        "multipass",
                      ].includes(_vm.apiInEdit.meta_key)
                        ? _c(
                            "div",
                            { staticClass: "deny-points-and-coupons" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "form-site__field form-site__field_helf",
                                },
                                [
                                  _c("div", { staticClass: "input" }, [
                                    _c("span", {
                                      staticClass: "form-site__label",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Deny points and coupons")
                                        ),
                                      },
                                    }),
                                    _c("br"),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "switch mie8",
                                        attrs: { "data-v-0138fa6f": "" },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "switch__content" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.apiInEdit.meta_value
                                                      .denyPointsAndCoupons,
                                                  expression:
                                                    "apiInEdit.meta_value.denyPointsAndCoupons",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.apiInEdit.meta_value
                                                    .denyPointsAndCoupons
                                                )
                                                  ? _vm._i(
                                                      _vm.apiInEdit.meta_value
                                                        .denyPointsAndCoupons,
                                                      null
                                                    ) > -1
                                                  : _vm.apiInEdit.meta_value
                                                      .denyPointsAndCoupons,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.apiInEdit.meta_value
                                                        .denyPointsAndCoupons,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "denyPointsAndCoupons",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.apiInEdit
                                                            .meta_value,
                                                          "denyPointsAndCoupons",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.apiInEdit.meta_value,
                                                      "denyPointsAndCoupons",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                            _c("span", {
                                              staticClass: "switch__background",
                                            }),
                                            _c("span", {
                                              staticClass: "switch__marker",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("br"),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "form-site__btn md-none" },
                  [
                    _vm.apiInEdit.id
                      ? _c("remove-btn", {
                          on: {
                            click: function ($event) {
                              return _vm.$emit("removeApi")
                            },
                          },
                        })
                      : _vm._e(),
                    _c("div"),
                    _c("button", {
                      staticClass: "btn",
                      attrs: { type: "submit" },
                      domProps: { textContent: _vm._s(_vm.$t("Save")) },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "new-item__btn flex d-md-none" }, [
                  _c(
                    "button",
                    { staticClass: "save-btn", attrs: { type: "submit" } },
                    [
                      _c("svg-icon", {
                        staticClass: "ic-edit",
                        attrs: {
                          name: "ic-save-btn",
                          width: "93",
                          height: "93",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "bin-btn",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("removeApi")
                        },
                      },
                    },
                    [
                      _c("svg-icon", {
                        attrs: { name: "ic-bin", width: "18", height: "20" },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _vm.exportMenuPopup
        ? _c(
            "base-dialog",
            {
              on: {
                close: function ($event) {
                  _vm.exportMenuPopup = false
                },
              },
            },
            [
              _c("div", { staticClass: "popup__top mb16" }, [
                _c("div", { staticClass: "popup__head" }, [
                  _c("h2", {
                    domProps: {
                      textContent: _vm._s(_vm.$t("Choose a menu to export")),
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "popup__content" }, [
                _c(
                  "div",
                  { staticClass: "menu-buttons" },
                  _vm._l(_vm.menusList, function (menu) {
                    return _c(
                      "button",
                      {
                        key: menu.id,
                        staticClass: "menu-button",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.exportMenuJson(menu.id)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " + _vm._s(menu.name) + "\n          "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }