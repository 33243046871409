var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "base-dialog",
        {
          staticClass: "existing-user-check",
          attrs: { width: "640px", "overflow-visible": "" },
          on: { close: _vm.close },
        },
        [
          _c("div", { staticClass: "popup__top" }, [
            _c(
              "div",
              { staticClass: "popup__head flex bet" },
              [
                _c("div", { staticClass: "dialog-title mis0" }, [
                  _vm._v(_vm._s(_vm.$t("Create / Edit a user"))),
                ]),
                _c("content-switch", {
                  attrs: {
                    "positive-label": _vm.$t("Phone"),
                    "negative-label": _vm.$t("Email"),
                  },
                  model: {
                    value: _vm.showPhone,
                    callback: function ($$v) {
                      _vm.showPhone = $$v
                    },
                    expression: "showPhone",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "popup__content" }, [
            _c("div", { staticClass: "form-existing-user-check" }, [
              _c(
                "form",
                {
                  staticClass: "form-site-wrap",
                  on: {
                    submit: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.forward.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "form-site" }, [
                    _c("div", { staticClass: "form-site__content" }, [
                      _vm.showPhone
                        ? _c(
                            "div",
                            { staticClass: "form-site__field" },
                            [
                              _c("div", {
                                staticClass: "form-site__label",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Phone")),
                                },
                              }),
                              _c("phone-fields", {
                                attrs: { required: true },
                                on: {
                                  enter: _vm.forward,
                                  "update-full-phone": function ($event) {
                                    _vm.phone = $event
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "form-site__field" }, [
                            _c("div", {
                              staticClass: "form-site__label",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Email")),
                              },
                            }),
                            _c("div", { staticClass: "form-site__input" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.email,
                                    expression: "email",
                                  },
                                ],
                                attrs: { type: "email", required: "" },
                                domProps: { value: _vm.email },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.forward.apply(null, arguments)
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.email = $event.target.value
                                  },
                                },
                              }),
                            ]),
                          ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-site__btn md-none" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline",
                        on: { click: _vm.close },
                      },
                      [
                        _c("svg-icon", {
                          attrs: {
                            name: "ic-arrow-left",
                            width: "18",
                            height: "20",
                          },
                        }),
                        _c("span", {
                          domProps: { textContent: _vm._s(_vm.$t("Cancel")) },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "button",
                      { staticClass: "btn", attrs: { type: "submit" } },
                      [
                        _c("span", {
                          domProps: { textContent: _vm._s(_vm.$t("Continue")) },
                        }),
                        _c("svg-icon", {
                          staticClass: "ic-white",
                          attrs: {
                            name: "ic-arrow-right",
                            width: "16",
                            height: "12",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
                    _c(
                      "button",
                      { staticClass: "save-btn", attrs: { type: "submit" } },
                      [
                        _c("svg-icon", {
                          staticClass: "ic-edit",
                          attrs: {
                            name: "ic-save-btn",
                            width: "93",
                            height: "93",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "button",
                      { staticClass: "bin-btn", on: { click: _vm.close } },
                      [
                        _c("svg-icon", {
                          attrs: {
                            name: "ic-arrow-right",
                            width: "18",
                            height: "20",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }