var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-dialog", { on: { close: _vm.close } }, [
    _c("div", { staticClass: "edit-phons-dialog" }, [
      _c("div", { staticClass: "popup__content" }, [
        _c("div", { staticClass: "form-new-user" }, [
          _c(
            "form",
            {
              staticClass: "form-site-wrap",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "form-site phone-list" },
                [
                  _vm._l(_vm.phones, function (phone, i) {
                    return _c(
                      "div",
                      { key: i, staticClass: "phone" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: phone.data,
                              expression: "phone.data",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: { value: phone.data },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(phone, "data", $event.target.value)
                            },
                          },
                        }),
                        _c("select-box", {
                          attrs: {
                            showSearch: "",
                            maxWidth: "180px",
                            widthHeader: "180px",
                            maxHeight:
                              185 + (_vm.phones.length - i - 1) * 60 + "px",
                            options: _vm.branchesOpt,
                          },
                          model: {
                            value: phone.branch_id,
                            callback: function ($$v) {
                              _vm.$set(phone, "branch_id", $$v)
                            },
                            expression: "phone.branch_id",
                          },
                        }),
                        _c("bin-btn", {
                          on: {
                            click: function ($event) {
                              return _vm.rmPhone(i)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  _c("div", { staticClass: "line-more" }, [
                    _c(
                      "button",
                      {
                        staticClass: "line-more__content",
                        on: { click: _vm.addPhone },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "line-more__icon" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-plus",
                                width: "12",
                                height: "12",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", {
                          staticClass: "line-more__text",
                          domProps: { textContent: _vm._s(_vm.$t("Add")) },
                        }),
                      ]
                    ),
                  ]),
                ],
                2
              ),
              _c("div", { staticClass: "form-site__btn md-none" }, [
                _c("button", {
                  staticClass: "btn btn_border",
                  domProps: { textContent: _vm._s(_vm.$t("Cancel")) },
                  on: { click: _vm.close },
                }),
                _c("button", {
                  staticClass: "btn",
                  domProps: { textContent: _vm._s(_vm.$t("Save")) },
                  on: { click: _vm.save },
                }),
              ]),
              _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
                _c(
                  "button",
                  { staticClass: "save-btn", attrs: { type: "submit" } },
                  [
                    _c("svg-icon", {
                      staticClass: "ic-edit",
                      attrs: { name: "ic-save-btn", width: "93", height: "93" },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }