var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "base-dialog",
        {
          on: {
            close: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "device-details-top-row" },
              [
                _c("div", { staticClass: "device-details-top" }, [
                  _c("div", [
                    _vm._v(_vm._s(_vm.$t("Id")) + " " + _vm._s(_vm.device.id)),
                  ]),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.$t("Device key")) +
                        " " +
                        _vm._s(_vm.device.device_key)
                    ),
                  ]),
                  _vm.local_data && _vm.local_data.version
                    ? _c("div", [
                        _vm._v(
                          _vm._s(_vm.$t("Device version")) +
                            " " +
                            _vm._s(_vm.local_data.version)
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm.local_data
                  ? _c("div", { staticClass: "fleet-info-details" }, [
                      _c("div", [
                        _c("span", { staticClass: "fleet-info-row" }, [
                          _vm._v(_vm._s(_vm.$t("Device name"))),
                        ]),
                        _c("span", [_vm._v(_vm._s(_vm.local_data.mi) + " ")]),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "flex-direction": "row",
                            display: "flex",
                          },
                        },
                        [
                          _c("span", {
                            staticClass: "fleet-info-row",
                            staticStyle: { display: "block" },
                            domProps: {
                              textContent: _vm._s(_vm.$t("Local Ip address")),
                            },
                          }),
                          _c("span", [
                            _vm._v(_vm._s(_vm.local_data.local_ip) + " "),
                          ]),
                        ]
                      ),
                      _c("div", [
                        _c("span", { staticClass: "fleet-info-row" }, [
                          _vm._v(_vm._s(_vm.$t("Status"))),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.online ? _vm.$t("Online") : _vm.$t("Offline")
                            )
                          ),
                        ]),
                      ]),
                      _vm.local_data.netbird
                        ? _c("div", [
                            _c("span", { staticClass: "fleet-info-row" }, [
                              _vm._v(_vm._s(_vm.$t("Netbird ip"))),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.local_data.netbird.ip) + " "),
                            ]),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.actions
                  ? [
                      _c("select-box", {
                        staticClass: "mb20",
                        attrs: {
                          placeholder: _vm.$t("Select command"),
                          options: _vm.deviceCommands,
                          "first-top": "",
                          width: "100%",
                          widthHeader: "100%",
                          "open-to-up": "",
                        },
                        on: { input: _vm.selectCommand },
                        model: {
                          value: _vm.commandPressed,
                          callback: function ($$v) {
                            _vm.commandPressed = $$v
                          },
                          expression: "commandPressed",
                        },
                      }),
                      _vm.configurator_url
                        ? _c(
                            "button",
                            {
                              staticClass: "btn mb20",
                              attrs: { type: "button" },
                              on: { click: _vm.openInterface },
                            },
                            [_vm._v(_vm._s(_vm.$t("Open Interface")))]
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ]
      ),
      _vm.devicesStatusResult
        ? _c(
            "base-dialog",
            {
              on: {
                close: function ($event) {
                  _vm.devicesStatusResult = null
                },
              },
            },
            [
              _c("div", { staticClass: "popup__content" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    attrs: { type: "button" },
                    on: { click: _vm.communicationTest },
                  },
                  [_vm._v(_vm._s(_vm.$t("Retest")))]
                ),
                _vm.hasDevicesStatusForPrinters
                  ? _c("div", { staticClass: "devices-status-table-title" }, [
                      _c("span", {
                        domProps: { textContent: _vm._s(_vm.$t("Printers")) },
                      }),
                    ])
                  : _vm._e(),
                _vm.hasDevicesStatusForPrinters
                  ? _c(
                      "table",
                      { staticClass: "devices-status-table" },
                      [
                        _c("tr", [
                          _c("th", [_vm._v(_vm._s(_vm.$t("Name")))]),
                          _c("th", [_vm._v(_vm._s(_vm.$t("Ip")))]),
                          _c("th", [_vm._v(_vm._s(_vm.$t("Print lines")))]),
                          _c("th", [_vm._v(_vm._s(_vm.$t("Status")))]),
                        ]),
                        _vm._l(
                          _vm.devicesStatusResult.printers,
                          function (printer, printerIndex) {
                            return _c("tr", { key: printerIndex }, [
                              _c("td", {
                                domProps: { textContent: _vm._s(printer.name) },
                              }),
                              _c("td", {
                                domProps: { textContent: _vm._s(printer.ip) },
                              }),
                              _c("td", {
                                domProps: {
                                  textContent: _vm._s(
                                    printer.print_lines.join(", ")
                                  ),
                                },
                              }),
                              _c("td", {
                                class: {
                                  offline: !printer.online,
                                  online: printer.online,
                                },
                                domProps: {
                                  textContent: _vm._s(
                                    printer.online
                                      ? _vm.$t("Online")
                                      : _vm.$t("Offline")
                                  ),
                                },
                              }),
                            ])
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.hasDevicesStatusForEmvs
                  ? _c("div", { staticClass: "devices-status-table-title" }, [
                      _c("span", {
                        domProps: { textContent: _vm._s(_vm.$t("Emvs")) },
                      }),
                    ])
                  : _vm._e(),
                _vm.hasDevicesStatusForEmvs
                  ? _c(
                      "table",
                      { staticClass: "devices-status-table" },
                      [
                        _c("tr", [
                          _c("th", [_vm._v(_vm._s(_vm.$t("Name")))]),
                          _c("th", [_vm._v(_vm._s(_vm.$t("Ip")))]),
                          _c("th", [_vm._v(_vm._s(_vm.$t("Pos number")))]),
                          _c("th", [_vm._v(_vm._s(_vm.$t("Terminal")))]),
                          _c("th", [_vm._v(_vm._s(_vm.$t("Status")))]),
                        ]),
                        _vm._l(
                          _vm.devicesStatusResult.emvs,
                          function (emv, emvIndex) {
                            return _c("tr", { key: emvIndex }, [
                              _c("td", {
                                domProps: { textContent: _vm._s(emv.name) },
                              }),
                              _c("td", {
                                domProps: { textContent: _vm._s(emv.ip) },
                              }),
                              _c("td", {
                                domProps: { textContent: _vm._s(emv.pos) },
                              }),
                              _c("td", {
                                domProps: { textContent: _vm._s(emv.terminal) },
                              }),
                              _c("td", {
                                class: {
                                  offline: !emv.online,
                                  online: emv.online,
                                },
                                domProps: {
                                  textContent: _vm._s(
                                    emv.online
                                      ? _vm.$t("Online")
                                      : _vm.$t("Offline")
                                  ),
                                },
                              }),
                            ])
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.hasDevicesStatusForAdditionalDevices
                  ? _c("div", { staticClass: "devices-status-table-title" }, [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("Additional devices")),
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.hasDevicesStatusForAdditionalDevices
                  ? _c(
                      "table",
                      { staticClass: "devices-status-table" },
                      [
                        _c("tr", [
                          _c("th", [_vm._v(_vm._s(_vm.$t("Name")))]),
                          _c("th", [_vm._v(_vm._s(_vm.$t("Ip")))]),
                          _c("th", [_vm._v(_vm._s(_vm.$t("Pos \\ Kiosk")))]),
                          _c("th", [_vm._v(_vm._s(_vm.$t("Status")))]),
                        ]),
                        _vm._l(
                          _vm.devicesStatusResult.additional_devices,
                          function (device, deviceIndex) {
                            return _c("tr", { key: deviceIndex }, [
                              _c("td", {
                                domProps: { textContent: _vm._s(device.name) },
                              }),
                              _c("td", {
                                domProps: { textContent: _vm._s(device.ip) },
                              }),
                              _c("td", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.displayDeviceType(device.device_type)
                                  ),
                                },
                              }),
                              _c("td", {
                                class: {
                                  offline: !device.online,
                                  online: device.online,
                                },
                                domProps: {
                                  textContent: _vm._s(
                                    device.online
                                      ? _vm.$t("Online")
                                      : _vm.$t("Offline")
                                  ),
                                },
                              }),
                            ])
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }