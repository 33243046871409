var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-member-club-dialog" },
    [
      _c(
        "base-dialog",
        {
          attrs: { width: "614px", title: "Member club" },
          on: { close: _vm.close },
        },
        [
          _c(
            "div",
            { staticClass: "popup__content" },
            [
              _vm.clubInEdit
                ? [
                    _c("div", { staticClass: "form-lang" }, [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "form-site" }, [
                            _c("div", { staticClass: "form-site__content" }, [
                              _c("div", { staticClass: "form-site__group" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-site__content" },
                                  [
                                    _c("div", {
                                      staticClass: "form-site__label",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Club name")
                                        ),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "form-site__input" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.clubInEdit.name,
                                              expression: "clubInEdit.name",
                                            },
                                          ],
                                          attrs: { type: "text" },
                                          domProps: {
                                            value: _vm.clubInEdit.name,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.clubInEdit,
                                                "name",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-site__content" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex bet w100" },
                                      [
                                        _c("div", {
                                          staticClass: "form-site__label",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Name for sms")
                                            ),
                                          },
                                        }),
                                        _c("div", {
                                          staticClass: "form-site__label",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.clubInEdit.sms_name.length +
                                                "/11"
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "form-site__input" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.clubInEdit.sms_name,
                                              expression: "clubInEdit.sms_name",
                                            },
                                          ],
                                          attrs: {
                                            maxlength: "11",
                                            type: "text",
                                          },
                                          domProps: {
                                            value: _vm.clubInEdit.sms_name,
                                          },
                                          on: {
                                            input: [
                                              function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.clubInEdit,
                                                  "sms_name",
                                                  $event.target.value
                                                )
                                              },
                                              _vm.slugifySmsName,
                                            ],
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-site__content" },
                                  [
                                    _c("div", {
                                      staticClass: "form-site__label",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Terms url")
                                        ),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "form-site__input" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.clubInEdit.terms_url,
                                              expression:
                                                "clubInEdit.terms_url",
                                            },
                                          ],
                                          attrs: { type: "text" },
                                          domProps: {
                                            value: _vm.clubInEdit.terms_url,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.clubInEdit,
                                                "terms_url",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-site__content" },
                                  [
                                    _c("div", {
                                      staticClass: "form-site__label",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Firebase sender id")
                                        ),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "form-site__input" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.clubInEdit.firebase
                                                  .sender_id,
                                              expression:
                                                "clubInEdit.firebase.sender_id",
                                            },
                                          ],
                                          attrs: { type: "text" },
                                          domProps: {
                                            value:
                                              _vm.clubInEdit.firebase.sender_id,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.clubInEdit.firebase,
                                                "sender_id",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-site__content" },
                                  [
                                    _c("div", {
                                      staticClass: "form-site__label",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Firebase key")
                                        ),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "form-site__input" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.clubInEdit.firebase
                                                  .server_key,
                                              expression:
                                                "clubInEdit.firebase.server_key",
                                            },
                                          ],
                                          attrs: { type: "text" },
                                          domProps: {
                                            value:
                                              _vm.clubInEdit.firebase
                                                .server_key,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.clubInEdit.firebase,
                                                "server_key",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-site__content" },
                                  [
                                    _c("div", {
                                      staticClass: "form-site__label",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t(
                                            "Accumulation percentage from order"
                                          )
                                        ),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "form-site__input" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.clubInEdit.configs
                                                  .acc_percentage,
                                              expression:
                                                "clubInEdit.configs.acc_percentage",
                                            },
                                          ],
                                          attrs: {
                                            type: "number",
                                            min: "0",
                                            max: "100",
                                          },
                                          domProps: {
                                            value:
                                              _vm.clubInEdit.configs
                                                .acc_percentage,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.clubInEdit.configs,
                                                "acc_percentage",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-site__content" },
                                  [
                                    _c("div", {
                                      staticClass: "form-site__label",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Order max accumulation")
                                        ),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "form-site__input" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.clubInEdit.configs
                                                  .order_max_acc,
                                              expression:
                                                "clubInEdit.configs.order_max_acc",
                                            },
                                          ],
                                          attrs: { type: "number" },
                                          domProps: {
                                            value:
                                              _vm.clubInEdit.configs
                                                .order_max_acc,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.clubInEdit.configs,
                                                "order_max_acc",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-site__content" },
                                  [
                                    _c("div", {
                                      staticClass: "form-site__label",
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Active")),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "form-site__input" },
                                      [
                                        _c("swich-button", {
                                          attrs: {
                                            value: _vm.clubInEdit.active,
                                          },
                                          on: {
                                            input: function ($event) {
                                              _vm.clubInEdit.active = $event
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "renew-skus" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-site__content" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "form-site__label" },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.$t("Renew skus"))),
                                        ]),
                                        _c(
                                          "info-popup",
                                          {
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "title",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("Renew skus")
                                                        )
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              305875124
                                            ),
                                          },
                                          [
                                            _vm._v(
                                              "\r\n                                          " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "This field is used to identify the renewal product for the customer club membership. When an order includes the SKU specified in this field, the system will automatically renew the membership validity for the club member. Using this field enables easy and straightforward management of membership renewals and ensures that the renewal process is carried out accurately and orderly."
                                                  )
                                                ) +
                                                "\r\n                                        "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._l(
                                      _vm.clubInEdit.configs.renewal_sku,
                                      function (sku, skuIndex) {
                                        return _c(
                                          "div",
                                          {
                                            key: skuIndex,
                                            staticClass:
                                              "form-site__input sku-input",
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.clubInEdit.configs
                                                      .renewal_sku[skuIndex],
                                                  expression:
                                                    "clubInEdit.configs.renewal_sku[skuIndex]",
                                                },
                                              ],
                                              attrs: { type: "text" },
                                              domProps: {
                                                value:
                                                  _vm.clubInEdit.configs
                                                    .renewal_sku[skuIndex],
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.clubInEdit.configs
                                                      .renewal_sku,
                                                    skuIndex,
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "edit-info__btn" },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "edit-info__del",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeRenewalSku(
                                                          skuIndex
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      attrs: {
                                                        name: "ic-close",
                                                        width: "10",
                                                        height: "10",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    _c("div", { staticClass: "line-more" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "line-more__content",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addRenewalSku()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "line-more__icon" },
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  name: "ic-plus",
                                                  width: "12",
                                                  height: "12",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("span", {
                                            staticClass: "line-more__text",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t("add sku")
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  2
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "points-reset-fields" },
                                [
                                  _c("div", {
                                    staticClass: "form-site__label",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Points reset")
                                      ),
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "form-site__group" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-site__content" },
                                        [
                                          _c("div", {
                                            staticClass: "form-site__label",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t("Months")
                                              ),
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "form-site__input" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.clubInEdit.configs
                                                        .reset_points.months,
                                                    expression:
                                                      "clubInEdit.configs.reset_points.months",
                                                  },
                                                ],
                                                attrs: { type: "number" },
                                                domProps: {
                                                  value:
                                                    _vm.clubInEdit.configs
                                                      .reset_points.months,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.clubInEdit.configs
                                                        .reset_points,
                                                      "months",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "form-site__content" },
                                        [
                                          _c("div", {
                                            staticClass: "form-site__label",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t("Days before alert")
                                              ),
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "form-site__input" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.clubInEdit.configs
                                                        .reset_points
                                                        .days_before_alert,
                                                    expression:
                                                      "clubInEdit.configs.reset_points.days_before_alert",
                                                  },
                                                ],
                                                attrs: { type: "number" },
                                                domProps: {
                                                  value:
                                                    _vm.clubInEdit.configs
                                                      .reset_points
                                                      .days_before_alert,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.clubInEdit.configs
                                                        .reset_points,
                                                      "days_before_alert",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "form-site__content" },
                                        [
                                          _c("div", {
                                            staticClass: "form-site__label",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t("Alert message")
                                              ),
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "form-site__input" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.clubInEdit.configs
                                                        .reset_points
                                                        .alert_message,
                                                    expression:
                                                      "clubInEdit.configs.reset_points.alert_message",
                                                  },
                                                ],
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value:
                                                    _vm.clubInEdit.configs
                                                      .reset_points
                                                      .alert_message,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.clubInEdit.configs
                                                        .reset_points,
                                                      "alert_message",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "form-site__btn pb40 form-site__btn_sb d-md-flex d-none",
                            },
                            [
                              _c("div"),
                              _c("button", {
                                staticClass: "btn",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("save")),
                                },
                                on: { click: _vm.save },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "new-item__btn d-flex d-md-none" },
                      [
                        _c(
                          "button",
                          { staticClass: "save-btn", on: { click: _vm.save } },
                          [
                            _c("svg-icon", {
                              staticClass: "ic-edit",
                              attrs: {
                                name: "ic-save-btn",
                                width: "93",
                                height: "93",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                : _c("div", [
                    _c("div", {
                      domProps: {
                        textContent: _vm._s(_vm.$t("No club selected")),
                      },
                    }),
                  ]),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }