var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    { attrs: { title: "Kosher settings" }, on: { close: _vm.close } },
    [
      _c("div", { staticClass: "popup__content" }, [
        _c("div", { staticClass: "form-new-user" }, [
          _c(
            "form",
            {
              staticClass: "form-site-wrap",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.save.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "form-site" }, [
                _c("div", { staticClass: "form-site__content" }, [
                  _c("div", { staticClass: "lang" }, [
                    _c("button", {
                      staticClass: "lang__item",
                      class: { active: !_vm.english },
                      domProps: { textContent: _vm._s(_vm.$t("Hebrew")) },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.english = false
                        },
                      },
                    }),
                    _c("button", {
                      staticClass: "lang__item",
                      class: { active: _vm.english },
                      domProps: { textContent: _vm._s(_vm.$t("English")) },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.english = true
                        },
                      },
                    }),
                  ]),
                  _vm.english == true
                    ? _c(
                        "div",
                        { staticClass: "form-site__field-wrap" },
                        [
                          _c("form-site-field-text", {
                            attrs: { title: "Kosher type" },
                            model: {
                              value: _vm.kosherSetting.type_en,
                              callback: function ($$v) {
                                _vm.$set(_vm.kosherSetting, "type_en", $$v)
                              },
                              expression: "kosherSetting.type_en",
                            },
                          }),
                          _c("form-site-field-text", {
                            attrs: { title: "Text" },
                            model: {
                              value: _vm.kosherSetting.text_en,
                              callback: function ($$v) {
                                _vm.$set(_vm.kosherSetting, "text_en", $$v)
                              },
                              expression: "kosherSetting.text_en",
                            },
                          }),
                          _c("form-site-field-date", {
                            attrs: { title: "Expired at" },
                            model: {
                              value: _vm.kosherSetting.expiration,
                              callback: function ($$v) {
                                _vm.$set(_vm.kosherSetting, "expiration", $$v)
                              },
                              expression: "kosherSetting.expiration",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.english == false
                    ? _c(
                        "div",
                        { staticClass: "form-site__field-wrap" },
                        [
                          _c("form-site-field-text", {
                            attrs: { title: "Kosher type" },
                            model: {
                              value: _vm.kosherSetting.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.kosherSetting, "type", $$v)
                              },
                              expression: "kosherSetting.type",
                            },
                          }),
                          _c("form-site-field-text", {
                            attrs: { title: "Text" },
                            model: {
                              value: _vm.kosherSetting.text,
                              callback: function ($$v) {
                                _vm.$set(_vm.kosherSetting, "text", $$v)
                              },
                              expression: "kosherSetting.text",
                            },
                          }),
                          _c("form-site-field-date", {
                            attrs: { title: "Expired at" },
                            model: {
                              value: _vm.kosherSetting.expiration,
                              callback: function ($$v) {
                                _vm.$set(_vm.kosherSetting, "expiration", $$v)
                              },
                              expression: "kosherSetting.expiration",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "form-site__group imgs" }, [
                    _c("div", { staticClass: "form-site__field" }, [
                      _c("div", { staticClass: "form-site__input" }, [
                        _c("div", { staticClass: "coupon-info__img" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.kosherSetting.english) +
                              "\n                    "
                          ),
                          _c("div", {
                            staticClass: "coupon-info__label",
                            domProps: { textContent: _vm._s(_vm.$t("Image")) },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "coupon-info__img",
                              class: {
                                "coupon-info__empty":
                                  !_vm.kosherSetting.image ||
                                  _vm.kosherSetting.image === "delete",
                              },
                              style: "background-image: url(" + _vm.image + ")",
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "coupon-info__img-change" },
                                [
                                  _vm.kosherSetting.image &&
                                  _vm.kosherSetting.image != "delete"
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "edit-img__icon",
                                          on: { click: _vm.clearImage },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "ic-plus",
                                              width: "22",
                                              height: "22",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : [
                                        _c("input", {
                                          attrs: {
                                            type: "file",
                                            accept: "image/*",
                                          },
                                          on: { change: _vm.enterImage },
                                        }),
                                        _c("svg-icon", {
                                          attrs: {
                                            name: "ic-plus",
                                            width: "22",
                                            height: "22",
                                          },
                                        }),
                                      ],
                                ],
                                2
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-site__btn md-none" }, [
                _c("div", {}),
                _c(
                  "button",
                  { staticClass: "btn", attrs: { type: "submit" } },
                  [
                    !_vm.kosherSetting.id
                      ? _c("span", {
                          domProps: { textContent: _vm._s(_vm.$t("Add")) },
                        })
                      : _vm._e(),
                    _vm.kosherSetting.id
                      ? _c("span", {
                          domProps: { textContent: _vm._s(_vm.$t("Save")) },
                        })
                      : _vm._e(),
                  ]
                ),
              ]),
              _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
                _c(
                  "button",
                  { staticClass: "save-btn", attrs: { type: "submit" } },
                  [
                    _c("svg-icon", {
                      staticClass: "ic-edit",
                      attrs: { name: "ic-save-btn", width: "93", height: "93" },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }