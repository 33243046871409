var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "clients" },
    [
      _vm.showEditDialog
        ? _c("client-details-popup", {
            attrs: { client: _vm.clientInEdit },
            on: {
              close: function ($event) {
                _vm.showEditDialog = false
              },
            },
          })
        : _vm._e(),
      _c("clients-filter-dialog", {
        on: { apply: _vm.filterApplied },
        model: {
          value: _vm.isFilterOpen,
          callback: function ($$v) {
            _vm.isFilterOpen = $$v
          },
          expression: "isFilterOpen",
        },
      }),
      _c(
        "table-list",
        _vm._b(
          {
            ref: "clientsTable",
            on: {
              "on-click-line": _vm.selectClient,
              changeOptions: _vm.changeOptions,
            },
            scopedSlots: _vm._u(
              [
                !_vm.mobile
                  ? {
                      key: "filter-icon",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "filter" }, [
                            _c(
                              "div",
                              {
                                staticClass: "filter__btn",
                                on: {
                                  click: function ($event) {
                                    _vm.isFilterOpen = !_vm.isFilterOpen
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    name: "ic-filter",
                                    width: "24",
                                    height: "24",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
                !_vm.mobile
                  ? {
                      key: "export-btn",
                      fn: function () {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "export-btn",
                              on: { click: _vm.onExportToExel },
                            },
                            [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Export")),
                                },
                              }),
                              _c("span", { staticClass: "icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/img/export.svg",
                                    alt: "Export",
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
                !_vm.mobile
                  ? {
                      key: "filter-text",
                      fn: function () {
                        return [
                          _vm.filtersText
                            ? _c("div", {
                                staticClass: "mt12 mb12",
                                domProps: {
                                  textContent: _vm._s(_vm.filtersText),
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
                {
                  key: "filter-list",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "filter-list d-md-none" },
                        [
                          _vm._l(_vm.filters, function (filter, i) {
                            return _c(
                              "div",
                              { key: filter, staticClass: "filter-list-item" },
                              [
                                _c("span", {
                                  staticClass: "mie8",
                                  domProps: { textContent: _vm._s(filter) },
                                }),
                                _c(
                                  "button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.closeFilter(i)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          width: "12",
                                          height: "12",
                                          viewBox: "0 0 12 12",
                                          fill: "none",
                                          xmlns: "http://www.w3.org/2000/svg",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M11.7792 0.219436C11.4866 -0.0731455 11.0173 -0.0731455 10.7248 0.219436L5.9993 4.94491L1.27383 0.219436C0.981252 -0.0731455 0.512017 -0.0731455 0.219436 0.219436C-0.0731454 0.512018 -0.0731454 0.981254 0.219436 1.27384L4.9449 5.99931L0.219436 10.7248C-0.0731454 11.0174 -0.0731454 11.4866 0.219436 11.7792C0.362967 11.9227 0.556181 12 0.743874 12C0.931568 12 1.12478 11.9282 1.26831 11.7792L5.99378 7.05371L10.7192 11.7792C10.8628 11.9227 11.056 12 11.2437 12C11.4369 12 11.6246 11.9282 11.7681 11.7792C12.0607 11.4866 12.0607 11.0174 11.7681 10.7248L7.0537 5.99931L11.7792 1.27384C12.0717 0.981254 12.0717 0.512018 11.7792 0.219436Z",
                                            fill: "#404040",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          _c("div", { staticClass: "space" }, [
                            _vm._v("---------"),
                          ]),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "filter-list__btn-warp d-md-none" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "filter-list_btn filter__btn",
                              on: {
                                click: function ($event) {
                                  _vm.isFilterOpen = !_vm.isFilterOpen
                                },
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-filter",
                                  width: "24",
                                  height: "24",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          },
          "table-list",
          _vm.dataTable,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }