var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.mobile && !_vm.ipad
      ? _c(
          "div",
          { staticClass: "dispatcher-orders" },
          [
            _c("orders-filter-dialog", {
              attrs: {
                dataForFilter: _vm.dataForFilter,
                dispatcherFilters: true,
              },
              on: { apply: _vm.filterApplied },
              model: {
                value: _vm.isFilterOpen,
                callback: function ($$v) {
                  _vm.isFilterOpen = $$v
                },
                expression: "isFilterOpen",
              },
            }),
            _c(
              "table-list",
              _vm._b(
                {
                  ref: "ordersTable",
                  on: { changeOptions: _vm.changeOptions },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "filter-by-date",
                        fn: function () {
                          return undefined
                        },
                        proxy: true,
                      },
                      {
                        key: "filter-text",
                        fn: function () {
                          return [
                            _vm.filtersText
                              ? _c("div", {
                                  staticClass: "mt12 mb12 md-none",
                                  domProps: {
                                    textContent: _vm._s(_vm.filtersText),
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "filter-icon",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "flex col" }, [
                              _c(
                                "div",
                                { staticClass: "flex row m0" },
                                [
                                  _vm.onDeliveryFilter || _vm.onReadyFilter
                                    ? _c("select-box", {
                                        staticClass: "driverSelectBox",
                                        attrs: {
                                          firstTop: "",
                                          showSearch: "",
                                          widthHeader: "160px",
                                          width: "160px",
                                          options: _vm.deliveryGuiesOptions,
                                        },
                                        model: {
                                          value: _vm.filterByDriver,
                                          callback: function ($$v) {
                                            _vm.filterByDriver = $$v
                                          },
                                          expression: "filterByDriver",
                                        },
                                      })
                                    : _vm._e(),
                                  _c("filter-by-date", {
                                    model: {
                                      value: _vm.dateFilter,
                                      callback: function ($$v) {
                                        _vm.dateFilter = $$v
                                      },
                                      expression: "dateFilter",
                                    },
                                  }),
                                  _c("div", { staticClass: "filter" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "filter__btn",
                                        on: {
                                          click: function ($event) {
                                            _vm.isFilterOpen = !_vm.isFilterOpen
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            name: "ic-filter",
                                            width: "24",
                                            height: "24",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "undertitle2",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "btns-filter" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn-filter",
                                  class: { active: _vm.inPendingFilter },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setFilter("inPendingFilter")
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Pending")),
                                    },
                                  }),
                                  _c("span", {
                                    staticClass: "count",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.dispacher_summary.pending
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-filter",
                                  class: { active: _vm.onReadyFilter },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setFilter("onReadyFilter")
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("ready orders")
                                      ),
                                    },
                                  }),
                                  _c("span", {
                                    staticClass: "count",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.dispacher_summary.ready
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-filter",
                                  class: { active: _vm.onDeliveryFilter },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setFilter("onDeliveryFilter")
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("In delivery")
                                      ),
                                    },
                                  }),
                                  _c("span", {
                                    staticClass: "count",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.dispacher_summary.on_delivery
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-filter",
                                  class: { active: _vm.closedFilter },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setFilter("closedFilter")
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Close orders")
                                      ),
                                    },
                                  }),
                                  _c("span", {
                                    staticClass: "count",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.dispacher_summary.closed
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "bottom-table",
                        fn: function () {
                          return [
                            _vm.checked.some(function (k) {
                              return k
                            })
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "bottom-table",
                                    style: { display: "flex" },
                                  },
                                  [
                                    _c("div"),
                                    _vm.isOnDeliveryTab
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "remove-from-pickup",
                                            on: { click: _vm.removeFromPickup },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "img",
                                                style: {
                                                  "background-color": "#fff",
                                                  width: "48px",
                                                  height: "48px",
                                                  border: "4px solid red",
                                                  "box-sizing": "border-box",
                                                  "border-radius": "30px",
                                                  display: "flex",
                                                  "justify-content": "center",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "icon-btn",
                                                    style: {
                                                      width: "25px",
                                                      height: "25px",
                                                    },
                                                    attrs: {
                                                      viewBox: "0 0 48 48",
                                                      fill: "none",
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                    },
                                                  },
                                                  [
                                                    _c("circle", {
                                                      attrs: {
                                                        cx: "24",
                                                        cy: "24",
                                                        r: "24",
                                                        fill: "#FFF2F7",
                                                      },
                                                    }),
                                                    _c("path", {
                                                      staticClass:
                                                        "second-fill",
                                                      attrs: {
                                                        d: "M34.1135 13.884C33.6015 13.372 32.7804 13.372 32.2683 13.884L23.9988 22.1536L15.7292 13.884C15.2172 13.372 14.396 13.372 13.884 13.884C13.372 14.396 13.372 15.2172 13.884 15.7292L22.1536 23.9988L13.884 32.2684C13.372 32.7804 13.372 33.6016 13.884 34.1136C14.1352 34.3647 14.4733 34.5 14.8018 34.5C15.1302 34.5 15.4684 34.3744 15.7195 34.1136L23.9891 25.844L32.2587 34.1136C32.5099 34.3647 32.848 34.5 33.1764 34.5C33.5146 34.5 33.843 34.3744 34.0942 34.1136C34.6062 33.6016 34.6062 32.7804 34.0942 32.2684L25.844 23.9988L34.1135 15.7292C34.6256 15.2172 34.6256 14.396 34.1135 13.884Z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c("span", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    _vm.inPendingFilter
                                                      ? "Remove from pickup"
                                                      : "Remove from pickup"
                                                  )
                                                ),
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "ready-to-pickup",
                                        on: { click: _vm.readyToPickup },
                                      },
                                      [
                                        _c("span", { staticClass: "img" }, [
                                          _vm.inPendingFilter
                                            ? _c("img", {
                                                attrs: {
                                                  src: require("@/assets/img/svg/ready-to-pickup.svg"),
                                                  alt: "",
                                                },
                                              })
                                            : _c(
                                                "svg",
                                                {
                                                  attrs: {
                                                    width: "20",
                                                    height: "20",
                                                    viewBox: "0 0 20 20",
                                                    fill: "none",
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                  },
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      d: "M4.81167 10.6427C4.98561 10.8166 5.21807 10.9091 5.45467 10.9091C5.57178 10.9091 5.68986 10.8864 5.80228 10.8398C6.14197 10.6991 6.36349 10.3676 6.36349 9.99992V6.36363H19.0906C19.5926 6.36363 19.9997 5.9566 19.9997 5.45453C19.9997 4.95247 19.5926 4.54544 19.0906 4.54544H6.36353V0.909143C6.36353 0.541529 6.14209 0.210008 5.80232 0.0692674C5.46264 -0.0715126 5.07155 0.00629919 4.81167 0.266297L0.266277 4.81169C-0.088759 5.16672 -0.088759 5.74226 0.266277 6.0973L4.81167 10.6427Z",
                                                      fill: "#f28f16",
                                                    },
                                                  }),
                                                  _c("path", {
                                                    attrs: {
                                                      d: "M15.1881 9.35716C14.9281 9.09708 14.5371 9.01935 14.1974 9.16005C13.8577 9.30079 13.6362 9.63227 13.6362 9.99993V13.6362H0.909094C0.407028 13.6363 0 14.0433 0 14.5453C0 15.0474 0.407028 15.4544 0.909094 15.4544H13.6362V19.0907C13.6362 19.4584 13.8576 19.7899 14.1974 19.9306C14.3098 19.9772 14.428 19.9998 14.545 19.9998C14.7816 19.9998 15.0141 19.9075 15.188 19.7335L19.7334 15.1881C20.0885 14.8332 20.0885 14.2576 19.7334 13.9025L15.1881 9.35716Z",
                                                      fill: "#f28f16",
                                                    },
                                                  }),
                                                ]
                                              ),
                                        ]),
                                        _c("span", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                _vm.inPendingFilter
                                                  ? "Ready"
                                                  : "Replace messenger"
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "ready-to-pickup2",
                                        style: { "align-items": "center" },
                                        on: { click: _vm.closeOrder },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "img" },
                                          [
                                            _c("svg-icon", {
                                              staticClass: "ic-edit",
                                              attrs: {
                                                name: "ic-save-btn",
                                                width: "112",
                                                height: "112",
                                              },
                                            }),
                                            _c("use", {
                                              attrs: {
                                                "xlink:href":
                                                  "/img/sprite.4e96e1db.svg#ic-save-btn",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("span", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                _vm.inPendingFilter
                                                  ? "Close order"
                                                  : "Close order"
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1730380837
                  ),
                },
                "table-list",
                _vm.dataTable,
                false
              )
            ),
            _vm.setDelivery
              ? _c("choose-driver", {
                  attrs: {
                    sumOfPickupOrders: _vm.sumOfPickupOrders,
                    sumOfDeliveredOrders: _vm.sumOfDeliveredOrders,
                  },
                  on: { input: _vm.setDriver },
                })
              : _vm._e(),
            !!_vm.currentOrder
              ? _c("order-details-popup", {
                  attrs: { order: _vm.currentOrder, show: !!_vm.currentOrder },
                  on: {
                    closed: _vm.closeOrderDetails,
                    statusChanged: _vm.changeOrderStatus,
                  },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm.ipad
      ? _c(
          "div",
          { staticClass: "dispatcher-orders" },
          [
            _c("orders-filter-dialog", {
              attrs: {
                dataForFilter: _vm.dataForFilter,
                dispatcherFilters: true,
              },
              on: { apply: _vm.filterApplied },
              model: {
                value: _vm.isFilterOpen,
                callback: function ($$v) {
                  _vm.isFilterOpen = $$v
                },
                expression: "isFilterOpen",
              },
            }),
            _c(
              "table-list",
              _vm._b(
                {
                  on: { changeOptions: _vm.changeOptions },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "filter-by-date",
                        fn: function () {
                          return undefined
                        },
                        proxy: true,
                      },
                      {
                        key: "filter-text",
                        fn: function () {
                          return [
                            _vm.filtersText
                              ? _c("div", {
                                  staticClass: "mt12 mb12 md-none",
                                  domProps: {
                                    textContent: _vm._s(_vm.filtersText),
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "filter-icon",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "page__content" }, [
                              _c("div", { staticClass: "btns-filter" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-filter",
                                    class: { active: _vm.inPendingFilter },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setFilter("inPendingFilter")
                                      },
                                    },
                                  },
                                  [
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Pending")),
                                      },
                                    }),
                                    _c("span", {
                                      staticClass: "count",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.dispacher_summary.pending
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-filter",
                                    class: { active: _vm.onReadyFilter },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setFilter("onReadyFilter")
                                      },
                                    },
                                  },
                                  [
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("ready orders")
                                        ),
                                      },
                                    }),
                                    _c("span", {
                                      staticClass: "count",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.dispacher_summary.ready
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-filter",
                                    class: { active: _vm.onDeliveryFilter },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setFilter("onDeliveryFilter")
                                      },
                                    },
                                  },
                                  [
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("In delivery")
                                        ),
                                      },
                                    }),
                                    _c("span", {
                                      staticClass: "count",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.dispacher_summary.on_delivery
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-filter",
                                    class: { active: _vm.closedFilter },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setFilter("closedFilter")
                                      },
                                    },
                                  },
                                  [
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Close orders")
                                        ),
                                      },
                                    }),
                                    _c("span", {
                                      staticClass: "count",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.dispacher_summary.closed
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "undertitle2",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "flex col" }, [
                              _c(
                                "div",
                                { staticClass: "flex row m0" },
                                [
                                  _vm.onDeliveryFilter ||
                                  _vm.closedFilter ||
                                  _vm.onReadyFilter
                                    ? _c("select-box", {
                                        staticClass: "driverSelectBox",
                                        attrs: {
                                          firstTop: "",
                                          showSearch: "",
                                          options: _vm.deliveryGuiesOptions,
                                        },
                                        model: {
                                          value: _vm.filterByDriver,
                                          callback: function ($$v) {
                                            _vm.filterByDriver = $$v
                                          },
                                          expression: "filterByDriver",
                                        },
                                      })
                                    : _vm._e(),
                                  _c("filter-by-date", {
                                    model: {
                                      value: _vm.dateFilter,
                                      callback: function ($$v) {
                                        _vm.dateFilter = $$v
                                      },
                                      expression: "dateFilter",
                                    },
                                  }),
                                  _c("div", { staticClass: "filter" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "filter__btn",
                                        on: {
                                          click: function ($event) {
                                            _vm.isFilterOpen = !_vm.isFilterOpen
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            name: "ic-filter",
                                            width: "24",
                                            height: "24",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "bottom-table",
                        fn: function () {
                          return [
                            _vm.checked.some(function (k) {
                              return k
                            })
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "bottom-table",
                                    style: { display: "flex" },
                                  },
                                  [
                                    _c("div"),
                                    _vm.isOnDeliveryTab
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "remove-from-pickup",
                                            on: { click: _vm.removeFromPickup },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "img",
                                                style: {
                                                  "background-color": "#fff",
                                                  width: "48px",
                                                  height: "48px",
                                                  border: "4px solid red",
                                                  "box-sizing": "border-box",
                                                  "border-radius": "30px",
                                                  display: "flex",
                                                  "justify-content": "center",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "icon-btn",
                                                    style: {
                                                      width: "25px",
                                                      height: "25px",
                                                    },
                                                    attrs: {
                                                      viewBox: "0 0 48 48",
                                                      fill: "none",
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                    },
                                                  },
                                                  [
                                                    _c("circle", {
                                                      attrs: {
                                                        cx: "24",
                                                        cy: "24",
                                                        r: "24",
                                                        fill: "#FFF2F7",
                                                      },
                                                    }),
                                                    _c("path", {
                                                      staticClass:
                                                        "second-fill",
                                                      attrs: {
                                                        d: "M34.1135 13.884C33.6015 13.372 32.7804 13.372 32.2683 13.884L23.9988 22.1536L15.7292 13.884C15.2172 13.372 14.396 13.372 13.884 13.884C13.372 14.396 13.372 15.2172 13.884 15.7292L22.1536 23.9988L13.884 32.2684C13.372 32.7804 13.372 33.6016 13.884 34.1136C14.1352 34.3647 14.4733 34.5 14.8018 34.5C15.1302 34.5 15.4684 34.3744 15.7195 34.1136L23.9891 25.844L32.2587 34.1136C32.5099 34.3647 32.848 34.5 33.1764 34.5C33.5146 34.5 33.843 34.3744 34.0942 34.1136C34.6062 33.6016 34.6062 32.7804 34.0942 32.2684L25.844 23.9988L34.1135 15.7292C34.6256 15.2172 34.6256 14.396 34.1135 13.884Z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c("span", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    _vm.inPendingFilter
                                                      ? "Remove from pickup"
                                                      : "Remove from pickup"
                                                  )
                                                ),
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "ready-to-pickup",
                                        on: { click: _vm.readyToPickup },
                                      },
                                      [
                                        _c("span", { staticClass: "img" }, [
                                          _vm.inPendingFilter
                                            ? _c("img", {
                                                attrs: {
                                                  src: require("@/assets/img/svg/ready-to-pickup.svg"),
                                                  alt: "",
                                                },
                                              })
                                            : _c(
                                                "svg",
                                                {
                                                  attrs: {
                                                    width: "20",
                                                    height: "20",
                                                    viewBox: "0 0 20 20",
                                                    fill: "none",
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                  },
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      d: "M4.81167 10.6427C4.98561 10.8166 5.21807 10.9091 5.45467 10.9091C5.57178 10.9091 5.68986 10.8864 5.80228 10.8398C6.14197 10.6991 6.36349 10.3676 6.36349 9.99992V6.36363H19.0906C19.5926 6.36363 19.9997 5.9566 19.9997 5.45453C19.9997 4.95247 19.5926 4.54544 19.0906 4.54544H6.36353V0.909143C6.36353 0.541529 6.14209 0.210008 5.80232 0.0692674C5.46264 -0.0715126 5.07155 0.00629919 4.81167 0.266297L0.266277 4.81169C-0.088759 5.16672 -0.088759 5.74226 0.266277 6.0973L4.81167 10.6427Z",
                                                      fill: "#f28f16",
                                                    },
                                                  }),
                                                  _c("path", {
                                                    attrs: {
                                                      d: "M15.1881 9.35716C14.9281 9.09708 14.5371 9.01935 14.1974 9.16005C13.8577 9.30079 13.6362 9.63227 13.6362 9.99993V13.6362H0.909094C0.407028 13.6363 0 14.0433 0 14.5453C0 15.0474 0.407028 15.4544 0.909094 15.4544H13.6362V19.0907C13.6362 19.4584 13.8576 19.7899 14.1974 19.9306C14.3098 19.9772 14.428 19.9998 14.545 19.9998C14.7816 19.9998 15.0141 19.9075 15.188 19.7335L19.7334 15.1881C20.0885 14.8332 20.0885 14.2576 19.7334 13.9025L15.1881 9.35716Z",
                                                      fill: "#f28f16",
                                                    },
                                                  }),
                                                ]
                                              ),
                                        ]),
                                        _c("span", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                _vm.inPendingFilter
                                                  ? "Ready"
                                                  : "Replace messenger"
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "ready-to-pickup2",
                                        style: { "align-items": "center" },
                                        on: { click: _vm.closeOrder },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "img" },
                                          [
                                            _c("svg-icon", {
                                              staticClass: "ic-edit",
                                              attrs: {
                                                name: "ic-save-btn",
                                                width: "112",
                                                height: "112",
                                              },
                                            }),
                                            _c("use", {
                                              attrs: {
                                                "xlink:href":
                                                  "/img/sprite.4e96e1db.svg#ic-save-btn",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("span", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                _vm.inPendingFilter
                                                  ? "Close order"
                                                  : "Close order"
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2014835000
                  ),
                },
                "table-list",
                _vm.dataTable,
                false
              )
            ),
            _vm.setDelivery
              ? _c("choose-driver", {
                  attrs: {
                    sumOfPickupOrders: _vm.sumOfPickupOrders,
                    sumOfDeliveredOrders: _vm.sumOfDeliveredOrders,
                  },
                  on: { input: _vm.setDriver },
                })
              : _vm._e(),
            !!_vm.currentOrder
              ? _c("order-details-popup", {
                  attrs: { order: _vm.currentOrder, show: !!_vm.currentOrder },
                  on: {
                    closed: _vm.closeOrderDetails,
                    statusChanged: _vm.changeOrderStatus,
                  },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm.mobile
      ? _c(
          "div",
          { staticClass: "dispatcher-orders" },
          [
            _c("orders-filter-dialog", {
              attrs: {
                dispatcherFilters: true,
                dataForFilter: _vm.dataForFilter,
              },
              on: { apply: _vm.filterApplied },
              model: {
                value: _vm.isFilterOpen,
                callback: function ($$v) {
                  _vm.isFilterOpen = $$v
                },
                expression: "isFilterOpen",
              },
            }),
            _c(
              "table-list",
              _vm._b(
                {
                  on: { changeOptions: _vm.changeOptions },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "filter-by-date",
                        fn: function () {
                          return undefined
                        },
                        proxy: true,
                      },
                      {
                        key: "filter-text",
                        fn: function () {
                          return [
                            _vm.filtersText
                              ? _c("div", {
                                  staticClass: "mt12 mb12 md-none",
                                  domProps: {
                                    textContent: _vm._s(_vm.filtersText),
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "filter-icon",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "btns-filter" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn-filter",
                                  class: { active: _vm.inPendingFilter },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setFilter("inPendingFilter")
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass: "count",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.dispacher_summary.pending
                                      ),
                                    },
                                  }),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Pending")),
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-filter",
                                  class: { active: _vm.onReadyFilter },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setFilter("onReadyFilter")
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass: "count",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.dispacher_summary.ready
                                      ),
                                    },
                                  }),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("ready orders")
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-filter",
                                  class: { active: _vm.onDeliveryFilter },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setFilter("onDeliveryFilter")
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass: "count",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.dispacher_summary.on_delivery
                                      ),
                                    },
                                  }),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("In delivery")
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-filter",
                                  class: { active: _vm.closedFilter },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setFilter("closedFilter")
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass: "count",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.dispacher_summary.closed
                                      ),
                                    },
                                  }),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Close orders")
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "undertitle",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "flex col" }, [
                              _c(
                                "div",
                                { staticClass: "flex row m0" },
                                [
                                  _c("filter-by-date", {
                                    model: {
                                      value: _vm.dateFilter,
                                      callback: function ($$v) {
                                        _vm.dateFilter = $$v
                                      },
                                      expression: "dateFilter",
                                    },
                                  }),
                                  _c("div", { staticClass: "filter" }, [
                                    _c(
                                      "div",
                                      { staticClass: "driver-select" },
                                      [
                                        _vm.onDeliveryFilter ||
                                        _vm.closedFilter ||
                                        _vm.onReadyFilter
                                          ? _c("select-box", {
                                              staticClass: "driverSelectBox",
                                              attrs: {
                                                firstTop: "",
                                                showSearch: "",
                                                options:
                                                  _vm.deliveryGuiesOptions,
                                              },
                                              model: {
                                                value: _vm.filterByDriver,
                                                callback: function ($$v) {
                                                  _vm.filterByDriver = $$v
                                                },
                                                expression: "filterByDriver",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "filter__btn",
                                        on: {
                                          click: function ($event) {
                                            _vm.isFilterOpen = !_vm.isFilterOpen
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            name: "ic-filter",
                                            width: "24",
                                            height: "24",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "bottom-table",
                        fn: function () {
                          return [
                            _vm.checked.some(function (k) {
                              return k
                            })
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "bottom-table",
                                    style: { display: "flex" },
                                  },
                                  [
                                    _c("div"),
                                    _vm.isOnDeliveryTab
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "remove-from-pickup",
                                            on: { click: _vm.removeFromPickup },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "img",
                                                style: {
                                                  "background-color": "#fff",
                                                  width: "48px",
                                                  height: "48px",
                                                  border: "4px solid red",
                                                  "box-sizing": "border-box",
                                                  "border-radius": "30px",
                                                  display: "flex",
                                                  "justify-content": "center",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "icon-btn",
                                                    style: {
                                                      width: "25px",
                                                      height: "25px",
                                                    },
                                                    attrs: {
                                                      viewBox: "0 0 48 48",
                                                      fill: "none",
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                    },
                                                  },
                                                  [
                                                    _c("circle", {
                                                      attrs: {
                                                        cx: "24",
                                                        cy: "24",
                                                        r: "24",
                                                        fill: "#FFF2F7",
                                                      },
                                                    }),
                                                    _c("path", {
                                                      staticClass:
                                                        "second-fill",
                                                      attrs: {
                                                        d: "M34.1135 13.884C33.6015 13.372 32.7804 13.372 32.2683 13.884L23.9988 22.1536L15.7292 13.884C15.2172 13.372 14.396 13.372 13.884 13.884C13.372 14.396 13.372 15.2172 13.884 15.7292L22.1536 23.9988L13.884 32.2684C13.372 32.7804 13.372 33.6016 13.884 34.1136C14.1352 34.3647 14.4733 34.5 14.8018 34.5C15.1302 34.5 15.4684 34.3744 15.7195 34.1136L23.9891 25.844L32.2587 34.1136C32.5099 34.3647 32.848 34.5 33.1764 34.5C33.5146 34.5 33.843 34.3744 34.0942 34.1136C34.6062 33.6016 34.6062 32.7804 34.0942 32.2684L25.844 23.9988L34.1135 15.7292C34.6256 15.2172 34.6256 14.396 34.1135 13.884Z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c("span", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    _vm.inPendingFilter
                                                      ? "Remove from pickup"
                                                      : "Remove from pickup"
                                                  )
                                                ),
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "ready-to-pickup",
                                        on: { click: _vm.readyToPickup },
                                      },
                                      [
                                        _c("span", { staticClass: "img" }, [
                                          _vm.inPendingFilter
                                            ? _c("img", {
                                                attrs: {
                                                  src: require("@/assets/img/svg/ready-to-pickup.svg"),
                                                  alt: "",
                                                },
                                              })
                                            : _c(
                                                "svg",
                                                {
                                                  attrs: {
                                                    width: "20",
                                                    height: "20",
                                                    viewBox: "0 0 20 20",
                                                    fill: "none",
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                  },
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      d: "M4.81167 10.6427C4.98561 10.8166 5.21807 10.9091 5.45467 10.9091C5.57178 10.9091 5.68986 10.8864 5.80228 10.8398C6.14197 10.6991 6.36349 10.3676 6.36349 9.99992V6.36363H19.0906C19.5926 6.36363 19.9997 5.9566 19.9997 5.45453C19.9997 4.95247 19.5926 4.54544 19.0906 4.54544H6.36353V0.909143C6.36353 0.541529 6.14209 0.210008 5.80232 0.0692674C5.46264 -0.0715126 5.07155 0.00629919 4.81167 0.266297L0.266277 4.81169C-0.088759 5.16672 -0.088759 5.74226 0.266277 6.0973L4.81167 10.6427Z",
                                                      fill: "#f28f16",
                                                    },
                                                  }),
                                                  _c("path", {
                                                    attrs: {
                                                      d: "M15.1881 9.35716C14.9281 9.09708 14.5371 9.01935 14.1974 9.16005C13.8577 9.30079 13.6362 9.63227 13.6362 9.99993V13.6362H0.909094C0.407028 13.6363 0 14.0433 0 14.5453C0 15.0474 0.407028 15.4544 0.909094 15.4544H13.6362V19.0907C13.6362 19.4584 13.8576 19.7899 14.1974 19.9306C14.3098 19.9772 14.428 19.9998 14.545 19.9998C14.7816 19.9998 15.0141 19.9075 15.188 19.7335L19.7334 15.1881C20.0885 14.8332 20.0885 14.2576 19.7334 13.9025L15.1881 9.35716Z",
                                                      fill: "#f28f16",
                                                    },
                                                  }),
                                                ]
                                              ),
                                        ]),
                                        _c("span", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                _vm.inPendingFilter
                                                  ? "Ready"
                                                  : "Replace messenger"
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "ready-to-pickup2",
                                        style: { "align-items": "center" },
                                        on: { click: _vm.closeOrder },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "img" },
                                          [
                                            _c("svg-icon", {
                                              staticClass: "ic-edit",
                                              attrs: {
                                                name: "ic-save-btn",
                                                width: "112",
                                                height: "112",
                                              },
                                            }),
                                            _c("use", {
                                              attrs: {
                                                "xlink:href":
                                                  "/img/sprite.4e96e1db.svg#ic-save-btn",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("span", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                _vm.inPendingFilter
                                                  ? "Close order"
                                                  : "Close order"
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    265928598
                  ),
                },
                "table-list",
                _vm.dataTable,
                false
              )
            ),
            _vm.setDelivery
              ? _c("choose-driver", {
                  attrs: {
                    sumOfPickupOrders: _vm.sumOfPickupOrders,
                    sumOfDeliveredOrders: _vm.sumOfDeliveredOrders,
                  },
                  on: { input: _vm.setDriver },
                })
              : _vm._e(),
            !!_vm.currentOrder
              ? _c("order-details-popup", {
                  attrs: { order: _vm.currentOrder, show: !!_vm.currentOrder },
                  on: {
                    closed: _vm.closeOrderDetails,
                    statusChanged: _vm.changeOrderStatus,
                  },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }