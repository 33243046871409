var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reasons full-screen" },
    [
      _c("main", [
        _c("section", { staticClass: "container" }, [
          _c("div", { staticClass: "page" }, [
            _c("div", { staticClass: "page__main" }, [
              _c(
                "div",
                { staticClass: "reasons-head" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page__back", attrs: { to: "../settings" } },
                    [
                      _c("svg-icon", {
                        attrs: {
                          name: "ic-close-table-arrow",
                          width: "34",
                          height: "34",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("h1", {
                    domProps: { textContent: _vm._s(_vm.$t("Reasons")) },
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn_pink",
                      attrs: { type: "button" },
                      on: { click: _vm.createReason },
                    },
                    [
                      _c("svg-icon", {
                        attrs: { name: "ic-plus", width: "16", height: "16" },
                      }),
                      _c("span", [_vm._v(_vm._s(_vm.$t("New Reason")))]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "page__content" },
              [
                _c(
                  "table-list",
                  _vm._b(
                    {
                      on: {
                        "on-click-line": _vm.openDialogFromTable,
                        changeOptions: _vm.changeOptions,
                      },
                    },
                    "table-list",
                    _vm.dataTable,
                    false
                  )
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm.showReasonEditDialog && _vm.reasonInEdit
        ? _c(
            "base-dialog",
            {
              staticClass: "reason-edit-dialog",
              attrs: {
                width: "500px",
                title: _vm.reasonInEdit.id
                  ? _vm.$t("Edit Reason")
                  : _vm.$t("Create Reason"),
              },
              on: { close: _vm.closeReasonEditDialog },
            },
            [
              _c("div", { staticClass: "popup__content" }, [
                _c("div", { staticClass: "form-site" }, [
                  _c("div", { staticClass: "form-site__group name-edit" }, [
                    _c("div", { staticClass: "form-site__field" }, [
                      _c(
                        "label",
                        { staticClass: "form-site__label d-md-block d-none" },
                        [_vm._v(_vm._s(_vm.$t("Reason type")))]
                      ),
                      _c(
                        "div",
                        { staticClass: "form-site__input" },
                        [
                          _c("select-box", {
                            attrs: {
                              options: _vm.reasonTypeOptions,
                              placeholder: _vm.$t("Select reason type"),
                            },
                            model: {
                              value: _vm.reasonInEdit.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.reasonInEdit, "type", $$v)
                              },
                              expression: "reasonInEdit.type",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-site__field" }, [
                      _c(
                        "label",
                        { staticClass: "form-site__label d-md-block d-none" },
                        [_vm._v(_vm._s(_vm.$t("Name")))]
                      ),
                      _c("div", { staticClass: "form-site__input" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.reasonInEdit.name,
                              expression: "reasonInEdit.name",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.reasonInEdit.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.reasonInEdit,
                                "name",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-site__field" }, [
                      _c(
                        "label",
                        { staticClass: "form-site__label d-md-block d-none" },
                        [_vm._v(_vm._s(_vm.$t("English Name")))]
                      ),
                      _c("div", { staticClass: "form-site__input" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.reasonInEdit.name_en,
                              expression: "reasonInEdit.name_en",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.reasonInEdit.name_en },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.reasonInEdit,
                                "name_en",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "popup-btn pb40" },
                  [
                    _vm.reasonInEdit && _vm.reasonInEdit.id
                      ? _c("remove-btn", { on: { click: _vm.deleteReason } })
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass: "btn",
                        attrs: { type: "button" },
                        on: { click: _vm.saveReason },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(_vm.$t("Save")) + "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }