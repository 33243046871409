var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "bg" }, [
    _c("div", {
      staticClass: "shadow-site is-visible md-block",
      on: { click: _vm.exit },
    }),
    _c("div", { staticClass: "table-search-popup-ancor", style: _vm.style }, [
      _c(
        "div",
        {
          ref: "wd",
          staticClass: "table-search-popup",
          class: { intop: _vm.intop },
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "table-search-popup__field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filter,
                      expression: "filter",
                    },
                  ],
                  attrs: { type: "text", placeholder: _vm.$t("Search...") },
                  domProps: { value: _vm.filter },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.filter = $event.target.value
                    },
                  },
                }),
                _c(
                  "button",
                  { attrs: { type: "submit" } },
                  [
                    _c("svg-icon", {
                      staticClass: "ic-stroke",
                      attrs: { name: "ic-search", width: "19", height: "19" },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "table-search-popup__content" },
                [
                  _c("div", { staticClass: "table-search-popup__item" }, [
                    _c("div", {
                      staticClass: "table-search-popup__title",
                      domProps: { textContent: _vm._s(_vm.$t("All branches")) },
                    }),
                    _c(
                      "div",
                      { staticClass: "table-search-popup__action" },
                      [
                        _c("swich-button", {
                          attrs: {
                            value:
                              _vm.item[_vm.branchesKey].includes(0) ||
                              (_vm.branchesKey === "branches_activity" &&
                                _vm.item[_vm.branchesKey].length ===
                                  _vm.item.branches.length),
                          },
                          on: { input: _vm.setAllBranche },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._l(_vm.branches, function (branch) {
                    return _c(
                      "div",
                      {
                        key: branch.id,
                        staticClass: "table-search-popup__item",
                      },
                      [
                        _c("div", {
                          staticClass: "table-search-popup__title",
                          domProps: {
                            textContent: _vm._s(
                              _vm.he ||
                                !branch.name_en ||
                                branch.name_en === "undefined"
                                ? branch.name
                                : branch.name_en
                            ),
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "table-search-popup__action" },
                          [
                            _c("swich-button", {
                              attrs: { value: _vm.branchActive(branch.id) },
                              on: {
                                input: function ($event) {
                                  return _vm.setBranch(branch.id)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }