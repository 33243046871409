import { render, staticRenderFns } from "./add-legacy-club-dialog.vue?vue&type=template&id=40296e7f&scoped=true&"
import script from "./add-legacy-club-dialog.vue?vue&type=script&lang=ts&"
export * from "./add-legacy-club-dialog.vue?vue&type=script&lang=ts&"
import style0 from "./add-legacy-club-dialog.vue?vue&type=style&index=0&id=40296e7f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40296e7f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/dorshoshan/Documents/DEV/Atmos-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('40296e7f')) {
      api.createRecord('40296e7f', component.options)
    } else {
      api.reload('40296e7f', component.options)
    }
    module.hot.accept("./add-legacy-club-dialog.vue?vue&type=template&id=40296e7f&scoped=true&", function () {
      api.rerender('40296e7f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/dialogs/members-club/add-legacy-club-dialog.vue"
export default component.exports