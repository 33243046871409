var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-dialog-tabs", {
    attrs: { tabList: ["Extras", "Tags"], width: "700px" },
    on: {
      close: function ($event) {
        return _vm.$emit("save")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "tab0",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "popup-main" },
              [
                _c("div", { staticClass: "search-form__field" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.search,
                        expression: "search",
                      },
                    ],
                    attrs: { type: "text", placeholder: _vm.$t("Search...") },
                    domProps: { value: _vm.search },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.search = $event.target.value
                      },
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "search-form__icon" },
                    [
                      _c("svg-icon", {
                        attrs: {
                          name: "ic-search2",
                          width: "20",
                          height: "20",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._l(_vm.extrasFilter, function (extra) {
                  return _c(
                    "label",
                    { key: extra.id, staticClass: "extra flex" },
                    [
                      _c("checkbox", {
                        attrs: {
                          name: "cb" + extra.id,
                          value: _vm.deal_extra_ids.includes(extra.id),
                        },
                        on: {
                          input: function ($event) {
                            return _vm.inputCheckbox($event, extra.id, true)
                          },
                        },
                      }),
                      _c("span", {
                        style: { overflow: "auto" },
                        domProps: { textContent: _vm._s(extra.name) },
                      }),
                      _c("span", {
                        staticClass: "mis4",
                        domProps: {
                          textContent: _vm._s("(" + extra.makat + ")"),
                        },
                      }),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "tab1",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "popup-main" },
              [
                _c("div", { staticClass: "search-form__field" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.search,
                        expression: "search",
                      },
                    ],
                    attrs: { type: "text", placeholder: _vm.$t("Search...") },
                    domProps: { value: _vm.search },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.search = $event.target.value
                      },
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "search-form__icon" },
                    [
                      _c("svg-icon", {
                        attrs: {
                          name: "ic-search2",
                          width: "20",
                          height: "20",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._l(_vm.tagsFilter, function (tag) {
                  return _c(
                    "label",
                    { key: tag.id, staticClass: "extra flex" },
                    [
                      _c("checkbox", {
                        attrs: {
                          name: "cb" + tag.id,
                          value: _vm.deal_tag_ids.includes(tag.id),
                        },
                        on: {
                          input: function ($event) {
                            return _vm.inputCheckbox($event, tag.id, false)
                          },
                        },
                      }),
                      _c("span", {
                        style: { overflow: "auto" },
                        domProps: { textContent: _vm._s(tag.name) },
                      }),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "btns",
        fn: function () {
          return [
            _c("div", { staticClass: "form-site mb0 btm-btns" }, [
              _c(
                "div",
                { staticClass: "form-site__btn d-none d-md-flex bet" },
                [
                  _c("div", {}),
                  _c("form-site-field-text", {
                    staticClass: "mie8",
                    attrs: { placeholder: "0", title: "How many?" },
                    model: {
                      value: _vm.howMany,
                      callback: function ($$v) {
                        _vm.howMany = $$v
                      },
                      expression: "howMany",
                    },
                  }),
                  _vm.restaurantCurrencyInfo === null
                    ? _c("form-site-field-text", {
                        staticClass: "mie8",
                        attrs: {
                          placeholder: "0",
                          sign: "₪",
                          title: "How much?",
                        },
                        model: {
                          value: _vm.howMuch,
                          callback: function ($$v) {
                            _vm.howMuch = $$v
                          },
                          expression: "howMuch",
                        },
                      })
                    : _vm._e(),
                  _vm.restaurantCurrencyInfo !== null
                    ? _c("form-site-field-text", {
                        staticClass: "mie8",
                        attrs: {
                          placeholder: "0",
                          sign: _vm.restaurantCurrencySymbol,
                          title: "How much?",
                        },
                        model: {
                          value: _vm.howMuch,
                          callback: function ($$v) {
                            _vm.howMuch = $$v
                          },
                          expression: "howMuch",
                        },
                      })
                    : _vm._e(),
                  _c("button", {
                    staticClass: "btn mt16",
                    domProps: { textContent: _vm._s(_vm.$t("save")) },
                    on: { click: _vm.save },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
                _c(
                  "button",
                  { staticClass: "save-btn", on: { click: _vm.save } },
                  [
                    _c("svg-icon", {
                      staticClass: "ic-edit",
                      attrs: {
                        name: "ic-save-btn",
                        width: "112",
                        height: "112",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }