var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "theme full-screen" }, [
    _c("main", [
      _c("section", { staticClass: "container" }, [
        _c("div", { staticClass: "page" }, [
          _c(
            "div",
            { staticClass: "page__main" },
            [
              _c(
                "router-link",
                { staticClass: "page__back", attrs: { to: "../settings" } },
                [
                  _c("svg-icon", {
                    attrs: {
                      name: "ic-close-table-arrow",
                      width: "34",
                      height: "34",
                    },
                  }),
                ],
                1
              ),
              _c("h1", { domProps: { textContent: _vm._s(_vm.$t("Theme")) } }),
              _c("section", { staticClass: "tab-theme" }, [
                _c("div", { staticClass: "tab-theme__list" }, [
                  _c("button", {
                    staticClass: "tab-theme__label",
                    class: { active: _vm.tabMode === "image" },
                    domProps: { textContent: _vm._s(_vm.$t("Images")) },
                    on: {
                      click: function ($event) {
                        return _vm.setMode("tab", "image")
                      },
                    },
                  }),
                  _c("button", {
                    staticClass: "tab-theme__label",
                    class: { active: _vm.tabMode === "color" },
                    domProps: { textContent: _vm._s(_vm.$t("Colors")) },
                    on: {
                      click: function ($event) {
                        return _vm.setMode("tab", "color")
                      },
                    },
                  }),
                  _c("button", {
                    staticClass: "tab-theme__label",
                    class: { active: _vm.tabMode === "style" },
                    domProps: { textContent: _vm._s(_vm.$t("Style")) },
                    on: {
                      click: function ($event) {
                        return _vm.setMode("tab", "style")
                      },
                    },
                  }),
                  _c("button", {
                    staticClass: "tab-theme__label",
                    class: { active: _vm.tabMode === "customCss" },
                    domProps: { textContent: _vm._s(_vm.$t("Custom CSS")) },
                    on: {
                      click: function ($event) {
                        return _vm.setMode("tab", "customCss")
                      },
                    },
                  }),
                  _c("button", {
                    staticClass: "tab-theme__label",
                    class: { active: _vm.tabMode === "Grid" },
                    domProps: { textContent: _vm._s(_vm.$t("grid")) },
                    on: {
                      click: function ($event) {
                        return _vm.setMode("tab", "grid")
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "tab-theme__content" }, [
                  _c(
                    "div",
                    {
                      staticClass: "tab-theme__item image md-none",
                      class: { active: _vm.tabMode === "image" },
                    },
                    [
                      _c("div", { staticClass: "tab-theme__info" }, [
                        _c("ul", { staticClass: "theme-menu" }, [
                          _c("li", {
                            staticClass: "theme-menu__item",
                            class: { active: _vm.menuMode === "Loader" },
                            domProps: { textContent: _vm._s(_vm.$t("Loader")) },
                            on: {
                              click: function ($event) {
                                return _vm.setMode("theme-menu", "Loader")
                              },
                            },
                          }),
                          _c("li", {
                            staticClass: "theme-menu__item",
                            class: { active: _vm.menuMode === "Logos" },
                            domProps: { textContent: _vm._s(_vm.$t("Logos")) },
                            on: {
                              click: function ($event) {
                                return _vm.setMode("theme-menu", "Logos")
                              },
                            },
                          }),
                          _c("li", {
                            staticClass: "theme-menu__item",
                            class: { active: _vm.menuMode === "Main images" },
                            domProps: { textContent: _vm._s(_vm.$t("Icons")) },
                            on: {
                              click: function ($event) {
                                return _vm.setMode("theme-menu", "Main images")
                              },
                            },
                          }),
                          _c("li", {
                            staticClass: "theme-menu__item",
                            class: {
                              active: _vm.menuMode === "Main background",
                            },
                            domProps: {
                              textContent: _vm._s(_vm.$t("Main background")),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.setMode(
                                  "theme-menu",
                                  "Main background"
                                )
                              },
                            },
                          }),
                          _c("li", {
                            staticClass: "theme-menu__item",
                            class: {
                              active: _vm.menuMode === "Menu background",
                            },
                            domProps: {
                              textContent: _vm._s(_vm.$t("Menu background")),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.setMode(
                                  "theme-menu",
                                  "Menu background"
                                )
                              },
                            },
                          }),
                          _c("li", {
                            staticClass: "theme-menu__item",
                            class: { active: _vm.menuMode === "Video" },
                            domProps: { textContent: _vm._s(_vm.$t("Video")) },
                            on: {
                              click: function ($event) {
                                return _vm.setMode("theme-menu", "Video")
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "tab-theme__visual" }, [
                        _c("div", { staticClass: "theme-view" }, [
                          _c("div", { staticClass: "theme-view__top" }, [
                            _vm.menuMode !== "Grid"
                              ? _c(
                                  "label",
                                  { staticClass: "theme-view__photo" },
                                  [
                                    _vm.hasImage
                                      ? _c(
                                          "button",
                                          { on: { click: _vm.removeImage } },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                name: "ic-plus",
                                                width: "22",
                                                height: "22",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm.hasVideo
                                      ? _c(
                                          "button",
                                          { on: { click: _vm.removeVideo } },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                name: "ic-plus",
                                                width: "22",
                                                height: "22",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm.menuMode === "Video"
                                      ? [
                                          _c("input", {
                                            attrs: {
                                              type: "file",
                                              accept: "video/*,image/gif",
                                            },
                                            on: { change: _vm.enterVideo },
                                          }),
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "ic-video",
                                              width: "22",
                                              height: "22",
                                            },
                                          }),
                                        ]
                                      : [
                                          _c("input", {
                                            attrs: {
                                              type: "file",
                                              accept: "image/*",
                                            },
                                            on: { change: _vm.enterPhoto },
                                          }),
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "ic-photo",
                                              width: "22",
                                              height: "22",
                                            },
                                          }),
                                        ],
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm.menuMode === "Main images"
                              ? _c("div", { staticClass: "theme-view-menu" }, [
                                  _c("button", {
                                    staticClass: "theme-view-menu__item",
                                    class: {
                                      active:
                                        _vm.themeViewMainImage === "Delivery",
                                    },
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Delivery")),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setMode(
                                          "theme-view-main-image",
                                          "Delivery"
                                        )
                                      },
                                    },
                                  }),
                                  _c("button", {
                                    staticClass: "theme-view-menu__item",
                                    class: {
                                      active:
                                        _vm.themeViewMainImage === "Takeaway",
                                    },
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Takeaway")),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setMode(
                                          "theme-view-main-image",
                                          "Takeaway"
                                        )
                                      },
                                    },
                                  }),
                                  _c("button", {
                                    staticClass: "theme-view-menu__item",
                                    class: {
                                      active:
                                        _vm.themeViewMainImage === "Sitting",
                                    },
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Sitting")),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setMode(
                                          "theme-view-main-image",
                                          "Sitting"
                                        )
                                      },
                                    },
                                  }),
                                ])
                              : _vm.menuMode === "Loader"
                              ? _c("div")
                              : _vm.menuMode === "Logos"
                              ? _c("div", { staticClass: "theme-view-menu" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "theme-view-menu__item",
                                      class: {
                                        active: _vm.themeView === "Desktop",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setMode(
                                            "theme-view",
                                            "Desktop"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "theme-view-menu__icon",
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "ic-desctop",
                                              width: "20",
                                              height: "20",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("Regular")
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "theme-view-menu__item",
                                      class: {
                                        active: _vm.themeView === "Mobile",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setMode(
                                            "theme-view",
                                            "Mobile"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "theme-view-menu__icon",
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "ic-mobile",
                                              width: "12",
                                              height: "20",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("Horizontal")
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ])
                              : _vm.menuMode === "Video"
                              ? _c("div", { staticClass: "theme-view-menu" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "theme-view-menu__item",
                                      class: {
                                        active:
                                          _vm.themeView === "Digital menu",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setMode(
                                            "theme-view",
                                            "Digital menu"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "theme-view-menu__icon",
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "ic-tablet",
                                              width: "20",
                                              height: "20",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("Digital menu")
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "theme-view-menu__item",
                                      class: {
                                        active: _vm.themeView === "Kiosk",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setMode(
                                            "theme-view",
                                            "Kiosk"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "theme-view-menu__icon",
                                        },
                                        [
                                          _c("svg-icon", {
                                            staticClass: "ic-stroke",
                                            attrs: {
                                              name: "ic-kiosk",
                                              width: "12",
                                              height: "20",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(_vm.$t("Kiosk")),
                                        },
                                      }),
                                    ]
                                  ),
                                ])
                              : _c("div", { staticClass: "theme-view-menu" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "theme-view-menu__item",
                                      class: {
                                        active: _vm.themeView === "Desktop",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setMode(
                                            "theme-view",
                                            "Desktop"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "theme-view-menu__icon",
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "ic-desctop",
                                              width: "20",
                                              height: "20",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("Desktop")
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "theme-view-menu__item",
                                      class: {
                                        active: _vm.themeView === "Mobile",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setMode(
                                            "theme-view",
                                            "Mobile"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "theme-view-menu__icon",
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "ic-mobile",
                                              width: "12",
                                              height: "20",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(_vm.$t("Mobile")),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "theme-view-menu__item",
                                      class: {
                                        active: _vm.themeView === "Kiosk",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setMode(
                                            "theme-view",
                                            "Kiosk"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "theme-view-menu__icon",
                                        },
                                        [
                                          _c("svg-icon", {
                                            staticClass: "ic-stroke",
                                            attrs: {
                                              name: "ic-kiosk",
                                              width: "12",
                                              height: "20",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(_vm.$t("Kiosk")),
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                          ]),
                          _vm.activeImage
                            ? _c(
                                "div",
                                { staticClass: "theme-view__content" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "theme-view__img active" },
                                    [
                                      _vm.images
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.activeImage,
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              )
                            : _vm.activeVideo
                            ? _c(
                                "div",
                                { staticClass: "theme-view__content" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "theme-view__img active" },
                                    [
                                      _vm.activeVideoIsGift
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.activeVideo,
                                              alt: "Video",
                                            },
                                          })
                                        : _vm.videos
                                        ? _c(
                                            "video",
                                            {
                                              attrs: {
                                                width: "100%",
                                                height: "100%",
                                                controls: "",
                                              },
                                            },
                                            [
                                              _c("source", {
                                                attrs: {
                                                  src: _vm.activeVideo,
                                                  type: "video/mp4",
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "tab-theme__item image d-md-none",
                      class: { active: _vm.tabMode === "image" },
                    },
                    [
                      _vm.menuImage
                        ? _c("div", { staticClass: "tab-theme__info" }, [
                            _c("ul", { staticClass: "theme-menu" }, [
                              _c(
                                "li",
                                {
                                  staticClass: "theme-menu__item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.setMode("theme-menu", "Video")
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "flex a-center" }, [
                                    _c("div", { staticClass: "imgs" }, [
                                      _vm.videos
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.videos.video_kiosk,
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.videos
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.videos.video_menu,
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                    ]),
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Video")),
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "theme-menu__item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.setMode("theme-menu", "Loader")
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "flex a-center" }, [
                                    _vm.images
                                      ? _c("img", {
                                          attrs: {
                                            src: _vm.images.loader,
                                            alt: "",
                                          },
                                        })
                                      : _vm._e(),
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Loader")),
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "theme-menu__item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.setMode("theme-menu", "Logos")
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "flex a-center" }, [
                                    _c("div", { staticClass: "imgs" }, [
                                      _vm.images
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.images.logo,
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.images
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.images.logo_horizontal,
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                    ]),
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Logos")),
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "theme-menu__item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.setMode(
                                        "theme-menu",
                                        "Main images"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "flex a-center" }, [
                                    _c("div", { staticClass: "imgs" }, [
                                      _vm.images
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.images.delivery_image,
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.images
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.images.takeaway_image,
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.images
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.images.sitting_image,
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                    ]),
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Icons")),
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "theme-menu__item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.setMode(
                                        "theme-menu",
                                        "Main background"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "flex a-center" }, [
                                    _c("div", { staticClass: "imgs" }, [
                                      _vm.images
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.images
                                                .start_background_desktop,
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.images
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.images
                                                .start_background_mobile,
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.images
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.images
                                                .start_background_kiosk,
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                    ]),
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Main background")
                                        ),
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "theme-menu__item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.setMode(
                                        "theme-menu",
                                        "Menu background"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "flex a-center" }, [
                                    _c("div", { staticClass: "imgs" }, [
                                      _vm.images
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.images
                                                .menu_background_desktop,
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.images
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.images
                                                .menu_background_mobile,
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.images
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.images
                                                .menu_background_kiosk,
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                    ]),
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Menu background")
                                        ),
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "tab-theme__item color",
                      class: { active: _vm.tabMode === "color" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "tab-theme__info" },
                        [
                          _vm.colorPickerOpen
                            ? _c("color-picker", {
                                staticClass: "color-picker",
                                class: { collapse: !_vm.colorPickerOpen },
                                attrs: { gradient: _vm.gradient },
                                on: { close: _vm.closeColorPicker },
                                model: {
                                  value: _vm.colorToChange,
                                  callback: function ($$v) {
                                    _vm.colorToChange = $$v
                                  },
                                  expression: "colorToChange",
                                },
                              })
                            : _vm._e(),
                          _c("div", { staticClass: "theme-color-wrap" }, [
                            _c("div", { staticClass: "theme-color" }, [
                              _c(
                                "div",
                                { staticClass: "theme-color__list" },
                                [
                                  _vm.colors
                                    ? _vm._l(_vm.colorsArry, function (color) {
                                        return _c(
                                          "div",
                                          {
                                            key: color.key,
                                            staticClass: "theme-color__item",
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeColor(
                                                  color.key
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("div", {
                                              staticClass: "theme-color__label",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(color.key)
                                                ),
                                              },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "theme-color__content",
                                              },
                                              [
                                                _c("button", {
                                                  staticClass:
                                                    "theme-color__fill",
                                                  style: {
                                                    background: color.value,
                                                  },
                                                }),
                                                _c("div", {
                                                  staticClass:
                                                    "theme-color__text",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      color.value &&
                                                        color.value.indexOf(
                                                          "gradient"
                                                        ) !== -1
                                                        ? _vm.$t("Gradient")
                                                        : color.value
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      })
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "tab-theme__item style",
                      class: { active: _vm.tabMode === "style" },
                    },
                    [
                      _c("div", { staticClass: "tab-theme__info" }, [
                        _c("div", { staticClass: "theme-style" }, [
                          _c("div", {
                            staticClass: "theme-style__head",
                            domProps: { textContent: _vm._s(_vm.$t("Font")) },
                          }),
                          _c(
                            "form",
                            {
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                },
                              },
                            },
                            [
                              _c("div", {
                                staticClass: "theme-style__label",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Regular")),
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "theme-style__font" },
                                [
                                  _c("input-font", {
                                    attrs: {
                                      name: "normal_woff",
                                      nameFromServer: _vm.fontList.normal_woff,
                                      type: "WOFF",
                                    },
                                    on: {
                                      upload: _vm.uploadFont,
                                      remove: function ($event) {
                                        return _vm.removeFont("normal_woff")
                                      },
                                    },
                                  }),
                                  _c("input-font", {
                                    attrs: {
                                      name: "normal_ttf",
                                      nameFromServer: _vm.fontList.normal_ttf,
                                      type: "TTF",
                                    },
                                    on: {
                                      upload: _vm.uploadFont,
                                      remove: function ($event) {
                                        return _vm.removeFont("normal_ttf")
                                      },
                                    },
                                  }),
                                  _c("input-font", {
                                    attrs: {
                                      name: "normal_eot",
                                      nameFromServer: _vm.fontList.normal_eot,
                                      type: "EOT",
                                    },
                                    on: {
                                      upload: _vm.uploadFont,
                                      remove: function ($event) {
                                        return _vm.removeFont("normal_eot")
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", {
                                staticClass: "theme-style__label",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("bold")),
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "theme-style__font" },
                                [
                                  _c("input-font", {
                                    attrs: {
                                      name: "bold_woff",
                                      nameFromServer: _vm.fontList.bold_woff,
                                      type: "WOFF",
                                    },
                                    on: {
                                      upload: _vm.uploadFont,
                                      remove: function ($event) {
                                        return _vm.removeFont("bold_woff")
                                      },
                                    },
                                  }),
                                  _c("input-font", {
                                    attrs: {
                                      name: "bold_ttf",
                                      nameFromServer: _vm.fontList.bold_ttf,
                                      type: "TTF",
                                    },
                                    on: {
                                      upload: _vm.uploadFont,
                                      remove: function ($event) {
                                        return _vm.removeFont("bold_ttf")
                                      },
                                    },
                                  }),
                                  _c("input-font", {
                                    attrs: {
                                      name: "bold_eot",
                                      nameFromServer: _vm.fontList.bold_eot,
                                      type: "EOT",
                                    },
                                    on: {
                                      upload: _vm.uploadFont,
                                      remove: function ($event) {
                                        return _vm.removeFont("bold_eot")
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("hr", { staticClass: "hr" }),
                              _c("div", { staticClass: "theme-style__sect" }, [
                                _c(
                                  "div",
                                  { staticClass: "theme-style__item" },
                                  [
                                    _c("div", {
                                      staticClass: "theme-style__label",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Cards radius")
                                        ),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "theme-style__field" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "select" },
                                          [
                                            _c("input-px", {
                                              model: {
                                                value: _vm.radiuses.cards,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.radiuses,
                                                    "cards",
                                                    $$v
                                                  )
                                                },
                                                expression: "radiuses.cards",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "theme-style__item" },
                                  [
                                    _c("div", {
                                      staticClass: "theme-style__label",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("buttons radius")
                                        ),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "theme-style__field" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "select" },
                                          [
                                            _c("input-px", {
                                              model: {
                                                value: _vm.radiuses.buttons,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.radiuses,
                                                    "buttons",
                                                    $$v
                                                  )
                                                },
                                                expression: "radiuses.buttons",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "theme-style__item" },
                                  [
                                    _c("div", {
                                      staticClass: "theme-style__label",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("inputs radius")
                                        ),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "theme-style__field" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "select" },
                                          [
                                            _c("input-px", {
                                              model: {
                                                value: _vm.radiuses.inputs,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.radiuses,
                                                    "inputs",
                                                    $$v
                                                  )
                                                },
                                                expression: "radiuses.inputs",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "tab-theme__item customCss",
                      class: { active: _vm.tabMode === "customCss" },
                    },
                    [
                      _c("div", { staticClass: "tab-theme__info" }, [
                        _c("div", { staticClass: "theme-css" }, [
                          _c("div", { staticClass: "theme-css__label" }, [
                            _vm._v("Css"),
                          ]),
                          _c("div", { staticClass: "theme-css__field" }, [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.castomCssText,
                                  expression: "castomCssText",
                                },
                              ],
                              attrs: {
                                dir: "ltr",
                                placeholder: _vm.$t("Custom CSS..."),
                              },
                              domProps: { value: _vm.castomCssText },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.castomCssText = $event.target.value
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "tab-theme__item customCss",
                      class: { active: _vm.tabMode === "grid" },
                    },
                    [
                      _c("div", { staticStyle: { padding: "30px" } }, [
                        _c("h2", {
                          staticStyle: { "margin-bottom": "30px" },
                          domProps: { textContent: _vm._s(_vm.$t("grid")) },
                        }),
                        _c(
                          "div",
                          { staticClass: "coupon-info-check" },
                          _vm._l(["Both", "Rows", "Cubes"], function (name, i) {
                            return _c(
                              "div",
                              {
                                key: name,
                                staticClass: "coupon-info-check__item",
                                staticStyle: {
                                  display: "flex",
                                  width: "100px",
                                },
                              },
                              [
                                _c("div", { staticClass: "input-check" }, [
                                  _c("div", { staticClass: "input-radio" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.grid,
                                          expression: "grid",
                                        },
                                      ],
                                      attrs: {
                                        type: "radio",
                                        name: "buyradio",
                                        id: "radio" + name,
                                      },
                                      domProps: {
                                        value: i,
                                        checked: _vm._q(_vm.grid, i),
                                      },
                                      on: {
                                        change: function ($event) {
                                          _vm.grid = i
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-radio__label",
                                        attrs: { for: "radio" + name },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "input-radio__mark",
                                        }),
                                        _c("span", {
                                          staticClass: "input-radio__text",
                                          domProps: {
                                            textContent: _vm._s(_vm.$t(name)),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ]),
        _vm.change
          ? _c("div", { staticClass: "btn-save-container-settings" }, [
              _c(
                "button",
                { staticClass: "save-btn", on: { click: _vm.saveTheme } },
                [
                  _c("svg-icon", {
                    attrs: { name: "ic-save-btn", width: "112", height: "128" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }