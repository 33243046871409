<template>
  <base-dialog
    class="atmos-promotions-success-msg"
    width="600px"
    height="800px"
    overflowAuto
    @close="$emit('close')"
  >
    <div class="atmos-promotions-success-msg__header">
      <div class="atmos-promotions-success-msg__header__backgroung">
        <img class="img" src="../assets/img/vi.png" />
      </div>
      <div style="display: flex; flex-direction: column; align-items: center">
        <h3
          v-text="$t('Congratulations')"
          style="font-weight: 500; font-size: 32px"
        />
        <span
          class="atmos-promotions-success-msg__footer__span"
          v-text="$t('Your promotions is done and ready to use')"
        />
      </div>
      <div style="display: flex; justify-content: space-around; width: 100%">
        <button class="btn-green">
          <svg-icon
            name="ic-arrow-left"
            class="ic-white"
            width="16"
            height="12"
          />
          <span v-text="$t('Go to list')" />
        </button>
      </div>
    </div>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/dialogs/base-dialog";
import store from "@/store";
export default {
  name: "atmos-promotions-success-msg",
  components: { BaseDialog },
  mounted() {
    //closing the popup after time
    setTimeout(() => {
     this.$emit("close")
    }, 2000);
  }
};
</script>

<style lang="scss">
.atmos-promotions-success-msg {
  .popup__close-continer {
    padding: 10px;
  }

  .btn-green {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    min-width: 170px;
    border: 0;
    border-radius: 1.5rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 3rem;
    color: #fff;
    text-transform: uppercase;
    padding: 0 20px;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s ease 0s;
    box-shadow: 0px 8px 16px rgba(138, 138, 138, 0.25);
    border-radius: 200px;
    background-color: #48b26b;
    color: white;
    margin-top: 50px;
  }

  .img {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .atmos-promotions-success-msg {
    height: 500px;
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 1);
    width: 500px;
    box-shadow: 0px 37px 30px 0px rgba(65, 65, 65, 0.06);

    /*background: linear-gradient(180deg, rgba(255, 255, 255, 0) 15.03%, #FFFFFF 72.72%);*/
    /*box-shadow: 0px 37px 30px 0px #4141410F;*/
    &__header {
      --radial-gradient: rgba(72, 178, 107, 0.4);
      --radial-gradient2: rgba(152, 219, 124, 0.4);
      top: 50%;
      position: relative;
      left: 0%;
      display: flex;
      justify-content: center;
      align-items: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 600px;
      box-shadow: 0px 37px 30px 0px #4141410f;
      color: #ffffff;
      background: linear-gradient(rgba(152, 219, 124, 0.4) 40%, white 60%);

      &__backgroung {
        width: 90px;
        height: 90px;
        border-radius: 30px;
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 60px;
        background: rgb(72, 178, 107);
      }
    }

    &__footer {
      height: 300px;
      color: #ffffff;
      margin-top: 50px;
      background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 15.03%,
          #ffffff 72.72%
      );
      box-shadow: 0px 37px 30px 0px #4141410f;

      &__span {
        margin-top: 10px;
        display: flex;
        font-size: 16px;
        line-height: 20px;
        justify-content: center;
        align-items: center;
        color: #404040;
      }
    }
  }
}
</style>
