var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.statistic.name
    ? _c("div", { staticClass: "statistics-box flex col relative" }, [
        _c(
          "span",
          {
            class:
              _vm.payment && !(_vm.statistic.type || _vm.statistic.type === 0)
                ? "to-end"
                : "",
          },
          [
            _c("header", { staticClass: "flex bet" }, [
              _c(
                "span",
                {
                  staticClass: "flex",
                  class:
                    _vm.payment &&
                    !(_vm.statistic.type || _vm.statistic.type === 0)
                      ? "green"
                      : "",
                },
                [
                  !_vm.payment && !_vm.statistic.hide
                    ? _c("span", {
                        staticClass: "mie4",
                        domProps: {
                          textContent: _vm._s(
                            _vm.filters.abbreviate(_vm.statistic.records)
                          ),
                        },
                      })
                    : _vm._e(),
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(_vm.$t(_vm.statistic.name)),
                    },
                  }),
                ]
              ),
              _vm.statistic.iconUrl
                ? _c("img", { attrs: { src: _vm.statistic.iconUrl, alt: "" } })
                : _vm._e(),
            ]),
            _vm.payment
              ? [
                  _vm.statistic.type === 1 || _vm.statistic.type === 6
                    ? _c("button", { staticClass: "btn-opne-card" }, [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "14",
                              height: "8",
                              viewBox: "0 0 14 8",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M6.99996 7.99211C6.74905 7.99211 6.49818 7.89631 6.30688 7.70511L0.287198 1.68536C-0.0957326 1.30243 -0.0957326 0.681576 0.287198 0.2988C0.669973 -0.0839751 1.29071 -0.0839751 1.67367 0.2988L6.99996 5.6254L12.3263 0.298986C12.7092 -0.0837891 13.3299 -0.0837891 13.7126 0.298986C14.0957 0.681762 14.0957 1.30262 13.7126 1.68555L7.69304 7.70529C7.50165 7.89653 7.25078 7.99211 6.99996 7.99211Z",
                                fill: "#666666",
                              },
                            }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "total" },
                    [
                      _c("format-currency", {
                        attrs: {
                          data:
                            _vm.statistic.amount -
                            _vm.statistic.refunded_amount,
                        },
                      }),
                      _vm.statistic.details
                        ? _c(
                            "button",
                            {
                              class: { detailsIsOpen: _vm.detailsIsOpen },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  _vm.detailsIsOpen = !_vm.detailsIsOpen
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "14",
                                    height: "8",
                                    viewBox: "0 0 14 8",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M6.99996 7.99211C6.74905 7.99211 6.49818 7.89631 6.30688 7.70511L0.287198 1.68536C-0.0957326 1.30243 -0.0957326 0.681576 0.287198 0.2988C0.669973 -0.0839751 1.29071 -0.0839751 1.67367 0.2988L6.99996 5.6254L12.3263 0.298986C12.7092 -0.0837891 13.3299 -0.0837891 13.7126 0.298986C14.0957 0.681762 14.0957 1.30262 13.7126 1.68555L7.69304 7.70529C7.50165 7.89653 7.25078 7.99211 6.99996 7.99211Z",
                                      fill: "#666666",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.detailsIsOpen
                    ? _c(
                        "div",
                        { staticClass: "details w100 mb12" },
                        _vm._l(_vm.statistic.details, function (detail) {
                          return _c(
                            "div",
                            { key: detail.name, staticClass: "flex bet w100" },
                            [
                              _c("div", [_vm._v(_vm._s(detail.name))]),
                              _c("div", [_vm._v(_vm._s(detail.cnt))]),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]
              : [
                  _c("format-currency", {
                    staticClass: "total",
                    attrs: { data: _vm.statistic.amount },
                  }),
                  _c("section", { staticClass: "flex a-end bet mb18" }, [
                    _vm.checkPermissionPath("dashboard.summary_average") &&
                    !_vm.statistic.hide
                      ? _c(
                          "span",
                          { staticClass: "flex average" },
                          [
                            _c("format-currency", {
                              attrs: {
                                data: _vm.statistic.records
                                  ? _vm.statistic.amount / _vm.statistic.records
                                  : 0,
                              },
                            }),
                            _c("span", {
                              staticClass: "mis6",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Average")),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.statistic.traffic
                      ? _c(
                          "span",
                          {
                            staticClass: "traffic flex a-center",
                            class: _vm.statistic.traffic
                              ? _vm.statistic.traffic > 0
                                ? "traffic-up"
                                : "traffic-down"
                              : "zero",
                          },
                          [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  Math.abs(_vm.statistic.traffic) + "%"
                                ),
                              },
                            }),
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "12",
                                  height: "12",
                                  viewBox: "0 0 12 12",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M2.76785 4.71258C2.52372 4.9567 2.13939 4.9567 1.89527 4.71258C1.65939 4.46845 1.65939 4.08412 1.89527 3.84L5.55163 0.183093C5.66489 0.069828 5.82214 0 5.99644 0C6.17073 0 6.32798 0.069828 6.4418 0.183093L10.0982 3.84C10.3423 4.08412 10.3423 4.46845 10.0982 4.71258C9.85403 4.9567 9.4697 4.9567 9.22558 4.71258L6.61609 2.0943L6.61609 11.3891C6.61664 11.7295 6.33733 12 5.99699 12C5.65664 12 5.38613 11.7295 5.38613 11.3891L5.38613 2.09485L2.76785 4.71258Z",
                                    fill:
                                      _vm.statistic.traffic > 0
                                        ? "#369B58"
                                        : "#dd4a36",
                                  },
                                }),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ],
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }