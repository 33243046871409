var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    { attrs: { width: "700px", height: "800px" }, on: { close: _vm.close } },
    [
      _c("div", { staticClass: "popup__content" }, [
        _c("div", { staticClass: "compensation" }, [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "coupon-statistic" }, [
                _c("div", { staticClass: "form-site__content" }, [
                  _c("div", { staticClass: "coupon-statistic__content" }, [
                    _c(
                      "label",
                      {
                        staticClass: "coupon-statistic__choose",
                        class: { active: !_vm.compensationCopy.type },
                        style: { "margin-top": "20px" },
                      },
                      [
                        _c("span", { staticClass: "input-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.compensationCopy.type,
                                expression: "compensationCopy.type",
                              },
                            ],
                            staticClass: "d-none",
                            attrs: {
                              disabled: _vm.compensationCopy.status != -1,
                              type: "radio",
                            },
                            domProps: {
                              value: 0,
                              checked: _vm._q(_vm.compensationCopy.type, 0),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(_vm.compensationCopy, "type", 0)
                              },
                            },
                          }),
                          _c(
                            "span",
                            { staticClass: "input-check__label flex a-center" },
                            [
                              _c(
                                "span",
                                { staticClass: "input-check__mark" },
                                [
                                  !_vm.compensationCopy.type
                                    ? _c("svg-icon", {
                                        attrs: {
                                          name: "ic-check",
                                          width: "21",
                                          height: "16",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("span", {
                                staticClass: "input-check__text",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Gift")),
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "label",
                      {
                        staticClass: "coupon-statistic__choose",
                        class: { active: _vm.compensationCopy.type },
                        style: { "margin-top": "20px" },
                      },
                      [
                        _c("span", { staticClass: "input-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.compensationCopy.type,
                                expression: "compensationCopy.type",
                              },
                            ],
                            staticClass: "d-none",
                            attrs: {
                              disabled: _vm.compensationCopy.status != -1,
                              type: "radio",
                            },
                            domProps: {
                              value: 1,
                              checked: _vm._q(_vm.compensationCopy.type, 1),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(_vm.compensationCopy, "type", 1)
                              },
                            },
                          }),
                          _c(
                            "span",
                            { staticClass: "input-check__label flex a-center" },
                            [
                              _c(
                                "span",
                                { staticClass: "input-check__mark" },
                                [
                                  _vm.compensationCopy.type
                                    ? _c("svg-icon", {
                                        attrs: {
                                          name: "ic-check",
                                          width: "21",
                                          height: "16",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("span", {
                                staticClass: "input-check__text",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Points")),
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "form-site__content" }, [
                    !_vm.compensationCopy.type
                      ? _c(
                          "div",
                          { staticClass: "form-site__field" },
                          [
                            _c("select-category", {
                              attrs: {
                                disabled: _vm.compensationCopy.status != -1,
                                hideTitle: "",
                              },
                              model: {
                                value: _vm.compensationCopy.gift_ids,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.compensationCopy,
                                    "gift_ids",
                                    $$v
                                  )
                                },
                                expression: "compensationCopy.gift_ids",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "form-site__group form-site__group_mob-full",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "form-site__field" },
                        [
                          _c("div", {
                            staticClass: "form-site__label",
                            domProps: { textContent: _vm._s(_vm.$t("Client")) },
                          }),
                          _c("select-client", {
                            attrs: {
                              disabled: _vm.compensationCopy.status != -1,
                            },
                            model: {
                              value: _vm.compensationCopy.client_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.compensationCopy, "client_id", $$v)
                              },
                              expression: "compensationCopy.client_id",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.compensationCopy.type
                        ? _c("div", { staticClass: "form-site__field" }, [
                            _c("div", {
                              staticClass: "form-site__label",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Amount")),
                              },
                            }),
                            _c("div", { staticClass: "form-site__input" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.compensationCopy.amount,
                                    expression: "compensationCopy.amount",
                                  },
                                ],
                                staticClass: "pis24 w100",
                                attrs: {
                                  disabled: _vm.compensationCopy.status != -1,
                                  type: "number",
                                },
                                domProps: {
                                  value: _vm.compensationCopy.amount,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.compensationCopy,
                                      "amount",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  !_vm.mobile
                    ? _c(
                        "div",
                        {
                          staticClass: "form-site__field form-site__field_full",
                          style: {
                            width: "100%",
                            display: "flex",
                            "justify-content": "space-between",
                            "align-items": "end",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "class-reason",
                              style: { width: "48%" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "form-site__label-wrap" },
                                [
                                  _c("div", {
                                    staticClass: "form-site__label",
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Reason")),
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-site-msg" },
                                [
                                  _vm.reload >= 1 &&
                                  _vm.compensationCopy.status == -1
                                    ? _c("select-box", {
                                        key: _vm.compensationCopy.reason,
                                        staticClass: "selectReason",
                                        attrs: {
                                          widthHeader: "290px",
                                          width: "290px",
                                          placeholder: _vm.$t("select"),
                                          isDisabled:
                                            _vm.compensationCopy.status != -1,
                                          unsort: "",
                                          showSearch: "",
                                          options: _vm.reasonsOptions,
                                        },
                                        on: { input: _vm.input },
                                        model: {
                                          value: _vm.compensationCopy.reason,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.compensationCopy,
                                              "reason",
                                              $$v
                                            )
                                          },
                                          expression: "compensationCopy.reason",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.compensationCopy.status != -1 &&
                                  _vm.compensationCopy.reason !== -1
                                    ? _c("div", {
                                        staticClass: "select__head",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.compensationCopy.reason
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.compensationCopy.status != -1 &&
                                  _vm.compensationCopy.reason == -1
                                    ? _c("div", {
                                        staticClass: "select__head",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("has no reason")
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "class-branch",
                              style: { width: "48%" },
                            },
                            [
                              (_vm.compensationCopy.status !== -1 ||
                                _vm.compensationCopy.reason !== -1) &&
                              _vm.branch
                                ? _c("div", {
                                    staticClass: "form-site__label",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "Branch name for realization compensation"
                                        )
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              _vm.compensationCopy.status == -1 &&
                              _vm.compensationCopy.reason != -1
                                ? _c("select-box", {
                                    staticClass: "selectBranch",
                                    attrs: {
                                      placeholder: _vm.$t("select"),
                                      isDisabled:
                                        _vm.compensationCopy.status != -1,
                                      widthHeader: "290px",
                                      width: "290px",
                                      unsort: "",
                                      showSearch: "",
                                      options: _vm.branches,
                                    },
                                    on: { input: _vm.input },
                                    model: {
                                      value: _vm.compensationCopy.branch_id,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.compensationCopy,
                                          "branch_id",
                                          $$v
                                        )
                                      },
                                      expression: "compensationCopy.branch_id",
                                    },
                                  })
                                : _vm._e(),
                              _vm.compensationCopy.status != -1 && _vm.branch
                                ? _c("div", {
                                    staticClass: "select__head",
                                    domProps: {
                                      textContent: _vm._s(_vm.branch.name),
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.mobile
                    ? _c(
                        "div",
                        {
                          staticClass: "form-site__field form-site__field_full",
                          style: {
                            width: "100%",
                            display: "flex",
                            "flex-direction": "column",
                            "row-gap": "30px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "class-reason",
                              style: { width: "100%" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "form-site__label-wrap" },
                                [
                                  _c("div", {
                                    staticClass: "form-site__label",
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Reason")),
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-site-msg" },
                                [
                                  _vm.reload >= 1 &&
                                  _vm.compensationCopy.status == -1
                                    ? _c("select-box", {
                                        key: _vm.compensationCopy.reason,
                                        staticClass: "selectReason",
                                        attrs: {
                                          placeholder: _vm.$t("select"),
                                          isDisabled:
                                            _vm.compensationCopy.status != -1,
                                          unsort: "",
                                          showSearch: "",
                                          options: _vm.reasonsOptions,
                                        },
                                        on: { input: _vm.input },
                                        model: {
                                          value: _vm.compensationCopy.reason,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.compensationCopy,
                                              "reason",
                                              $$v
                                            )
                                          },
                                          expression: "compensationCopy.reason",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.compensationCopy.status != -1 &&
                                  _vm.compensationCopy.reason !== -1
                                    ? _c("div", {
                                        staticClass: "select__head",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.compensationCopy.reason
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.compensationCopy.status != -1 &&
                                  _vm.compensationCopy.reason == -1
                                    ? _c("div", {
                                        staticClass: "select__head",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("has no reason")
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "class-branch",
                              style: { width: "100%" },
                            },
                            [
                              (_vm.compensationCopy.status !== -1 ||
                                _vm.compensationCopy.reason !== -1) &&
                              _vm.branch
                                ? _c("div", {
                                    staticClass: "form-site__label",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "Branch name for realization compensation"
                                        )
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              _vm.compensationCopy.status == -1 &&
                              _vm.compensationCopy.reason != -1
                                ? _c("select-box", {
                                    staticClass: "selectBranch",
                                    attrs: {
                                      placeholder: _vm.$t("select"),
                                      isDisabled:
                                        _vm.compensationCopy.status != -1,
                                      unsort: "",
                                      showSearch: "",
                                      options: _vm.branches,
                                    },
                                    on: { input: _vm.input },
                                    model: {
                                      value: _vm.compensationCopy.branch_id,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.compensationCopy,
                                          "branch_id",
                                          $$v
                                        )
                                      },
                                      expression: "compensationCopy.branch_id",
                                    },
                                  })
                                : _vm._e(),
                              _vm.compensationCopy.status != -1 && _vm.branch
                                ? _c("div", {
                                    staticClass: "select__head",
                                    domProps: {
                                      textContent: _vm._s(_vm.branch.name),
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.compensationCopy.reason === 0
                    ? _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.compensationCopy.reason_text,
                            expression: "compensationCopy.reason_text",
                          },
                        ],
                        staticClass: "reason-text",
                        domProps: { value: _vm.compensationCopy.reason_text },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.compensationCopy,
                              "reason_text",
                              $event.target.value
                            )
                          },
                        },
                      })
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "wrap-compensation-status",
                    style: { "margin-top": "80px", display: "grid" },
                  },
                  [
                    _c("div", {
                      staticClass: "compensation-status",
                      class: [
                        "status-pending",
                        "status-active",
                        "status-redeemed",
                        "status-rejected",
                      ][_vm.compensationCopy.status],
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            [
                              "Pending approval",
                              "Active",
                              "Redeemed",
                              "Rejected",
                            ][_vm.compensationCopy.status]
                          )
                        ),
                      },
                    }),
                  ]
                ),
                _vm.compensation.status === 2
                  ? _c(
                      "div",
                      {
                        style: {
                          "margin-top": "50px",
                          "margin-bottom": "80px",
                        },
                      },
                      [
                        _vm.compensation.redemption_info
                          ? _c(
                              "div",
                              _vm._l(
                                _vm.compensation.redemption_info,
                                function (key, compensationIndex) {
                                  return _c(
                                    "label",
                                    {
                                      key: compensationIndex,
                                      staticClass: "availability-check",
                                      style: { width: "100%" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getOrderDetailsByReedemId(
                                            key.redeemed_order_id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "comp" }, [
                                        _c("span", {
                                          staticClass: "span-reedem-time",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Redeemed at") +
                                                " " +
                                                _vm.filters.date(
                                                  key.redeemed_at,
                                                  "DD/MM/YYYY"
                                                ) +
                                                ", " +
                                                _vm.$t("at") +
                                                " " +
                                                _vm.filters.clockTime(
                                                  key.redeemed_at,
                                                  false
                                                )
                                            ),
                                          },
                                        }),
                                        _vm.restaurantCurrencyInfo == null
                                          ? _c("span", {
                                              staticClass: "span-reedem-amount",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t("Reedem amount") +
                                                    " " +
                                                    "₪" +
                                                    key.redeem_amount
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.restaurantCurrencyInfo !== null
                                          ? _c("span", {
                                              staticClass: "span-reedem-amount",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t("Reedem amount") +
                                                    " " +
                                                    _vm.restaurantCurrencySymbol +
                                                    key.redeem_amount
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                      ]),
                                      _c("br"),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _vm.compensationCopy.status == -1 &&
                _vm.compensationCopy.reason == -1
                  ? _c("span", {
                      style: {
                        color: "#d60b52",
                        display: "flex",
                        "justify-content": "center",
                        "margin-top": "50px",
                        "font-weight": "bold",
                      },
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("Please select reason for compensation")
                        ),
                      },
                    })
                  : _vm._e(),
                _c("div", { staticClass: "form-site" }, [
                  _c(
                    "div",
                    { staticClass: "form-site__btn flex bet md-none" },
                    [
                      _vm.compensationCopy.id && !_vm.compensationCopy.status
                        ? _c("button", {
                            staticClass: "btn-icon btn-icon_light-red",
                            on: { click: _vm.cancel },
                          })
                        : _c("div"),
                      _vm.compensationCopy.status == -1 &&
                      _vm.compensationCopy.reason != -1
                        ? _c("button", {
                            staticClass: "btn",
                            attrs: { type: "submit" },
                            domProps: { textContent: _vm._s(_vm.$t("Save")) },
                            on: { click: _vm.save },
                          })
                        : _vm._e(),
                    ]
                  ),
                ]),
                !_vm.mobile
                  ? _c(
                      "div",
                      {
                        staticClass: "btns",
                        style: {
                          display: "flex",
                          "justify-content": "space-around",
                          width: "100%",
                        },
                      },
                      [
                        _vm.compensation.status !== 2 &&
                        _vm.compensation.status !== -1
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn_border",
                                style: { "margin-bottom": "50px" },
                                on: { click: _vm.deleteCompensation },
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Delete")),
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm.compensation.status === 1
                          ? _c(
                              "button",
                              {
                                staticClass: "btn submit-btn",
                                style: { "margin-bottom": "50px" },
                                on: { click: _vm.reedemCompensation },
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Reedem")),
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _vm.mobile
                  ? _c(
                      "div",
                      {
                        staticClass: "btns",
                        style: {
                          display: "flex",
                          "justify-content": "space-around",
                          width: "100%",

                          "margin-bottom": "80px",
                        },
                      },
                      [
                        _vm.compensation.status !== 2 &&
                        _vm.compensation.status !== -1
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn_border",
                                style: {
                                  "margin-top": "50px",
                                  "margin-bottom": "40px",
                                },
                                on: { click: _vm.deleteCompensation },
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Delete")),
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm.compensation.status === 1
                          ? _c(
                              "button",
                              {
                                staticClass: "btn submit-btn",
                                style: {
                                  "margin-top": "50px",
                                  "margin-bottom": "40px",
                                },
                                on: { click: _vm.reedemCompensation },
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Reedem")),
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _vm.compensationCopy.status == -1 &&
                _vm.compensationCopy.reason != -1
                  ? _c(
                      "div",
                      { staticClass: "new-item__btn d-flex d-md-none" },
                      [
                        _c(
                          "button",
                          { staticClass: "save-btn", on: { click: _vm.save } },
                          [
                            _c("svg-icon", {
                              staticClass: "ic-edit",
                              attrs: {
                                name: "ic-save-btn",
                                width: "93",
                                height: "93",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]
          ),
        ]),
      ]),
      _vm.showOrderDetailsPopup
        ? _c("order-details-popup", {
            attrs: {
              order: _vm.currentOrder,
              show: true,
              showDragonButton: _vm.showDragonButton,
            },
            on: {
              closed: function ($event) {
                _vm.openOrder = false
              },
            },
          })
        : _vm._e(),
      _c("reedemCompensationDialog", {
        attrs: { show: _vm.toReedem, compensation: _vm.compensation },
        on: { close: _vm.closeRedeemAmountDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }