var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "base-dialog",
        { attrs: { width: "500px" }, on: { close: _vm.close } },
        [
          _c("div", { staticClass: "popup__top" }, [
            _c("div", { staticClass: "popup__head" }, [
              _c("div", {
                staticClass: "h1",
                domProps: {
                  textContent: _vm._s(_vm.$t("Order finished successfully")),
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "popup__content" }, [
            _c("div", {
              domProps: {
                textContent: _vm._s(_vm.$t("Order received successfully")),
              },
            }),
            _c("div", { staticClass: "form-site__btn flex bet" }, [
              _c("div"),
              _c(
                "button",
                {
                  staticClass: "btn",
                  attrs: { type: "button" },
                  on: { click: _vm.close },
                },
                [
                  _c("span", {
                    domProps: { textContent: _vm._s(_vm.$t("Ok")) },
                  }),
                ]
              ),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }