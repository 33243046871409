var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    { attrs: { width: "700px" }, on: { close: _vm.close } },
    [
      _c("div", { staticClass: "popup__content" }, [
        _c("div", { staticClass: "compensation" }, [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "coupon-statistic" }, [
                _c("div", { staticClass: "form-site__content" }, [
                  _c(
                    "div",
                    {
                      staticClass: "form-site__group form-site__group_mob-full",
                    },
                    [
                      _c("div", { staticClass: "form-site__field" }, [
                        _c("div", {
                          staticClass: "form-site__label",
                          domProps: { textContent: _vm._s(_vm.$t("Client")) },
                        }),
                        _c("span", {
                          staticStyle: { "font-size": "24px" },
                          domProps: {
                            textContent: _vm._s(
                              _vm.compensationCopy.client_name
                            ),
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "form-site__field" }, [
                        _c("div", {
                          staticClass: "form-site__label",
                          domProps: {
                            textContent: _vm._s(_vm.$t("Benefit amount")),
                          },
                        }),
                        _c("div", { staticClass: "form-site__input" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.compensationCopy.expiration_per_user,
                                expression:
                                  "compensationCopy.expiration_per_user",
                                modifiers: { number: true },
                              },
                            ],
                            staticClass: "pis24 w100",
                            attrs: { type: "number" },
                            domProps: {
                              value: _vm.compensationCopy.expiration_per_user,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.compensationCopy,
                                  "expiration_per_user",
                                  _vm._n($event.target.value)
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "form-site__field form-site__field_full" },
                    [
                      _c("div", { staticClass: "form-site__label-wrap" }, [
                        _c("div", {
                          staticClass: "form-site__label",
                          domProps: { textContent: _vm._s(_vm.$t("Reason")) },
                        }),
                      ]),
                      _c("div", { staticClass: "form-site-msg" }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.compensationCopy.description,
                              expression: "compensationCopy.description",
                            },
                          ],
                          domProps: { value: _vm.compensationCopy.description },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.compensationCopy,
                                "description",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "coupon-statistic__content" }, [
                  _c(
                    "label",
                    {
                      staticClass: "coupon-statistic__choose flex bet md-none",
                      class: { active: !_vm.compensationCopy.type },
                    },
                    [
                      _c("div", { staticClass: "input-check" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.compensationCopy.type,
                              expression: "compensationCopy.type",
                            },
                          ],
                          staticClass: "d-none",
                          attrs: { type: "radio" },
                          domProps: {
                            value: 0,
                            checked: _vm._q(_vm.compensationCopy.type, 0),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.compensationCopy, "type", 0)
                            },
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "input-check__label flex a-center" },
                          [
                            _c("span", {
                              staticClass: "input-check__text",
                              domProps: { textContent: _vm._s(_vm.$t("Gift")) },
                            }),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "form-site" }, [
                  _c("div", { staticClass: "form-site__content" }, [
                    _c(
                      "div",
                      { staticClass: "form-site__field" },
                      [
                        _c("select-category", {
                          attrs: { hideTitle: "" },
                          model: {
                            value: _vm.compensationCopy.gift_ids,
                            callback: function ($$v) {
                              _vm.$set(_vm.compensationCopy, "gift_ids", $$v)
                            },
                            expression: "compensationCopy.gift_ids",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-site__btn flex bet md-none" }, [
                  !_vm.compensationCopy.id ? _c("div") : _vm._e(),
                  !_vm.compensationCopy.status
                    ? _c("button", {
                        staticClass: "btn",
                        attrs: { type: "submit" },
                        domProps: { textContent: _vm._s(_vm.$t("Save")) },
                        on: { click: _vm.save },
                      })
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "btns",
                    style: {
                      display: "flex",
                      "justify-content": "space-around",
                      width: "100%",
                    },
                  },
                  [
                    _vm.compensation.status == 2 && !_vm.mobile
                      ? _c(
                          "button",
                          {
                            staticClass: "btn",
                            style: {
                              "margin-top": "-50px",
                              "margin-bottom": "50px",
                            },
                            on: { click: _vm.deleteCompensation },
                          },
                          [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(_vm.$t("Delete")),
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm.compensation.status == 2 && _vm.mobile
                      ? _c(
                          "button",
                          {
                            staticClass: "btn",
                            style: {
                              "margin-top": "10px",
                              "margin-bottom": "80px",
                            },
                            on: { click: _vm.deleteCompensation },
                          },
                          [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(_vm.$t("Delete")),
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
                  _c(
                    "button",
                    { staticClass: "save-btn", on: { click: _vm.save } },
                    [
                      _c("svg-icon", {
                        staticClass: "ic-edit",
                        attrs: {
                          name: "ic-save-btn",
                          width: "93",
                          height: "93",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }