var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-px", on: { click: _vm.start } }, [
    _c("div", { staticClass: "input-container" }, [
      _c("input", {
        ref: "input",
        attrs: { type: "number" },
        domProps: { value: _vm.num },
        on: {
          focusout: function ($event) {
            _vm.inEdit = false
          },
          input: _vm.validate,
        },
      }),
    ]),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "px" }, [_c("span", [_vm._v(" px ")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }