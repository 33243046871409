var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "history-item",
      class: { open: _vm.opened, inclient: _vm.inclient },
    },
    [
      _c(
        "div",
        { staticClass: "history-item__main", on: { click: _vm.toggle } },
        [
          _c("div", { staticClass: "history-item__info" }, [
            _vm.showOrderInfo
              ? _c("div", { staticClass: "history-item__num" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("Branch order id")) +
                      ": " +
                      _vm._s(_vm.order.branch_order_id || _vm.order.id) +
                      "\n        " +
                      _vm._s(_vm.$t("Daily order id")) +
                      ": " +
                      _vm._s(_vm.order.daily_order_id) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
            _c("div", {
              staticClass: "history-item__num",
              domProps: { textContent: _vm._s(_vm.order.branch_name) },
            }),
            _vm.address && typeof _vm.address === "object"
              ? _c(
                  "div",
                  { staticClass: "history-item__address" },
                  [_c("address-to-span", { attrs: { address: _vm.address } })],
                  1
                )
              : _vm.address && typeof _vm.address === "string"
              ? _c("div", {
                  staticClass: "history-item__address",
                  domProps: { textContent: _vm._s(_vm.address) },
                })
              : _vm._e(),
            _c("div", { staticClass: "history-item__add" }, [
              _vm.showOrderInfo
                ? _c("div", { staticClass: "history-item__add-date" }, [
                    _c("span", {
                      staticClass: "mie8",
                      domProps: {
                        textContent: _vm._s(
                          _vm.filters.date(_vm.order.date, "DD/MM/YY")
                        ),
                      },
                    }),
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.filters.clockTime(_vm.order.date)
                        ),
                      },
                    }),
                  ])
                : _vm._e(),
              _vm.sources[_vm.order.source]
                ? _c(
                    "div",
                    { staticClass: "history-item__cat" },
                    [
                      _c("svg-icon", {
                        staticClass: "icon",
                        attrs: {
                          name:
                            "ic-availability-" +
                            _vm.sourcesLowerCase[_vm.order.source],
                          width: "12",
                          height: "12",
                        },
                      }),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.sources[_vm.order.source]),
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
          !_vm.showOrderInfo
            ? _c("div", { staticClass: "history-item__time-wrap" }, [
                _c("div", {
                  staticClass: "history-item__time",
                  domProps: {
                    textContent: _vm._s(_vm.filters.clockTime(_vm.order.date)),
                  },
                }),
                _c("div", {
                  staticClass: "history-item__date",
                  domProps: {
                    textContent: _vm._s(
                      _vm.filters.date(_vm.order.date, "DD/MM/YY")
                    ),
                  },
                }),
              ])
            : _vm._e(),
          _vm.showOrderInfo
            ? _c("div", { staticClass: "history-item__action flex j-end" }, [
                _c("div", { staticClass: "history-item__price mie24 pt10" }, [
                  _vm._v("₪" + _vm._s(_vm.order.sum)),
                ]),
                _c(
                  "div",
                  { staticClass: "history-item__arr" },
                  [
                    _c("svg-icon", {
                      attrs: {
                        name: "ic-arrow-down",
                        width: "18",
                        height: "10",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm.products
        ? _c(
            "div",
            { staticClass: "history-item__list" },
            [
              _vm._l(_vm.products, function (product, pIndex) {
                return _c(
                  "div",
                  {
                    key: pIndex,
                    staticClass: "history-product",
                    class: { open: _vm.productsToggle[pIndex] },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "history-product__main",
                        on: {
                          click: function ($event) {
                            return _vm.conditionToToggleProduct(product, pIndex)
                          },
                        },
                      },
                      [
                        product.image_variants &&
                        product.image_variants.small &&
                        !(
                          product.image_variants.small.endsWith("/") ||
                          product.image_variants.small.endsWith("-")
                        )
                          ? _c("div", {
                              staticClass: "history-product__img",
                              style:
                                "background-image: url('" +
                                product.image_variants.small +
                                "');",
                            })
                          : product.image &&
                            !(
                              product.image.endsWith("/") ||
                              product.image.endsWith("-")
                            )
                          ? _c("div", {
                              staticClass: "history-product__img",
                              style:
                                "background-image: url('" +
                                product.image.replace(
                                  "/images/menu/",
                                  "/images/menu/sm-"
                                ) +
                                "');",
                            })
                          : _c("div", { staticClass: "history-product__img" }),
                        _c(
                          "div",
                          {
                            staticClass: "history-product__content",
                            class: { inPopup: _vm.inPopup },
                          },
                          [
                            _vm.inPopup
                              ? _c("div", {
                                  staticClass: "history-product__name",
                                  domProps: {
                                    textContent: _vm._s(product.name),
                                  },
                                })
                              : _c(
                                  "div",
                                  { staticClass: "history-product__name" },
                                  [
                                    _c("a", { attrs: { href: product.link } }, [
                                      _vm._v(
                                        _vm._s(product.name) +
                                          " (" +
                                          _vm._s(product.makat) +
                                          ")\n              " +
                                          _vm._s(
                                            product.amount.toString() + "x"
                                          )
                                      ),
                                    ]),
                                  ]
                                ),
                            _vm.inPopup
                              ? _c(
                                  "div",
                                  { staticClass: "history-product__price" },
                                  [
                                    product.extras_categories
                                      ? [
                                          _vm._l(
                                            product.extras_categories,
                                            function (cat) {
                                              return [
                                                _c("div", {
                                                  key: cat.id,
                                                  staticClass: "bold",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.he || !cat.name_en
                                                        ? cat.name
                                                        : cat.name_en
                                                    ),
                                                  },
                                                }),
                                                _vm._l(
                                                  cat.extras,
                                                  function (ex) {
                                                    return [
                                                      _c("div", {
                                                        key: cat.id + ex.name,
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.he ||
                                                              !ex.name_en
                                                              ? ex.name
                                                              : ex.name_en
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  }
                                                ),
                                              ]
                                            }
                                          ),
                                        ]
                                      : _vm._e(),
                                    product.notes
                                      ? _c("div", [
                                          _c("span", { staticClass: "bold" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("Notes")) + ": "
                                            ),
                                          ]),
                                          _vm._v(
                                            _vm._s(product.notes) +
                                              "\n            "
                                          ),
                                        ])
                                      : _vm._e(),
                                    product.diner_name && !_vm.inPopup
                                      ? _c("div", [
                                          _c("span", { staticClass: "bold" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("Diner name")) +
                                                ": "
                                            ),
                                          ]),
                                          _vm._v(
                                            _vm._s(product.diner_name) +
                                              "\n            "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              : product.price >= 0 &&
                                _vm.he &&
                                _vm.restaurantCurrencyInfo === null
                              ? _c("div", {
                                  staticClass: "history-product__price",
                                  staticStyle: { "text-align": "end" },
                                  attrs: { dir: "ltr" },
                                  domProps: {
                                    textContent: _vm._s(
                                      product.status === 2
                                        ? _vm.$t("_Canceled")
                                        : "₪" + product.price
                                    ),
                                  },
                                })
                              : product.price < 0 &&
                                _vm.he &&
                                _vm.restaurantCurrencyInfo === null
                              ? _c("div", {
                                  staticClass: "history-product__price",
                                  staticStyle: { "text-align": "end" },
                                  attrs: { dir: "ltr" },
                                  domProps: {
                                    textContent: _vm._s(
                                      product.status === 2
                                        ? _vm.$t("_Canceled")
                                        : "-₪" + product.price * -1
                                    ),
                                  },
                                })
                              : product.price >= 0 &&
                                !_vm.he &&
                                _vm.restaurantCurrencyInfo === null
                              ? _c("div", {
                                  staticClass: "history-product__price",
                                  staticStyle: { "text-align": "end" },
                                  attrs: { dir: "ltr" },
                                  domProps: {
                                    textContent: _vm._s(
                                      product.status === 2
                                        ? _vm.$t("_Canceled")
                                        : "₪" + product.price
                                    ),
                                  },
                                })
                              : product.price < 0 &&
                                !_vm.he &&
                                _vm.restaurantCurrencyInfo === null
                              ? _c("div", {
                                  staticClass: "history-product__price",
                                  staticStyle: { "text-align": "end" },
                                  attrs: { dir: "ltr" },
                                  domProps: {
                                    textContent: _vm._s(
                                      product.status === 2
                                        ? _vm.$t("_Canceled")
                                        : "-₪" + product.price * -1
                                    ),
                                  },
                                })
                              : product.price >= 0 &&
                                _vm.he &&
                                _vm.restaurantCurrencyInfo !== null
                              ? _c("div", {
                                  staticClass: "history-product__price",
                                  staticStyle: { "text-align": "end" },
                                  attrs: { dir: "ltr" },
                                  domProps: {
                                    textContent: _vm._s(
                                      product.status === 2
                                        ? _vm.$t("_Canceled")
                                        : _vm.restaurantCurrencySymbol +
                                            product.price
                                    ),
                                  },
                                })
                              : product.price < 0 &&
                                _vm.he &&
                                _vm.restaurantCurrencyInfo !== null
                              ? _c("div", {
                                  staticClass: "history-product__price",
                                  staticStyle: { "text-align": "end" },
                                  attrs: { dir: "ltr" },
                                  domProps: {
                                    textContent: _vm._s(
                                      product.status === 2
                                        ? _vm.$t("_Canceled")
                                        : "-" +
                                            _vm.restaurantCurrencySymbol +
                                            product.price * -1
                                    ),
                                  },
                                })
                              : product.price >= 0 &&
                                !_vm.he &&
                                _vm.restaurantCurrencyInfo !== null
                              ? _c("div", {
                                  staticClass: "history-product__price",
                                  staticStyle: { "text-align": "end" },
                                  attrs: { dir: "ltr" },
                                  domProps: {
                                    textContent: _vm._s(
                                      product.status === 2
                                        ? _vm.$t("_Canceled")
                                        : _vm.restaurantCurrencySymbol +
                                            product.price
                                    ),
                                  },
                                })
                              : product.price < 0 &&
                                !_vm.he &&
                                _vm.restaurantCurrencyInfo !== null
                              ? _c("div", {
                                  staticClass: "history-product__price",
                                  staticStyle: { "text-align": "end" },
                                  attrs: { dir: "ltr" },
                                  domProps: {
                                    textContent: _vm._s(
                                      product.status === 2
                                        ? _vm.$t("_Canceled")
                                        : "-" +
                                            _vm.restaurantCurrencySymbol +
                                            product.price * -1
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm.inPopup &&
                            product.price >= 0 &&
                            _vm.he &&
                            _vm.restaurantCurrencyInfo === null
                              ? _c("div", {
                                  staticClass: "popup-price",
                                  domProps: {
                                    textContent: _vm._s(
                                      product.status === 2
                                        ? _vm.$t("_Canceled")
                                        : "₪" + product.price
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm.inPopup &&
                            product.price < 0 &&
                            _vm.he &&
                            _vm.restaurantCurrencyInfo === null
                              ? _c("div", {
                                  staticClass: "popup-price",
                                  domProps: {
                                    textContent: _vm._s(
                                      product.status === 2
                                        ? _vm.$t("_Canceled")
                                        : "₪" + product.price * -1 + "-"
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm.inPopup &&
                            product.price >= 0 &&
                            !_vm.he &&
                            _vm.restaurantCurrencyInfo === null
                              ? _c("div", {
                                  staticClass: "popup-price",
                                  domProps: {
                                    textContent: _vm._s(
                                      product.status === 2
                                        ? _vm.$t("_Canceled")
                                        : "₪" + product.price
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm.inPopup &&
                            product.price < 0 &&
                            !_vm.he &&
                            _vm.restaurantCurrencyInfo === null
                              ? _c("div", {
                                  staticClass: "popup-price",
                                  domProps: {
                                    textContent: _vm._s(
                                      product.status === 2
                                        ? _vm.$t("_Canceled")
                                        : "-₪" + product.price * -1
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm.inPopup &&
                            product.price >= 0 &&
                            _vm.he &&
                            _vm.restaurantCurrencyInfo != null
                              ? _c("div", {
                                  staticClass: "popup-price",
                                  domProps: {
                                    textContent: _vm._s(
                                      product.status === 2
                                        ? _vm.$t("_Canceled")
                                        : _vm.restaurantCurrencySymbol +
                                            product.price
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm.inPopup &&
                            product.price < 0 &&
                            _vm.he &&
                            _vm.restaurantCurrencyInfo != null
                              ? _c("div", {
                                  staticClass: "popup-price",
                                  domProps: {
                                    textContent: _vm._s(
                                      product.status === 2
                                        ? _vm.$t("_Canceled")
                                        : _vm.restaurantCurrencySymbol +
                                            product.price * -1 +
                                            "-"
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm.inPopup &&
                            product.price >= 0 &&
                            !_vm.he &&
                            _vm.restaurantCurrencyInfo != null
                              ? _c("div", {
                                  staticClass: "popup-price",
                                  domProps: {
                                    textContent: _vm._s(
                                      product.status === 2
                                        ? _vm.$t("_Canceled")
                                        : _vm.restaurantCurrencySymbol +
                                            product.price
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm.inPopup &&
                            product.price < 0 &&
                            !_vm.he &&
                            _vm.restaurantCurrencyInfo != null
                              ? _c("div", {
                                  staticClass: "popup-price",
                                  domProps: {
                                    textContent: _vm._s(
                                      product.status === 2
                                        ? _vm.$t("_Canceled")
                                        : "-" +
                                            _vm.restaurantCurrencySymbol +
                                            product.price * -1
                                    ),
                                  },
                                })
                              : product.diner_name ||
                                product.description ||
                                product.description_en ||
                                product.notes ||
                                (product.extras_categories &&
                                  product.extras_categories.length) ||
                                product.children.length
                              ? _c(
                                  "div",
                                  { staticClass: "history-product__arr" },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        name: "ic-arrow-down",
                                        width: "14",
                                        height: "8",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "history-product-list" },
                      [
                        product.description
                          ? _c(
                              "div",
                              { staticClass: "history-product-list__item" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "history-product-list__info" },
                                  [
                                    _c("div", {
                                      staticClass: "history-product-list__text",
                                      domProps: {
                                        textContent: _vm._s(
                                          product.description
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        product.extras_categories
                          ? _c(
                              "div",
                              { staticClass: "history-product-list" },
                              _vm._l(
                                product.extras_categories,
                                function (cat, cIndex) {
                                  return _c(
                                    "div",
                                    {
                                      key: cIndex,
                                      staticClass: "history-product-list__item",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "history-product-list__info",
                                        },
                                        [
                                          _c("div", {
                                            staticClass:
                                              "history-product-list__head",
                                            domProps: {
                                              textContent: _vm._s(cat.name),
                                            },
                                          }),
                                          _vm._l(
                                            cat.extras,
                                            function (extra, eIndex) {
                                              return _c(
                                                "div",
                                                {
                                                  key: eIndex,
                                                  staticClass:
                                                    "history-product-list__text",
                                                },
                                                [
                                                  _c("span", {
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        extra.amount +
                                                          " * " +
                                                          extra.extra_name +
                                                          " "
                                                      ),
                                                    },
                                                  }),
                                                  extra.makat
                                                    ? _c("span", {
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            "(" +
                                                              extra.makat +
                                                              ")"
                                                          ),
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  extra &&
                                                  !_vm.restaurantCurrencyInfo &&
                                                  extra.price >= 0
                                                    ? _c("span", {
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            ": ₪" +
                                                              extra.price +
                                                              " "
                                                          ),
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  extra &&
                                                  !_vm.restaurantCurrencyInfo &&
                                                  extra.price < 0
                                                    ? _c("span", {
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            ": -₪" +
                                                              extra.price * -1 +
                                                              " "
                                                          ),
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  extra &&
                                                  _vm.restaurantCurrencyInfo &&
                                                  extra.price >= 0
                                                    ? _c("span", {
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            ": " +
                                                              _vm.restaurantCurrencySymbol +
                                                              extra.price +
                                                              " "
                                                          ),
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  extra &&
                                                  _vm.restaurantCurrencyInfo &&
                                                  extra.price < 0
                                                    ? _c("span", {
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            ": -" +
                                                              _vm.restaurantCurrencySymbol +
                                                              extra.price * -1 +
                                                              " "
                                                          ),
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                      cat.total
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "history-product-list__price",
                                            },
                                            [
                                              _vm._v(
                                                "\n              + ₪" +
                                                  _vm._s(cat.total) +
                                                  "\n            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _vm._l(
                          product.children,
                          function (caildProduct, pIndex2) {
                            return _c(
                              "div",
                              {
                                key: pIndex2,
                                staticClass: "history-product child",
                                class: {
                                  open: _vm.productsToggleChildren[pIndex][
                                    pIndex2
                                  ],
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "history-product__main",
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleProductsChildren(
                                          pIndex,
                                          pIndex2
                                        )
                                      },
                                    },
                                  },
                                  [
                                    caildProduct.image_variants &&
                                    caildProduct.image_variants.small
                                      ? _c("div", {
                                          staticClass: "history-product__img",
                                          style:
                                            "background-image: url('" +
                                            caildProduct.image_variants.small +
                                            "');",
                                        })
                                      : caildProduct.image
                                      ? _c("div", {
                                          staticClass: "history-product__img",
                                          style:
                                            "background-image: url('" +
                                            caildProduct.image +
                                            "');",
                                        })
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "history-product__content",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "history-product__name",
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: caildProduct.link,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(caildProduct.name) +
                                                    " (" +
                                                    _vm._s(caildProduct.makat) +
                                                    ")"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm.restaurantCurrencyInfo === null
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "history-product__price",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                ₪" +
                                                    _vm._s(caildProduct.price) +
                                                    "\n              "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.restaurantCurrencyInfo !== null
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "history-product__price",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.restaurantCurrencySymbol +
                                                        caildProduct.price
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "history-product__arr",
                                          },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                pun: "",
                                                name: "ic-arrow-down",
                                                width: "14",
                                                height: "8",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "history-product-list" },
                                  [
                                    caildProduct.description
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "history-product-list__item",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "history-product-list__info",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "history-product-list__text",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      caildProduct.description
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    caildProduct.extras_categories
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "history-product-list",
                                          },
                                          _vm._l(
                                            caildProduct.extras_categories,
                                            function (cat, cIndex) {
                                              return _c(
                                                "div",
                                                {
                                                  key: cIndex,
                                                  staticClass:
                                                    "history-product-list__item",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "history-product-list__info",
                                                    },
                                                    [
                                                      _c("div", {
                                                        staticClass:
                                                          "history-product-list__head",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            cat.name
                                                          ),
                                                        },
                                                      }),
                                                      _vm._l(
                                                        cat.extras,
                                                        function (
                                                          extra,
                                                          eIndex
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: eIndex,
                                                              staticClass:
                                                                "history-product-list__text",
                                                            },
                                                            [
                                                              _c("span", {
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s(
                                                                      extra.amount +
                                                                        " * " +
                                                                        extra.extra_name +
                                                                        " "
                                                                    ),
                                                                },
                                                              }),
                                                              extra.makat
                                                                ? _c("span", {
                                                                    domProps: {
                                                                      textContent:
                                                                        _vm._s(
                                                                          "(" +
                                                                            extra.makat +
                                                                            ")"
                                                                        ),
                                                                    },
                                                                  })
                                                                : _vm._e(),
                                                              extra &&
                                                              extra.price !==
                                                                "0.00"
                                                                ? _c("span", {
                                                                    domProps: {
                                                                      textContent:
                                                                        _vm._s(
                                                                          ": ₪" +
                                                                            extra.price
                                                                        ),
                                                                    },
                                                                  })
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                  cat.total
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "history-product-list__price",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                  + ₪" +
                                                              _vm._s(
                                                                cat.total
                                                              ) +
                                                              "\n                "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                    caildProduct.notes
                                      ? _c("div", [
                                          _c("span", { staticClass: "bold" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("Notes")) + ": "
                                            ),
                                          ]),
                                          _vm._v(
                                            _vm._s(caildProduct.notes) +
                                              "\n            "
                                          ),
                                        ])
                                      : _vm._e(),
                                    caildProduct.diner_name
                                      ? _c("div", [
                                          _c("span", { staticClass: "bold" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("Diner name")) +
                                                ": "
                                            ),
                                          ]),
                                          _vm._v(
                                            _vm._s(caildProduct.diner_name) +
                                              "\n            "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        _c(
                          "div",
                          { staticClass: "history-product-list__item" },
                          [
                            _c(
                              "div",
                              { staticClass: "history-product-list__info" },
                              [
                                product.notes
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "history-product-list__text",
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.$t("Notes")) +
                                            ": " +
                                            _vm._s(product.notes) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                product.diner_name
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "history-product-list__text",
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.$t("Diner name")) +
                                            ": " +
                                            _vm._s(product.diner_name) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                      ],
                      2
                    ),
                    _vm._l(product.active_discounts, function (discount) {
                      return _c(
                        "div",
                        { key: discount.id, staticClass: "history-product" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "history-product__content flex a-center",
                              class: { inPopup: _vm.inPopup },
                            },
                            [
                              discount.description.length > 0
                                ? _c("span", {
                                    staticClass: "mie4",
                                    staticStyle: {
                                      color: "#f28f16",
                                      "font-weight": "500",
                                    },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          [
                                            "Promotion",
                                            "Club voucher",
                                            "Valuecard",
                                            "Multipass",
                                            "Round",
                                            "Admin discount",
                                            "Item compensation",
                                            "Free delivery",
                                            "Pizzahut coupons",
                                            "Points",
                                            "Employee meal",
                                          ][discount.type]
                                        )
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              discount.description.length > 0
                                ? _c("span", {
                                    staticClass: "mie4",
                                    domProps: {
                                      textContent: _vm._s(
                                        " - " + discount.description
                                      ),
                                    },
                                  })
                                : _c("span", {
                                    staticClass: "mie4",
                                    staticStyle: {
                                      color: "#f28f16",
                                      "font-weight": "500",
                                    },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          [
                                            "Promotion",
                                            "Club voucher",
                                            "Valuecard",
                                            "Multipass",
                                            "Round",
                                            "Admin discount",
                                            "Item compensation",
                                            "Free delivery",
                                            "Pizzahut coupons",
                                            "Points",
                                            "Employee meal",
                                          ][discount.type]
                                        )
                                      ),
                                    },
                                  }),
                              discount.discount >= 0 &&
                              _vm.he &&
                              _vm.restaurantCurrencyInfo === null
                                ? _c("span", {
                                    staticClass:
                                      "history-product__price mt0 mie4",
                                    domProps: {
                                      textContent: _vm._s(
                                        "₪" + discount.discount
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              discount.discount < 0 &&
                              _vm.he &&
                              _vm.restaurantCurrencyInfo === null
                                ? _c("span", {
                                    staticClass:
                                      "history-product__price mt0 mie4",
                                    domProps: {
                                      textContent: _vm._s(
                                        "₪" + discount.discount * -1 + "-"
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              discount.discount >= 0 &&
                              !_vm.he &&
                              _vm.restaurantCurrencyInfo === null
                                ? _c("span", {
                                    staticClass:
                                      "history-product__price mt0 mie4",
                                    domProps: {
                                      textContent: _vm._s(
                                        "₪" + discount.discount
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              discount.discount < 0 &&
                              !_vm.he &&
                              _vm.restaurantCurrencyInfo === null
                                ? _c("span", {
                                    staticClass:
                                      "history-product__price mt0 mie4",
                                    domProps: {
                                      textContent: _vm._s(
                                        "-₪" + discount.discount * -1
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              discount.discount >= 0 &&
                              _vm.he &&
                              _vm.restaurantCurrencyInfo !== null
                                ? _c("span", {
                                    staticClass:
                                      "history-product__price mt0 mie4",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.restaurantCurrencySymbol +
                                          discount.discount
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              discount.discount < 0 &&
                              _vm.he &&
                              _vm.restaurantCurrencyInfo !== null
                                ? _c("span", {
                                    staticClass:
                                      "history-product__price mt0 mie4",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.restaurantCurrencySymbol +
                                          discount.discount * -1 +
                                          "-"
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              discount.discount >= 0 &&
                              !_vm.he &&
                              _vm.restaurantCurrencyInfo !== null
                                ? _c("span", {
                                    staticClass:
                                      "history-product__price mt0 mie4",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.restaurantCurrencySymbol +
                                          discount.discount
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              discount.discount < 0 &&
                              !_vm.he &&
                              _vm.restaurantCurrencyInfo !== null
                                ? _c("span", {
                                    staticClass:
                                      "history-product__price mt0 mie4",
                                    domProps: {
                                      textContent: _vm._s(
                                        "-" +
                                          _vm.restaurantCurrencySymbol +
                                          discount.discount * -1
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              discount.discount_type
                                ? _c("span", {
                                    staticClass: "history-product__price mt0",
                                    domProps: {
                                      textContent: _vm._s(
                                        "(" + discount.discount_amount + "%)"
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                )
              }),
              _vm._l(_vm.order.cart.active_discounts, function (discount) {
                return _c(
                  "div",
                  { key: discount.id, staticClass: "history-product" },
                  [
                    _c("div", { staticClass: "history-product__main" }, [
                      _c(
                        "div",
                        {
                          staticClass: "history-product__content flex a-center",
                          class: { inPopup: _vm.inPopup },
                        },
                        [
                          discount.description.length > 0
                            ? _c("span", {
                                staticClass: "mie4",
                                staticStyle: {
                                  color: "#f28f16",
                                  "font-weight": "500",
                                },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      [
                                        "Promotion",
                                        "Club voucher",
                                        "Valuecard",
                                        "Multipass",
                                        "Round",
                                        "Admin discount",
                                        "Item compensation",
                                        "Free delivery",
                                        "Pizzahut coupons",
                                        "Points",
                                        "Employee meal",
                                      ][discount.type]
                                    )
                                  ),
                                },
                              })
                            : _vm._e(),
                          discount.description.length > 0
                            ? _c("span", {
                                staticClass: "mie4",
                                domProps: {
                                  textContent: _vm._s(
                                    " - " + discount.description
                                  ),
                                },
                              })
                            : _c("span", {
                                staticClass: "mie4",
                                staticStyle: {
                                  color: "#f28f16",
                                  "font-weight": "500",
                                },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      [
                                        "Promotion",
                                        "Club voucher",
                                        "Valuecard",
                                        "Multipass",
                                        "Round",
                                        "Admin discount",
                                        "Item compensation",
                                        "Free delivery",
                                        "Pizzahut coupons",
                                        "Points",
                                        "Employee meal",
                                      ][discount.type]
                                    )
                                  ),
                                },
                              }),
                          discount.discount >= 0 &&
                          _vm.he &&
                          _vm.restaurantCurrencyInfo === null
                            ? _c("span", {
                                staticClass: "history-product__price mt0 mie4",
                                domProps: {
                                  textContent: _vm._s("₪" + discount.discount),
                                },
                              })
                            : _vm._e(),
                          discount.discount < 0 &&
                          _vm.he &&
                          _vm.restaurantCurrencyInfo === null
                            ? _c("span", {
                                staticClass: "history-product__price mt0 mie4",
                                domProps: {
                                  textContent: _vm._s(
                                    "₪" + discount.discount * -1 + "-"
                                  ),
                                },
                              })
                            : _vm._e(),
                          discount.discount >= 0 &&
                          !_vm.he &&
                          _vm.restaurantCurrencyInfo === null
                            ? _c("span", {
                                staticClass: "history-product__price mt0 mie4",
                                domProps: {
                                  textContent: _vm._s("₪" + discount.discount),
                                },
                              })
                            : _vm._e(),
                          discount.discount < 0 &&
                          !_vm.he &&
                          _vm.restaurantCurrencyInfo === null
                            ? _c("span", {
                                staticClass: "history-product__price mt0 mie4",
                                domProps: {
                                  textContent: _vm._s(
                                    "-₪" + discount.discount * -1
                                  ),
                                },
                              })
                            : _vm._e(),
                          discount.discount >= 0 &&
                          _vm.he &&
                          _vm.restaurantCurrencyInfo !== null
                            ? _c("span", {
                                staticClass: "history-product__price mt0 mie4",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.restaurantCurrencySymbol +
                                      discount.discount
                                  ),
                                },
                              })
                            : _vm._e(),
                          discount.discount < 0 &&
                          _vm.he &&
                          _vm.restaurantCurrencyInfo !== null
                            ? _c("span", {
                                staticClass: "history-product__price mt0 mie4",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.restaurantCurrencySymbol +
                                      discount.discount * -1 +
                                      "-"
                                  ),
                                },
                              })
                            : _vm._e(),
                          discount.discount >= 0 &&
                          !_vm.he &&
                          _vm.restaurantCurrencyInfo !== null
                            ? _c("span", {
                                staticClass: "history-product__price mt0 mie4",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.restaurantCurrencySymbol +
                                      discount.discount
                                  ),
                                },
                              })
                            : _vm._e(),
                          discount.discount < 0 &&
                          !_vm.he &&
                          _vm.restaurantCurrencyInfo !== null
                            ? _c("span", {
                                staticClass: "history-product__price mt0 mie4",
                                domProps: {
                                  textContent: _vm._s(
                                    "-" +
                                      _vm.restaurantCurrencySymbol +
                                      discount.discount * -1
                                  ),
                                },
                              })
                            : _vm._e(),
                          discount.discount_type
                            ? _c("span", {
                                staticClass: "history-product__price mt0",
                                domProps: {
                                  textContent: _vm._s(
                                    "(" + discount.discount_amount + "%)"
                                  ),
                                },
                              })
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]
                )
              }),
              _vm.tipAmount
                ? _c("div", { staticClass: "history-product" }, [
                    _c("div", { staticClass: "history-product__main" }, [
                      _c(
                        "div",
                        {
                          staticClass: "history-product__content flex a-center",
                          class: { inPopup: _vm.inPopup },
                        },
                        [
                          _c("div", {
                            staticClass: "history-product__name mie8",
                            domProps: { textContent: _vm._s(_vm.$t("Tip")) },
                          }),
                          _vm.restaurantCurrencyInfo === null
                            ? _c("div", {
                                staticClass: "history-product__price mb6",
                                domProps: {
                                  textContent: _vm._s("₪" + _vm.tipAmount),
                                },
                              })
                            : _vm._e(),
                          _vm.restaurantCurrencyInfo !== null
                            ? _c("div", {
                                staticClass: "history-product__price mb6",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.restaurantCurrencySymbol + _vm.tipAmount
                                  ),
                                },
                              })
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _c("div", { staticClass: "history-product-list__item" }, [
        _c("div", { staticClass: "history-product-list__info" }, [
          _c("div", { staticClass: "history-product-list__text" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("Send cutlery")) +
                ": " +
                _vm._s(_vm.order.cutlery ? _vm.$t("Yes") : _vm.$t("No")) +
                "\n      "
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }