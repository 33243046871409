var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "score-circle-bg" }, [
    _c(
      "svg",
      {
        staticClass: "score-circle",
        attrs: {
          width: "45",
          height: "45",
          viewBox: "0 0 128 128",
          fill: "none",
        },
      },
      [
        _c("ellipse", {
          attrs: {
            cx: "64",
            cy: "64",
            rx: "60",
            ry: "60",
            stroke: "white",
            "stroke-width": "8",
          },
        }),
        _c("circle", {
          class: _vm.colorClass,
          attrs: {
            cx: "64",
            cy: "64",
            r: "60",
            ry: "60",
            "stroke-width": "8",
            "stroke-dashoffset": "1000",
            "stroke-dasharray": _vm.strokeDasharray,
            "stroke-linecap": "round",
          },
        }),
      ]
    ),
    _c("div", { staticClass: "score-text" }, [
      _c("div", {
        staticClass: "score-count",
        class: _vm.colorClass,
        domProps: {
          textContent: _vm._s(Math.round(_vm.data.score * 100) / 100),
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }