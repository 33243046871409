var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    { staticClass: "edit-contact-dialog", on: { close: _vm.close } },
    [
      _c("div", [
        _c("div", { staticClass: "form-new-user" }, [
          _c(
            "form",
            {
              staticClass: "form-site-wrap",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.save.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "form-site" }, [
                _c(
                  "div",
                  { staticClass: "form-site__content" },
                  [
                    _c("form-site-field-text", {
                      attrs: { focus: "", title: "Phone" },
                      model: {
                        value: _vm.contactInEdit.phone,
                        callback: function ($$v) {
                          _vm.$set(_vm.contactInEdit, "phone", $$v)
                        },
                        expression: "contactInEdit.phone",
                      },
                    }),
                    _c("div", { staticClass: "event-item-title" }, [
                      _c("div", {
                        staticClass: "event-item-name",
                        domProps: { textContent: _vm._s(_vm.$t("Name")) },
                      }),
                      _c("div", {
                        staticClass: "event-item-cb",
                        domProps: { textContent: _vm._s(_vm.$t("sms")) },
                      }),
                      _c("div", {
                        staticClass: "event-item-cb grey",
                        domProps: { textContent: _vm._s(_vm.$t("Push")) },
                      }),
                    ]),
                    _vm._l(_vm.typeOptions, function (type) {
                      return _c(
                        "div",
                        { key: type.value, staticClass: "event-item" },
                        [
                          _c("div", { staticClass: "event-item-name" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t(type.label)) +
                                "\n              "
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "event-item-cb" },
                            [
                              _c("checkbox", {
                                attrs: {
                                  value: _vm.contactInEdit.log_types.includes(
                                    type.value
                                  ),
                                  name: "sms" + type.label,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.setTypeSms($event, type.value)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "event-item-cb" },
                            [
                              _c("checkbox", {
                                attrs: {
                                  disabled: "",
                                  name: "push" + type.label,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "form-site__btn",
                        style: {
                          display: "flex",
                          width: "100%",
                          "justify-content": "space-between",
                          gap: "20px",
                        },
                      },
                      [
                        _vm.contactInEdit.id
                          ? _c("button", {
                              staticClass: "btn btn_border",
                              attrs: { type: "button" },
                              domProps: {
                                textContent: _vm._s(_vm.$t("Delete")),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit(
                                    "remove",
                                    _vm.contactInEdit.id
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                        _c("button", {
                          staticClass: "btn",
                          attrs: { type: "button" },
                          domProps: { textContent: _vm._s(_vm.$t("Save")) },
                          on: { click: _vm.save },
                        }),
                      ]
                    ),
                  ],
                  2
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }