var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "orders-statistics-box flex col relative" }, [
    _c(
      "span",
      { class: "to-end" },
      [
        _c("header", { staticClass: "flex bet" }, [
          _c("span", { staticClass: "flex" }, [
            _c("span", {
              domProps: { textContent: _vm._s(_vm.$t("Sales data")) },
            }),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "row",
                "justify-content": "center",
                gap: "10px",
              },
            },
            [_c("img", { attrs: { src: _vm.ordersIcon, alt: "" } })]
          ),
        ]),
        [
          _c("div", { staticClass: "row-orderso" }, [
            _c("div", { staticClass: "datao" }, [
              _c("table", [
                _c("thead", [
                  _c("tr", [
                    _c("th"),
                    _c("th", [_vm._v(_vm._s(_vm.$t("Count")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("Total")))]),
                  ]),
                ]),
                _c(
                  "tbody",
                  [
                    _c("tr", [
                      _c("td", [_vm._v(_vm._s(_vm.$t("Orders")))]),
                      _c("td", [
                        _vm.statistic.orders_total_count !== null
                          ? _c("span", {
                              staticClass: "total",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.statistic.orders_total_count
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                      _c(
                        "td",
                        [
                          _vm.statistic.all_orders !== null
                            ? _c("format-currency", {
                                staticClass: "total",
                                attrs: { data: _vm.statistic.all_orders },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v(_vm._s(_vm.$t("Open orders")))]),
                      _c("td", [
                        _vm.statistic.open_orders_total_count !== null
                          ? _c("span", {
                              staticClass: "total",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.statistic.open_orders_total_count
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                      _c(
                        "td",
                        [
                          _vm.statistic.open_orders_total_sum !== null
                            ? _c("format-currency", {
                                staticClass: "total",
                                attrs: {
                                  data: _vm.statistic.open_orders_total_sum,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _c("tr", { staticClass: "discounts-row" }, [
                      _c("td", [_vm._v(_vm._s(_vm.$t("Discounts")))]),
                      _c("td", [
                        _vm.totalSummaryDiscounts.total_summary_discounts_amount
                          ? _c("span", {
                              staticClass: "total",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.totalSummaryDiscounts
                                    .total_summary_discounts_amount
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                      _c(
                        "td",
                        [
                          _vm.totalSummaryDiscounts.total_summary_discounts_sum
                            ? _c("format-currency", {
                                staticClass: "total",
                                attrs: {
                                  data: _vm.totalSummaryDiscounts
                                    .total_summary_discounts_sum,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm.statistic.summary_discounts &&
                    _vm.statistic.summary_discounts.length > 0
                      ? [
                          _c("tr", { staticClass: "discounts-row-details" }, [
                            _c("td", { attrs: { colspan: "3" } }, [
                              _c("table", [
                                _c(
                                  "tbody",
                                  _vm._l(
                                    _vm.statistic.summary_discounts,
                                    function (discount, discountIndex) {
                                      return _c("tr", { key: discountIndex }, [
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "tooltip-list-item-type",
                                          },
                                          [
                                            _vm._v(
                                              "\n                          " +
                                                _vm._s(
                                                  _vm.$t(
                                                    _vm.discountTypeName(
                                                      discount.type
                                                    )
                                                  )
                                                ) +
                                                "\n                        "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "tooltip-list-item-value",
                                          },
                                          [
                                            _vm._v(
                                              "\n                          " +
                                                _vm._s(discount.amount) +
                                                "\n                        "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          [
                                            discount.total_discount !== null
                                              ? _c("format-currency", {
                                                  staticClass: "total",
                                                  attrs: {
                                                    data: discount.total_discount,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      : _vm._e(),
                    _c("tr", [
                      _c("td", [_vm._v(_vm._s(_vm.$t("Cancellations")))]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.statistic.canceled_orders_count)),
                      ]),
                      _c(
                        "td",
                        [
                          _c("format-currency", {
                            staticClass: "total",
                            attrs: { data: _vm.statistic.canceled_orders_sum },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v(_vm._s(_vm.$t("Refunds")))]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.statistic.total_refund_count)),
                      ]),
                      _c(
                        "td",
                        [
                          _c("format-currency", {
                            staticClass: "total",
                            attrs: { data: _vm.statistic.total_refund_amount },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v(_vm._s(_vm.$t("New customers")))]),
                      _c("td", [
                        _vm.statistic.new_club_member_count !== null
                          ? _c("span", {
                              staticClass: "total",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.statistic.new_club_member_count
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                      _c(
                        "td",
                        [
                          _vm.statistic.new_club_member_amount !== null
                            ? _c("format-currency", {
                                staticClass: "total",
                                attrs: {
                                  data: _vm.statistic.new_club_member_amount,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.$t("Net")))]),
                    _c(
                      "td",
                      { attrs: { colspan: "2" } },
                      [
                        _vm.statistic.net !== null
                          ? _c("format-currency", {
                              staticClass: "total",
                              attrs: { data: _vm.statistic.net },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.$t("Gross")))]),
                    _c(
                      "td",
                      { attrs: { colspan: "2" } },
                      [
                        _vm.statistic.bruto !== null
                          ? _c("format-currency", {
                              staticClass: "total",
                              attrs: { data: _vm.statistic.bruto },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm.statistic.orders_total_value_sum !== null &&
                  _vm.statistic.orders_total_value_sum !== 0
                    ? _c("tr", [
                        _c("td", [_vm._v(_vm._s(_vm.$t("Coupons net")))]),
                        _c(
                          "td",
                          { attrs: { colspan: "2" } },
                          [
                            _vm.statistic.orders_total_value_sum !== null
                              ? _c("format-currency", {
                                  staticClass: "total",
                                  attrs: {
                                    data: _vm.statistic.orders_total_value_sum,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.statistic.net !== null &&
                  _vm.statistic.orders_total_value_sum !== null &&
                  _vm.statistic.orders_total_value_sum !== 0
                    ? _c("tr", [
                        _c("td", [_vm._v(_vm._s(_vm.$t("Net coupons")))]),
                        _c(
                          "td",
                          { attrs: { colspan: "2" } },
                          [
                            _c("format-currency", {
                              staticClass: "total",
                              attrs: {
                                data:
                                  _vm.statistic.orders_total_value_sum +
                                  _vm.statistic.net,
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("tr", [
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.$t("Total benefits and discounts sum"))
                      ),
                    ]),
                    _c(
                      "td",
                      { attrs: { colspan: "2" } },
                      [
                        _vm.statistic.promotion_discount_amount !== null
                          ? _c("format-currency", {
                              staticClass: "total",
                              attrs: {
                                data: _vm.statistic.promotion_discount_amount,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.$t("Order average")))]),
                    _c(
                      "td",
                      { attrs: { colspan: "2" } },
                      [
                        _vm.statistic.average !== null
                          ? _c("format-currency", {
                              staticClass: "total",
                              attrs: { data: _vm.statistic.average },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.$t("Tip")))]),
                    _c(
                      "td",
                      { attrs: { colspan: "2" } },
                      [
                        _vm.statistic.total_tip !== null
                          ? _c("format-currency", {
                              staticClass: "total",
                              attrs: { data: _vm.statistic.total_tip },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("br"),
            ]),
            _c(
              "div",
              { staticClass: "graphso" },
              [
                _c("chart", {
                  attrs: {
                    ordersBySource: _vm.ordersBySource,
                    ordersByType: _vm.ordersByType,
                    totalOrders: _vm.statistic.all_orders,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }