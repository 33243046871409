var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    { staticClass: "problomatic-shifts-dialog", on: { close: _vm.close } },
    [
      _c(
        "div",
        [
          _c(
            "table-list",
            _vm._b(
              { on: { changeOptions: _vm.changeOptions } },
              "table-list",
              _vm.dataTable,
              false
            )
          ),
        ],
        1
      ),
      _vm.popup == 1
        ? _c("edit-problematic-shift", {
            attrs: { selectedShift: _vm.selectedShift },
            on: { close: _vm.close },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }