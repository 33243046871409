var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: { width: "600px", title: _vm.$t("Message Analytics") },
      on: { close: _vm.close },
    },
    [
      _c("div", { staticClass: "popup__content" }, [
        _c("ul", { staticClass: "sms-analytics-info" }, [
          typeof _vm.analytics.audience_count !== "undefined"
            ? _c("li", [
                _vm._v(
                  _vm._s(
                    _vm.$t("Total customers perceived as relevant for mailing")
                  ) +
                    ": " +
                    _vm._s(_vm.analytics.audience_count)
                ),
              ])
            : _vm._e(),
          typeof _vm.analytics.next_send
            ? _c("li", [
                _vm._v(
                  _vm._s(_vm.$t("Next send")) +
                    ": " +
                    _vm._s(_vm.formattedNextSend)
                ),
              ])
            : _vm._e(),
          typeof _vm.analytics.push_ratio !== "undefined"
            ? _c("li", [
                _vm._v(
                  _vm._s(_vm.$t("How many push")) +
                    ": " +
                    _vm._s(_vm.analytics.push_ratio)
                ),
              ])
            : _vm._e(),
          typeof _vm.analytics.sms_ratio !== "undefined"
            ? _c("li", [
                _vm._v(
                  _vm._s(_vm.$t("How many sms")) +
                    ": " +
                    _vm._s(_vm.analytics.sms_ratio)
                ),
              ])
            : _vm._e(),
          typeof _vm.analytics.unsubscribed_ratio !== "undefined"
            ? _c("li", [
                _vm._v(
                  _vm._s(
                    _vm.$t("Do not approve mailing from the captured audience")
                  ) +
                    ": " +
                    _vm._s(_vm.analytics.unsubscribed_ratio)
                ),
              ])
            : _vm._e(),
        ]),
        _vm.confirmation
          ? _c("div", { staticClass: "form-site__btn" }, [
              _c("button", {
                staticClass: "btn btn_border",
                attrs: { type: "button" },
                domProps: { textContent: _vm._s(_vm.$t("Cancel")) },
                on: { click: _vm.close },
              }),
              _c("button", {
                staticClass: "btn",
                attrs: { type: "button" },
                domProps: { textContent: _vm._s(_vm.$t("Continue")) },
                on: { click: _vm.confirm },
              }),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }