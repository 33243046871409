var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showed
    ? _c(
        "base-dialog",
        {
          staticClass: "reedem-compensation",
          attrs: { width: "500px" },
          on: { close: _vm.close },
        },
        [
          _c("div", { staticClass: "confirmation-dialog" }, [
            _c("div", { staticClass: "popup__top" }, [
              _c("div", { staticClass: "popup__head" }, [
                _c("div", { staticClass: "h2 t-center" }),
                _c("div", {
                  staticClass: "h1",
                  domProps: { textContent: _vm._s(_vm.$t("Redeem points")) },
                }),
                _c(
                  "div",
                  { style: { display: "flex", "margin-right": "5px" } },
                  [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("Amount of points to reedem") + ": "
                        ),
                      },
                    }),
                    _vm.compensation
                      ? _c("span", {
                          style: {
                            "font-weight": "bold ",
                            "margin-right": "5px",
                          },
                          domProps: {
                            textContent: _vm._s(
                              _vm.compensation.amount -
                                _vm.compensation.amount_redeemed
                            ),
                          },
                        })
                      : _vm._e(),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "form-site", style: { "margin-top": "30px" } },
                  [
                    _c("div", { staticClass: "form-site__field" }, [
                      _c("div", { staticClass: "form-site__input" }, [
                        Number === "checkbox"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.amount,
                                  expression: "amount",
                                },
                              ],
                              ref: "inp",
                              staticClass: "input-n",
                              attrs: {
                                placeholder: _vm.$t("Amount"),
                                type: "checkbox",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.amount)
                                  ? _vm._i(_vm.amount, null) > -1
                                  : _vm.amount,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.amount,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.amount = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.amount = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.amount = $$c
                                  }
                                },
                              },
                            })
                          : Number === "radio"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.amount,
                                  expression: "amount",
                                },
                              ],
                              ref: "inp",
                              staticClass: "input-n",
                              attrs: {
                                placeholder: _vm.$t("Amount"),
                                type: "radio",
                              },
                              domProps: { checked: _vm._q(_vm.amount, null) },
                              on: {
                                change: function ($event) {
                                  _vm.amount = null
                                },
                              },
                            })
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.amount,
                                  expression: "amount",
                                },
                              ],
                              ref: "inp",
                              staticClass: "input-n",
                              attrs: {
                                placeholder: _vm.$t("Amount"),
                                type: Number,
                              },
                              domProps: { value: _vm.amount },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.amount = $event.target.value
                                },
                              },
                            }),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "form-site__btn md-none" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn_border-grad",
                    attrs: { type: "button" },
                    on: { click: _vm.close },
                  },
                  [
                    _c("span", {
                      staticClass: "btn__grad-content",
                      domProps: { textContent: _vm._s(_vm.$t("Back")) },
                    }),
                  ]
                ),
                _c("button", {
                  staticClass: "btn btn_green",
                  attrs: { type: "button" },
                  domProps: { textContent: _vm._s(_vm.$t("Ok")) },
                  on: { click: _vm.reedem },
                }),
              ]),
              _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn_border-grad",
                    attrs: { type: "button" },
                    on: { click: _vm.close },
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(_vm.$t("Back")) + "\n          "
                    ),
                  ]
                ),
                _c(
                  "button",
                  { staticClass: "btn btn_green", on: { click: _vm.reedem } },
                  [
                    _vm._v(
                      "\n            " + _vm._s(_vm.$t("Ok")) + "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }