var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "payments",
      class:
        _vm.statisticsBoxBanks || _vm.statisticsBoxMultipass
          ? "open-banks"
          : "",
    },
    [
      _c(
        "table-list",
        _vm._b(
          {
            on: { changeOptions: _vm.changeOptions },
            scopedSlots: _vm._u([
              {
                key: "filter-by-date",
                fn: function () {
                  return [
                    _c("filter-by-date", {
                      model: {
                        value: _vm.dateFilter,
                        callback: function ($$v) {
                          _vm.dateFilter = $$v
                        },
                        expression: "dateFilter",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "filter-by-properties",
                fn: function () {
                  return [
                    _c("filter-by-properties", {
                      staticClass: "md-none",
                      attrs: {
                        properties: _vm.propertiesFilterOptions,
                        typeIsArray: "",
                      },
                      model: {
                        value: _vm.propertiesFilter,
                        callback: function ($$v) {
                          _vm.propertiesFilter = $$v
                        },
                        expression: "propertiesFilter",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "above-tabel",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "statistics-boxes-contain mt12 mb12" },
                      [
                        _vm.checkPermissionPath("payments.summary")
                          ? _c(
                              "div",
                              {
                                staticClass: "statistics-boxes",
                                class: {
                                  opened:
                                    _vm.statisticsBoxBanks ||
                                    _vm.statisticsBoxMultipass,
                                },
                              },
                              _vm._l(_vm.summary, function (statistic) {
                                return _c("statistics-box", {
                                  key: statistic.issuer,
                                  class:
                                    (_vm.statisticsBoxBanks &&
                                      statistic.type === 1) ||
                                    (_vm.statisticsBoxMultipass &&
                                      statistic.type === 6)
                                      ? "open"
                                      : "",
                                  attrs: { statistic: statistic, payment: "" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return function () {
                                        if (statistic.type === 1) {
                                          _vm.statisticsBoxBanks =
                                            !_vm.statisticsBoxBanks
                                        }
                                        if (statistic.type === 6) {
                                          _vm.statisticsBoxMultipass =
                                            !_vm.statisticsBoxMultipass
                                        }
                                      }.apply(null, arguments)
                                    },
                                  },
                                })
                              }),
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.statisticsBoxBanks,
                                expression: "statisticsBoxBanks",
                              },
                            ],
                            staticClass: "statistics-boxes banks",
                          },
                          _vm._l(_vm.summaryBanks, function (statistic) {
                            return _c("statistics-box", {
                              key: statistic.issuer,
                              attrs: { statistic: statistic, payment: "" },
                            })
                          }),
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.statisticsBoxMultipass,
                                expression: "statisticsBoxMultipass",
                              },
                            ],
                            staticClass: "statistics-boxes banks",
                          },
                          _vm._l(_vm.summaryMultipass, function (statistic) {
                            return _c("statistics-box", {
                              key: statistic.issuer,
                              attrs: { statistic: statistic, payment: "" },
                            })
                          }),
                          1
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "filter-text",
                fn: function () {
                  return [
                    _vm.filtersText
                      ? _c("div", {
                          staticClass: "mt12 mb12 md-none",
                          domProps: { textContent: _vm._s(_vm.filtersText) },
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
              {
                key: "filter-list",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "filter-list d-md-none" },
                      [
                        _vm._l(_vm.filters, function (filter, i) {
                          return _c(
                            "div",
                            { key: filter, staticClass: "filter-list-item" },
                            [
                              _c("span", {
                                staticClass: "mie8",
                                domProps: { textContent: _vm._s(filter) },
                              }),
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.closeFilter(i)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "12",
                                        height: "12",
                                        viewBox: "0 0 12 12",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M11.7792 0.219436C11.4866 -0.0731455 11.0173 -0.0731455 10.7248 0.219436L5.9993 4.94491L1.27383 0.219436C0.981252 -0.0731455 0.512017 -0.0731455 0.219436 0.219436C-0.0731454 0.512018 -0.0731454 0.981254 0.219436 1.27384L4.9449 5.99931L0.219436 10.7248C-0.0731454 11.0174 -0.0731454 11.4866 0.219436 11.7792C0.362967 11.9227 0.556181 12 0.743874 12C0.931568 12 1.12478 11.9282 1.26831 11.7792L5.99378 7.05371L10.7192 11.7792C10.8628 11.9227 11.056 12 11.2437 12C11.4369 12 11.6246 11.9282 11.7681 11.7792C12.0607 11.4866 12.0607 11.0174 11.7681 10.7248L7.0537 5.99931L11.7792 1.27384C12.0717 0.981254 12.0717 0.512018 11.7792 0.219436Z",
                                          fill: "#404040",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        _c("div", { staticClass: "space" }, [_vm._v("-")]),
                      ],
                      2
                    ),
                    _c("filter-by-properties", {
                      staticClass: "d-md-none",
                      attrs: {
                        properties: _vm.propertiesFilterOptions,
                        typeIsArray: "",
                      },
                      model: {
                        value: _vm.propertiesFilter,
                        callback: function ($$v) {
                          _vm.propertiesFilter = $$v
                        },
                        expression: "propertiesFilter",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "table-list",
          _vm.tableListData,
          false
        )
      ),
      _vm.statisticsBoxBanks || _vm.statisticsBoxMultipass
        ? _c("div", {
            staticClass: "backround",
            on: {
              click: function ($event) {
                _vm.statisticsBoxBanks = false
                _vm.statisticsBoxMultipass = false
              },
            },
          })
        : _vm._e(),
      _vm.show_order_details
        ? _c("order-details-popup", {
            attrs: { order: _vm.order, show: _vm.show_order_details },
            on: {
              closed: function ($event) {
                _vm.show_order_details = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }