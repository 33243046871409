var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.iconUrls
    ? _c(
        "ul",
        { staticClass: "icon-list  gap4 j-end" },
        [
          _vm.emergency
            ? _c("emergency-icon", { attrs: { emergency: _vm.emergency } })
            : _vm._e(),
          _vm._l(_vm.iconUrls, function (iconUrl, i) {
            return _c("img", { key: i, attrs: { src: iconUrl } })
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }