import { render, staticRenderFns } from "./delivery-notes-payment.vue?vue&type=template&id=75b5f748&scoped=true&"
import script from "./delivery-notes-payment.vue?vue&type=script&lang=js&"
export * from "./delivery-notes-payment.vue?vue&type=script&lang=js&"
import style0 from "./delivery-notes-payment.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./delivery-notes-payment.vue?vue&type=style&index=1&id=75b5f748&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75b5f748",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/dorshoshan/Documents/DEV/Atmos-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('75b5f748')) {
      api.createRecord('75b5f748', component.options)
    } else {
      api.reload('75b5f748', component.options)
    }
    module.hot.accept("./delivery-notes-payment.vue?vue&type=template&id=75b5f748&scoped=true&", function () {
      api.rerender('75b5f748', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/dialogs/business-clients/delivery-notes-payment.vue"
export default component.exports