import { render, staticRenderFns } from "./booking.vue?vue&type=template&id=635ab939&"
import script from "./booking.vue?vue&type=script&lang=ts&"
export * from "./booking.vue?vue&type=script&lang=ts&"
import style0 from "./booking.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/dorshoshan/Documents/DEV/Atmos-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('635ab939')) {
      api.createRecord('635ab939', component.options)
    } else {
      api.reload('635ab939', component.options)
    }
    module.hot.accept("./booking.vue?vue&type=template&id=635ab939&", function () {
      api.rerender('635ab939', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/route-components/settings/booking.vue"
export default component.exports