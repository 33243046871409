var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "orders-statistics-box flex col relative" }, [
    _c(
      "span",
      { class: "to-end" },
      [
        _c("header", { staticClass: "flex bet" }, [
          _c("span", { staticClass: "flex" }, [
            _c("span", {
              domProps: { textContent: _vm._s(_vm.$t("Sales data")) },
            }),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "row",
                "justify-content": "center",
                gap: "10px",
              },
            },
            [_c("img", { attrs: { src: _vm.ordersIcon, alt: "" } })]
          ),
        ]),
        [
          _c("div", { staticClass: "row-orderso" }, [
            _c("div", { staticClass: "datao" }, [
              _c("div", { staticClass: "all_lineo" }, [
                _c("div", { staticClass: "text_line" }, [
                  _c("div", { staticClass: "statistic-text" }, [
                    _c("span", {
                      staticStyle: { "font-weight": "500" },
                      domProps: { textContent: _vm._s(_vm.$t("all orders")) },
                    }),
                  ]),
                  _c(
                    "div",
                    { staticClass: "statistic-amount" },
                    [
                      _vm.statistic.all_orders !== null
                        ? _c("format-currency", {
                            staticClass: "total",
                            attrs: { data: _vm.statistic.all_orders },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm.pastDates && _vm.pastDates.length > 0
                  ? _c("div", { staticClass: "tooltip" }, [
                      _c(
                        "div",
                        {
                          staticClass: "tooltiptext",
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                          },
                        },
                        [
                          _c("div", [
                            _c("span", {
                              staticStyle: { "font-weight": "bold" },
                              domProps: {
                                textContent: _vm._s(_vm.$t("compare dates")),
                              },
                            }),
                          ]),
                          _vm._l(_vm.pastDates, function (date) {
                            return _c("div", [
                              _c("span", { staticStyle: { color: "black" } }, [
                                _vm._v(" " + _vm._s(date)),
                              ]),
                            ])
                          }),
                        ],
                        2
                      ),
                      _c("div", { staticClass: "perstage" }, [
                        _vm.statistic.percentage_total_sum
                          ? _c(
                              "span",
                              {
                                staticClass: "traffic flex a-center",
                                class: _vm.statistic.percentage_total_sum
                                  ? _vm.statistic.percentage_total_sum > 0
                                    ? "traffic-up"
                                    : "traffic-down"
                                  : "zero",
                              },
                              [
                                _c("span", {
                                  staticClass: "mie4",
                                  staticStyle: { color: "#e34836" },
                                  domProps: {
                                    textContent: _vm._s(
                                      Math.abs(
                                        _vm.statistic.percentage_total_sum.toFixed(
                                          1
                                        )
                                      ) + "%"
                                    ),
                                  },
                                }),
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "12",
                                      height: "12",
                                      viewBox: "0 0 12 12",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M2.76785 4.71258C2.52372 4.9567 2.13939 4.9567 1.89527 4.71258C1.65939 4.46845 1.65939 4.08412 1.89527 3.84L5.55163 0.183093C5.66489 0.069828 5.82214 0 5.99644 0C6.17073 0 6.32798 0.069828 6.4418 0.183093L10.0982 3.84C10.3423 4.08412 10.3423 4.46845 10.0982 4.71258C9.85403 4.9567 9.4697 4.9567 9.22558 4.71258L6.61609 2.0943L6.61609 11.3891C6.61664 11.7295 6.33733 12 5.99699 12C5.65664 12 5.38613 11.7295 5.38613 11.3891L5.38613 2.09485L2.76785 4.71258Z",
                                        fill:
                                          _vm.statistic.traffic > 0
                                            ? "#369B58"
                                            : "#dd4a36",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "all_lineo" }, [
                _c("div", { staticClass: "text_line" }, [
                  _c("div", { staticClass: "statistic-text" }, [
                    _c("span", {
                      staticStyle: { "font-weight": "500" },
                      domProps: { textContent: _vm._s(_vm.$t("total orders")) },
                    }),
                  ]),
                  _c("div", { staticClass: "statistic-amount" }, [
                    _vm.statistic.orders_total_count !== null
                      ? _c("span", {
                          staticClass: "total",
                          domProps: {
                            textContent: _vm._s(
                              _vm.statistic.orders_total_count
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "all_lineo" }, [
                _c("div", { staticClass: "text_line" }, [
                  _c("div", { staticClass: "statistic-text" }, [
                    _c("span", {
                      staticStyle: { "font-weight": "500" },
                      domProps: {
                        textContent: _vm._s(_vm.$t("Total open orders")),
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "statistic-amount" }, [
                    _vm.statistic.open_orders_total_count !== null
                      ? _c("span", {
                          staticClass: "total",
                          domProps: {
                            textContent: _vm._s(
                              _vm.statistic.open_orders_total_count
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "all_lineo" }, [
                _c("div", { staticClass: "text_line" }, [
                  _c("div", { staticClass: "statistic-text" }, [
                    _c("span", {
                      staticStyle: { "font-weight": "500" },
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("Total open orders money sum")
                        ),
                      },
                    }),
                  ]),
                  _c(
                    "div",
                    { staticClass: "statistic-amount" },
                    [
                      _vm.statistic.open_orders_total_sum !== null
                        ? _c("format-currency", {
                            staticClass: "total",
                            attrs: {
                              data: _vm.statistic.open_orders_total_sum,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "all_lineo" }, [
                _c("div", { staticClass: "text_line" }, [
                  _c("div", { staticClass: "statistic-text" }, [
                    _c("span", {
                      staticStyle: { "font-weight": "500" },
                      domProps: {
                        textContent: _vm._s(_vm.$t("Order average")),
                      },
                    }),
                  ]),
                  _c(
                    "div",
                    { staticClass: "statistic-amount" },
                    [
                      _vm.statistic.average !== null
                        ? _c("format-currency", {
                            staticClass: "total",
                            attrs: { data: _vm.statistic.average },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "all_lineo" }, [
                _c("div", { staticClass: "text_line" }, [
                  _c("div", { staticClass: "statistic-text" }, [
                    _c("span", {
                      staticStyle: { "font-weight": "500" },
                      domProps: { textContent: _vm._s(_vm.$t("Gross")) },
                    }),
                  ]),
                  _c(
                    "div",
                    { staticClass: "statistic-amount" },
                    [
                      _vm.statistic.bruto !== null
                        ? _c("format-currency", {
                            staticClass: "total",
                            attrs: { data: _vm.statistic.bruto },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm.pastDates && _vm.pastDates.length > 0
                  ? _c("div", { staticClass: "tooltip" }, [
                      _c(
                        "div",
                        {
                          staticClass: "tooltiptext",
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                          },
                        },
                        [
                          _c("div", [
                            _c("span", {
                              staticStyle: { "font-weight": "bold" },
                              domProps: {
                                textContent: _vm._s(_vm.$t("compare dates")),
                              },
                            }),
                          ]),
                          _vm._l(_vm.pastDates, function (date) {
                            return _c("div", [
                              _c("span", { staticStyle: { color: "black" } }, [
                                _vm._v(" " + _vm._s(date)),
                              ]),
                            ])
                          }),
                        ],
                        2
                      ),
                      _c("div", { staticClass: "perstage" }, [
                        _vm.statistic.perstage_bruto
                          ? _c(
                              "span",
                              {
                                staticClass: "traffic flex a-center",
                                class: _vm.statistic.perstage_bruto
                                  ? _vm.statistic.perstage_bruto > 0
                                    ? "traffic-up"
                                    : "traffic-down"
                                  : "zero",
                              },
                              [
                                _c("span", {
                                  staticClass: "mie4",
                                  staticStyle: { color: "#e34836" },
                                  domProps: {
                                    textContent: _vm._s(
                                      Math.abs(
                                        _vm.statistic.perstage_bruto.toFixed(1)
                                      ) + "%"
                                    ),
                                  },
                                }),
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "12",
                                      height: "12",
                                      viewBox: "0 0 12 12",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M2.76785 4.71258C2.52372 4.9567 2.13939 4.9567 1.89527 4.71258C1.65939 4.46845 1.65939 4.08412 1.89527 3.84L5.55163 0.183093C5.66489 0.069828 5.82214 0 5.99644 0C6.17073 0 6.32798 0.069828 6.4418 0.183093L10.0982 3.84C10.3423 4.08412 10.3423 4.46845 10.0982 4.71258C9.85403 4.9567 9.4697 4.9567 9.22558 4.71258L6.61609 2.0943L6.61609 11.3891C6.61664 11.7295 6.33733 12 5.99699 12C5.65664 12 5.38613 11.7295 5.38613 11.3891L5.38613 2.09485L2.76785 4.71258Z",
                                        fill:
                                          _vm.statistic.traffic > 0
                                            ? "#369B58"
                                            : "#dd4a36",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "all_lineo" }, [
                _c("div", { staticClass: "text_line" }, [
                  _c("div", { staticClass: "statistic-text" }, [
                    _c("span", {
                      staticStyle: { "font-weight": "500" },
                      domProps: { textContent: _vm._s(_vm.$t("Net")) },
                    }),
                  ]),
                  _c(
                    "div",
                    { staticClass: "statistic-amount" },
                    [
                      _vm.statistic.net !== null
                        ? _c("format-currency", {
                            staticClass: "total",
                            attrs: { data: _vm.statistic.net },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm.pastDates && _vm.pastDates.length > 0
                  ? _c("div", { staticClass: "tooltip" }, [
                      _c(
                        "div",
                        {
                          staticClass: "tooltiptext",
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                          },
                        },
                        [
                          _c("div", [
                            _c("span", {
                              staticStyle: { "font-weight": "bold" },
                              domProps: {
                                textContent: _vm._s(_vm.$t("compare dates")),
                              },
                            }),
                          ]),
                          _vm._l(_vm.pastDates, function (date) {
                            return _c("div", [
                              _c("span", { staticStyle: { color: "black" } }, [
                                _vm._v(" " + _vm._s(date)),
                              ]),
                            ])
                          }),
                        ],
                        2
                      ),
                      _c("div", { staticClass: "perstage" }, [
                        _vm.statistic.percentage_total_net
                          ? _c(
                              "span",
                              {
                                staticClass: "traffic flex a-center",
                                class: _vm.statistic.percentage_total_net
                                  ? _vm.statistic.percentage_total_net > 0
                                    ? "traffic-up"
                                    : "traffic-down"
                                  : "zero",
                              },
                              [
                                _c("span", {
                                  staticClass: "mie4",
                                  staticStyle: { color: "#e34836" },
                                  domProps: {
                                    textContent: _vm._s(
                                      Math.abs(
                                        _vm.statistic.percentage_total_net.toFixed(
                                          1
                                        )
                                      ) + "%"
                                    ),
                                  },
                                }),
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "12",
                                      height: "12",
                                      viewBox: "0 0 12 12",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M2.76785 4.71258C2.52372 4.9567 2.13939 4.9567 1.89527 4.71258C1.65939 4.46845 1.65939 4.08412 1.89527 3.84L5.55163 0.183093C5.66489 0.069828 5.82214 0 5.99644 0C6.17073 0 6.32798 0.069828 6.4418 0.183093L10.0982 3.84C10.3423 4.08412 10.3423 4.46845 10.0982 4.71258C9.85403 4.9567 9.4697 4.9567 9.22558 4.71258L6.61609 2.0943L6.61609 11.3891C6.61664 11.7295 6.33733 12 5.99699 12C5.65664 12 5.38613 11.7295 5.38613 11.3891L5.38613 2.09485L2.76785 4.71258Z",
                                        fill:
                                          _vm.statistic.traffic > 0
                                            ? "#369B58"
                                            : "#dd4a36",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm.statistic.orders_total_value_sum !== null &&
              _vm.statistic.orders_total_value_sum !== 0
                ? _c("div", { staticClass: "all_lineo" }, [
                    _c("div", { staticClass: "text_line" }, [
                      _c("div", { staticClass: "statistic-text" }, [
                        _c("span", {
                          staticStyle: { "font-weight": "500" },
                          domProps: {
                            textContent: _vm._s(_vm.$t("Coupons net")),
                          },
                        }),
                      ]),
                      _c(
                        "div",
                        { staticClass: "statistic-amount" },
                        [
                          _vm.statistic.orders_total_value_sum !== null
                            ? _c("format-currency", {
                                staticClass: "total",
                                attrs: {
                                  data: _vm.statistic.orders_total_value_sum,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm.pastDates && _vm.pastDates.length > 0
                      ? _c("div", { staticClass: "tooltip" }, [
                          _c(
                            "div",
                            {
                              staticClass: "tooltiptext",
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "column",
                              },
                            },
                            [
                              _c("div", [
                                _c("span", {
                                  staticStyle: { "font-weight": "bold" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("compare dates")
                                    ),
                                  },
                                }),
                              ]),
                              _vm._l(_vm.pastDates, function (date) {
                                return _c("div", [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "black" } },
                                    [_vm._v(" " + _vm._s(date))]
                                  ),
                                ])
                              }),
                            ],
                            2
                          ),
                          _c("div", { staticClass: "perstage" }, [
                            _vm.statistic.per_orders_total_value_sum
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "traffic flex a-center",
                                    class: _vm.statistic
                                      .per_orders_total_value_sum
                                      ? _vm.statistic
                                          .per_orders_total_value_sum > 0
                                        ? "traffic-up"
                                        : "traffic-down"
                                      : "zero",
                                  },
                                  [
                                    _vm.statistic.per_orders_total_value_sum
                                      ? _c("span", {
                                          staticClass: "mie4",
                                          staticStyle: { color: "#e34836" },
                                          domProps: {
                                            textContent: _vm._s(
                                              Math.abs(
                                                _vm.statistic.per_orders_total_value_sum.toFixed(
                                                  1
                                                )
                                              ) + "%"
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          width: "12",
                                          height: "12",
                                          viewBox: "0 0 12 12",
                                          fill: "none",
                                          xmlns: "http://www.w3.org/2000/svg",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M2.76785 4.71258C2.52372 4.9567 2.13939 4.9567 1.89527 4.71258C1.65939 4.46845 1.65939 4.08412 1.89527 3.84L5.55163 0.183093C5.66489 0.069828 5.82214 0 5.99644 0C6.17073 0 6.32798 0.069828 6.4418 0.183093L10.0982 3.84C10.3423 4.08412 10.3423 4.46845 10.0982 4.71258C9.85403 4.9567 9.4697 4.9567 9.22558 4.71258L6.61609 2.0943L6.61609 11.3891C6.61664 11.7295 6.33733 12 5.99699 12C5.65664 12 5.38613 11.7295 5.38613 11.3891L5.38613 2.09485L2.76785 4.71258Z",
                                            fill:
                                              _vm.statistic.traffic > 0
                                                ? "#369B58"
                                                : "#dd4a36",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm.statistic.net !== null &&
              _vm.statistic.orders_total_value_sum !== null &&
              _vm.statistic.orders_total_value_sum !== 0
                ? _c("div", { staticClass: "all_lineo" }, [
                    _c("div", { staticClass: "text_line" }, [
                      _c("div", { staticClass: "statistic-text" }, [
                        _c("span", {
                          staticStyle: { "font-weight": "500" },
                          domProps: {
                            textContent: _vm._s(_vm.$t("Net coupons")),
                          },
                        }),
                      ]),
                      _c(
                        "div",
                        { staticClass: "statistic-amount" },
                        [
                          _c("format-currency", {
                            staticClass: "total",
                            attrs: {
                              data:
                                _vm.statistic.orders_total_value_sum +
                                _vm.statistic.net,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm.pastDates && _vm.pastDates.length > 0
                      ? _c("div", { staticClass: "tooltip" }, [
                          _c(
                            "div",
                            {
                              staticClass: "tooltiptext",
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "column",
                              },
                            },
                            [
                              _c("div", [
                                _c("span", {
                                  staticStyle: { "font-weight": "bold" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("compare dates")
                                    ),
                                  },
                                }),
                              ]),
                              _vm._l(_vm.pastDates, function (date) {
                                return _c("div", [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "black" } },
                                    [_vm._v(" " + _vm._s(date))]
                                  ),
                                ])
                              }),
                            ],
                            2
                          ),
                          _c("div", { staticClass: "perstage" }, [
                            _vm.statistic.per_net_coupons
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "traffic flex a-center",
                                    class: _vm.statistic.per_net_coupons
                                      ? _vm.statistic.per_net_coupons > 0
                                        ? "traffic-up"
                                        : "traffic-down"
                                      : "zero",
                                  },
                                  [
                                    _vm.statistic.per_net_coupons
                                      ? _c("span", {
                                          staticClass: "mie4",
                                          staticStyle: { color: "#e34836" },
                                          domProps: {
                                            textContent: _vm._s(
                                              Math.abs(
                                                _vm.statistic.per_net_coupons.toFixed(
                                                  1
                                                )
                                              ) + "%"
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          width: "12",
                                          height: "12",
                                          viewBox: "0 0 12 12",
                                          fill: "none",
                                          xmlns: "http://www.w3.org/2000/svg",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M2.76785 4.71258C2.52372 4.9567 2.13939 4.9567 1.89527 4.71258C1.65939 4.46845 1.65939 4.08412 1.89527 3.84L5.55163 0.183093C5.66489 0.069828 5.82214 0 5.99644 0C6.17073 0 6.32798 0.069828 6.4418 0.183093L10.0982 3.84C10.3423 4.08412 10.3423 4.46845 10.0982 4.71258C9.85403 4.9567 9.4697 4.9567 9.22558 4.71258L6.61609 2.0943L6.61609 11.3891C6.61664 11.7295 6.33733 12 5.99699 12C5.65664 12 5.38613 11.7295 5.38613 11.3891L5.38613 2.09485L2.76785 4.71258Z",
                                            fill:
                                              _vm.statistic.traffic > 0
                                                ? "#369B58"
                                                : "#dd4a36",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "all_lineo" }, [
                _c("div", { staticClass: "text_line" }, [
                  _c("div", { staticClass: "statistic-text" }, [
                    _c("span", {
                      staticClass: "total",
                      staticStyle: { "font-weight": "500" },
                      domProps: {
                        textContent: _vm._s(_vm.$t("total refunds")),
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "statistic-amount" }, [
                    _vm.refunds.canceled_orders_count !== null
                      ? _c("span", {
                          staticClass: "total",
                          domProps: {
                            textContent: _vm._s(
                              _vm.refunds.canceled_orders_count
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "all_lineo" }, [
                _c("div", { staticClass: "text_line" }, [
                  _c("div", { staticClass: "statistic-text" }, [
                    _c("span", {
                      staticStyle: { "font-weight": "500" },
                      domProps: {
                        textContent: _vm._s(_vm.$t("total all refunds")),
                      },
                    }),
                  ]),
                  _c(
                    "div",
                    { staticClass: "statistic-amount" },
                    [
                      _vm.refunds.canceled_orders_sum !== null
                        ? _c("format-currency", {
                            staticClass: "total",
                            attrs: { data: _vm.refunds.canceled_orders_sum },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "all_lineo" }, [
                _c("div", { staticClass: "text_line" }, [
                  _c("div", { staticClass: "statistic-text" }, [
                    _c("span", {
                      staticStyle: { "font-weight": "500" },
                      domProps: { textContent: _vm._s(_vm.$t("Tip")) },
                    }),
                  ]),
                  _c(
                    "div",
                    { staticClass: "statistic-amount" },
                    [
                      _vm.statistic.total_tip !== null
                        ? _c("format-currency", {
                            staticClass: "total",
                            attrs: { data: _vm.statistic.total_tip },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm.pastDates && _vm.pastDates.length > 0
                  ? _c("div", { staticClass: "tooltip" }, [
                      _c(
                        "div",
                        {
                          staticClass: "tooltiptext",
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                          },
                        },
                        [
                          _c("div", [
                            _c("span", {
                              staticStyle: { "font-weight": "bold" },
                              domProps: {
                                textContent: _vm._s(_vm.$t("compare dates")),
                              },
                            }),
                          ]),
                          _vm._l(_vm.pastDates, function (date) {
                            return _c("div", [
                              _c("span", { staticStyle: { color: "black" } }, [
                                _vm._v(" " + _vm._s(date)),
                              ]),
                            ])
                          }),
                        ],
                        2
                      ),
                      _c("div", { staticClass: "perstage" }, [
                        _vm.statistic.percentage_total_tip
                          ? _c(
                              "span",
                              {
                                staticClass: "traffic flex a-center",
                                class: _vm.statistic.percentage_total_tip
                                  ? _vm.statistic.percentage_total_tip > 0
                                    ? "traffic-up"
                                    : "traffic-down"
                                  : "zero",
                              },
                              [
                                _vm.statistic.percentage_total_tip
                                  ? _c("span", {
                                      staticClass: "mie4",
                                      staticStyle: { color: "#e34836" },
                                      domProps: {
                                        textContent: _vm._s(
                                          Math.abs(
                                            _vm.statistic.percentage_total_tip.toFixed(
                                              1
                                            )
                                          ) + "%"
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "12",
                                      height: "12",
                                      viewBox: "0 0 12 12",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M2.76785 4.71258C2.52372 4.9567 2.13939 4.9567 1.89527 4.71258C1.65939 4.46845 1.65939 4.08412 1.89527 3.84L5.55163 0.183093C5.66489 0.069828 5.82214 0 5.99644 0C6.17073 0 6.32798 0.069828 6.4418 0.183093L10.0982 3.84C10.3423 4.08412 10.3423 4.46845 10.0982 4.71258C9.85403 4.9567 9.4697 4.9567 9.22558 4.71258L6.61609 2.0943L6.61609 11.3891C6.61664 11.7295 6.33733 12 5.99699 12C5.65664 12 5.38613 11.7295 5.38613 11.3891L5.38613 2.09485L2.76785 4.71258Z",
                                        fill:
                                          _vm.statistic.traffic > 0
                                            ? "#369B58"
                                            : "#dd4a36",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c("br"),
            ]),
            _c(
              "div",
              { staticClass: "graphso" },
              [
                _c("chart", {
                  attrs: {
                    ordersBySource: _vm.ordersBySource,
                    ordersByType: _vm.ordersByType,
                    totalOrders: _vm.statistic.all_orders,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }