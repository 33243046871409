var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "base-dialog",
        { attrs: { title: _vm.title }, on: { close: _vm.close } },
        [
          _c("div", { staticClass: "popup__content pb40" }, [
            _c("div", { domProps: { innerHTML: _vm._s(_vm.message) } }),
            _c("div", { staticClass: "form-site__btn flex j-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn",
                  attrs: { type: "button" },
                  on: { click: _vm.close },
                },
                [
                  _c("span", {
                    domProps: { textContent: _vm._s(_vm.$t("Ok")) },
                  }),
                ]
              ),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }