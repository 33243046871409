var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      staticClass: "preview-report-dialog",
      attrs: { width: "85vw", overflowVisible: "" },
      on: { close: _vm.close },
    },
    [
      _c(
        "button",
        {
          staticClass: "btn btn_red",
          on: {
            click: function ($event) {
              return _vm.exportToPDF()
            },
          },
        },
        [_c("span", { domProps: { textContent: _vm._s(_vm.$t("Download")) } })]
      ),
      _c("div", { staticClass: "preview-pdf" }, [
        _vm.data.pages.length
          ? _c(
              "div",
              { attrs: { id: "export-pdf" } },
              [
                _c(
                  "div",
                  { ref: "title", staticClass: "preview-title" },
                  [
                    _c("h1", { staticClass: "preview-h1" }, [
                      _vm._v(_vm._s(_vm.data.title)),
                    ]),
                    _c("hr"),
                    _vm._l(_vm.data.extra_details, function (text) {
                      return _c("span", [_vm._v(_vm._s(text))])
                    }),
                  ],
                  2
                ),
                _vm._l(_vm.data.pages, function (page) {
                  return _c(
                    "div",
                    {
                      ref: "tables",
                      refInFor: true,
                      staticClass: "preview-table-container",
                    },
                    [
                      _c("h3", [_vm._v(_vm._s(page.subtitle))]),
                      _c("table", { staticClass: "preview-table" }, [
                        _c("thead", [
                          _c(
                            "tr",
                            { staticClass: "preview-tr" },
                            _vm._l(page.rows[0], function (column) {
                              return _c("th", { staticClass: "preview-th" }, [
                                _vm._v(_vm._s(column)),
                              ])
                            }),
                            0
                          ),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(page.rows.slice(1), function (row) {
                            return _c(
                              "tr",
                              { staticClass: "preview-tr" },
                              _vm._l(row, function (value) {
                                return _c("td", { staticClass: "preview-td" }, [
                                  _vm._v(_vm._s(value)),
                                ])
                              }),
                              0
                            )
                          }),
                          0
                        ),
                      ]),
                      _c("hr", { staticClass: "preview-end-page" }),
                    ]
                  )
                }),
              ],
              2
            )
          : _vm._e(),
      ]),
      _vm.data.pages.length === 0
        ? _c("div", [_vm._v("\n    לא קיימות רשומות מתאימות\n  ")])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }