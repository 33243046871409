var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "edit-permissions__content" }, [
    _vm.hasCurrentPolicy
      ? _c("div", { staticClass: "input-check" }, [
          _c(
            "label",
            { staticClass: "edit-permissions__title" },
            [
              _c("checkbox", {
                staticClass: "cb",
                attrs: { name: "cb", disabled: _vm.checkboxDisabled("view") },
                on: {
                  input: function ($event) {
                    return _vm.checkboxChanged()
                  },
                },
                model: {
                  value: _vm.permissionByKeyword.view,
                  callback: function ($$v) {
                    _vm.$set(_vm.permissionByKeyword, "view", $$v)
                  },
                  expression: "permissionByKeyword.view",
                },
              }),
              _c("span", {
                staticClass: "edit-permissions__title-text",
                attrs: { title: _vm.keyword },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(_vm.filters.titleCase(_vm.keyword))
                  ),
                },
              }),
              _vm.checkboxDisabled("view")
                ? _c("role-permission-label")
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.viewIsActive,
                  expression: "viewIsActive",
                },
              ],
              staticClass: "choose-promo__check",
            },
            [
              _vm._l(_vm.policyKeys, function (key) {
                return [
                  key !== "view" &&
                  typeof _vm.permissionByKeyword[key] === "boolean"
                    ? _c("div", { key: key, staticClass: "input-check" }, [
                        key === "all"
                          ? _c(
                              "label",
                              { staticClass: "input-check__label" },
                              [
                                _c("checkbox", {
                                  staticClass: "cb",
                                  attrs: {
                                    name: "cb",
                                    disabled: _vm.checkboxDisabled(key),
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.changeAll(
                                        _vm.permissionByKeyword
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.permissionByKeyword[key],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.permissionByKeyword,
                                        key,
                                        $$v
                                      )
                                    },
                                    expression: "permissionByKeyword[key]",
                                  },
                                }),
                                _c("span", {
                                  staticClass: "input-check__text",
                                  attrs: { title: key },
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("All")),
                                  },
                                }),
                                _vm.checkboxDisabled(key)
                                  ? _c("role-permission-label")
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "label",
                          { staticClass: "input-check__label" },
                          [
                            key !== "all" && _vm.keyword !== "catalog_editor"
                              ? [
                                  _c("checkbox", {
                                    staticClass: "cb",
                                    attrs: {
                                      name: "cb",
                                      disabled: _vm.checkboxDisabled(key),
                                    },
                                    on: { input: _vm.checkboxChanged },
                                    model: {
                                      value: _vm.permissionByKeyword[key],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.permissionByKeyword,
                                          key,
                                          $$v
                                        )
                                      },
                                      expression: "permissionByKeyword[key]",
                                    },
                                  }),
                                  _c("span", {
                                    staticClass: "input-check__text",
                                    attrs: { title: key },
                                    domProps: {
                                      textContent: _vm._s(_vm.keyName(key)),
                                    },
                                  }),
                                  _vm.checkboxDisabled(key)
                                    ? _c("role-permission-label")
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                            _vm.keyword === "catalog_editor" &&
                            (key === "full_access" ||
                              key === "items_availability")
                              ? [
                                  _c("checkbox", {
                                    staticClass: "cb",
                                    attrs: {
                                      name: "cb",
                                      disabled: _vm.checkboxDisabled(key),
                                    },
                                    on: { input: _vm.checkboxChanged },
                                    model: {
                                      value: _vm.permissionByKeyword[key],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.permissionByKeyword,
                                          key,
                                          $$v
                                        )
                                      },
                                      expression: "permissionByKeyword[key]",
                                    },
                                  }),
                                  _c("span", {
                                    staticClass: "input-check__text",
                                    attrs: { title: key },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(_vm.filters.titleCase(key))
                                      ),
                                    },
                                  }),
                                  _vm.checkboxDisabled(key)
                                    ? _c("role-permission-label")
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ])
                    : typeof _vm.permissionByKeyword[key] === "object"
                    ? _c("div", { staticClass: "input-check has-inner" }, [
                        _vm.hasView(_vm.permissionByKeyword[key])
                          ? _c(
                              "label",
                              { staticClass: "edit-permissions__title" },
                              [
                                _c("checkbox", {
                                  staticClass: "cb",
                                  attrs: {
                                    name: "cb",
                                    disabled: _vm.checkboxDisabled(key, "view"),
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.checkboxChanged()
                                    },
                                  },
                                  model: {
                                    value: _vm.permissionByKeyword[key].view,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.permissionByKeyword[key],
                                        "view",
                                        $$v
                                      )
                                    },
                                    expression: "permissionByKeyword[key].view",
                                  },
                                }),
                                _c("span", {
                                  staticClass: "edit-permissions__title-text",
                                  attrs: { title: key },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(_vm.filters.titleCase(key))
                                    ),
                                  },
                                }),
                                _vm.checkboxDisabled(key, "view")
                                  ? _c("role-permission-label")
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.permissionByKeyword[key].view,
                                expression: "permissionByKeyword[key].view",
                              },
                            ],
                            staticClass: "choose-promo__check",
                          },
                          [
                            _vm._l(
                              Object.keys(_vm.permissionByKeyword[key]),
                              function (innerKeyword) {
                                return [
                                  innerKeyword !== "view"
                                    ? _c(
                                        "div",
                                        {
                                          key: innerKeyword,
                                          staticClass: "input-check",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "input-check__label",
                                            },
                                            [
                                              innerKeyword !== "all"
                                                ? _c("checkbox", {
                                                    staticClass: "cb",
                                                    attrs: {
                                                      name: "cb",
                                                      disabled:
                                                        _vm.checkboxDisabled(
                                                          key,
                                                          innerKeyword
                                                        ),
                                                    },
                                                    on: {
                                                      input:
                                                        _vm.checkboxChanged,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.permissionByKeyword[
                                                          key
                                                        ][innerKeyword],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm
                                                            .permissionByKeyword[
                                                            key
                                                          ],
                                                          innerKeyword,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "permissionByKeyword[key][innerKeyword]",
                                                    },
                                                  })
                                                : _vm._e(),
                                              innerKeyword == "all"
                                                ? _c("checkbox", {
                                                    staticClass: "cb",
                                                    attrs: {
                                                      name: "cb",
                                                      disabled:
                                                        _vm.checkboxDisabled(
                                                          key,
                                                          innerKeyword
                                                        ),
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.changeAll(
                                                          _vm
                                                            .permissionByKeyword[
                                                            key
                                                          ]
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.permissionByKeyword[
                                                          key
                                                        ][innerKeyword],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm
                                                            .permissionByKeyword[
                                                            key
                                                          ],
                                                          innerKeyword,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "permissionByKeyword[key][innerKeyword]",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _c("span", {
                                                staticClass:
                                                  "input-check__text",
                                                attrs: { title: innerKeyword },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      _vm.filters.titleCase(
                                                        innerKeyword
                                                      )
                                                    )
                                                  ),
                                                },
                                              }),
                                              _vm.checkboxDisabled(
                                                key,
                                                innerKeyword
                                              )
                                                ? _c("role-permission-label")
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ])
      : _c("div", { staticClass: "input-check" }, [
          _c(
            "label",
            { staticClass: "edit-permissions__title" },
            [
              _c("checkbox", {
                staticClass: "cb",
                attrs: { name: "cb", disabled: _vm.checkboxDisabled() },
                on: {
                  input: function ($event) {
                    return _vm.checkboxChanged()
                  },
                },
                model: {
                  value: _vm.permissionByKeyword,
                  callback: function ($$v) {
                    _vm.permissionByKeyword = $$v
                  },
                  expression: "permissionByKeyword",
                },
              }),
              _c("span", {
                staticClass: "edit-permissions__title-text",
                attrs: { title: _vm.keyword },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(_vm.filters.titleCase(_vm.keyword))
                  ),
                },
              }),
              _vm.checkboxDisabled() ? _c("role-permission-label") : _vm._e(),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }