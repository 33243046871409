var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "dashbourd-graph-box",
      class: {
        "card-graph_red": (_vm.productIdx + 1) % 2 === 0,
        "card-graph_dark-orange": (_vm.productIdx + 1) % 3 === 0,
      },
    },
    [
      _c("div", { staticClass: "card-graph" }, [
        _c("div", { staticClass: "card-graph__content" }, [
          _c("div", { staticClass: "card-graph__text d-md-none" }, [
            _vm._v(
              _vm._s(_vm.$t("Place")) +
                " " +
                _vm._s(_vm.productIdx + 1) +
                " - " +
                _vm._s(_vm.product.count) +
                " " +
                _vm._s(_vm.$t("sales"))
            ),
          ]),
          _c("div", { staticClass: "card-graph__text md-none" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.$t("Place")) +
                " " +
                _vm._s(_vm.productIdx + 1) +
                "\n            "
            ),
          ]),
          _c("div", {
            staticClass: "card-graph__head",
            domProps: { textContent: _vm._s(_vm.product.name) },
          }),
          _c("div", {
            staticClass: "card-graph__makat",
            domProps: {
              textContent: _vm._s(_vm.$t("SKU") + " " + _vm.product.makat),
            },
          }),
          _c("div", { staticClass: "card-graph__num md-none" }, [
            _vm._v(_vm._s(_vm.product.count) + " " + _vm._s(_vm.$t("sales"))),
          ]),
        ]),
        (_vm.productIdx + 1) % 3 === 0
          ? _c("div", { staticClass: "card-graph__img md-none" }, [
              _c("img", { attrs: { src: "/assets/img/graph3.svg", alt: "" } }),
            ])
          : (_vm.productIdx + 1) % 2 === 0
          ? _c("div", { staticClass: "card-graph__img md-none" }, [
              _c("img", { attrs: { src: "/assets/img/graph2.svg", alt: "" } }),
            ])
          : _c("div", { staticClass: "card-graph__img md-none" }, [
              _c("img", { attrs: { src: "/assets/img/graph1.svg", alt: "" } }),
            ]),
        _c(
          "div",
          { staticClass: "card-graph__arr" },
          [
            _c("svg-icon", {
              attrs: { name: "ic-arrow-up", width: "14", height: "20" },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }