var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      staticClass: "filter-dialog",
      attrs: { mobFilter: "", overflowVisible: "", width: "500px" },
      on: { close: _vm.close },
    },
    [
      _c("div", { staticClass: "popup__top" }, [
        _c("div", { staticClass: "popup__head" }, [
          _c("div", {
            staticClass: "h1",
            domProps: { textContent: _vm._s(_vm.$t("Invoices filter")) },
          }),
          _c(
            "button",
            {
              staticClass: "popup__close-filter d-md-none",
              on: { click: _vm.close },
            },
            [
              _c("svg-icon", {
                attrs: { name: "ic-close-circle", width: "32", height: "32" },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "form",
        {
          staticClass: "form-site",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.applyFilter.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "popup__content" }, [
            _c("div", { staticClass: "filters__lists" }, [
              _c("div", { staticClass: "filter__list-invoices" }, [
                _c("div", { staticClass: "filter__sect" }, [
                  _c("div", {
                    staticClass: "filter__label",
                    domProps: { textContent: _vm._s(_vm.$t("Minimum amount")) },
                  }),
                  _c("div", { staticClass: "filter__item" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.minAmount,
                          expression: "minAmount",
                        },
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.minAmount },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.minAmount = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "filter__sect" }, [
                  _c("div", {
                    staticClass: "filter__label",
                    domProps: { textContent: _vm._s(_vm.$t("Maximum amount")) },
                  }),
                  _c("div", { staticClass: "filter__item" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.maxAmount,
                          expression: "maxAmount",
                        },
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.maxAmount },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.maxAmount = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "filter__sect" }, [
                  _c("div", {
                    staticClass: "filter__label",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("From") + " " + _vm.$t("Date")
                      ),
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "input-date" },
                    [
                      _c("date-picker", {
                        staticClass: "date-picker",
                        attrs: { name: "a1" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var inputValue = ref.inputValue
                              var inputEvents = ref.inputEvents
                              return [
                                _c(
                                  "input",
                                  _vm._g(
                                    {
                                      attrs: { type: "text" },
                                      domProps: {
                                        value: inputValue.replaceAll(".", "/"),
                                      },
                                    },
                                    inputEvents
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.from,
                          callback: function ($$v) {
                            _vm.from = $$v
                          },
                          expression: "from",
                        },
                      }),
                      _c("svg-icon", {
                        attrs: {
                          name: "ic-calendar",
                          width: "20",
                          height: "20",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "filter__sect" }, [
                  _c("div", {
                    staticClass: "filter__label",
                    domProps: {
                      textContent: _vm._s(_vm.$t("To") + " " + _vm.$t("Date")),
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "input-date" },
                    [
                      _c("date-picker", {
                        staticClass: "date-picker",
                        attrs: { name: "a1" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var inputValue = ref.inputValue
                              var inputEvents = ref.inputEvents
                              return [
                                _c(
                                  "input",
                                  _vm._g(
                                    {
                                      attrs: { type: "text" },
                                      domProps: {
                                        value: inputValue.replaceAll(".", "/"),
                                      },
                                    },
                                    inputEvents
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.to,
                          callback: function ($$v) {
                            _vm.to = $$v
                          },
                          expression: "to",
                        },
                      }),
                      _c("svg-icon", {
                        attrs: {
                          name: "ic-calendar",
                          width: "20",
                          height: "20",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "filter__sect" }, [
                  _c("div", {
                    staticClass: "filter__label",
                    domProps: { textContent: _vm._s(_vm.$t("Paid")) },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "filter__item",
                      style: { "margin-bottom": "80px" },
                    },
                    [
                      _c("checkbox", {
                        attrs: { name: "paid" },
                        model: {
                          value: _vm.paid,
                          callback: function ($$v) {
                            _vm.paid = $$v
                          },
                          expression: "paid",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "form-site__btn" }, [
            _c(
              "button",
              {
                staticClass: "btn btn_border",
                attrs: { type: "button" },
                on: { click: _vm.reset },
              },
              [
                _c("span", {
                  domProps: { textContent: _vm._s(_vm.$t("Reset")) },
                }),
              ]
            ),
            _c("button", { staticClass: "btn", attrs: { type: "submit" } }, [
              _c("span", {
                domProps: { textContent: _vm._s(_vm.$t("Filter")) },
              }),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }