var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c(
        "div",
        [
          _vm.srcPath
            ? _c("div", {
                class: _vm.imgClass + " " + _vm.avatarClass,
                style: {
                  "background-image": "url('" + _vm.srcPath + "')",
                  width: _vm.size + "px",
                  height: _vm.size + "px",
                },
              })
            : _c("avatar", {
                class: _vm.avatarClass,
                attrs: {
                  username: _vm.user.name || "",
                  initials: _vm.initials,
                  size: _vm.size,
                  customStyle: _vm.avatarStyle,
                },
              }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }