var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "business-clients" },
    [
      _c(
        "table-list",
        _vm._b(
          {
            ref: "businessClientsTable",
            on: {
              changeOptions: _vm.changeOptions,
              "on-click-line": _vm.openPopup,
            },
            scopedSlots: _vm._u(
              [
                _vm.mobile
                  ? {
                      key: "above-tabel",
                      fn: function () {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "mobile-btn-plus",
                              on: { click: _vm.createBusinessClient },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-plus",
                                  width: "16",
                                  height: "16",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : {
                      key: "btn",
                      fn: function () {
                        return [
                          _vm.checkPermissionPath("business_clients.create")
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn_pink",
                                  on: { click: _vm.createBusinessClient },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      name: "ic-plus",
                                      width: "16",
                                      height: "16",
                                    },
                                  }),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("New business client")
                                      ),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
              ],
              null,
              true
            ),
          },
          "table-list",
          _vm.dataTable,
          false
        )
      ),
      _vm.popup
        ? _c("business-client-details", {
            attrs: { businessClient: _vm.clientInEdit },
            on: {
              save: _vm.saveBusinessClient,
              remove: _vm.removeBusinessClient,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }