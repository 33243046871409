var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "color-picker" },
    [
      _c("button", {
        staticClass: "tab-theme__label",
        class: { active: _vm.gradientMode },
        domProps: { textContent: _vm._s(_vm.$t("Gradient")) },
        on: {
          click: function ($event) {
            return _vm.changeMode(true)
          },
        },
      }),
      _c("button", {
        staticClass: "tab-theme__label",
        class: { active: !_vm.gradientMode },
        domProps: { textContent: _vm._s(_vm.$t("Flat")) },
        on: {
          click: function ($event) {
            return _vm.changeMode(false)
          },
        },
      }),
      _c("ColorPicker", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.gradientMode,
            expression: "!gradientMode",
          },
        ],
        attrs: {
          color: _vm.color,
          onChange: _vm.onChange,
          onChangeEnd: _vm.onChange,
          gradient: _vm.color,
          isGradient: false,
        },
      }),
      _c("ColorPicker", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.gradientMode,
            expression: "gradientMode",
          },
        ],
        attrs: {
          onChange: _vm.onChange,
          onChangeEnd: _vm.onChange,
          gradient: _vm.color,
          isGradient: true,
        },
      }),
      _c("div", { staticClass: "btns" }, [
        _c(
          "button",
          { staticClass: "btn-close agree", on: { click: _vm.agree } },
          [
            _c(
              "svg",
              {
                staticClass: "verte__icon verte__icon--small",
                attrs: {
                  height: "78.369px",
                  viewBox: "0 0 78.369 78.369",
                  width: "78.369px",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("title", [_vm._v("Agree")]),
                _c("g", [
                  _c("path", {
                    attrs: {
                      d: "M78.049,19.015L29.458,67.606c-0.428,0.428-1.121,0.428-1.548,0L0.32,40.015c-0.427-0.426-0.427-1.119,0-1.547l6.704-6.704 c0.428-0.427,1.121-0.427,1.548,0l20.113,20.112l41.113-41.113c0.429-0.427,1.12-0.427,1.548,0l6.703,6.704 C78.477,17.894,78.477,18.586,78.049,19.015z",
                    },
                  }),
                ]),
              ]
            ),
          ]
        ),
        _c("button", { staticClass: "btn-close", on: { click: _vm.close } }, [
          _c(
            "svg",
            {
              staticClass: "verte__icon verte__icon--small",
              attrs: { viewBox: "0 0 24 24" },
            },
            [
              _c("title", [_vm._v("Close")]),
              _c("path", {
                attrs: {
                  d: "M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z",
                },
              }),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }