var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "popup__close-continer md-none" }, [
    _c(
      "button",
      {
        staticClass: "popup__close",
        style: _vm.style,
        on: { click: _vm.close },
      },
      [
        _c(
          "svg",
          {
            attrs: {
              width: "32",
              height: "32",
              viewBox: "0 0 32 32",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M27.3131 4.68687C24.2896 1.6633 20.2761 0 16 0C11.7239 0 7.71044 1.6633 4.68687 4.68687C1.6633 7.71044 0 11.7239 0 16C0 20.2761 1.6633 24.2896 4.68687 27.3131C7.71044 30.3367 11.7239 32 16 32C20.2761 32 24.2896 30.3367 27.3131 27.3131C30.3367 24.2896 32 20.2761 32 16C32 11.7239 30.3367 7.71044 27.3131 4.68687ZM26.0269 26.0269C23.3468 28.7071 19.7845 30.1818 16 30.1818C12.2155 30.1818 8.6532 28.7071 5.97306 26.0269C0.444444 20.4983 0.444444 11.5017 5.97306 5.97306C8.6532 3.29293 12.2155 1.81818 16 1.81818C19.7845 1.81818 23.3468 3.29293 26.0269 5.97306C31.5556 11.5017 31.5556 20.4983 26.0269 26.0269Z",
                fill: "url(#close-popup-btn-paint0_linear)",
              },
            }),
            _c("path", {
              attrs: {
                d: "M23.0503 8.94932C22.6934 8.59242 22.121 8.59242 21.7641 8.94932L15.9998 14.7136L10.2355 8.94932C9.87861 8.59242 9.30622 8.59242 8.94932 8.94932C8.59242 9.30622 8.59242 9.87861 8.94932 10.2355L14.7136 15.9998L8.94932 21.7641C8.59242 22.121 8.59242 22.6934 8.94932 23.0503C9.1244 23.2254 9.36009 23.3197 9.58905 23.3197C9.818 23.3197 10.0537 23.2321 10.2288 23.0503L15.9931 17.286L21.7574 23.0503C21.9325 23.2254 22.1681 23.3197 22.3971 23.3197C22.6328 23.3197 22.8618 23.2321 23.0368 23.0503C23.3937 22.6934 23.3937 22.121 23.0368 21.7641L17.286 15.9998L23.0503 10.2355C23.4072 9.87861 23.4072 9.30622 23.0503 8.94932Z",
                fill: "url(#close-popup-btn-paint1_linear)",
              },
            }),
            _c(
              "defs",
              [
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "close-popup-btn-paint0_linear",
                      x1: "16",
                      y1: "0",
                      x2: "16",
                      y2: "32",
                      gradientUnits: "userSpaceOnUse",
                    },
                  },
                  [
                    _c("stop", { attrs: { "stop-color": "#f28f16" } }),
                    _c("stop", {
                      attrs: { offset: "1", "stop-color": "#d60b52" },
                    }),
                  ],
                  1
                ),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "close-popup-btn-paint1_linear",
                      x1: "15.9998",
                      y1: "8.68164",
                      x2: "15.9998",
                      y2: "23.3197",
                      gradientUnits: "userSpaceOnUse",
                    },
                  },
                  [
                    _c("stop", { attrs: { "stop-color": "#f28f16" } }),
                    _c("stop", {
                      attrs: { offset: "1", "stop-color": "#d60b52" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }