var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pos-settings full-screen" },
    [
      _c("main", [
        _c("section", { staticClass: "container" }, [
          _c("div", { staticClass: "page" }, [
            _c(
              "div",
              { staticClass: "page__main" },
              [
                _c(
                  "router-link",
                  { staticClass: "page__back", attrs: { to: "../settings" } },
                  [
                    _c("svg-icon", {
                      attrs: {
                        name: "ic-close-table-arrow",
                        width: "34",
                        height: "34",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "page__top" }, [
                  _c("h1", {
                    domProps: { textContent: _vm._s(_vm.$t("Devices")) },
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: { type: "button" },
                      on: { click: _vm.showGenerateTechnicianCodeDialog },
                    },
                    [_vm._v(_vm._s(_vm.$t("Technician code generate")))]
                  ),
                ]),
                _c("div", { staticClass: "page__content" }, [
                  _vm.restaurantId
                    ? _c(
                        "form",
                        {
                          staticClass: "form-site",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "form-site__field-wrap" },
                            [
                              _c("form-site-field-select-box", {
                                attrs: {
                                  title: "Type",
                                  unsort: "",
                                  options: _vm.posOptions,
                                },
                                model: {
                                  value: _vm.pos,
                                  callback: function ($$v) {
                                    _vm.pos = $$v
                                  },
                                  expression: "pos",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.pos_settings
                            ? _c(
                                "div",
                                { staticClass: "form-site__content" },
                                [
                                  _c("div", { staticClass: "hr" }),
                                  _vm.pos === 1
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-site__field-wrap",
                                          },
                                          [
                                            _c("form-site-field-text", {
                                              attrs: { title: "Port" },
                                              model: {
                                                value: _vm.pos_settings.port,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.pos_settings,
                                                    "port",
                                                    $$v
                                                  )
                                                },
                                                expression: "pos_settings.port",
                                              },
                                            }),
                                            _c("form-site-field-text", {
                                              attrs: { title: "Card number" },
                                              model: {
                                                value:
                                                  _vm.pos_settings.card_number,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.pos_settings,
                                                    "card_number",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "pos_settings.card_number",
                                              },
                                            }),
                                            _c("form-site-field-text", {
                                              attrs: {
                                                title: "Multipass card number",
                                              },
                                              model: {
                                                value:
                                                  _vm.pos_settings
                                                    .multipass_card_number,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.pos_settings,
                                                    "multipass_card_number",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "pos_settings.multipass_card_number",
                                              },
                                            }),
                                            _c("form-site-field-text", {
                                              attrs: {
                                                title:
                                                  "Compensation card number",
                                              },
                                              model: {
                                                value:
                                                  _vm.pos_settings
                                                    .compensation_card_number,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.pos_settings,
                                                    "compensation_card_number",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "pos_settings.compensation_card_number",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("div", { staticClass: "hr" }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-site__field-wrap",
                                          },
                                          [
                                            _c("form-site-field-text", {
                                              attrs: { title: "Discount sku" },
                                              model: {
                                                value:
                                                  _vm.pos_settings
                                                    .discount_makat,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.pos_settings,
                                                    "discount_makat",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "pos_settings.discount_makat",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm.pos === 2
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-site__field-wrap",
                                          },
                                          [
                                            _c("form-site-field-text", {
                                              attrs: {
                                                title: "Restid",
                                                disabled: "",
                                                value: _vm.restaurantId,
                                              },
                                            }),
                                            _c("form-site-field-text", {
                                              attrs: {
                                                title: "Posid",
                                                disabled: "",
                                                value: _vm.branchId,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm.pos === 3
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-site__field-wrap",
                                          },
                                          [
                                            _c("form-site-field-text", {
                                              attrs: {
                                                title: "Practi branch id",
                                              },
                                              model: {
                                                value:
                                                  _vm.pos_settings
                                                    .practi_branch_id,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.pos_settings,
                                                    "practi_branch_id",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "pos_settings.practi_branch_id",
                                              },
                                            }),
                                            _c("form-site-field-text", {
                                              attrs: {
                                                title: "Practi client id",
                                              },
                                              model: {
                                                value:
                                                  _vm.pos_settings
                                                    .practi_client_id,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.pos_settings,
                                                    "practi_client_id",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "pos_settings.practi_client_id",
                                              },
                                            }),
                                            _c("form-site-field-text", {
                                              attrs: {
                                                title: "Practi client secret",
                                              },
                                              model: {
                                                value:
                                                  _vm.pos_settings
                                                    .practi_client_secret,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.pos_settings,
                                                    "practi_client_secret",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "pos_settings.practi_client_secret",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("div", { staticClass: "hr" }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-site__field-wrap",
                                          },
                                          [
                                            _c("form-site-field-text", {
                                              attrs: { title: "Delivery sku" },
                                              model: {
                                                value:
                                                  _vm.pos_settings
                                                    .delivery_product_id,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.pos_settings,
                                                    "delivery_product_id",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "pos_settings.delivery_product_id",
                                              },
                                            }),
                                            _c("form-site-field-text", {
                                              attrs: { title: "Tip sku" },
                                              model: {
                                                value:
                                                  _vm.pos_settings.tip_makat,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.pos_settings,
                                                    "tip_makat",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "pos_settings.tip_makat",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm.pos === 4
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-site__field-wrap",
                                          },
                                          [
                                            _c("form-site-field-text", {
                                              attrs: { title: "Discount sku" },
                                              model: {
                                                value:
                                                  _vm.pos_settings
                                                    .discount_makat,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.pos_settings,
                                                    "discount_makat",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "pos_settings.discount_makat",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("div", { staticClass: "hr" }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-site__field-wrap",
                                          },
                                          [
                                            _c("form-site-field-select-box", {
                                              attrs: {
                                                showSearch: "",
                                                viewValue: "",
                                                title: "Beecomm branch id",
                                                unsort: "",
                                                options: _vm.beecommCustomers,
                                              },
                                              model: {
                                                value:
                                                  _vm.pos_settings
                                                    .beecomm_branch_id,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.pos_settings,
                                                    "beecomm_branch_id",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "pos_settings.beecomm_branch_id",
                                              },
                                            }),
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn-icn",
                                                on: {
                                                  click: _vm.openSendJsonPopup,
                                                },
                                              },
                                              [
                                                _c("svg-icon", {
                                                  staticClass: "shadow",
                                                  attrs: {
                                                    name: "ic-miniprint",
                                                    width: "48",
                                                    height: "48",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("div", { staticClass: "hr" }),
                                        _c("h3", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Tests")
                                            ),
                                          },
                                        }),
                                        _c(
                                          "div",
                                          {},
                                          _vm._l(
                                            _vm.demoOrdersBranch,
                                            function (test) {
                                              return _c(
                                                "div",
                                                {
                                                  key: test.tmp_order_id,
                                                  staticClass:
                                                    "form-site__field",
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass: "test",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.chackTmpOrder(
                                                            test.tmp_order_id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass: "order-id",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            "#" +
                                                              test.tmp_order_id
                                                          ),
                                                        },
                                                      }),
                                                      _c("span", {
                                                        staticClass: "is-scss",
                                                        class: test.success
                                                          ? "scss"
                                                          : "fail",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            test.success
                                                              ? _vm.$t(
                                                                  "Succeeded"
                                                                )
                                                              : _vm.$t("Fail")
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    : _vm.pos === 5
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-site__field-wrap",
                                          },
                                          [
                                            _c("form-site-field-text", {
                                              attrs: { title: "FRS branch id" },
                                              model: {
                                                value:
                                                  _vm.pos_settings
                                                    .frs_branch_id,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.pos_settings,
                                                    "frs_branch_id",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "pos_settings.frs_branch_id",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-site__field-wrap",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "form-site__field",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "input-check",
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "input-check__label",
                                                      },
                                                      [
                                                        _c("checkbox", {
                                                          attrs: {
                                                            name: "name",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.pos_settings
                                                                .dragontail,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.pos_settings,
                                                                "dragontail",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "pos_settings.dragontail",
                                                          },
                                                        }),
                                                        _c("span", {
                                                          staticClass:
                                                            "input-check__text",
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t("DTS")
                                                            ),
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c("div", {
                                              staticClass: "form-site__field",
                                            }),
                                            _c("form-site-field-text", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.pos_settings.dragontail,
                                                  expression:
                                                    "pos_settings.dragontail",
                                                },
                                              ],
                                              attrs: { title: "User name" },
                                              model: {
                                                value:
                                                  _vm.pos_settings.userName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.pos_settings,
                                                    "userName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "pos_settings.userName",
                                              },
                                            }),
                                            _c("form-site-field-text", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.pos_settings.dragontail,
                                                  expression:
                                                    "pos_settings.dragontail",
                                                },
                                              ],
                                              attrs: { title: "Password" },
                                              model: {
                                                value:
                                                  _vm.pos_settings.password,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.pos_settings,
                                                    "password",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "pos_settings.password",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm.pos === 6
                                    ? _c(
                                        "div",
                                        { staticClass: "atmos-pos-page" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "filter-tab",
                                              staticStyle: {
                                                "flex-direction": "row",
                                                "justify-content": "center",
                                                display: "flex",
                                              },
                                            },
                                            [
                                              !_vm.mobile
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn_pink mie20",
                                                      on: {
                                                        click: _vm.createNewPos,
                                                      },
                                                    },
                                                    [
                                                      _c("svg-icon", {
                                                        attrs: {
                                                          name: "ic-plus",
                                                          width: "16",
                                                          height: "16",
                                                        },
                                                      }),
                                                      _c("span", {
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t("Add Device")
                                                          ),
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._l(
                                                _vm.devicesTabs,
                                                function (pos, index) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: index,
                                                      staticClass:
                                                        "input-check",
                                                    },
                                                    [
                                                      _c("label", [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.device_type,
                                                              expression:
                                                                "device_type",
                                                            },
                                                          ],
                                                          attrs: {
                                                            type: "radio",
                                                          },
                                                          domProps: {
                                                            value: pos.value,
                                                            checked: _vm._q(
                                                              _vm.device_type,
                                                              pos.value
                                                            ),
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              _vm.device_type =
                                                                pos.value
                                                            },
                                                          },
                                                        }),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "input-check__label",
                                                          },
                                                          [
                                                            _c("span", {
                                                              staticClass:
                                                                "input-check__text",
                                                              domProps: {
                                                                textContent:
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      pos.label
                                                                    )
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                          _vm.checkedPosCount > 0 &&
                                          _vm.device_type === 2
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "multiselect-header",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "multiselect-header-title",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("selected")
                                                          ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.checkedPosCount
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.currentDeviceTabLabel
                                                            )
                                                        ),
                                                      ]),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "choose action to run on them"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "actions" },
                                                    [
                                                      _c("button", {
                                                        staticClass:
                                                          "btn btn_border",
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t("Restart")
                                                          ),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.action(
                                                              "restart_pos"
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c("button", {
                                                        staticClass:
                                                          "btn btn_border",
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t("Stop")
                                                          ),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.action(
                                                              "stop_pos"
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c("button", {
                                                        staticClass:
                                                          "btn btn_border",
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t("Start")
                                                          ),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.action(
                                                              "start_pos"
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c("button", {
                                                        staticClass:
                                                          "btn btn_border",
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t("Update")
                                                          ),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.action(
                                                              "update_pos"
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c("button", {
                                                        staticClass:
                                                          "btn btn_border",
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t("Lock")
                                                          ),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.action(
                                                              "lock_screen",
                                                              "kiosk"
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c("button", {
                                                        staticClass:
                                                          "btn btn_border",
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t("Unlock")
                                                          ),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.action(
                                                              "unlock_screen",
                                                              "kiosk"
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c("button", {
                                                        staticClass:
                                                          "btn btn_border",
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t(
                                                              "Reload Menu"
                                                            )
                                                          ),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.action(
                                                              "reload_menu",
                                                              "kiosk"
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c("v-data-table", {
                                            attrs: {
                                              items: _vm.geteatPoses,
                                              "show-select":
                                                _vm.device_type === 2,
                                              "item-key": "id",
                                              "server-items-length": _vm.total,
                                              page: _vm.page,
                                              "items-per-page": _vm.itemPerPage,
                                              headers: _vm.dataTableHeaders,
                                            },
                                            on: {
                                              "update:options":
                                                _vm.changeOptions,
                                              "click:row": _vm.editPos,
                                            },
                                            model: {
                                              value: _vm.checkedPos,
                                              callback: function ($$v) {
                                                _vm.checkedPos = $$v
                                              },
                                              expression: "checkedPos",
                                            },
                                          }),
                                          _vm.mobile
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "mobile-btn-plus",
                                                  on: {
                                                    click: _vm.createNewPos,
                                                  },
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      name: "ic-plus",
                                                      width: "16",
                                                      height: "16",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm.pos === 7
                                    ? [
                                        _vm.pos_settings
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-site__field-wrap",
                                              },
                                              [
                                                _c("form-site-field-text", {
                                                  attrs: { title: "User" },
                                                  model: {
                                                    value:
                                                      _vm.pos_settings.user,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.pos_settings,
                                                        "user",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "pos_settings.user",
                                                  },
                                                }),
                                                _c("form-site-field-text", {
                                                  attrs: { title: "Password" },
                                                  model: {
                                                    value:
                                                      _vm.pos_settings.password,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.pos_settings,
                                                        "password",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "pos_settings.password",
                                                  },
                                                }),
                                                _c("form-site-field-text", {
                                                  attrs: { title: "Schema" },
                                                  model: {
                                                    value:
                                                      _vm.pos_settings
                                                        .schemaTable,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.pos_settings,
                                                        "schemaTable",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "pos_settings.schemaTable",
                                                  },
                                                }),
                                                _c("form-site-field-text", {
                                                  attrs: {
                                                    title: "Document POS Id",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.pos_settings.docPosId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.pos_settings,
                                                        "docPosId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "pos_settings.docPosId",
                                                  },
                                                }),
                                                _c("form-site-field-text", {
                                                  attrs: {
                                                    title: "Document warehouse",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.pos_settings
                                                        .docWarehouse,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.pos_settings,
                                                        "docWarehouse",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "pos_settings.docWarehouse",
                                                  },
                                                }),
                                                _c("form-site-field-text", {
                                                  attrs: { title: "IP" },
                                                  model: {
                                                    value: _vm.pos_settings.ip,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.pos_settings,
                                                        "ip",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "pos_settings.ip",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    : _vm.pos === 8
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-site__field-wrap",
                                          },
                                          [
                                            _c("form-site-field-select-box", {
                                              attrs: {
                                                showSearch: "",
                                                title: "Beecomm restaurant id",
                                                unsort: "",
                                                options: _vm.beecommRestaurants,
                                              },
                                              model: {
                                                value:
                                                  _vm.pos_settings
                                                    .beecomm_restaurant_id,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.pos_settings,
                                                    "beecomm_restaurant_id",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "pos_settings.beecomm_restaurant_id",
                                              },
                                            }),
                                            _c("form-site-field-select-box", {
                                              attrs: {
                                                showSearch: "",
                                                title: "Beecomm branch id",
                                                unsort: "",
                                                options: _vm.beecommBranches,
                                              },
                                              model: {
                                                value:
                                                  _vm.pos_settings
                                                    .beecomm_branch_id,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.pos_settings,
                                                    "beecomm_branch_id",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "pos_settings.beecomm_branch_id",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ]),
          _vm.change
            ? _c("div", { staticClass: "btn-save-container-settings" }, [
                _c(
                  "button",
                  { staticClass: "save-btn", on: { click: _vm.save } },
                  [
                    _c("svg-icon", {
                      staticClass: "ic-edit",
                      attrs: {
                        name: "ic-save-btn",
                        width: "112",
                        height: "128",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm.popup === 1
        ? _c("demo-order-dialog", { on: { save: _vm.saveDemo } })
        : _vm._e(),
      _vm.popup === 2
        ? _c("edit-pos-dialog", {
            attrs: { pos: _vm.posInEdit },
            on: {
              save: _vm.saveOurPos,
              removePos: _vm.removePos,
              setActive: _vm.setActive,
            },
          })
        : _vm._e(),
      _c("technician-code-generate-dialog", {
        model: {
          value: _vm.generateTechnicianCodeDialog,
          callback: function ($$v) {
            _vm.generateTechnicianCodeDialog = $$v
          },
          expression: "generateTechnicianCodeDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }