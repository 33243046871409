var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", {
    staticClass: "show-time",
    domProps: { textContent: _vm._s(_vm.timeForShow) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }