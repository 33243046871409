var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "bin-btn-cmp",
      class: _vm.className,
      style: { width: _vm.size + "px", height: _vm.size + "px" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("svg-icon", {
        staticClass: "ic-edit",
        attrs: { name: "ic-bin", width: _vm.size / 2.3, height: _vm.size / 2 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }