var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filter-tab" },
    [
      _c(
        "button",
        {
          staticClass: "filter-tab__item",
          class: { active: _vm.current === -1 },
          on: {
            click: function ($event) {
              return _vm.tabChange(-1)
            },
          },
        },
        [
          _c("div", {
            staticClass: "filter-tab__text",
            domProps: { textContent: _vm._s(_vm.$t("All")) },
          }),
        ]
      ),
      _vm._l(_vm.$data.sources, function (source, i) {
        return _c("div", { key: "source" + i }, [
          _c(
            "button",
            {
              staticClass: "filter-tab__item",
              class: { active: _vm.current === i },
              staticStyle: { margin: "0 0 10px 20px" },
              on: {
                click: function ($event) {
                  return _vm.tabChange(i)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "filter-tab__icon" },
                [
                  _c("svg-icon", {
                    staticStyle: { color: "#000" },
                    attrs: {
                      name: _vm.$data.icons[i],
                      width: "16",
                      height: "16",
                    },
                  }),
                ],
                1
              ),
              _c("div", {
                staticClass: "filter-tab__text",
                domProps: { textContent: _vm._s(_vm.$t(source)) },
              }),
            ]
          ),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }