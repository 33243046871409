var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-dialog-tabs", {
    staticClass: "catalog-editor-menu-dialog",
    attrs: { width: "776px", tabList: _vm.tabList },
    on: { close: _vm.close },
    scopedSlots: _vm._u(
      [
        {
          key: "tab0",
          fn: function () {
            return [
              _c("div", { staticClass: "form-lang pb40" }, [
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.save.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "form-site" }, [
                      _c(
                        "div",
                        {
                          staticClass: "header-back back d-flex d-md-none",
                          style: { "margin-right": "-25px", width: "103vw" },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "header__back",
                              on: { click: _vm.close },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "header-company" },
                                [
                                  _c("svg-icon", {
                                    staticClass: "ic-white",
                                    attrs: {
                                      name: "ic-arrow-left",
                                      width: "16",
                                      height: "12",
                                    },
                                  }),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Back")),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "popup__top" }, [
                        _c(
                          "div",
                          { staticClass: "popup__head flex bet a-center" },
                          [
                            _c("form-site-field-text", {
                              ref: "name",
                              staticStyle: { width: "50%" },
                              attrs: { title: _vm.$t("Menu name") },
                              model: {
                                value: _vm.menuCopy.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.menuCopy, "name", $$v)
                                },
                                expression: "menuCopy.name",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "switch-wrap inline-flex a-center",
                              },
                              [
                                _c("div", {
                                  staticClass: "switch-wrap__label mie12",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Active")),
                                  },
                                }),
                                _c("swich-button", {
                                  model: {
                                    value: _vm.menuCopy.active,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.menuCopy, "active", $$v)
                                    },
                                    expression: "menuCopy.active",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "tab1",
          fn: function () {
            return [
              _c("div", { staticClass: "popup__top d-md-block d-none" }, [
                _c("div", { staticClass: "popup__head flex bet a-center" }, [
                  _c("div", {
                    staticClass: "h1 mis0 mt20",
                    domProps: { textContent: _vm._s(_vm.menuCopy.name) },
                  }),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "popup__content" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "header-back back d-flex d-md-none",
                      style: { "margin-right": "-25px", width: "103vw" },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "header__back",
                          on: { click: _vm.close },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "header-company" },
                            [
                              _c("svg-icon", {
                                staticClass: "ic-white",
                                attrs: {
                                  name: "ic-arrow-left",
                                  width: "16",
                                  height: "12",
                                },
                              }),
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Back")),
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("branches-tab", {
                    attrs: {
                      showOnlyThisBranch: !!_vm.branchId,
                      availability: _vm.menuCopy.availability,
                    },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "tab2",
          fn: function () {
            return [
              _c("div", { staticClass: "popup__top" }, [
                _c("div", { staticClass: "popup__head flex bet a-center" }, [
                  _c("div", {
                    staticClass: "h1 mis0 mt20",
                    domProps: { textContent: _vm._s(_vm.menuCopy.name) },
                  }),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "popup__content" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "header-back back d-flex d-md-none",
                      style: { "margin-right": "-25px", width: "103vw" },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "header__back",
                          on: { click: _vm.close },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "header-company" },
                            [
                              _c("svg-icon", {
                                staticClass: "ic-white",
                                attrs: {
                                  name: "ic-arrow-left",
                                  width: "16",
                                  height: "12",
                                },
                              }),
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Back")),
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("availability-tab", {
                    attrs: {
                      id: !!_vm.menuCopy.id,
                      availability: _vm.menuCopy.availability,
                    },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: +_vm.branchId ? "" : "tab3",
          fn: function () {
            return [
              _c("div", { staticClass: "form-lang pb40" }, [
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.save.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "form-site" }, [
                      _c(
                        "div",
                        {
                          staticClass: "header-back back d-flex d-md-none",
                          style: { "margin-right": "-25px", width: "103vw" },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "header__back",
                              on: { click: _vm.close },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "header-company" },
                                [
                                  _c("svg-icon", {
                                    staticClass: "ic-white",
                                    attrs: {
                                      name: "ic-arrow-left",
                                      width: "16",
                                      height: "12",
                                    },
                                  }),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Back")),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "popup__top d-md-block d-none" },
                        [
                          _c(
                            "div",
                            { staticClass: "popup__head flex bet a-center" },
                            [
                              _c("div", {
                                staticClass: "h1 mis0 mt20",
                                domProps: {
                                  textContent: _vm._s(_vm.menuCopy.name),
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "popup__content" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-end" },
                            [
                              _c("div", { staticClass: "lang_grey_contain" }, [
                                _c("div", { staticClass: "lang lang_grey" }, [
                                  _c("button", {
                                    staticClass: "lang__item",
                                    class: { active: _vm.english },
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("English")),
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        _vm.english = true
                                      },
                                    },
                                  }),
                                  _c("button", {
                                    staticClass: "lang__item",
                                    class: { active: !_vm.english },
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Hebrew")),
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        _vm.english = false
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                            ]
                          ),
                          _vm.english
                            ? _c("form-site-field-text", {
                                ref: "name",
                                attrs: { title: _vm.$t("Finish step name") },
                                model: {
                                  value: _vm.menuCopy.finish_step_name_en,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.menuCopy,
                                      "finish_step_name_en",
                                      $$v
                                    )
                                  },
                                  expression: "menuCopy.finish_step_name_en",
                                },
                              })
                            : _c("form-site-field-text", {
                                ref: "name",
                                attrs: { title: _vm.$t("Finish step name") },
                                model: {
                                  value: _vm.menuCopy.finish_step_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.menuCopy,
                                      "finish_step_name",
                                      $$v
                                    )
                                  },
                                  expression: "menuCopy.finish_step_name",
                                },
                              }),
                          _vm.menuCopy.finish_steps &&
                          _vm.menuCopy.finish_steps.length > 0
                            ? _c(
                                "div",
                                { staticClass: "finish-steps" },
                                _vm._l(
                                  _vm.menuCopy.finish_steps,
                                  function (step, stepIndex) {
                                    return _c(
                                      "div",
                                      { staticClass: "finish-steps__item" },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _vm.english
                                              ? _c("form-site-field-text", {
                                                  ref: "name",
                                                  refInFor: true,
                                                  attrs: {
                                                    title: _vm.$t("Title"),
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.saveFinishStep(
                                                        step
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: step.name_en,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        step,
                                                        "name_en",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "step.name_en",
                                                  },
                                                })
                                              : _c("form-site-field-text", {
                                                  ref: "name",
                                                  refInFor: true,
                                                  attrs: {
                                                    title: _vm.$t("Title"),
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.saveFinishStep(
                                                        step
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: step.name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        step,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "step.name",
                                                  },
                                                }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "finish-step-actions",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-site__content",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-site__group-title",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("Show always")
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("swich-button", {
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.updateFinishStepShowAlways(
                                                        $event,
                                                        step
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: step.show_always,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        step,
                                                        "show_always",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "step.show_always",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c("edit-btn", {
                                              attrs: { size: 40 },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editFinishStep(
                                                    stepIndex
                                                  )
                                                },
                                              },
                                            }),
                                            _c("remove-btn", {
                                              attrs: {
                                                "only-icon": true,
                                                size: 40,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeFinishStep(
                                                    stepIndex
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "line-more" }, [
                            _c(
                              "button",
                              {
                                staticClass: "line-more__content",
                                attrs: { type: "button" },
                                on: { click: _vm.addFinishStep },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "line-more__icon" },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        name: "ic-plus",
                                        width: "12",
                                        height: "12",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("span", { staticClass: "line-more__text" }, [
                                  _vm._v(_vm._s(_vm.$t("Add"))),
                                ]),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: +_vm.branchId ? "tab3" : "",
          fn: function () {
            return [
              _c("div", { staticClass: "popup__top d-md-block d-none" }, [
                _c("div", { staticClass: "popup__head flex bet a-center" }, [
                  _c("div", {
                    staticClass: "h1 mis0 mt20",
                    domProps: { textContent: _vm._s(_vm.menuCopy.name) },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-orders-set" }, [
                _c(
                  "div",
                  {
                    staticClass: "header-back back d-flex d-md-none",
                    style: { "margin-right": "-25px", width: "103vw" },
                  },
                  [
                    _c(
                      "button",
                      { staticClass: "header__back", on: { click: _vm.close } },
                      [
                        _c(
                          "span",
                          { staticClass: "header-company" },
                          [
                            _c("svg-icon", {
                              staticClass: "ic-white",
                              attrs: {
                                name: "ic-arrow-left",
                                width: "16",
                                height: "12",
                              },
                            }),
                            _c("span", {
                              domProps: { textContent: _vm._s(_vm.$t("Back")) },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
                _vm.menuCopy.orders_settings
                  ? _c("div", { staticClass: "orders-settings-list" }, [
                      _vm.menuCopy.orders_settings[_vm.typeEnumToLowerStr(2)]
                        ? _c(
                            "div",
                            {
                              key: _vm.typeEnumToLowerStr(2),
                              staticClass: "orders-settings",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "orders-settings__switch" },
                                [
                                  _c("swich-button", {
                                    on: {
                                      input: function ($event) {
                                        return _vm.$store.commit(
                                          "setChange",
                                          true
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.menuCopy.orders_settings[
                                          _vm.typeEnumToLowerStr(2)
                                        ].active,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.menuCopy.orders_settings[
                                            _vm.typeEnumToLowerStr(2)
                                          ],
                                          "active",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "menuCopy.orders_settings[typeEnumToLowerStr(2)].active",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "orders-settings__info" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "orders-settings__img" },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          name:
                                            "ic-availability-" +
                                            _vm.typeEnumToLowerStr(2),
                                          width: "52",
                                          height: "72",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", {
                                    staticClass: "orders-settings__title",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "_" +
                                            _vm.capitalize(
                                              _vm.typeEnumToLowerStr(2)
                                            )
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "orders-settings__params",
                                  class: {
                                    disable:
                                      !_vm.menuCopy.orders_settings[
                                        _vm.typeEnumToLowerStr(2)
                                      ].active,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "orders-settings__item",
                                      on: {
                                        click: function ($event) {
                                          _vm.orderSettingskey =
                                            _vm.typeEnumToLowerStr(2)
                                          _vm.popup = 1
                                        },
                                      },
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "orders-settings__label",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("Settings")
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "orders-settings__item",
                                      on: {
                                        click: function ($event) {
                                          _vm.orderSettingskey =
                                            _vm.typeEnumToLowerStr(2)
                                          _vm.popup = 2
                                        },
                                      },
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "orders-settings__label",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("Payment methods")
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.menuCopy.orders_settings[_vm.typeEnumToLowerStr(1)]
                        ? _c(
                            "div",
                            {
                              key: _vm.typeEnumToLowerStr(1),
                              staticClass: "orders-settings",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "orders-settings__switch" },
                                [
                                  _c("swich-button", {
                                    on: {
                                      input: function ($event) {
                                        return _vm.$store.commit(
                                          "setChange",
                                          true
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.menuCopy.orders_settings[
                                          _vm.typeEnumToLowerStr(1)
                                        ].active,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.menuCopy.orders_settings[
                                            _vm.typeEnumToLowerStr(1)
                                          ],
                                          "active",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "menuCopy.orders_settings[typeEnumToLowerStr(1)].active",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "orders-settings__info" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "orders-settings__img" },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          name:
                                            "ic-availability-" +
                                            _vm.typeEnumToLowerStr(1),
                                          width: "52",
                                          height: "72",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", {
                                    staticClass: "orders-settings__title",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "_" +
                                            _vm.capitalize(
                                              _vm.typeEnumToLowerStr(1)
                                            )
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "orders-settings__params",
                                  class: {
                                    disable:
                                      !_vm.menuCopy.orders_settings[
                                        _vm.typeEnumToLowerStr(1)
                                      ].active,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "orders-settings__item" },
                                    [
                                      _c("div", {
                                        staticClass: "orders-settings__label",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("Settings")
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.orderSettingskey =
                                              _vm.typeEnumToLowerStr(1)
                                            _vm.popup = 1
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "orders-settings__item",
                                      on: {
                                        click: function ($event) {
                                          _vm.orderSettingskey =
                                            _vm.typeEnumToLowerStr(1)
                                          _vm.popup = 2
                                        },
                                      },
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "orders-settings__label",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("Payment methods")
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.menuCopy.orders_settings[_vm.typeEnumToLowerStr(3)]
                        ? _c(
                            "div",
                            {
                              key: _vm.typeEnumToLowerStr(3),
                              staticClass: "orders-settings",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "orders-settings__switch" },
                                [
                                  _c("swich-button", {
                                    on: {
                                      input: function ($event) {
                                        return _vm.$store.commit(
                                          "setChange",
                                          true
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.menuCopy.orders_settings[
                                          _vm.typeEnumToLowerStr(3)
                                        ].active,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.menuCopy.orders_settings[
                                            _vm.typeEnumToLowerStr(3)
                                          ],
                                          "active",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "menuCopy.orders_settings[typeEnumToLowerStr(3)].active",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "orders-settings__info" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "orders-settings__img" },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          name:
                                            "ic-availability-" +
                                            _vm.typeEnumToLowerStr(3),
                                          width: "52",
                                          height: "72",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", {
                                    staticClass: "orders-settings__title",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "_" +
                                            _vm.capitalize(
                                              _vm.typeEnumToLowerStr(3)
                                            )
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "orders-settings__params",
                                  class: {
                                    disable:
                                      !_vm.menuCopy.orders_settings[
                                        _vm.typeEnumToLowerStr(3)
                                      ].active,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "orders-settings__item" },
                                    [
                                      _c("div", {
                                        staticClass: "orders-settings__label",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("Settings")
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.orderSettingskey =
                                              _vm.typeEnumToLowerStr(3)
                                            _vm.popup = 1
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "orders-settings__item",
                                      on: {
                                        click: function ($event) {
                                          _vm.orderSettingskey =
                                            _vm.typeEnumToLowerStr(3)
                                          _vm.popup = 2
                                        },
                                      },
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "orders-settings__label",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("Payment methods")
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.menuCopy.orders_settings[_vm.typeEnumToLowerStr(0)]
                        ? _c(
                            "div",
                            {
                              key: _vm.typeEnumToLowerStr(0),
                              staticClass: "orders-settings",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "orders-settings__switch" },
                                [
                                  _c("swich-button", {
                                    on: {
                                      input: function ($event) {
                                        return _vm.$store.commit(
                                          "setChange",
                                          true
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.menuCopy.orders_settings[
                                          _vm.typeEnumToLowerStr(0)
                                        ].active,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.menuCopy.orders_settings[
                                            _vm.typeEnumToLowerStr(0)
                                          ],
                                          "active",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "menuCopy.orders_settings[typeEnumToLowerStr(0)].active",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "orders-settings__info" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "orders-settings__img" },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          name:
                                            "ic-availability-" +
                                            _vm.typeEnumToLowerStr(0),
                                          width: "52",
                                          height: "72",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", {
                                    staticClass: "orders-settings__title",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "_" +
                                            _vm.capitalize(
                                              _vm.typeEnumToLowerStr(0)
                                            )
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "orders-settings__params",
                                  class: {
                                    disable:
                                      !_vm.menuCopy.orders_settings[
                                        _vm.typeEnumToLowerStr(0)
                                      ].active,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "orders-settings__item" },
                                    [
                                      _c("div", {
                                        staticClass: "orders-settings__label",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("Settings")
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.orderSettingskey =
                                              _vm.typeEnumToLowerStr(0)
                                            _vm.popup = 1
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "orders-settings__item",
                                      on: {
                                        click: function ($event) {
                                          _vm.orderSettingskey =
                                            _vm.typeEnumToLowerStr(0)
                                          _vm.popup = 2
                                        },
                                      },
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "orders-settings__label",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("Payment methods")
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.menuCopy.orders_settings[_vm.typeEnumToLowerStr(5)]
                        ? _c(
                            "div",
                            {
                              key: _vm.typeEnumToLowerStr(5),
                              staticClass: "orders-settings",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "orders-settings__switch" },
                                [
                                  _c("swich-button", {
                                    on: {
                                      input: function ($event) {
                                        return _vm.$store.commit(
                                          "setChange",
                                          true
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.menuCopy.orders_settings[
                                          _vm.typeEnumToLowerStr(5)
                                        ].active,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.menuCopy.orders_settings[
                                            _vm.typeEnumToLowerStr(5)
                                          ],
                                          "active",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "menuCopy.orders_settings[typeEnumToLowerStr(5)].active",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "orders-settings__info" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "orders-settings__img" },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          name:
                                            "ic-availability-" +
                                            _vm.typeEnumToLowerStr(5),
                                          width: "52",
                                          height: "72",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", {
                                    staticClass: "orders-settings__title",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "_" +
                                            _vm.capitalize(
                                              _vm.typeEnumToLowerStr(5)
                                            )
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "orders-settings__params",
                                  class: {
                                    disable:
                                      !_vm.menuCopy.orders_settings[
                                        _vm.typeEnumToLowerStr(5)
                                      ].active,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "orders-settings__item" },
                                    [
                                      _c("div", {
                                        staticClass: "orders-settings__label",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("Settings")
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.orderSettingskey =
                                              _vm.typeEnumToLowerStr(5)
                                            _vm.popup = 1
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "orders-settings__item",
                                      on: {
                                        click: function ($event) {
                                          _vm.orderSettingskey =
                                            _vm.typeEnumToLowerStr(5)
                                          _vm.popup = 2
                                        },
                                      },
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "orders-settings__label",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("Payment methods")
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.menuCopy.orders_settings[_vm.typeEnumToLowerStr(4)]
                        ? _c(
                            "div",
                            {
                              key: _vm.typeEnumToLowerStr(4),
                              staticClass: "orders-settings",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "orders-settings__switch" },
                                [
                                  _c("swich-button", {
                                    on: {
                                      input: function ($event) {
                                        return _vm.$store.commit(
                                          "setChange",
                                          true
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.menuCopy.orders_settings[
                                          _vm.typeEnumToLowerStr(4)
                                        ].active,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.menuCopy.orders_settings[
                                            _vm.typeEnumToLowerStr(4)
                                          ],
                                          "active",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "menuCopy.orders_settings[typeEnumToLowerStr(4)].active",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "orders-settings__info" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "orders-settings__img" },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          name: "ic-availability-drive-through",
                                          width: "52",
                                          height: "72",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", {
                                    staticClass: "orders-settings__title",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "_" +
                                            _vm.capitalize(
                                              _vm.typeEnumToLowerStr(4)
                                            )
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "orders-settings__params",
                                  class: {
                                    disable:
                                      !_vm.menuCopy.orders_settings[
                                        _vm.typeEnumToLowerStr(4)
                                      ].active,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "orders-settings__item" },
                                    [
                                      _c("div", {
                                        staticClass: "orders-settings__label",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("Settings")
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.orderSettingskey =
                                              _vm.typeEnumToLowerStr(4)
                                            _vm.popup = 1
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "orders-settings__item",
                                      on: {
                                        click: function ($event) {
                                          _vm.orderSettingskey =
                                            _vm.typeEnumToLowerStr(4)
                                          _vm.popup = 2
                                        },
                                      },
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "orders-settings__label",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("Payment methods")
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: +_vm.branchId ? "tab4" : "",
          fn: function () {
            return [
              _c("div", { staticClass: "popup__top d-md-block d-none" }, [
                _c("div", { staticClass: "popup__head flex bet a-center" }, [
                  _c("div", {
                    staticClass: "h1 mis0 mt20",
                    domProps: { textContent: _vm._s(_vm.menuCopy.name) },
                  }),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "header-back back d-flex d-md-none",
                  style: { "margin-right": "-25px", width: "103vw" },
                },
                [
                  _c(
                    "button",
                    { staticClass: "header__back", on: { click: _vm.close } },
                    [
                      _c(
                        "span",
                        { staticClass: "header-company" },
                        [
                          _c("svg-icon", {
                            staticClass: "ic-white",
                            attrs: {
                              name: "ic-arrow-left",
                              width: "16",
                              height: "12",
                            },
                          }),
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Back")) },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _vm.futureOrderSettings
                ? _c("div", { staticClass: "working-hours mt0" }, [
                    _c("div", { staticClass: "working-hours__sect" }, [
                      _c("div", { staticClass: "working-hours__row pb12" }, [
                        _c("div", { staticClass: "working-hours__item" }, [
                          _c("div", { staticClass: "input-check" }, [
                            _c("span", {
                              staticClass: "input-check__text m0",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Future days show")),
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "working-hours__item" }, [
                          _c("div", { staticClass: "input-check" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.futureOrderSettings.future_days_show,
                                  expression:
                                    "futureOrderSettings.future_days_show",
                                },
                              ],
                              staticStyle: {
                                "text-align": "center",
                                width: "60px",
                              },
                              attrs: { type: "number" },
                              domProps: {
                                value: _vm.futureOrderSettings.future_days_show,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.futureOrderSettings,
                                    "future_days_show",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "working-hours__row" }, [
                        _c("div", { staticClass: "working-hours__item" }, [
                          _c("div", {
                            staticClass: "working-hours__text",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("Stop getting orders for today at")
                              ),
                            },
                          }),
                        ]),
                        _c(
                          "div",
                          { staticClass: "working-hours__item" },
                          [
                            _c("select-box", {
                              attrs: {
                                maxWidth: "100%",
                                options: _vm.hoursList,
                              },
                              model: {
                                value:
                                  _vm.futureOrderSettings.stop_getting_order,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.futureOrderSettings,
                                    "stop_getting_order",
                                    $$v
                                  )
                                },
                                expression:
                                  "futureOrderSettings.stop_getting_order",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "working-hours__sect" }, [
                      _c("div", { staticClass: "working-hours__row" }, [
                        _c("div", { staticClass: "working-hours__item" }, [
                          _c("div", { staticClass: "input-radio" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.futureOrderSettings.only_days,
                                  expression: "futureOrderSettings.only_days",
                                },
                              ],
                              attrs: { id: "radio1", type: "radio" },
                              domProps: {
                                value: false,
                                checked: _vm._q(
                                  _vm.futureOrderSettings.only_days,
                                  false
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(
                                    _vm.futureOrderSettings,
                                    "only_days",
                                    false
                                  )
                                },
                              },
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "input-radio__label",
                                attrs: { for: "radio1" },
                              },
                              [
                                _c("span", {
                                  staticClass: "input-radio__mark",
                                }),
                                _c("span", {
                                  staticClass: "input-radio__text",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Days & hours")),
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "working-hours__item" }, [
                          _c("div", { staticClass: "input-radio" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.futureOrderSettings.only_days,
                                  expression: "futureOrderSettings.only_days",
                                },
                              ],
                              attrs: { id: "radio2", type: "radio" },
                              domProps: {
                                value: true,
                                checked: _vm._q(
                                  _vm.futureOrderSettings.only_days,
                                  true
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(
                                    _vm.futureOrderSettings,
                                    "only_days",
                                    true
                                  )
                                },
                              },
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "input-radio__label",
                                attrs: { for: "radio2" },
                              },
                              [
                                _c("span", {
                                  staticClass: "input-radio__mark",
                                }),
                                _c("span", {
                                  staticClass: "input-radio__text",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Days only")),
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "working-hours-dh",
                          class: {
                            inactive: _vm.futureOrderSettings.only_days,
                          },
                        },
                        [
                          _c("div", { staticClass: "working-hours-dh__line" }, [
                            _c("div", { staticClass: "input-check" }, [
                              _c("div", { staticClass: "input-radio" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.daysHours,
                                      expression: "daysHours",
                                    },
                                  ],
                                  attrs: { id: "radio3", type: "radio" },
                                  domProps: {
                                    value: true,
                                    checked: _vm._q(_vm.daysHours, true),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.daysHours = true
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "input-radio__label",
                                    attrs: { for: "radio3" },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "input-radio__mark",
                                    }),
                                    _c("span", {
                                      staticClass: "input-radio__text",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Every day with steps of")
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.futureOrderSettings.step,
                                  expression: "futureOrderSettings.step",
                                },
                              ],
                              attrs: { type: "number" },
                              domProps: { value: _vm.futureOrderSettings.step },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.futureOrderSettings,
                                    "step",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("div", {
                              staticClass: "working-hours-dh__text",
                              domProps: {
                                textContent: _vm._s(_vm.$t("minutes")),
                              },
                            }),
                          ]),
                          _c(
                            "div",
                            { staticClass: "working-hours-dh__line" },
                            [
                              _c("div", { staticClass: "input-check" }, [
                                _c("div", { staticClass: "input-radio" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.daysHours,
                                        expression: "daysHours",
                                      },
                                    ],
                                    attrs: { id: "radio4", type: "radio" },
                                    domProps: {
                                      value: false,
                                      checked: _vm._q(_vm.daysHours, false),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.daysHours = false
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "input-radio__label",
                                      attrs: { for: "radio4" },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "input-radio__mark",
                                      }),
                                      _c("span", {
                                        staticClass: "input-radio__text",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("Specific days and times")
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("edit-btn", {
                                staticClass: "mis12",
                                attrs: { size: 32 },
                                on: {
                                  click: function ($event) {
                                    _vm.popup = 3
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "working-hours__sect",
                        class: { inactive: !_vm.futureOrderSettings.only_days },
                      },
                      [
                        _c("div", { staticClass: "working-hours__field" }, [
                          _c("div", {
                            staticClass: "working-hours__label",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("future-order-settings Alert")
                              ),
                            },
                          }),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.futureOrderSettings.alert,
                                expression: "futureOrderSettings.alert",
                              },
                            ],
                            attrs: {
                              placeholder: _vm.$t(
                                "Describe your delivery/take away conditions in your own way"
                              ),
                            },
                            domProps: { value: _vm.futureOrderSettings.alert },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.futureOrderSettings,
                                  "alert",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "working-hours__row" }, [
                          _c("div", { staticClass: "working-hours__item" }, [
                            _c("div", {
                              staticClass: "working-hours__text",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("Get orders in POS/FAX every day at")
                                ),
                              },
                            }),
                          ]),
                          _c(
                            "div",
                            { staticClass: "working-hours__item" },
                            [
                              _c("select-box", {
                                attrs: {
                                  openToUp: "",
                                  maxWidth: "100%",
                                  options: _vm.hoursList,
                                },
                                model: {
                                  value: _vm.futureOrderSettings.get_order_time,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.futureOrderSettings,
                                      "get_order_time",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "futureOrderSettings.get_order_time",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]
          },
          proxy: true,
        },
        {
          key: +_vm.branchId ? "tab5" : "",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass: "header-back back d-flex d-md-none",
                  style: { "margin-right": "-25px", width: "103vw" },
                },
                [
                  _c(
                    "button",
                    { staticClass: "header__back", on: { click: _vm.close } },
                    [
                      _c(
                        "span",
                        { staticClass: "header-company" },
                        [
                          _c("svg-icon", {
                            staticClass: "ic-white",
                            attrs: {
                              name: "ic-arrow-left",
                              width: "16",
                              height: "12",
                            },
                          }),
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Back")) },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _c("catalog-editor-edit-menu-setting-images", {
                attrs: { itemCopy: _vm.menuCopy },
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "btns",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "form-site__btn form-site__btn_sb d-none d-md-flex",
                },
                [
                  _vm.menuCopy.id
                    ? _c("remove-btn", { on: { click: _vm.remove } })
                    : _c("div"),
                  _c("button", {
                    staticClass: "btn",
                    domProps: { textContent: _vm._s(_vm.$t("save")) },
                    on: { click: _vm.save },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
                _c(
                  "button",
                  { staticClass: "save-btn", on: { click: _vm.save } },
                  [
                    _c("svg-icon", {
                      staticClass: "ic-edit",
                      attrs: { name: "ic-save-btn", width: "93", height: "93" },
                    }),
                  ],
                  1
                ),
                _vm.menuCopy.id
                  ? _c(
                      "button",
                      { staticClass: "bin-btn", on: { click: _vm.remove } },
                      [
                        _c("svg-icon", {
                          attrs: { name: "ic-bin", width: "18", height: "20" },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "popups",
          fn: function () {
            return [
              _vm.popup === 1
                ? _c("catalog-editor-menu-dialog-settings", {
                    on: {
                      close: function ($event) {
                        _vm.popup = 0
                      },
                    },
                    model: {
                      value: _vm.menuCopy.orders_settings[_vm.orderSettingskey],
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.menuCopy.orders_settings,
                          _vm.orderSettingskey,
                          $$v
                        )
                      },
                      expression: "menuCopy.orders_settings[orderSettingskey]",
                    },
                  })
                : _vm._e(),
              _vm.popup === 2
                ? _c("catalog-editor-menu-dialog-payments", {
                    on: {
                      close: function ($event) {
                        _vm.popup = 0
                      },
                    },
                    model: {
                      value: _vm.menuCopy.orders_settings[_vm.orderSettingskey],
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.menuCopy.orders_settings,
                          _vm.orderSettingskey,
                          $$v
                        )
                      },
                      expression: "menuCopy.orders_settings[orderSettingskey]",
                    },
                  })
                : _vm._e(),
              _vm.popup === 3
                ? _c("catalog-editor-menu-dialog-working-days", {
                    on: {
                      close: function ($event) {
                        _vm.popup = 0
                      },
                    },
                    model: {
                      value: _vm.futureOrderSettings,
                      callback: function ($$v) {
                        _vm.futureOrderSettings = $$v
                      },
                      expression: "futureOrderSettings",
                    },
                  })
                : _vm._e(),
              _vm.showFinishStepEditPopup
                ? _c(
                    "base-dialog",
                    {
                      attrs: { width: "800px" },
                      on: { close: _vm.closeFinishStepEditPopup },
                    },
                    [
                      _c("div", { staticClass: "finish-step-edit-popup" }, [
                        _c("div", { staticClass: "popup__top" }, [
                          _c(
                            "div",
                            { staticClass: "popup__head flex bet a-center" },
                            [
                              _c("div", { staticClass: "h3 mis0 mt20" }, [
                                _vm._v(_vm._s(_vm.$t("Finish step edit"))),
                              ]),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "popup__content" }, [
                          _vm.currentFinishStep
                            ? _c("div", { staticClass: "form-site" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-site__content" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "form-site__group-title" },
                                      [_vm._v(_vm._s(_vm.$t("Max select")))]
                                    ),
                                    _c("form-site-field-text", {
                                      attrs: { type: "number" },
                                      model: {
                                        value: _vm.currentFinishStep.max_select,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.currentFinishStep,
                                            "max_select",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "currentFinishStep.max_select",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-site__content" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "form-site__group-title" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("Items list to display")
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("select-box", {
                                      attrs: {
                                        placeholder: _vm.$t("Select items"),
                                        options: _vm.itemsOptionsForFinishSteps,
                                        autocomplete: "",
                                      },
                                      model: {
                                        value: _vm.currentFinishStep.items,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.currentFinishStep,
                                            "items",
                                            $$v
                                          )
                                        },
                                        expression: "currentFinishStep.items",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-site__content" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "form-site__group-title" },
                                      [_vm._v(_vm._s(_vm.$t("Settings")))]
                                    ),
                                    _vm._l(
                                      _vm.currentFinishStep.settings,
                                      function (setting, settingIndex) {
                                        return _c(
                                          "div",
                                          {
                                            staticClass: "finish-step-setting",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-site__content",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-site__group-title",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("Item"))
                                                    ),
                                                  ]
                                                ),
                                                _c("select-box", {
                                                  attrs: {
                                                    "is-disabled":
                                                      setting.tag_id !== null,
                                                    placeholder:
                                                      _vm.$t("Select item"),
                                                    options:
                                                      _vm.itemsOptionsForFinishSteps,
                                                    autocomplete: "",
                                                  },
                                                  model: {
                                                    value: setting.item_id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        setting,
                                                        "item_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "setting.item_id",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-site__content",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-site__group-title",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("Tagit"))
                                                    ),
                                                  ]
                                                ),
                                                _c("select-box", {
                                                  attrs: {
                                                    "is-disabled":
                                                      setting.item_id !== null,
                                                    placeholder:
                                                      _vm.$t("Select tag"),
                                                    options:
                                                      _vm.tagsOptionsForFinishSteps,
                                                    autocomplete: "",
                                                  },
                                                  model: {
                                                    value: setting.tag_id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        setting,
                                                        "tag_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "setting.tag_id",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-site__content",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-site__group-title",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("Buy up to")
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("form-site-field-text", {
                                                  attrs: {
                                                    type: "number",
                                                    min: "0",
                                                  },
                                                  model: {
                                                    value: setting.buy,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        setting,
                                                        "buy",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "setting.buy",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-site__content",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-site__group-title",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "Finish step get"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("form-site-field-text", {
                                                  attrs: {
                                                    type: "number",
                                                    min: "0",
                                                  },
                                                  model: {
                                                    value: setting.get,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        setting,
                                                        "get",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "setting.get",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "finish-step-setting-actions",
                                              },
                                              [
                                                _c("remove-btn", {
                                                  attrs: {
                                                    "only-icon": true,
                                                    size: 36,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeCurrentFinishStepSetting(
                                                        settingIndex
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _c("div", { staticClass: "line-more" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "line-more__content",
                                      attrs: { type: "button" },
                                      on: {
                                        click: _vm.addCurrentFinishStepSetting,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "line-more__icon" },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "ic-plus",
                                              width: "12",
                                              height: "12",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "line-more__text" },
                                        [_vm._v(_vm._s(_vm.$t("Add setting")))]
                                      ),
                                    ]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                        _c(
                          "div",
                          { staticClass: "form-site__btn form-site__btn_sb" },
                          [
                            _c("remove-btn", {
                              on: { click: _vm.removeCurrentFinishStep },
                            }),
                            _c("button", {
                              staticClass: "btn",
                              domProps: { textContent: _vm._s(_vm.$t("Save")) },
                              on: { click: _vm.saveCurrentFinishStep },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }