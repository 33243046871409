var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.recordingUrl
    ? _c("div", { attrs: { "data-test": "recording" } }, [
        _c(
          "div",
          {
            staticClass: "player",
            class: { playing: _vm.playingACall, opened: _vm.showing },
            on: {
              click: function ($event) {
                if ($event.target !== $event.currentTarget) {
                  return null
                }
                return _vm.close.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "popup-wrap" }, [
              _c("audio", {
                ref: "call-player",
                staticStyle: { display: "none" },
                attrs: { controls: true, preload: "metadata" },
              }),
              _c("div", { staticClass: "popup" }, [
                _c(
                  "button",
                  { staticClass: "popup__close", on: { click: _vm.close } },
                  [
                    _c("svg-icon", {
                      attrs: {
                        name: "player-popup__close",
                        width: "32",
                        height: "32",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "button",
                  { staticClass: "download", on: { click: _vm.download } },
                  [
                    _c("svg-icon", {
                      attrs: {
                        name: "player-download",
                        width: "32",
                        height: "32",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "audio-control" }, [
                  _c("div", {
                    staticClass: "phone",
                    domProps: { textContent: _vm._s(_vm.data.phone) },
                  }),
                  _c("div", { staticClass: "player-btns" }, [
                    _c(
                      "button",
                      {
                        staticClass: "back",
                        on: {
                          click: function ($event) {
                            return _vm.changeCurrentTime(-10)
                          },
                        },
                      },
                      [
                        _c("svg-icon", {
                          attrs: {
                            name: "player-back",
                            width: "12",
                            height: "12",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "button",
                      { staticClass: "play", on: { click: _vm.play } },
                      [
                        _c("svg-icon", {
                          attrs: {
                            name: _vm.playingACall
                              ? "player-pause"
                              : "player-play",
                            width: "32",
                            height: "32",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "next",
                        on: {
                          click: function ($event) {
                            return _vm.changeCurrentTime(10)
                          },
                        },
                      },
                      [
                        _c("svg-icon", {
                          attrs: {
                            name: "player-next",
                            width: "12",
                            height: "12",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "process-bar-container",
                      on: { mousedown: _vm.jump, mousemove: _vm.moveJump },
                    },
                    [
                      _c("div", {
                        ref: "process-bar",
                        staticClass: "process-bar",
                      }),
                      _c("div", {
                        staticClass: "process",
                        style: { width: _vm.process + "px" },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "ball",
                          style: { left: _vm.process - 8 + "px" },
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "16",
                                height: "16",
                                viewBox: "0 0 16 16",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("circle", {
                                attrs: {
                                  cx: "8",
                                  cy: "8",
                                  r: "8",
                                  fill: "white",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("span", { staticClass: "time" }, [
                    _c("div", {
                      staticClass: "time-now",
                      domProps: { textContent: _vm._s(_vm.time) },
                    }),
                    _c("div", {
                      staticClass: "time-end",
                      domProps: { textContent: _vm._s(_vm.timeEnd) },
                    }),
                  ]),
                ]),
              ]),
              _c(
                "button",
                { staticClass: "player-close", on: { click: _vm.close } },
                [
                  _c("svg-icon", {
                    attrs: {
                      name: "ic-close-circle",
                      width: "32",
                      height: "32",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _c("button", {
          staticClass: "btn-table-play",
          class: { stop: _vm.playingACall },
          on: { click: _vm.playCall },
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }