var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "payments-statistics-box flex col relative" },
    [
      _c(
        "span",
        { class: "to-end" },
        [
          _c("header", { staticClass: "flex bet" }, [
            _c("span", { staticClass: "flex" }, [
              _c("span", {
                domProps: { textContent: _vm._s(_vm.$t("Payments")) },
              }),
            ]),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "row",
                  "justify-content": "center",
                  gap: "10px",
                },
              },
              [_c("img", { attrs: { src: _vm.ordersIcon, alt: "" } })]
            ),
          ]),
          [
            _c("div", { staticClass: "row-orders" }, [
              _c(
                "div",
                { staticClass: "datao" },
                [
                  _vm._l(_vm.allPayments, function (payment) {
                    return _vm.allPayments.length >= 1
                      ? _c("div", { staticClass: "all_lineo" }, [
                          _c("div", { staticClass: "text_line" }, [
                            payment
                              ? _c("div", { staticClass: "statistic-text" }, [
                                  _c("span", {
                                    staticStyle: { "font-weight": "500" },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.getNamePartCke(payment)
                                      ),
                                    },
                                  }),
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "statistic-amount" },
                              [
                                payment
                                  ? _c("format-currency", {
                                      staticClass: "total",
                                      attrs: { data: payment.amount },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _vm.forDates && _vm.forDates.length > 0
                            ? _c("div", { staticClass: "tooltip" }, [
                                _vm.forDates && _vm.forDates.length > 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "tooltiptext",
                                        staticStyle: {
                                          display: "flex",
                                          "flex-direction": "column",
                                        },
                                      },
                                      [
                                        _c("div", [
                                          _vm.forDates &&
                                          _vm.forDates.length > 0
                                            ? _c("span", {
                                                staticStyle: {
                                                  "font-weight": "bold",
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t("compare dates")
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                        ]),
                                        _vm._l(_vm.forDates, function (date) {
                                          return _vm.forDates &&
                                            _vm.forDates.length > 0
                                            ? _c("div", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "black",
                                                    },
                                                  },
                                                  [_vm._v(" " + _vm._s(date))]
                                                ),
                                              ])
                                            : _vm._e()
                                        }),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _c("div", { staticClass: "perstage" }, [
                                  payment.per
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "traffic flex a-center",
                                          class: payment.per
                                            ? payment.per > 0
                                              ? "traffic-up"
                                              : "traffic-down"
                                            : "zero",
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "mie4",
                                            staticStyle: { color: "#e34836" },
                                            domProps: {
                                              textContent: _vm._s(
                                                Math.abs(
                                                  payment.per.toFixed(1)
                                                ) + "%"
                                              ),
                                            },
                                          }),
                                          _c("svg", {
                                            attrs: {
                                              width: "12",
                                              height: "12",
                                              viewBox: "0 0 12 12",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e()
                  }),
                  _c("section", { staticClass: "flex a-end bet mb18" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "end",
                          width: "100%",
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "flex average" },
                          [
                            _c("format-currency", {
                              attrs: {
                                data: _vm.statistic.average
                                  ? _vm.statistic.average
                                  : 0,
                              },
                            }),
                            _c("span", {
                              staticClass: "mis6",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Average")),
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "graph" },
                [
                  _c("single-chart", {
                    attrs: { summary: _vm.allPayments, bruto: _vm.bruto },
                  }),
                ],
                1
              ),
            ]),
          ],
        ],
        2
      ),
      _c("br"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }