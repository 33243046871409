var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-dialog", { on: { close: _vm.close } }, [
    _c("div", { staticClass: "backup-branch-edit" }, [
      _c("div", { staticClass: "form-site" }, [
        _c(
          "div",
          {
            style: {
              display: "grid",
              "justify-content": "center",
              width: "100%",
            },
          },
          [
            _c("div", { staticClass: "fields-row" }, [
              _c("div", { staticClass: "field-wrapper" }, [
                _c("span", {
                  staticClass: "form-site__label",
                  domProps: { textContent: _vm._s(_vm.$t("price")) },
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.price,
                      expression: "price",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.price },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.price = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "field-wrapper" }, [
                _c("span", {
                  staticClass: "form-site__label",
                  domProps: { textContent: _vm._s(_vm.$t("minim")) },
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.minimum,
                      expression: "minimum",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.minimum },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.minimum = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "fields-row" }, [
              _c("div", { staticClass: "field-wrapper" }, [
                _c("span", {
                  staticClass: "form-site__label",
                  domProps: { textContent: _vm._s(_vm.$t("Estimated time")) },
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.estimated_time,
                      expression: "estimated_time",
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: _vm.estimated_time },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.estimated_time = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
          ]
        ),
      ]),
      !_vm.mobile
        ? _c(
            "div",
            {
              staticClass: "form-site__btn md-none",
              style: {
                display: "flex",
                width: "100%",
                "justify-content": "center",
              },
            },
            [
              _c("button", {
                staticClass: "btn",
                attrs: { type: "button" },
                domProps: { textContent: _vm._s(_vm.$t("save")) },
                on: { click: _vm.saveBackupBranches },
              }),
            ]
          )
        : _vm._e(),
      _vm.mobile
        ? _c(
            "div",
            {
              staticClass: "form-site__btn",
              style: {
                display: "flex",
                width: "100%",
                "justify-content": "center",
              },
            },
            [
              _c("button", {
                staticClass: "btn",
                attrs: { type: "button" },
                domProps: { textContent: _vm._s(_vm.$t("save")) },
                on: { click: _vm.saveBackupBranches },
              }),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }