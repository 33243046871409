var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "catalog-editor-edit-item-make-lines" },
    [
      _c("div", { staticClass: "new-item-info__top" }, [
        _c("div", {
          staticClass: "new-item-info__head",
          domProps: {
            textContent: _vm._s(
              _vm.he ||
                !_vm.itemCopy.name_en ||
                _vm.itemCopy.name_en === "undefined"
                ? _vm.itemCopy.name
                : _vm.itemCopy.name_en
            ),
          },
        }),
      ]),
      _vm.makeLines.length
        ? [
            _c(
              "div",
              { staticClass: "availability-check-list rp5" },
              _vm._l(_vm.typesEnumValues, function (k) {
                return _c(
                  "label",
                  {
                    key: _vm
                      .typeEnumToStr(k)
                      .replace(" ", "_")
                      .replace(" ", "_"),
                    staticClass: "availability-check",
                    class: { active: _vm.key === k },
                    style: { width: "100%" },
                    on: {
                      click: function ($event) {
                        _vm.key = k
                      },
                    },
                  },
                  [
                    _c("svg-icon", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.key ===
                            _vm
                              .typeEnumToStr(k)
                              .toLowerCase()
                              .replace(" ", "_"),
                          expression:
                            "key === typeEnumToStr(k).toLowerCase().replace(' ', '_')",
                        },
                      ],
                      attrs: {
                        name: "availability-check-v",
                        width: "16",
                        height: "12",
                      },
                    }),
                    _c("svg-icon", {
                      staticClass: "icon",
                      attrs: {
                        name: _vm.typeEnumToIcons(k),
                        width: "30",
                        height: "25",
                      },
                    }),
                    _c("span", {
                      staticClass: "input-check__text",
                      domProps: {
                        textContent: _vm._s(_vm.$t(_vm.typeEnumToStr(k))),
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
            _c("div", { staticClass: "table-container" }, [
              _c("table", [
                _c("thead", [
                  _c(
                    "tr",
                    _vm._l(
                      [
                        "",
                        "open",
                        "in_preparation",
                        "ready",
                        "on_delivery",
                        "closed",
                        "canceled",
                      ],
                      function (km) {
                        return _c("td", {
                          key: km,
                          domProps: {
                            textContent: _vm._s(_vm.$t(_vm.capitalize(km))),
                          },
                        })
                      }
                    ),
                    0
                  ),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.makeLines, function (line) {
                    return _c(
                      "tr",
                      { key: line.id },
                      [
                        _c("td", [
                          _c("div", [
                            _c("div", {
                              staticClass: "first",
                              domProps: { textContent: _vm._s(line.name) },
                            }),
                            _c("div", {
                              staticClass: "preparation-time-title",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Preparation time")),
                              },
                            }),
                          ]),
                        ]),
                        _vm._l(
                          [
                            "open",
                            "in_preparation",
                            "ready",
                            "on_delivery",
                            "closed",
                            "canceled",
                          ],
                          function (ki) {
                            return _c("td", { key: ki }, [
                              _c(
                                "label",
                                [
                                  _c("checkbox", {
                                    attrs: {
                                      name: ki + line.id,
                                      value: _vm.checkboxValue(ki, line.id),
                                      disabled: !_vm.checkPermissionPath(
                                        "catalog_editor.full_access"
                                      ),
                                      inline: true,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.checkboxInput(
                                          $event,
                                          ki,
                                          line.id
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("input", {
                                staticClass: "preparation-time-input",
                                attrs: { type: "number" },
                                domProps: {
                                  value: _vm.preparationTimeValue(ki, line.id),
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.preparationTimeInput(
                                      $event,
                                      ki,
                                      line.id
                                    )
                                  },
                                },
                              }),
                            ])
                          }
                        ),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]
        : _c("button", {
            staticClass: "new-make-line",
            domProps: { textContent: _vm._s(_vm.$t("Create new line")) },
            on: {
              click: function ($event) {
                _vm.props.popup = true
              },
            },
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }