var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: { width: "900px", title: "Payment method" },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _vm.inIfarame
        ? _c(
            "div",
            { staticClass: "popup__content-iframe" },
            [
              _c("Iframe", {
                attrs: { width: "810", height: "600", src: _vm.iFrameSrc },
              }),
            ],
            1
          )
        : _c("div", { staticClass: "popup__content pb40" }, [
            _c("div", { staticClass: "btns" }, [
              _c(
                "button",
                { on: { click: _vm.setInIfarame } },
                [
                  _c("svg-icon", {
                    attrs: { name: "credit-card", width: "126", height: "126" },
                  }),
                  _c("div", {
                    staticClass: "text",
                    domProps: { textContent: _vm._s(_vm.$t("Credit card")) },
                  }),
                ],
                1
              ),
              _c(
                "button",
                { on: { click: _vm.setCash } },
                [
                  _c("svg-icon", {
                    attrs: { name: "cash", width: "126", height: "126" },
                  }),
                  _c("div", {
                    staticClass: "text",
                    domProps: { textContent: _vm._s(_vm.$t("Cash")) },
                  }),
                ],
                1
              ),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }