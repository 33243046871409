var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-category-items-list" },
    _vm._l(_vm.categories, function (category) {
      return _c("order-category-items", {
        key: category.id,
        attrs: { category: category },
        on: {
          input: function ($event) {
            return _vm.categoryInput(category, $event)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }