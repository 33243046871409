import { render, staticRenderFns } from "./barrage_gates.vue?vue&type=template&id=1cdcfe6e&"
import script from "./barrage_gates.vue?vue&type=script&lang=ts&"
export * from "./barrage_gates.vue?vue&type=script&lang=ts&"
import style0 from "./barrage_gates.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/dorshoshan/Documents/DEV/Atmos-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1cdcfe6e')) {
      api.createRecord('1cdcfe6e', component.options)
    } else {
      api.reload('1cdcfe6e', component.options)
    }
    module.hot.accept("./barrage_gates.vue?vue&type=template&id=1cdcfe6e&", function () {
      api.rerender('1cdcfe6e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/route-components/settings/barrage_gates.vue"
export default component.exports