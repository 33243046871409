var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "header__content a-start gap16",
      class: { pis12: _vm.backMode },
    },
    [
      _c("div", { staticClass: "header__company" }, [
        !_vm.backMode
          ? _c(
              "button",
              {
                staticClass: "ic-hamburger",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.$store.commit("toggleSidebar")
                  },
                },
              },
              [_c("span")]
            )
          : _vm._e(),
        _vm.backMode
          ? _c(
              "button",
              {
                staticClass: "btn-back",
                on: {
                  click: function ($event) {
                    return _vm.$store.commit("derogateBackLevel")
                  },
                },
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "16",
                      height: "12",
                      viewBox: "0 0 16 12",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M5.22151 11.4614C5.44719 11.6794 5.80247 11.6794 6.02815 11.4614C6.2462 11.2357 6.2462 10.8723 6.02815 10.6547L1.93805 6.56465L15.4353 6.56465C15.7499 6.56465 16 6.31458 16 5.99996C16 5.68533 15.7499 5.42713 15.4353 5.42713L1.93805 5.42713L6.02815 1.34517C6.2462 1.1195 6.2462 0.764212 6.02815 0.538538C5.80247 0.320487 5.44719 0.320487 5.22151 0.538538L0.163155 5.59689C-0.0548954 5.81494 -0.0548954 6.17785 0.163155 6.40353L5.22151 11.4614Z",
                        fill: "white",
                      },
                    }),
                  ]
                ),
                _c("span", {
                  domProps: { textContent: _vm._s(_vm.$t("Back")) },
                }),
              ]
            )
          : _c("div", { staticClass: "header-company" }, [
              _vm.showTenantSelect
                ? _c(
                    "div",
                    { staticClass: "header-company__name" },
                    [
                      _c("select-box", {
                        attrs: {
                          translate: false,
                          "is-fit": false,
                          "show-search": true,
                          options: _vm.tenantsOptions,
                          placeholder: _vm.$t("Select tenant"),
                          "view-id": _vm.isAdmin,
                          "show-val": false,
                          "is-scroll": true,
                          "search-by-value": true,
                        },
                        model: {
                          value: _vm.tenantName,
                          callback: function ($$v) {
                            _vm.tenantName = $$v
                          },
                          expression: "tenantName",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showRestaurantSelect
                ? _c(
                    "div",
                    { staticClass: "header-company__name" },
                    [
                      _c("select-box", {
                        attrs: {
                          translate: false,
                          "is-fit": false,
                          "show-search": true,
                          options: _vm.restaurantsOptions,
                          "view-id": _vm.isAdmin,
                          "show-val": true,
                          "is-scroll": true,
                          "search-by-value": true,
                        },
                        scopedSlots: _vm._u(
                          [
                            _vm.restaurant && _vm.restaurant.icon
                              ? {
                                  key: "logo",
                                  fn: function () {
                                    return [
                                      _c("img", {
                                        staticClass: "logo-select-box",
                                        attrs: {
                                          src: _vm.restaurant.icon,
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : null,
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.restaurantId,
                          callback: function ($$v) {
                            _vm.restaurantId = $$v
                          },
                          expression: "restaurantId",
                        },
                      }),
                    ],
                    1
                  )
                : _c("div", { staticClass: "header-company__name" }, [
                    _vm.restaurant && _vm.restaurant.icon
                      ? _c("img", {
                          attrs: {
                            src: _vm.restaurant.icon,
                            alt: _vm.restaurantName,
                          },
                        })
                      : _vm._e(),
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.restaurantName) },
                    }),
                  ]),
              _vm.showBranchSelect
                ? _c(
                    "div",
                    { staticClass: "header-company_branch" },
                    [
                      _c("select-box", {
                        attrs: {
                          translate: false,
                          maxWidth: "calc(80vw - 70px)",
                          "is-fit": false,
                          "show-search": true,
                          options: _vm.branchesOptions,
                          "empty-option": _vm.emptyOptionAllBranches,
                          placeholder: _vm.$t("All branches"),
                          "view-id": _vm.isAdmin,
                          "show-val": true,
                          "is-scroll": true,
                          "search-by-value": true,
                          "ul-start-mobil": "-50px",
                        },
                        model: {
                          value: _vm.branchId,
                          callback: function ($$v) {
                            _vm.branchId = $$v
                          },
                          expression: "branchId",
                        },
                      }),
                    ],
                    1
                  )
                : _vm.branchesOptions.length === 1 && _vm.branch
                ? _c("div", { staticClass: "header-company_branch-limit" }, [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.branch.name) },
                    }),
                  ])
                : _vm._e(),
            ]),
      ]),
      _c("header-profile", { attrs: { viewSearch: _vm.viewSearch } }),
      _vm.viewSearch
        ? _c("header-search", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.backMode,
                expression: "!backMode",
              },
            ],
          })
        : _vm._e(),
      _c("user-activity-checker"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }