var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      staticClass: "edit-member-club-restaurant-dialog",
      attrs: { title: _vm.restaurantDetails.name, width: "817px" },
      on: { close: _vm.close },
    },
    [
      _c("div", { staticClass: "popup__content" }, [
        _c("div", { staticClass: "form-lang" }, [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "form-site" }, [
                _c("div", { staticClass: "form-site__content" }, [
                  _c("div", { staticClass: "form-site__group" }, [
                    _c(
                      "div",
                      { staticClass: "form-site__field form-site__field_full" },
                      [
                        _c("div", { staticClass: "form-site__label-wrap" }, [
                          _c("div", {
                            staticClass: "form-site__label",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Description")),
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "form-site-msg" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.req.description,
                                expression: "req.description",
                              },
                            ],
                            domProps: { value: _vm.req.description },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.req,
                                  "description",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "form-site__field" }, [
                      _c("div", { staticClass: "form-site__input" }, [
                        _c("div", { staticClass: "edit-img" }, [
                          _c("div", {
                            staticClass: "edit-img__label",
                            domProps: { textContent: _vm._s(_vm.$t("Logo")) },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "edit-img__content",
                              class: { "edit-img__content_empty": !_vm.logo },
                              style: "background-image: url(" + _vm.logo + ")",
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "edit-img__icon" },
                                [
                                  _vm.logo
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "edit-img__icon deg45",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.removeImage("logo")
                                            },
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "ic-plus",
                                              width: "22",
                                              height: "22",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : [
                                        _c("input", {
                                          attrs: {
                                            type: "file",
                                            accept: "image/*",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.enterImage(
                                                $event,
                                                "logo"
                                              )
                                            },
                                          },
                                        }),
                                        _c("svg-icon", {
                                          attrs: {
                                            name: "ic-plus",
                                            width: "22",
                                            height: "22",
                                          },
                                        }),
                                      ],
                                ],
                                2
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-site__field" }, [
                      _c("div", { staticClass: "form-site__input" }, [
                        _c("div", { staticClass: "edit-img" }, [
                          _c("div", {
                            staticClass: "edit-img__label",
                            domProps: { textContent: _vm._s(_vm.$t("Icon")) },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "edit-img__content",
                              class: { "edit-img__content_empty": !_vm.icon },
                              style: "background-image: url(" + _vm.icon + ")",
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "edit-img__icon" },
                                [
                                  _vm.icon
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "edit-img__icon deg45",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.removeImage("icon")
                                            },
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "ic-plus",
                                              width: "22",
                                              height: "22",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : [
                                        _c("input", {
                                          attrs: {
                                            type: "file",
                                            accept: "image/*",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.enterImage(
                                                $event,
                                                "icon"
                                              )
                                            },
                                          },
                                        }),
                                        _c("svg-icon", {
                                          staticClass: "icon ic-plus",
                                          attrs: {
                                            name: "ic-plus",
                                            width: "22",
                                            height: "22",
                                          },
                                        }),
                                      ],
                                ],
                                2
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", {
                    staticClass: " mis40 edit-img__label",
                    domProps: { textContent: _vm._s(_vm.$t("Photos gallery")) },
                  }),
                  _c("form", {
                    staticClass: "dropzone",
                    attrs: { action: "/upload", id: "dropzone-form" },
                  }),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "form-site__btn pb40 form-site__btn_sb d-md-flex d-none",
                },
                [
                  _c("div"),
                  _c("button", {
                    staticClass: "btn",
                    domProps: { textContent: _vm._s(_vm.$t("save")) },
                    on: { click: _vm.save },
                  }),
                ]
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
          _c(
            "button",
            { staticClass: "save-btn", on: { click: _vm.save } },
            [
              _c("svg-icon", {
                staticClass: "ic-edit",
                attrs: { name: "ic-save-btn", width: "93", height: "93" },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }