var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-settings-branch edt" },
    [
      _c("base-dialog", { on: { close: _vm.close } }, [
        _c("div", { staticClass: "popup__top" }, [
          _c("div", { staticClass: "popup__head md-none" }, [
            _c("div", {
              staticClass: "h1 m0",
              domProps: {
                textContent: _vm._s(
                  _vm.$t(
                    _vm.branchInEditCopy.id != 0
                      ? _vm.branchInEditCopy.name
                      : "Create branch group"
                  )
                ),
              },
            }),
          ]),
          _c("div", { staticClass: "popup__lang a-start d-md-none" }, [
            _c("div", {
              staticClass: "h1 pt10",
              domProps: {
                textContent: _vm._s(
                  _vm.$t(
                    _vm.branchInEditCopy.id != 0
                      ? _vm.branchInEditCopy.name
                      : "Create branch group"
                  )
                ),
              },
            }),
            _c("div", { staticClass: "flex col a-end p0" }),
          ]),
        ]),
        _c("div", { staticClass: "popup__content" }, [
          _c(
            "form",
            {
              staticClass: "form-site-wrap",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "form-site" }, [
                _c(
                  "div",
                  { staticClass: "form-site__content form-site_mobile" },
                  [
                    _c(
                      "div",
                      { staticClass: "input-label" },
                      [
                        _c("div", {
                          staticClass: "form-site__label",
                          domProps: { textContent: _vm._s(_vm.$t("Branches")) },
                        }),
                        _c("select-box", {
                          staticClass: "select__branches",
                          attrs: {
                            options: _vm.branchesOptions,
                            maxWidth: "calc(80vw - 70px)",
                          },
                          model: {
                            value: _vm.branchInEditCopy.branches,
                            callback: function ($$v) {
                              _vm.$set(_vm.branchInEditCopy, "branches", $$v)
                            },
                            expression: "branchInEditCopy.branches",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "input-label" }, [
                      _c("div", {
                        staticClass: "form-site__label",
                        domProps: {
                          textContent: _vm._s(_vm.$t("Branch group name")),
                        },
                      }),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.branchInEditCopy.name,
                            expression: "branchInEditCopy.name",
                          },
                        ],
                        ref: "name",
                        staticClass: "input-branch-group mb12",
                        attrs: {
                          type: "text",
                          placeholder: _vm.branchInEditCopy.name
                            ? _vm.branchInEditCopy.name
                            : _vm.$t("Branch group name"),
                        },
                        domProps: { value: _vm.branchInEditCopy.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.branchInEditCopy,
                              "name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "form-site__btn md-none" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between",
                      width: "100%",
                    },
                  },
                  [
                    _vm.branchInEditCopy.id != 0
                      ? _c("remove-btn", { on: { click: _vm.remove } })
                      : _vm._e(),
                    _c("button", {
                      staticClass: "btn",
                      domProps: { textContent: _vm._s(_vm.$t("Save")) },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.save.apply(null, arguments)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
          _c(
            "button",
            { staticClass: "save-btn", on: { click: _vm.save } },
            [
              _c("svg-icon", {
                staticClass: "ic-edit",
                attrs: { name: "ic-save-btn", width: "93", height: "93" },
              }),
            ],
            1
          ),
          _vm.branchInEditCopy.id != 0
            ? _c(
                "button",
                { staticClass: "bin-btn", on: { click: _vm.remove } },
                [
                  _c("svg-icon", {
                    attrs: { name: "ic-bin", width: "18", height: "20" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }