var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-dialog-tabs", {
    staticClass: "edit-audiences-dialog",
    style: { height: "800px" },
    attrs: { tabList: ["General", "Availability"] },
    on: { close: _vm.close },
    scopedSlots: _vm._u([
      {
        key: "tab0",
        fn: function () {
          return [
            _c("div", { staticClass: "popup__content" }, [
              _c("div", { staticClass: "form-new-address" }, [
                _c(
                  "form",
                  {
                    staticClass: "form-site-wrap",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.save.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "form-site" }, [
                      _c("div", { staticClass: "form-site__content" }, [
                        _c(
                          "div",
                          { staticClass: "form-site__field-wrap mb12" },
                          [
                            _c("form-site-field-text", {
                              attrs: { title: "Audience name" },
                              model: {
                                value: _vm.audienceInEdit.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.audienceInEdit, "name", $$v)
                                },
                                expression: "audienceInEdit.name",
                              },
                            }),
                            _c("form-site-field-text", {
                              attrs: { short: "", title: "Minimum amount" },
                              model: {
                                value: _vm.audienceInEdit.min_amount,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.audienceInEdit,
                                    "min_amount",
                                    $$v
                                  )
                                },
                                expression: "audienceInEdit.min_amount",
                              },
                            }),
                            _c("form-site-field-text", {
                              attrs: { short: "", title: "Maximum amount" },
                              model: {
                                value: _vm.audienceInEdit.max_amount,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.audienceInEdit,
                                    "max_amount",
                                    $$v
                                  )
                                },
                                expression: "audienceInEdit.max_amount",
                              },
                            }),
                            _c("div", { staticClass: "subscription-status" }, [
                              _c(
                                "label",
                                { staticClass: "members-club__input" },
                                [
                                  _c("checkbox", {
                                    attrs: { name: "subscribed" },
                                    model: {
                                      value: _vm.subscribed,
                                      callback: function ($$v) {
                                        _vm.subscribed = $$v
                                      },
                                      expression: "subscribed",
                                    },
                                  }),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Subscribed")),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "label",
                                { staticClass: "members-club__input" },
                                [
                                  _c("checkbox", {
                                    attrs: { name: "unsubscribed" },
                                    model: {
                                      value: _vm.unsubscribed,
                                      callback: function ($$v) {
                                        _vm.unsubscribed = $$v
                                      },
                                      expression: "unsubscribed",
                                    },
                                  }),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Unsubscribed")
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "label",
                                { staticClass: "members-club__input" },
                                [
                                  _c("checkbox", {
                                    attrs: { name: "club_members" },
                                    model: {
                                      value: _vm.clubMembers,
                                      callback: function ($$v) {
                                        _vm.clubMembers = $$v
                                      },
                                      expression: "clubMembers",
                                    },
                                  }),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Club members")
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "label",
                                { staticClass: "members-club__input" },
                                [
                                  _c("checkbox", {
                                    attrs: { name: "club_members" },
                                    model: {
                                      value: _vm.newClubMembers,
                                      callback: function ($$v) {
                                        _vm.newClubMembers = $$v
                                      },
                                      expression: "newClubMembers",
                                    },
                                  }),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("New club members")
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "members-club__content" }, [
                          _c("div", { staticClass: "input-radio mb8" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.audienceInEdit.cond,
                                  expression: "audienceInEdit.cond",
                                },
                              ],
                              attrs: {
                                id: "radio1",
                                type: "radio",
                                name: "radio",
                              },
                              domProps: {
                                value: 0,
                                checked: _vm._q(_vm.audienceInEdit.cond, 0),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(_vm.audienceInEdit, "cond", 0)
                                },
                              },
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "input-radio__label",
                                attrs: { for: "radio1" },
                              },
                              [
                                _c("span", {
                                  staticClass: "input-radio__mark",
                                }),
                                _c("span", {
                                  staticClass: "input-radio__text",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("Ordered in the last")
                                    ),
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "input-radio" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.audienceInEdit.cond,
                                  expression: "audienceInEdit.cond",
                                },
                              ],
                              attrs: {
                                id: "radio2",
                                type: "radio",
                                name: "radio",
                              },
                              domProps: {
                                value: 1,
                                checked: _vm._q(_vm.audienceInEdit.cond, 1),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(_vm.audienceInEdit, "cond", 1)
                                },
                              },
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "input-radio__label",
                                attrs: { for: "radio2" },
                              },
                              [
                                _c("span", {
                                  staticClass: "input-radio__mark",
                                }),
                                _c("span", {
                                  staticClass: "input-radio__text",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("Didn't ordered in the last")
                                    ),
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "members-club__content" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "members-club__field members-club__field_short md-members-club__field",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "members-club__input" },
                                [
                                  _c("div", { staticClass: "input-label" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.audienceInEdit.time_interval,
                                          expression:
                                            "audienceInEdit.time_interval",
                                        },
                                      ],
                                      attrs: { type: "text" },
                                      domProps: {
                                        value: _vm.audienceInEdit.time_interval,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.audienceInEdit,
                                            "time_interval",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "members-club__field members-club__field_short md-members-club__field",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "members-club__input" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "input-label" },
                                    [
                                      _c("select-box", {
                                        attrs: {
                                          maxWidth: "150px",
                                          unsort: "",
                                          options: _vm.periodOptions,
                                        },
                                        model: {
                                          value: _vm.audienceInEdit.period,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.audienceInEdit,
                                              "period",
                                              $$v
                                            )
                                          },
                                          expression: "audienceInEdit.period",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "label",
                          { staticClass: "members-club__input" },
                          [
                            _c("checkbox", {
                              attrs: { name: "club_members" },
                              model: {
                                value: _vm.firstOrder,
                                callback: function ($$v) {
                                  _vm.firstOrder = $$v
                                },
                                expression: "firstOrder",
                              },
                            }),
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(_vm.$t("First order")),
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "tab1",
        fn: function () {
          return [
            _c(
              "div",
              [
                _c(
                  "div",
                  { staticClass: "form-site__field-wrap mb12" },
                  [
                    _c("form-site-field-select-box", {
                      staticClass: "select-restaurants",
                      attrs: {
                        title: "Restaurant",
                        maxWidth: "100%",
                        showSearch: "",
                        options: _vm.restaurantsOptions,
                      },
                      on: { input: _vm.changeBranch },
                      model: {
                        value: _vm.audienceInEdit.restaurant_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.audienceInEdit, "restaurant_id", $$v)
                        },
                        expression: "audienceInEdit.restaurant_id",
                      },
                    }),
                    _c("form-site-field-select-box", {
                      attrs: {
                        title: "Branch",
                        maxWidth: "100%",
                        showSearch: "",
                        options: _vm.branchesOptions,
                      },
                      on: { input: _vm.changeBranch },
                      model: {
                        value: _vm.audienceInEdit.branches_ids,
                        callback: function ($$v) {
                          _vm.$set(_vm.audienceInEdit, "branches_ids", $$v)
                        },
                        expression: "audienceInEdit.branches_ids",
                      },
                    }),
                    _c("form-site-field-select-box", {
                      attrs: {
                        title: "Delivery area",
                        maxWidth: "100%",
                        showSearch: "",
                        options: _vm.deliveryAreaOptions,
                      },
                      model: {
                        value: _vm.audienceInEdit.delivery_areas_ids,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.audienceInEdit,
                            "delivery_areas_ids",
                            $$v
                          )
                        },
                        expression: "audienceInEdit.delivery_areas_ids",
                      },
                    }),
                  ],
                  1
                ),
                _c("availability-tab", {
                  attrs: { availability: _vm.audienceInEdit.availability },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "btns",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "form-site__btn pb40 flex bet md-none" },
              [
                _vm.audienceInEdit.id
                  ? _c("remove-btn", { on: { click: _vm.remove } })
                  : _vm._e(),
                _c("label", { staticClass: "btn-icon btn-icon_green" }, [
                  _c("input", {
                    attrs: {
                      hidden: "",
                      type: "file",
                      accept:
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,xlsx",
                    },
                    on: { change: _vm.importClients },
                  }),
                  _c(
                    "span",
                    { staticClass: "btn-icon__note" },
                    [
                      _c("svg-icon", {
                        attrs: { name: "ic-import", width: "18", height: "20" },
                      }),
                    ],
                    1
                  ),
                  _c("span", {
                    staticClass: "btn-icon__text flex a-center",
                    style: { color: "#f28f16", background: "#fdecd7" },
                    domProps: { textContent: _vm._s(_vm.$t("Import")) },
                  }),
                ]),
                _vm.audienceInEdit.id
                  ? _c(
                      "button",
                      {
                        staticClass: "btn-icon btn-icon_gree",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.exportClients.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "btn-icon__note" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-export",
                                width: "20",
                                height: "20",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", {
                          staticClass: "btn-icon__text flex a-center",
                          style: { color: "#fd6779", background: "#fbecee" },
                          domProps: { textContent: _vm._s(_vm.$t("Export")) },
                        }),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    style: {
                      "margin-inline-start": "25px",
                      "margin-inline-end": "24px",
                      "min-width": "150px",
                    },
                    attrs: { type: "submit" },
                    on: { click: _vm.save },
                  },
                  [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.$t("Save")) },
                    }),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
              _c(
                "button",
                {
                  staticClass: "save-btn",
                  attrs: { type: "submit" },
                  on: { click: _vm.save },
                },
                [
                  _c("svg-icon", {
                    staticClass: "ic-edit",
                    attrs: { name: "ic-save-btn", width: "93", height: "93" },
                  }),
                ],
                1
              ),
              _c(
                "button",
                { staticClass: "bin-btn", on: { click: _vm.remove } },
                [
                  _c("svg-icon", {
                    attrs: { name: "ic-bin", width: "18", height: "20" },
                  }),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }