var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "branches-backups" }, [
    _vm.branchesOptions.length > 1
      ? _c(
          "div",
          {
            staticClass: "sidebar-menu__list",
            style: { display: "block", width: "100%", "margin-top": "30px" },
          },
          _vm._l(_vm.zones, function (zone) {
            return _c("div", { key: zone.id }, [
              _c("div", { staticClass: "backup_container" }, [
                _c("div", { staticClass: "select__zone" }, [
                  _c("span", {
                    staticClass: "zone-name",
                    domProps: { textContent: _vm._s(_vm.$t(zone.name)) },
                  }),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "select__branch",
                    attrs: { "data-id": zone.id },
                  },
                  [
                    _c("select-box", {
                      key: zone.id,
                      staticClass: "select-Branch",
                      style: { "margin-bottom": "15px" },
                      attrs: {
                        widthHeader: "100%",
                        width: "100%",
                        maxHeight: "300px",
                        placeholder: _vm.$t("select"),
                        unsort: "",
                        value: _vm.inputValue(zone.id),
                        showSearch: "",
                        options: _vm.branchesOptions,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.input($event, zone)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }