var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.show
        ? _c(
            "base-dialog",
            {
              attrs: { width: "700px", title: "SMS templates" },
              on: { close: _vm.close },
            },
            [
              _c("div", { staticClass: "popup__content pb40" }, [
                _c("div", { staticClass: "sms-templ-list" }, [
                  _c(
                    "div",
                    {
                      staticClass: "sms-templ-list__content disable-scrollbars",
                    },
                    _vm._l(_vm.templates, function (template, index) {
                      return _c(
                        "div",
                        { key: template.id, staticClass: "sms-templ" },
                        [
                          _c("div", { staticClass: "sms-templ__content" }, [
                            _c("div", {
                              staticClass: "sms-templ__title",
                              domProps: { textContent: _vm._s(template.name) },
                            }),
                            _c("div", {
                              staticClass: "sms-templ__text",
                              domProps: {
                                textContent: _vm._s(template.content),
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "sms-templ__btn" }, [
                            _c(
                              "button",
                              {
                                staticClass: "sms-templ__btn-item",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editSmsTemplate(template)
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    name: "ic-pencil",
                                    width: "16",
                                    height: "16",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "sms-templ__btn-item sms-templ__btn-item_red",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteTemplate(index)
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    name: "ic-bin",
                                    width: "14",
                                    height: "16",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                  _c("div", { staticClass: "sms-templ-list__btn" }, [
                    _c("button", {
                      staticClass: "btn",
                      domProps: { textContent: _vm._s(_vm.$t("Add template")) },
                      on: { click: _vm.addTemplate },
                    }),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _c("sms-template-edit-popup", {
        attrs: {
          show: !!_vm.currentSmsTemplate,
          template: _vm.currentSmsTemplate,
        },
        on: { closed: _vm.closeSmsTemplate, saved: _vm.saveSmsTemplate },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }