var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: { width: "600px" },
      on: {
        close: function ($event) {
          return _vm.$emit("save")
        },
      },
    },
    [
      _c("div", { staticClass: "form-site" }, [
        _c(
          "div",
          { staticClass: "form-site__field", style: { height: "180px" } },
          [
            _c("span", {
              staticClass: "form-site__label",
              domProps: { textContent: _vm._s(_vm.hintText) },
            }),
            _c("div", { staticClass: "form-site__input" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password",
                  },
                ],
                staticClass: "mb8",
                attrs: {
                  maxlength: _vm.requiredPasswordLength,
                  type: "text",
                  disabled: !_vm.manualPassword,
                },
                domProps: { value: _vm.password },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.password = $event.target.value
                  },
                },
              }),
              _c(
                "label",
                { staticClass: "flex pis8" },
                [
                  _c("checkbox", {
                    attrs: { name: "manual-password" },
                    on: {
                      input: function ($event) {
                        _vm.password = _vm.serverPassword
                      },
                    },
                    model: {
                      value: _vm.manualPassword,
                      callback: function ($$v) {
                        _vm.manualPassword = $$v
                      },
                      expression: "manualPassword",
                    },
                  }),
                  _c("div", {
                    domProps: { textContent: _vm._s(_vm.$t("Manual setting")) },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "form-site__btn form-site__btn_sb",
            style: { "margin-bottom": "-80px" },
          },
          [
            _c("div"),
            _c("button", {
              staticClass: "btn",
              style: { "margin-top": "-50px" },
              domProps: { textContent: _vm._s(_vm.$t("Ok")) },
              on: { click: _vm.setPassword },
            }),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }