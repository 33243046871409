var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      on: {
        close: function ($event) {
          return _vm.$emit("save")
        },
      },
    },
    [
      _c("div", { staticClass: "popup__content" }, [
        _c("div", { staticClass: "form-new-user" }, [
          _c(
            "form",
            {
              staticClass: "form-site-wrap",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.save.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "form-site" }, [
                _c("div", { staticClass: "form-site__content" }, [
                  _c(
                    "div",
                    { staticClass: "form-site__field-wrap" },
                    [
                      _c("form-site-field-text", {
                        attrs: { focus: "", title: "Name" },
                        model: {
                          value: _vm.printingLineCopy.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.printingLineCopy, "name", $$v)
                          },
                          expression: "printingLineCopy.name",
                        },
                      }),
                      _c("button", { attrs: { type: "submit" } }),
                      _c("form-site-field-select-box", {
                        attrs: {
                          title: "Type",
                          maxWidth: "220px",
                          options: ["Paper", "Stickers", "KDS"].map(function (
                            label,
                            value
                          ) {
                            return { label: label, value: value }
                          }),
                        },
                        model: {
                          value: _vm.printingLineCopy.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.printingLineCopy, "type", $$v)
                          },
                          expression: "printingLineCopy.type",
                        },
                      }),
                      _vm.printingLineCopy.type !== 2
                        ? _c("form-site-field-select-box", {
                            attrs: {
                              title: "Template",
                              maxWidth: "220px",
                              options: _vm.templateOptions,
                            },
                            model: {
                              value: _vm.printingLineCopy.template,
                              callback: function ($$v) {
                                _vm.$set(_vm.printingLineCopy, "template", $$v)
                              },
                              expression: "printingLineCopy.template",
                            },
                          })
                        : _vm._e(),
                      _vm.printingLineCopy.type !== 2
                        ? _c("form-site-field-select-box", {
                            attrs: {
                              title: "Reverse",
                              maxWidth: "220px",
                              options: ["No", "Yes"].map(function (
                                label,
                                value
                              ) {
                                return { label: label, value: !!value }
                              }),
                            },
                            model: {
                              value: _vm.printingLineCopy.reverse,
                              callback: function ($$v) {
                                _vm.$set(_vm.printingLineCopy, "reverse", $$v)
                              },
                              expression: "printingLineCopy.reverse",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "form-site__btn md-none" },
                [
                  _vm.printingLineCopy.id
                    ? _c("remove-btn", {
                        on: { click: _vm.removePrintingLine },
                      })
                    : _c("div"),
                  _c("button", {
                    staticClass: "btn ",
                    attrs: { type: "submit" },
                    domProps: { textContent: _vm._s(_vm.$t("Save")) },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
                _c(
                  "button",
                  { staticClass: "save-btn", attrs: { type: "submit" } },
                  [
                    _c("svg-icon", {
                      staticClass: "ic-edit",
                      attrs: { name: "ic-save-btn", width: "93", height: "93" },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }