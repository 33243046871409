var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    { attrs: { width: "614px" }, on: { close: _vm.close } },
    [
      _c("div", { staticClass: "popup__content pb40" }, [
        _c("div", { staticClass: "form-lang" }, [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "form-site" }, [
                _c(
                  "div",
                  {
                    staticClass: "header-summary",
                    style: {
                      display: "grid",
                      "justify-content": "center",
                      width: "100%",
                      "margin-bottom": "10px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "summary-pickup",
                        style: {
                          display: "flex",
                          width: "100%",
                        },
                      },
                      [
                        _c("h3", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("Summary of pickup orders")
                            ),
                          },
                        }),
                        _c("h3", {
                          style: {
                            "margin-right": "5px",
                          },
                          domProps: {
                            textContent: _vm._s(_vm.sumOfPickupOrders),
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "summary-delivery",
                        style: {
                          display: "flex",
                          width: "100%",
                        },
                      },
                      [
                        _c("h3", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("Summary of delivery orders")
                            ),
                          },
                        }),
                        _c("h3", {
                          style: {
                            "margin-right": "5px",
                          },
                          domProps: {
                            textContent: _vm._s(_vm.sumOfDeliveredOrders),
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                _c("h4", {
                  domProps: { textContent: _vm._s(_vm.$t("Choose driver")) },
                }),
                _c("section", { staticClass: "filter-by flex a-center bet" }, [
                  _c(
                    "section",
                    { staticClass: "input-container" },
                    [
                      _c("svg-icon", {
                        staticClass: "ic-stroke m-i-end-1ch",
                        staticStyle: { opacity: "0.4" },
                        attrs: { name: "ic-search", width: "16", height: "16" },
                      }),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchTerm,
                            expression: "searchTerm",
                          },
                        ],
                        attrs: {
                          type: "search",
                          placeholder: _vm.$t("Search..."),
                        },
                        domProps: { value: _vm.searchTerm },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.searchTerm = $event.target.value
                            },
                            _vm.search,
                          ],
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "messenger-list" },
                  _vm._l(_vm.allMessengers, function (messenger) {
                    return _c(
                      "button",
                      {
                        key: messenger.id,
                        staticClass: "messenger",
                        on: {
                          click: function ($event) {
                            return _vm.choose(messenger.id)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "user-details" },
                          [
                            _c("user-avatar", {
                              attrs: {
                                user: messenger,
                                "img-class": "table-client__photo",
                              },
                            }),
                            _c(
                              "section",
                              { staticClass: "flex col a-start gap4" },
                              [
                                _c("span", {
                                  staticClass: "same-line size-16 opacity-9",
                                  domProps: {
                                    textContent: _vm._s(messenger.name),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "size-14 opacity-6 md-none",
                                  domProps: {
                                    textContent: _vm._s(messenger.phone),
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          { staticClass: "save" },
                          [
                            _c("span", {
                              staticClass: "orange",
                              domProps: {
                                textContent: _vm._s(
                                  messenger.unresolved_orders.length +
                                    " " +
                                    _vm.$t("Orders left")
                                ),
                              },
                            }),
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-arry",
                                width: "8",
                                height: "14",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }