var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "devices-manager" },
    [
      _c(
        "table-list",
        _vm._b(
          {
            on: {
              changeOptions: _vm.changeOptions,
              "on-click-line": _vm.openPopup,
            },
            scopedSlots: _vm._u(
              [
                _vm.mobile
                  ? {
                      key: "above-tabel",
                      fn: function () {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "mobile-btn-plus",
                              on: { click: _vm.createDevice },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-plus",
                                  width: "16",
                                  height: "16",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : {
                      key: "btn",
                      fn: function () {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn_pink",
                              on: { click: _vm.createDevice },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-plus",
                                  width: "16",
                                  height: "16",
                                },
                              }),
                              _c("span", {
                                staticClass: "xl-none",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("New device")),
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                _vm.somethingIsChecked
                  ? {
                      key: "after-pager",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "action-popover",
                              class: { floating: _vm.actionPopoverFloating },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "minimize",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      _vm.actionPopoverFloating =
                                        !_vm.actionPopoverFloating
                                    },
                                  },
                                },
                                [
                                  _vm.actionPopoverFloating
                                    ? _c("span", [_vm._v("-")])
                                    : _c("span", [_vm._v("+")]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "action-popover-title" },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm.$t("Do action on selected brains")
                                      ) +
                                      "\n        "
                                  ),
                                ]
                              ),
                              _c("select-box", {
                                staticClass: "mb20",
                                attrs: {
                                  placeholder: _vm.$t("Select command") + "",
                                  options: _vm.deviceCommands,
                                  "first-top": "",
                                  "open-to-up": "",
                                },
                                on: { input: _vm.selectCommand },
                                model: {
                                  value: _vm.commandPressed,
                                  callback: function ($$v) {
                                    _vm.commandPressed = $$v
                                  },
                                  expression: "commandPressed",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          },
          "table-list",
          _vm.dataTable,
          false
        )
      ),
      _vm.openDetails === 1
        ? _c("show-device-details", {
            attrs: { device: _vm.selectedFleet },
            on: { save: _vm.save, close: _vm.close },
          })
        : _vm._e(),
      !!_vm.newDevice
        ? _c("create-new-device", {
            attrs: { device: _vm.newDevice },
            on: { save: _vm.saveNewDevice, close: _vm.saveNewDevice },
          })
        : _vm._e(),
      _vm.json_result
        ? _c("device-output-dialog", {
            attrs: { response: _vm.json_result },
            on: {
              close: function ($event) {
                _vm.json_result = null
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }