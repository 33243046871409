var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        staticClass: "popup-trans",
        class: { popupOn: _vm.popupOn },
        attrs: {
          "data-dialog-num": _vm.dialogNum,
          "data-dialog-count": _vm.countDialogs,
        },
      },
      [
        _c("backdrop", {
          class: { "md-block": _vm.mobFilter },
          attrs: { zIndex: 110 + _vm.dialogNum * 10 },
          on: {
            emitClose: function ($event) {
              return _vm.$emit("close")
            },
          },
        }),
        !_vm.mobFilter && !_vm.hideCloseBar
          ? _c("div", { staticClass: "header-back d-flex d-md-none" }, [
              _c(
                "button",
                {
                  staticClass: "header__back",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    { staticClass: "header-company" },
                    [
                      _c("svg-icon", {
                        staticClass: "ic-white",
                        attrs: {
                          name: "ic-arrow-left",
                          width: "16",
                          height: "12",
                        },
                      }),
                      _c("span", {
                        domProps: { textContent: _vm._s(_vm.$t("Back")) },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ])
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "popup-wrap-containsr",
            class: {
              "popup-wrap_mob-filter": _vm.mobFilter,
              hideCloseBar: _vm.hideCloseBar,
            },
            style: {
              width: _vm.mobile ? "" : _vm.width,
              "z-index": 110 + _vm.dialogNum * 10,
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "popup-wrap",
                class: {
                  overflowVisible: _vm.overflowVisible,
                  popupOn: _vm.popupOn,
                },
                style: { width: _vm.width },
              },
              [
                _c(
                  "div",
                  { staticClass: "popup" },
                  [
                    _c("close-popup-btn", {
                      attrs: { top: "-20px", end: "-18px" },
                      on: {
                        close: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    }),
                    _vm.title
                      ? _c("div", { staticClass: "popup__top" }, [
                          _c("div", { staticClass: "popup__head" }, [
                            _c("div", {
                              staticClass: "h1",
                              domProps: {
                                textContent: _vm._s(_vm.$t(_vm.title)),
                              },
                            }),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._t("default"),
                  ],
                  2
                ),
              ]
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }