var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isDashboard
      ? _c(
          "span",
          { staticClass: "statistics-box action-statistics-box flex col bet" },
          [
            _c("span", { staticClass: "flex bet w100 a-center" }, [
              !_vm.refresh || _vm.statistic.amount !== ""
                ? _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.filters.abbreviate(_vm.statistic.amount)
                      ),
                    },
                  })
                : _vm.refresh && !_vm.sent
                ? _c("button", {
                    staticClass: "p12",
                    domProps: { textContent: _vm._s(_vm.$t("Show")) },
                    on: { click: _vm.send },
                  })
                : _c("span", [
                    _c(
                      "svg",
                      {
                        staticStyle: {
                          margin: "auto",
                          background:
                            "rgb(255, 255, 255) none repeat scroll 0 0",
                          display: "block",
                          "shape-rendering": "auto",
                        },
                        attrs: {
                          width: "48px",
                          height: "48px",
                          viewBox: "0 0 100 100",
                          preserveAspectRatio: "xMidYMid",
                        },
                      },
                      [
                        _c(
                          "circle",
                          {
                            staticClass: "main-second-stroke",
                            attrs: {
                              cx: "50",
                              cy: "50",
                              r: "32",
                              "stroke-width": "8",
                              "stroke-dasharray":
                                "50.26548245743669 50.26548245743669",
                              fill: "none",
                              "stroke-linecap": "round",
                            },
                          },
                          [
                            _c("animateTransform", {
                              attrs: {
                                attributeName: "transform",
                                type: "rotate",
                                repeatCount: "indefinite",
                                dur: "1s",
                                keyTimes: "0;1",
                                values: "0 50 50;360 50 50",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
            ]),
            _c("span", {
              staticClass: "flex a-center",
              domProps: { textContent: _vm._s(_vm.$t(_vm.statistic.name)) },
            }),
          ]
        )
      : _vm._e(),
    _vm.isDashboard
      ? _c(
          "span",
          { staticClass: "statistics-box action-statistics-box flex col bet" },
          [
            _vm.isDashboard
              ? _c("span", { staticClass: "flex bet w100 a-center" }, [
                  _vm.isDashboard
                    ? _c("button", {
                        staticClass: "p12",
                        staticStyle: {
                          display: "flex",
                          "justify-content": "center",
                          width: "100%",
                        },
                        domProps: {
                          textContent: _vm._s(_vm.$t("Show all payments")),
                        },
                        on: { click: _vm.send },
                      })
                    : _c("span", [
                        _c(
                          "svg",
                          {
                            staticStyle: {
                              margin: "auto",
                              background:
                                "rgb(255, 255, 255) none repeat scroll 0 0",
                              display: "block",
                              "shape-rendering": "auto",
                            },
                            attrs: {
                              width: "48px",
                              height: "48px",
                              viewBox: "0 0 100 100",
                              preserveAspectRatio: "xMidYMid",
                            },
                          },
                          [
                            _c(
                              "circle",
                              {
                                staticClass: "main-second-stroke",
                                attrs: {
                                  cx: "50",
                                  cy: "50",
                                  r: "32",
                                  "stroke-width": "8",
                                  "stroke-dasharray":
                                    "50.26548245743669 50.26548245743669",
                                  fill: "none",
                                  "stroke-linecap": "round",
                                },
                              },
                              [
                                _c("animateTransform", {
                                  attrs: {
                                    attributeName: "transform",
                                    type: "rotate",
                                    repeatCount: "indefinite",
                                    dur: "1s",
                                    keyTimes: "0;1",
                                    values: "0 50 50;360 50 50",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                ])
              : _vm._e(),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }