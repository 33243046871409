var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kms" },
    [
      _c(
        "table-list",
        _vm._b(
          {
            on: {
              "on-click-line": _vm.selectKitchen,
              changeOptions: _vm.changeOptions,
            },
          },
          "table-list",
          _vm.dataTable,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }