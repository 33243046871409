var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__content" },
    [
      _c("base-dialog-tabs", {
        attrs: { tabList: ["Request", "Response"] },
        on: { close: _vm.close },
        scopedSlots: _vm._u([
          {
            key: "tab0",
            fn: function () {
              return [
                _c("div", { staticClass: "log-order pt20" }, [
                  _c(
                    "div",
                    { staticClass: "json-view" },
                    [
                      _vm.sent
                        ? _c("vue-json-pretty", {
                            attrs: { path: "res", data: _vm.sent },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "tab1",
            fn: function () {
              return [
                _c("div", { staticClass: "log-order pt20" }, [
                  _c(
                    "div",
                    { staticClass: "json-view" },
                    [
                      _vm.dragonResponse
                        ? _c("vue-json-pretty", {
                            attrs: { path: "res", data: _vm.dragonResponse },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }