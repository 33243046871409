var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog-tabs",
    {
      staticClass: "catalog-editor-edit-extra",
      attrs: { width: "672px", tabList: _vm.tabList },
      on: { close: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "tab0",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "form-site" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "header-back back d-flex d-md-none",
                      style: { "margin-right": "-25px", width: "103vw" },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "header__back",
                          on: { click: _vm.close },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "header-company" },
                            [
                              _c("svg-icon", {
                                staticClass: "ic-white",
                                attrs: {
                                  name: "ic-arrow-left",
                                  width: "16",
                                  height: "12",
                                },
                              }),
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Back")),
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "form-site__top flex bet" }, [
                    _c("div", { staticClass: "form-site-mob-header" }, [
                      _c("div", { staticClass: "lang lang_grey" }, [
                        _c("div", {
                          staticClass: "lang__item",
                          class: { active: _vm.english },
                          domProps: { textContent: _vm._s(_vm.$t("English")) },
                          on: {
                            click: function ($event) {
                              _vm.english = true
                            },
                          },
                        }),
                        _c("div", {
                          staticClass: "lang__item",
                          class: { active: !_vm.english },
                          domProps: { textContent: _vm._s(_vm.$t("Hebrew")) },
                          on: {
                            click: function ($event) {
                              _vm.english = false
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", [
                      _c("div", { staticClass: "form-site__lang" }, [
                        _c(
                          "div",
                          { staticClass: "switch-wrap flex a-center" },
                          [
                            _c("div", {
                              staticClass: "switch-wrap__label mie12",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Active")),
                              },
                            }),
                            _c("swich-button", {
                              model: {
                                value: _vm.extraInEditCopy.active,
                                callback: function ($$v) {
                                  _vm.$set(_vm.extraInEditCopy, "active", $$v)
                                },
                                expression: "extraInEditCopy.active",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                  _vm.english
                    ? _c("form-site-field-text", {
                        attrs: { title: _vm.$t("Extra name") },
                        model: {
                          value: _vm.extraInEditCopy.name_en,
                          callback: function ($$v) {
                            _vm.$set(_vm.extraInEditCopy, "name_en", $$v)
                          },
                          expression: "extraInEditCopy.name_en",
                        },
                      })
                    : _vm._e(),
                  !_vm.english
                    ? _c("form-site-field-text", {
                        attrs: { title: _vm.$t("Extra name") },
                        model: {
                          value: _vm.extraInEditCopy.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.extraInEditCopy, "name", $$v)
                          },
                          expression: "extraInEditCopy.name",
                        },
                      })
                    : _vm._e(),
                  !_vm.mobile
                    ? _c("div", { staticClass: "form-site__lang" }, [
                        _c(
                          "div",
                          { staticClass: "switch-wrap flex a-center" },
                          [
                            _c("div", {
                              staticClass: "switch-wrap__label mie12",
                              domProps: {
                                textContent: _vm._s(_vm.$t("combine")),
                              },
                            }),
                            _c("swich-button", {
                              model: {
                                value: _vm.extraInEditCopy.combine,
                                callback: function ($$v) {
                                  _vm.$set(_vm.extraInEditCopy, "combine", $$v)
                                },
                                expression: "extraInEditCopy.combine",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "form-site__content mb12 pis8" }, [
                    _c(
                      "div",
                      { staticClass: "form-site__group mie8" },
                      [
                        !_vm.english
                          ? _c("form-site-field-text", {
                              attrs: { title: "Menu name" },
                              model: {
                                value: _vm.extraInEditCopy.name_menu,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.extraInEditCopy,
                                    "name_menu",
                                    $$v
                                  )
                                },
                                expression: "extraInEditCopy.name_menu",
                              },
                            })
                          : _vm._e(),
                        _vm.english
                          ? _c("form-site-field-text", {
                              attrs: { title: "Menu name" },
                              model: {
                                value: _vm.extraInEditCopy.name_menu_en,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.extraInEditCopy,
                                    "name_menu_en",
                                    $$v
                                  )
                                },
                                expression: "extraInEditCopy.name_menu_en",
                              },
                            })
                          : _vm._e(),
                        _c("form-site-field-text", {
                          attrs: { title: "Print name" },
                          model: {
                            value: _vm.extraInEditCopy.name_invoice,
                            callback: function ($$v) {
                              _vm.$set(_vm.extraInEditCopy, "name_invoice", $$v)
                            },
                            expression: "extraInEditCopy.name_invoice",
                          },
                        }),
                        _vm.english
                          ? _c("form-site-field-text", {
                              attrs: { title: "name on bon" },
                              model: {
                                value: _vm.extraInEditCopy.name_bon_en,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.extraInEditCopy,
                                    "name_bon_en",
                                    $$v
                                  )
                                },
                                expression: "extraInEditCopy.name_bon_en",
                              },
                            })
                          : _c("form-site-field-text", {
                              attrs: { title: "name on bon" },
                              model: {
                                value: _vm.extraInEditCopy.name_bon,
                                callback: function ($$v) {
                                  _vm.$set(_vm.extraInEditCopy, "name_bon", $$v)
                                },
                                expression: "extraInEditCopy.name_bon",
                              },
                            }),
                        _c("form-site-field-text", {
                          attrs: { title: "SKU" },
                          on: {
                            input: function ($event) {
                              _vm.changeMakat = true
                            },
                          },
                          model: {
                            value: _vm.extraInEditCopy.makat,
                            callback: function ($$v) {
                              _vm.$set(_vm.extraInEditCopy, "makat", $$v)
                            },
                            expression: "extraInEditCopy.makat",
                          },
                        }),
                        _c("form-site-field-text", {
                          attrs: { title: "External SKU" },
                          model: {
                            value: _vm.extraInEditCopy.external_makat,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.extraInEditCopy,
                                "external_makat",
                                $$v
                              )
                            },
                            expression: "extraInEditCopy.external_makat",
                          },
                        }),
                        _c("form-site-field-text", {
                          attrs: { title: "Stiker name" },
                          model: {
                            value: _vm.extraInEditCopy.name_sticker,
                            callback: function ($$v) {
                              _vm.$set(_vm.extraInEditCopy, "name_sticker", $$v)
                            },
                            expression: "extraInEditCopy.name_sticker",
                          },
                        }),
                        _c("form-site-field-text", {
                          attrs: { title: "Cancel makat" },
                          model: {
                            value: _vm.extraInEditCopy.cancel_makat,
                            callback: function ($$v) {
                              _vm.$set(_vm.extraInEditCopy, "cancel_makat", $$v)
                            },
                            expression: "extraInEditCopy.cancel_makat",
                          },
                        }),
                        _vm.mobile
                          ? _c(
                              "div",
                              { staticClass: "switch-wrap flex a-center" },
                              [
                                _c("div", {
                                  staticClass: "switch-wrap__label mie12",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("combine")),
                                  },
                                }),
                                _c("swich-button", {
                                  model: {
                                    value: _vm.extraInEditCopy.combine,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.extraInEditCopy,
                                        "combine",
                                        $$v
                                      )
                                    },
                                    expression: "extraInEditCopy.combine",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-site__group mie8" },
                      [
                        _c("form-site-field-text", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            title: "Cook notes",
                            type: "textarea",
                            placeholder: _vm.$t("Write notes for the cook..."),
                          },
                          model: {
                            value: _vm.extraInEditCopy.cook_notes,
                            callback: function ($$v) {
                              _vm.$set(_vm.extraInEditCopy, "cook_notes", $$v)
                            },
                            expression: "extraInEditCopy.cook_notes",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "form-site__content" }, [
                    _c("div", { staticClass: "form-site__group" }, [
                      _c("div", { staticClass: "form-site__field" }, [
                        _c("div", { staticClass: "form-site__input" }, [
                          _c("div", { staticClass: "edit-img" }, [
                            _c("div", {
                              staticClass: "edit-img__label",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Desktop")),
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "coupon-info__img",
                                class: {
                                  "coupon-info__empty":
                                    !_vm.extraInEditCopy.image ||
                                    _vm.extraInEditCopy.image === "delete",
                                },
                                style: _vm.image
                                  ? "background-image: url(" + _vm.image + ")"
                                  : "",
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "coupon-info__img-change" },
                                  [
                                    _vm.extraInEditCopy.image &&
                                    _vm.extraInEditCopy.image !== "delete"
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "edit-img__icon",
                                            on: { click: _vm.clearImage },
                                          },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                name: "ic-plus",
                                                width: "24",
                                                height: "19",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : [
                                          _c("input", {
                                            attrs: {
                                              type: "file",
                                              accept: "image/*",
                                            },
                                            on: { change: _vm.enterImage },
                                          }),
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "ic-photo",
                                              width: "24",
                                              height: "19",
                                            },
                                          }),
                                        ],
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "mb16" }, [
                _c("div", {
                  staticClass: "form-site__label",
                  domProps: { textContent: _vm._s(_vm.$t("Tags")) },
                }),
                _vm.allTags.length
                  ? _c(
                      "div",
                      {
                        staticClass: "tags-itm__search",
                        on: {
                          click: function ($event) {
                            return _vm.$refs.searchtag.focus()
                          },
                        },
                      },
                      [
                        _vm._l(_vm.hasTags, function (tag) {
                          return _c(
                            "div",
                            {
                              key: tag.id,
                              staticClass: "tags-itm__has-tag",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _c("div", {
                                staticClass: "tags-itm__name pie8",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.he ? tag.name : tag.name_en
                                  ),
                                },
                              }),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-close-tag",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.toggleTag(tag.id)
                                    },
                                  },
                                },
                                [_vm._v("\n            ×\n          ")]
                              ),
                            ]
                          )
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchTags,
                              expression: "searchTags",
                            },
                          ],
                          ref: "searchtag",
                          staticClass: "p12",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("Search..."),
                          },
                          domProps: { value: _vm.searchTags },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.searchTags = $event.target.value
                            },
                          },
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tagsFilterSearch.length,
                        expression: "tagsFilterSearch.length",
                      },
                    ],
                    staticClass: "tags-itm__tag-list",
                  },
                  _vm._l(_vm.tagsFilterSearch, function (tag) {
                    return _c("button", {
                      key: tag.id,
                      staticClass: "tags-itm__tag-list_tag",
                      domProps: {
                        textContent: _vm._s(_vm.he ? tag.name : tag.name_en),
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.toggleTag(tag.id)
                        },
                      },
                    })
                  }),
                  0
                ),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "tab1",
          fn: function () {
            return [
              _c("div", {
                staticClass: "h1",
                domProps: {
                  textContent: _vm._s(
                    _vm.he
                      ? _vm.extraInEditCopy.name
                      : _vm.extraInEditCopy.name_en
                  ),
                },
              }),
              _c(
                "div",
                {
                  staticClass: "header-back back d-flex d-md-none",
                  style: { "margin-right": "-25px", width: "103vw" },
                },
                [
                  _c(
                    "button",
                    { staticClass: "header__back", on: { click: _vm.close } },
                    [
                      _c(
                        "span",
                        { staticClass: "header-company" },
                        [
                          _c("svg-icon", {
                            staticClass: "ic-white",
                            attrs: {
                              name: "ic-arrow-left",
                              width: "16",
                              height: "12",
                            },
                          }),
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Back")) },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _vm.branchId
                ? _c("branches-tab", {
                    attrs: {
                      activity: "",
                      showOnlyThisBranch: true,
                      availability: _vm.extraInEditCopy.availability,
                    },
                  })
                : _vm._e(),
              !_vm.branchId
                ? _c("branches-tab", {
                    attrs: {
                      activity: "",
                      showOnlyThisBranch: false,
                      availability: _vm.extraInEditCopy.availability,
                    },
                  })
                : _vm._e(),
            ]
          },
          proxy: true,
        },
        {
          key: "tab2",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass: "header-back back d-flex d-md-none",
                  style: { "margin-right": "-25px", width: "103vw" },
                },
                [
                  _c(
                    "button",
                    { staticClass: "header__back", on: { click: _vm.close } },
                    [
                      _c(
                        "span",
                        { staticClass: "header-company" },
                        [
                          _c("svg-icon", {
                            staticClass: "ic-white",
                            attrs: {
                              name: "ic-arrow-left",
                              width: "16",
                              height: "12",
                            },
                          }),
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Back")) },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _c("div", {
                staticClass: "h1",
                domProps: {
                  textContent: _vm._s(
                    _vm.he
                      ? _vm.extraInEditCopy.name
                      : _vm.extraInEditCopy.name_en
                  ),
                },
              }),
              _c("availability-tab", {
                attrs: {
                  activity: "",
                  availability: _vm.extraInEditCopy.availability,
                },
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "tab3",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass: "header-back back d-flex d-md-none",
                  style: { "margin-right": "-25px", width: "103vw" },
                },
                [
                  _c(
                    "button",
                    { staticClass: "header__back", on: { click: _vm.close } },
                    [
                      _c(
                        "span",
                        { staticClass: "header-company" },
                        [
                          _c("svg-icon", {
                            staticClass: "ic-white",
                            attrs: {
                              name: "ic-arrow-left",
                              width: "16",
                              height: "12",
                            },
                          }),
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Back")) },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _c("div", { staticClass: "new-item-info" }, [
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.save.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "form-site" }, [
                      _c(
                        "div",
                        { staticClass: "new-item-info__top flex" },
                        [
                          _c("div", {
                            staticClass: "h1",
                            domProps: {
                              textContent: _vm._s(
                                _vm.he
                                  ? _vm.extraInEditCopy.name
                                  : _vm.extraInEditCopy.name_en
                              ),
                            },
                          }),
                          _vm.restaurantCurrencyInfo === null
                            ? _c("form-site-field-text", {
                                attrs: {
                                  helf: "",
                                  title: "Primary price",
                                  sign: "₪",
                                  placeholder: "0",
                                },
                                model: {
                                  value: _vm.extraInEditCopy.price,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.extraInEditCopy, "price", $$v)
                                  },
                                  expression: "extraInEditCopy.price",
                                },
                              })
                            : _vm._e(),
                          _vm.restaurantCurrencyInfo !== null
                            ? _c("form-site-field-text", {
                                attrs: {
                                  helf: "",
                                  title: "Primary price",
                                  sign: _vm.restaurantCurrencySymbol,
                                  placeholder: "0",
                                },
                                model: {
                                  value: _vm.extraInEditCopy.price,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.extraInEditCopy, "price", $$v)
                                  },
                                  expression: "extraInEditCopy.price",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "form-site__content" }, [
                        _vm.allBranchesPermissions
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "input-container price-container-main flex f-wrap",
                              },
                              [
                                _vm.restaurantCurrencyInfo == null
                                  ? _c("form-site-field-text", {
                                      attrs: {
                                        short: "",
                                        title: "Delivery",
                                        sign: "₪",
                                        placeholder:
                                          _vm.extraInEditCopy.price || 0,
                                      },
                                      model: {
                                        value:
                                          _vm.extraInEditCopy.delivery_price,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.extraInEditCopy,
                                            "delivery_price",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "extraInEditCopy.delivery_price",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.restaurantCurrencyInfo !== null
                                  ? _c("form-site-field-text", {
                                      attrs: {
                                        short: "",
                                        title: "Delivery",
                                        sign: _vm.restaurantCurrencySymbol,
                                        placeholder:
                                          _vm.extraInEditCopy.price || 0,
                                      },
                                      model: {
                                        value:
                                          _vm.extraInEditCopy.delivery_price,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.extraInEditCopy,
                                            "delivery_price",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "extraInEditCopy.delivery_price",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.restaurantCurrencyInfo == null
                                  ? _c("form-site-field-text", {
                                      attrs: {
                                        short: "",
                                        title: "Take away",
                                        sign: "₪",
                                        placeholder:
                                          _vm.extraInEditCopy.price || 0,
                                      },
                                      model: {
                                        value:
                                          _vm.extraInEditCopy.takeaway_price,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.extraInEditCopy,
                                            "takeaway_price",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "extraInEditCopy.takeaway_price",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.restaurantCurrencyInfo !== null
                                  ? _c("form-site-field-text", {
                                      attrs: {
                                        short: "",
                                        title: "Take away",
                                        sign: _vm.restaurantCurrencySymbol,
                                        placeholder:
                                          _vm.extraInEditCopy.price || 0,
                                      },
                                      model: {
                                        value:
                                          _vm.extraInEditCopy.takeaway_price,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.extraInEditCopy,
                                            "takeaway_price",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "extraInEditCopy.takeaway_price",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.restaurantCurrencyInfo == null
                                  ? _c("form-site-field-text", {
                                      attrs: {
                                        short: "",
                                        title: "Sitting",
                                        sign: "₪",
                                        placeholder:
                                          _vm.extraInEditCopy.price || 0,
                                      },
                                      model: {
                                        value:
                                          _vm.extraInEditCopy.sitting_price,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.extraInEditCopy,
                                            "sitting_price",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "extraInEditCopy.sitting_price",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.restaurantCurrencyInfo !== null
                                  ? _c("form-site-field-text", {
                                      attrs: {
                                        short: "",
                                        title: "Sitting",
                                        sign: _vm.restaurantCurrencySymbol,
                                        placeholder:
                                          _vm.extraInEditCopy.price || 0,
                                      },
                                      model: {
                                        value:
                                          _vm.extraInEditCopy.sitting_price,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.extraInEditCopy,
                                            "sitting_price",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "extraInEditCopy.sitting_price",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.restaurantCurrencyInfo == null
                                  ? _c("form-site-field-text", {
                                      attrs: {
                                        short: "",
                                        title: "Dining",
                                        sign: "₪",
                                        placeholder:
                                          _vm.extraInEditCopy.price || 0,
                                      },
                                      model: {
                                        value: _vm.extraInEditCopy.dining_price,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.extraInEditCopy,
                                            "dining_price",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "extraInEditCopy.dining_price",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.restaurantCurrencyInfo !== null
                                  ? _c("form-site-field-text", {
                                      attrs: {
                                        short: "",
                                        title: "Dining",
                                        sign: _vm.restaurantCurrencySymbol,
                                        placeholder:
                                          _vm.extraInEditCopy.price || 0,
                                      },
                                      model: {
                                        value: _vm.extraInEditCopy.dining_price,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.extraInEditCopy,
                                            "dining_price",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "extraInEditCopy.dining_price",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.restaurantCurrencyInfo == null
                                  ? _c("form-site-field-text", {
                                      attrs: {
                                        short: "",
                                        title: "Pickup",
                                        sign: "₪",
                                        placeholder:
                                          _vm.extraInEditCopy.price || 0,
                                      },
                                      model: {
                                        value: _vm.extraInEditCopy.pickup_price,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.extraInEditCopy,
                                            "pickup_price",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "extraInEditCopy.pickup_price",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.restaurantCurrencyInfo !== null
                                  ? _c("form-site-field-text", {
                                      attrs: {
                                        short: "",
                                        title: "Pickup",
                                        sign: _vm.restaurantCurrencySymbol,
                                        placeholder:
                                          _vm.extraInEditCopy.price || 0,
                                      },
                                      model: {
                                        value: _vm.extraInEditCopy.pickup_price,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.extraInEditCopy,
                                            "pickup_price",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "extraInEditCopy.pickup_price",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.restaurantCurrencyInfo == null
                                  ? _c("form-site-field-text", {
                                      attrs: {
                                        short: "",
                                        title: "Drive Through",
                                        sign: "₪",
                                        placeholder:
                                          _vm.extraInEditCopy.price || 0,
                                      },
                                      model: {
                                        value:
                                          _vm.extraInEditCopy
                                            .drive_through_price,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.extraInEditCopy,
                                            "drive_through_price",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "extraInEditCopy.drive_through_price",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.restaurantCurrencyInfo !== null
                                  ? _c("form-site-field-text", {
                                      attrs: {
                                        short: "",
                                        title: "Drive Through",
                                        sign: _vm.restaurantCurrencySymbol,
                                        placeholder:
                                          _vm.extraInEditCopy.price || 0,
                                      },
                                      model: {
                                        value:
                                          _vm.extraInEditCopy
                                            .drive_through_price,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.extraInEditCopy,
                                            "drive_through_price",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "extraInEditCopy.drive_through_price",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.pricesFiltered.length > 0
                          ? _c("div", { staticClass: "form-site__field" }, [
                              _c("div", {
                                staticClass: "form-site__label",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Branches")),
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "form-site-search" },
                                [
                                  !_vm.branchId
                                    ? _c("filter-by", {
                                        on: {
                                          emitFilter: _vm.doFilterBranches,
                                        },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "form-site-search__field" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-search-param" },
                                        _vm._l(
                                          _vm.pricesFiltered,
                                          function (branche) {
                                            return _c(
                                              "div",
                                              {
                                                key: branche.id,
                                                staticClass:
                                                  "form-search-param__item-container",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-search-param__item",
                                                  },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "form-search-param__title",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          branche.name
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "input-container flex f-wrap",
                                                  },
                                                  [
                                                    _vm.restaurantCurrencyInfo ==
                                                    null
                                                      ? _c(
                                                          "form-site-field-text",
                                                          {
                                                            attrs: {
                                                              bgwhite: "",
                                                              short: "",
                                                              title: "Delivery",
                                                              sign: "₪",
                                                              placeholder:
                                                                _vm
                                                                  .extraInEditCopy
                                                                  .delivery_price ||
                                                                _vm
                                                                  .extraInEditCopy
                                                                  .price ||
                                                                0,
                                                            },
                                                            model: {
                                                              value:
                                                                branche.delivery_price,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    branche,
                                                                    "delivery_price",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "branche.delivery_price",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm.restaurantCurrencyInfo !==
                                                    null
                                                      ? _c(
                                                          "form-site-field-text",
                                                          {
                                                            attrs: {
                                                              bgwhite: "",
                                                              short: "",
                                                              title: "Delivery",
                                                              sign: _vm.restaurantCurrencySymbol,
                                                              placeholder:
                                                                _vm
                                                                  .extraInEditCopy
                                                                  .delivery_price ||
                                                                _vm
                                                                  .extraInEditCopy
                                                                  .price ||
                                                                0,
                                                            },
                                                            model: {
                                                              value:
                                                                branche.delivery_price,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    branche,
                                                                    "delivery_price",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "branche.delivery_price",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm.restaurantCurrencyInfo ==
                                                    null
                                                      ? _c(
                                                          "form-site-field-text",
                                                          {
                                                            attrs: {
                                                              bgwhite: "",
                                                              short: "",
                                                              title:
                                                                "Take away",
                                                              sign: "₪",
                                                              placeholder:
                                                                _vm
                                                                  .extraInEditCopy
                                                                  .takeaway_price ||
                                                                _vm
                                                                  .extraInEditCopy
                                                                  .price ||
                                                                0,
                                                            },
                                                            model: {
                                                              value:
                                                                branche.takeaway_price,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    branche,
                                                                    "takeaway_price",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "branche.takeaway_price",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm.restaurantCurrencyInfo !==
                                                    null
                                                      ? _c(
                                                          "form-site-field-text",
                                                          {
                                                            attrs: {
                                                              bgwhite: "",
                                                              short: "",
                                                              title:
                                                                "Take away",
                                                              sign: _vm.restaurantCurrencySymbol,
                                                              placeholder:
                                                                _vm
                                                                  .extraInEditCopy
                                                                  .takeaway_price ||
                                                                _vm
                                                                  .extraInEditCopy
                                                                  .price ||
                                                                0,
                                                            },
                                                            model: {
                                                              value:
                                                                branche.takeaway_price,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    branche,
                                                                    "takeaway_price",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "branche.takeaway_price",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm.restaurantCurrencyInfo ==
                                                    null
                                                      ? _c(
                                                          "form-site-field-text",
                                                          {
                                                            attrs: {
                                                              bgwhite: "",
                                                              short: "",
                                                              title: "Sitting",
                                                              sign: "₪",
                                                              placeholder:
                                                                _vm
                                                                  .extraInEditCopy
                                                                  .sitting_price ||
                                                                _vm
                                                                  .extraInEditCopy
                                                                  .price ||
                                                                0,
                                                            },
                                                            model: {
                                                              value:
                                                                branche.sitting_price,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    branche,
                                                                    "sitting_price",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "branche.sitting_price",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm.restaurantCurrencyInfo !==
                                                    null
                                                      ? _c(
                                                          "form-site-field-text",
                                                          {
                                                            attrs: {
                                                              bgwhite: "",
                                                              short: "",
                                                              title: "Sitting",
                                                              sign: _vm.restaurantCurrencySymbol,
                                                              placeholder:
                                                                _vm
                                                                  .extraInEditCopy
                                                                  .sitting_price ||
                                                                _vm
                                                                  .extraInEditCopy
                                                                  .price ||
                                                                0,
                                                            },
                                                            model: {
                                                              value:
                                                                branche.sitting_price,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    branche,
                                                                    "sitting_price",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "branche.sitting_price",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm.restaurantCurrencyInfo ==
                                                    null
                                                      ? _c(
                                                          "form-site-field-text",
                                                          {
                                                            attrs: {
                                                              bgwhite: "",
                                                              short: "",
                                                              title: "Dining",
                                                              sign: "₪",
                                                              placeholder:
                                                                _vm
                                                                  .extraInEditCopy
                                                                  .dining_price ||
                                                                _vm
                                                                  .extraInEditCopy
                                                                  .price ||
                                                                0,
                                                            },
                                                            model: {
                                                              value:
                                                                branche.dining_price,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    branche,
                                                                    "dining_price",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "branche.dining_price",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm.restaurantCurrencyInfo !==
                                                    null
                                                      ? _c(
                                                          "form-site-field-text",
                                                          {
                                                            attrs: {
                                                              bgwhite: "",
                                                              short: "",
                                                              title: "Dining",
                                                              sign: _vm.restaurantCurrencySymbol,
                                                              placeholder:
                                                                _vm
                                                                  .extraInEditCopy
                                                                  .dining_price ||
                                                                _vm
                                                                  .extraInEditCopy
                                                                  .price ||
                                                                0,
                                                            },
                                                            model: {
                                                              value:
                                                                branche.dining_price,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    branche,
                                                                    "dining_price",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "branche.dining_price",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm.restaurantCurrencyInfo ==
                                                    null
                                                      ? _c(
                                                          "form-site-field-text",
                                                          {
                                                            attrs: {
                                                              bgwhite: "",
                                                              short: "",
                                                              title: "Pickup",
                                                              sign: "₪",
                                                              placeholder:
                                                                _vm
                                                                  .extraInEditCopy
                                                                  .pickup_price ||
                                                                _vm
                                                                  .extraInEditCopy
                                                                  .price ||
                                                                0,
                                                            },
                                                            model: {
                                                              value:
                                                                branche.pickup_price,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    branche,
                                                                    "pickup_price",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "branche.pickup_price",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm.restaurantCurrencyInfo !==
                                                    null
                                                      ? _c(
                                                          "form-site-field-text",
                                                          {
                                                            attrs: {
                                                              bgwhite: "",
                                                              short: "",
                                                              title: "Pickup",
                                                              sign: _vm.restaurantCurrencySymbol,
                                                              placeholder:
                                                                _vm
                                                                  .extraInEditCopy
                                                                  .pickup_price ||
                                                                _vm
                                                                  .extraInEditCopy
                                                                  .price ||
                                                                0,
                                                            },
                                                            model: {
                                                              value:
                                                                branche.pickup_price,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    branche,
                                                                    "pickup_price",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "branche.pickup_price",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm.restaurantCurrencyInfo ==
                                                    null
                                                      ? _c(
                                                          "form-site-field-text",
                                                          {
                                                            attrs: {
                                                              bgwhite: "",
                                                              short: "",
                                                              title:
                                                                "Drive Through",
                                                              sign: "₪",
                                                              placeholder:
                                                                _vm
                                                                  .extraInEditCopy
                                                                  .drive_through_price ||
                                                                _vm
                                                                  .extraInEditCopy
                                                                  .price ||
                                                                0,
                                                            },
                                                            model: {
                                                              value:
                                                                branche.drive_through_price,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    branche,
                                                                    "drive_through_price",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "branche.drive_through_price",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm.restaurantCurrencyInfo !==
                                                    null
                                                      ? _c(
                                                          "form-site-field-text",
                                                          {
                                                            attrs: {
                                                              bgwhite: "",
                                                              short: "",
                                                              title:
                                                                "Drive Through",
                                                              sign: _vm.restaurantCurrencySymbol,
                                                              placeholder:
                                                                _vm
                                                                  .extraInEditCopy
                                                                  .drive_through_price ||
                                                                _vm
                                                                  .extraInEditCopy
                                                                  .price ||
                                                                0,
                                                            },
                                                            model: {
                                                              value:
                                                                branche.drive_through_price,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    branche,
                                                                    "drive_through_price",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "branche.drive_through_price",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "tab4",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass: "header-back back d-flex d-md-none",
                  style: { "margin-right": "-25px", width: "103vw" },
                },
                [
                  _c(
                    "button",
                    { staticClass: "header__back", on: { click: _vm.close } },
                    [
                      _c(
                        "span",
                        { staticClass: "header-company" },
                        [
                          _c("svg-icon", {
                            staticClass: "ic-white",
                            attrs: {
                              name: "ic-arrow-left",
                              width: "16",
                              height: "12",
                            },
                          }),
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Back")) },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _c("catalog-editor-edit-item-make-lines", {
                attrs: {
                  props: _vm.propsMakeLines,
                  itemCopy: _vm.extraInEditCopy,
                },
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "btns",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "form-site__btn form-site__btn_sb d-md-flex d-none",
                },
                [
                  _vm.extraInEditCopy.id
                    ? _c("remove-btn", { on: { click: _vm.remove } })
                    : _c("div"),
                  _c("button", {
                    staticClass: "btn",
                    domProps: { textContent: _vm._s(_vm.$t("Save")) },
                    on: { click: _vm.save },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
                _c(
                  "button",
                  { staticClass: "save-btn", on: { click: _vm.save } },
                  [
                    _c("svg-icon", {
                      staticClass: "ic-edit",
                      attrs: { name: "ic-save-btn", width: "93", height: "93" },
                    }),
                  ],
                  1
                ),
                _vm.extraInEditCopy.id
                  ? _c(
                      "button",
                      { staticClass: "bin-btn", on: { click: _vm.remove } },
                      [
                        _c("svg-icon", {
                          attrs: { name: "ic-bin", width: "18", height: "20" },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_vm._v("\n  Ï\n  ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }