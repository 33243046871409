var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: { title: _vm.$t("You’re about to delete ") + _vm.itemName },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("div", { staticClass: "popup__content pb40" }, [
        _c("div", {
          staticClass: "mb40 t-center",
          domProps: { textContent: _vm._s(_vm.$t("What’s the reason?")) },
        }),
        _c("div", { staticClass: "form-new-user" }, [
          _c(
            "form",
            {
              staticClass: "form-site-wrap",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "form-site__btn" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn_border-grad",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("cancel", 1)
                      },
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "btn__grad-content",
                      domProps: { textContent: _vm._s(_vm.$t("Out of stock")) },
                    }),
                  ]
                ),
                _c("button", {
                  staticClass: "red customers-choice",
                  domProps: {
                    textContent: _vm._s(_vm.$t("Customer's choice")),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("cancel", 2)
                    },
                  },
                }),
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close")
                      },
                    },
                  },
                  [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.$t("Cancel")) },
                    }),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }