var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { class: { disabled: _vm.disabled } }, [
    _c("input", {
      attrs: { disabled: _vm.disabled, type: "checkbox", name: _vm.name },
      domProps: { value: _vm.value, checked: _vm.isChecked },
      on: { input: _vm.change },
    }),
    _vm.isChecked
      ? _c(
          "svg",
          {
            attrs: {
              width: "20",
              height: "21",
              viewBox: "0 0 20 20",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("rect", {
              staticClass: "second-stroke",
              attrs: {
                x: "0.5",
                y: "0.5",
                width: "19",
                height: "19",
                rx: "1.5",
              },
            }),
            _c("path", {
              staticClass: "second-fill",
              attrs: {
                d: "M7.75634 15.7559C7.4317 15.7559 7.10673 15.632 6.85908 15.3842L2.37175 10.8969C1.87608 10.4012 1.87608 9.59771 2.37175 9.10204C2.86742 8.60637 3.67093 8.60637 4.1666 9.10204L7.75634 12.6918L15.8334 4.61486C16.3291 4.11934 17.1328 4.11919 17.6281 4.61486C18.1239 5.11053 18.1239 5.91423 17.6281 6.40971L8.65378 15.3842C8.40594 15.6319 8.08112 15.7559 7.75634 15.7559Z",
              },
            }),
          ]
        )
      : !_vm.isChecked && !_vm.partial
      ? _c(
          "svg",
          {
            attrs: {
              width: "20",
              height: "21",
              viewBox: "0 0 20 20",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("rect", {
              staticClass: "second-stroke",
              attrs: {
                x: "0.5",
                y: "0.5",
                width: "19",
                height: "19",
                rx: "1.5",
                fill: "white",
              },
            }),
          ]
        )
      : _vm.partial
      ? _c(
          "svg",
          {
            attrs: {
              x: "0px",
              y: "0px",
              width: "20px",
              height: "21px",
              viewBox: "0 0 612 612",
              fill: "#d60b52",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M420.75,286.875h-229.5c-10.557,0-19.125,8.568-19.125,19.125c0,10.557,8.568,19.125,19.125,19.125h229.5\r\n        c10.557,0,19.125-8.568,19.125-19.125C439.875,295.443,431.307,286.875,420.75,286.875z M535.5,0h-459C34.253,0,0,34.253,0,76.5\r\n        v459C0,577.747,34.253,612,76.5,612h459c42.247,0,76.5-34.253,76.5-76.5v-459C612,34.253,577.747,0,535.5,0z M573.75,535.5\r\n        c0,21.133-17.117,38.25-38.25,38.25h-459c-21.133,0-38.25-17.117-38.25-38.25v-459c0-21.133,17.117-38.25,38.25-38.25h459\r\n        c21.133,0,38.25,17.136,38.25,38.25V535.5z",
              },
            }),
          ]
        )
      : _vm._e(),
    !_vm.inline
      ? _c("span", { staticClass: "m-i-start-1ch" }, [_vm._t("default")], 2)
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }