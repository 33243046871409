var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", [
    _c("section", { staticClass: "container" }, [
      _c("div", { staticClass: "page" }, [
        _vm._m(0),
        _c("div", { staticClass: "page__main" }, [
          _vm.isLoading
            ? _c("div", { staticClass: "page__content" }, [
                _c("div", { staticClass: "page__content-top" }, [
                  _c("h1", { staticClass: "h1" }, [
                    _vm._v(_vm._s(_vm.$t("Loading")) + "..."),
                  ]),
                ]),
                _c("div", { staticClass: "page__content-bottom" }, [
                  _c("div", { staticClass: "page__content-bottom-text" }, [
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t("Just a moment, please")) + "..."),
                    ]),
                  ]),
                ]),
              ])
            : _c("div", { staticClass: "page__content" }, [
                _c("div", { staticClass: "page__content-top" }, [
                  _c("h1", { staticClass: "h1" }, [
                    _vm._v(_vm._s(_vm.$t("No access"))),
                  ]),
                ]),
                _c("div", { staticClass: "page__content-bottom" }, [
                  _c("div", { staticClass: "page__content-bottom-text" }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.$t("You dont have permission for this area"))
                      ),
                    ]),
                  ]),
                ]),
              ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__top" }, [
      _c("h1", { staticClass: "md-none" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }