var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-site__field" }, [
    _c("label", {
      staticClass: "form-site__label",
      attrs: { for: "address_name" },
      domProps: { textContent: _vm._s(_vm.$t(_vm.title)) },
    }),
    _c(
      "div",
      { staticClass: "form-site__select-bank" },
      [
        _c(
          "select-box",
          _vm._b(
            {
              staticClass: "mie8 md-none",
              attrs: {
                fixed: true,
                fixedleft: true,
                viewId: "",
                showVal: "",
                showSearch: "",
                searchByValue: "",
                name: "",
                options: _vm.options,
              },
              on: { input: _vm.input },
            },
            "select-box",
            _vm.$attrs,
            false
          )
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }