var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "full-screen general-settings" },
    [
      _c("section", { staticClass: "container" }, [
        _c("div", { staticClass: "page" }, [
          _c(
            "div",
            { staticClass: "page__main" },
            [
              _c(
                "router-link",
                { staticClass: "page__back", attrs: { to: "../settings" } },
                [
                  _c("svg-icon", {
                    attrs: {
                      name: "ic-close-table-arrow",
                      width: "34",
                      height: "34",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "settings-head" }, [
                _c("h1", {
                  domProps: { textContent: _vm._s(_vm.$t("General settings")) },
                }),
                _c("div", { staticClass: "lang md-none" }, [
                  _c("button", {
                    staticClass: "lang__item",
                    class: { active: !_vm.english },
                    domProps: { textContent: _vm._s(_vm.$t("Hebrew")) },
                    on: {
                      click: function ($event) {
                        _vm.english = false
                      },
                    },
                  }),
                  _c("button", {
                    staticClass: "lang__item",
                    class: { active: _vm.english },
                    domProps: { textContent: _vm._s(_vm.$t("English")) },
                    on: {
                      click: function ($event) {
                        _vm.english = true
                      },
                    },
                  }),
                ]),
              ]),
              _vm.allBranches
                ? _c(
                    "section",
                    { staticClass: "main-settings all-branches-settings" },
                    [
                      _c("div", { staticClass: "main-settings__sect" }, [
                        _c("div", { staticClass: "main-settings__top" }, [
                          _c("div", { staticClass: "main-settings__img" }, [
                            _c(
                              "div",
                              { staticClass: "main-settings__img-item" },
                              [
                                _c("div", {
                                  staticClass: "main-settings__label",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Logo")),
                                  },
                                }),
                                _vm.restaurantData
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "edit-logo",
                                        class: {
                                          "edit-img__content_empty":
                                            !_vm.restaurantLogo,
                                        },
                                        style: {
                                          backgroundImage:
                                            "url(" + _vm.restaurantLogo + ")",
                                        },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "edit-logo__icon" },
                                          [
                                            _vm.restaurantLogo
                                              ? _c(
                                                  "button",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeImage(
                                                          "Logo"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      attrs: {
                                                        name: "ic-plus",
                                                        width: "22",
                                                        height: "22",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : [
                                                  _c("input", {
                                                    attrs: {
                                                      type: "file",
                                                      name: "logo",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.enterPhotoLogo,
                                                    },
                                                  }),
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      name: "ic-photo",
                                                      width: "22",
                                                      height: "22",
                                                    },
                                                  }),
                                                ],
                                          ],
                                          2
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "main-settings__img-item" },
                              [
                                _c("div", {
                                  staticClass: "main-settings__label",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Icon")),
                                  },
                                }),
                                _vm.restaurantData
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "edit-logo edit-logo_square",
                                        class: {
                                          "edit-img__content_empty":
                                            !_vm.restaurantIcon,
                                        },
                                        style: {
                                          backgroundImage:
                                            "url(" + _vm.restaurantIcon + ")",
                                        },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "edit-logo__icon" },
                                          [
                                            _vm.restaurantIcon
                                              ? _c(
                                                  "button",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeImage(
                                                          "Icon"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      attrs: {
                                                        name: "ic-plus",
                                                        width: "22",
                                                        height: "22",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : [
                                                  _c("input", {
                                                    attrs: {
                                                      type: "file",
                                                      name: "icon",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.enterPhotoIcon,
                                                    },
                                                  }),
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      name: "ic-photo",
                                                      width: "22",
                                                      height: "22",
                                                    },
                                                  }),
                                                ],
                                          ],
                                          2
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "lang mb12 d-md-none" }, [
                            _c("button", {
                              staticClass: "lang__item",
                              class: { active: !_vm.english },
                              domProps: {
                                textContent: _vm._s(_vm.$t("Hebrew")),
                              },
                              on: {
                                click: function ($event) {
                                  _vm.english = false
                                },
                              },
                            }),
                            _c("button", {
                              staticClass: "lang__item",
                              class: { active: _vm.english },
                              domProps: {
                                textContent: _vm._s(_vm.$t("English")),
                              },
                              on: {
                                click: function ($event) {
                                  _vm.english = true
                                },
                              },
                            }),
                          ]),
                          _c(
                            "div",
                            { staticClass: "main-settings__form w100" },
                            [
                              _c(
                                "form",
                                {
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "form-site" }, [
                                    _vm.restaurantData
                                      ? _c(
                                          "div",
                                          { staticClass: "form-site__content" },
                                          [
                                            _vm.english
                                              ? _c("form-site-field-text", {
                                                  staticClass: "mie4",
                                                  attrs: {
                                                    title: "Restaurant name",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.restaurantData
                                                        .name_en,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.restaurantData,
                                                        "name_en",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "restaurantData.name_en",
                                                  },
                                                })
                                              : _c("form-site-field-text", {
                                                  staticClass: "mie4",
                                                  attrs: {
                                                    title: "Restaurant name",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.restaurantData.name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.restaurantData,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "restaurantData.name",
                                                  },
                                                }),
                                            _c("form-site-field-text", {
                                              attrs: {
                                                short: "",
                                                title: "Primary phone",
                                              },
                                              model: {
                                                value: _vm.restaurantData.phone,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.restaurantData,
                                                    "phone",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "restaurantData.phone",
                                              },
                                            }),
                                            _vm.restaurantData
                                              ? _c(
                                                  "form-site-field-select-box",
                                                  {
                                                    attrs: {
                                                      short: "",
                                                      title: "Business type",
                                                      "max-width": "200px",
                                                      options: [
                                                        {
                                                          value: 0,
                                                          label:
                                                            _vm.$t(
                                                              "Restaurant"
                                                            ),
                                                        },
                                                        {
                                                          value: 1,
                                                          label:
                                                            _vm.$t("Store"),
                                                        },
                                                        {
                                                          value: 2,
                                                          label:
                                                            _vm.$t("Hotel"),
                                                        },
                                                      ],
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.restaurantData.type,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.restaurantData,
                                                          "type",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "restaurantData.type",
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                            _c("form-site-field-text", {
                                              attrs: {
                                                short: "",
                                                title: "Restaurant URL",
                                              },
                                              model: {
                                                value:
                                                  _vm.restaurantData.rest_url,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.restaurantData,
                                                    "rest_url",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "restaurantData.rest_url",
                                              },
                                            }),
                                            _c("form-site-field-text", {
                                              staticClass: "mie4",
                                              attrs: {
                                                title:
                                                  "Employee password length",
                                                type: "number",
                                                min: "4",
                                                max: "10",
                                              },
                                              model: {
                                                value:
                                                  _vm.restaurantData
                                                    .employee_password_length,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.restaurantData,
                                                    "employee_password_length",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "restaurantData.employee_password_length",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "form-site__field",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "form-site__label",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("Default city")
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("cities-input", {
                                                  on: {
                                                    input:
                                                      _vm.defaultCityNameChanged,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.restaurantData
                                                        .default_city_name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.restaurantData,
                                                        "default_city_name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "restaurantData.default_city_name",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "input-check__label",
                                                staticStyle: {
                                                  padding: "30px",
                                                },
                                              },
                                              [
                                                _c("checkbox", {
                                                  attrs: { name: "on-limit" },
                                                  model: {
                                                    value:
                                                      _vm.restaurantData
                                                        .prices_include_vat,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.restaurantData,
                                                        "prices_include_vat",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "restaurantData.prices_include_vat",
                                                  },
                                                }),
                                                _c("span", {
                                                  staticClass:
                                                    "input-check__text",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        "Prices include VAT"
                                                      )
                                                    ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "input-check__label",
                                                staticStyle: {
                                                  padding: "30px",
                                                },
                                              },
                                              [
                                                _c("checkbox", {
                                                  attrs: { name: "on-limit" },
                                                  model: {
                                                    value:
                                                      _vm.restaurantData
                                                        .has_round_discount,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.restaurantData,
                                                        "has_round_discount",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "restaurantData.has_round_discount",
                                                  },
                                                }),
                                                _c("span", {
                                                  staticClass:
                                                    "input-check__text",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t("Round discount")
                                                    ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "input-check__label",
                                                staticStyle: {
                                                  padding: "30px",
                                                },
                                              },
                                              [
                                                _c("checkbox", {
                                                  attrs: { name: "on-limit" },
                                                  model: {
                                                    value:
                                                      _vm.restaurantData
                                                        .new_design,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.restaurantData,
                                                        "new_design",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "restaurantData.new_design",
                                                  },
                                                }),
                                                _c("span", {
                                                  staticClass:
                                                    "input-check__text",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t("New design")
                                                    ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "input-check__label",
                                                staticStyle: {
                                                  padding: "30px",
                                                },
                                              },
                                              [
                                                _c("checkbox", {
                                                  attrs: { name: "on-limit" },
                                                  model: {
                                                    value:
                                                      _vm.restaurantData
                                                        .extras_ordered_print,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.restaurantData,
                                                        "extras_ordered_print",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "restaurantData.extras_ordered_print",
                                                  },
                                                }),
                                                _c("span", {
                                                  staticClass:
                                                    "input-check__text",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        "Extras ordered print"
                                                      )
                                                    ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-site__field form-site__field_full",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-site__label-wrap",
                                                  },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "form-site__label",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t("description")
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-site-msg",
                                                  },
                                                  [
                                                    _vm.restaurantData &&
                                                    _vm.english
                                                      ? _c("textarea", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm
                                                                  .restaurantData
                                                                  .description_en,
                                                              expression:
                                                                "restaurantData.description_en",
                                                            },
                                                          ],
                                                          attrs: {
                                                            placeholder: _vm.$t(
                                                              "Describe your restaurant cuisine"
                                                            ),
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm.restaurantData
                                                                .description_en,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.$set(
                                                                _vm.restaurantData,
                                                                "description_en",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    _vm.restaurantData &&
                                                    !_vm.english
                                                      ? _c("textarea", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm
                                                                  .restaurantData
                                                                  .description,
                                                              expression:
                                                                "restaurantData.description",
                                                            },
                                                          ],
                                                          attrs: {
                                                            placeholder: _vm.$t(
                                                              "Describe your restaurant cuisine"
                                                            ),
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm.restaurantData
                                                                .description,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.$set(
                                                                _vm.restaurantData,
                                                                "description",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "additional-general-settings" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "input-check__label",
                                staticStyle: { margin: "30px 20px 0 0" },
                              },
                              [
                                _c("checkbox", {
                                  attrs: { name: "minors_notifications" },
                                  model: {
                                    value:
                                      _vm.restaurantData.minors_notifications,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.restaurantData,
                                        "minors_notifications",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "restaurantData.minors_notifications",
                                  },
                                }),
                                _c("span", {
                                  staticClass: "input-check__text",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("Minors notifications")
                                    ),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "label",
                              {
                                staticClass: "input-check__label",
                                staticStyle: { margin: "30px 20px 0 0" },
                              },
                              [
                                _c("checkbox", {
                                  attrs: { name: "approve_regulations" },
                                  model: {
                                    value:
                                      _vm.restaurantData.approve_regulations,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.restaurantData,
                                        "approve_regulations",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "restaurantData.approve_regulations",
                                  },
                                }),
                                _c("span", {
                                  staticClass: "input-check__text",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("Regulations must be approved")
                                    ),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "label",
                              {
                                staticClass: "input-check__label",
                                staticStyle: { margin: "30px 20px 0 0" },
                              },
                              [
                                _c("checkbox", {
                                  attrs: { name: "send_external_order_sms" },
                                  model: {
                                    value:
                                      _vm.restaurantData
                                        .send_external_order_sms,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.restaurantData,
                                        "send_external_order_sms",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "restaurantData.send_external_order_sms",
                                  },
                                }),
                                _c("span", {
                                  staticClass: "input-check__text",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "Send SMS about orders from external sources"
                                      )
                                    ),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "label",
                              {
                                staticClass: "input-check__label",
                                staticStyle: { margin: "30px 20px 0 0" },
                              },
                              [
                                _c("checkbox", {
                                  attrs: { name: "send_external_order_sms" },
                                  model: {
                                    value:
                                      _vm.restaurantData.require_client_email,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.restaurantData,
                                        "require_client_email",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "restaurantData.require_client_email",
                                  },
                                }),
                                _c("span", {
                                  staticClass: "input-check__text",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("Require client email")
                                    ),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "label",
                              {
                                staticClass: "input-check__label",
                                staticStyle: { margin: "30px 20px 0 0" },
                              },
                              [
                                _c("checkbox", {
                                  attrs: { name: "send_external_order_sms" },
                                  model: {
                                    value:
                                      _vm.restaurantData
                                        .statement_depositing_bills,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.restaurantData,
                                        "statement_depositing_bills",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "restaurantData.statement_depositing_bills",
                                  },
                                }),
                                _c("span", {
                                  staticClass: "input-check__text",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("Deposit Bills")
                                    ),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "label",
                              {
                                staticClass: "input-check__label",
                                staticStyle: { padding: "30px" },
                              },
                              [
                                _c("checkbox", {
                                  attrs: { name: "on-limit" },
                                  model: {
                                    value: _vm.restaurantData.allow_ivr,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.restaurantData,
                                        "allow_ivr",
                                        $$v
                                      )
                                    },
                                    expression: "restaurantData.allow_ivr",
                                  },
                                }),
                                _c("span", {
                                  staticClass: "input-check__text",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Allow IVR")),
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _vm.allBranches
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "main-settings__sect main-settings__sect_end",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "main-settings__content" },
                                [
                                  _c(
                                    "form",
                                    {
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "members-club" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "members-club__top",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "members-club__info",
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass:
                                                      "members-club__title",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t("Atmos Promos")
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex a-center j-end",
                                                },
                                                [
                                                  _c("span", {
                                                    staticClass: "mie8",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t("Active")
                                                      ),
                                                    },
                                                  }),
                                                  _vm.atmos_promos
                                                    ? _c("swich-button", {
                                                        model: {
                                                          value:
                                                            _vm.atmos_promos
                                                              .active,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.atmos_promos,
                                                              "active",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "atmos_promos.active",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-site__content mb12 pis8",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-site__group mie8",
                                                },
                                                [
                                                  _vm.atmos_promos
                                                    ? _c(
                                                        "form-site-field-text",
                                                        {
                                                          attrs: {
                                                            short: "",
                                                            disabled: "",
                                                            title: "Api key",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.atmos_promos
                                                                .meta_value
                                                                .apiKey,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.atmos_promos
                                                                  .meta_value,
                                                                "apiKey",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "atmos_promos.meta_value.apiKey",
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                  _vm.atmos_promos
                                                    ? _c(
                                                        "form-site-field-text",
                                                        {
                                                          attrs: {
                                                            short: "",
                                                            disabled: "",
                                                            title: "Tenant id",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.atmos_promos
                                                                .meta_value
                                                                .tenantId,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.atmos_promos
                                                                  .meta_value,
                                                                "tenantId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "atmos_promos.meta_value.tenantId",
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                  _vm.atmos_promos
                                                    ? _c(
                                                        "form-site-field-text",
                                                        {
                                                          attrs: {
                                                            short: "",
                                                            disabled: "",
                                                            title:
                                                              "Host tenant",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.atmos_promos
                                                                .meta_value
                                                                .hostTenant,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.atmos_promos
                                                                  .meta_value,
                                                                "hostTenant",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "atmos_promos.meta_value.hostTenant",
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                  _c("div", {
                                                    staticClass:
                                                      "form-site__field form-site__field_short",
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.isAdmin
                        ? _c("div", { staticClass: "main-settings__sect" }, [
                            _c(
                              "div",
                              { staticClass: "main-settings__content" },
                              [
                                _c(
                                  "form",
                                  {
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "members-club" }, [
                                      _c(
                                        "div",
                                        { staticClass: "members-club__top" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "members-club__info",
                                            },
                                            [
                                              _vm._m(0),
                                              _c("div", {
                                                staticClass:
                                                  "members-club__title",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t("Languages")
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-site__content mb12 pis8",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-site__group mie8",
                                            },
                                            [
                                              _vm.restaurantData
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "members-club__field radio",
                                                    },
                                                    [
                                                      _c("h6", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "Default language"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "members-club__radio mt8",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input-radio",
                                                            },
                                                            [
                                                              _vm.restaurantData
                                                                ? _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .restaurantData
                                                                              .primary_language,
                                                                          expression:
                                                                            "restaurantData.primary_language",
                                                                        },
                                                                      ],
                                                                    attrs: {
                                                                      id: "radio_hebrew",
                                                                      type: "radio",
                                                                      name: "primary_language",
                                                                    },
                                                                    domProps: {
                                                                      value: 0,
                                                                      checked:
                                                                        _vm._q(
                                                                          _vm
                                                                            .restaurantData
                                                                            .primary_language,
                                                                          0
                                                                        ),
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            _vm.restaurantData,
                                                                            "primary_language",
                                                                            0
                                                                          )
                                                                        },
                                                                    },
                                                                  })
                                                                : _vm._e(),
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "input-radio__label",
                                                                  attrs: {
                                                                    for: "radio_hebrew",
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "input-radio__mark",
                                                                  }),
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "input-radio__text",
                                                                    domProps: {
                                                                      textContent:
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "Hebrew"
                                                                          )
                                                                        ),
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input-radio",
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .restaurantData
                                                                        .primary_language,
                                                                    expression:
                                                                      "restaurantData.primary_language",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  id: "radio_english",
                                                                  type: "radio",
                                                                  name: "primary_language",
                                                                },
                                                                domProps: {
                                                                  value: 1,
                                                                  checked:
                                                                    _vm._q(
                                                                      _vm
                                                                        .restaurantData
                                                                        .primary_language,
                                                                      1
                                                                    ),
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$set(
                                                                        _vm.restaurantData,
                                                                        "primary_language",
                                                                        1
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "input-radio__label",
                                                                  attrs: {
                                                                    for: "radio_english",
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "input-radio__mark",
                                                                  }),
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "input-radio__text",
                                                                    domProps: {
                                                                      textContent:
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "English"
                                                                          )
                                                                        ),
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm.isAdmin
                        ? _c("div", { staticClass: "main-settings__sect" }, [
                            _c(
                              "div",
                              { staticClass: "main-settings__content" },
                              [
                                _vm.hasClub
                                  ? _c(
                                      "form",
                                      {
                                        on: {
                                          submit: function ($event) {
                                            $event.preventDefault()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "members-club" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "members-club__top",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "members-club__info",
                                                  },
                                                  [
                                                    _vm._m(1),
                                                    _c("div", {
                                                      staticClass:
                                                        "members-club__title",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t("Members club")
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _vm.restaurantData
                                                  ? _c("swich-button", {
                                                      model: {
                                                        value:
                                                          _vm.restaurantData
                                                            .club.has_club,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.restaurantData
                                                              .club,
                                                            "has_club",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "restaurantData.club.has_club",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-site__content mb12 pis8",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-site__group mie8",
                                                  },
                                                  [
                                                    _vm.restaurantData
                                                      ? _c(
                                                          "form-site-field-select-box",
                                                          {
                                                            attrs: {
                                                              short: "",
                                                              title:
                                                                "Club management",
                                                              "max-width":
                                                                "200px",
                                                              options: [
                                                                {
                                                                  value: "0",
                                                                  label:
                                                                    "atmos",
                                                                },
                                                                {
                                                                  value: "1",
                                                                  label:
                                                                    "ValueCard",
                                                                },
                                                              ],
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .restaurantData
                                                                  .club.type,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .restaurantData
                                                                      .club,
                                                                    "type",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "restaurantData.club.type",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm.restaurantData
                                                      ? _c(
                                                          "form-site-field-text",
                                                          {
                                                            attrs: {
                                                              short: "",
                                                              sign: "%",
                                                              title:
                                                                "collect for next order",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .restaurantData
                                                                  .club
                                                                  .collect_precent,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .restaurantData
                                                                      .club,
                                                                    "collect_precent",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "restaurantData.club.collect_precent",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm.restaurantData
                                                      ? _c(
                                                          "form-site-field-text",
                                                          {
                                                            attrs: {
                                                              short: "",
                                                              title:
                                                                "SKU for new member",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .restaurantData
                                                                  .club
                                                                  .new_member_makat,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .restaurantData
                                                                      .club,
                                                                    "new_member_makat",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "restaurantData.club.new_member_makat",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm.restaurantData
                                                      ? _c(
                                                          "form-site-field-text",
                                                          {
                                                            attrs: {
                                                              short: "",
                                                              title:
                                                                "SKU for remembership",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .restaurantData
                                                                  .club
                                                                  .remembership_makat,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .restaurantData
                                                                      .club,
                                                                    "remembership_makat",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "restaurantData.club.remembership_makat",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm.restaurantData &&
                                                    !_vm.restaurantCurrencyInfo
                                                      ? _c(
                                                          "form-site-field-text",
                                                          {
                                                            attrs: {
                                                              short: "",
                                                              sign: "₪",
                                                              title:
                                                                "Joining price",
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.valid(
                                                                  $event,
                                                                  "new_member_price"
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .restaurantData
                                                                  .club
                                                                  .new_member_price,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .restaurantData
                                                                      .club,
                                                                    "new_member_price",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "restaurantData.club.new_member_price",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm.restaurantData &&
                                                    _vm.restaurantCurrencyInfo
                                                      ? _c(
                                                          "form-site-field-text",
                                                          {
                                                            attrs: {
                                                              short: "",
                                                              sign: _vm.restaurantCurrencySymbol,
                                                              title:
                                                                "Joining price",
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.valid(
                                                                  $event,
                                                                  "new_member_price"
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .restaurantData
                                                                  .club
                                                                  .new_member_price,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .restaurantData
                                                                      .club,
                                                                    "new_member_price",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "restaurantData.club.new_member_price",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm.restaurantData &&
                                                    !_vm.restaurantCurrencyInfo
                                                      ? _c(
                                                          "form-site-field-text",
                                                          {
                                                            attrs: {
                                                              short: "",
                                                              sign: "₪",
                                                              title:
                                                                "Remembership price",
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.valid(
                                                                  $event,
                                                                  "remembership_price"
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .restaurantData
                                                                  .club
                                                                  .remembership_price,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .restaurantData
                                                                      .club,
                                                                    "remembership_price",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "restaurantData.club.remembership_price",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm.restaurantData &&
                                                    _vm.restaurantCurrencyInfo
                                                      ? _c(
                                                          "form-site-field-text",
                                                          {
                                                            attrs: {
                                                              short: "",
                                                              sign: _vm.restaurantCurrencySymbol,
                                                              title:
                                                                "Remembership price",
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.valid(
                                                                  $event,
                                                                  "remembership_price"
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .restaurantData
                                                                  .club
                                                                  .remembership_price,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .restaurantData
                                                                      .club,
                                                                    "remembership_price",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "restaurantData.club.remembership_price",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm.restaurantData
                                                      ? _c(
                                                          "form-site-field-text",
                                                          {
                                                            attrs: {
                                                              short: "",
                                                              title:
                                                                "Maximum points for order",
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.valid(
                                                                  $event,
                                                                  "max_collect"
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .restaurantData
                                                                  .club
                                                                  .max_collect,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .restaurantData
                                                                      .club,
                                                                    "max_collect",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "restaurantData.club.max_collect",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm.restaurantData
                                                      ? _c(
                                                          "form-site-field-text",
                                                          {
                                                            attrs: {
                                                              short: "",
                                                              title:
                                                                "Maximum total points for club member",
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.valid(
                                                                  $event,
                                                                  "max_total_point"
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .restaurantData
                                                                  .club
                                                                  .max_total_point,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .restaurantData
                                                                      .club,
                                                                    "max_total_point",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "restaurantData.club.max_total_point",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "members-club__field radio",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "members-club__radio mt8",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "input-radio",
                                                              },
                                                              [
                                                                _vm.restaurantData
                                                                  ? _c(
                                                                      "input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                _vm
                                                                                  .restaurantData
                                                                                  .club
                                                                                  .redeem,
                                                                              expression:
                                                                                "restaurantData.club.redeem",
                                                                            },
                                                                          ],
                                                                        attrs: {
                                                                          id: "radio1",
                                                                          type: "radio",
                                                                          name: "radio",
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            value: false,
                                                                            checked:
                                                                              _vm._q(
                                                                                _vm
                                                                                  .restaurantData
                                                                                  .club
                                                                                  .redeem,
                                                                                false
                                                                              ),
                                                                          },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                _vm
                                                                                  .restaurantData
                                                                                  .club,
                                                                                "redeem",
                                                                                false
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "input-radio__label",
                                                                    attrs: {
                                                                      for: "radio1",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("span", {
                                                                      staticClass:
                                                                        "input-radio__mark",
                                                                    }),
                                                                    _c("span", {
                                                                      staticClass:
                                                                        "input-radio__text",
                                                                      domProps:
                                                                        {
                                                                          textContent:
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "Collect only"
                                                                              )
                                                                            ),
                                                                        },
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "input-radio",
                                                              },
                                                              [
                                                                _vm.restaurantData
                                                                  ? _c(
                                                                      "input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                _vm
                                                                                  .restaurantData
                                                                                  .club
                                                                                  .redeem,
                                                                              expression:
                                                                                "restaurantData.club.redeem",
                                                                            },
                                                                          ],
                                                                        attrs: {
                                                                          id: "radio2",
                                                                          type: "radio",
                                                                          name: "radio",
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            value: true,
                                                                            checked:
                                                                              _vm._q(
                                                                                _vm
                                                                                  .restaurantData
                                                                                  .club
                                                                                  .redeem,
                                                                                true
                                                                              ),
                                                                          },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                _vm
                                                                                  .restaurantData
                                                                                  .club,
                                                                                "redeem",
                                                                                true
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "input-radio__label",
                                                                    attrs: {
                                                                      for: "radio2",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("span", {
                                                                      staticClass:
                                                                        "input-radio__mark",
                                                                    }),
                                                                    _c("span", {
                                                                      staticClass:
                                                                        "input-radio__text",
                                                                      domProps:
                                                                        {
                                                                          textContent:
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "Collect & Redeem"
                                                                              )
                                                                            ),
                                                                        },
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "joining-message",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "main-settings__img-item",
                                                          },
                                                          [
                                                            _c("div", {
                                                              staticClass:
                                                                "main-settings__label",
                                                              domProps: {
                                                                textContent:
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "Club Joining message image"
                                                                    )
                                                                  ),
                                                              },
                                                            }),
                                                            _vm.restaurantData
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "edit-logo",
                                                                    class: {
                                                                      "edit-img__content_empty":
                                                                        !_vm.joinMessageImagePreview,
                                                                    },
                                                                    style: {
                                                                      backgroundImage:
                                                                        "url(" +
                                                                        _vm.joinMessageImagePreview +
                                                                        ")",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "edit-logo__icon",
                                                                      },
                                                                      [
                                                                        _vm.joinMessageImagePreview
                                                                          ? _c(
                                                                              "button",
                                                                              {
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.removeImage(
                                                                                        "join_message_image"
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "svg-icon",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        name: "ic-plus",
                                                                                        width:
                                                                                          "22",
                                                                                        height:
                                                                                          "22",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : [
                                                                              _c(
                                                                                "input",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      type: "file",
                                                                                      name: "logo",
                                                                                    },
                                                                                  on: {
                                                                                    change:
                                                                                      _vm.enterJoinMessageImage,
                                                                                  },
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "svg-icon",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      name: "ic-photo",
                                                                                      width:
                                                                                        "22",
                                                                                      height:
                                                                                        "22",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                      ],
                                                                      2
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                        _c(
                                                          "form-site-field-text",
                                                          {
                                                            attrs: {
                                                              short: "",
                                                              title:
                                                                "Club joining button text",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .restaurantData
                                                                  .club
                                                                  .join_button_text,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .restaurantData
                                                                      .club,
                                                                    "join_button_text",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "restaurantData.club.join_button_text",
                                                            },
                                                          }
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-site__field form-site__field_full",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-site__label-wrap",
                                                              },
                                                              [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "form-site__label",
                                                                  domProps: {
                                                                    textContent:
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "Club joining message"
                                                                        )
                                                                      ),
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-site-msg",
                                                              },
                                                              [
                                                                _c("textarea", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .restaurantData
                                                                          .club
                                                                          .join_text,
                                                                      expression:
                                                                        "restaurantData.club.join_text",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    placeholder:
                                                                      _vm.$t(
                                                                        "Club joining message"
                                                                      ),
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      _vm
                                                                        .restaurantData
                                                                        .club
                                                                        .join_text,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        ) {
                                                                          return
                                                                        }
                                                                        _vm.$set(
                                                                          _vm
                                                                            .restaurantData
                                                                            .club,
                                                                          "join_text",
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c("div", [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              _vm.showCreateLegacyClubDialog = true
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Create club")))]
                                      ),
                                    ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm.restaurantData.better_chains
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "main-settings__sect main-settings__sect_end",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "main-settings__content" },
                                [
                                  _c(
                                    "form",
                                    {
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "members-club" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "members-club__top",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "members-club__info",
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass:
                                                      "members-club__title",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t("Better chains")
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex a-center j-end",
                                                },
                                                [
                                                  _c("span", {
                                                    staticClass: "mie8",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t("Test mode")
                                                      ),
                                                    },
                                                  }),
                                                  _vm.restaurantData
                                                    ? _c("swich-button", {
                                                        model: {
                                                          value:
                                                            _vm.restaurantData
                                                              .better_chains
                                                              .test_mode,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.restaurantData
                                                                .better_chains,
                                                              "test_mode",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "restaurantData.better_chains.test_mode",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-site__content mb12 pis8",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-site__group mie8",
                                                },
                                                [
                                                  _vm.restaurantData
                                                    ? _c(
                                                        "form-site-field-text",
                                                        {
                                                          attrs: {
                                                            short: "",
                                                            title: "Password",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.restaurantData
                                                                .better_chains
                                                                .password,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .restaurantData
                                                                  .better_chains,
                                                                "password",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "restaurantData.better_chains.password",
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                  _vm.restaurantData
                                                    ? _c(
                                                        "form-site-field-text",
                                                        {
                                                          attrs: {
                                                            short: "",
                                                            title: "Username",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.restaurantData
                                                                .better_chains
                                                                .username,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .restaurantData
                                                                  .better_chains,
                                                                "username",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "restaurantData.better_chains.username",
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                  _vm.restaurantData
                                                    ? _c(
                                                        "form-site-field-text",
                                                        {
                                                          attrs: {
                                                            short: "",
                                                            title: "Chain id",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.restaurantData
                                                                .better_chains
                                                                .chain_id,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .restaurantData
                                                                  .better_chains,
                                                                "chain_id",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "restaurantData.better_chains.chain_id",
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                  _c("div", {
                                                    staticClass:
                                                      "form-site__field form-site__field_short",
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm.roles.length
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "item-setting p10",
                                                      class: {
                                                        open: _vm.rolesScaled,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "item-setting__head flex bet w100",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              _vm.rolesScaled =
                                                                !_vm.rolesScaled
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex",
                                                              staticStyle: {
                                                                "align-items":
                                                                  "center",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "item-setting__icon",
                                                                },
                                                                [
                                                                  _c(
                                                                    "svg-icon",
                                                                    {
                                                                      staticClass:
                                                                        "icon ic-settings",
                                                                      attrs: {
                                                                        name: "ic-settings",
                                                                        width:
                                                                          "24",
                                                                        height:
                                                                          "24",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c("div", {
                                                                staticClass:
                                                                  "item-setting__title",
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Roles"
                                                                      )
                                                                    ),
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "item-setting__arr",
                                                            },
                                                            [
                                                              _c("svg-icon", {
                                                                attrs: {
                                                                  name: "ic-arrow-down",
                                                                  width: "14",
                                                                  height: "9",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                      _vm.restaurantData
                                                        .better_chains &&
                                                      _vm.restaurantData
                                                        .better_chains.positions
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "item-setting__content",
                                                            },
                                                            _vm._l(
                                                              _vm.restaurantData
                                                                .better_chains
                                                                .positions,
                                                              function (
                                                                role,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: role.position_id,
                                                                    staticClass:
                                                                      "form-site__group",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "form-site-field-text",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "100%",
                                                                          },
                                                                        attrs: {
                                                                          title:
                                                                            role.name,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .restaurantData
                                                                              .better_chains
                                                                              .positions[
                                                                              index
                                                                            ]
                                                                              .better_position_id,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .restaurantData
                                                                                  .better_chains
                                                                                  .positions[
                                                                                  index
                                                                                ],
                                                                                "better_position_id",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "\n                                restaurantData.better_chains.positions[index]\n                                  .better_position_id\n                              ",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "main-settings__sect main-settings__sect_end",
                        },
                        [
                          _c("div", { staticClass: "main-settings__content" }, [
                            _c(
                              "form",
                              {
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "members-club" }, [
                                  _c(
                                    "div",
                                    { staticClass: "members-club__top" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "members-club__info" },
                                        [
                                          _c("div", {
                                            staticClass: "members-club__title",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t("Firebase App settings")
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "flex a-center j-end" },
                                        [
                                          _c("span", {
                                            staticClass: "mie8",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t("Active")
                                              ),
                                            },
                                          }),
                                          _vm.restaurantData
                                            ? _c("swich-button", {
                                                model: {
                                                  value:
                                                    _vm.restaurantData.app
                                                      .active,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.restaurantData.app,
                                                      "active",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "restaurantData.app.active",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-site__content mb12 pis8",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-site__group mie8",
                                        },
                                        [
                                          _vm.restaurantData &&
                                          _vm.restaurantData.app &&
                                          _vm.restaurantData.app.meta_value
                                            ? _c("form-site-field-text", {
                                                attrs: {
                                                  short: "",
                                                  title: "Project ID",
                                                },
                                                model: {
                                                  value:
                                                    _vm.restaurantData.app
                                                      .meta_value.fpmProjectId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.restaurantData.app
                                                        .meta_value,
                                                      "fpmProjectId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "restaurantData.app.meta_value.fpmProjectId",
                                                },
                                              })
                                            : _vm._e(),
                                          _c("div", {
                                            staticClass:
                                              "form-site__field form-site__field_short",
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "main-settings__sect main-settings__sect_end",
                        },
                        [
                          _c("div", { staticClass: "main-settings__content" }, [
                            _c(
                              "div",
                              { staticClass: "setting-panel md-none" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "setting-panel__content" },
                                  [
                                    _vm._m(2),
                                    _c(
                                      "div",
                                      { staticClass: "setting-panel__info" },
                                      [
                                        _c("div", {
                                          staticClass: "setting-panel__title",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Pending charge")
                                            ),
                                          },
                                        }),
                                        _c("div", {
                                          staticClass: "setting-panel__text",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "Turning this on will not charge clients directly, the payment will be pending for changes and approve in orders page"
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm.restaurantData
                                  ? _c("swich-button", {
                                      model: {
                                        value:
                                          _vm.restaurantData.business_billing,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.restaurantData,
                                            "business_billing",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "restaurantData.business_billing",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "setting-panel d-md-none" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "setting-panel__content col w100",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex w100 bet a-center" },
                                      [
                                        _vm._m(3),
                                        _c("div", {
                                          staticClass: "setting-panel__title",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Pending charge")
                                            ),
                                          },
                                        }),
                                        _vm.restaurantData
                                          ? _c("swich-button", {
                                              model: {
                                                value:
                                                  _vm.restaurantData
                                                    .business_billing,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.restaurantData,
                                                    "business_billing",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "restaurantData.business_billing",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "setting-panel__info w100",
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "setting-panel__text",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "Turning this on will not charge clients directly, the payment will be pending for changes and approve in orders page"
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "main-settings__sect main-settings__sect_end double",
                        },
                        [
                          _c("div", { staticClass: "main-settings__content" }, [
                            _c(
                              "div",
                              { staticClass: "main-settings__toggel" },
                              [
                                _vm.restaurantData
                                  ? _c("swich-button", {
                                      model: {
                                        value: _vm.restaurantData.has_delivery,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.restaurantData,
                                            "has_delivery",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "restaurantData.has_delivery",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "setting-panel j-center" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "orders-settings__info" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "orders-settings__img" },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            name: "delivery",
                                            width: "72",
                                            height: "72",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("div", {
                                      staticClass: "orders-settings__title",
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Delivery")),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "main-settings__content" }, [
                            _c(
                              "div",
                              { staticClass: "main-settings__toggel" },
                              [
                                _vm.restaurantData
                                  ? _c("swich-button", {
                                      model: {
                                        value: _vm.restaurantData.has_takeaway,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.restaurantData,
                                            "has_takeaway",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "restaurantData.has_takeaway",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "setting-panel j-center" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "orders-settings__info" },
                                  [
                                    _vm._m(4),
                                    _c("div", {
                                      staticClass: "orders-settings__title",
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Pickup")),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "main-settings__sect main-settings__sect_end",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "main-settings__content terms-privacy-area",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn",
                                  attrs: { type: "button" },
                                  on: { click: _vm.editTermsAndConditions },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("Edit Terms and conditions"))
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "main-settings__sect main-settings__sect_end",
                        },
                        [
                          _c("div", { staticClass: "main-settings__content" }, [
                            _c(
                              "div",
                              {
                                staticClass: "setting-panel",
                                class: {
                                  col:
                                    _vm.restaurantData &&
                                    _vm.restaurantData.terms,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "setting-panel__content md-none",
                                  },
                                  [
                                    _vm._m(5),
                                    _c(
                                      "div",
                                      { staticClass: "setting-panel__info" },
                                      [
                                        _c("div", {
                                          staticClass: "setting-panel__title",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Terms & services")
                                            ),
                                          },
                                        }),
                                        _c("div", {
                                          staticClass: "setting-panel__text",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "Upload file with PDF/TXT/DOCX format only "
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "setting-panel__content w100 col d-md-none",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex w100 bet a-center" },
                                      [
                                        _vm._m(6),
                                        _c("div", {
                                          staticClass: "setting-panel__title",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Terms & services")
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "setting-panel__info w100",
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "setting-panel__text",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "Upload file with PDF/TXT/DOCX format only "
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm.termsToUpload
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "setting-panel__uploadedit",
                                      },
                                      [
                                        _c("div", { staticClass: "edit" }, [
                                          _c(
                                            "label",
                                            [
                                              _c("input", {
                                                attrs: {
                                                  accept: "application/pdf",
                                                  type: "file",
                                                },
                                                on: { change: _vm.addFile },
                                              }),
                                              _c("svg-icon", {
                                                attrs: {
                                                  name: "ic-terms-upload",
                                                  width: "32",
                                                  height: "32",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.termsToUpload
                                            ? _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.termsToUpload.name,
                                                    expression:
                                                      "termsToUpload.name",
                                                  },
                                                ],
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value: _vm.termsToUpload.name,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.termsToUpload,
                                                      "name",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "button",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  _vm.termsToUpload = null
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  attrs: {
                                                    width: "14",
                                                    height: "14",
                                                    viewBox: "0 0 14 14",
                                                    fill: "none",
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                  },
                                                },
                                                [
                                                  _c("path", {
                                                    staticClass: "second-fill",
                                                    attrs: {
                                                      d: "M13.7424 0.256009C13.401 -0.0853364 12.8536 -0.0853364 12.5122 0.256009L6.99918 5.76906L1.48614 0.256009C1.14479 -0.0853364 0.597354 -0.0853364 0.256009 0.256009C-0.0853363 0.597355 -0.0853363 1.1448 0.256009 1.48614L5.76905 6.9992L0.256009 12.5122C-0.0853363 12.8536 -0.0853363 13.401 0.256009 13.7424C0.423461 13.9098 0.648878 14 0.867854 14C1.08683 14 1.31225 13.9163 1.4797 13.7424L6.99274 8.22933L12.5058 13.7424C12.6732 13.9098 12.8987 14 13.1176 14C13.343 14 13.562 13.9163 13.7295 13.7424C14.0708 13.401 14.0708 12.8536 13.7295 12.5122L8.22931 6.9992L13.7424 1.48614C14.0837 1.1448 14.0837 0.597355 13.7424 0.256009Z",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm.restaurantData &&
                                    _vm.restaurantData.terms
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "setting-panel__upload-exist",
                                        on: { click: _vm.openTerms },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            name: "ic-terms-upload",
                                            width: "32",
                                            height: "32",
                                          },
                                        }),
                                        _vm.restaurantData
                                          ? _c("span", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.restaurantData.terms
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "upload-continer",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                              },
                                            },
                                          },
                                          [
                                            _c("input", {
                                              attrs: {
                                                type: "file",
                                                accept: "application/pdf",
                                              },
                                              on: { change: _vm.addFile },
                                            }),
                                            _c("svg-icon", {
                                              attrs: {
                                                name: "ic-upload",
                                                width: "20",
                                                height: "20",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "label",
                                      { staticClass: "setting-panel__upload" },
                                      [
                                        _c("input", {
                                          attrs: {
                                            type: "file",
                                            accept: "application/pdf",
                                          },
                                          on: { change: _vm.addFile },
                                        }),
                                        _c("svg-icon", {
                                          attrs: {
                                            name: "ic-upload",
                                            width: "20",
                                            height: "20",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _c("section", { staticClass: "main-settings" }, [
                    _c("div", { staticClass: "main-settings__sect" }, [
                      _c("div", { staticClass: "main-settings__content" }, [
                        _c("div", { staticClass: "main-settings__form" }, [
                          _c(
                            "form",
                            {
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "form-site" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-site__content" },
                                  [
                                    _vm.english
                                      ? _c("form-site-field-text", {
                                          attrs: { title: "Branch name" },
                                          model: {
                                            value: _vm.brancheData.name_en,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.brancheData,
                                                "name_en",
                                                $$v
                                              )
                                            },
                                            expression: "brancheData.name_en",
                                          },
                                        })
                                      : _c("form-site-field-text", {
                                          attrs: { title: "Branch name" },
                                          model: {
                                            value: _vm.brancheData.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.brancheData,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression: "brancheData.name",
                                          },
                                        }),
                                    _c("form-site-field-text", {
                                      attrs: { title: "Branch phone" },
                                      model: {
                                        value: _vm.brancheData.phone,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.brancheData,
                                            "phone",
                                            $$v
                                          )
                                        },
                                        expression: "brancheData.phone",
                                      },
                                    }),
                                    _vm.english
                                      ? _c("form-site-field-text", {
                                          attrs: { title: "City" },
                                          model: {
                                            value: _vm.brancheData.city_en,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.brancheData,
                                                "city_en",
                                                $$v
                                              )
                                            },
                                            expression: "brancheData.city_en",
                                          },
                                        })
                                      : _c("form-site-field-text", {
                                          attrs: { title: "City" },
                                          model: {
                                            value: _vm.brancheData.city,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.brancheData,
                                                "city",
                                                $$v
                                              )
                                            },
                                            expression: "brancheData.city",
                                          },
                                        }),
                                    _vm.english
                                      ? _c("form-site-field-text", {
                                          attrs: { title: "Address" },
                                          model: {
                                            value: _vm.brancheData.address_en,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.brancheData,
                                                "address_en",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "brancheData.address_en",
                                          },
                                        })
                                      : _c("form-site-field-text", {
                                          attrs: { title: "Address" },
                                          model: {
                                            value: _vm.brancheData.address,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.brancheData,
                                                "address",
                                                $$v
                                              )
                                            },
                                            expression: "brancheData.address",
                                          },
                                        }),
                                    _c("form-site-field-text", {
                                      attrs: { title: "External branch id" },
                                      model: {
                                        value:
                                          _vm.brancheData.external_branch_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.brancheData,
                                            "external_branch_id",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "brancheData.external_branch_id",
                                      },
                                    }),
                                    _c("form-site-field-text", {
                                      attrs: { title: "Zed number" },
                                      model: {
                                        value: _vm.brancheData.zed_number,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.brancheData,
                                            "zed_number",
                                            $$v
                                          )
                                        },
                                        expression: "brancheData.zed_number",
                                      },
                                    }),
                                    _c("form-site-field-text", {
                                      attrs: { title: "Order number" },
                                      model: {
                                        value: _vm.brancheData.order_number,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.brancheData,
                                            "order_number",
                                            $$v
                                          )
                                        },
                                        expression: "brancheData.order_number",
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "form-site__field" },
                                      [
                                        _c("span", {
                                          staticClass: "form-site__label",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Zed close time")
                                            ),
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "form-site__input" },
                                          [
                                            _c("select-box", {
                                              ref: "timepicker1",
                                              staticClass:
                                                "t-center timepicker-zed-close-time",
                                              attrs: {
                                                widthHeader: "190px",
                                                width: "190px",
                                                autocomplete: "",
                                                options: _vm.listHours,
                                                placeholder: "00:00",
                                              },
                                              model: {
                                                value:
                                                  _vm.brancheData
                                                    .zed_close_time,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.brancheData,
                                                    "zed_close_time",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "brancheData.zed_close_time",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "form-site__field",
                                        staticStyle: { display: "flex" },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "members-club__info" },
                                          [
                                            _c("checkbox", {
                                              attrs: {
                                                name: "hasTerminalTemplateCheckbox",
                                              },
                                              model: {
                                                value: _vm.brancheData.chain,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.brancheData,
                                                    "chain",
                                                    $$v
                                                  )
                                                },
                                                expression: "brancheData.chain",
                                              },
                                            }),
                                            _c("div", {
                                              staticClass:
                                                "members-club__title",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t("Chain")
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "form-site__field",
                                        staticStyle: { display: "flex" },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "members-club__info" },
                                          [
                                            _c("checkbox", {
                                              attrs: {
                                                name: "testingCheckbox",
                                              },
                                              model: {
                                                value: _vm.brancheData.testing,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.brancheData,
                                                    "testing",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "brancheData.testing",
                                              },
                                            }),
                                            _c("div", {
                                              staticClass:
                                                "members-club__title",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t("Testing branch")
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "form-site__field",
                                        staticStyle: { display: "flex" },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "members-club__info" },
                                          [
                                            _c("checkbox", {
                                              attrs: {
                                                name: "wsNotificationsCheckbox",
                                              },
                                              model: {
                                                value:
                                                  _vm.brancheData
                                                    .ws_notifications,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.brancheData,
                                                    "ws_notifications",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "brancheData.ws_notifications",
                                              },
                                            }),
                                            _c("div", {
                                              staticClass:
                                                "members-club__title",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    "Websocket notifications"
                                                  )
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "form-site__field",
                                        staticStyle: { display: "flex" },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "members-club__info" },
                                          [
                                            _c("checkbox", {
                                              attrs: {
                                                name: "savePaymentToken",
                                              },
                                              model: {
                                                value:
                                                  _vm.brancheData
                                                    .save_payment_token,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.brancheData,
                                                    "save_payment_token",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "brancheData.save_payment_token",
                                              },
                                            }),
                                            _c("div", {
                                              staticClass:
                                                "members-club__title",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t("Save credit cards")
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "form-site__field" },
                                      [
                                        _c("div", {
                                          staticClass: "form-site__label",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "Branch for calculate shabat"
                                              )
                                            ),
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "form-site__input" },
                                          [
                                            _vm.reload >= 1
                                              ? _c("select-box", {
                                                  key: _vm.brancheData
                                                    .hebcal_city,
                                                  staticClass: "branchShabat",
                                                  attrs: {
                                                    widthHeader: "190px",
                                                    width: "190px",
                                                    placeholder: String(
                                                      _vm.$t("select city")
                                                    ),
                                                    unsort: "",
                                                    showSearch: "",
                                                    options:
                                                      _vm.branchesForShabatOptions,
                                                  },
                                                  on: { input: _vm.input },
                                                  model: {
                                                    value:
                                                      _vm.brancheData
                                                        .hebcal_city,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.brancheData,
                                                        "hebcal_city",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "brancheData.hebcal_city",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-site__field food_general_cost",
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "form-site__label",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("food cost")
                                            ),
                                          },
                                        }),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.brancheData.food_cost,
                                              expression:
                                                "brancheData.food_cost",
                                            },
                                          ],
                                          attrs: { type: "number", min: "0" },
                                          domProps: {
                                            value: _vm.brancheData.food_cost,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.brancheData,
                                                "food_cost",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "form-site__field" },
                                      [
                                        _c("div", {
                                          staticClass: "form-site__label",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("branch general cost")
                                            ),
                                          },
                                        }),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.brancheData
                                                  .branch_general_cost,
                                              expression:
                                                "brancheData.branch_general_cost",
                                            },
                                          ],
                                          attrs: { type: "number", min: "0" },
                                          domProps: {
                                            value:
                                              _vm.brancheData
                                                .branch_general_cost,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.brancheData,
                                                "branch_general_cost",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "form-site__field" },
                                      [
                                        _c("div", {
                                          staticClass: "form-site__label",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "Additional text for finish order message"
                                              )
                                            ),
                                          },
                                        }),
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.brancheData
                                                  .finish_order_sms,
                                              expression:
                                                "brancheData.finish_order_sms",
                                            },
                                          ],
                                          domProps: {
                                            value:
                                              _vm.brancheData.finish_order_sms,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.brancheData,
                                                "finish_order_sms",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "form-site__field" },
                                      [
                                        _c("div", {
                                          staticClass: "form-site__label",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Automatic staffing")
                                            ),
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "form-site__input" },
                                          [
                                            _c("select-box", {
                                              staticClass: "branchShabat",
                                              attrs: {
                                                widthHeader: "190px",
                                                width: "190px",
                                                unsort: "",
                                                options:
                                                  _vm.deliveryProvidersOptions,
                                              },
                                              model: {
                                                value:
                                                  _vm.brancheData
                                                    .default_delivery_provider_id,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.brancheData,
                                                    "default_delivery_provider_id",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "brancheData.default_delivery_provider_id",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c("button", {
                                  staticClass: "btn btn_border",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("Set coordinates")
                                    ),
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.popupCoordinates = true
                                    },
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _vm.brancheData && _vm.brancheData.dragontail
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "main-settings__sect main-settings__sect_end",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "main-settings__content" },
                              [
                                _c(
                                  "form",
                                  {
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "members-club" }, [
                                      _c(
                                        "div",
                                        { staticClass: "members-club__top" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "members-club__info",
                                            },
                                            [
                                              _c("checkbox", {
                                                attrs: {
                                                  name: "hasTerminalTemplateCheckbox",
                                                },
                                                model: {
                                                  value:
                                                    _vm.brancheData.dragontail
                                                      .active,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.brancheData
                                                        .dragontail,
                                                      "active",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "brancheData.dragontail.active",
                                                },
                                              }),
                                              _c("div", {
                                                staticClass:
                                                  "members-club__title",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "Interfacing to Dragon"
                                                    )
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c("div", { staticClass: "form-site" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-site__content flex f-wrap",
                                          },
                                          [
                                            _c("form-site-field-text", {
                                              attrs: {
                                                helf: "",
                                                title: "Branch number",
                                              },
                                              model: {
                                                value:
                                                  _vm.brancheData.dragontail
                                                    .storeNo,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.brancheData.dragontail,
                                                    "storeNo",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "brancheData.dragontail.storeNo",
                                              },
                                            }),
                                            _c("form-site-field-text", {
                                              attrs: {
                                                helf: "",
                                                title: "User name",
                                              },
                                              model: {
                                                value:
                                                  _vm.brancheData.dragontail
                                                    .userName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.brancheData.dragontail,
                                                    "userName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "brancheData.dragontail.userName",
                                              },
                                            }),
                                            _c("form-site-field-text", {
                                              attrs: {
                                                helf: "",
                                                title: "Password",
                                              },
                                              model: {
                                                value:
                                                  _vm.brancheData.dragontail
                                                    .password,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.brancheData.dragontail,
                                                    "password",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "brancheData.dragontail.password",
                                              },
                                            }),
                                            _c("form-site-field-text", {
                                              attrs: {
                                                helf: "",
                                                title: "Host",
                                              },
                                              model: {
                                                value:
                                                  _vm.brancheData.dragontail
                                                    .host,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.brancheData.dragontail,
                                                    "host",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "brancheData.dragontail.host",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "main-settings__sect" }, [
                      _c("div", {
                        staticClass: "swich-block-wrap__title",
                        domProps: {
                          textContent: _vm._s(_vm.$t("Credit card terminal")),
                        },
                      }),
                      _c("div", { staticClass: "main-settings__content" }, [
                        _c("div", { staticClass: "main-settings__form" }, [
                          _c(
                            "form",
                            {
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "form-site" }, [
                                _vm.brancheData
                                  ? _c(
                                      "div",
                                      { staticClass: "form-site__content" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-site__field" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "form-site__field-wrap checkbox-label",
                                              },
                                              [
                                                _c("checkbox", {
                                                  attrs: {
                                                    name: "hasTerminalTemplateCheckbox",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.brancheData
                                                        .has_terminal_template,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.brancheData,
                                                        "has_terminal_template",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "brancheData.has_terminal_template",
                                                  },
                                                }),
                                                _c("div", {
                                                  staticClass:
                                                    "form-site__label",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t("Using template")
                                                    ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "form-site__field" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "form-site__field-wrap checkbox-label",
                                              },
                                              [
                                                _c("checkbox", {
                                                  attrs: {
                                                    name: "hasTerminalTemplateCheckbox",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.brancheData
                                                        .has_split_payments,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.brancheData,
                                                        "has_split_payments",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "brancheData.has_split_payments",
                                                  },
                                                }),
                                                _c("div", {
                                                  staticClass:
                                                    "form-site__label",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t("Split payments")
                                                    ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "form-site__field" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "form-site__field-wrap checkbox-label",
                                              },
                                              [
                                                _c("checkbox", {
                                                  attrs: {
                                                    name: "hasTerminalTemplateCheckbox",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.brancheData.has_tip,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.brancheData,
                                                        "has_tip",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "brancheData.has_tip",
                                                  },
                                                }),
                                                _c("div", {
                                                  staticClass:
                                                    "form-site__label",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t("Has tip")
                                                    ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "form-site__field" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "form-site__field-wrap checkbox-label",
                                              },
                                              [
                                                _c("checkbox", {
                                                  attrs: {
                                                    name: "print_canceled_bon",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.brancheData
                                                        .print_canceled_bon,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.brancheData,
                                                        "print_canceled_bon",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "brancheData.print_canceled_bon",
                                                  },
                                                }),
                                                _c("div", {
                                                  staticClass:
                                                    "form-site__label",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        "Print canceled bon"
                                                      )
                                                    ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c("div", {
                                          staticClass: "form-site__field",
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "form-site__field" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "form-site__field-wrap",
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "form-site__label",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t("Auto_print")
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c("select-box", {
                                              attrs: {
                                                name: "auto_print",
                                                options: _vm.autoPrintOptions,
                                              },
                                              model: {
                                                value:
                                                  _vm.brancheData.auto_print,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.brancheData,
                                                    "auto_print",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "brancheData.auto_print",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("form-site-field-text", {
                                          attrs: { title: "api key" },
                                          model: {
                                            value:
                                              _vm.brancheData.terminal_api_key,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.brancheData,
                                                "terminal_api_key",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "brancheData.terminal_api_key",
                                          },
                                        }),
                                        _c("form-site-field-text", {
                                          attrs: { title: "Terminal number" },
                                          model: {
                                            value:
                                              _vm.brancheData.terminal_number,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.brancheData,
                                                "terminal_number",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "brancheData.terminal_number",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "main-settings__sect" }, [
                      _c("div", {
                        staticClass: "swich-block-wrap__title",
                        domProps: { textContent: _vm._s(_vm.$t("Get orders")) },
                      }),
                      _c("div", { staticClass: "main-settings__content" }, [
                        _c("div", { staticClass: "main-settings__form" }, [
                          _c(
                            "form",
                            {
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "form-site" }, [
                                _vm.brancheData
                                  ? _c(
                                      "div",
                                      { staticClass: "form-site__content" },
                                      [
                                        _c("form-site-field-text", {
                                          attrs: { title: "fax" },
                                          model: {
                                            value: _vm.brancheData.faxnumber,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.brancheData,
                                                "faxnumber",
                                                $$v
                                              )
                                            },
                                            expression: "brancheData.faxnumber",
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "form-site__field" },
                                          [
                                            _c("div", {
                                              staticClass: "form-site__label",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t("sms")
                                                ),
                                              },
                                            }),
                                            _c("text-box-list", {
                                              model: {
                                                value: _vm.brancheData.phones,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.brancheData,
                                                    "phones",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "brancheData.phones",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "form-site__field" },
                                          [
                                            _c("div", {
                                              staticClass: "form-site__label",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t("error phones")
                                                ),
                                              },
                                            }),
                                            _c("text-box-list", {
                                              model: {
                                                value:
                                                  _vm.brancheData.error_phones,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.brancheData,
                                                    "error_phones",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "brancheData.error_phones",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "form-site__field" },
                                          [
                                            _c("div", {
                                              staticClass: "form-site__label",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t("email")
                                                ),
                                              },
                                            }),
                                            _c("text-box-list", {
                                              model: {
                                                value: _vm.brancheData.emails,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.brancheData,
                                                    "emails",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "brancheData.emails",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "main-settings__sect main-settings__sect_end",
                      },
                      [
                        _c("div", { staticClass: "main-settings__content" }, [
                          _c("div", { staticClass: "setting-panel" }, [
                            _c(
                              "div",
                              { staticClass: "setting-panel__content" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "setting-panel__info" },
                                  [
                                    _c("div", {
                                      staticClass: "setting-panel__title",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Admin password")
                                        ),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "members-club__input pt10 maxw300",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "view-pass-btn",
                                            on: { mousedown: _vm.viewPass },
                                          },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                name: "eye",
                                                width: "22",
                                                height: "22",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm.isPass
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.brancheData.refund_pswd,
                                                  expression:
                                                    "brancheData.refund_pswd",
                                                },
                                              ],
                                              staticClass: "text-center",
                                              attrs: {
                                                dir: "ltr",
                                                type: "password",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.brancheData.refund_pswd,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.brancheData,
                                                    "refund_pswd",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            })
                                          : _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.brancheData.refund_pswd,
                                                  expression:
                                                    "brancheData.refund_pswd",
                                                },
                                              ],
                                              staticClass: "text-center",
                                              attrs: {
                                                dir: "ltr",
                                                type: "text",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.brancheData.refund_pswd,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.brancheData,
                                                    "refund_pswd",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "main-settings__sect" }, [
                      _c("div", { staticClass: "swich-block-wrap" }, [
                        _c("div", {
                          staticClass: "swich-block-wrap__title",
                          domProps: { textContent: _vm._s(_vm.$t("Features")) },
                        }),
                        _c("div", { staticClass: "swich-block-container" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "swich-block swich-block-accessibility",
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "swich-block__info" },
                                [
                                  _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "swich-block__img" },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              width: "48",
                                              height: "48",
                                              viewBox: "0 0 48 48",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c(
                                              "g",
                                              {
                                                attrs: {
                                                  "clip-path": "url(#clip0)",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  staticClass: "second-fill",
                                                  attrs: {
                                                    d: "M21.9821 9.46029C24.5918 9.46029 26.712 7.34252 26.712 4.73019C26.712 2.11708 24.5918 0 21.9821 0C19.3694 0 17.251 2.11708 17.251 4.73019C17.251 7.34252 19.3694 9.46029 21.9821 9.46029Z",
                                                  },
                                                }),
                                                _c("path", {
                                                  staticClass: "second-fill",
                                                  attrs: {
                                                    d: "M29.2919 36.2645C28.035 40.6288 24.0116 43.8339 19.2479 43.8339C13.4792 43.8339 8.78794 39.1425 8.78794 33.3755C8.78794 29.1066 11.3633 25.4324 15.0386 23.8088V19.375C9.01976 21.1879 4.62207 26.7751 4.62207 33.3755C4.62207 41.4393 11.1831 48.0004 19.2478 48.0004C25.125 48.0004 30.1907 44.5074 32.5135 39.4956L30.862 36.2511C30.3985 36.2549 29.8615 36.2598 29.2919 36.2645Z",
                                                  },
                                                }),
                                                _c("path", {
                                                  staticClass: "second-fill",
                                                  attrs: {
                                                    d: "M43.074 41.8782L36.7381 29.4217C36.2652 28.4897 35.3093 27.903 34.2636 27.903H26.1487V26.0526H33.1327C33.8301 26.0526 34.4163 25.6881 34.7949 25.163C35.041 24.8209 35.216 24.4235 35.216 23.9692C35.216 22.819 34.2844 21.886 33.1327 21.886H28.9252H26.1487V15.6098C26.1487 13.8837 24.8689 11.4434 21.9837 11.4434C19.6827 11.4434 17.8174 13.309 17.8174 15.6098V29.2078C17.8174 31.555 19.7202 33.4578 22.0675 33.4578H29.7035H32.5607L38.1243 44.3966C38.6132 45.3601 39.5888 45.916 40.6014 45.916C41.0258 45.916 41.4561 45.8191 41.8583 45.6132C43.2275 44.9187 43.7714 43.2455 43.074 41.8782Z",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c("path", {
                                              staticClass: "main-fill",
                                              attrs: {
                                                d: "M31 0.171123H32.7155L33.7387 4.18182L33.9144 5.1016H34.0177L34.2347 4.18182L35.1338 1.19786H36.6427L37.5521 4.18182L37.7588 5.08021H37.8622L38.0172 4.18182L38.9783 0.171123H40.6938L38.7406 7.82888H37.2111L36.157 4.47059L35.9399 3.5508H35.8366L35.6196 4.47059L34.5654 7.82888H33.0359L31 0.171123Z",
                                              },
                                            }),
                                            _c("path", {
                                              staticClass: "main-fill",
                                              attrs: {
                                                d: "M48 6.58823C47.2421 7.52941 46.2535 8 45.034 8C44.4828 8 43.9695 7.89661 43.4941 7.68984C43.0187 7.48307 42.6053 7.20143 42.254 6.84492C41.9095 6.48128 41.6373 6.05704 41.4375 5.57219C41.2377 5.08734 41.1378 4.56328 41.1378 4C41.1378 3.43672 41.2377 2.91266 41.4375 2.42781C41.6373 1.94296 41.9095 1.52228 42.254 1.16578C42.6053 0.802139 43.0187 0.516934 43.4941 0.310161C43.9695 0.103387 44.4828 0 45.034 0C45.6403 0 46.1639 0.106952 46.6048 0.320856C47.0527 0.534759 47.4592 0.84492 47.8243 1.25134L46.6978 2.38503C46.5049 2.15686 46.2741 1.96791 46.0054 1.81818C45.7367 1.66132 45.4164 1.58289 45.0443 1.58289C44.7205 1.58289 44.4173 1.63993 44.1349 1.75401C43.8593 1.86809 43.6181 2.03209 43.4114 2.24599C43.2047 2.45989 43.0428 2.71658 42.9257 3.01604C42.8086 3.30838 42.75 3.63636 42.75 4C42.75 4.36364 42.8086 4.69519 42.9257 4.99465C43.0428 5.28699 43.2047 5.54011 43.4114 5.75401C43.6181 5.96791 43.8593 6.13191 44.1349 6.24599C44.4173 6.36007 44.7205 6.41711 45.0443 6.41711C45.437 6.41711 45.7781 6.33512 46.0674 6.17112C46.3568 6.00713 46.6186 5.77184 46.8529 5.46524L48 6.58823Z",
                                              },
                                            }),
                                            _c("defs", [
                                              _c(
                                                "clipPath",
                                                { attrs: { id: "clip0" } },
                                                [
                                                  _c("rect", {
                                                    attrs: {
                                                      width: "48",
                                                      height: "48",
                                                      fill: "white",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("div", {
                                      staticClass: "swich-block__title",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Toilet for disabled")
                                        ),
                                      },
                                    }),
                                  ]),
                                  _vm.brancheData.accessability
                                    ? _c("swich-button", {
                                        model: {
                                          value:
                                            _vm.brancheData.accessability
                                              .toilet_for_disabled,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.brancheData.accessability,
                                              "toilet_for_disabled",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "brancheData.accessability.toilet_for_disabled",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "label",
                                { staticClass: "swich-block__info" },
                                [
                                  _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "swich-block__img" },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              width: "42",
                                              height: "48",
                                              viewBox: "0 0 42 48",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              staticClass: "main-fill",
                                              attrs: {
                                                d: "M22.4004 38.062C22.4004 43.489 26.7971 47.999 32.2001 47.999C37.6031 47.999 41.9998 43.489 41.9998 38.062C41.9998 32.635 37.6031 28.2188 32.2001 28.2188C26.7971 28.2188 22.4004 32.635 22.4004 38.062ZM28.4104 36.2438C27.8636 35.6946 27.8636 34.8046 28.4104 34.2554C28.9572 33.7061 29.8432 33.7061 30.39 34.2554L32.2002 36.0736L34.0103 34.2554C34.5572 33.7061 35.4431 33.7061 35.99 34.2554C36.5368 34.8046 36.5368 35.6946 35.99 36.2438L34.1798 38.062L35.99 39.8802C36.5368 40.4294 36.5368 41.3194 35.99 41.8686C35.4431 42.4179 34.5572 42.4179 34.0103 41.8686L32.2002 40.0504L30.39 41.8686C29.8432 42.4179 28.9572 42.4179 28.4104 41.8686C27.8636 41.3194 27.8636 40.4294 28.4104 39.8802L30.2206 38.062L28.4104 36.2438Z",
                                              },
                                            }),
                                            _c("path", {
                                              staticClass: "second-fill",
                                              attrs: {
                                                d: "M9.80932 47.9999C15.193 47.9999 19.5994 43.5286 19.5994 38.063C19.5994 34.0696 21.4587 30.362 24.7002 27.8916C28.576 24.9365 30.7991 20.4433 30.7991 15.5642C30.7991 7.15362 24.0088 -0.0205795 15.3995 4.43561e-05C6.57411 0.021137 0 7.67344 0 16.1685V37.5851C0 43.0763 4.31196 47.9999 9.80932 47.9999ZM8.23838 26.1586C6.51167 22.8958 5.59983 19.2349 5.59983 15.5712C5.59983 10.4926 9.74464 5.62736 15.3995 5.62736C20.8025 5.62736 25.1992 10.1373 25.1992 15.5643C25.1992 20.9913 20.8025 25.4075 15.3995 25.4075C14.6257 25.4075 13.9996 24.7786 13.9996 24.0014C13.9996 23.2241 14.6257 22.5952 15.3995 22.5952C19.259 22.5952 22.3993 19.4409 22.3993 15.5643C22.3993 11.6877 19.259 8.43971 15.3995 8.43971C13.1203 8.43971 11.1117 9.65005 9.83265 11.3493C13.6767 11.3678 16.7995 14.5115 16.7995 18.3767C16.7995 19.1539 16.1733 19.7828 15.3995 19.7828C14.6257 19.7828 13.9996 19.1539 13.9996 18.3767C13.9996 16.0505 12.1156 14.1581 9.79971 14.1581H8.54142C8.44912 14.6127 8.39975 15.0828 8.39975 15.5643C8.39975 18.7763 9.19819 21.98 10.7088 24.8349C12.1785 27.598 10.1919 31.0322 6.99979 31.0322C6.22599 31.0322 5.59983 30.4033 5.59983 29.626C5.59983 28.8488 6.22599 28.2199 6.99979 28.2199C8.06637 28.2198 8.72444 27.0722 8.23838 26.1586Z",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("div", {
                                      staticClass: "swich-block__title",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Hearing loss")
                                        ),
                                      },
                                    }),
                                  ]),
                                  _vm.brancheData.accessability
                                    ? _c("swich-button", {
                                        model: {
                                          value:
                                            _vm.brancheData.accessability
                                              .hearing_loss,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.brancheData.accessability,
                                              "hearing_loss",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "brancheData.accessability.hearing_loss",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "label",
                                { staticClass: "swich-block__info" },
                                [
                                  _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "swich-block__img" },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              width: "48",
                                              height: "48",
                                              viewBox: "0 0 48 48",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c(
                                              "g",
                                              {
                                                attrs: {
                                                  "clip-path": "url(#clip0)",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  staticClass: "second-fill",
                                                  attrs: {
                                                    d: "M21.9821 9.46029C24.5918 9.46029 26.712 7.34252 26.712 4.73019C26.712 2.11708 24.5918 0 21.9821 0C19.3694 0 17.251 2.11708 17.251 4.73019C17.251 7.34252 19.3694 9.46029 21.9821 9.46029Z",
                                                  },
                                                }),
                                                _c("path", {
                                                  staticClass: "second-fill",
                                                  attrs: {
                                                    d: "M29.2919 36.2645C28.035 40.6288 24.0116 43.8339 19.2479 43.8339C13.4792 43.8339 8.78794 39.1425 8.78794 33.3755C8.78794 29.1066 11.3633 25.4324 15.0386 23.8088V19.375C9.01976 21.1879 4.62207 26.7751 4.62207 33.3755C4.62207 41.4393 11.1831 48.0004 19.2478 48.0004C25.125 48.0004 30.1907 44.5074 32.5135 39.4956L30.862 36.2511C30.3985 36.2549 29.8615 36.2598 29.2919 36.2645Z",
                                                  },
                                                }),
                                                _c("path", {
                                                  staticClass: "second-fill",
                                                  attrs: {
                                                    d: "M43.074 41.8782L36.7381 29.4217C36.2652 28.4897 35.3093 27.903 34.2636 27.903H26.1487V26.0526H33.1327C33.8301 26.0526 34.4163 25.6881 34.7949 25.163C35.041 24.8209 35.216 24.4235 35.216 23.9692C35.216 22.819 34.2844 21.886 33.1327 21.886H28.9252H26.1487V15.6098C26.1487 13.8837 24.8689 11.4434 21.9837 11.4434C19.6827 11.4434 17.8174 13.309 17.8174 15.6098V29.2078C17.8174 31.555 19.7202 33.4578 22.0675 33.4578H29.7035H32.5607L38.1243 44.3966C38.6132 45.3601 39.5888 45.916 40.6014 45.916C41.0258 45.916 41.4561 45.8191 41.8583 45.6132C43.2275 44.9187 43.7714 43.2455 43.074 41.8782Z",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c("defs", [
                                              _c(
                                                "clipPath",
                                                { attrs: { id: "clip1" } },
                                                [
                                                  _c("rect", {
                                                    attrs: {
                                                      width: "48",
                                                      height: "48",
                                                      fill: "white",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("div", {
                                      staticClass: "swich-block__title",
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Access")),
                                      },
                                    }),
                                  ]),
                                  _vm.brancheData.accessability
                                    ? _c("swich-button", {
                                        model: {
                                          value:
                                            _vm.brancheData.accessability
                                              .access,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.brancheData.accessability,
                                              "access",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "brancheData.accessability.access",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "label",
                                { staticClass: "swich-block__info" },
                                [
                                  _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "swich-block__img" },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              width: "48",
                                              height: "48",
                                              viewBox: "0 0 48 48",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              staticClass: "second-fill",
                                              attrs: {
                                                d: "M24 0C19.2533 0 14.6131 1.40758 10.6663 4.04473C6.71954 6.68188 3.6434 10.4302 1.8269 14.8156C0.0103989 19.201 -0.464881 24.0266 0.461164 28.6822C1.38721 33.3377 3.67299 37.6141 7.02945 40.9706C10.3859 44.327 14.6623 46.6128 19.3178 47.5388C23.9734 48.4649 28.799 47.9896 33.1844 46.1731C37.5698 44.3566 41.3181 41.2805 43.9553 37.3337C46.5924 33.3869 48 28.7467 48 24C47.9929 17.637 45.462 11.5367 40.9627 7.03733C36.4633 2.538 30.363 0.00714632 24 0V0ZM24 44C20.0444 44 16.1776 42.827 12.8886 40.6294C9.59962 38.4318 7.03617 35.3082 5.52242 31.6537C4.00867 27.9991 3.6126 23.9778 4.3843 20.0982C5.15601 16.2186 7.06082 12.6549 9.85787 9.85786C12.6549 7.06081 16.2186 5.156 20.0982 4.38429C23.9778 3.61259 27.9992 4.00866 31.6537 5.52241C35.3082 7.03616 38.4318 9.59961 40.6294 12.8886C42.827 16.1776 44 20.0444 44 24C43.994 29.3025 41.8849 34.3861 38.1355 38.1355C34.3861 41.8849 29.3025 43.994 24 44Z",
                                              },
                                            }),
                                            _c("path", {
                                              staticClass: "second-fill",
                                              attrs: {
                                                d: "M27 14H20C19.4696 14 18.9609 14.2107 18.5858 14.5858C18.2107 14.9609 18 15.4696 18 16V36H22V28H27C28.8565 28 30.637 27.2625 31.9497 25.9497C33.2625 24.637 34 22.8565 34 21C34 19.1435 33.2625 17.363 31.9497 16.0502C30.637 14.7375 28.8565 14 27 14ZM27 24H22V18H27C27.7956 18 28.5587 18.3161 29.1213 18.8787C29.6839 19.4413 30 20.2043 30 21C30 21.7956 29.6839 22.5587 29.1213 23.1213C28.5587 23.6839 27.7956 24 27 24Z",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("div", {
                                      staticClass: "swich-block__title",
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Parking")),
                                      },
                                    }),
                                  ]),
                                  _vm.brancheData.accessability
                                    ? _c("swich-button", {
                                        model: {
                                          value:
                                            _vm.brancheData.accessability
                                              .parking,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.brancheData.accessability,
                                              "parking",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "brancheData.accessability.parking",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "swich-block-wrap__list" }, [
                            _vm.brancheData.kosher === true ||
                            _vm.brancheData.kosher === false
                              ? _c("div", { staticClass: "swich-block" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "swich-block__info",
                                      on: {
                                        click: function ($event) {
                                          _vm.kosherPopup = true
                                        },
                                      },
                                    },
                                    [
                                      _vm._m(7),
                                      _c("div", {
                                        staticClass: "swich-block__title",
                                        domProps: {
                                          textContent: _vm._s(_vm.$t("Kosher")),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "flex a-center j-end" },
                                    [
                                      _vm.brancheData
                                        ? _c("swich-button", {
                                            model: {
                                              value: _vm.brancheData.kosher,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.brancheData,
                                                  "kosher",
                                                  $$v
                                                )
                                              },
                                              expression: "brancheData.kosher",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
            ],
            1
          ),
        ]),
        _vm.change && !_vm.allBranches
          ? _c("div", { staticClass: "btn-save-container-settings" }, [
              _c(
                "button",
                { staticClass: "save-btn", on: { click: _vm.saveSettings } },
                [
                  _c("svg-icon", {
                    staticClass: "ic-edit",
                    attrs: { name: "ic-save-btn", width: "112", height: "128" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.changeAllBranches && _vm.allBranches
          ? _c("div", { staticClass: "btn-save-container-settings" }, [
              _c(
                "button",
                { staticClass: "save-btn", on: { click: _vm.saveSettings } },
                [
                  _c("svg-icon", {
                    staticClass: "ic-edit",
                    attrs: { name: "ic-save-btn", width: "112", height: "128" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _vm.kosherPopup
        ? _c("kosher-dialog", {
            on: {
              close: function ($event) {
                _vm.kosherPopup = false
              },
            },
          })
        : _vm._e(),
      _vm.popupCoordinates && _vm.brancheData.coordinates
        ? _c("choose-coordinates", {
            attrs: { coordinates: _vm.brancheData.coordinates },
            on: { save: _vm.saveCoordinates },
          })
        : _vm._e(),
      _vm.showTermsAndConditionsEditDialog
        ? _c(
            "base-dialog",
            { on: { close: _vm.closeEditTermsAndConditions } },
            [
              _c("div", { staticClass: "edit-terms-dialog popup__content" }, [
                _c("div", { staticClass: "popup__head" }, [
                  _c("h3", [
                    _vm._v(_vm._s(_vm.$t("Edit Terms and conditions"))),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "popup__body" },
                  [
                    _c("quill-editor", {
                      attrs: {
                        options: _vm.editorOption,
                        name: "terms_and_conditions",
                      },
                      model: {
                        value: _vm.restaurantData.html_restaurant_terms,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.restaurantData,
                            "html_restaurant_terms",
                            $$v
                          )
                        },
                        expression: "restaurantData.html_restaurant_terms",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    attrs: { type: "button" },
                    on: { click: _vm.saveEditTermsAndConditions },
                  },
                  [_vm._v(_vm._s(_vm.$t("Save")))]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.showCreateLegacyClubDialog
        ? _c("add-legacy-club-dialog", { on: { save: _vm.saveLegacyClub } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "members-club__logo" }, [
      _c("img", {
        staticStyle: { width: "48px", height: "48px" },
        attrs: { src: require("@/assets/img/translater.svg"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "members-club__logo" }, [
      _c("img", { attrs: { src: require("@/assets/img/euro.svg"), alt: "" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "setting-panel__img" }, [
      _c("img", { attrs: { src: require("@/assets/img/power.svg"), alt: "" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "setting-panel__img" }, [
      _c("img", { attrs: { src: require("@/assets/img/power.svg"), alt: "" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "orders-settings__img" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/pickup.svg"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "setting-panel__img" }, [
      _c("img", { attrs: { src: require("@/assets/img/file.svg"), alt: "" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "setting-panel__img" }, [
      _c("img", { attrs: { src: require("@/assets/img/file.svg"), alt: "" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "swich-block__img" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/kosher.svg"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }