var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      staticClass: "atmos-promotions-success-msg",
      attrs: { width: "600px", height: "800px", overflowAuto: "" },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("div", { staticClass: "atmos-promotions-success-msg__header" }, [
        _c(
          "div",
          { staticClass: "atmos-promotions-success-msg__header__backgroung" },
          [
            _c("img", {
              staticClass: "img",
              attrs: { src: require("../assets/img/vi.png") },
            }),
          ]
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "align-items": "center",
            },
          },
          [
            _c("h3", {
              staticStyle: { "font-weight": "500", "font-size": "32px" },
              domProps: { textContent: _vm._s(_vm.$t("Congratulations")) },
            }),
            _c("span", {
              staticClass: "atmos-promotions-success-msg__footer__span",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("Your promotions is done and ready to use")
                ),
              },
            }),
          ]
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "space-around",
              width: "100%",
            },
          },
          [
            _c(
              "button",
              { staticClass: "btn-green" },
              [
                _c("svg-icon", {
                  staticClass: "ic-white",
                  attrs: { name: "ic-arrow-left", width: "16", height: "12" },
                }),
                _c("span", {
                  domProps: { textContent: _vm._s(_vm.$t("Go to list")) },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }