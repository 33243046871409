var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      staticClass: "edit-extention-dialog",
      attrs: { title: "Extentions", width: "480px" },
      on: { close: _vm.close },
    },
    [
      _c("div", { staticClass: "popup__content" }, [
        _c("div", { staticClass: "form-new-address" }, [
          _c(
            "form",
            {
              staticClass: "form-site-wrap",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.save.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "form-site" }, [
                _c("div", { staticClass: "form-site__content" }, [
                  _c("div", { staticClass: "form-site__field" }, [
                    _c("div", { staticClass: "extentions-club__field" }, [
                      _c("div", {
                        staticClass: "extentions-club__label",
                        domProps: {
                          textContent: _vm._s(_vm.$t("Extention number")),
                        },
                      }),
                      _c("div", { staticClass: "extentions-club__input" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.extentionCopy.username,
                              expression: "extentionCopy.username",
                            },
                          ],
                          attrs: { required: "", type: "text" },
                          domProps: { value: _vm.extentionCopy.username },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.extentionCopy,
                                "username",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-site__field" }, [
                    _c("div", { staticClass: "extentions-club__field" }, [
                      _c("div", {
                        staticClass: "extentions-club__label",
                        domProps: { textContent: _vm._s(_vm.$t("Password")) },
                      }),
                      _c("div", { staticClass: "extentions-club__input" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.extentionCopy.password,
                              expression: "extentionCopy.password",
                            },
                          ],
                          attrs: { required: "", type: "text" },
                          domProps: { value: _vm.extentionCopy.password },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.extentionCopy,
                                "password",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "form-site__btn md-none" },
                [
                  _vm.extentionCopy.id
                    ? _c("remove-btn", { on: { click: _vm.remove } })
                    : _vm._e(),
                  _c("div", {}),
                  _c("button", {
                    staticClass: "btn",
                    attrs: { type: "submit" },
                    domProps: { textContent: _vm._s(_vm.$t("Save")) },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "new-item__btn flex d-md-none" }, [
                _c(
                  "button",
                  { staticClass: "save-btn", attrs: { type: "submit" } },
                  [
                    _c("svg-icon", {
                      staticClass: "ic-edit",
                      attrs: { name: "ic-save-btn", width: "93", height: "93" },
                    }),
                  ],
                  1
                ),
                _c(
                  "button",
                  { staticClass: "bin-btn", on: { click: _vm.remove } },
                  [
                    _c("svg-icon", {
                      attrs: { name: "ic-bin", width: "18", height: "20" },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }