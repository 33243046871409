var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-dialog-tabs", {
    staticClass: "edit-category",
    attrs: { width: "740px", tabList: _vm.tabList },
    on: { close: _vm.exit },
    scopedSlots: _vm._u([
      {
        key: "tab0",
        fn: function () {
          return [
            _c("div", { staticClass: "form-lang" }, [
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "form-site" }, [
                    _c("div", { staticClass: "top__header" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "form-site__head form-site__head_grey d-none d-md-block",
                          staticStyle: { width: "60%" },
                        },
                        [
                          _vm.english
                            ? _c("form-site-field-text", {
                                attrs: { title: _vm.$t("Category name") },
                                model: {
                                  value: _vm.categoryCopy.name_en,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.categoryCopy, "name_en", $$v)
                                  },
                                  expression: "categoryCopy.name_en",
                                },
                              })
                            : _vm._e(),
                          !_vm.english
                            ? _c("form-site-field-text", {
                                attrs: { title: _vm.$t("Category name") },
                                model: {
                                  value: _vm.categoryCopy.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.categoryCopy, "name", $$v)
                                  },
                                  expression: "categoryCopy.name",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "form-site__lang d-none d-md-inline-flex",
                        },
                        [
                          _c("div", { staticClass: "lang lang_grey" }, [
                            _c("button", {
                              staticClass: "lang__item",
                              class: { active: _vm.english },
                              domProps: {
                                textContent: _vm._s(_vm.$t("English")),
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.english = true
                                },
                              },
                            }),
                            _c("button", {
                              staticClass: "lang__item",
                              class: { active: !_vm.english },
                              domProps: {
                                textContent: _vm._s(_vm.$t("Hebrew")),
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.english = false
                                },
                              },
                            }),
                          ]),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "form-site__content" }, [
                      !_vm.categoryCopy.sub_categories ||
                      !_vm.categoryCopy.sub_categories.length
                        ? _c(
                            "div",
                            {
                              staticClass: "form-site__group",
                              staticStyle: { width: "100%" },
                            },
                            [
                              _c("form-site-field-select-box", {
                                staticClass: "category__father",
                                attrs: {
                                  title: "Parent category",
                                  maxHeight: "300px",
                                  firstTop: "",
                                  width: "100%",
                                  widthHeader: "100%",
                                  options: _vm.categoriesToParents,
                                },
                                model: {
                                  value: _vm.categoryCopy.parent_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.categoryCopy, "parent_id", $$v)
                                  },
                                  expression: "categoryCopy.parent_id",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "form-site__field" },
                        [
                          _c("form-site-field-text", {
                            attrs: { title: _vm.$t("UTM text") },
                            model: {
                              value: _vm.categoryCopy.utm_text,
                              callback: function ($$v) {
                                _vm.$set(_vm.categoryCopy, "utm_text", $$v)
                              },
                              expression: "categoryCopy.utm_text",
                            },
                          }),
                          _c(
                            "div",
                            [
                              _c("div", {
                                staticClass: "form-site__label",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("Hidden category")
                                  ),
                                },
                              }),
                              _c("checkbox", {
                                staticClass: "cb",
                                attrs: { name: "cb" },
                                model: {
                                  value: _vm.categoryCopy.hidden_category,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.categoryCopy,
                                      "hidden_category",
                                      $$v
                                    )
                                  },
                                  expression: "categoryCopy.hidden_category",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "form-site__field" }, [
                        _c("div", {
                          staticClass: "form-site__label",
                          domProps: {
                            textContent: _vm._s(_vm.$t("Description")),
                          },
                        }),
                        _c("div", { staticClass: "form-site__input" }, [
                          _vm.english
                            ? _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.categoryCopy.text_top_en,
                                    expression: "categoryCopy.text_top_en",
                                  },
                                ],
                                attrs: {
                                  placeholder: _vm.$t("Category description"),
                                },
                                domProps: {
                                  value: _vm.categoryCopy.text_top_en,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.categoryCopy,
                                      "text_top_en",
                                      $event.target.value
                                    )
                                  },
                                },
                              })
                            : _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.categoryCopy.text_top,
                                    expression: "categoryCopy.text_top",
                                  },
                                ],
                                attrs: {
                                  placeholder: _vm.$t("Category description"),
                                },
                                domProps: { value: _vm.categoryCopy.text_top },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.categoryCopy,
                                      "text_top",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "tab1",
        fn: function () {
          return [
            _c("div", { staticClass: "form-site__head-wrap" }, [
              _c("div", {
                staticClass: "form-site__head d-none d-md-block",
                domProps: {
                  textContent: _vm._s(
                    _vm.he ? _vm.categoryCopy.name : _vm.categoryCopy.name_en
                  ),
                },
              }),
            ]),
            _c("branches-tab", {
              attrs: { availability: _vm.categoryCopy.availability },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "tab2",
        fn: function () {
          return [
            _c("div", { staticClass: "form-site__head-wrap" }, [
              _c("div", {
                staticClass: "form-site__head d-none d-md-block",
                domProps: {
                  textContent: _vm._s(
                    _vm.he ? _vm.categoryCopy.name : _vm.categoryCopy.name_en
                  ),
                },
              }),
            ]),
            _c("availability-tab", {
              attrs: { availability: _vm.categoryCopy.availability },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "tab3",
        fn: function () {
          return [
            _vm.categoryCopy.lines_data
              ? _c("catalog-editor-edit-item-make-lines", {
                  attrs: {
                    props: _vm.propsMakeLines,
                    itemCopy: _vm.categoryCopy,
                  },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "tab4",
        fn: function () {
          return [
            _vm.categoryCopy.kds_settings !== null
              ? _c("kds", {
                  attrs: { "kds-settings": _vm.categoryCopy.kds_settings },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "tab5",
        fn: function () {
          var _obj, _obj$1, _obj$2
          return [
            _c("div", { staticClass: "form-lang" }, [
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "form-site" }, [
                    _c("div", { staticClass: "form-site__head-wrap" }, [
                      _c("div", {
                        staticClass: "form-site__head d-none d-md-block",
                        domProps: {
                          textContent: _vm._s(
                            _vm.he
                              ? _vm.categoryCopy.name
                              : _vm.categoryCopy.name_en
                          ),
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "form-site__content" }, [
                      _c("div", { staticClass: "form-site__group" }, [
                        _c("div", { staticClass: "form-site__field" }, [
                          _c("div", { staticClass: "form-site__input" }, [
                            _c("div", { staticClass: "edit-img" }, [
                              _c("div", {
                                staticClass: "edit-img__label",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Image")),
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "edit-img__content",
                                  class:
                                    ((_obj = {}),
                                    (_obj["edit-img__content_empty"] =
                                      !_vm.image),
                                    _obj),
                                  style:
                                    "background-image: url(" + _vm.image + ")",
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "edit-img__icon" },
                                    [
                                      _vm.image
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "edit-img__icon",
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.removeImage(
                                                    "image"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  name: "ic-plus",
                                                  width: "22",
                                                  height: "22",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : [
                                            _c("input", {
                                              attrs: {
                                                type: "file",
                                                name: "image",
                                              },
                                              on: { change: _vm.enterImage },
                                            }),
                                            _c("svg-icon", {
                                              attrs: {
                                                name: "ic-photo",
                                                width: "22",
                                                height: "22",
                                              },
                                            }),
                                          ],
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-site__field" }, [
                          _c("div", { staticClass: "form-site__input" }, [
                            _c("div", { staticClass: "edit-img" }, [
                              _c("div", {
                                staticClass: "edit-img__label",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Icon")),
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "edit-img__content",
                                  class:
                                    ((_obj$1 = {}),
                                    (_obj$1["edit-img__content_empty"] =
                                      !_vm.icon),
                                    _obj$1),
                                  style:
                                    "background-image: url(" + _vm.icon + ")",
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "edit-img__icon" },
                                    [
                                      _vm.icon
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "edit-img__icon",
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.removeImage("icon")
                                                },
                                              },
                                            },
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  name: "ic-plus",
                                                  width: "22",
                                                  height: "22",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : [
                                            _c("input", {
                                              attrs: {
                                                type: "file",
                                                name: "icon",
                                              },
                                              on: { change: _vm.enterImage },
                                            }),
                                            _c("svg-icon", {
                                              attrs: {
                                                name: "ic-photo",
                                                width: "22",
                                                height: "22",
                                              },
                                            }),
                                          ],
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-site__group" }, [
                        _c("div", { staticClass: "form-site__field" }, [
                          _c("div", { staticClass: "form-site__input" }, [
                            _c("div", { staticClass: "edit-img" }, [
                              _c("div", {
                                staticClass: "edit-img__label",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Banner")),
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "edit-img__content",
                                  class:
                                    ((_obj$2 = {}),
                                    (_obj$2["edit-img__content_empty"] =
                                      !_vm.banner),
                                    _obj$2),
                                  style:
                                    "background-image: url(" + _vm.banner + ")",
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "edit-img__icon" },
                                    [
                                      _vm.banner
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "edit-img__icon",
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.removeImage(
                                                    "banner"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  name: "ic-plus",
                                                  width: "22",
                                                  height: "22",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : [
                                            _c("input", {
                                              attrs: {
                                                type: "file",
                                                name: "banner",
                                              },
                                              on: { change: _vm.enterImage },
                                            }),
                                            _c("svg-icon", {
                                              attrs: {
                                                name: "ic-photo",
                                                width: "22",
                                                height: "22",
                                              },
                                            }),
                                          ],
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-site__field" }, [
                          _c("div", { staticClass: "form-site__label" }, [
                            _vm._v(
                              _vm._s(_vm.$t("item to open on banner click"))
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "form-site__input" },
                            [
                              _c("select-box", {
                                attrs: {
                                  options: _vm.itemsOptions,
                                  autocomplete: true,
                                  "empty-option": _vm.$t("Select"),
                                },
                                model: {
                                  value: _vm.selectedItemId,
                                  callback: function ($$v) {
                                    _vm.selectedItemId = $$v
                                  },
                                  expression: "selectedItemId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "btns",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass:
                  "form-site__btn form-site__btn_sb d-none d-md-flex",
              },
              [
                _vm.categoryCopy.id
                  ? _c("remove-btn", { on: { click: _vm.remove } })
                  : _c("div"),
                _c("button", { staticClass: "btn", on: { click: _vm.save } }, [
                  _c("span", {
                    domProps: { textContent: _vm._s(_vm.$t("save")) },
                  }),
                ]),
              ],
              1
            ),
            _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
              _c(
                "button",
                { staticClass: "save-btn", on: { click: _vm.save } },
                [
                  _c("svg-icon", {
                    staticClass: "ic-edit",
                    attrs: { name: "ic-save-btn", width: "112", height: "112" },
                  }),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }