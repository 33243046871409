var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "filter-by flex a-center bet",
      class: { focus: _vm.isFocus },
      on: {
        click: function ($event) {
          return _vm.$refs["search-input"].focus()
        },
      },
    },
    [
      _c(
        "section",
        { staticClass: "input-container" },
        [
          _c("svg-icon", {
            staticClass: "m-i-end-1ch md-inline ic-stroke",
            style: { opacity: _vm.opacity },
            attrs: { name: "ic-search", width: "16", height: "16" },
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.newFilterBy.term,
                expression: "newFilterBy.term",
              },
            ],
            ref: "search-input",
            staticClass: "md-inline",
            style: _vm.styles,
            attrs: { type: "search", placeholder: _vm.$t("Search...") },
            domProps: { value: _vm.newFilterBy.term },
            on: {
              focus: function ($event) {
                _vm.isFocus = true
              },
              blur: function ($event) {
                _vm.isFocus = false
              },
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.newFilterBy, "term", $event.target.value)
                },
                _vm.onInput,
              ],
            },
          }),
        ],
        1
      ),
      _vm._t("default"),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.newFilterBy.term
            ? _c("svg-icon", {
                staticClass: "pointer",
                attrs: { name: "ic-close", width: "13", height: "13" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.clearFilter.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }