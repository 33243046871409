var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "map-orders" },
    [
      _c(
        "section",
        { staticClass: "container" },
        [
          _c(
            "div",
            { staticClass: "flex row m0" },
            [
              _c("filter-by-date", {
                model: {
                  value: _vm.dateFilter,
                  callback: function ($$v) {
                    _vm.dateFilter = $$v
                  },
                  expression: "dateFilter",
                },
              }),
            ],
            1
          ),
          _vm.deliveryGuysCordinates.length > 0
            ? _c("here-map", {
                staticClass: "map",
                attrs: {
                  coordinatesHome: _vm.coordinatesHome,
                  cordinates: _vm.coordinates,
                  deliveryGuysCordinates: _vm.deliveryGuysCordinates,
                  selectedOrders: _vm.selectedOrders,
                },
                on: { selectOrders: _vm.selectOrders },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.selectedOrders.length
        ? _c(
            "button",
            {
              staticClass: "ready-to-pickup",
              on: { click: _vm.readyToPickup },
            },
            [
              _vm._m(0),
              _c("span", {
                domProps: { textContent: _vm._s(_vm.$t("Ready")) },
              }),
            ]
          )
        : _vm._e(),
      _vm.setDelivery
        ? _c("choose-driver", { on: { input: _vm.setDriver } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/svg/ready-to-pickup.svg") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }