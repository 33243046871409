var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "survey-details" },
    [
      _c("div", { staticClass: "header-back d-flex d-md-none" }, [
        _vm._v("\n    }\n    "),
        _c(
          "button",
          {
            staticClass: "header__back",
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "header-company" },
              [
                _c("svg-icon", {
                  staticClass: "ic-white",
                  attrs: { name: "ic-arrow-left", width: "16", height: "12" },
                }),
                _c("span", {
                  domProps: { textContent: _vm._s(_vm.$t("Back")) },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "table-list",
        _vm._b(
          {
            on: {
              "on-click-line": _vm.editItem,
              changeOptions: _vm.changeOptions,
            },
            scopedSlots: _vm._u([
              {
                key: "btn",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "filter-wrap gap6",
                        style: {
                          display: "flex",
                          width: "100%",
                          "justify-content": "center",
                        },
                      },
                      [
                        !_vm.mobile
                          ? _c("h1", {
                              domProps: {
                                textContent: _vm._s(_vm.survey.title),
                              },
                              on: { click: _vm.editSurvey },
                            })
                          : _vm._e(),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "btn-back",
                fn: function () {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "page__back",
                        on: {
                          click: function ($event) {
                            return _vm.$emit("close")
                          },
                        },
                      },
                      [
                        _c("svg-icon", {
                          attrs: {
                            name: "ic-close-table-arrow",
                            width: "34",
                            height: "34",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "filter-icon",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "flex a-center f-wrap" },
                      [
                        _c("select-box", {
                          staticClass: "mie12 mb8 md-none",
                          attrs: {
                            maxWidth: "200px",
                            unsort: "",
                            options: [
                              "Waiting",
                              "Sent",
                              "_Opened",
                              "Answered",
                            ].map(function (label, value) {
                              return {
                                label: _vm.$t(label),
                                value: value,
                              }
                            }),
                          },
                          model: {
                            value: _vm.statusFilter,
                            callback: function ($$v) {
                              _vm.statusFilter = $$v
                            },
                            expression: "statusFilter",
                          },
                        }),
                        _c("filter-by-date", {
                          staticClass: "mb12",
                          model: {
                            value: _vm.dateFilter,
                            callback: function ($$v) {
                              _vm.dateFilter = $$v
                            },
                            expression: "dateFilter",
                          },
                        }),
                        _c(
                          "button",
                          {
                            staticClass: "edit-btn-duplicate mb12",
                            on: {
                              click: function ($event) {
                                return _vm.$emit("duplicate", _vm.surveyCopy)
                              },
                            },
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-document",
                                width: "20",
                                height: "20",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.checkPermissionPath("surveys.delete")
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "page-details__action d-none d-md-inline-flex mie16 mb12",
                              },
                              [
                                _c("bin-btn", {
                                  attrs: { size: 42 },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("remove")
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("swich-button", {
                          attrs: {
                            value: _vm.surveyCopy
                              ? _vm.surveyCopy.active
                              : true,
                          },
                          on: { input: _vm.setActiveSurvey },
                        }),
                        _c("select-box", {
                          staticClass: "mis12 mb8 d-md-none",
                          attrs: {
                            widthHeader: "100%",
                            maxWidth: "120px",
                            unsort: "",
                            options: [
                              "Waiting",
                              "Sent",
                              "_Opened",
                              "Answered",
                            ].map(function (label, value) {
                              return {
                                label: _vm.$t(label),
                                value: value,
                              }
                            }),
                          },
                          model: {
                            value: _vm.statusFilter,
                            callback: function ($$v) {
                              _vm.statusFilter = $$v
                            },
                            expression: "statusFilter",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "above-tabel",
                fn: function () {
                  return [
                    _vm.mobile
                      ? _c("h1", {
                          domProps: { textContent: _vm._s(_vm.survey.title) },
                          on: { click: _vm.editSurvey },
                        })
                      : _vm._e(),
                    _c("div", {
                      staticClass: "page-det-company__desc",
                      domProps: {
                        textContent: _vm._s(
                          _vm.surveyCopy ? _vm.surveyCopy.description : ""
                        ),
                      },
                    }),
                    _vm.surveyCopy
                      ? _c(
                          "div",
                          { staticClass: "questions-list" },
                          [
                            _c(
                              "div",
                              { staticClass: "graph-score" },
                              [
                                _c("div", {
                                  staticClass: "graph-score__text",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("Overall score")
                                    ),
                                  },
                                }),
                                _c("score", {
                                  attrs: { score: _vm.surveyCopy.total_score },
                                }),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.surveyCopy.questions.filter(function (
                                question
                              ) {
                                return question.type !== 2
                              }),
                              function (question) {
                                return [
                                  question.type
                                    ? _c(
                                        "div",
                                        {
                                          key: question.id,
                                          staticClass: "questions-item",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "questions-item__top",
                                            },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "questions-item__title",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    question.question
                                                  ),
                                                },
                                              }),
                                              _c("div", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    question.weight + "%"
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                          question.answers.length
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "questions-item__content",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "questions-item__info",
                                                    },
                                                    [
                                                      _vm.segments.find(
                                                        function (s) {
                                                          return (
                                                            s.id ===
                                                            question.segment_id
                                                          )
                                                        }
                                                      )
                                                        ? _c("div", {
                                                            staticClass:
                                                              "questions-item__type",
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  _vm.segments.find(
                                                                    function (
                                                                      s
                                                                    ) {
                                                                      return (
                                                                        s.id ===
                                                                        question.segment_id
                                                                      )
                                                                    }
                                                                  ).name
                                                                ),
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _c("score-cell", {
                                                        attrs: {
                                                          data: {
                                                            score:
                                                              question.score,
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _c(
                                        "button",
                                        {
                                          key: question.id,
                                          staticClass: "questions-item",
                                          on: {
                                            click: function ($event) {
                                              return _vm.openGraph(question)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "questions-item__top",
                                            },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "questions-item__title",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    question.question
                                                  ),
                                                },
                                              }),
                                              _c("div", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    question.weight + "%"
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                          question.answers.length
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "questions-item__content",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "questions-item__info",
                                                    },
                                                    [
                                                      _vm.segments.find(
                                                        function (s) {
                                                          return (
                                                            s.id ===
                                                            question.segment_id
                                                          )
                                                        }
                                                      )
                                                        ? _c("div", {
                                                            staticClass:
                                                              "questions-item__type",
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  _vm.segments.find(
                                                                    function (
                                                                      s
                                                                    ) {
                                                                      return (
                                                                        s.id ===
                                                                        question.segment_id
                                                                      )
                                                                    }
                                                                  ).name
                                                                ),
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _c("score-cell", {
                                                        attrs: {
                                                          data: {
                                                            score:
                                                              question.score,
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                ]
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "table-list",
          _vm.dataTable,
          false
        )
      ),
      _vm.popup === 1
        ? _c("survey-answer-sidebar", {
            attrs: { answeredSurvey: _vm.openedAnswer },
            on: { openOrder: _vm.openOrder, close: _vm.closeSidebar },
          })
        : _vm._e(),
      !!_vm.currentOrder
        ? _c("order-details-popup", {
            attrs: { order: _vm.currentOrder, show: !!_vm.currentOrder },
            on: {
              closed: function ($event) {
                _vm.currentOrder = null
              },
            },
          })
        : _vm._e(),
      _vm.popup === 2
        ? _c("edit-survey-dialog", {
            attrs: { surveyInEdit: _vm.surveyCopy },
            on: { save: _vm.saveSurvey },
          })
        : _vm._e(),
      _vm.popup === 3
        ? _c("survey-answer-graph", {
            attrs: { question: _vm.questionInGraph },
            on: {
              close: function ($event) {
                _vm.popup = 0
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }