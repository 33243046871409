var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.close,
          expression: "close",
        },
      ],
      staticClass: "drop-color",
      class: _vm.dropClasses,
    },
    [
      _c(
        "div",
        { staticClass: "drop-color__head", on: { click: _vm.toggle } },
        [
          _c("span", { domProps: { textContent: _vm._s(_vm.valueLabel) } }),
          _c(
            "div",
            { staticClass: "drop-color__arr" },
            [
              _c("svg-icon", {
                attrs: { name: "ic-arrow-down", width: "12", height: "8" },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "drop-color__list" },
        _vm._l(_vm.options, function (option, index) {
          return _c("button", {
            key: index,
            staticClass: "drop-color__item",
            domProps: { textContent: _vm._s(option.label || option) },
            on: {
              click: function ($event) {
                return _vm.select(option)
              },
            },
          })
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }