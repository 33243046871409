var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "branches full-screen" },
    [
      _c("section", { staticClass: "container" }, [
        _c("div", { staticClass: "page" }, [
          _c(
            "div",
            { staticClass: "page__main" },
            [
              _c(
                "router-link",
                { staticClass: "page__back", attrs: { to: "../settings" } },
                [
                  _c("svg-icon", {
                    attrs: {
                      name: "ic-close-table-arrow",
                      width: "34",
                      height: "34",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "branches-head" },
                [
                  _c("h1", {
                    staticClass: "mb2rem",
                    domProps: {
                      textContent: _vm._s(_vm.$t("Branches groups")),
                    },
                  }),
                  _vm.mobile
                    ? [
                        _c(
                          "button",
                          {
                            staticClass: "mobile-btn-plus",
                            on: {
                              click: function ($event) {
                                _vm.editBranchGroup(_vm.newBranchGroup())
                              },
                            },
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-plus",
                                width: "16",
                                height: "16",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn_pink",
                            on: {
                              click: function ($event) {
                                _vm.editBranchGroup(_vm.newBranchGroup())
                              },
                            },
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-plus",
                                width: "16",
                                height: "16",
                              },
                            }),
                            _c("span", {
                              staticClass: "d-md-block d-none",
                              domProps: {
                                textContent: _vm._s(_vm.$t("New branch group")),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "branch-edit-wrap" },
                _vm._l(_vm.branchesGroups, function (branchGroup) {
                  return _c(
                    "div",
                    { key: branchGroup.id, staticClass: "branch-edit" },
                    [
                      _c("div", { staticClass: "details-branch-groups-row" }, [
                        _c(
                          "div",
                          {
                            staticStyle: { width: "100%", height: "45px" },
                            on: {
                              click: function ($event) {
                                return _vm.editBranchGroup(branchGroup)
                              },
                            },
                          },
                          [
                            _c("div", {
                              staticClass: "branch-edit__title",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.he
                                    ? branchGroup.name
                                    : branchGroup.name_en
                                ),
                              },
                            }),
                          ]
                        ),
                        _c("div", { staticClass: "btn-row" }, [
                          _c("div", { staticClass: "flex" }),
                          _c(
                            "button",
                            {
                              staticClass: "bin-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.removeGroup(branchGroup)
                                },
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-bin",
                                  width: "18",
                                  height: "20",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm.showBranchGroupDialog
        ? _c("edit-branch-group", {
            attrs: { branchInEdit: _vm.selectedBranchGroup },
            on: { save: _vm.save, close: _vm.close },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }