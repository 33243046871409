import { render, staticRenderFns } from "./catalog-editor-add-extra.vue?vue&type=template&id=7548a3f4&scoped=true&"
import script from "./catalog-editor-add-extra.vue?vue&type=script&lang=ts&"
export * from "./catalog-editor-add-extra.vue?vue&type=script&lang=ts&"
import style0 from "./catalog-editor-add-extra.vue?vue&type=style&index=0&id=7548a3f4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7548a3f4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/dorshoshan/Documents/DEV/Atmos-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7548a3f4')) {
      api.createRecord('7548a3f4', component.options)
    } else {
      api.reload('7548a3f4', component.options)
    }
    module.hot.accept("./catalog-editor-add-extra.vue?vue&type=template&id=7548a3f4&scoped=true&", function () {
      api.rerender('7548a3f4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/apps/catalog-editor/dialogs/catalog-editor-add-extra.vue"
export default component.exports