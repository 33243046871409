var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "barrage_gates full screen" },
    [
      _c(
        "div",
        { staticClass: "zeds" },
        [
          _c(
            "table-list",
            _vm._b(
              {
                on: {
                  changeOptions: _vm.changeOptions,
                  "on-click-line": _vm.openPopup,
                },
                scopedSlots: _vm._u([
                  {
                    key: "btn-back",
                    fn: function () {
                      return [
                        _c(
                          "router-link",
                          {
                            staticClass: "page__back",
                            attrs: { to: "../settings" },
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-close-table-arrow",
                                width: "34",
                                height: "34",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              "table-list",
              _vm.dataTable,
              false
            )
          ),
        ],
        1
      ),
      _vm.popup == 1
        ? _c("editBarrage_gatesDialog", {
            attrs: { barrage: _vm.selectedBarrage },
            on: { close: _vm.close, save: _vm.save },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }