var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "branches-table",
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c("button", { on: { click: _vm.openSelectBranches } }, [
        _c("span", {
          ref: "span",
          staticClass: "admin-table__text",
          class: { inactive: !_vm.isItemActive },
          domProps: { textContent: _vm._s(_vm.text) },
        }),
      ]),
      _vm.isOpen
        ? _c(
            "select-branchs",
            _vm._g(
              {
                attrs: {
                  item: _vm.itemInEdit,
                  "switch-btn": _vm.switchBtn,
                  "branches-data": _vm.branchesData,
                  "branches-key": _vm.branchesKey,
                },
              },
              _vm.emitFunctions
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }