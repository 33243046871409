var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap-map" }, [
    _c("meta", { attrs: { "http-equiv": "X-UA-Compatible", content: "IE=9" } }),
    _c("div", { ref: "hereMap", staticClass: "map", attrs: { dir: "ltr" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }