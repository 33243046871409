var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tag-products-list", class: { active: _vm.show_items_dd } },
    [
      _c(
        "button",
        {
          staticClass: "tag-products-list-toggle",
          attrs: { type: "button" },
          on: { click: _vm.toggleItemsDD },
        },
        [
          _c("svg-icon", {
            attrs: { name: "ic-more-opaque", width: "12", height: "12" },
          }),
        ],
        1
      ),
      _vm.show_items_dd
        ? _c(
            "div",
            { staticClass: "tag-products-list-dd" },
            [
              _vm.loading
                ? _c("v-progress-circular", {
                    attrs: {
                      size: "20",
                      color: "primary",
                      indeterminate: true,
                    },
                  })
                : _vm._e(),
              _vm.extras && _vm.extras.length > 0
                ? _c(
                    "ul",
                    _vm._l(_vm.extras, function (extra, extraIndex) {
                      return _c("li", { key: extraIndex }, [
                        extra && extra.name
                          ? _c("span", [
                              _vm._v(
                                _vm._s(extra.name) +
                                  " (" +
                                  _vm._s(extra.makat) +
                                  ")"
                              ),
                            ])
                          : _vm._e(),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
              _vm.items && _vm.items.length
                ? _c(
                    "ul",
                    _vm._l(_vm.items, function (item, itemIndex) {
                      return _c("li", { key: itemIndex }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(item.name) + " (" + _vm._s(item.makat) + ")"
                          ),
                        ]),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
              (!_vm.items || _vm.items.length === 0) &&
              (!_vm.extras || _vm.extras.length === 0)
                ? _c("p", [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("Items or extras not found")) +
                        "\n    "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }