var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("svg", { staticClass: "icon", class: _vm.name }, [
    _c("use", { attrs: { "xlink:href": _vm.sprite + "#" + _vm.name } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }