var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-survey-dialog" },
    [
      _c("base-dialog-tabs", {
        attrs: {
          tabList: [
            "Questions",
            "% " + _vm.$t("Splits"),
            "Availability",
            "Hooks",
          ],
          width: "1017px",
        },
        on: { close: _vm.close },
        scopedSlots: _vm._u([
          {
            key: "tab0",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "header-back back d-flex d-md-none",
                    style: { "margin-right": "-25px", width: "103vw" },
                  },
                  [
                    _c(
                      "button",
                      { staticClass: "header__back", on: { click: _vm.close } },
                      [
                        _c(
                          "div",
                          { staticClass: "header-company" },
                          [
                            _c("svg-icon", {
                              staticClass: "ic-white",
                              attrs: {
                                name: "ic-arrow-left",
                                width: "16",
                                height: "12",
                              },
                            }),
                            _c("span", {
                              domProps: { textContent: _vm._s(_vm.$t("Back")) },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
                _c("div", {
                  staticClass: "h5",
                  domProps: { textContent: _vm._s(_vm.$t("General")) },
                }),
                _c("div", { staticClass: "survey-general" }, [
                  _c(
                    "div",
                    { staticClass: "survey-general__description form-site" },
                    [
                      _c("div", {
                        staticClass: "survey-general__title",
                        domProps: {
                          textContent: _vm._s(_vm.$t("Description (optional)")),
                        },
                      }),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.editSurvey.description,
                            expression: "editSurvey.description",
                          },
                        ],
                        attrs: {
                          placeholder: _vm.$t("Write your description"),
                        },
                        domProps: { value: _vm.editSurvey.description },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.editSurvey,
                              "description",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                  _c("div", { staticClass: "survey-general__image" }, [
                    _c("div", { staticClass: "survey-general__description" }, [
                      _c("div", {
                        staticClass: "survey-general__title",
                        domProps: {
                          textContent: _vm._s(_vm.$t("Image (optional)")),
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "coupon-info__img",
                          class: { "coupon-info__empty": !_vm.image },
                          style: "background-image: url(" + _vm.image + ")",
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "coupon-info__img-change" },
                            [
                              _vm.image
                                ? _c(
                                    "button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearImage()
                                        },
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          name: "ic-plus",
                                          width: "22",
                                          height: "22",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : [
                                    _c("input", {
                                      attrs: {
                                        type: "file",
                                        accept: "image/*",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.enterImage($event)
                                        },
                                      },
                                    }),
                                    _c("svg-icon", {
                                      attrs: {
                                        name: "ic-plus",
                                        width: "22",
                                        height: "22",
                                      },
                                    }),
                                  ],
                            ],
                            2
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "survey-general__start form-site" },
                    [
                      _c("div", {
                        staticClass: "survey-general__title",
                        domProps: {
                          textContent: _vm._s(_vm.$t("Text to start")),
                        },
                      }),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.editSurvey.start_text,
                            expression: "editSurvey.start_text",
                          },
                        ],
                        attrs: { placeholder: _vm.$t("Write your text") },
                        domProps: { value: _vm.editSurvey.start_text },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.editSurvey,
                              "start_text",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                  _c("div", { staticClass: "survey-general__end form-site" }, [
                    _c("div", {
                      staticClass: "survey-general__title",
                      domProps: { textContent: _vm._s(_vm.$t("Text to end")) },
                    }),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.editSurvey.end_text,
                          expression: "editSurvey.end_text",
                        },
                      ],
                      attrs: { placeholder: _vm.$t("Write your text") },
                      domProps: { value: _vm.editSurvey.end_text },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.editSurvey,
                            "end_text",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", {
                  staticClass: "h5",
                  domProps: { textContent: _vm._s(_vm.$t("Questions")) },
                }),
                _c("div", { staticClass: "test" }),
                _c(
                  "draggable",
                  _vm._b(
                    {
                      staticClass: "question-list",
                      model: {
                        value: _vm.editSurvey.questions,
                        callback: function ($$v) {
                          _vm.$set(_vm.editSurvey, "questions", $$v)
                        },
                        expression: "editSurvey.questions",
                      },
                    },
                    "draggable",
                    _vm.darggableQuestionBind,
                    false
                  ),
                  _vm._l(_vm.editSurvey.questions, function (question, i) {
                    return _c(
                      "div",
                      {
                        key: i,
                        staticClass: "question",
                        class: { activesub: i === _vm.openedQuestion },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "question__open-btn",
                            on: {
                              click: function ($event) {
                                return _vm.changeOpenedQuestion(i)
                              },
                            },
                          },
                          [
                            _c("svg-icon", {
                              class: { activesub: i === _vm.openedQuestion },
                              attrs: {
                                name: "ic-arrow-down",
                                width: "18",
                                height: "10",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "form-site" }, [
                          _c("div", { staticClass: "question__main" }, [
                            _c(
                              "div",
                              { staticClass: "form-site__content" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "form-site__group" },
                                  [
                                    _c("form-site-field-text", {
                                      attrs: {
                                        title:
                                          _vm.$t("Question") + " " + (i + 1),
                                      },
                                      model: {
                                        value: question.question,
                                        callback: function ($$v) {
                                          _vm.$set(question, "question", $$v)
                                        },
                                        expression: "question.question",
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "members-club__field radio mt0",
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "form-site__label mb16",
                                          domProps: {
                                            textContent: _vm._s(_vm.$t("Type")),
                                          },
                                        }),
                                        _c(
                                          "form",
                                          {
                                            staticClass:
                                              "members-club__radio m0",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "input-radio" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: question.type,
                                                      expression:
                                                        "question.type",
                                                    },
                                                  ],
                                                  attrs: {
                                                    disabled: _vm.unEdit,
                                                    id: "radioQ" + i + "2",
                                                    type: "radio",
                                                    name: "radio",
                                                  },
                                                  domProps: {
                                                    value: 1,
                                                    checked: _vm._q(
                                                      question.type,
                                                      1
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.$set(
                                                        question,
                                                        "type",
                                                        1
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "input-radio__label",
                                                    attrs: {
                                                      for: "radioQ" + i + "2",
                                                    },
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass:
                                                        "input-radio__mark",
                                                    }),
                                                    _c("span", {
                                                      staticClass:
                                                        "input-radio__text",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t("Rate")
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "input-radio" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: question.type,
                                                      expression:
                                                        "question.type",
                                                    },
                                                  ],
                                                  attrs: {
                                                    disabled: _vm.unEdit,
                                                    id: "radioQ" + i + "1",
                                                    type: "radio",
                                                    name: "radio",
                                                  },
                                                  domProps: {
                                                    value: 0,
                                                    checked: _vm._q(
                                                      question.type,
                                                      0
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.$set(
                                                        question,
                                                        "type",
                                                        0
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "input-radio__label",
                                                    attrs: {
                                                      for: "radioQ" + i + "1",
                                                    },
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass:
                                                        "input-radio__mark",
                                                    }),
                                                    _c("span", {
                                                      staticClass:
                                                        "input-radio__text",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t("Answers")
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "input-radio" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: question.type,
                                                      expression:
                                                        "question.type",
                                                    },
                                                  ],
                                                  attrs: {
                                                    disabled: _vm.unEdit,
                                                    id: "radioQ" + i + "3",
                                                    type: "radio",
                                                    name: "radio",
                                                  },
                                                  domProps: {
                                                    value: 2,
                                                    checked: _vm._q(
                                                      question.type,
                                                      2
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.$set(
                                                        question,
                                                        "type",
                                                        2
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "input-radio__label",
                                                    attrs: {
                                                      for: "radioQ" + i + "3",
                                                    },
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass:
                                                        "input-radio__mark",
                                                    }),
                                                    _c("span", {
                                                      staticClass:
                                                        "input-radio__text",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t(
                                                            "Open question"
                                                          )
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                i === _vm.openedQuestion
                                  ? [
                                      _c(
                                        "div",
                                        { staticClass: "form-site__group" },
                                        [
                                          _c("form-site-field-select-box", {
                                            attrs: {
                                              isDisabled: _vm.unEdit,
                                              title: "Segment",
                                              options: _vm.segmentOpts,
                                            },
                                            model: {
                                              value: question.segment_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  question,
                                                  "segment_id",
                                                  $$v
                                                )
                                              },
                                              expression: "question.segment_id",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: question.type === 0,
                                              expression: "question.type === 0",
                                            },
                                          ],
                                          staticClass: "question__answer-list",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "question__answer-list_title",
                                            },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "question__answer-list_title-text",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t("Answers")
                                                  ),
                                                },
                                              }),
                                              _c("div", {
                                                staticClass:
                                                  "question__answer-list_title-percent",
                                                domProps: {
                                                  textContent: _vm._s("%"),
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "draggable",
                                            {
                                              attrs: {
                                                handle: ".draggable-handle2",
                                              },
                                              model: {
                                                value: question.answers,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    question,
                                                    "answers",
                                                    $$v
                                                  )
                                                },
                                                expression: "question.answers",
                                              },
                                            },
                                            _vm._l(
                                              question.answers,
                                              function (answer, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "question__answer-list_answer",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "question__answer-list_answer-text",
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                answer.text,
                                                              expression:
                                                                "answer.text",
                                                            },
                                                          ],
                                                          ref: "answerText",
                                                          refInFor: true,
                                                          attrs: {
                                                            placeholder:
                                                              _vm.$t(
                                                                "Variant"
                                                              ) +
                                                              " " +
                                                              (index + 1),
                                                            type: "text",
                                                          },
                                                          domProps: {
                                                            value: answer.text,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.$set(
                                                                answer,
                                                                "text",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: answer.weight,
                                                          expression:
                                                            "answer.weight",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "question__answer-list_answer-percent",
                                                      attrs: {
                                                        disabled: _vm.unEdit,
                                                        type: "text",
                                                      },
                                                      domProps: {
                                                        value: answer.weight,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            answer,
                                                            "weight",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: i === _vm.openedQuestion,
                                  expression: "i === openedQuestion",
                                },
                              ],
                              staticClass: "question__btm",
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "question__btm_required" },
                                [
                                  _c("span", {
                                    staticClass: "mie12",
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Required")),
                                    },
                                  }),
                                  _c("swich-button", {
                                    model: {
                                      value: question.required,
                                      callback: function ($$v) {
                                        _vm.$set(question, "required", $$v)
                                      },
                                      expression: "question.required",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "tab1",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "header-back back d-flex d-md-none",
                    style: { "margin-right": "-25px", width: "103vw" },
                  },
                  [
                    _c(
                      "button",
                      { staticClass: "header__back", on: { click: _vm.close } },
                      [
                        _c(
                          "div",
                          { staticClass: "header-company" },
                          [
                            _c("svg-icon", {
                              staticClass: "ic-white",
                              attrs: {
                                name: "ic-arrow-left",
                                width: "16",
                                height: "12",
                              },
                            }),
                            _c("span", {
                              domProps: { textContent: _vm._s(_vm.$t("Back")) },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
                _c("div", {
                  staticClass: "h5",
                  domProps: { textContent: _vm._s(_vm.$t("Questions")) },
                }),
                _c(
                  "div",
                  _vm._b(
                    { staticClass: "question-list mt12" },
                    "div",
                    _vm.darggableQuestionBind,
                    false
                  ),
                  [
                    _vm._l(_vm.questionsFilter, function (question, i) {
                      return [
                        _c(
                          "div",
                          {
                            key: i,
                            staticClass: "split-question",
                            class: { activesub: i === _vm.openedQuestion },
                          },
                          [
                            _c("div", {
                              domProps: {
                                textContent: _vm._s(question.question),
                              },
                            }),
                            _c("div", { staticClass: "members-club__input" }, [
                              _c("div", { staticClass: "input-label" }, [
                                _c(
                                  "div",
                                  { staticClass: "input-label__note" },
                                  [_vm._v("%")]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: question.weight,
                                      expression: "question.weight",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  attrs: {
                                    disabled: !!_vm.splitsEqually || _vm.unEdit,
                                    type: "number",
                                  },
                                  domProps: { value: question.weight },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        question,
                                        "weight",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    }),
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "tab2",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "header-back back d-flex d-md-none",
                    style: { "margin-right": "-25px", width: "103vw" },
                  },
                  [
                    _c(
                      "button",
                      { staticClass: "header__back", on: { click: _vm.close } },
                      [
                        _c(
                          "div",
                          { staticClass: "header-company" },
                          [
                            _c("svg-icon", {
                              staticClass: "ic-white",
                              attrs: {
                                name: "ic-arrow-left",
                                width: "16",
                                height: "12",
                              },
                            }),
                            _c("span", {
                              domProps: { textContent: _vm._s(_vm.$t("Back")) },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
                _c("div", { staticClass: "coupon-info__field" }, [
                  _c("div", {
                    staticClass: "coupon-info__label",
                    domProps: { textContent: _vm._s(_vm.$t("Source")) },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "availability-check-list rp5",
                      style: { width: "100%" },
                    },
                    _vm._l(_vm.sourcesEnumValues, function (key, i) {
                      return _c(
                        "label",
                        {
                          key: _vm.sourceEnumToStr(key),
                          staticClass: "availability-check",
                          class: {
                            active: _vm.hook.sources.includes(
                              _vm.sourceEnumToNumber(key)
                            ),
                          },
                          style: { width: "90%" },
                        },
                        [
                          _c("input", {
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: _vm.hook.sources.includes(
                                _vm.sourceEnumToNumber(key)
                              ),
                            },
                            on: {
                              input: function ($event) {
                                _vm.inputHook(
                                  $event,
                                  "sources",
                                  _vm.hook,
                                  _vm.sourceEnumToNumber(i)
                                )
                              },
                            },
                          }),
                          _c("svg-icon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.hook.sources.includes(
                                  _vm.sourceEnumToNumber(key)
                                ),
                                expression:
                                  "hook.sources.includes(sourceEnumToNumber(key))",
                              },
                            ],
                            attrs: {
                              name: "availability-check-v",
                              width: "16",
                              height: "12",
                            },
                          }),
                          _c("svg-icon", {
                            attrs: {
                              name:
                                "ic-availability-" +
                                _vm.sourceEnumToLowerStr(key),
                              width: "24",
                              height: "24",
                            },
                          }),
                          _c("span", {
                            staticClass: "input-check__text",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(_vm.sourceEnumToStr(key))
                              ),
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c("div", {
                    staticClass: "coupon-info__label",
                    domProps: { textContent: _vm._s(_vm.$t("Method")) },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "availability-check-list rp4",
                      style: { width: "100%" },
                    },
                    _vm._l(_vm.typesEnumValues, function (key, i) {
                      return _c(
                        "label",
                        {
                          key: _vm.typeEnumToStr(key),
                          staticClass: "availability-check",
                          class: {
                            active: _vm.hook.types.includes(
                              _vm.typeEnumToNumber(key)
                            ),
                          },
                          style: { width: "100%" },
                        },
                        [
                          _c("input", {
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: _vm.hook.types.includes(
                                _vm.typeEnumToNumber(key)
                              ),
                            },
                            on: {
                              input: function ($event) {
                                _vm.inputHook(
                                  $event,
                                  "types",
                                  _vm.hook,
                                  _vm.typeEnumToNumber(key)
                                )
                              },
                            },
                          }),
                          _c("svg-icon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.hook.types.includes(
                                  _vm.typeEnumToNumber(key)
                                ),
                                expression:
                                  "hook.types.includes(typeEnumToNumber(key))",
                              },
                            ],
                            attrs: {
                              name: "availability-check-v",
                              width: "16",
                              height: "12",
                            },
                          }),
                          _c("svg-icon", {
                            attrs: {
                              name:
                                "ic-availability-" +
                                _vm.typeEnumToLowerStr(key),
                              width: "30",
                              height: "25",
                            },
                          }),
                          _c("span", {
                            staticClass: "input-check__text",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(_vm.typeEnumToStr(key))
                              ),
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]),
                _c("branches-tab", { attrs: { availability: _vm.editSurvey } }),
              ]
            },
            proxy: true,
          },
          {
            key: "tab3",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "header-back back d-flex d-md-none",
                    style: { "margin-right": "-25px", width: "103vw" },
                  },
                  [
                    _c(
                      "button",
                      { staticClass: "header__back", on: { click: _vm.close } },
                      [
                        _c(
                          "div",
                          { staticClass: "header-company" },
                          [
                            _c("svg-icon", {
                              staticClass: "ic-white",
                              attrs: {
                                name: "ic-arrow-left",
                                width: "16",
                                height: "12",
                              },
                            }),
                            _c("span", {
                              domProps: { textContent: _vm._s(_vm.$t("Back")) },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "hook-list form-site" },
                  _vm._l(_vm.editSurvey.hooks, function (hook, i) {
                    return _c(
                      "div",
                      {
                        key: i,
                        staticClass: "hook",
                        class: { activesub: i === _vm.openedHook },
                      },
                      [
                        _c("div", { staticClass: "hook__main" }, [
                          _c(
                            "div",
                            { staticClass: "form-site__content" },
                            [
                              _c(
                                "div",
                                { staticClass: "form-site__group" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "members-club__field radio mt0",
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "form-site__label mb16",
                                        domProps: {
                                          textContent: _vm._s(_vm.$t("Target")),
                                        },
                                      }),
                                      _c(
                                        "form",
                                        {
                                          staticClass: "members-club__radio m0",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "input-radio" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: hook.target,
                                                    expression: "hook.target",
                                                  },
                                                ],
                                                attrs: {
                                                  id: "radioT" + i + "1",
                                                  type: "radio",
                                                  name: "radio",
                                                },
                                                domProps: {
                                                  value: 0,
                                                  checked: _vm._q(
                                                    hook.target,
                                                    0
                                                  ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.$set(
                                                      hook,
                                                      "target",
                                                      0
                                                    )
                                                  },
                                                },
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "input-radio__label",
                                                  attrs: {
                                                    for: "radioT" + i + "1",
                                                  },
                                                },
                                                [
                                                  _c("span", {
                                                    staticClass:
                                                      "input-radio__mark",
                                                  }),
                                                  _c("span", {
                                                    staticClass:
                                                      "input-radio__text",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t("Client")
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "input-radio" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: hook.target,
                                                    expression: "hook.target",
                                                  },
                                                ],
                                                attrs: {
                                                  id: "radioT" + i + "2",
                                                  type: "radio",
                                                  name: "radio",
                                                },
                                                domProps: {
                                                  value: 1,
                                                  checked: _vm._q(
                                                    hook.target,
                                                    1
                                                  ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.$set(
                                                      hook,
                                                      "target",
                                                      1
                                                    )
                                                  },
                                                },
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "input-radio__label",
                                                  attrs: {
                                                    for: "radioT" + i + "2",
                                                  },
                                                },
                                                [
                                                  _c("span", {
                                                    staticClass:
                                                      "input-radio__mark",
                                                  }),
                                                  _c("span", {
                                                    staticClass:
                                                      "input-radio__text",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t("User")
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  i === _vm.openedHook
                                    ? [
                                        hook.target
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "members-club__field radio mt0",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "form-site__label mb16",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t("Score")
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "form",
                                                  {
                                                    staticClass:
                                                      "members-club__radio m0",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "input-radio",
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                hook.score_trigger,
                                                              expression:
                                                                "hook.score_trigger",
                                                            },
                                                          ],
                                                          attrs: {
                                                            id:
                                                              "radioS" +
                                                              i +
                                                              "1",
                                                            type: "radio",
                                                            name: "radio",
                                                          },
                                                          domProps: {
                                                            value: 0,
                                                            checked: _vm._q(
                                                              hook.score_trigger,
                                                              0
                                                            ),
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                hook,
                                                                "score_trigger",
                                                                0
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "input-radio__label",
                                                            attrs: {
                                                              for:
                                                                "radioS" +
                                                                i +
                                                                "1",
                                                            },
                                                          },
                                                          [
                                                            _c("span", {
                                                              staticClass:
                                                                "input-radio__mark",
                                                            }),
                                                            _c("span", {
                                                              staticClass:
                                                                "input-radio__text",
                                                              domProps: {
                                                                textContent:
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "Below"
                                                                    )
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "input-radio",
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                hook.score_trigger,
                                                              expression:
                                                                "hook.score_trigger",
                                                            },
                                                          ],
                                                          attrs: {
                                                            id:
                                                              "radioS" +
                                                              i +
                                                              "2",
                                                            type: "radio",
                                                            name: "radio",
                                                          },
                                                          domProps: {
                                                            value: 1,
                                                            checked: _vm._q(
                                                              hook.score_trigger,
                                                              1
                                                            ),
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                hook,
                                                                "score_trigger",
                                                                1
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "input-radio__label",
                                                            attrs: {
                                                              for:
                                                                "radioS" +
                                                                i +
                                                                "2",
                                                            },
                                                          },
                                                          [
                                                            _c("span", {
                                                              staticClass:
                                                                "input-radio__mark",
                                                            }),
                                                            _c("span", {
                                                              staticClass:
                                                                "input-radio__text",
                                                              domProps: {
                                                                textContent:
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "Above"
                                                                    )
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        hook.target
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-site__field flex a-center",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass: "mie16",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      hook.phones.length +
                                                        " " +
                                                        _vm.$t("Users")
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-more__content",
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.popup = true
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "btn-more__icon",
                                                      },
                                                      [
                                                        _c("svg-icon", {
                                                          attrs: {
                                                            name: "ic-plus",
                                                            width: "12",
                                                            height: "12",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        hook.target
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-site__field mt0",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-site__input",
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: hook.score,
                                                          expression:
                                                            "hook.score",
                                                        },
                                                      ],
                                                      attrs: { type: "number" },
                                                      domProps: {
                                                        value: hook.score,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            hook,
                                                            "score",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        !hook.target
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "members-club__field mt0",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "form-site__label",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t("When to send?")
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "members-club__input",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "input-label",
                                                      },
                                                      [
                                                        _c("div", {
                                                          staticClass:
                                                            "input-label__note",
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t("min")
                                                            ),
                                                          },
                                                        }),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value: hook.delay,
                                                              expression:
                                                                "hook.delay",
                                                            },
                                                          ],
                                                          staticClass: "pis48",
                                                          attrs: {
                                                            type: "number",
                                                          },
                                                          domProps: {
                                                            value: hook.delay,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.$set(
                                                                hook,
                                                                "delay",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                              i === _vm.openedHook
                                ? [
                                    !hook.target
                                      ? _c(
                                          "div",
                                          { staticClass: "form-site" },
                                          [
                                            _c("div", {
                                              staticClass: "form-site__label",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t("Message")
                                                ),
                                              },
                                            }),
                                            _c(
                                              "ul",
                                              { staticClass: "flex mb12" },
                                              _vm._l(
                                                [
                                                  "link",
                                                  "user_name",
                                                  "date",
                                                  "restaurant_name",
                                                  "branch_name",
                                                  "order_id",
                                                ],
                                                function (key) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: key,
                                                      staticClass: "mie16",
                                                    },
                                                    [
                                                      _c("button", {
                                                        staticClass:
                                                          "btn-add-text",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t(
                                                              _vm.capitalize(
                                                                key
                                                              )
                                                            )
                                                          ),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.addTextToHook(
                                                              i,
                                                              key,
                                                              hook
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                            _c("textarea", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: hook.message,
                                                  expression: "hook.message",
                                                },
                                              ],
                                              ref: "texthook" + i,
                                              refInFor: true,
                                              staticClass: "input",
                                              attrs: { rows: "10" },
                                              domProps: { value: hook.message },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    hook,
                                                    "message",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "hook__btms" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "hook__open-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.changeOpenedHook(i)
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  class: { activesub: i === _vm.openedHook },
                                  attrs: {
                                    name: "ic-arrow-down",
                                    width: "18",
                                    height: "10",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("bin-btn", {
                              staticClass: "hook__bin",
                              on: {
                                click: function ($event) {
                                  return _vm.rmHook(i)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _c("div", { staticClass: "line-more" }, [
                  _c(
                    "button",
                    {
                      staticClass: "line-more__content",
                      on: { click: _vm.addHook },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "line-more__icon" },
                        [
                          _c("svg-icon", {
                            attrs: {
                              name: "ic-plus",
                              width: "12",
                              height: "12",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", {
                        staticClass: "line-more__text",
                        domProps: { textContent: _vm._s(_vm.$t("Add")) },
                      }),
                    ]
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "btns",
            fn: function () {
              return [
                _c("div", { staticClass: "coupon-info__btn btn-btm" }, [
                  _c("div"),
                  _c(
                    "button",
                    { staticClass: "btn", on: { click: _vm.save } },
                    [
                      _c("span", {
                        domProps: { textContent: _vm._s(_vm.$t("Save")) },
                      }),
                    ]
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "startTab",
            fn: function () {
              return [
                _c("div", { staticClass: "popup__top" }, [
                  _c("div", { staticClass: "popup__head" }, [
                    _c("div", { staticClass: "survey-top" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.editSurvey.title,
                            expression: "editSurvey.title",
                          },
                        ],
                        ref: "surveyTitle",
                        attrs: {
                          placeholder: _vm.$t("Survey Title"),
                          type: "text",
                        },
                        domProps: { value: _vm.editSurvey.title },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.editSurvey,
                              "title",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c(
                        "button",
                        {
                          staticClass: "survey-top__preview md-none",
                          on: {
                            click: function ($event) {
                              _vm.preview = true
                            },
                          },
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "20",
                                height: "12",
                                viewBox: "0 0 20 12",
                                fill: "none",
                              },
                            },
                            [
                              _c("path", {
                                staticClass: "main-fill",
                                attrs: {
                                  d: "M10 0C6.17879 0 2.71351 2.10432 0.15649 5.52231C-0.0521632 5.80233 -0.0521632 6.19355 0.15649 6.47358C2.71351 9.89568 6.17879 12 10 12C13.8212 12 17.2865 9.89568 19.8435 6.47769C20.0522 6.19767 20.0522 5.80645 19.8435 5.52642C17.2865 2.10432 13.8212 0 10 0ZM10.2741 10.2251C7.73755 10.3857 5.64284 8.2814 5.80239 5.72409C5.93331 3.61565 7.63118 1.90666 9.72589 1.77488C12.2625 1.61428 14.3572 3.7186 14.1976 6.27591C14.0626 8.38023 12.3647 10.0892 10.2741 10.2251ZM10.1473 8.27316C8.78081 8.35964 7.65163 7.22718 7.74164 5.85175C7.81119 4.71517 8.72763 3.79684 9.85681 3.72272C11.2233 3.63624 12.3525 4.7687 12.2625 6.14413C12.1888 7.28483 11.2724 8.20316 10.1473 8.27316Z",
                                },
                              }),
                            ]
                          ),
                          _c("span", {
                            staticClass: "mis12",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Preview")),
                            },
                          }),
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "survey-top__preview share",
                          on: { click: _vm.copyPreviewLink },
                        },
                        [
                          _c("svg-icon", {
                            staticClass: "shadow",
                            attrs: {
                              name: "ic-share",
                              width: "24",
                              height: "24",
                            },
                          }),
                          _c("span", {
                            staticClass: "mis12",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Copy link")),
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div"),
                    ]),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.preview
        ? _c("survey-preview", {
            attrs: { survey: _vm.editSurvey },
            on: {
              close: function ($event) {
                _vm.preview = false
              },
            },
          })
        : _vm._e(),
      _vm.popup
        ? _c("edit-phons-dialog", {
            attrs: {
              hook: _vm.editSurvey.hooks[_vm.openedHook],
              surveyBranches: _vm.editSurvey.branches,
            },
            on: {
              close: function ($event) {
                _vm.popup = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }