var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "streets-input" },
    [
      _c(
        "vue-select",
        {
          attrs: {
            label: "name",
            reduce: function (street) {
              return street.name
            },
            filterable: false,
            options: _vm.options,
            dir: _vm.dir,
          },
          on: { search: _vm.onSearch },
          scopedSlots: _vm._u([
            {
              key: "option",
              fn: function (option) {
                return [
                  _c("div", {
                    staticClass: "d-center",
                    domProps: { textContent: _vm._s(option.name) },
                  }),
                ]
              },
            },
            {
              key: "selected-option",
              fn: function (option) {
                return [
                  _c("div", {
                    staticClass: "selected d-center",
                    domProps: { textContent: _vm._s(option.name) },
                  }),
                ]
              },
            },
          ]),
          model: {
            value: _vm.val,
            callback: function ($$v) {
              _vm.val = $$v
            },
            expression: "val",
          },
        },
        [
          _c("template", {
            domProps: { textContent: _vm._s(_vm.$t("Search streets")) },
            slot: "no-options",
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }