var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "toast toast-error toast-missed-call" },
    [
      _c("span", { staticClass: "msg" }, [
        _c("span", {
          domProps: { textContent: _vm._s(_vm.$t("Missed call from")) },
        }),
        _vm.data.caller_name
          ? _c("span", {
              domProps: { textContent: _vm._s(_vm.data.caller_name) },
            })
          : _vm._e(),
        _c("div", {
          staticClass: "phone",
          domProps: { textContent: _vm._s(_vm.data.caller_phone) },
        }),
      ]),
      _c("span", { staticClass: "msg-space" }),
      _vm.data && _vm.data.caller_phone && _vm.extensionConnected
        ? _c("phone-btn", {
            attrs: { data: _vm.data.caller_phone },
            on: { click: _vm.calledBack },
          })
        : _vm._e(),
      _c(
        "button",
        {
          staticClass: "dismiss",
          attrs: { type: "button" },
          on: { click: _vm.dismiss },
        },
        [
          _c(
            "svg",
            {
              attrs: {
                width: "16",
                height: "16",
                viewBox: "0 0 16 16",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M13.6566 2.34343C12.1448 0.83165 10.138 0 8 0C5.86195 0 3.85522 0.83165 2.34343 2.34343C0.83165 3.85522 0 5.86195 0 8C0 10.138 0.83165 12.1448 2.34343 13.6566C3.85522 15.1684 5.86195 16 8 16C10.138 16 12.1448 15.1684 13.6566 13.6566C15.1684 12.1448 16 10.138 16 8C16 5.86195 15.1684 3.85522 13.6566 2.34343ZM13.0135 13.0135C11.6734 14.3535 9.89226 15.0909 8 15.0909C6.10774 15.0909 4.3266 14.3535 2.98653 13.0135C0.222222 10.2492 0.222222 5.75084 2.98653 2.98653C4.3266 1.64646 6.10774 0.909091 8 0.909091C9.89226 0.909091 11.6734 1.64646 13.0135 2.98653C15.7778 5.75084 15.7778 10.2492 13.0135 13.0135Z",
                },
              }),
              _c("path", {
                attrs: {
                  d: "M11.5252 4.4749C11.3467 4.29645 11.0605 4.29645 10.8821 4.4749L7.99991 7.35706L5.11776 4.4749C4.9393 4.29645 4.65311 4.29645 4.47466 4.4749C4.29621 4.65335 4.29621 4.93955 4.47466 5.118L7.35681 8.00016L4.47466 10.8823C4.29621 11.0608 4.29621 11.347 4.47466 11.5254C4.5622 11.613 4.68005 11.6601 4.79452 11.6601C4.909 11.6601 5.02685 11.6163 5.11439 11.5254L7.99654 8.64325L10.8787 11.5254C10.9662 11.613 11.0841 11.6601 11.1986 11.6601C11.3164 11.6601 11.4309 11.6163 11.5184 11.5254C11.6969 11.347 11.6969 11.0608 11.5184 10.8823L8.643 8.00016L11.5252 5.118C11.7036 4.93955 11.7036 4.65335 11.5252 4.4749Z",
                },
              }),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }