var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "new-item-info" }, [
    _c("div", { staticClass: "new-item-info__top" }, [
      _c("div", {
        staticClass: "new-item-info__head",
        domProps: {
          textContent: _vm._s(
            _vm.he ||
              !_vm.currentItem.name_en ||
              _vm.currentItem.name_en === "undefined"
              ? _vm.currentItem.name
              : _vm.currentItem.name_en
          ),
        },
      }),
    ]),
    _vm.currentItem.item_type === 2
      ? _c("div", { staticClass: "item-setting open mb100" }, [
          _c("div", { staticClass: "item-setting__content" }, [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.save.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "form-site" }, [
                  _c(
                    "div",
                    { staticClass: "form-site__content" },
                    [
                      _vm.currentItem.item_type === 2
                        ? _c(
                            "div",
                            {
                              staticClass: "promotion-item",
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("extrasDiscount")
                                },
                              },
                            },
                            [
                              _vm.currentItem.how_many
                                ? _c("div", {
                                    staticClass: "text",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Extras discount - ") +
                                          _vm.currentItem.how_many +
                                          _vm.$t(" for ") +
                                          "-₪" +
                                          _vm.currentItem.how_much
                                      ),
                                    },
                                  })
                                : _c("div", {
                                    staticClass: "text",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Extras discount - click to set")
                                      ),
                                    },
                                  }),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "draggable",
                        _vm._b(
                          {
                            staticClass: "new-group__list combain",
                            on: { end: _vm.moveCombination },
                            model: {
                              value: _vm.stages,
                              callback: function ($$v) {
                                _vm.stages = $$v
                              },
                              expression: "stages",
                            },
                          },
                          "draggable",
                          _vm.draggableBind,
                          false
                        ),
                        _vm._l(_vm.stages, function (stage) {
                          return _c(
                            "div",
                            {
                              key: stage.id,
                              staticClass:
                                "form-site__group border-none darggable",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "combination",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editCombination(stage)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "combination__name" },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.he
                                              ? stage.name
                                              : stage.name_en
                                              ? stage.name_en
                                              : stage.name
                                          ) +
                                          "\n                  "
                                      ),
                                    ]
                                  ),
                                  stage.id
                                    ? _c("div", {
                                        staticClass: "combination__name",
                                        domProps: {
                                          textContent: _vm._s(" - " + stage.id),
                                        },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "flex a-center j-end" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "new-group-res__menu" },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "ic-double-more",
                                              width: "8",
                                              height: "14",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _c("div", { staticClass: "line-more" }, [
                        _c(
                          "button",
                          {
                            staticClass: "line-more__content",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.createCombination.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "line-more__icon" },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    name: "ic-plus",
                                    width: "12",
                                    height: "12",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("span", {
                              staticClass: "line-more__text",
                              domProps: { textContent: _vm._s(_vm.$t("Add")) },
                            }),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }