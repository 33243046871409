var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-category-items-list" },
    [
      !_vm.hideTitle
        ? _c("div", {
            staticClass: "coupon-buy__title",
            domProps: {
              textContent: _vm._s(
                _vm.$t(_vm.categoryonly ? "Categories" : "Categories & items")
              ),
            },
          })
        : _vm._e(),
      !_vm.inEdit
        ? _c(
            "form",
            {
              staticClass: "search-form",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "search-form__field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.search,
                      expression: "search",
                    },
                  ],
                  attrs: { type: "text", placeholder: _vm.$t("Search...") },
                  domProps: { value: _vm.search },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.search = $event.target.value
                    },
                  },
                }),
                _c(
                  "div",
                  { staticClass: "search-form__icon" },
                  [
                    _c("svg-icon", {
                      attrs: { name: "ic-search2", width: "20", height: "20" },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._l(_vm.categoryList, function (category) {
        return _c(
          "select-category-items",
          _vm._b(
            {
              key: category.id,
              attrs: { category: category, value: _vm.categoryValue(category) },
              on: {
                input: function ($event) {
                  return _vm.categoryInput(category, $event)
                },
                selectItem: _vm.selectItem,
                mainCheckbox: _vm.mainCheckbox,
              },
            },
            "select-category-items",
            _vm.selectCategoryItemsBind,
            false
          )
        )
      }),
      _vm.includeTags
        ? _vm._l(_vm.tagList, function (tag) {
            return _c(
              "select-category-items",
              _vm._b(
                {
                  key: tag.name + tag.id,
                  attrs: {
                    isSelected: _vm.tags
                      .map(function (tag) {
                        return tag.id
                      })
                      .includes(tag.id),
                    isTag: "",
                    tag: tag,
                    value: _vm.tagValue(tag.id),
                  },
                  on: {
                    inputMainCheckbox: function ($event) {
                      return _vm.tagInputMain(tag, $event)
                    },
                    input: function ($event) {
                      return _vm.tagInput(tag, $event)
                    },
                    selectItem: _vm.selectItem,
                  },
                },
                "select-category-items",
                _vm.selectCategoryItemsBind,
                false
              )
            )
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }