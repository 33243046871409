var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orders-settings-container full-screen" },
    [
      _c("main", [
        _c(
          "section",
          { staticClass: "container" },
          [
            _vm.popup
              ? _c("backdrop", { on: { emitClose: _vm.closePopup } })
              : _vm._e(),
            _vm.popup === 1
              ? _c(
                  "div",
                  {
                    staticClass: "popup-wrap",
                    class: {
                      times: _vm.futureoderMode === "specific-day-and-time",
                    },
                    staticStyle: { width: "640px" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "popup" },
                      [
                        _c("close-popup-btn", {
                          attrs: {
                            top: "-22px",
                            end:
                              _vm.futureoderMode === "specific-day-and-time"
                                ? ""
                                : "-55px",
                          },
                          on: { close: _vm.closePopup },
                        }),
                        _c("div", { staticClass: "popup__top" }, [
                          _c("div", { staticClass: "popup__head" }, [
                            _c("div", {
                              staticClass: "h1",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("Future order settings")
                                ),
                              },
                            }),
                          ]),
                        ]),
                        _vm.futureOrderSettingsCopy &&
                        _vm.futureoderMode === "specific-day-and-time"
                          ? _c("div", { staticClass: "popup__content" }, [
                              _c(
                                "form",
                                {
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "working-days" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "working-days__all" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "input-check" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "input-check__label",
                                                },
                                                [
                                                  _c("checkbox", {
                                                    attrs: {
                                                      name: "always-available",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm
                                                          .futureOrderSettingsCopy
                                                          .times
                                                          .always_available,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm
                                                            .futureOrderSettingsCopy
                                                            .times,
                                                          "always_available",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "futureOrderSettingsCopy.times.always_available",
                                                    },
                                                  }),
                                                  _c("span", {
                                                    staticClass:
                                                      "input-check__text",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t(
                                                          "Always available"
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._l(_vm.days, function (time) {
                                        return _c("day-working-hours", {
                                          key: time.id,
                                          attrs: {
                                            unerror: "",
                                            always:
                                              _vm.futureOrderSettingsCopy.times
                                                .always_available,
                                            popup: "",
                                            props: time,
                                          },
                                          on: { save: _vm.saveDay },
                                        })
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "working-days__btn" },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn btn_border",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.futureoderMode = "hours"
                                                },
                                              },
                                            },
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  name: "ic-arrow-left",
                                                  width: "16",
                                                  height: "12",
                                                },
                                              }),
                                              _c("span", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t("Back")
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("button", {
                                            staticClass: "btn",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t("save")
                                              ),
                                            },
                                            on: {
                                              click:
                                                _vm.saveFutureOrderSettings,
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ])
                          : _vm.futureOrderSettingsCopy
                          ? _c("div", { staticClass: "popup__content" }, [
                              _c(
                                "form",
                                {
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "working-hours" }, [
                                    _c(
                                      "div",
                                      { staticClass: "working-hours__sect" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "working-hours__row pb12",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "working-hours__item",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "input-check",
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass:
                                                        "input-check__text m0",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t(
                                                            "Future days show"
                                                          )
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "working-hours__item",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "input-check",
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm
                                                              .futureOrderSettingsCopy
                                                              .future_days_show,
                                                          expression:
                                                            "futureOrderSettingsCopy.future_days_show",
                                                        },
                                                      ],
                                                      staticStyle: {
                                                        "text-align": "center",
                                                        width: "60px",
                                                      },
                                                      attrs: { type: "number" },
                                                      domProps: {
                                                        value:
                                                          _vm
                                                            .futureOrderSettingsCopy
                                                            .future_days_show,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            _vm.futureOrderSettingsCopy,
                                                            "future_days_show",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "working-hours__row" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "working-hours__item",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "working-hours__text",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        "Stop getting orders for today at"
                                                      )
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "working-hours__item",
                                              },
                                              [
                                                _c("select-box", {
                                                  attrs: {
                                                    maxWidth: "100%",
                                                    options: _vm.hoursList,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm
                                                        .futureOrderSettingsCopy
                                                        .stop_getting_order,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.futureOrderSettingsCopy,
                                                        "stop_getting_order",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "futureOrderSettingsCopy.stop_getting_order",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "working-hours__sect" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "working-hours__row" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "working-hours__item",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "input-radio",
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm
                                                              .futureOrderSettingsCopy
                                                              .only_days,
                                                          expression:
                                                            "futureOrderSettingsCopy.only_days",
                                                        },
                                                      ],
                                                      attrs: {
                                                        id: "radio1",
                                                        type: "radio",
                                                      },
                                                      domProps: {
                                                        value: false,
                                                        checked: _vm._q(
                                                          _vm
                                                            .futureOrderSettingsCopy
                                                            .only_days,
                                                          false
                                                        ),
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.$set(
                                                            _vm.futureOrderSettingsCopy,
                                                            "only_days",
                                                            false
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "input-radio__label",
                                                        attrs: {
                                                          for: "radio1",
                                                        },
                                                      },
                                                      [
                                                        _c("span", {
                                                          staticClass:
                                                            "input-radio__mark",
                                                        }),
                                                        _c("span", {
                                                          staticClass:
                                                            "input-radio__text",
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "Days & hours"
                                                              )
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "working-hours__item",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "input-radio",
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm
                                                              .futureOrderSettingsCopy
                                                              .only_days,
                                                          expression:
                                                            "futureOrderSettingsCopy.only_days",
                                                        },
                                                      ],
                                                      attrs: {
                                                        id: "radio2",
                                                        type: "radio",
                                                      },
                                                      domProps: {
                                                        value: true,
                                                        checked: _vm._q(
                                                          _vm
                                                            .futureOrderSettingsCopy
                                                            .only_days,
                                                          true
                                                        ),
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.$set(
                                                            _vm.futureOrderSettingsCopy,
                                                            "only_days",
                                                            true
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "input-radio__label",
                                                        attrs: {
                                                          for: "radio2",
                                                        },
                                                      },
                                                      [
                                                        _c("span", {
                                                          staticClass:
                                                            "input-radio__mark",
                                                        }),
                                                        _c("span", {
                                                          staticClass:
                                                            "input-radio__text",
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "Days only"
                                                              )
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "working-hours-dh",
                                            class: {
                                              inactive:
                                                _vm.futureOrderSettingsCopy
                                                  .only_days,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "working-hours-dh__line",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "input-check",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "input-radio",
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.daysHours,
                                                              expression:
                                                                "daysHours",
                                                            },
                                                          ],
                                                          attrs: {
                                                            id: "radio3",
                                                            type: "radio",
                                                          },
                                                          domProps: {
                                                            value: true,
                                                            checked: _vm._q(
                                                              _vm.daysHours,
                                                              true
                                                            ),
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              _vm.daysHours = true
                                                            },
                                                          },
                                                        }),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "input-radio__label",
                                                            attrs: {
                                                              for: "radio3",
                                                            },
                                                          },
                                                          [
                                                            _c("span", {
                                                              staticClass:
                                                                "input-radio__mark",
                                                            }),
                                                            _c("span", {
                                                              staticClass:
                                                                "input-radio__text",
                                                              domProps: {
                                                                textContent:
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "Every day with steps of"
                                                                    )
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .futureOrderSettingsCopy
                                                          .step,
                                                      expression:
                                                        "futureOrderSettingsCopy.step",
                                                    },
                                                  ],
                                                  attrs: { type: "number" },
                                                  domProps: {
                                                    value:
                                                      _vm
                                                        .futureOrderSettingsCopy
                                                        .step,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.futureOrderSettingsCopy,
                                                        "step",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c("div", {
                                                  staticClass:
                                                    "working-hours-dh__text",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t("minutes")
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "working-hours-dh__line",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "input-check",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "input-radio",
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.daysHours,
                                                              expression:
                                                                "daysHours",
                                                            },
                                                          ],
                                                          attrs: {
                                                            id: "radio4",
                                                            type: "radio",
                                                          },
                                                          domProps: {
                                                            value: false,
                                                            checked: _vm._q(
                                                              _vm.daysHours,
                                                              false
                                                            ),
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              _vm.daysHours = false
                                                            },
                                                          },
                                                        }),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "input-radio__label",
                                                            attrs: {
                                                              for: "radio4",
                                                            },
                                                          },
                                                          [
                                                            _c("span", {
                                                              staticClass:
                                                                "input-radio__mark",
                                                            }),
                                                            _c("span", {
                                                              staticClass:
                                                                "input-radio__text",
                                                              domProps: {
                                                                textContent:
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "Specific days and times"
                                                                    )
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c("edit-btn", {
                                                  staticClass: "mis12",
                                                  attrs: { size: 32 },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.futureoderMode =
                                                        "specific-day-and-time"
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "working-hours__sect",
                                        class: {
                                          inactive:
                                            !_vm.futureOrderSettingsCopy
                                              .only_days,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "working-hours__field",
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "working-hours__label",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    "future-order-settings Alert"
                                                  )
                                                ),
                                              },
                                            }),
                                            _c("textarea", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.futureOrderSettingsCopy
                                                      .alert,
                                                  expression:
                                                    "futureOrderSettingsCopy.alert",
                                                },
                                              ],
                                              attrs: {
                                                placeholder: _vm.$t(
                                                  "Describe your delivery/take away conditions in your own way"
                                                ),
                                              },
                                              domProps: {
                                                value:
                                                  _vm.futureOrderSettingsCopy
                                                    .alert,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.futureOrderSettingsCopy,
                                                    "alert",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "working-hours__row" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "working-hours__item",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "working-hours__text",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        "Get orders in POS/FAX every day at"
                                                      )
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "working-hours__item",
                                              },
                                              [
                                                _c("select-box", {
                                                  attrs: {
                                                    openToUp: "",
                                                    maxWidth: "100%",
                                                    options: _vm.hoursList,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm
                                                        .futureOrderSettingsCopy
                                                        .get_order_time,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.futureOrderSettingsCopy,
                                                        "get_order_time",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "futureOrderSettingsCopy.get_order_time",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "working-hours__btn" },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn",
                                            on: {
                                              click:
                                                _vm.saveFutureOrderSettings,
                                            },
                                          },
                                          [
                                            _c("span", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t("save")
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _vm.popup === 2
              ? _c(
                  "div",
                  {
                    staticClass: "popup-wrap",
                    staticStyle: { width: "640px" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "popup" },
                      [
                        _c("close-popup-btn", {
                          attrs: { top: "-22px", end: "-50px" },
                          on: {
                            close: function ($event) {
                              _vm.popup = 0
                            },
                          },
                        }),
                        _c("div", { staticClass: "popup__top" }, [
                          _c("div", { staticClass: "popup__head" }, [
                            _c("div", {
                              staticClass: "h1",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Other payments")),
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "popup__content" }, [
                          _c("div", { staticClass: "other-pay" }, [
                            _c(
                              "div",
                              { staticClass: "other-pay__list" },
                              _vm._l(_vm.paymentMethods, function (key) {
                                return _c(
                                  "div",
                                  { key: key, staticClass: "other-pay__item" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "other-pay__info" },
                                      [
                                        _c("div", {
                                          staticClass: "other-pay__title",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(_vm.capitalize(key))
                                            ),
                                          },
                                        }),
                                        key === "cash"
                                          ? _c(
                                              "div",
                                              { staticClass: "form-site" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.ordersSettingsData[
                                                          _vm.orderSettingskey
                                                        ].max_cash_pay,
                                                      expression:
                                                        "ordersSettingsData[orderSettingskey].max_cash_pay",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "number",
                                                    placeholder:
                                                      _vm.$t(
                                                        "Max cash payment"
                                                      ),
                                                    title:
                                                      _vm.$t(
                                                        "Max cash payment"
                                                      ),
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.ordersSettingsData[
                                                        _vm.orderSettingskey
                                                      ].max_cash_pay,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.ordersSettingsData[
                                                          _vm.orderSettingskey
                                                        ],
                                                        "max_cash_pay",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c("swich-button", {
                                      staticClass: "switch_small",
                                      on: {
                                        input: function ($event) {
                                          return _vm.$store.commit(
                                            "setChange",
                                            true
                                          )
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.ordersSettingsData[
                                            _vm.orderSettingskey
                                          ][key],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ordersSettingsData[
                                              _vm.orderSettingskey
                                            ],
                                            key,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ordersSettingsData[orderSettingskey][key]",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _vm.popup === 3
              ? _c(
                  "div",
                  {
                    staticClass: "popup-wrap",
                    staticStyle: { width: "640px" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "popup" },
                      [
                        _c("close-popup-btn", {
                          attrs: { top: "-22px", end: "-50px" },
                          on: {
                            close: function ($event) {
                              _vm.popup = 0
                            },
                          },
                        }),
                        _c("div", { staticClass: "popup__top" }, [
                          _c("div", { staticClass: "popup__head" }, [
                            _c("div", {
                              staticClass: "h1",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Settings")),
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "popup__content" }, [
                          _c("div", { staticClass: "other-pay" }, [
                            _c("div", { staticClass: "other-pay__list" }, [
                              _c(
                                "div",
                                { staticClass: "orders-settings__item" },
                                [
                                  _c("div", {
                                    staticClass: "form-orders-set__label",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Takeaway ETA")
                                      ),
                                    },
                                  }),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "input-label",
                                      on: {
                                        click: function ($event) {
                                          _vm.numericKeyboardIsOpen = true
                                        },
                                      },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "input-label__note",
                                        domProps: {
                                          textContent: _vm._s(_vm.$t("min")),
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "input",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.ordersSettingsData[
                                              _vm.orderSettingskey
                                            ].time
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "orders-settings__item" },
                                [
                                  _c("div", {
                                    staticClass: "form-orders-set__label",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Order completed alert type")
                                      ),
                                    },
                                  }),
                                  _c("select-box", {
                                    attrs: {
                                      unsort: "",
                                      maxWidth: "150px",
                                      options: _vm.completedAlertOpt,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$store.commit(
                                          "setChange",
                                          true
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.ordersSettingsData[
                                          _vm.orderSettingskey
                                        ].order_completed_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ordersSettingsData[
                                            _vm.orderSettingskey
                                          ],
                                          "order_completed_type",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ordersSettingsData[orderSettingskey].order_completed_type",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "orders-settings__item flex col a-start",
                                },
                                [
                                  _c("div", {
                                    staticClass: "form-orders-set__label",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          _vm.capitalize("order_completed_text")
                                        )
                                      ),
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "input-label form-site w100",
                                    },
                                    [
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.ordersSettingsData[
                                                _vm.orderSettingskey
                                              ].order_completed_text,
                                            expression:
                                              "\n                        ordersSettingsData[orderSettingskey].order_completed_text\n                      ",
                                          },
                                        ],
                                        domProps: {
                                          value:
                                            _vm.ordersSettingsData[
                                              _vm.orderSettingskey
                                            ].order_completed_text,
                                        },
                                        on: {
                                          input: [
                                            function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.ordersSettingsData[
                                                  _vm.orderSettingskey
                                                ],
                                                "order_completed_text",
                                                $event.target.value
                                              )
                                            },
                                            function ($event) {
                                              return _vm.$store.commit(
                                                "setChange",
                                                true
                                              )
                                            },
                                          ],
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "orders-settings__item" },
                                [
                                  _c("div", {
                                    staticClass: "orders-settings__label",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Future order")
                                      ),
                                    },
                                  }),
                                  _c("swich-button", {
                                    on: {
                                      input: function ($event) {
                                        return _vm.$store.commit(
                                          "setChange",
                                          true
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.ordersSettingsData[
                                          _vm.orderSettingskey
                                        ].future,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ordersSettingsData[
                                            _vm.orderSettingskey
                                          ],
                                          "future",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ordersSettingsData[orderSettingskey].future",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "orders-settings__item" },
                                [
                                  _c("div", {
                                    staticClass: "orders-settings__label",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Instant order")
                                      ),
                                    },
                                  }),
                                  _c("swich-button", {
                                    on: {
                                      input: function ($event) {
                                        return _vm.$store.commit(
                                          "setChange",
                                          true
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.ordersSettingsData[
                                          _vm.orderSettingskey
                                        ].instant,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ordersSettingsData[
                                            _vm.orderSettingskey
                                          ],
                                          "instant",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ordersSettingsData[orderSettingskey].instant",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "orders-settings__item" },
                                [
                                  _c("div", {
                                    staticClass: "orders-settings__label",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Require employee selection")
                                      ),
                                    },
                                  }),
                                  _c("swich-button", {
                                    on: {
                                      input: function ($event) {
                                        return _vm.$store.commit(
                                          "setChange",
                                          true
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.ordersSettingsData[
                                          _vm.orderSettingskey
                                        ].require_employee_auth,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ordersSettingsData[
                                            _vm.orderSettingskey
                                          ],
                                          "require_employee_auth",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ordersSettingsData[orderSettingskey].require_employee_auth",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "orders-settings__item" },
                                [
                                  _c("div", {
                                    staticClass: "orders-settings__label",
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Has tip")),
                                    },
                                  }),
                                  _c("swich-button", {
                                    on: {
                                      input: function ($event) {
                                        return _vm.$store.commit(
                                          "setChange",
                                          true
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.ordersSettingsData[
                                          _vm.orderSettingskey
                                        ].has_tip,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ordersSettingsData[
                                            _vm.orderSettingskey
                                          ],
                                          "has_tip",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ordersSettingsData[orderSettingskey].has_tip",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "page" }, [
              _c(
                "div",
                { staticClass: "page__main" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page__back", attrs: { to: "../settings" } },
                    [
                      _c("svg-icon", {
                        attrs: {
                          name: "ic-close-table-arrow",
                          width: "34",
                          height: "34",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "orders-settings-head" },
                    [
                      _c("h1", {
                        staticClass: "mb2rem",
                        domProps: {
                          textContent: _vm._s(_vm.$t("Menus settings")),
                        },
                      }),
                      _c("select-box", {
                        attrs: { options: _vm.menuOptions },
                        model: {
                          value: _vm.selectedMenu,
                          callback: function ($$v) {
                            _vm.selectedMenu = $$v
                          },
                          expression: "selectedMenu",
                        },
                      }),
                      _c(
                        "button",
                        {
                          staticClass: "setting-btn",
                          on: { click: _vm.openModal1 },
                        },
                        [
                          _c("span", {
                            staticClass: "setting-btn__label md-none",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("Future order settings")
                              ),
                            },
                          }),
                          _c(
                            "span",
                            { staticClass: "setting-btn__icon" },
                            [
                              _c("svg-icon", {
                                staticClass: "ic-white",
                                attrs: {
                                  name: "ic-settings",
                                  width: "20",
                                  height: "20",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("section", { staticClass: "orders-settings-wrap" }, [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "form-orders-set" }, [
                          _vm.ordersSettingsData
                            ? _c(
                                "div",
                                { staticClass: "orders-settings-list" },
                                _vm._l(_vm.typesEnumValues, function (k) {
                                  return _c(
                                    "div",
                                    {
                                      key: _vm.typeEnumToLowerStr(k),
                                      staticClass: "orders-settings",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "orders-settings__switch",
                                        },
                                        [
                                          _c("swich-button", {
                                            on: {
                                              input: function ($event) {
                                                return _vm.$store.commit(
                                                  "setChange",
                                                  true
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.ordersSettingsData[
                                                  _vm.typeEnumToLowerStr(k)
                                                ].active,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.ordersSettingsData[
                                                    _vm.typeEnumToLowerStr(k)
                                                  ],
                                                  "active",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "ordersSettingsData[typeEnumToLowerStr(k)].active",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "orders-settings__info",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "orders-settings__img",
                                            },
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  name:
                                                    "ic-availability-" +
                                                    _vm.typeEnumToLowerStr(k),
                                                  width: "52",
                                                  height: "72",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("div", {
                                            staticClass:
                                              "orders-settings__title",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "_" +
                                                    _vm.capitalize(
                                                      _vm.typeEnumToLowerStr(k)
                                                    )
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "orders-settings__params",
                                          class: {
                                            disable:
                                              !_vm.ordersSettingsData[
                                                _vm.typeEnumToLowerStr(k)
                                              ].active,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "orders-settings__item",
                                              on: {
                                                click: function ($event) {
                                                  _vm.orderSettingskey = _vm.key
                                                  _vm.popup = 3
                                                },
                                              },
                                            },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "orders-settings__label",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t("Settings")
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "orders-settings__item",
                                              on: {
                                                click: function ($event) {
                                                  _vm.orderSettingskey = _vm.key
                                                  _vm.popup = 2
                                                },
                                              },
                                            },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "orders-settings__label",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t("Other payments")
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ]),
            _vm.change
              ? _c("div", { staticClass: "btn-save-container-settings" }, [
                  _c(
                    "button",
                    { staticClass: "save-btn", on: { click: _vm.save } },
                    [
                      _c("svg-icon", {
                        staticClass: "ic-edit",
                        attrs: {
                          name: "ic-save-btn",
                          width: "112",
                          height: "128",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.numericKeyboardIsOpen
        ? _c("numeric-keyboard", {
            on: {
              input: function ($event) {
                _vm.numericKeyboardIsOpen = false
                _vm.$store.commit("setChange", true)
              },
            },
            model: {
              value: _vm.ordersSettingsData[_vm.orderSettingskey].time,
              callback: function ($$v) {
                _vm.$set(
                  _vm.ordersSettingsData[_vm.orderSettingskey],
                  "time",
                  $$v
                )
              },
              expression: "ordersSettingsData[orderSettingskey].time",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }