var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-settings-branch" },
    [
      _c("base-dialog", { on: { close: _vm.close } }, [
        _c("div", { staticClass: "popup__top" }, [
          _c("div", { staticClass: "popup__head md-none" }, [
            _c("div", {
              staticClass: "h1 m0",
              staticStyle: { width: "100%" },
              domProps: {
                textContent: _vm._s(
                  _vm.$t(
                    _vm.branchInEditCopy.id
                      ? _vm.branchInEditCopy.name
                      : "Create branch"
                  )
                ),
              },
            }),
            _c(
              "div",
              {
                staticClass: "switch-wrap active",
                style: {
                  display: "flex",
                  "justify-content": "left",
                  width: "100%",
                  gap: "10px",
                },
              },
              [
                _c("span", {
                  staticClass: "swich-name mie12",
                  domProps: { textContent: _vm._s(_vm.$t("Active")) },
                }),
                _c("swich-button", {
                  model: {
                    value: _vm.branchInEditCopy.active,
                    callback: function ($$v) {
                      _vm.$set(_vm.branchInEditCopy, "active", $$v)
                    },
                    expression: "branchInEditCopy.active",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "popup__lang a-start d-md-none" }, [
            _c("div", {
              staticClass: "h1 pt10",
              staticStyle: { color: "#3c3c3b", "text-decoration": "none" },
              domProps: {
                textContent: _vm._s(
                  _vm.$t(
                    _vm.branchInEditCopy.id
                      ? _vm.branchInEditCopy.name
                      : "Create branch"
                  )
                ),
              },
            }),
            _c("div", { staticClass: "flex col a-end p0" }, [
              _c("div", { staticClass: "lang mb12" }, [
                _c("button", {
                  staticClass: "lang__item",
                  class: { active: _vm.branchInEditEn },
                  domProps: { textContent: _vm._s(_vm.$t("English")) },
                  on: {
                    click: function ($event) {
                      _vm.branchInEditEn = true
                    },
                  },
                }),
                _c("button", {
                  staticClass: "lang__item",
                  class: { active: !_vm.branchInEditEn },
                  domProps: { textContent: _vm._s(_vm.$t("Hebrew")) },
                  on: {
                    click: function ($event) {
                      _vm.branchInEditEn = false
                    },
                  },
                }),
              ]),
              _c(
                "div",
                {
                  staticClass: "switch-wrap active",
                  style: {
                    display: "flex",
                    "justify-content": "left",
                    width: "100%",
                    gap: "10px",
                  },
                },
                [
                  _c("span", {
                    staticClass: "swich-name",
                    domProps: { textContent: _vm._s(_vm.$t("Active")) },
                  }),
                  _c("swich-button", {
                    model: {
                      value: _vm.branchInEditCopy.active,
                      callback: function ($$v) {
                        _vm.$set(_vm.branchInEditCopy, "active", $$v)
                      },
                      expression: "branchInEditCopy.active",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "popup__content" }, [
          _c(
            "form",
            {
              staticClass: "form-site-wrap",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "form-site" }, [
                _c(
                  "div",
                  { staticClass: "form-site__content" },
                  [
                    _vm.branchInEditEn
                      ? _c("form-site-field-text", {
                          attrs: { title: "Name" },
                          model: {
                            value: _vm.branchInEditCopy.name_en,
                            callback: function ($$v) {
                              _vm.$set(_vm.branchInEditCopy, "name_en", $$v)
                            },
                            expression: "branchInEditCopy.name_en",
                          },
                        })
                      : _c("form-site-field-text", {
                          attrs: { title: "Name" },
                          model: {
                            value: _vm.branchInEditCopy.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.branchInEditCopy, "name", $$v)
                            },
                            expression: "branchInEditCopy.name",
                          },
                        }),
                    _c(
                      "div",
                      { staticClass: "lang-container d-none d-md-block" },
                      [
                        _c("div", { staticClass: "lang" }, [
                          _c("button", {
                            staticClass: "lang__item",
                            class: { active: _vm.branchInEditEn },
                            domProps: {
                              textContent: _vm._s(_vm.$t("English")),
                            },
                            on: {
                              click: function ($event) {
                                _vm.branchInEditEn = true
                              },
                            },
                          }),
                          _c("button", {
                            staticClass: "lang__item",
                            class: { active: !_vm.branchInEditEn },
                            domProps: { textContent: _vm._s(_vm.$t("Hebrew")) },
                            on: {
                              click: function ($event) {
                                _vm.branchInEditEn = false
                              },
                            },
                          }),
                        ]),
                      ]
                    ),
                    _c("form-site-field-text", {
                      attrs: { title: "Phone number" },
                      model: {
                        value: _vm.branchInEditCopy.phone,
                        callback: function ($$v) {
                          _vm.$set(_vm.branchInEditCopy, "phone", $$v)
                        },
                        expression: "branchInEditCopy.phone",
                      },
                    }),
                    _vm.branchInEditEn
                      ? _c("form-site-field-text", {
                          attrs: { title: "City" },
                          model: {
                            value: _vm.branchInEditCopy.city_en,
                            callback: function ($$v) {
                              _vm.$set(_vm.branchInEditCopy, "city_en", $$v)
                            },
                            expression: "branchInEditCopy.city_en",
                          },
                        })
                      : _c("form-site-field-text", {
                          attrs: { title: "City" },
                          model: {
                            value: _vm.branchInEditCopy.city,
                            callback: function ($$v) {
                              _vm.$set(_vm.branchInEditCopy, "city", $$v)
                            },
                            expression: "branchInEditCopy.city",
                          },
                        }),
                    _vm.branchInEditEn
                      ? _c("form-site-field-text", {
                          attrs: { title: "Address" },
                          model: {
                            value: _vm.branchInEditCopy.address_en,
                            callback: function ($$v) {
                              _vm.$set(_vm.branchInEditCopy, "address_en", $$v)
                            },
                            expression: "branchInEditCopy.address_en",
                          },
                        })
                      : _c("form-site-field-text", {
                          attrs: { title: "Address" },
                          model: {
                            value: _vm.branchInEditCopy.address,
                            callback: function ($$v) {
                              _vm.$set(_vm.branchInEditCopy, "address", $$v)
                            },
                            expression: "branchInEditCopy.address",
                          },
                        }),
                    _c("div", { staticClass: "form-site__field" }, [
                      _c("div", { staticClass: "form-site__input pt20" }, [
                        _c("button", {
                          staticClass: "btn btn_border",
                          domProps: {
                            textContent: _vm._s(_vm.$t("Set coordinates")),
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.popupCoordinates = true
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "form-site__content" },
                      [
                        _c("div", { staticClass: "form-site__group-title" }, [
                          _vm._v(_vm._s(_vm.$t("Digital receipt"))),
                        ]),
                        _c("select-box", {
                          attrs: { options: _vm.digitalReceiptOptions },
                          model: {
                            value: _vm.branchInEditCopy.digital_receipt,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.branchInEditCopy,
                                "digital_receipt",
                                $$v
                              )
                            },
                            expression: "branchInEditCopy.digital_receipt",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "form-site__btn md-none" }, [
                _c("div"),
                _c("button", {
                  staticClass: "btn",
                  domProps: { textContent: _vm._s(_vm.$t("Save")) },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                }),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "new-item__btn flex d-md-none" }, [
          _c(
            "button",
            { staticClass: "save-btn", on: { click: _vm.save } },
            [
              _c("svg-icon", {
                staticClass: "ic-edit",
                attrs: { name: "ic-save-btn", width: "93", height: "93" },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm.popupCoordinates
        ? _c("choose-coordinates", {
            attrs: { coordinates: _vm.branchInEditCopy.coordinates },
            on: { save: _vm.saveCoordinates },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }