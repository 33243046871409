var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { width: "100%" } }, [
    _c("span", {
      staticClass: "mie8",
      domProps: {
        textContent: _vm._s(_vm.$t("graph of all payments by type")),
      },
    }),
    _c("canvas", {
      staticClass: "singleChart",
      attrs: { id: "singleChart", height: "200px" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }