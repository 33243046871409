var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "social-networks full-screen" }, [
    _c("main", [
      _c("section", { staticClass: "container" }, [
        _c("div", { staticClass: "page" }, [
          _c(
            "div",
            { staticClass: "page__main" },
            [
              _c(
                "router-link",
                { staticClass: "page__back", attrs: { to: "../settings" } },
                [
                  _c("svg-icon", {
                    attrs: {
                      name: "ic-close-table-arrow",
                      width: "34",
                      height: "34",
                    },
                  }),
                ],
                1
              ),
              _c("h1", {
                domProps: {
                  textContent: _vm._s(_vm.$t("Pixels and social networks")),
                },
              }),
              _c("section", { staticClass: "main-settings" }, [
                _c("div", { staticClass: "main-settings__sect" }, [
                  _c("div", { staticClass: "main-settings__content" }, [
                    _c("div", { staticClass: "main-settings__form" }, [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "form-site" }, [
                            _c("div", { staticClass: "form-site__content" }, [
                              _c("div", { staticClass: "form-site__field" }, [
                                _c("div", {
                                  staticClass: "form-site__label",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Instagram")),
                                  },
                                }),
                                _c("div", { staticClass: "form-site__input" }, [
                                  _c(
                                    "div",
                                    { staticClass: "input-icon" },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          name: "ic-instagram",
                                          width: "16",
                                          height: "16",
                                        },
                                      }),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.socials.instagram,
                                            expression: "socials.instagram",
                                          },
                                        ],
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.socials.instagram,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.socials,
                                              "instagram",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "form-site__field" }, [
                                _c("div", {
                                  staticClass: "form-site__label",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Tripadvisor")),
                                  },
                                }),
                                _c("div", { staticClass: "form-site__input" }, [
                                  _c(
                                    "div",
                                    { staticClass: "input-icon" },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          name: "ic-tripadvisor",
                                          width: "16",
                                          height: "16",
                                        },
                                      }),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.socials.trip_advisor,
                                            expression: "socials.trip_advisor",
                                          },
                                        ],
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.socials.trip_advisor,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.socials,
                                              "trip_advisor",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "form-site__field" }, [
                                _c("div", {
                                  staticClass: "form-site__label",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Facebook")),
                                  },
                                }),
                                _c("div", { staticClass: "form-site__input" }, [
                                  _c(
                                    "div",
                                    { staticClass: "input-icon" },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          name: "ic-facebook",
                                          width: "16",
                                          height: "16",
                                        },
                                      }),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.socials.facebook,
                                            expression: "socials.facebook",
                                          },
                                        ],
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.socials.facebook,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.socials,
                                              "facebook",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "main-settings__sect" }, [
                  _c("div", { staticClass: "main-settings__content" }, [
                    _c("div", { staticClass: "main-settings__form" }, [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "form-site" }, [
                            _c(
                              "div",
                              { staticClass: "form-site__content" },
                              [
                                _c("form-site-field-text", {
                                  attrs: { title: "Google tag manager" },
                                  model: {
                                    value: _vm.socials.google_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.socials, "google_id", $$v)
                                    },
                                    expression: "socials.google_id",
                                  },
                                }),
                                _c("form-site-field-text", {
                                  attrs: { title: "Facebook pixel" },
                                  model: {
                                    value: _vm.socials.facebook_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.socials, "facebook_id", $$v)
                                    },
                                    expression: "socials.facebook_id",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                !_vm.branchId
                  ? _c("div", { staticClass: "main-settings__sect" }, [
                      _c("div", { staticClass: "main-settings__content" }, [
                        _c("div", { staticClass: "main-settings__form" }, [
                          _c(
                            "form",
                            {
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "form-site" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-site__content" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "form-site__field" },
                                      [
                                        _c("div", {
                                          staticClass: "form-site__label",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Title")
                                            ),
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "form-site__input" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.socials.title,
                                                  expression: "socials.title",
                                                },
                                              ],
                                              attrs: { type: "text" },
                                              domProps: {
                                                value: _vm.socials.title,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.socials,
                                                    "title",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-site__field flex a-center pt20",
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "form-site__input" },
                                          [
                                            _c("checkbox", {
                                              attrs: {
                                                name: "allow-search-engines",
                                              },
                                              model: {
                                                value:
                                                  _vm.socials
                                                    .allow_search_engines,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.socials,
                                                    "allow_search_engines",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "socials.allow_search_engines",
                                              },
                                            }),
                                            _c("span", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    "Allow search engines to index my website"
                                                  )
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "form-site m0 mb22" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-site__content" },
                                  [
                                    _c("div", {
                                      staticClass: "form-site__label",
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("keywords")),
                                      },
                                    }),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.socials.keywords,
                                          expression: "socials.keywords",
                                        },
                                      ],
                                      attrs: { type: "text" },
                                      domProps: { value: _vm.socials.keywords },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.socials,
                                            "keywords",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "form-site m0" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-site__content" },
                                  [
                                    _c("div", {
                                      staticClass: "form-site__label",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Description")
                                        ),
                                      },
                                    }),
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.socials.description,
                                          expression: "socials.description",
                                        },
                                      ],
                                      attrs: { type: "text" },
                                      domProps: {
                                        value: _vm.socials.description,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.socials,
                                            "description",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.branchId
                  ? _c("div", { staticClass: "main-settings__sect" }, [
                      _c("div", { staticClass: "main-settings__content" }, [
                        _c("div", { staticClass: "main-settings__form" }, [
                          _c(
                            "form",
                            {
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "form-site" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-site__content" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "form-site__content w100",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "settings-head flex col a-start",
                                          },
                                          [
                                            _c("h4", {
                                              staticClass: "pis12",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t("Scripts")
                                                ),
                                              },
                                            }),
                                            _c("div", { staticClass: "lang" }, [
                                              _c("button", {
                                                staticClass: "lang__item",
                                                class: {
                                                  active: _vm.script === 0,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t("Head")
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.script = 0
                                                  },
                                                },
                                              }),
                                              _c("button", {
                                                staticClass: "lang__item",
                                                class: {
                                                  active: _vm.script === 1,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t("Body")
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.script = 1
                                                  },
                                                },
                                              }),
                                              _c("button", {
                                                staticClass: "lang__item",
                                                class: {
                                                  active: _vm.script === 2,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t("Conversion")
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.script = 2
                                                  },
                                                },
                                              }),
                                            ]),
                                          ]
                                        ),
                                        _vm.script === 0
                                          ? _c("textarea", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.socials.head,
                                                  expression: "socials.head",
                                                },
                                              ],
                                              staticClass: "script",
                                              attrs: {
                                                dir: "ltr",
                                                type: "text",
                                              },
                                              domProps: {
                                                value: _vm.socials.head,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.socials,
                                                    "head",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.script === 1
                                          ? _c("textarea", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.socials.body,
                                                  expression: "socials.body",
                                                },
                                              ],
                                              staticClass: "script",
                                              attrs: {
                                                dir: "ltr",
                                                type: "text",
                                              },
                                              domProps: {
                                                value: _vm.socials.body,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.socials,
                                                    "body",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.script === 2
                                          ? _c("textarea", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.socials.after_payment,
                                                  expression:
                                                    "socials.after_payment",
                                                },
                                              ],
                                              staticClass: "script",
                                              attrs: {
                                                dir: "ltr",
                                                type: "text",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.socials.after_payment,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.socials,
                                                    "after_payment",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ]),
        _vm.change
          ? _c("div", { staticClass: "btn-save-container-settings" }, [
              _c(
                "button",
                { staticClass: "save-btn", on: { click: _vm.save } },
                [
                  _c("svg-icon", {
                    attrs: { name: "ic-save-btn", width: "112", height: "128" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }