var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "toaster" }, [
      _c(
        "div",
        _vm._l(_vm.missedCalls, function (callToast, callIndex) {
          return _c("toast-missed-call", {
            key: callIndex,
            attrs: { data: callToast },
            on: {
              dismiss: function ($event) {
                return _vm.dismissMissedCall(callIndex)
              },
            },
          })
        }),
        1
      ),
      _c(
        "div",
        _vm._l(_vm.toasts, function (toast, index) {
          return _c("toast", {
            key: index,
            attrs: { data: toast },
            on: {
              dismiss: function ($event) {
                return _vm.dismiss(index)
              },
            },
          })
        }),
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }