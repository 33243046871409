var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentClient
    ? _c(
        "div",
        { staticClass: "client-details" },
        [
          _c(
            "base-dialog",
            { attrs: { width: "1017px" }, on: { close: _vm.close } },
            [
              _c("div", { staticClass: "client-detail" }, [
                _c("div", { staticClass: "client-detail__content" }, [
                  _vm.currentClient
                    ? _c("div", { staticClass: "client-detail__main" }, [
                        _c("div", { staticClass: "profile-mob d-md-none" }, [
                          _c(
                            "div",
                            { staticClass: "profile__author" },
                            [
                              _c("user-avatar", {
                                attrs: {
                                  user: _vm.currentClient,
                                  "img-class": "profile__photo",
                                  size: 80,
                                },
                              }),
                              _c("div", { staticClass: "profile__info" }, [
                                _c("div", { staticClass: "profile__head" }, [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.currentClient.name
                                      ),
                                    },
                                  }),
                                  _vm.currentClient.is_club_member
                                    ? _c(
                                        "span",
                                        { staticClass: "profile__club-icon" },
                                        [
                                          _c("svg-icon", {
                                            staticClass: "ic-white",
                                            attrs: {
                                              name: "ic-crown",
                                              width: "16",
                                              height: "16",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                                _c("div", { staticClass: "profile__block" }, [
                                  _c("div", { staticClass: "input-check" }, [
                                    _c("label", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.currentClient.subscribe,
                                            expression:
                                              "currentClient.subscribe",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          disabled:
                                            !_vm.editClientDetailsPermission,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.currentClient.subscribe
                                          )
                                            ? _vm._i(
                                                _vm.currentClient.subscribe,
                                                null
                                              ) > -1
                                            : _vm.currentClient.subscribe,
                                        },
                                        on: {
                                          input: function ($event) {
                                            _vm.change = true
                                          },
                                          change: function ($event) {
                                            var $$a =
                                                _vm.currentClient.subscribe,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.currentClient,
                                                    "subscribe",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.currentClient,
                                                    "subscribe",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.currentClient,
                                                "subscribe",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "input-check__label" },
                                        [
                                          _c("span", {
                                            staticClass: "input-check__mark",
                                          }),
                                          _c("span", {
                                            staticClass: "input-check__text",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t("Subscribed")
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                    ]),
                                    _c("label", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.currentClient.blocked,
                                            expression: "currentClient.blocked",
                                          },
                                        ],
                                        attrs: {
                                          disabled:
                                            !_vm.blockUnblockClientPermission,
                                          type: "checkbox",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.currentClient.blocked
                                          )
                                            ? _vm._i(
                                                _vm.currentClient.blocked,
                                                null
                                              ) > -1
                                            : _vm.currentClient.blocked,
                                        },
                                        on: {
                                          input: function ($event) {
                                            _vm.change = true
                                          },
                                          change: function ($event) {
                                            var $$a = _vm.currentClient.blocked,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.currentClient,
                                                    "blocked",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.currentClient,
                                                    "blocked",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.currentClient,
                                                "blocked",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "input-check__label" },
                                        [
                                          _c("span", {
                                            staticClass: "input-check__mark",
                                          }),
                                          _c("span", {
                                            staticClass: "input-check__text",
                                            class: {
                                              grey: !_vm.blockUnblockClientPermission,
                                            },
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t("Blocked")
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "profile-mob__comment" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn-circle btn-circle_red",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    _vm.commentsMode = !_vm.commentsMode
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    name: "ic-chat",
                                    width: "20",
                                    height: "16",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "tabs-page active",
                            class: _vm.commentsMode ? "d-none d-md-block" : "",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "tabs-page__list" },
                              [
                                _vm._l(_vm.tabs, function (tab, tIndex) {
                                  return [
                                    _c("button", {
                                      key: tIndex,
                                      staticClass: "tabs-page__item",
                                      class: {
                                        active: _vm.activeTab === tab.id,
                                      },
                                      attrs: {
                                        type: "button",
                                        disabled: !tab.visit,
                                      },
                                      domProps: {
                                        textContent: _vm._s(tab.label),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeTab(tab)
                                        },
                                      },
                                    }),
                                  ]
                                }),
                              ],
                              2
                            ),
                            _c("div", { staticClass: "tabs-page__content" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "tabs-page__content-item",
                                  class: {
                                    active: _vm.activeTab === "client_details",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "profile" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "profile__author d-none d-md-flex",
                                      },
                                      [
                                        _c("user-avatar", {
                                          attrs: {
                                            user: _vm.currentClient,
                                            "img-class": "profile__photo",
                                            size: 100,
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "profile__info" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "profile__head" },
                                              [
                                                _c("span", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.currentClient.name
                                                    ),
                                                  },
                                                }),
                                                _vm.currentClient.is_club_member
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "profile__club-icon",
                                                      },
                                                      [
                                                        _c("svg-icon", {
                                                          staticClass:
                                                            "ic-white",
                                                          attrs: {
                                                            name: "ic-crown",
                                                            width: "16",
                                                            height: "16",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm.currentClient.is_club_member
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "profile__point",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.currentClient
                                                            .points
                                                        ) +
                                                        "\n                        " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "VIP Club points"
                                                          )
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.$store.getters.club
                                              ? [
                                                  _vm.currentClient
                                                    .is_club_member
                                                    ? _c("div", {
                                                        staticClass:
                                                          "profile__point",
                                                      })
                                                    : _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn_small btn_orange",
                                                          attrs: {
                                                            disabled:
                                                              !_vm.addClientToClubPermission,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.joinClub.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("svg-icon", {
                                                            staticClass:
                                                              "ic-white",
                                                            attrs: {
                                                              name: "ic-crown",
                                                              width: "16",
                                                              height: "16",
                                                            },
                                                          }),
                                                          _c("span", {
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "Join VIP Club"
                                                                  )
                                                                ),
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                ]
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              { staticClass: "profile__block" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "input-check",
                                                  },
                                                  [
                                                    _c("label", [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.currentClient
                                                                .subscribe,
                                                            expression:
                                                              "currentClient.subscribe",
                                                          },
                                                        ],
                                                        attrs: {
                                                          disabled:
                                                            !_vm.editClientDetailsPermission,
                                                          type: "checkbox",
                                                        },
                                                        domProps: {
                                                          checked:
                                                            Array.isArray(
                                                              _vm.currentClient
                                                                .subscribe
                                                            )
                                                              ? _vm._i(
                                                                  _vm
                                                                    .currentClient
                                                                    .subscribe,
                                                                  null
                                                                ) > -1
                                                              : _vm
                                                                  .currentClient
                                                                  .subscribe,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            _vm.change = true
                                                          },
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm
                                                                  .currentClient
                                                                  .subscribe,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    _vm.currentClient,
                                                                    "subscribe",
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    _vm.currentClient,
                                                                    "subscribe",
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                _vm.currentClient,
                                                                "subscribe",
                                                                $$c
                                                              )
                                                            }
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "input-check__label",
                                                        },
                                                        [
                                                          _c("span", {
                                                            staticClass:
                                                              "input-check__mark",
                                                          }),
                                                          _c("span", {
                                                            staticClass:
                                                              "input-check__text",
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "Subscribed"
                                                                  )
                                                                ),
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]),
                                                    _c("label", [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.currentClient
                                                                .blocked,
                                                            expression:
                                                              "currentClient.blocked",
                                                          },
                                                        ],
                                                        attrs: {
                                                          disabled:
                                                            !_vm.blockUnblockClientPermission,
                                                          type: "checkbox",
                                                        },
                                                        domProps: {
                                                          checked:
                                                            Array.isArray(
                                                              _vm.currentClient
                                                                .blocked
                                                            )
                                                              ? _vm._i(
                                                                  _vm
                                                                    .currentClient
                                                                    .blocked,
                                                                  null
                                                                ) > -1
                                                              : _vm
                                                                  .currentClient
                                                                  .blocked,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            _vm.change = true
                                                          },
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm
                                                                  .currentClient
                                                                  .blocked,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    _vm.currentClient,
                                                                    "blocked",
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    _vm.currentClient,
                                                                    "blocked",
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                _vm.currentClient,
                                                                "blocked",
                                                                $$c
                                                              )
                                                            }
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "input-check__label",
                                                        },
                                                        [
                                                          _c("span", {
                                                            staticClass:
                                                              "input-check__mark",
                                                          }),
                                                          _c("span", {
                                                            staticClass:
                                                              "input-check__text",
                                                            class: {
                                                              grey: !_vm.blockUnblockClientPermission,
                                                            },
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "Blocked"
                                                                  )
                                                                ),
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "form",
                                      {
                                        staticClass: "form-site-wrap",
                                        on: {
                                          submit: function ($event) {
                                            $event.preventDefault()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-site" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-site__content",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-site__field-wrap",
                                                  },
                                                  [
                                                    _c("form-site-field-text", {
                                                      attrs: {
                                                        title: "Full name",
                                                        readonly:
                                                          !_vm.editClientDetailsPermission,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _vm.change = true
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.currentClient
                                                            .name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentClient,
                                                            "name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentClient.name",
                                                      },
                                                    }),
                                                    _c("form-site-field-text", {
                                                      attrs: {
                                                        title: "Email",
                                                        readonly:
                                                          !_vm.editClientDetailsPermission,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _vm.change = true
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.currentClient
                                                            .email,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentClient,
                                                            "email",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentClient.email",
                                                      },
                                                    }),
                                                    _c("form-site-field-text", {
                                                      attrs: {
                                                        title: "Phone number",
                                                        readonly:
                                                          !_vm.editClientDetailsPermission,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _vm.change = true
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.currentClient
                                                            .phone,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentClient,
                                                            "phone",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentClient.phone",
                                                      },
                                                    }),
                                                    _c("form-site-field-date", {
                                                      attrs: {
                                                        title: "Birthday",
                                                        readonly:
                                                          !_vm.editClientDetailsPermission,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.currentClient
                                                            .birth,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentClient,
                                                            "birth",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentClient.birth",
                                                      },
                                                    }),
                                                    _c("form-site-field-date", {
                                                      attrs: {
                                                        title: "Wedding day",
                                                        readonly:
                                                          !_vm.editClientDetailsPermission,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.currentClient
                                                            .wedding,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentClient,
                                                            "wedding",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentClient.wedding",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-site__field",
                                                  },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "form-site__label",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t("Addresses")
                                                        ),
                                                      },
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "edit-info",
                                                      },
                                                      [
                                                        _vm.currentClient &&
                                                        _vm.currentClient
                                                          .addresses &&
                                                        _vm.currentClient
                                                          .addresses.length
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "edit-info__list",
                                                              },
                                                              _vm._l(
                                                                _vm
                                                                  .currentClient
                                                                  .addresses,
                                                                function (
                                                                  address,
                                                                  adIndex
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key: adIndex,
                                                                      staticClass:
                                                                        "edit-info__item",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "edit-info__info",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.editAddress(
                                                                                  address
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "edit-info__title",
                                                                              domProps:
                                                                                {
                                                                                  textContent:
                                                                                    _vm._s(
                                                                                      address.name
                                                                                    ),
                                                                                },
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "edit-info__text",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "address-to-span",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      address:
                                                                                        address,
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "edit-info__btn",
                                                                        },
                                                                        [
                                                                          _vm.editClientAddressesPermission
                                                                            ? _c(
                                                                                "button",
                                                                                {
                                                                                  staticClass:
                                                                                    "edit-info__del",
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.deleteAddress(
                                                                                          address
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "svg-icon",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          name: "ic-close",
                                                                                          width:
                                                                                            "10",
                                                                                          height:
                                                                                            "10",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          : _vm._e(),
                                                        _vm.editClientAddressesPermission
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "line-more",
                                                              },
                                                              [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "line-more__content",
                                                                    on: {
                                                                      click:
                                                                        _vm.addAddress,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "line-more__icon",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "svg-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                name: "ic-plus",
                                                                                width:
                                                                                  "12",
                                                                                height:
                                                                                  "12",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c("span", {
                                                                      staticClass:
                                                                        "line-more__text",
                                                                      domProps:
                                                                        {
                                                                          textContent:
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "Add"
                                                                              )
                                                                            ),
                                                                        },
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "tabs-page__content-item",
                                  class: {
                                    active: _vm.activeTab === "client-orders",
                                  },
                                },
                                [
                                  _c("order-sources-tabs", {
                                    on: { change: _vm.ordersSourceChange },
                                  }),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "table-list",
                                        _vm._b(
                                          {
                                            on: {
                                              "on-click-line": _vm.orderDetails,
                                            },
                                          },
                                          "table-list",
                                          _vm.dataTable,
                                          false
                                        )
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "paginationOrders" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "pager-wrap" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "count-row" },
                                                [
                                                  _c("div", {
                                                    staticClass:
                                                      "count-row__title",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t("Rows per page:")
                                                      ),
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "count-row-num",
                                                    },
                                                    [
                                                      _c("select-box", {
                                                        attrs: {
                                                          unsort: "",
                                                          openToUp: "",
                                                          widthHeader: "50px",
                                                          width: "100px",
                                                          options:
                                                            _vm.itemsPerPageOptions.map(
                                                              function (i) {
                                                                return {
                                                                  label: "" + i,
                                                                  value: i,
                                                                }
                                                              }
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.rowsPerPageOrders,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.rowsPerPageOrders =
                                                              $$v
                                                          },
                                                          expression:
                                                            "rowsPerPageOrders",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "pager" },
                                                [
                                                  _c("div", {
                                                    staticClass: "pager__text",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.pageNameForOrders
                                                      ),
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "pager__arr",
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "pager__prev",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.setPageForOrders(
                                                                -1
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("svg-icon", {
                                                            staticClass:
                                                              "ic-stroke",
                                                            attrs: {
                                                              name: "ic-arrow-prev",
                                                              width: "8",
                                                              height: "14",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "pager__next",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.setPageForOrders(
                                                                1
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("svg-icon", {
                                                            staticClass:
                                                              "ic-stroke",
                                                            attrs: {
                                                              name: "ic-arrow-next",
                                                              width: "8",
                                                              height: "14",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "tabs-page__content-item",
                                  class: { active: _vm.activeTab === "calls" },
                                },
                                [
                                  _c("div", { staticClass: "client-calls" }, [
                                    _c("div", {
                                      staticClass: "h2 d-none d-md-block",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Calls log")
                                        ),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "page-admin-table" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "admin-table-wrap" },
                                          [
                                            _c(
                                              "table",
                                              { staticClass: "admin-table" },
                                              [
                                                _c("thead", [
                                                  _c("tr", [
                                                    _c("th", {
                                                      staticClass:
                                                        "admin-table__play",
                                                    }),
                                                    _c("th", {
                                                      staticClass:
                                                        "admin-table__status-call",
                                                    }),
                                                    _c("th", {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t("Start")
                                                        ),
                                                      },
                                                    }),
                                                    _c("th", {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t("End")
                                                        ),
                                                      },
                                                    }),
                                                    _c("th", {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t("Total")
                                                        ),
                                                      },
                                                    }),
                                                    _c("th", {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t("Date")
                                                        ),
                                                      },
                                                    }),
                                                    _c("th", {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t("Made by")
                                                        ),
                                                      },
                                                    }),
                                                  ]),
                                                ]),
                                                _c(
                                                  "tbody",
                                                  {
                                                    staticClass: "calls-tbody",
                                                  },
                                                  _vm._l(
                                                    _vm.calls,
                                                    function (
                                                      callItem,
                                                      callIndex
                                                    ) {
                                                      return _c(
                                                        "tr",
                                                        { key: callIndex },
                                                        [
                                                          _c(
                                                            "td",
                                                            [
                                                              _c(
                                                                "recording-player",
                                                                {
                                                                  attrs: {
                                                                    data: {
                                                                      recordingUrl:
                                                                        callItem.recording_url,
                                                                      phone:
                                                                        callItem.phone,
                                                                      id: callItem.call_id,
                                                                    },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "td",
                                                            [
                                                              _c("svg-icon", {
                                                                attrs: {
                                                                  name:
                                                                    [
                                                                      "in",
                                                                      "aut",
                                                                      "un",
                                                                      "un",
                                                                    ][
                                                                      callItem
                                                                        .type
                                                                    ] + "-call",
                                                                  width: "16",
                                                                  height: "16",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c("td", {
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  _vm.filters.time(
                                                                    callItem.start
                                                                  )
                                                                ),
                                                            },
                                                          }),
                                                          _c("td", {
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  _vm.filters.time(
                                                                    callItem.end
                                                                  )
                                                                ),
                                                            },
                                                          }),
                                                          _c("td", {
                                                            staticClass:
                                                              "d-md-table-cell d-none",
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  _vm.filters.duration(
                                                                    callItem.duration
                                                                  )
                                                                ),
                                                            },
                                                          }),
                                                          _c("td", {
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  _vm.filters.date(
                                                                    callItem.start
                                                                  )
                                                                ),
                                                            },
                                                          }),
                                                          _c("td", {
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  callItem.client
                                                                    ? callItem
                                                                        .client
                                                                        .name
                                                                    : ""
                                                                ),
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "tabs-page__content-item",
                                  class: {
                                    active: _vm.activeTab === "vouchers",
                                  },
                                },
                                [
                                  _c("div", {
                                    staticClass: "h2 d-none d-md-block",
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Vouchers")),
                                    },
                                  }),
                                  _c("div", { staticClass: "voucher-tabs" }, [
                                    _c("button", {
                                      staticClass: "voucher-tab",
                                      class: {
                                        active:
                                          _vm.voucherTab === "compensation",
                                      },
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Compensation")
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.voucherTab = "compensation"
                                        },
                                      },
                                    }),
                                    _c("button", {
                                      staticClass: "voucher-tab",
                                      class: {
                                        active: _vm.voucherTab === "benefits",
                                      },
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Benefits")),
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.voucherTab = "benefits"
                                        },
                                      },
                                    }),
                                    _c("button", {
                                      staticClass: "voucher-tab",
                                      class: {
                                        active: _vm.voucherTab === "tabs",
                                      },
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Tabs")),
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.voucherTab = "tabs"
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm.voucherTab === "compensation"
                                    ? _c(
                                        "div",
                                        { staticClass: "voucher-list" },
                                        [
                                          _vm.createCompensationPermission
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass: "compensation",
                                                  on: {
                                                    click: _vm.newCompensation,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "compensation-name flex a-center mb0",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "add-btn mie12",
                                                        },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              attrs: {
                                                                width: "20",
                                                                height: "20",
                                                                viewBox:
                                                                  "0 0 20 20",
                                                                fill: "none",
                                                                xmlns:
                                                                  "http://www.w3.org/2000/svg",
                                                              },
                                                            },
                                                            [
                                                              _c("rect", {
                                                                attrs: {
                                                                  x: "8.75",
                                                                  width: "2.5",
                                                                  height: "20",
                                                                  rx: "1.25",
                                                                  fill: "#48B26B",
                                                                },
                                                              }),
                                                              _c("rect", {
                                                                attrs: {
                                                                  y: "11.25",
                                                                  width: "2.5",
                                                                  height: "20",
                                                                  rx: "1.25",
                                                                  transform:
                                                                    "rotate(-90 0 11.25)",
                                                                  fill: "#48B26B",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c("span", {
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t(
                                                              "New compensation"
                                                            )
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._l(
                                            _vm.compensations,
                                            function (compensation) {
                                              return _c(
                                                "button",
                                                {
                                                  key: compensation.id,
                                                  staticClass: "compensation",
                                                  class: {
                                                    active:
                                                      _vm.compensationSelected &&
                                                      _vm.compensationSelected
                                                        .id === compensation.id,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openCompensation2(
                                                        compensation
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "flex w100 bet a-center",
                                                    },
                                                    [
                                                      _c("span", {}, [
                                                        _c("span", {
                                                          staticClass:
                                                            "compensation-name",
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              compensation.name
                                                            ),
                                                          },
                                                        }),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "compensation-created",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "Created"
                                                                  )
                                                                ) +
                                                                "\n                          " +
                                                                _vm._s(
                                                                  _vm.filters.date(
                                                                    compensation.created_at,
                                                                    "DD/MM/YYYY"
                                                                  )
                                                                ) +
                                                                "\n                          " +
                                                                _vm._s(
                                                                  compensation.created_at ===
                                                                    _vm.$t(
                                                                      "Now"
                                                                    )
                                                                    ? ""
                                                                    : _vm.$t(
                                                                        "at"
                                                                      )
                                                                ) +
                                                                "\n                          " +
                                                                _vm._s(
                                                                  String(
                                                                    compensation.created_at
                                                                  ).slice(
                                                                    10,
                                                                    25
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "compensation-created",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.$t("By:")
                                                                ) +
                                                                "\n                          " +
                                                                _vm._s(
                                                                  compensation.created_by
                                                                ) +
                                                                "\n                        "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _c("span", {
                                                        class: [
                                                          "status-pending",
                                                          "status-active",
                                                          "status-redeemed",
                                                          "status-rejected",
                                                        ][compensation.status],
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t(
                                                              [
                                                                "Pending approval",
                                                                "Active",
                                                                "Redeemed",
                                                                "Rejected",
                                                              ][
                                                                compensation
                                                                  .status
                                                              ]
                                                            )
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "pager-wrap" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "count-row" },
                                                [
                                                  _c("div", {
                                                    staticClass:
                                                      "count-row__title",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t("Rows per page:")
                                                      ),
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "count-row-num",
                                                    },
                                                    [
                                                      _c("select-box", {
                                                        attrs: {
                                                          unsort: "",
                                                          openToUp: "",
                                                          widthHeader: "50px",
                                                          width: "100px",
                                                          options:
                                                            _vm.itemsPerPageOptions.map(
                                                              function (i) {
                                                                return {
                                                                  label: "" + i,
                                                                  value: i,
                                                                }
                                                              }
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.rowsPerPageCompensations,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.rowsPerPageCompensations =
                                                              $$v
                                                          },
                                                          expression:
                                                            "rowsPerPageCompensations",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "pager" },
                                                [
                                                  _c("div", {
                                                    staticClass: "pager__text",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.pageName
                                                      ),
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "pager__arr",
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "pager__prev",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.setPage(
                                                                -1
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("svg-icon", {
                                                            staticClass:
                                                              "ic-stroke",
                                                            attrs: {
                                                              name: "ic-arrow-prev",
                                                              width: "8",
                                                              height: "14",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "pager__next",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.setPage(
                                                                1
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("svg-icon", {
                                                            staticClass:
                                                              "ic-stroke",
                                                            attrs: {
                                                              name: "ic-arrow-next",
                                                              width: "8",
                                                              height: "14",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "client-comment",
                            class: _vm.commentsMode
                              ? "d-flex d-md-none"
                              : "d-none",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "client-comment__content" },
                              [
                                _c("div", {
                                  staticClass: "client-comment__title",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Comments")),
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "client-comment__list_container",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "client-comment__list" },
                                      _vm._l(
                                        _vm.comments,
                                        function (comment, commIndex) {
                                          return _c(
                                            "div",
                                            {
                                              key: commIndex,
                                              staticClass:
                                                "client-comment-item",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "client-comment-item__content",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "client-comment-item__top",
                                                    },
                                                    [
                                                      comment.user
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "client-comment-item__name",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    comment.user
                                                                      .name
                                                                  ) +
                                                                  "\n                          " +
                                                                  _vm._s(
                                                                    comment.user
                                                                      .last_name
                                                                  ) +
                                                                  "\n                        "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "client-comment-item__date",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                _vm.filters.date(
                                                                  comment.created_at,
                                                                  "DD/MM/YYYY"
                                                                )
                                                              ) +
                                                              "\n                          " +
                                                              _vm._s(
                                                                comment.created_at ===
                                                                  _vm.$t("Now")
                                                                  ? ""
                                                                  : _vm.$t("at")
                                                              ) +
                                                              "\n                          " +
                                                              _vm._s(
                                                                _vm.filters.time(
                                                                  comment.created_at
                                                                )
                                                              ) +
                                                              "\n                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c("div", {
                                                    staticClass:
                                                      "client-comment-item__text",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        comment.content
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "client-comment__field" },
                              [
                                _c(
                                  "form",
                                  {
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                        return _vm.makeComment.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "form-comment" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.newComment,
                                            expression: "newComment",
                                          },
                                        ],
                                        attrs: {
                                          type: "text",
                                          placeholder: _vm.$t("Add comment"),
                                          title: _vm.$t("Add comment"),
                                          required: "",
                                        },
                                        domProps: { value: _vm.newComment },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.newComment = $event.target.value
                                          },
                                        },
                                      }),
                                      _c(
                                        "button",
                                        { attrs: { type: "submit" } },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "ic-big-arrow",
                                              width: "24",
                                              height: "24",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "client-detail__bottom" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              attrs: { type: "button" },
                              on: { click: _vm.save },
                            },
                            [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Save")),
                                },
                              }),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex j-end d-none d-md-flex max-w80",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "btn-whatsapp",
                                  attrs: {
                                    target: "_blank",
                                    href:
                                      "https://wa.me/972" +
                                      _vm.currentClient.phone.substr(1),
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/img/WhatsApp.svg"),
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-call",
                                  on: { click: _vm.makeCall },
                                },
                                [
                                  _c("svg-icon", {
                                    staticClass: "ic-white",
                                    attrs: {
                                      name: "ic-calls",
                                      width: "24",
                                      height: "24",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "flex j-end d-md-none" }, [
                            _c(
                              "a",
                              {
                                staticClass: "btn-whatsapp",
                                attrs: {
                                  href:
                                    "https://wa.me/972" +
                                    _vm.currentClient.phone.substr(1),
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/WhatsApp.svg"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "btn-call d-flex",
                                attrs: {
                                  href: "tel:" + _vm.currentClient.phone,
                                },
                              },
                              [
                                _c("svg-icon", {
                                  staticClass: "ic-white",
                                  attrs: {
                                    name: "ic-calls",
                                    width: "24",
                                    height: "24",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.activeTab === "client_details"
                    ? _c("div", { staticClass: "client-comment" }, [
                        _c("div", { staticClass: "client-comment__content" }, [
                          _c("div", {
                            staticClass: "client-comment__title",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Comments")),
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "client-comment__list" },
                            _vm._l(_vm.comments, function (comment, commIndex) {
                              return _c(
                                "div",
                                {
                                  key: commIndex,
                                  staticClass: "client-comment-item",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "client-comment-item__content",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "client-comment-item__top",
                                        },
                                        [
                                          comment.user
                                            ? _c("div", {
                                                staticClass:
                                                  "client-comment-item__name",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    comment.user.name
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "client-comment-item__date",
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.filters.date(
                                                      comment.created_at,
                                                      "DD/MM/YYYY"
                                                    )
                                                  ) +
                                                  "\n                      " +
                                                  _vm._s(
                                                    comment.created_at ===
                                                      _vm.$t("Now")
                                                      ? ""
                                                      : _vm.$t("at")
                                                  ) +
                                                  "\n                      " +
                                                  _vm._s(
                                                    _vm.filters.time(
                                                      comment.created_at
                                                    )
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("div", {
                                        staticClass:
                                          "client-comment-item__text",
                                        domProps: {
                                          textContent: _vm._s(comment.content),
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm.deleteCommentPermission
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "client-comment-item__more",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.removeComment(
                                                comment,
                                                commIndex
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            staticClass: "icon ic-more",
                                            attrs: {
                                              name: "ic-bin",
                                              width: "14",
                                              height: "16",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                        _c("div", { staticClass: "client-comment__field" }, [
                          _c(
                            "form",
                            {
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.makeComment.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "form-comment" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.newComment,
                                      expression: "newComment",
                                    },
                                  ],
                                  attrs: {
                                    type: "text",
                                    placeholder: _vm.$t("Add comment"),
                                    title: _vm.$t("Add comment"),
                                    required: "",
                                  },
                                  domProps: { value: _vm.newComment },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.newComment = $event.target.value
                                    },
                                  },
                                }),
                                _c(
                                  "button",
                                  { attrs: { type: "submit" } },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        name: "ic-big-arrow",
                                        width: "24",
                                        height: "24",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "client-details-popups" },
            [
              !!_vm.currentOrder
                ? _c("order-details-popup", {
                    attrs: {
                      order: _vm.currentOrder,
                      show: !!_vm.currentOrder,
                    },
                    on: {
                      closed: _vm.closeOrderDetails,
                      displayCanceledOrder: _vm.displayCanceledOrder,
                      cancelOrder: _vm.cancelOrder,
                      statusChanged: _vm.changeOrderStatus,
                    },
                  })
                : _vm._e(),
              !!_vm.currentAddress
                ? _c("edit-address-dialog", {
                    attrs: {
                      address: _vm.currentAddress,
                      show: !!_vm.currentAddress,
                    },
                    on: {
                      closed: _vm.closeAddressEditing,
                      saved: _vm.saveAddress,
                    },
                  })
                : _vm._e(),
              _vm.popoupCompensation
                ? _c("compensation-edit-dialog", {
                    attrs: { compensation: _vm.newCompensationInEdit },
                    on: {
                      save: _vm.saveNewCompensation,
                      close: function ($event) {
                        return _vm.saveNewCompensation2()
                      },
                    },
                  })
                : _vm._e(),
              _vm.popoupCompensation2
                ? _c("compensation-edit-dialog", {
                    attrs: { compensation: _vm.compensationSelected },
                    on: {
                      save: function ($event) {
                        return _vm.saveNewCompensation2()
                      },
                      close: function ($event) {
                        return _vm.saveNewCompensation2()
                      },
                      closeload: _vm.closeAndLoad,
                    },
                  })
                : _vm._e(),
              _vm.memberInEdit
                ? _c("club-member-details-popup", {
                    attrs: { member: _vm.memberInEdit },
                    on: { save: _vm.saveDetailsMember },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }