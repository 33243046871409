var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "full-screen" }, [
    _c(
      "main",
      [
        _c("section", { staticClass: "container" }, [
          _c("div", { staticClass: "page" }, [
            _c(
              "div",
              { staticClass: "page__main" },
              [
                _c(
                  "router-link",
                  { staticClass: "page__back", attrs: { to: "../settings" } },
                  [
                    _c("svg-icon", {
                      attrs: {
                        name: "ic-close-table-arrow",
                        width: "34",
                        height: "34",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "page__top mb22" }, [
                  _c("h1", {
                    domProps: {
                      textContent: _vm._s(_vm.$t("Call center settings")),
                    },
                  }),
                ]),
                _c("div", { staticClass: "page__content" }, [
                  _vm.restaurantId
                    ? _c(
                        "form",
                        {
                          staticClass: "form-site",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.saveSwitchboard.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "form-site__content" }, [
                            _c(
                              "div",
                              { staticClass: "form-site__field-wrap" },
                              [
                                _c("form-site-field-text", {
                                  attrs: { title: "Business id" },
                                  model: {
                                    value: _vm.settings.rest_number,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.settings, "rest_number", $$v)
                                    },
                                    expression: "settings.rest_number",
                                  },
                                }),
                                _c("form-site-field-text", {
                                  attrs: { title: "Username" },
                                  model: {
                                    value: _vm.settings.user,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.settings, "user", $$v)
                                    },
                                    expression: "settings.user",
                                  },
                                }),
                                _c("form-site-field-text", {
                                  attrs: {
                                    ltr: "",
                                    type: "password",
                                    title: "Password",
                                  },
                                  model: {
                                    value: _vm.settings.key,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.settings, "key", $$v)
                                    },
                                    expression: "settings.key",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "hr" }),
                  _c("h3", {
                    staticClass: "h3",
                    domProps: { textContent: _vm._s(_vm.$t("Extentions")) },
                  }),
                  _c(
                    "div",
                    { staticClass: "extentions-container" },
                    _vm._l(_vm.extentions, function (extention) {
                      return _c(
                        "div",
                        { key: extention.user_id, staticClass: "extention" },
                        [
                          _c("div", {
                            staticClass: "extention-name",
                            domProps: {
                              textContent: _vm._s(extention.username),
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "extention-btns" },
                            [
                              extention.user_id
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "book-set-item__edit pink",
                                      on: {
                                        click: function ($event) {
                                          return _vm.disconnect(extention)
                                        },
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        staticClass: "ic-edit",
                                        attrs: {
                                          name: "ic-disconnect",
                                          width: "48",
                                          height: "48",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("edit-btn", {
                                attrs: { size: 50 },
                                on: {
                                  click: function ($event) {
                                    return _vm.edit(extention)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _c("div", { staticClass: "line-more" }, [
                    _c(
                      "div",
                      {
                        staticClass: "line-more__content",
                        on: { click: _vm.create },
                      },
                      [
                        _c(
                          "button",
                          { staticClass: "line-more__icon" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-plus",
                                width: "12",
                                height: "12",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", {
                          staticClass: "line-more__text",
                          domProps: { textContent: _vm._s(_vm.$t("Add")) },
                        }),
                      ]
                    ),
                  ]),
                  !_vm.restaurantId
                    ? _c("div", [
                        _c("p", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("Please choose restaurant")
                            ),
                          },
                        }),
                      ])
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ]),
          _vm.change
            ? _c("div", { staticClass: "btn-save-container-settings" }, [
                _c(
                  "button",
                  { staticClass: "save-btn", on: { click: _vm.save } },
                  [
                    _c("svg-icon", {
                      staticClass: "ic-edit",
                      attrs: {
                        name: "ic-save-btn",
                        width: "112",
                        height: "96",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
        _vm.popup
          ? _c("edit-extention-dialog", {
              attrs: { extention: _vm.extentionInEdit },
              on: {
                close: _vm.close,
                save: _vm.saveExtentions,
                remove: _vm.remove,
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }