var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-member-club-dialog" },
    [
      _c(
        "base-dialog",
        {
          attrs: { width: "614px", title: "Member club" },
          on: { close: _vm.close },
        },
        [
          _c("div", { staticClass: "popup__content" }, [
            _c("div", { staticClass: "form-lang" }, [
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c("div", { staticClass: "form-site" }, [
                    _c("div", { staticClass: "form-site__content" }, [
                      _c("div", { staticClass: "form-site__group" }, [
                        _c("div", { staticClass: "form-site__content" }, [
                          _c("div", {
                            staticClass: "form-site__label",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Club name")),
                            },
                          }),
                          _c("div", { staticClass: "form-site__input" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.club.name,
                                  expression: "club.name",
                                },
                              ],
                              ref: "name",
                              attrs: { type: "text" },
                              domProps: { value: _vm.club.name },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.club,
                                    "name",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-site__content" }, [
                          _c("div", { staticClass: "flex bet w100" }, [
                            _c("div", {
                              staticClass: "form-site__label",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Name for sms")),
                              },
                            }),
                            _c("div", {
                              staticClass: "form-site__label",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.club.name_for_sms.length + "/11"
                                ),
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "form-site__input" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.club.name_for_sms,
                                  expression: "club.name_for_sms",
                                },
                              ],
                              attrs: { maxlength: "11", type: "text" },
                              domProps: { value: _vm.club.name_for_sms },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.club,
                                      "name_for_sms",
                                      $event.target.value
                                    )
                                  },
                                  _vm.valid,
                                ],
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-site__btn pb40 form-site__btn_sb d-md-flex d-none",
                    },
                    [
                      _c("div"),
                      _c("button", {
                        staticClass: "btn",
                        domProps: { textContent: _vm._s(_vm.$t("save")) },
                        on: { click: _vm.save },
                      }),
                    ]
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
              _c(
                "button",
                { staticClass: "save-btn", on: { click: _vm.save } },
                [
                  _c("svg-icon", {
                    staticClass: "ic-edit",
                    attrs: { name: "ic-save-btn", width: "93", height: "93" },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }