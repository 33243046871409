var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "member-club" },
    [
      _c("main", [
        _c("section", { staticClass: "container" }, [
          _c("div", { staticClass: "page" }, [
            _c("div", { staticClass: "page__main" }, [
              _c("h1", {
                domProps: { textContent: _vm._s(_vm.$t("Members club")) },
              }),
              _c("div", { staticClass: "branches" }, [
                _c(
                  "div",
                  { staticClass: "branches__sect" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "branches-item",
                        attrs: { to: "./member-club/posts" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "branches-item__icon" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-posts",
                                width: "80",
                                height: "80",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "branches-item__content" }, [
                          _c("div", {
                            staticClass: "branches-item__title",
                            domProps: { textContent: _vm._s(_vm.$t("Posts")) },
                          }),
                          _c("div", {
                            staticClass: "branches-item__text",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Posts description")),
                            },
                          }),
                        ]),
                      ]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "branches-item",
                        attrs: { to: "./member-club/vouchers" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "branches-item__icon" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-vouchers",
                                width: "80",
                                height: "80",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "branches-item__content" }, [
                          _c("div", {
                            staticClass: "branches-item__title",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Vouchers")),
                            },
                          }),
                          _c("div", {
                            staticClass: "branches-item__text",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("Benefits description")
                              ),
                            },
                          }),
                        ]),
                      ]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "branches-item",
                        attrs: { to: "./member-club/members" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "branches-item__icon" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-members",
                                width: "80",
                                height: "80",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "branches-item__content" }, [
                          _c("div", {
                            staticClass: "branches-item__title",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Members")),
                            },
                          }),
                          _c("div", {
                            staticClass: "branches-item__text",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("Members description")
                              ),
                            },
                          }),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "branches-item",
                        staticStyle: { cursor: "pointer" },
                        on: { click: _vm.showPushDialog },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "branches-item__icon" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-members",
                                width: "80",
                                height: "80",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "branches-item__content" }, [
                          _c("div", {
                            staticClass: "branches-item__title",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("Send push notifications")
                              ),
                            },
                          }),
                          _c("div", { staticClass: "branches-item__text" }),
                        ]),
                      ]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "branches-item",
                        attrs: { to: "./audiences" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "branches-item__icon" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-audiences",
                                width: "80",
                                height: "80",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "branches-item__content" }, [
                          _c("div", {
                            staticClass: "branches-item__title",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Audiences")),
                            },
                          }),
                          _c("div", { staticClass: "branches-item__text" }),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "branches-item" }, [
                      _vm._m(0),
                      _c("div", { staticClass: "branches-item__content" }, [
                        _c("div", {
                          staticClass: "branches-item__title",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("Terms & services for clubs")
                            ),
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "space-between",
                            },
                          },
                          [
                            _c("div", {
                              staticClass: "branches-item__text",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "Upload file with PDF/TXT/DOCX format only "
                                  )
                                ),
                              },
                            }),
                            _vm.nameTerms && _vm.nameTerms.length == 0
                              ? _c(
                                  "label",
                                  {
                                    staticClass: "setting-panel__upload",
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "center",
                                      "margin-top": "-20px",
                                    },
                                  },
                                  [
                                    _c("input", {
                                      attrs: {
                                        type: "file",
                                        accept: "application/pdf",
                                      },
                                      on: { change: _vm.addFile },
                                    }),
                                    _c("svg-icon", {
                                      attrs: {
                                        name: "ic-upload",
                                        width: "20",
                                        height: "20",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm.nameTerms && _vm.nameTerms.length > 0
                          ? _c(
                              "div",
                              { staticClass: "setting-panel__upload-exist" },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.clubTermsLink,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        name: "ic-terms-upload",
                                        width: "32",
                                        height: "32",
                                      },
                                    }),
                                    _vm.nameTerms && _vm.nameTerms.length > 0
                                      ? _c("span", {
                                          domProps: {
                                            textContent: _vm._s(_vm.nameTerms),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "label",
                                  {
                                    staticClass: "upload-continer",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                  },
                                  [
                                    _c("input", {
                                      attrs: {
                                        type: "file",
                                        accept: "application/pdf",
                                      },
                                      on: { change: _vm.addFile },
                                    }),
                                    _c("svg-icon", {
                                      attrs: {
                                        name: "ic-upload",
                                        width: "20",
                                        height: "20",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.nameTerms && _vm.nameTerms.length > 0
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "exitbtn",
                                        on: {
                                          click: function ($event) {
                                            _vm.termsToUpload = null
                                            _vm.nameTerms = ""
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              width: "14",
                                              height: "14",
                                              viewBox: "0 0 14 14",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              staticClass: "second-fill",
                                              attrs: {
                                                d: "M13.7424 0.256009C13.401 -0.0853364 12.8536 -0.0853364 12.5122 0.256009L6.99918 5.76906L1.48614 0.256009C1.14479 -0.0853364 0.597354 -0.0853364 0.256009 0.256009C-0.0853363 0.597355 -0.0853363 1.1448 0.256009 1.48614L5.76905 6.9992L0.256009 12.5122C-0.0853363 12.8536 -0.0853363 13.401 0.256009 13.7424C0.423461 13.9098 0.648878 14 0.867854 14C1.08683 14 1.31225 13.9163 1.4797 13.7424L6.99274 8.22933L12.5058 13.7424C12.6732 13.9098 12.8987 14 13.1176 14C13.343 14 13.562 13.9163 13.7295 13.7424C14.0708 13.401 14.0708 12.8536 13.7295 12.5122L8.22931 6.9992L13.7424 1.48614C14.0837 1.1448 14.0837 0.597355 13.7424 0.256009Z",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _c(
                      "router-link",
                      {
                        staticClass: "branches-item",
                        attrs: { to: "/member-club/sms-messages" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "branches-item__icon" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-sms",
                                width: "36",
                                height: "36",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "branches-item__content" }, [
                          _c("div", {
                            staticClass: "branches-item__title",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Mailing")),
                            },
                          }),
                          _c("div", { staticClass: "branches-item__text" }),
                        ]),
                      ]
                    ),
                    _vm.change
                      ? _c(
                          "div",
                          { staticClass: "btn-save-container-settings" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "save-btn",
                                on: { click: _vm.saveSettings },
                              },
                              [
                                _c("svg-icon", {
                                  staticClass: "ic-edit",
                                  attrs: {
                                    name: "ic-save-btn",
                                    width: "112",
                                    height: "128",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm.showSendPushDialog
        ? _c("create-new-push-notification", { on: { close: _vm.closeDialog } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "branches-item__icon", staticStyle: { padding: "21px" } },
      [_c("img", { attrs: { src: require("@/assets/img/file.svg"), alt: "" } })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }