var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-check" }, [
    _c(
      "div",
      { staticClass: "input-check" },
      [
        _c(
          "label",
          { staticClass: "input-check__label", on: { click: _vm.open } },
          [
            _c("checkbox", {
              attrs: { inline: "", name: "" + _vm.item.id },
              on: { input: _vm.setValItem },
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            }),
            _c("span", { staticClass: "input-check__text" }, [
              _vm._v(
                _vm._s(
                  _vm.he ||
                    !_vm.item.name_en ||
                    _vm.item.name_en === "undefined"
                    ? _vm.item.name
                    : _vm.item.name_en
                ) +
                  "(" +
                  _vm._s(_vm.item.barcode) +
                  ")"
              ),
            ]),
          ],
          1
        ),
        _vm.isOpen
          ? [
              _c("div", { staticClass: "popup-worper" }, [
                _c("div", { ref: "popup", staticClass: "popup" }, [
                  _c(
                    "div",
                    { staticClass: "checks" },
                    _vm._l(_vm.extras, function (extra) {
                      return _c(
                        "label",
                        { key: extra.id, staticClass: "input-check__label" },
                        [
                          _c("checkbox", {
                            attrs: { inline: "", name: "" + extra.id },
                            on: {
                              input: function ($event) {
                                return _vm.setValItem(true)
                              },
                            },
                            model: {
                              value: _vm.extrasCheck[extra.id],
                              callback: function ($$v) {
                                _vm.$set(_vm.extrasCheck, extra.id, $$v)
                              },
                              expression: "extrasCheck[extra.id]",
                            },
                          }),
                          _c("span", {
                            staticClass: "input-check__text",
                            domProps: {
                              textContent: _vm._s(
                                _vm.he ||
                                  !extra.name_en ||
                                  extra.name_en === "undefined"
                                  ? extra.name
                                  : _vm.item.name_en
                              ),
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }