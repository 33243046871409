var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    { staticClass: "add-surplus-dialog", on: { close: _vm.close } },
    [
      _c("div", { staticClass: "custom" }, [
        _c("div", { staticClass: "flex " }, [
          _c("section", {
            staticClass: "min-btn",
            domProps: { textContent: _vm._s(_vm.customNum) },
          }),
        ]),
        _c(
          "section",
          { staticClass: "grid-nums" },
          [
            _vm._l(9, function (num) {
              return _c("button", {
                key: num,
                staticClass: "btn-dig",
                domProps: { textContent: _vm._s(num) },
                on: {
                  click: function ($event) {
                    return _vm.addDig(num)
                  },
                },
              })
            }),
            _c("br"),
            _c("button", {
              staticClass: "btn-dig",
              domProps: { textContent: _vm._s("0") },
              on: {
                click: function ($event) {
                  return _vm.addDig(0)
                },
              },
            }),
            _c(
              "button",
              { staticClass: "btn-dig", on: { click: _vm.removeDig } },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "34",
                      height: "24",
                      viewBox: "0 0 34 24",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      staticClass: "main-second-fill",
                      attrs: {
                        "fill-rule": "evenodd",
                        "clip-rule": "evenodd",
                        d: "M34 3.1007V20.8993C34 22.6118 32.6136 24 30.9115 24H12.3636L0 12L12.3636 0H30.9115C32.6172 0 34 1.39408 34 3.1007Z",
                      },
                    }),
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        "clip-rule": "evenodd",
                        d: "M20.1572 10.9096L15.1896 6.00049L14.0857 7.0914L19.0533 12.0005L14.0857 16.9096L15.1896 18.0005L20.1572 13.0914L25.1247 18.0005L26.2286 16.9096L21.261 12.0005L26.2286 7.0914L25.1247 6.00049L20.1572 10.9096Z",
                        fill: "#f1eff5",
                      },
                    }),
                  ]
                ),
              ]
            ),
          ],
          2
        ),
      ]),
      _c("div", { style: { "text-align": "center", "margin-top": "50px" } }, [
        _c("button", {
          staticClass: "btngreen",
          domProps: { textContent: _vm._s(_vm.$t("Approve")) },
          on: { click: _vm.send },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }