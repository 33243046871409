var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    [
      _c(
        "section",
        { staticClass: "container" },
        [
          _c("orders-filter-dialog", {
            attrs: {
              dataForFilter: _vm.dataForFilter,
              dashboard: true,
              dispatcherFilters: false,
            },
            on: { apply: _vm.filterApplied },
            model: {
              value: _vm.isFilterOpen,
              callback: function ($$v) {
                _vm.isFilterOpen = $$v
              },
              expression: "isFilterOpen",
            },
          }),
          _vm.canViewDashboard && _vm.restaurantId != 0
            ? _c("div", { staticClass: "page" }, [
                _c("div", { staticClass: "page__top" }, [
                  _c("h1", {
                    staticClass: "md-none",
                    domProps: { textContent: _vm._s(_vm.$t(_vm.title)) },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "filters",
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        gap: "20px",
                      },
                    },
                    [
                      _vm.branchId == 0 && !_vm.mobile
                        ? _c("div", { staticClass: "filter" }, [
                            _c("button", {
                              staticClass: "btn",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("Display per branch")
                                ),
                              },
                              on: { click: _vm.showDataPerBranch },
                            }),
                          ])
                        : _vm._e(),
                      _c("filter-by-date", {
                        attrs: { dashboard: true },
                        model: {
                          value: _vm.dateFilter,
                          callback: function ($$v) {
                            _vm.dateFilter = $$v
                          },
                          expression: "dateFilter",
                        },
                      }),
                      _vm.branchId == 0 && _vm.mobile
                        ? _c("div", { staticClass: "filter-mobile" }, [
                            _c("button", {
                              staticClass: "btn btn_border",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("Display per branch")
                                ),
                              },
                              on: { click: _vm.showDataPerBranch },
                            }),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm.filtersText
                  ? _c("div", {
                      staticClass:
                        "mt12 mb12 flex t-center j-center red-orange",
                      domProps: { textContent: _vm._s(_vm.filtersText) },
                    })
                  : _vm._e(),
                _c("div", { staticClass: "page__main" }, [
                  _c("div", { staticClass: "page__content" }, [
                    _c("div", { staticClass: "dashboard-wrap" }, [
                      _c("div", { staticClass: "dashboard" }, [
                        _vm.canViewDashboard
                          ? _c(
                              "div",
                              { staticClass: "dashboard__item" },
                              [
                                _c("div", { staticClass: "dashboard__head" }, [
                                  _c("div", {
                                    staticClass: "dashboard__title",
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Summary")),
                                    },
                                  }),
                                ]),
                                _vm.isLoad
                                  ? _c("dashbourd-loader")
                                  : _vm.topStatistics
                                  ? _c(
                                      "ul",
                                      {
                                        staticClass:
                                          "grid gap10 flex-container sb mb12",
                                      },
                                      [
                                        _c(
                                          "li",
                                          { staticClass: "flex-item" },
                                          [
                                            _c("orders-statistics-box", {
                                              attrs: {
                                                pastDates: _vm.compareDates,
                                                ordersByType: _vm.ordersByType,
                                                ordersBySource:
                                                  _vm.ordersBySource,
                                                statistic:
                                                  _vm.orders_statisticBox,
                                                refunds:
                                                  _vm.canceled_statisticBox,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "li",
                                          { staticClass: "flex-item" },
                                          [
                                            _c("payments-statistics-box", {
                                              attrs: {
                                                pastDates: _vm.lastData,
                                                bruto:
                                                  _vm.orders_statisticBox.bruto,
                                                forDates: _vm.compareDates,
                                                statistic:
                                                  _vm.payments_statisticBox,
                                                allPayments: _vm.payments,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "li",
                                          { staticClass: "flex-item" },
                                          [
                                            _c("laber-cost-statistic-box", {
                                              attrs: {
                                                pastDates: _vm.compareDates,
                                                statistic:
                                                  _vm.laber_cost_statisticBox,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "li",
                                          { staticClass: "flex-item" },
                                          [
                                            _c("club-statistic-box", {
                                              attrs: {
                                                pastDates: _vm.compareDates,
                                                statistic:
                                                  _vm.club_statisticBox,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "li",
                                          { staticClass: "flex-item" },
                                          [
                                            _c("items-statistics-box", {
                                              attrs: {
                                                items: _vm.bestSellerProduct,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm.showPerBranch
        ? _c("show-dashboard-per-branch", {
            attrs: { items: _vm.itemsPerBranch },
            on: {
              close: function ($event) {
                _vm.showPerBranch = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }