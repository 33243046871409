var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "catalog-editor-edit-group" },
    [
      _c("base-dialog-tabs", {
        staticClass: "catalog-editor-edit-group__tabs",
        attrs: { width: "680px", "tab-list": ["Details", "Availability"] },
        on: { close: _vm.exit },
        scopedSlots: _vm._u([
          {
            key: "tab0",
            fn: function () {
              return [
                _c("div", { staticClass: "form-lang" }, [
                  _c(
                    "form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.saveExtraCategory.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "form-site" }, [
                        _c(
                          "div",
                          { staticClass: "form-site__group name-edit" },
                          [
                            _c("div", { staticClass: "form-site__field" }, [
                              _c("div", {
                                staticClass:
                                  "form-site__label d-md-block d-none",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Name")),
                                },
                              }),
                              _c("div", { staticClass: "form-site__input" }, [
                                !_vm.english
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.extraCategoryToEdit.name,
                                          expression:
                                            "extraCategoryToEdit.name",
                                        },
                                      ],
                                      ref: "name",
                                      attrs: {
                                        placeholder: _vm.$t("Level name"),
                                        type: "text",
                                      },
                                      domProps: {
                                        value: _vm.extraCategoryToEdit.name,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.extraCategoryToEdit,
                                            "name",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    })
                                  : _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.extraCategoryToEdit.name_en,
                                          expression:
                                            "extraCategoryToEdit.name_en",
                                        },
                                      ],
                                      ref: "name",
                                      attrs: {
                                        placeholder: _vm.$t("Level name"),
                                        type: "text",
                                      },
                                      domProps: {
                                        value: _vm.extraCategoryToEdit.name_en,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.extraCategoryToEdit,
                                            "name_en",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "form-site__field wunset" },
                              [
                                _c("div", {
                                  staticClass: "form-site__label h",
                                }),
                                _c("div", { staticClass: "lang lang_grey" }, [
                                  _c("div", {
                                    staticClass: "lang__item",
                                    class: { active: _vm.english },
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("English")),
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.english = true
                                      },
                                    },
                                  }),
                                  _c("div", {
                                    staticClass: "lang__item",
                                    class: { active: !_vm.english },
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Hebrew")),
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.english = false
                                      },
                                    },
                                  }),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "form-site__field" }, [
                          !_vm.english
                            ? _c("div", {
                                staticClass: "form-site__label",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("description")),
                                },
                              })
                            : _vm.english
                            ? _c("div", {
                                staticClass: "form-site__label",
                                domProps: {
                                  textContent: _vm._s("description"),
                                },
                              })
                            : _vm._e(),
                          _c("div", { staticClass: "form-site__input" }, [
                            !_vm.english
                              ? _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.extraCategoryToEdit.notes,
                                      expression: "extraCategoryToEdit.notes",
                                    },
                                  ],
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "Write your description"
                                    ),
                                  },
                                  domProps: {
                                    value: _vm.extraCategoryToEdit.notes,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.extraCategoryToEdit,
                                        "notes",
                                        $event.target.value
                                      )
                                    },
                                  },
                                })
                              : _vm.english
                              ? _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.extraCategoryToEdit.notes_en,
                                      expression:
                                        "extraCategoryToEdit.notes_en",
                                    },
                                  ],
                                  attrs: {
                                    placeholder: "Write your description",
                                  },
                                  domProps: {
                                    value: _vm.extraCategoryToEdit.notes_en,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.extraCategoryToEdit,
                                        "notes_en",
                                        $event.target.value
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-site__content" }, [
                          _c("div", { staticClass: "form-site__field" }, [
                            _c("div", {
                              staticClass: "form-site__label",
                              domProps: { textContent: _vm._s(_vm.$t("Type")) },
                            }),
                            _c("div", { staticClass: "form-site__group" }, [
                              _c(
                                "div",
                                { staticClass: "form-site__field" },
                                [
                                  _c("select-box", {
                                    attrs: {
                                      width: "100%",
                                      widthHeader: "100%",
                                      options: [
                                        { value: 0, label: _vm.$t("Checkbox") },
                                        { value: 1, label: _vm.$t("Counter") },
                                        {
                                          value: 2,
                                          label: _vm.$t("Pizza topping"),
                                        },
                                      ],
                                    },
                                    model: {
                                      value:
                                        _vm.extraCategoryToEdit.select_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.extraCategoryToEdit,
                                          "select_type",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "extraCategoryToEdit.select_type",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "form-site__field flex a-center",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "form-site__group" },
                                    [
                                      _vm.extraCategoryToEdit.select_type != 2
                                        ? _c(
                                            "div",
                                            { staticClass: "input-check" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "input-check__label",
                                                },
                                                [
                                                  _c("checkbox", {
                                                    attrs: { name: "is_free" },
                                                    model: {
                                                      value:
                                                        _vm.extraCategoryToEdit
                                                          .grouping,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.extraCategoryToEdit,
                                                          "grouping",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "extraCategoryToEdit.grouping",
                                                    },
                                                  }),
                                                  _c("span", {
                                                    staticClass:
                                                      "input-check__text",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t(
                                                          "Merge identical items"
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "input-check" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "input-check__label",
                                            },
                                            [
                                              _c("checkbox", {
                                                attrs: { name: "is_free" },
                                                model: {
                                                  value:
                                                    _vm.extraCategoryToEdit
                                                      .print_all_extras,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.extraCategoryToEdit,
                                                      "print_all_extras",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "extraCategoryToEdit.print_all_extras",
                                                },
                                              }),
                                              _c("span", {
                                                staticClass:
                                                  "input-check__text",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t("Print all extras")
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "input-check" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "input-check__label",
                                            },
                                            [
                                              _c("checkbox", {
                                                attrs: { name: "is_free" },
                                                model: {
                                                  value:
                                                    _vm.extraCategoryToEdit
                                                      .collapsed,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.extraCategoryToEdit,
                                                      "collapsed",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "extraCategoryToEdit.collapsed",
                                                },
                                              }),
                                              _c("span", {
                                                staticClass:
                                                  "input-check__text",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t("Collapsed display")
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "form-site__group" }, [
                            _c("div", { staticClass: "form-site__field" }, [
                              _c(
                                "div",
                                { staticClass: "form-site__group" },
                                [
                                  _c("form-site-field-text", {
                                    attrs: {
                                      title: "Minimum",
                                      placeholder: "0",
                                      type: "number",
                                    },
                                    model: {
                                      value: _vm.extraCategoryToEdit.min_extras,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.extraCategoryToEdit,
                                          "min_extras",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression:
                                        "extraCategoryToEdit.min_extras",
                                    },
                                  }),
                                  _c("form-site-field-text", {
                                    attrs: {
                                      title: "Maximum",
                                      placeholder: "0",
                                      type: "number",
                                    },
                                    model: {
                                      value: _vm.extraCategoryToEdit.max_extras,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.extraCategoryToEdit,
                                          "max_extras",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression:
                                        "extraCategoryToEdit.max_extras",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "form-site__field" }, [
                              _c(
                                "div",
                                { staticClass: "form-site__group" },
                                [
                                  _c("form-site-field-text", {
                                    attrs: {
                                      title: "How many?",
                                      placeholder: "0",
                                      type: "number",
                                    },
                                    model: {
                                      value: _vm.extraCategoryToEdit.how_many,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.extraCategoryToEdit,
                                          "how_many",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression:
                                        "extraCategoryToEdit.how_many",
                                    },
                                  }),
                                  _c("form-site-field-text", {
                                    attrs: {
                                      title: "How much?",
                                      placeholder: "0",
                                      type: "number",
                                    },
                                    model: {
                                      value: _vm.extraCategoryToEdit.how_much,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.extraCategoryToEdit,
                                          "how_much",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression:
                                        "extraCategoryToEdit.how_much",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm.extraCategoryToEdit.select_type === 2
                              ? _c("div", { staticClass: "form-site__field" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-site__group pis24" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "input-check" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "input-check__label",
                                            },
                                            [
                                              _c("checkbox", {
                                                attrs: { name: "is_free" },
                                                model: {
                                                  value:
                                                    _vm.extraCategoryToEdit
                                                      .extras_per_slice,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.extraCategoryToEdit,
                                                      "extras_per_slice",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "extraCategoryToEdit.extras_per_slice",
                                                },
                                              }),
                                              _c("span", {
                                                staticClass:
                                                  "input-check__text",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t("Maximum per slice")
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm.extraCategoryToEdit.select_type === 2
                              ? _c("div", { staticClass: "form-site__field" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-site__group pis24" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "input-check" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "input-check__label",
                                            },
                                            [
                                              _c("checkbox", {
                                                attrs: { name: "is_free" },
                                                model: {
                                                  value:
                                                    _vm.extraCategoryToEdit
                                                      .pay_per_slice,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.extraCategoryToEdit,
                                                      "pay_per_slice",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "extraCategoryToEdit.pay_per_slice",
                                                },
                                              }),
                                              _c("span", {
                                                staticClass:
                                                  "input-check__text",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "Divide extras price"
                                                    )
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "history-product extra-category-depend-on-items-list",
                            },
                            [
                              _c("h5", [
                                _vm._v(_vm._s(_vm.$t("Show only if chosen"))),
                              ]),
                              _c(
                                "div",
                                { staticClass: "history-product__item_list" },
                                _vm._l(_vm.dependOn, function (dep) {
                                  return _c(
                                    "div",
                                    {
                                      key: dep.extra_id,
                                      staticClass: "history-product__item",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "history-product__info",
                                        },
                                        [
                                          _c("div", {
                                            staticClass:
                                              "history-product__title",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.extraNameWithMakat(
                                                  dep.extra
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "history-product__action",
                                        },
                                        [
                                          dep.extra
                                            ? _c("bin-btn", {
                                                staticClass: "mie8",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeDepExtra(
                                                      dep.extra.id
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                              _c("div", { staticClass: "line-more" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "line-more__content",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.addExtraDependency.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "line-more__icon" },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            name: "ic-plus",
                                            width: "12",
                                            height: "12",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("span", {
                                      staticClass: "line-more__text",
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Add")),
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "history-product extra-category-items-list",
                            },
                            [
                              _c("h5", [_vm._v(_vm._s(_vm.$t("Items")))]),
                              _c(
                                "draggable",
                                {
                                  key: _vm.draggableKey,
                                  staticClass: "history-product__item_list",
                                  attrs: { tag: "div", handle: ".drag-hamdle" },
                                  on: { end: _vm.moveExtraCategory },
                                },
                                _vm._l(_vm.details, function (detail) {
                                  return _c(
                                    "div",
                                    {
                                      key: _vm.generateDetailId(detail),
                                      staticClass: "history-product__item",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "history-product__info",
                                        },
                                        [
                                          detail.extra
                                            ? _c("div", {
                                                staticClass:
                                                  "history-product__title",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.extraNameWithMakat(
                                                      detail.extra
                                                    )
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                          detail.tag
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "history-product__title-wrapper",
                                                },
                                                [
                                                  _c("tag-products-list-dd", {
                                                    attrs: { tag: detail.tag },
                                                  }),
                                                  _c("div", {
                                                    staticClass:
                                                      "history-product__title",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.tagName(
                                                          detail.tag,
                                                          _vm.$t("Tag")
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "history-product__action",
                                        },
                                        [
                                          !detail.tag
                                            ? _c("select-box", {
                                                staticClass: "mie8",
                                                attrs: {
                                                  maxWidth: "200px",
                                                  value: detail.defined_as,
                                                  options: [
                                                    "Changeable",
                                                    "Constant and Required",
                                                    "Constant and not Required",
                                                  ].map(function (
                                                    label,
                                                    value
                                                  ) {
                                                    return {
                                                      label: _vm.$t(label),
                                                      value: value,
                                                    }
                                                  }),
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.setDetailDefinedAs(
                                                      detail,
                                                      $event
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          _c("input", {
                                            staticClass: "mie8 w80",
                                            attrs: {
                                              type: "text",
                                              placeholder:
                                                _vm.placeholderPrice(detail),
                                            },
                                            domProps: { value: detail.price },
                                            on: {
                                              input: function ($event) {
                                                return _vm.setDetailPrice(
                                                  detail,
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          detail.extra
                                            ? _c("bin-btn", {
                                                staticClass: "mie8",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeExtra(
                                                      detail.extra.id
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          detail.tag
                                            ? _c("bin-btn", {
                                                staticClass: "mie8",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeTag(
                                                      detail.tag.id
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "drag-hamdle mie16",
                                              style: {
                                                display: "flex",
                                                "justify-content": "center",
                                                width: "25px",
                                              },
                                            },
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  name: "ic-double-more",
                                                  width: "8",
                                                  height: "14",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                              _c("div", { staticClass: "line-more" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "line-more__content",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.addExtra.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "line-more__icon" },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            name: "ic-plus",
                                            width: "12",
                                            height: "12",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("span", {
                                      staticClass: "line-more__text",
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Add")),
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "tab1",
            fn: function () {
              return [
                _c("availability-tab", {
                  attrs: { availability: _vm.extraCategoryToEdit.availability },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "btns",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "form-site__btn d-none d-md-flex bet" },
                  [
                    _vm.extraCategoryToEdit.id ||
                    _vm.extraCategoryToEdit.groupTmpId
                      ? _c("remove-btn", {
                          on: { click: _vm.removeExtraCategory },
                        })
                      : _c("div", {}),
                    _c("button", {
                      staticClass: "btn",
                      domProps: { textContent: _vm._s(_vm.$t("save")) },
                      on: { click: _vm.saveExtraCategory },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
                  _c(
                    "button",
                    {
                      staticClass: "bin-btn",
                      style: {
                        bottom: "27px",
                        "margin-inline-end": "7px",
                        width: "65px",
                        height: "65px",
                      },
                      on: { click: _vm.removeExtraCategory },
                    },
                    [
                      _c("svg-icon", {
                        attrs: { name: "ic-bin", width: "18", height: "20" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "save-btn",
                      on: { click: _vm.saveExtraCategory },
                    },
                    [
                      _c("svg-icon", {
                        staticClass: "ic-edit",
                        attrs: {
                          name: "ic-save-btn",
                          width: "112",
                          height: "112",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.popup
        ? _c("catalog-editor-add-extra", {
            attrs: { items: _vm.details, "show-tags": true },
            on: { save: _vm.saveExtra, close: _vm.closePopup },
          })
        : _vm._e(),
      _vm.dependencyPopup
        ? _c("catalog-editor-add-extra", {
            attrs: { items: _vm.dependOn, "show-tags": false },
            on: {
              save: _vm.saveExtraDependencies,
              close: _vm.closeDependencyPopup,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }