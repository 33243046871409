var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "line-more" }, [
    _c(
      "button",
      _vm._g(
        _vm._b(
          { staticClass: "line-more__content" },
          "button",
          _vm.$attrs,
          false
        ),
        _vm.$listeners
      ),
      [
        _c(
          "span",
          { staticClass: "line-more__icon" },
          [
            _c("svg-icon", {
              attrs: { name: "ic-plus", width: "12", height: "12" },
            }),
          ],
          1
        ),
        _c("span", {
          staticClass: "line-more__text",
          domProps: { textContent: _vm._s(_vm.$t("Add")) },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }