var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "new-item-info" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
          },
        },
      },
      [
        _c("div", { staticClass: "form-site" }, [
          _c("div", { staticClass: "form-site__content" }, [
            _c("div", { staticClass: "new-item-info__top" }, [
              _c("div", {
                staticClass: "new-item-info__head",
                domProps: {
                  textContent: _vm._s(
                    _vm.he ||
                      !_vm.itemCopy.name_en ||
                      _vm.itemCopy.name_en === "undefined"
                      ? _vm.itemCopy.name
                      : _vm.itemCopy.name_en
                  ),
                },
              }),
            ]),
            _c("div", { staticClass: "form-site__field" }, [
              _c("div", { staticClass: "form-site-search" }, [
                _c("div", { staticClass: "form-site-search__field" }, [
                  _c("input", {
                    attrs: { type: "text", placeholder: "Search..." },
                  }),
                  _c(
                    "div",
                    { staticClass: "form-site-search__btn" },
                    [
                      _c("svg-icon", {
                        staticClass: "ic-stroke",
                        attrs: { name: "ic-search", width: "19", height: "19" },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "form-site__field" }, [
              _c(
                "div",
                { staticClass: "form-site__check form-site__check_col" },
                [
                  _c("div", { staticClass: "input-check" }, [
                    _c("input", {
                      attrs: { type: "checkbox", id: "check-search1" },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "input-check__label",
                        attrs: { for: "check-search1" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "input-check__mark" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-check",
                                width: "21",
                                height: "16",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "input-check__text" }, [
                          _vm._v("Cake (00115)"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "input-check" }, [
                    _c("input", {
                      attrs: {
                        type: "checkbox",
                        id: "check-search2",
                        checked: "checked",
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "input-check__label",
                        attrs: { for: "check-search2" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "input-check__mark" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-check",
                                width: "21",
                                height: "16",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "input-check__text" }, [
                          _vm._v("Onion (00013)"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "input-check" }, [
                    _c("input", {
                      attrs: {
                        type: "checkbox",
                        id: "check-search3",
                        checked: "checked",
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "input-check__label",
                        attrs: { for: "check-search3" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "input-check__mark" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-check",
                                width: "21",
                                height: "16",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "input-check__text" }, [
                          _vm._v("Apple pie (00124)"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "input-check" }, [
                    _c("input", {
                      attrs: { type: "checkbox", id: "check-search4" },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "input-check__label",
                        attrs: { for: "check-search4" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "input-check__mark" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-check",
                                width: "21",
                                height: "16",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "input-check__text" }, [
                          _vm._v("Apple (00011)"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "input-check" }, [
                    _c("input", {
                      attrs: { type: "checkbox", id: "check-search5" },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "input-check__label",
                        attrs: { for: "check-search5" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "input-check__mark" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-check",
                                width: "21",
                                height: "16",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "input-check__text" }, [
                          _vm._v("Truffle (00134)"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "input-check" }, [
                    _c("input", {
                      attrs: {
                        type: "checkbox",
                        id: "check-search6",
                        checked: "checked",
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "input-check__label",
                        attrs: { for: "check-search6" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "input-check__mark" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-check",
                                width: "21",
                                height: "16",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "input-check__text" }, [
                          _vm._v("Sauce ketchup (00215)"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "input-check" }, [
                    _c("input", {
                      attrs: {
                        type: "checkbox",
                        id: "check-search7",
                        checked: "checked",
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "input-check__label",
                        attrs: { for: "check-search7" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "input-check__mark" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-check",
                                width: "21",
                                height: "16",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "input-check__text" }, [
                          _vm._v("Hawaiian (00131)"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "input-check" }, [
                    _c("input", {
                      attrs: { type: "checkbox", id: "check-search8" },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "input-check__label",
                        attrs: { for: "check-search8" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "input-check__mark" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-check",
                                width: "21",
                                height: "16",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "input-check__text" }, [
                          _vm._v("French fries (00110)"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "input-check" }, [
                    _c("input", {
                      attrs: { type: "checkbox", id: "check-search9" },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "input-check__label",
                        attrs: { for: "check-search9" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "input-check__mark" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-check",
                                width: "21",
                                height: "16",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "input-check__text" }, [
                          _vm._v("Cake (00122)"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "input-check" }, [
                    _c("input", {
                      attrs: { type: "checkbox", id: "check-search10" },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "input-check__label",
                        attrs: { for: "check-search10" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "input-check__mark" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-check",
                                width: "21",
                                height: "16",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "input-check__text" }, [
                          _vm._v("Milk (00151)"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "input-check" }, [
                    _c("input", {
                      attrs: { type: "checkbox", id: "check-search11" },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "input-check__label",
                        attrs: { for: "check-search11" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "input-check__mark" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-check",
                                width: "21",
                                height: "16",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "input-check__text" }, [
                          _vm._v("Cake (00115)"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "input-check" }, [
                    _c("input", {
                      attrs: {
                        type: "checkbox",
                        id: "check-search12",
                        checked: "checked",
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "input-check__label",
                        attrs: { for: "check-search12" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "input-check__mark" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-check",
                                width: "21",
                                height: "16",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "input-check__text" }, [
                          _vm._v("Onion (00111)"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "input-check" }, [
                    _c("input", {
                      attrs: {
                        type: "checkbox",
                        id: "check-search13",
                        checked: "checked",
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "input-check__label",
                        attrs: { for: "check-search13" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "input-check__mark" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-check",
                                width: "21",
                                height: "16",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "input-check__text" }, [
                          _vm._v("Apple pie (00124)"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "input-check" }, [
                    _c("input", {
                      attrs: { type: "checkbox", id: "check-search14" },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "input-check__label",
                        attrs: { for: "check-search14" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "input-check__mark" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-check",
                                width: "21",
                                height: "16",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "input-check__text" }, [
                          _vm._v("Apple (00005)"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "input-check" }, [
                    _c("input", {
                      attrs: { type: "checkbox", id: "check-search15" },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "input-check__label",
                        attrs: { for: "check-search15" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "input-check__mark" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-check",
                                width: "21",
                                height: "16",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "input-check__text" }, [
                          _vm._v("Truffle (00134)"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "input-check" }, [
                    _c("input", {
                      attrs: {
                        type: "checkbox",
                        id: "check-search16",
                        checked: "checked",
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "input-check__label",
                        attrs: { for: "check-search16" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "input-check__mark" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-check",
                                width: "21",
                                height: "16",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "input-check__text" }, [
                          _vm._v("Sauce ketchup (00015)"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "input-check" }, [
                    _c("input", {
                      attrs: {
                        type: "checkbox",
                        id: "check-search17",
                        checked: "checked",
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "input-check__label",
                        attrs: { for: "check-search17" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "input-check__mark" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-check",
                                width: "21",
                                height: "16",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "input-check__text" }, [
                          _vm._v("Hawaiian (00131)"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "input-check" }, [
                    _c("input", {
                      attrs: { type: "checkbox", id: "check-search18" },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "input-check__label",
                        attrs: { for: "check-search18" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "input-check__mark" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-check",
                                width: "21",
                                height: "16",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "input-check__text" }, [
                          _vm._v("French fries (00015)"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "input-check" }, [
                    _c("input", {
                      attrs: { type: "checkbox", id: "check-search19" },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "input-check__label",
                        attrs: { for: "check-search19" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "input-check__mark" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-check",
                                width: "21",
                                height: "16",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "input-check__text" }, [
                          _vm._v("Cake (00122)"),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }