var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "billing-details full-screen" }, [
    _c("main", [
      _c("section", { staticClass: "container" }, [
        _c("div", { staticClass: "page" }, [
          _c(
            "div",
            { staticClass: "page__main" },
            [
              _c(
                "router-link",
                { staticClass: "page__back", attrs: { to: "../settings" } },
                [
                  _c("svg-icon", {
                    attrs: {
                      name: "ic-close-table-arrow",
                      width: "34",
                      height: "34",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "page__top" }, [
                _c("h1", {
                  domProps: { textContent: _vm._s(_vm.$t("Billing details")) },
                }),
              ]),
              _c("section", { staticClass: "main-settings" }, [
                _c("div", { staticClass: "main-settings__sect" }, [
                  _c("div", { staticClass: "main-settings__content" }, [
                    _c("div", { staticClass: "main-settings__form" }, [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "form-site" }, [
                            _c(
                              "div",
                              { staticClass: "form-site__content" },
                              [
                                _c("form-site-field-text", {
                                  attrs: {
                                    error: _vm.errors.includes("company"),
                                    title: "Company name",
                                  },
                                  on: { input: _vm.setChange },
                                  model: {
                                    value: _vm.billingObject.company,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.billingObject,
                                        "company",
                                        $$v
                                      )
                                    },
                                    expression: "billingObject.company",
                                  },
                                }),
                                _c("form-site-field-text", {
                                  attrs: {
                                    error: _vm.errors.includes("phone"),
                                    title: "Phone",
                                  },
                                  on: { input: _vm.setChange },
                                  model: {
                                    value: _vm.billingObject.phone,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.billingObject, "phone", $$v)
                                    },
                                    expression: "billingObject.phone",
                                  },
                                }),
                                _c("form-site-field-text", {
                                  attrs: {
                                    error:
                                      _vm.errors.includes("company_number"),
                                    title: "Business number",
                                  },
                                  on: { input: _vm.setChange },
                                  model: {
                                    value: _vm.billingObject.company_number,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.billingObject,
                                        "company_number",
                                        $$v
                                      )
                                    },
                                    expression: "billingObject.company_number",
                                  },
                                }),
                                _c("form-site-field-text", {
                                  attrs: { title: "Accounting manager email" },
                                  on: { input: _vm.setChange },
                                  model: {
                                    value:
                                      _vm.billingObject
                                        .accounting_manager_email,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.billingObject,
                                        "accounting_manager_email",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "billingObject.accounting_manager_email",
                                  },
                                }),
                                _c("form-site-field-select-box", {
                                  attrs: {
                                    title: "Business type",
                                    options: _vm.typesOpts,
                                    width: "100%",
                                    widthHeader: "100%",
                                  },
                                  on: { input: _vm.setChange },
                                  model: {
                                    value: _vm.billingObject.business_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.billingObject,
                                        "business_type",
                                        $$v
                                      )
                                    },
                                    expression: "billingObject.business_type",
                                  },
                                }),
                                _c("form-site-field-text", {
                                  attrs: {
                                    title: "TAX",
                                    type: "number",
                                    sign: "%",
                                  },
                                  on: { input: _vm.setChange },
                                  model: {
                                    value: _vm.billingObject.tax,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.billingObject, "tax", $$v)
                                    },
                                    expression: "billingObject.tax",
                                  },
                                }),
                                _c("form-site-field-text", {
                                  attrs: { title: "Tax withholding" },
                                  on: { input: _vm.setChange },
                                  model: {
                                    value: _vm.billingObject.tax_withholding,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.billingObject,
                                        "tax_withholding",
                                        $$v
                                      )
                                    },
                                    expression: "billingObject.tax_withholding",
                                  },
                                }),
                                _c("form-site-field-text", {
                                  attrs: { title: "Social security deposit" },
                                  on: { input: _vm.setChange },
                                  model: {
                                    value:
                                      _vm.billingObject.social_security_deposit,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.billingObject,
                                        "social_security_deposit",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "billingObject.social_security_deposit",
                                  },
                                }),
                                _vm.billingObject.business_type === 4
                                  ? [
                                      _c("form-site-field-text", {
                                        attrs: { title: "Siret number" },
                                        on: { input: _vm.setChange },
                                        model: {
                                          value: _vm.billingObject.siret,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.billingObject,
                                              "siret",
                                              $$v
                                            )
                                          },
                                          expression: "billingObject.siret",
                                        },
                                      }),
                                      _c("form-site-field-text", {
                                        attrs: { title: "Siren number" },
                                        on: { input: _vm.setChange },
                                        model: {
                                          value: _vm.billingObject.siren,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.billingObject,
                                              "siren",
                                              $$v
                                            )
                                          },
                                          expression: "billingObject.siren",
                                        },
                                      }),
                                      _c("form-site-field-text", {
                                        attrs: { title: "Ape code" },
                                        on: { input: _vm.setChange },
                                        model: {
                                          value: _vm.billingObject.ape_code,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.billingObject,
                                              "ape_code",
                                              $$v
                                            )
                                          },
                                          expression: "billingObject.ape_code",
                                        },
                                      }),
                                    ]
                                  : _vm._e(),
                                _c("form-site-field-text", {
                                  attrs: { title: "Zip code" },
                                  on: { input: _vm.setChange },
                                  model: {
                                    value: _vm.billingObject.zip_code,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.billingObject,
                                        "zip_code",
                                        $$v
                                      )
                                    },
                                    expression: "billingObject.zip_code",
                                  },
                                }),
                              ],
                              2
                            ),
                            _c("div", { staticClass: "hr" }),
                            _c("div", {
                              staticClass: "swich-block-wrap__title",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("Document numbering")
                                ),
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "form-site__content" },
                              [
                                _c("form-site-field-text", {
                                  attrs: { title: "Tax invoice", optional: "" },
                                  on: { input: _vm.setChange },
                                  model: {
                                    value: _vm.billingObject.tax_invoice,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.billingObject,
                                        "tax_invoice",
                                        $$v
                                      )
                                    },
                                    expression: "billingObject.tax_invoice",
                                  },
                                }),
                                _c("form-site-field-text", {
                                  attrs: {
                                    title: "Tax invoice receipt",
                                    optional: "",
                                  },
                                  on: { input: _vm.setChange },
                                  model: {
                                    value:
                                      _vm.billingObject.tax_invoice_receipt,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.billingObject,
                                        "tax_invoice_receipt",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "billingObject.tax_invoice_receipt",
                                  },
                                }),
                                _c("form-site-field-text", {
                                  attrs: { title: "Receipt", optional: "" },
                                  on: { input: _vm.setChange },
                                  model: {
                                    value: _vm.billingObject.receipt,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.billingObject,
                                        "receipt",
                                        $$v
                                      )
                                    },
                                    expression: "billingObject.receipt",
                                  },
                                }),
                                _c("form-site-field-text", {
                                  attrs: {
                                    title: "Refund invoice",
                                    optional: "",
                                  },
                                  on: { input: _vm.setChange },
                                  model: {
                                    value: _vm.billingObject.refund_invoice,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.billingObject,
                                        "refund_invoice",
                                        $$v
                                      )
                                    },
                                    expression: "billingObject.refund_invoice",
                                  },
                                }),
                                _c("form-site-field-text", {
                                  attrs: {
                                    title: "Proforma invoice",
                                    optional: "",
                                  },
                                  on: { input: _vm.setChange },
                                  model: {
                                    value: _vm.billingObject.proforma_invoice,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.billingObject,
                                        "proforma_invoice",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "billingObject.proforma_invoice",
                                  },
                                }),
                                _c("form-site-field-text", {
                                  attrs: {
                                    title: "Delivery document",
                                    optional: "",
                                  },
                                  on: { input: _vm.setChange },
                                  model: {
                                    value: _vm.billingObject.delivery_document,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.billingObject,
                                        "delivery_document",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "billingObject.delivery_document",
                                  },
                                }),
                                _c("form-site-field-text", {
                                  attrs: {
                                    title: "Payment confirmation",
                                    optional: "",
                                  },
                                  on: { input: _vm.setChange },
                                  model: {
                                    value: _vm.billingObject.payment_receipt,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.billingObject,
                                        "payment_receipt",
                                        $$v
                                      )
                                    },
                                    expression: "billingObject.payment_receipt",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "hr" }),
                            _c("div", {
                              staticClass: "swich-block-wrap__title",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Address")),
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "form-site__content" },
                              [
                                _c("form-site-field-text", {
                                  attrs: {
                                    error: _vm.errors.includes("address1"),
                                    title: "Country",
                                  },
                                  on: { input: _vm.setChange },
                                  model: {
                                    value: _vm.billingObject.address1,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.billingObject,
                                        "address1",
                                        $$v
                                      )
                                    },
                                    expression: "billingObject.address1",
                                  },
                                }),
                                _c("form-site-field-text", {
                                  attrs: {
                                    error: _vm.errors.includes("city"),
                                    title: "City",
                                  },
                                  on: { input: _vm.setChange },
                                  model: {
                                    value: _vm.billingObject.city,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.billingObject, "city", $$v)
                                    },
                                    expression: "billingObject.city",
                                  },
                                }),
                                _vm.billingObject.business_type === 4
                                  ? _c("form-site-field-text", {
                                      attrs: {
                                        error: _vm.errors.includes("state"),
                                        title: "State",
                                      },
                                      on: { input: _vm.setChange },
                                      model: {
                                        value: _vm.billingObject.state,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.billingObject,
                                            "state",
                                            $$v
                                          )
                                        },
                                        expression: "billingObject.state",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "hr" }),
                            _vm.billingObject.business_type === 4 && _vm.nf525
                              ? _c(
                                  "div",
                                  { staticClass: "form-site__content" },
                                  [
                                    _c("form-site-field-text", {
                                      attrs: {
                                        error: _vm.errors.includes("version"),
                                        title: "Version",
                                        readonly: true,
                                      },
                                      on: { input: _vm.setChange },
                                      model: {
                                        value: _vm.nf525.version,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.nf525, "version", $$v)
                                        },
                                        expression: "nf525.version",
                                      },
                                    }),
                                    _c("form-site-field-text", {
                                      attrs: {
                                        error:
                                          _vm.errors.includes("serial_number"),
                                        title: "Serial number",
                                        readonly: true,
                                      },
                                      on: { input: _vm.setChange },
                                      model: {
                                        value: _vm.nf525.serial_number,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.nf525,
                                            "serial_number",
                                            $$v
                                          )
                                        },
                                        expression: "nf525.serial_number",
                                      },
                                    }),
                                    _c("form-site-field-text", {
                                      attrs: {
                                        error:
                                          _vm.errors.includes(
                                            "use_license_number"
                                          ),
                                        title: "Use license number",
                                        readonly: true,
                                      },
                                      on: { input: _vm.setChange },
                                      model: {
                                        value: _vm.nf525.use_license_number,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.nf525,
                                            "use_license_number",
                                            $$v
                                          )
                                        },
                                        expression: "nf525.use_license_number",
                                      },
                                    }),
                                    _c("form-site-field-text", {
                                      attrs: {
                                        error: _vm.errors.includes("logo"),
                                        title: "Logo",
                                        readonly: true,
                                      },
                                      on: { input: _vm.setChange },
                                      model: {
                                        value: _vm.nf525.logo,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.nf525, "logo", $$v)
                                        },
                                        expression: "nf525.logo",
                                      },
                                    }),
                                    _c("form-site-field-text", {
                                      attrs: {
                                        error: _vm.errors.includes("mark"),
                                        title: "Mark",
                                        readonly: true,
                                      },
                                      on: { input: _vm.setChange },
                                      model: {
                                        value: _vm.nf525.mark,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.nf525, "mark", $$v)
                                        },
                                        expression: "nf525.mark",
                                      },
                                    }),
                                    _c("form-site-field-text", {
                                      attrs: {
                                        error:
                                          _vm.errors.includes(
                                            "certificate_number"
                                          ),
                                        title: "Certificate number",
                                        readonly: true,
                                      },
                                      on: { input: _vm.setChange },
                                      model: {
                                        value: _vm.nf525.certificate_number,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.nf525,
                                            "certificate_number",
                                            $$v
                                          )
                                        },
                                        expression: "nf525.certificate_number",
                                      },
                                    }),
                                    _c("form-site-field-text", {
                                      attrs: {
                                        error:
                                          _vm.errors.includes(
                                            "product_category"
                                          ),
                                        title: "Product category",
                                        readonly: true,
                                      },
                                      on: { input: _vm.setChange },
                                      model: {
                                        value: _vm.nf525.product_category,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.nf525,
                                            "product_category",
                                            $$v
                                          )
                                        },
                                        expression: "nf525.product_category",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm.isNf525
                ? _c("section", { staticClass: "main-settings" }, [
                    _c("div", { staticClass: "main-settings__content" }, [
                      _c("div", { staticClass: "nf525-buttons" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn",
                            attrs: { type: "button" },
                            on: { click: _vm.gotToNf525TechnicalEventsLog },
                          },
                          [_vm._v(_vm._s(_vm.$t("Technical Events Log")))]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn",
                            attrs: { type: "button" },
                            on: { click: _vm.goToNf525GrandTotals },
                          },
                          [_vm._v(_vm._s(_vm.$t("Grand Totals")))]
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm.change
          ? _c("div", { staticClass: "btn-save-container-settings" }, [
              _c(
                "button",
                { staticClass: "save-btn", on: { click: _vm.save } },
                [
                  _c("svg-icon", {
                    staticClass: "ic-edit",
                    attrs: { name: "ic-save-btn", width: "112", height: "128" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }