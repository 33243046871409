var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.survey
    ? _c("div", { staticClass: "preview-desktop" }, [
        _c(
          "div",
          { staticClass: "survey-page" },
          [
            _vm.inStart
              ? [
                  _c("div", { staticClass: "in-start" }, [
                    _c("div", {}, [
                      _c("h2", {
                        domProps: { textContent: _vm._s(_vm.survey.title) },
                      }),
                      _c("div", {
                        staticClass: "text",
                        domProps: {
                          textContent: _vm._s(_vm.survey.start_text),
                        },
                      }),
                    ]),
                    _c("div"),
                    _c("button", {
                      staticClass: "btn",
                      domProps: { textContent: _vm._s(_vm.$t("Start")) },
                      on: {
                        click: function ($event) {
                          _vm.inStart = false
                        },
                      },
                    }),
                  ]),
                ]
              : _vm.isClosed
              ? _c("div", { staticClass: "in-close" }, [
                  _c("h2", [_vm._v("תודה")]),
                  _c("div", {
                    domProps: { textContent: _vm._s(_vm.survey.end_text) },
                  }),
                  _c("div"),
                ])
              : _vm.survey.questions.length
              ? _c("div", { staticClass: "survey-page__main" }, [
                  _c(
                    "div",
                    { staticClass: "survey-page__location" },
                    _vm._l(_vm.survey.questions.length, function (num, i) {
                      return _c("div", {
                        key: num,
                        class: { active: i === _vm.location },
                        domProps: { textContent: _vm._s(num) },
                      })
                    }),
                    0
                  ),
                  _c("div", { staticClass: "survey-page__main2" }, [
                    _c(
                      "div",
                      { ref: "history", staticClass: "survey-page__history" },
                      _vm._l(_vm.history, function (msg, i) {
                        return _c("div", {
                          key: i,
                          class:
                            (msg.question ? "question" : "answer") +
                            " " +
                            (msg.opened ? "opened" : "") +
                            " " +
                            (msg.opened2 ? "opened2" : ""),
                          domProps: { textContent: _vm._s(msg.text) },
                        })
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: "survey-page__btns" },
                      [
                        _vm.survey.questions[_vm.location].type === 0
                          ? _vm._l(
                              _vm.survey.questions[_vm.location].answers,
                              function (answer) {
                                return _c("button", {
                                  key: answer.text,
                                  staticClass: "flex",
                                  domProps: {
                                    textContent: _vm._s(answer.text),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.choseAnswer(answer)
                                    },
                                  },
                                })
                              }
                            )
                          : _vm._e(),
                        _vm.survey.questions[_vm.location].type === 1
                          ? _vm._l(
                              [
                                "טוב מאוד",
                                "טוב",
                                "בסדר",
                                "לא טוב",
                                "ממש לא טוב",
                              ],
                              function (text, i) {
                                return _c(
                                  "button",
                                  {
                                    key: text,
                                    staticClass: "flex bet",
                                    on: {
                                      click: function ($event) {
                                        _vm.choseAnswer({
                                          weight: (5 - i) * 20,
                                          text: text,
                                        })
                                      },
                                    },
                                  },
                                  [
                                    _c("span", {
                                      domProps: { textContent: _vm._s(text) },
                                    }),
                                    _c("star-rating", {
                                      attrs: {
                                        "read-only": "",
                                        rating: 5 - i,
                                        "star-size": 24,
                                        "show-rating": false,
                                        rtl: "",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.survey.questions[_vm.location].type === 2
                          ? [
                              _c(
                                "form",
                                {
                                  staticClass: "survey-page__open-text",
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.openQuestionText,
                                        expression: "openQuestionText",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.openQuestionText },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.openQuestionText =
                                          $event.target.value
                                      },
                                    },
                                  }),
                                  _c(
                                    "button",
                                    { on: { click: _vm.setOpenQuestion } },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          attrs: {
                                            width: "20",
                                            height: "20",
                                            viewBox: "0 0 20 20",
                                            fill: "none",
                                            xmlns: "http://www.w3.org/2000/svg",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d: "M19.5857 9.35095L1.01474 0.779733C0.719033 0.64545 0.366184 0.725448 0.160475 0.979728C-0.046663 1.23401 -0.0538057 1.59543 0.143332 1.85685L6.25033 9.99951L0.143332 18.1422C-0.0538057 18.4036 -0.046663 18.7664 0.159046 19.0193C0.297614 19.1921 0.504752 19.285 0.714747 19.285C0.816173 19.285 0.917599 19.2636 1.01331 19.2193L19.5843 10.6481C19.8386 10.5309 20 10.2781 20 9.99951C20 9.72094 19.8386 9.46809 19.5857 9.35095Z",
                                              fill: "#979797",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                        _vm.location > 0 &&
                        !_vm.survey.questions[_vm.location].required
                          ? _c("div", { staticClass: "grid-2-btn" }, [
                              _vm.location > 0
                                ? _c(
                                    "button",
                                    { on: { click: _vm.backQuestion } },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          name: "arrow",
                                          width: "16",
                                          height: "12",
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "mis8",
                                        domProps: {
                                          textContent: _vm._s(_vm.$t("Back")),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.survey.questions[_vm.location].required
                                ? _c("button", {
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Skip")),
                                    },
                                    on: { click: _vm.skipQuestion },
                                  })
                                : _vm._e(),
                            ])
                          : [
                              _vm.location > 0
                                ? _c(
                                    "button",
                                    { on: { click: _vm.backQuestion } },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          name: "arrow",
                                          width: "16",
                                          height: "12",
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "mis8",
                                        domProps: {
                                          textContent: _vm._s(_vm.$t("Back")),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.survey.questions[_vm.location].required
                                ? _c("button", {
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Skip")),
                                    },
                                    on: { click: _vm.skipQuestion },
                                  })
                                : _vm._e(),
                            ],
                      ],
                      2
                    ),
                  ]),
                ])
              : _vm._e(),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }