var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "new-item-info" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
          },
        },
      },
      [
        _c("div", { staticClass: "form-site" }, [
          _c("div", { staticClass: "form-site__content" }, [
            _c("div", { staticClass: "new-item-info__top" }, [
              _c("div", {
                staticClass: "new-item-info__head",
                domProps: {
                  textContent: _vm._s(
                    _vm.he ||
                      !_vm.itemCopy.name_en ||
                      _vm.itemCopy.name_en === "undefined"
                      ? _vm.itemCopy.name
                      : _vm.itemCopy.name_en
                  ),
                },
              }),
            ]),
            _c("div", { staticClass: "form-site__sect" }, [
              _c(
                "div",
                { staticClass: "form-site__group" },
                _vm._l(_vm.imageTypes, function (key, i) {
                  return _c(
                    "div",
                    { key: key, staticClass: "form-site__field" },
                    [
                      _c("div", { staticClass: "form-site__input" }, [
                        _c("div", { staticClass: "edit-img" }, [
                          _c("div", {
                            staticClass: "edit-img__label",
                            domProps: {
                              textContent: _vm._s(_vm.$t(_vm.capitalize(key))),
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "edit-img__content",
                              class: {
                                "edit-img__content_empty": !_vm.imagesPath[i],
                              },
                              style: _vm.imageStyle(i),
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "edit-img__icon" },
                                [
                                  _vm.imagesPath[i]
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "edit-img__icon",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.removeImage(key)
                                            },
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "ic-plus",
                                              width: "22",
                                              height: "22",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : [
                                        _c("input", {
                                          attrs: {
                                            type: "file",
                                            accept: "image/*",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.enterImage($event, key)
                                            },
                                          },
                                        }),
                                        _c("svg-icon", {
                                          staticClass: "ic-plus",
                                          attrs: {
                                            name: "ic-photo",
                                            width: "22",
                                            height: "22",
                                          },
                                        }),
                                      ],
                                ],
                                2
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "form-site__sect" }, [
              _c("div", { staticClass: "form-site__group" }, [
                _c("div", { staticClass: "form-site__field" }, [
                  _c("div", { staticClass: "form-site__input" }, [
                    _c("div", { staticClass: "edit-img" }, [
                      _c("div", {
                        staticClass: "edit-img__label",
                        domProps: { textContent: _vm._s(_vm.$t("Gif")) },
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "edit-img__content edit-img__content_empty",
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "edit-img__icon" },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-photo",
                                  width: "24",
                                  height: "19",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }