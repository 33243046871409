var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "nf-525-technical-events-log full-screen" }, [
    _c("main", [
      _c("section", { staticClass: "container" }, [
        _c("div", { staticClass: "page" }, [
          _c(
            "div",
            { staticClass: "page__main" },
            [
              _c(
                "router-link",
                {
                  staticClass: "page__back",
                  attrs: { to: "/settings/billing-details" },
                },
                [
                  _c("svg-icon", {
                    attrs: {
                      name: "ic-close-table-arrow",
                      width: "34",
                      height: "34",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "page__content" },
            [
              _c(
                "table-list",
                _vm._b(
                  {
                    attrs: { "table-headers": _vm.tableHeaders },
                    on: {
                      changeOptions: _vm.changeOptions,
                      "on-click-line": _vm.validateTelItem,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "filter-by-date",
                        fn: function () {
                          return [
                            _c("filter-by-date", {
                              on: { input: _vm.fetchTechnicalEventsLog },
                              model: {
                                value: _vm.dateFilter,
                                callback: function ($$v) {
                                  _vm.dateFilter = $$v
                                },
                                expression: "dateFilter",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  "table-list",
                  _vm.dataTable,
                  false
                )
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }