var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: { width: "880px", title: "Create new push notification" },
      on: { close: _vm.close },
    },
    [
      _c("div", { staticClass: "popup__content" }, [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.send.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "form-sms" }, [
              _c("div", { staticClass: "form-sms__fields" }, [
                _c(
                  "div",
                  { staticClass: "form-sms__field form-sms__field_full" },
                  [
                    _c("div", { staticClass: "form-sms__label-wrap" }, [
                      _c("div", {
                        staticClass: "form-sms__label",
                        domProps: { textContent: _vm._s(_vm.$t("Message")) },
                      }),
                      _c("div", {
                        staticClass: "form-sms__msg-count",
                        domProps: {
                          textContent: _vm._s(_vm.message.length + "/134"),
                        },
                      }),
                    ]),
                    _c(
                      "div",
                      { staticClass: "form-sms-msg" },
                      [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.message,
                              expression: "message",
                            },
                          ],
                          attrs: {
                            maxlength: "134",
                            name: "message",
                            title: _vm.$t("Message"),
                            placeholder: _vm.$t("Type your message"),
                            required: "",
                          },
                          domProps: { value: _vm.message },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.message = $event.target.value
                            },
                          },
                        }),
                        _c("cs-emoji-picker", {
                          model: {
                            value: _vm.message,
                            callback: function ($$v) {
                              _vm.message = $$v
                            },
                            expression: "message",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "form-sms__btn",
                  staticStyle: {
                    "justify-content": "center",
                    "padding-top": "20px",
                  },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn_border-grad",
                      staticStyle: { display: "none" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.showSendExample.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "btn__grad-content",
                        domProps: {
                          textContent: _vm._s(_vm.$t("Send an example")),
                        },
                      }),
                    ]
                  ),
                  _c("button", {
                    staticClass: "btn",
                    attrs: { type: "submit" },
                    domProps: { textContent: _vm._s(_vm.$t("Send")) },
                  }),
                ]
              ),
            ]),
          ]
        ),
      ]),
      _vm.showSendExampleDialog
        ? _c(
            "div",
            { staticClass: "popup-add disable-scrollbars" },
            [
              _c(
                "base-dialog",
                {
                  attrs: { width: "75%", title: "Phone" },
                  on: { close: _vm.hideSendExample },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "form-site",
                      staticStyle: { margin: "40px", "text-align": "center" },
                    },
                    [
                      _c("div", { staticClass: "form-site__input" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "row",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.phone,
                                  expression: "phone",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t("Phone number"),
                                type: "number",
                              },
                              domProps: { value: _vm.phone },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.phone = $event.target.value
                                  },
                                  _vm.input,
                                ],
                              },
                            }),
                            _c(
                              "button",
                              {
                                staticClass: "survey-top__preview share",
                                on: { click: _vm.savePhone },
                              },
                              [
                                _c("svg-icon", {
                                  staticClass: "shadow",
                                  attrs: {
                                    name: "ic-save-btn",
                                    width: "80",
                                    height: "80",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }