var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "booking full-screen" }, [
    _c("main", [
      _c(
        "section",
        { staticClass: "container" },
        [
          _vm.popup === 2
            ? _c("add-new-area-table", {
                attrs: { table: _vm.tableInEdit },
                on: {
                  close: _vm.closePopup,
                  save: _vm.saveTable,
                  remove: _vm.removeTable,
                },
              })
            : _vm._e(),
          _vm.popup === 1
            ? _c("add-new-area-dialog", {
                attrs: { area: _vm.areaSelected },
                on: {
                  close: _vm.closePopup,
                  save: _vm.savePopup,
                  remove: _vm.removeArea,
                },
              })
            : _vm._e(),
          _c("div", { staticClass: "page" }, [
            _c(
              "div",
              { staticClass: "page__main" },
              [
                !_vm.areaSelected
                  ? _c(
                      "router-link",
                      {
                        staticClass: "page__back",
                        attrs: { to: "../settings" },
                      },
                      [
                        _c("svg-icon", {
                          attrs: {
                            name: "ic-close-table-arrow",
                            width: "34",
                            height: "34",
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "page__back",
                        on: {
                          click: function ($event) {
                            _vm.areaSelected = null
                          },
                        },
                      },
                      [
                        _c("svg-icon", {
                          attrs: {
                            name: "ic-close-table-arrow",
                            width: "34",
                            height: "34",
                          },
                        }),
                      ],
                      1
                    ),
                _vm.areaSelected
                  ? _c(
                      "div",
                      {
                        staticClass: "add-row",
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "row",
                        },
                      },
                      [
                        _c("h1", {
                          staticClass: "mb2rem mie16",
                          domProps: {
                            textContent: _vm._s(
                              _vm.areaSelected.name.length > 0
                                ? _vm.areaSelected.name
                                : _vm.$t("Area without name")
                            ),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.editArea(_vm.areaSelected)
                            },
                          },
                        }),
                        _vm.mobile
                          ? [
                              _c(
                                "button",
                                {
                                  staticClass: "mobile-btn-plus",
                                  on: { click: _vm.createTable },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      name: "ic-plus",
                                      width: "16",
                                      height: "16",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn_pink",
                                  on: { click: _vm.createTable },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      name: "ic-plus",
                                      width: "16",
                                      height: "16",
                                    },
                                  }),
                                  _c("span", {
                                    staticClass: "d-md-block d-none",
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Add Table")),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                      ],
                      2
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "add-row",
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "row",
                        },
                      },
                      [
                        _c("h1", {
                          staticClass: "mb2rem mie64",
                          domProps: {
                            textContent: _vm._s(_vm.$t("Sittings areas")),
                          },
                        }),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn_pink",
                            on: { click: _vm.create },
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-plus",
                                width: "16",
                                height: "16",
                              },
                            }),
                            _c("span", {
                              staticClass: "xl-none",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Add new area")),
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                _c(
                  "section",
                  { staticClass: "book-set flex" },
                  _vm._l(_vm.SittingsAreas, function (area, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "input-check md-none" },
                      [
                        _c("label", { staticClass: "mie8" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.areaSelected,
                                expression: "areaSelected",
                              },
                            ],
                            attrs: { type: "radio" },
                            domProps: {
                              value: area,
                              checked: _vm._q(_vm.areaSelected, area),
                            },
                            on: {
                              change: function ($event) {
                                _vm.areaSelected = area
                              },
                            },
                          }),
                          _c(
                            "span",
                            { staticClass: "input-check__label" },
                            [
                              _c("span", {
                                staticClass: "input-check__text",
                                domProps: {
                                  textContent: _vm._s(_vm.$t(area.name)),
                                },
                              }),
                              area.smoking
                                ? _c("svg-icon", {
                                    staticClass: "ic-stroke",
                                    staticStyle: { fill: "white" },
                                    attrs: {
                                      name: "ic-smoking",
                                      width: "16",
                                      height: "10",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "club__list mt16" },
              _vm._l(_vm.tables, function (table) {
                return _c(
                  "div",
                  {
                    key: table.id,
                    staticClass: "club__item",
                    on: {
                      click: function ($event) {
                        return _vm.edit(table)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "club__data" }, [
                      _c("div", { staticClass: "club__text" }, [
                        _c("div", {
                          staticClass: "club__name",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("Table number") + ": " + table.table_number
                            ),
                          },
                        }),
                        _c("div", {
                          staticClass: "club__dinners",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("number of dinners") +
                                ": " +
                                table.number_of_dinners
                            ),
                          },
                        }),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }