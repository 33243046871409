var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.close,
          expression: "close",
        },
      ],
      staticClass: "keypad-wrap",
      class: { open: _vm.opened },
    },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "button",
          { staticClass: "keypad-btn", on: { click: _vm.toggle } },
          [
            _c("svg-icon", {
              attrs: { name: "ic-keypad", width: "28", height: "28" },
            }),
            _c("svg-icon", {
              attrs: { name: "ic-close-thin", width: "24", height: "24" },
            }),
          ],
          1
        ),
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _c("div", { staticClass: "keypad", attrs: { dir: "ltr" } }, [
          _c("div", { staticClass: "keypad__field" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.phoneNumber,
                  expression: "phoneNumber",
                },
              ],
              attrs: { type: "text", readonly: "" },
              domProps: { value: _vm.phoneNumber },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.phoneNumber = $event.target.value
                },
              },
            }),
          ]),
          _c("div", { staticClass: "keypad__list" }, [
            _c(
              "button",
              {
                staticClass: "keypad__item",
                on: {
                  mousedown: function ($event) {
                    return _vm.keyPressed("1")
                  },
                  mouseup: function ($event) {
                    return _vm.keyUnpressed("1")
                  },
                  mouseleave: function ($event) {
                    return _vm.keyUnpressed("1")
                  },
                },
              },
              [_c("div", { staticClass: "keypad__num" }, [_vm._v("1")])]
            ),
            _c(
              "button",
              {
                staticClass: "keypad__item",
                on: {
                  mousedown: function ($event) {
                    return _vm.keyPressed("2")
                  },
                  mouseup: function ($event) {
                    return _vm.keyUnpressed("2")
                  },
                  mouseleave: function ($event) {
                    return _vm.keyUnpressed("2")
                  },
                },
              },
              [
                _c("div", { staticClass: "keypad__num" }, [_vm._v("2")]),
                _c("div", { staticClass: "keypad__letter" }, [_vm._v("a b c")]),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "keypad__item",
                on: {
                  mousedown: function ($event) {
                    return _vm.keyPressed("3")
                  },
                  mouseup: function ($event) {
                    return _vm.keyUnpressed("3")
                  },
                  mouseleave: function ($event) {
                    return _vm.keyUnpressed("3")
                  },
                },
              },
              [
                _c("div", { staticClass: "keypad__num" }, [_vm._v("3")]),
                _c("div", { staticClass: "keypad__letter" }, [_vm._v("d e f")]),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "keypad__item",
                on: {
                  mousedown: function ($event) {
                    return _vm.keyPressed("4")
                  },
                  mouseup: function ($event) {
                    return _vm.keyUnpressed("4")
                  },
                  mouseleave: function ($event) {
                    return _vm.keyUnpressed("4")
                  },
                },
              },
              [
                _c("div", { staticClass: "keypad__num" }, [_vm._v("4")]),
                _c("div", { staticClass: "keypad__letter" }, [_vm._v("g h i")]),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "keypad__item",
                on: {
                  mousedown: function ($event) {
                    return _vm.keyPressed("5")
                  },
                  mouseup: function ($event) {
                    return _vm.keyUnpressed("5")
                  },
                  mouseleave: function ($event) {
                    return _vm.keyUnpressed("5")
                  },
                },
              },
              [
                _c("div", { staticClass: "keypad__num" }, [_vm._v("5")]),
                _c("div", { staticClass: "keypad__letter" }, [_vm._v("j k l")]),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "keypad__item",
                on: {
                  mousedown: function ($event) {
                    return _vm.keyPressed("6")
                  },
                  mouseup: function ($event) {
                    return _vm.keyUnpressed("6")
                  },
                  mouseleave: function ($event) {
                    return _vm.keyUnpressed("6")
                  },
                },
              },
              [
                _c("div", { staticClass: "keypad__num" }, [_vm._v("6")]),
                _c("div", { staticClass: "keypad__letter" }, [_vm._v("m n o")]),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "keypad__item",
                on: {
                  mousedown: function ($event) {
                    return _vm.keyPressed("7")
                  },
                  mouseup: function ($event) {
                    return _vm.keyUnpressed("7")
                  },
                  mouseleave: function ($event) {
                    return _vm.keyUnpressed("7")
                  },
                },
              },
              [
                _c("div", { staticClass: "keypad__num" }, [_vm._v("7")]),
                _c("div", { staticClass: "keypad__letter" }, [
                  _vm._v("p q r s"),
                ]),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "keypad__item",
                on: {
                  mousedown: function ($event) {
                    return _vm.keyPressed("8")
                  },
                  mouseup: function ($event) {
                    return _vm.keyUnpressed("8")
                  },
                  mouseleave: function ($event) {
                    return _vm.keyUnpressed("8")
                  },
                },
              },
              [
                _c("div", { staticClass: "keypad__num" }, [_vm._v("8")]),
                _c("div", { staticClass: "keypad__letter" }, [_vm._v("t u v")]),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "keypad__item",
                on: {
                  mousedown: function ($event) {
                    return _vm.keyPressed("9")
                  },
                  mouseup: function ($event) {
                    return _vm.keyUnpressed("9")
                  },
                  mouseleave: function ($event) {
                    return _vm.keyUnpressed("9")
                  },
                },
              },
              [
                _c("div", { staticClass: "keypad__num" }, [_vm._v("9")]),
                _c("div", { staticClass: "keypad__letter" }, [
                  _vm._v("w x y z"),
                ]),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "keypad__item",
                on: {
                  mousedown: function ($event) {
                    return _vm.keyPressed("*")
                  },
                  mouseup: function ($event) {
                    return _vm.keyUnpressed("*")
                  },
                  mouseleave: function ($event) {
                    return _vm.keyUnpressed("*")
                  },
                },
              },
              [
                _c("div", { staticClass: "keypad__num keypad__num_star" }, [
                  _vm._v("*"),
                ]),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "keypad__item",
                on: {
                  mousedown: function ($event) {
                    return _vm.keyPressed("0")
                  },
                  mouseup: function ($event) {
                    return _vm.keyUnpressed("0")
                  },
                  mouseleave: function ($event) {
                    return _vm.keyUnpressed("0")
                  },
                },
              },
              [
                _c("div", { staticClass: "keypad__num" }, [_vm._v("0")]),
                _c(
                  "div",
                  { staticClass: "keypad__letter keypad__letter_big" },
                  [_vm._v("+")]
                ),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "keypad__item",
                on: {
                  mousedown: function ($event) {
                    return _vm.keyPressed("#")
                  },
                  mouseup: function ($event) {
                    return _vm.keyUnpressed("#")
                  },
                  mouseleave: function ($event) {
                    return _vm.keyUnpressed("#")
                  },
                },
              },
              [_c("div", { staticClass: "keypad__num" }, [_vm._v("#")])]
            ),
          ]),
          _c("div", { staticClass: "keypad__action" }, [
            _c("img", {
              staticClass: "pointer",
              attrs: {
                src: require("../assets/img/svg/phone-disconnect.svg"),
                alt: "",
              },
              on: { click: _vm.sipDisconnect },
            }),
            _c(
              "button",
              { staticClass: "keypad__call", on: { click: _vm.makeCall } },
              [
                _c("svg-icon", {
                  staticClass: "ic-white",
                  attrs: { name: "ic-calls", width: "28", height: "28" },
                }),
              ],
              1
            ),
            _c(
              "button",
              { staticClass: "keypad__reset", on: { click: _vm.backspace } },
              [
                _c("svg-icon", {
                  attrs: { name: "ic-reset-grey", width: "28", height: "20" },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }