var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-dialog", { on: { close: _vm.close } }, [
    !_vm.mobile
      ? _c("div", { staticClass: "new-backup-branch" }, [
          _c("div", { staticClass: "form-site" }, [
            _c(
              "div",
              {
                style: {
                  display: "grid",
                  "justify-content": "center",
                  width: "100%",
                },
              },
              [
                _c(
                  "div",
                  {
                    style: {
                      display: "flex",
                      "justify-content": "center",
                      "margin-bottom": "20px",
                      width: "100%",
                      gap: "10px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        style: { display: "flex", "flex-direction": "column" },
                      },
                      [
                        _c("span", {
                          staticClass: "form-site__label",
                          style: {
                            "margin-bottom": "5px",
                            display: "flex",
                            "margin-inline-start": "15px",
                          },
                          domProps: {
                            textContent: _vm._s(_vm.$t("select day")),
                          },
                        }),
                        _c("select-box", {
                          key: _vm.day,
                          staticClass: "selectday",
                          attrs: {
                            widthHeader: "200px",
                            width: "200px",
                            placeholder: String(_vm.$t("select day")),
                            unsort: "",
                            showSearch: "",
                            options: _vm.days.map(function (label, value) {
                              return { label: _vm.$t(label), value: value }
                            }),
                          },
                          model: {
                            value: _vm.day,
                            callback: function ($$v) {
                              _vm.day = $$v
                            },
                            expression: "day",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        style: { display: "flex", "flex-direction": "column" },
                      },
                      [
                        _c("span", {
                          staticClass: "form-site__label",
                          style: {
                            "margin-bottom": "5px",
                            display: "flex",
                            "margin-inline-start": "15px",
                          },
                          domProps: {
                            textContent: _vm._s(_vm.$t("Select branch")),
                          },
                        }),
                        _c("select-box", {
                          key: _vm.branchForBackup,
                          staticClass: "selectbranch",
                          attrs: {
                            widthHeader: "200px",
                            width: "200px",
                            placeholder: String(_vm.$t("Select branch")),
                            unsort: "",
                            showSearch: "",
                            options: _vm.branches,
                          },
                          model: {
                            value: _vm.branchForBackup,
                            callback: function ($$v) {
                              _vm.branchForBackup = $$v
                            },
                            expression: "branchForBackup",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    style: {
                      display: "flex",
                      "justify-content": "center",
                      "margin-bottom": "5px",
                      width: "100%",
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "form-site__label",
                      style: {
                        "margin-bottom": "5px",
                        display: "flex",
                        "margin-inline-start": "35px",
                        width: "50%",
                      },
                      domProps: { textContent: _vm._s(_vm.$t("Start hour")) },
                    }),
                    _c("span", {
                      staticClass: "form-site__label",
                      style: {
                        "margin-bottom": "5px",
                        display: "flex",
                        "margin-inline-start": "15px",
                        width: "50%",
                      },
                      domProps: { textContent: _vm._s(_vm.$t("End hour")) },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    style: {
                      display: "flex",
                      "justify-content": "center",
                      "margin-bottom": "20px",
                      width: "100%",
                      "margin-top": "5px",
                      gap: "5px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        style: {
                          display: "flex",
                          "flex-direction": "column",
                          gap: "15px",
                        },
                      },
                      [
                        _vm.day != 5 && _vm.day != 6
                          ? _c("select-box", {
                              staticClass: "time",
                              attrs: {
                                autocomplete: "",
                                options: _vm.listHours,
                                placeholder: "00:00",
                              },
                              model: {
                                value: _vm.drom_time,
                                callback: function ($$v) {
                                  _vm.drom_time = $$v
                                },
                                expression: "drom_time",
                              },
                            })
                          : _vm._e(),
                        _vm.day == 5 || _vm.day == 6
                          ? _c("div", [
                              _vm.day == 6
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "center",
                                        width: "100%",
                                        "margin-top": "7px",
                                        gap: "5px",
                                      },
                                    },
                                    [
                                      !_vm.showMinutes
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "justify-content": "center",
                                                width: "100%",
                                                "margin-top": "7px",
                                                gap: "5px",
                                              },
                                            },
                                            [
                                              _c("select-box", {
                                                staticClass: "time",
                                                attrs: {
                                                  autocomplete: "",
                                                  options: _vm.listHours,
                                                  placeholder: "00:00",
                                                },
                                                model: {
                                                  value: _vm.drom_time,
                                                  callback: function ($$v) {
                                                    _vm.drom_time = $$v
                                                  },
                                                  expression: "drom_time",
                                                },
                                              }),
                                              !_vm.showMinutes
                                                ? _c("select-box", {
                                                    staticClass: "time2",
                                                    attrs: {
                                                      autocomplete: "",
                                                      options: _vm.listHours,
                                                      placeholder: "00:00",
                                                    },
                                                    model: {
                                                      value: _vm.to_time,
                                                      callback: function ($$v) {
                                                        _vm.to_time = $$v
                                                      },
                                                      expression: "to_time",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.showMinutes
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "justify-content": "center",
                                                width: "100%",
                                                gap: "10px",
                                              },
                                            },
                                            [
                                              _c("select-box", {
                                                staticClass: "time",
                                                attrs: {
                                                  autocomplete: "",
                                                  options: _vm.listHours,
                                                  placeholder: "00:00",
                                                },
                                                model: {
                                                  value: _vm.drom_time,
                                                  callback: function ($$v) {
                                                    _vm.drom_time = $$v
                                                  },
                                                  expression: "drom_time",
                                                },
                                              }),
                                              !_vm.showMinutes
                                                ? _c("select-box", {
                                                    staticClass: "time2",
                                                    attrs: {
                                                      autocomplete: "",
                                                      options: _vm.listHours,
                                                      placeholder: "00:00",
                                                    },
                                                    model: {
                                                      value: _vm.drom_time,
                                                      callback: function ($$v) {
                                                        _vm.drom_time = $$v
                                                      },
                                                      expression: "drom_time",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.showMinutes && _vm.day == 6
                                                ? _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.before_after_shabat,
                                                        expression:
                                                          "before_after_shabat",
                                                      },
                                                    ],
                                                    style: {
                                                      width: "200px",
                                                      display: "flex",
                                                      "justify-content":
                                                        "center",
                                                    },
                                                    attrs: { type: "number" },
                                                    domProps: {
                                                      value:
                                                        _vm.before_after_shabat,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.before_after_shabat =
                                                          $event.target.value
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.day == 5
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "center",
                                        width: "100%",
                                        gap: "10px",
                                      },
                                    },
                                    [
                                      !_vm.showMinutes
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "justify-content": "center",
                                                width: "100%",
                                                gap: "10px",
                                              },
                                            },
                                            [
                                              !_vm.showMinutes
                                                ? _c("select-box", {
                                                    staticClass: "time",
                                                    attrs: {
                                                      autocomplete: "",
                                                      options: _vm.listHours,
                                                      placeholder: "00:00",
                                                    },
                                                    model: {
                                                      value: _vm.drom_time,
                                                      callback: function ($$v) {
                                                        _vm.drom_time = $$v
                                                      },
                                                      expression: "drom_time",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _c("select-box", {
                                                staticClass: "time2",
                                                attrs: {
                                                  autocomplete: "",
                                                  options: _vm.listHours,
                                                  placeholder: "00:00",
                                                },
                                                model: {
                                                  value: _vm.to_time,
                                                  callback: function ($$v) {
                                                    _vm.to_time = $$v
                                                  },
                                                  expression: "to_time",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.showMinutes
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "justify-content": "center",
                                                width: "100%",
                                                gap: "10px",
                                              },
                                            },
                                            [
                                              _vm.showMinutes && _vm.day == 5
                                                ? _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.before_after_shabat,
                                                        expression:
                                                          "before_after_shabat",
                                                      },
                                                    ],
                                                    style: {
                                                      width: "200px",
                                                      display: "flex",
                                                      "justify-content":
                                                        "center",
                                                    },
                                                    attrs: { type: "number" },
                                                    domProps: {
                                                      value:
                                                        _vm.before_after_shabat,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.before_after_shabat =
                                                          $event.target.value
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                              !_vm.showMinutes
                                                ? _c("select-box", {
                                                    staticClass: "time",
                                                    attrs: {
                                                      autocomplete: "",
                                                      options: _vm.listHours,
                                                      placeholder: "00:00",
                                                    },
                                                    model: {
                                                      value: _vm.drom_time,
                                                      callback: function ($$v) {
                                                        _vm.drom_time = $$v
                                                      },
                                                      expression: "drom_time",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _c("select-box", {
                                                staticClass: "time2",
                                                attrs: {
                                                  autocomplete: "",
                                                  options: _vm.listHours,
                                                  placeholder: "00:00",
                                                },
                                                model: {
                                                  value: _vm.to_time,
                                                  callback: function ($$v) {
                                                    _vm.to_time = $$v
                                                  },
                                                  expression: "to_time",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "label",
                                {
                                  staticClass: "input-check__label",
                                  style: {
                                    display: "flex",
                                    "justify-content": "center",
                                    width: "100%",
                                    "margin-top": "10px",
                                  },
                                },
                                [
                                  _c("checkbox", {
                                    attrs: { name: "is_free" },
                                    model: {
                                      value: _vm.showMinutes,
                                      callback: function ($$v) {
                                        _vm.showMinutes = $$v
                                      },
                                      expression: "showMinutes",
                                    },
                                  }),
                                  _vm.day == 5
                                    ? _c("span", {
                                        staticClass: "input-check__text",
                                        style: { "font-size": "13px" },
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("minutes before shabat")
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.day == 6
                                    ? _c("span", {
                                        staticClass: "input-check__text",
                                        style: { "font-size": "13px" },
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("minutes after shabat")
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.day != 5 && _vm.day != 6
                      ? _c("select-box", {
                          staticClass: "time2",
                          attrs: {
                            autocomplete: "",
                            options: _vm.listHours,
                            placeholder: "00:00",
                          },
                          model: {
                            value: _vm.to_time,
                            callback: function ($$v) {
                              _vm.to_time = $$v
                            },
                            expression: "to_time",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "form-site__btn md-none",
              style: {
                display: "flex",
                width: "100%",
                "justify-content": "center",
              },
            },
            [
              _c("button", {
                staticClass: "btn",
                attrs: { type: "button" },
                domProps: { textContent: _vm._s(_vm.$t("Save")) },
                on: { click: _vm.saveBackupBranches },
              }),
            ]
          ),
        ])
      : _vm._e(),
    _vm.mobile
      ? _c(
          "div",
          {
            staticClass: "new-backup-branch-mobile",
            style: {
              display: "flex",
              "flex-direcetion": "column",
              "justify-content": "center",
              width: "100%",
            },
          },
          [
            _c(
              "div",
              { staticClass: "form-site", staticStyle: { width: "90%" } },
              [
                _c(
                  "div",
                  {
                    style: {
                      display: "flex",
                      "justify-content": "center",
                      width: "100%",
                      "flex-direction": "column",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        style: {
                          "justify-content": "center",
                          "margin-bottom": "5px",
                          width: "100%",
                          display: "flex",
                          "flex-direction": "column",
                        },
                      },
                      [
                        _c("span", {
                          staticClass: "form-site__label",
                          style: {
                            "margin-bottom": "5px",
                            display: "flex",
                            "justify-content": "start",
                          },
                          domProps: {
                            textContent: _vm._s(_vm.$t("select day")),
                          },
                        }),
                        _c("select-box", {
                          key: _vm.day,
                          staticClass: "selectday",
                          style: {
                            "margin-bottom": "20px",
                            width: "100%",
                          },
                          attrs: {
                            placeholder: String(_vm.$t("select day")),
                            unsort: "",
                            showSearch: "",
                            options: _vm.days.map(function (label, value) {
                              return { label: _vm.$t(label), value: value }
                            }),
                          },
                          model: {
                            value: _vm.day,
                            callback: function ($$v) {
                              _vm.day = $$v
                            },
                            expression: "day",
                          },
                        }),
                        _c("span", {
                          staticClass: "form-site__label",
                          style: {
                            "margin-bottom": "5px",
                            display: "flex",
                            "justify-content": "start",
                          },
                          domProps: {
                            textContent: _vm._s(_vm.$t("Select branch")),
                          },
                        }),
                        _c("select-box", {
                          staticClass: "selectbranch",
                          style: { width: "100%" },
                          attrs: {
                            placeholder: String(_vm.$t("Select branch")),
                            unsort: "",
                            showSearch: "",
                            options: _vm.branches,
                          },
                          model: {
                            value: _vm.branchForBackup,
                            callback: function ($$v) {
                              _vm.branchForBackup = $$v
                            },
                            expression: "branchForBackup",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", {
                      style: {
                        display: "flex",
                        "justify-content": "center",
                        "margin-bottom": "30px",

                        width: "100%",
                      },
                    }),
                    _c(
                      "div",
                      {
                        style: {
                          display: "flex",
                          "flex-direction": "column",
                          width: "100%",
                        },
                      },
                      [
                        _vm.day != 5 && _vm.day !== 6
                          ? _c("span", {
                              staticClass: "form-site__label",
                              style: {
                                "margin-bottom": "5px",
                                display: "flex",
                                "justify-content": "start",
                              },
                              domProps: {
                                textContent: _vm._s(_vm.$t("Start hour")),
                              },
                            })
                          : _vm._e(),
                        _vm.day != 5 && _vm.day !== 6
                          ? _c("select-box", {
                              staticClass: "time",
                              style: {
                                display: "flex",
                                "margin-bottom": "10px",
                                width: "100%",
                              },
                              attrs: {
                                autocomplete: "",
                                options: _vm.listHours,
                                placeholder: "00:00",
                              },
                              model: {
                                value: _vm.drom_time,
                                callback: function ($$v) {
                                  _vm.drom_time = $$v
                                },
                                expression: "drom_time",
                              },
                            })
                          : _vm._e(),
                        _vm.day != 5 && _vm.day !== 6
                          ? _c("span", {
                              staticClass: "form-site__label",
                              style: {
                                "margin-bottom": "5px",
                                display: "flex",
                                "justify-content": "start",
                              },
                              domProps: {
                                textContent: _vm._s(_vm.$t("End hour")),
                              },
                            })
                          : _vm._e(),
                        _vm.day != 6 && _vm.day != 5
                          ? _c("select-box", {
                              staticClass: "time2",
                              style: {
                                display: "flex",

                                width: "100%",
                              },
                              attrs: {
                                autocomplete: "",
                                options: _vm.listHours,
                                placeholder: "00:00",
                              },
                              model: {
                                value: _vm.to_time,
                                callback: function ($$v) {
                                  _vm.to_time = $$v
                                },
                                expression: "to_time",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.day == 5
                      ? _c(
                          "div",
                          [
                            _c("span", {
                              staticClass: "form-site__label",
                              style: {
                                "margin-bottom": "5px",
                                display: "flex",
                                "justify-content": "start",
                              },
                              domProps: {
                                textContent: _vm._s(_vm.$t("Start hour")),
                              },
                            }),
                            _vm.showMinutes && _vm.day == 5 && _vm.showMinutes
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.before_after_shabat,
                                      expression: "before_after_shabat",
                                    },
                                  ],
                                  style: { width: "100%" },
                                  attrs: { type: "number" },
                                  domProps: { value: _vm.before_after_shabat },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.before_after_shabat =
                                        $event.target.value
                                    },
                                  },
                                })
                              : _vm._e(),
                            !_vm.showMinutes
                              ? _c("select-box", {
                                  staticClass: "time",
                                  style: {
                                    display: "flex",
                                    "margin-bottom": "10px",
                                    width: "100%",
                                  },
                                  attrs: {
                                    autocomplete: "",
                                    options: _vm.listHours,
                                    placeholder: "00:00",
                                  },
                                  model: {
                                    value: _vm.drom_time,
                                    callback: function ($$v) {
                                      _vm.drom_time = $$v
                                    },
                                    expression: "drom_time",
                                  },
                                })
                              : _vm._e(),
                            _c("span", {
                              staticClass: "form-site__label",
                              style: {
                                "margin-bottom": "5px",
                                "margin-top": "15px",
                                display: "flex",
                                "justify-content": "start",
                              },
                              domProps: {
                                textContent: _vm._s(_vm.$t("End hour")),
                              },
                            }),
                            _c("select-box", {
                              staticClass: "time2",
                              style: {
                                display: "flex",

                                width: "100%",
                              },
                              attrs: {
                                autocomplete: "",
                                options: _vm.listHours,
                                placeholder: "00:00",
                              },
                              model: {
                                value: _vm.to_time,
                                callback: function ($$v) {
                                  _vm.to_time = $$v
                                },
                                expression: "to_time",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.day == 6
                      ? _c(
                          "div",
                          [
                            _c("span", {
                              staticClass: "form-site__label",
                              style: {
                                "margin-bottom": "5px",
                                display: "flex",
                                "justify-content": "start",
                              },
                              domProps: {
                                textContent: _vm._s(_vm.$t("Start hour")),
                              },
                            }),
                            _c("select-box", {
                              staticClass: "time",
                              style: {
                                display: "flex",
                                width: "100%",
                              },
                              attrs: {
                                autocomplete: "",
                                options: _vm.listHours,
                                placeholder: "00:00",
                              },
                              model: {
                                value: _vm.drom_time,
                                callback: function ($$v) {
                                  _vm.drom_time = $$v
                                },
                                expression: "drom_time",
                              },
                            }),
                            _c("span", {
                              staticClass: "form-site__label",
                              style: {
                                "margin-bottom": "5px",
                                "margin-top": "15px",
                                display: "flex",
                                "justify-content": "start",
                              },
                              domProps: {
                                textContent: _vm._s(_vm.$t("End hour")),
                              },
                            }),
                            !_vm.showMinutes
                              ? _c("select-box", {
                                  staticClass: "time2",
                                  style: {
                                    display: "flex",
                                    "margin-bottom": "10px",
                                    width: "100%",
                                  },
                                  attrs: {
                                    autocomplete: "",
                                    options: _vm.listHours,
                                    placeholder: "00:00",
                                  },
                                  model: {
                                    value: _vm.to_time,
                                    callback: function ($$v) {
                                      _vm.to_time = $$v
                                    },
                                    expression: "to_time",
                                  },
                                })
                              : _vm._e(),
                            _vm.showMinutes && _vm.day == 6 && _vm.showMinutes
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.before_after_shabat,
                                      expression: "before_after_shabat",
                                    },
                                  ],
                                  style: { width: "100%" },
                                  attrs: { type: "number" },
                                  domProps: { value: _vm.before_after_shabat },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.before_after_shabat =
                                        $event.target.value
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.day == 6 || _vm.day == 5
                      ? _c(
                          "label",
                          {
                            staticClass: "input-check__label",
                            style: {
                              display: "flex",
                              "justify-content": "start",
                              width: "100%",
                              "margin-top": "15px",
                            },
                          },
                          [
                            _c("checkbox", {
                              attrs: { name: "is_free" },
                              model: {
                                value: _vm.showMinutes,
                                callback: function ($$v) {
                                  _vm.showMinutes = $$v
                                },
                                expression: "showMinutes",
                              },
                            }),
                            _vm.day == 5
                              ? _c("span", {
                                  staticClass: "input-check__text",
                                  style: { "font-size": "13px" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("minutes before shabat")
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm.day == 6
                              ? _c("span", {
                                  staticClass: "input-check__text",
                                  style: { "font-size": "13px" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("minutes after shabat")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "form-site__btn",
                    style: {
                      display: "flex",
                      width: "100%",
                      "justify-content": "center",
                    },
                  },
                  [
                    _c("button", {
                      staticClass: "btn",
                      attrs: { type: "button" },
                      domProps: { textContent: _vm._s(_vm.$t("Save")) },
                      on: { click: _vm.saveBackupBranches },
                    }),
                  ]
                ),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }