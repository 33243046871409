var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "client-details" }, [
    _c(
      "div",
      { staticClass: "club-members-details" },
      [
        _c(
          "base-dialog",
          { attrs: { width: "1017px" }, on: { close: _vm.close } },
          [
            _c("div", { staticClass: "client-detail__content" }, [
              _vm.currentClient
                ? _c("div", { staticClass: "client-detail__main" }, [
                    _c("div", { staticClass: "tabs-page" }, [
                      _c(
                        "div",
                        { staticClass: "tabs-page__list" },
                        [
                          _vm._l(_vm.tabs, function (tab, tIndex) {
                            return [
                              tab.visit
                                ? _c("div", {
                                    key: tIndex,
                                    staticClass: "tabs-page__item",
                                    class: { active: _vm.activeTab === tab.id },
                                    domProps: {
                                      textContent: _vm._s(tab.label),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeTab(tab)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                      _c("div", { staticClass: "tabs-page__content" }, [
                        _c(
                          "div",
                          {
                            staticClass: "tabs-page__content-item",
                            class: {
                              active: _vm.activeTab === "client_details",
                            },
                          },
                          [
                            _c("div", { staticClass: "profile" }, [
                              _c("div", { staticClass: "profile__author" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "profile__photo",
                                    staticStyle: {
                                      "background-image":
                                        "url('@/assets/img/ava-woman.jpg')",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "profile__photo-icon" },
                                      [
                                        _c("svg-icon", {
                                          staticClass: "ic-white",
                                          attrs: {
                                            name: "ic-photo",
                                            width: "16",
                                            height: "14",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c("div", { staticClass: "profile__info" }, [
                                  _c("div", { staticClass: "profile__head" }, [
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.currentClient.first_name +
                                            " " +
                                            _vm.currentClient.last_name
                                        ),
                                      },
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "profile__club-icon" },
                                      [
                                        _c("svg-icon", {
                                          staticClass: "ic-white",
                                          attrs: {
                                            name: "ic-crown",
                                            width: "16",
                                            height: "16",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "profile__points" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "profile__point" },
                                        [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(_vm.$t("Points")) +
                                              ": " +
                                              _vm._s(_vm.currentClient.points) +
                                              "\n                        "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "profile__point" },
                                        [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(
                                                _vm.$t("Points collected")
                                              ) +
                                              ": " +
                                              _vm._s(
                                                _vm.currentClient
                                                  .points_collected
                                              ) +
                                              "\n                        "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "profile__point" },
                                        [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(_vm.$t("Points used")) +
                                              ": " +
                                              _vm._s(
                                                _vm.currentClient
                                                  .points_redeemed
                                              ) +
                                              "\n                        "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "profile__block" }, [
                                    _c("div", { staticClass: "input-check" }, [
                                      _c("label", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.currentClient
                                                  .accept_push_notifications,
                                              expression:
                                                "currentClient.accept_push_notifications",
                                            },
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.currentClient
                                                .accept_push_notifications
                                            )
                                              ? _vm._i(
                                                  _vm.currentClient
                                                    .accept_push_notifications,
                                                  null
                                                ) > -1
                                              : _vm.currentClient
                                                  .accept_push_notifications,
                                          },
                                          on: {
                                            input: function ($event) {
                                              _vm.change = true
                                            },
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.currentClient
                                                    .accept_push_notifications,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.currentClient,
                                                      "accept_push_notifications",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.currentClient,
                                                      "accept_push_notifications",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.currentClient,
                                                  "accept_push_notifications",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "input-check__label" },
                                          [
                                            _c("span", {
                                              staticClass: "input-check__mark",
                                            }),
                                            _c("span", {
                                              staticClass: "input-check__text",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t("Subscribed")
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                              ]),
                              _c(
                                "form",
                                {
                                  staticClass: "form-site-wrap",
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "form-site" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-site__content" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-site__field-wrap",
                                          },
                                          [
                                            _c("form-site-field-text", {
                                              attrs: { title: "First name" },
                                              on: {
                                                input: function ($event) {
                                                  _vm.change = true
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.currentClient.first_name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.currentClient,
                                                    "first_name",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "currentClient.first_name",
                                              },
                                            }),
                                            _c("form-site-field-text", {
                                              attrs: { title: "Last name" },
                                              on: {
                                                input: function ($event) {
                                                  _vm.change = true
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.currentClient.last_name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.currentClient,
                                                    "last_name",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "currentClient.last_name",
                                              },
                                            }),
                                            _c("form-site-field-text", {
                                              attrs: { title: "Email" },
                                              on: {
                                                input: function ($event) {
                                                  _vm.change = true
                                                },
                                              },
                                              model: {
                                                value: _vm.currentClient.email,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.currentClient,
                                                    "email",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "currentClient.email",
                                              },
                                            }),
                                            _c("form-site-field-text", {
                                              attrs: { title: "Phone number" },
                                              on: {
                                                input: function ($event) {
                                                  _vm.change = true
                                                },
                                              },
                                              model: {
                                                value: _vm.currentClient.phone,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.currentClient,
                                                    "phone",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "currentClient.phone",
                                              },
                                            }),
                                            _c("form-site-field-date", {
                                              attrs: {
                                                title: "Birthday",
                                                maxDate: Date(),
                                              },
                                              on: {
                                                input: function ($event) {
                                                  _vm.change = true
                                                },
                                              },
                                              model: {
                                                value: _vm.birthdate,
                                                callback: function ($$v) {
                                                  _vm.birthdate = $$v
                                                },
                                                expression: "birthdate",
                                              },
                                            }),
                                            _c("form-site-field-date", {
                                              attrs: {
                                                title: "Wedding day",
                                                maxDate: Date(),
                                              },
                                              on: {
                                                input: function ($event) {
                                                  _vm.change = true
                                                },
                                              },
                                              model: {
                                                value: _vm.happyDay,
                                                callback: function ($$v) {
                                                  _vm.happyDay = $$v
                                                },
                                                expression: "happyDay",
                                              },
                                            }),
                                            _c("form-site-field-text", {
                                              attrs: {
                                                disabled:
                                                  !!_vm.currentClient.id,
                                                title: "ID number",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  _vm.change = true
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.currentClient
                                                    .id_card_number,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.currentClient,
                                                    "id_card_number",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "currentClient.id_card_number",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "form-site__field",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "form-site__label",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t("Favorite branch")
                                                    ),
                                                  },
                                                }),
                                                _c("select-box", {
                                                  attrs: {
                                                    autocomplete: true,
                                                    options:
                                                      _vm.clubBranchesOptions,
                                                    unsort: true,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.currentClient
                                                        .favorite_branch,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.currentClient,
                                                        "favorite_branch",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "currentClient.favorite_branch",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "form-site__field" },
                                          [
                                            _c("div", {
                                              staticClass: "form-site__label",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t("Addresses")
                                                ),
                                              },
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "edit-info" },
                                              [
                                                _vm.currentClient &&
                                                _vm.currentClient.addresses
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "edit-info__list",
                                                      },
                                                      _vm._l(
                                                        _vm.currentClient
                                                          .addresses,
                                                        function (
                                                          address,
                                                          adIndex
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: adIndex,
                                                              staticClass:
                                                                "edit-info__item",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.editAddress(
                                                                      address
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "edit-info__info",
                                                                },
                                                                [
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "edit-info__title",
                                                                    domProps: {
                                                                      textContent:
                                                                        _vm._s(
                                                                          address.name
                                                                        ),
                                                                    },
                                                                  }),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "edit-info__text",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "address-to-span",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              address:
                                                                                address,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "edit-info__btn",
                                                                },
                                                                [
                                                                  _vm.checkPermissionPath(
                                                                    "clients.edit_client_addresses"
                                                                  )
                                                                    ? _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "edit-info__del",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.deleteAddress(
                                                                                  address
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "svg-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  name: "ic-close",
                                                                                  width:
                                                                                    "10",
                                                                                  height:
                                                                                    "10",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  : _vm._e(),
                                                _vm.checkPermissionPath(
                                                  "clients.edit_client_addresses"
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "line-more",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "line-more__content",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "line-more__icon",
                                                              },
                                                              [
                                                                _c("svg-icon", {
                                                                  attrs: {
                                                                    name: "ic-plus",
                                                                    width: "12",
                                                                    height:
                                                                      "12",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c("button", {
                                                              staticClass:
                                                                "line-more__text",
                                                              domProps: {
                                                                textContent:
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "Add"
                                                                    )
                                                                  ),
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.addAddress,
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "tabs-page__content-item",
                            class: {
                              active: _vm.activeTab === "client-orders",
                            },
                          },
                          [
                            _c("order-sources-tabs", {
                              on: { change: _vm.ordersSourceChange },
                            }),
                            _c(
                              "div",
                              [
                                _c(
                                  "table-list",
                                  _vm._b(
                                    {
                                      on: { "on-click-line": _vm.orderDetails },
                                    },
                                    "table-list",
                                    _vm.dataTable,
                                    false
                                  )
                                ),
                                _c("div", { staticClass: "paginationOrders" }, [
                                  _c("div", { staticClass: "pager-wrap" }, [
                                    _c("div", { staticClass: "count-row" }, [
                                      _c("div", {
                                        staticClass: "count-row__title",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("Rows per page:")
                                          ),
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "count-row-num" },
                                        [
                                          _c("select-box", {
                                            attrs: {
                                              unsort: "",
                                              openToUp: "",
                                              widthHeader: "50px",
                                              width: "100px",
                                              options:
                                                _vm.itemsPerPageOptions.map(
                                                  function (i) {
                                                    return {
                                                      label: "" + i,
                                                      value: i,
                                                    }
                                                  }
                                                ),
                                            },
                                            model: {
                                              value: _vm.rowsPerPageOrders,
                                              callback: function ($$v) {
                                                _vm.rowsPerPageOrders = $$v
                                              },
                                              expression: "rowsPerPageOrders",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("div", { staticClass: "pager" }, [
                                      _c("div", {
                                        staticClass: "pager__text",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.pageNameForOrders
                                          ),
                                        },
                                      }),
                                      _c("div", { staticClass: "pager__arr" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "pager__prev",
                                            on: {
                                              click: function ($event) {
                                                return _vm.setPageForOrders(-1)
                                              },
                                            },
                                          },
                                          [
                                            _c("svg-icon", {
                                              staticClass: "ic-stroke",
                                              attrs: {
                                                name: "ic-arrow-prev",
                                                width: "8",
                                                height: "14",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "pager__next",
                                            on: {
                                              click: function ($event) {
                                                return _vm.setPageForOrders(1)
                                              },
                                            },
                                          },
                                          [
                                            _c("svg-icon", {
                                              staticClass: "ic-stroke",
                                              attrs: {
                                                name: "ic-arrow-next",
                                                width: "8",
                                                height: "14",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "tabs-page__content-item",
                            class: { active: _vm.activeTab === "calls" },
                          },
                          [
                            _c("div", { staticClass: "client-calls" }, [
                              _c("div", {
                                staticClass: "h2",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Calls log")),
                                },
                              }),
                              _c("div", { staticClass: "admin-table-wrap" }, [
                                _c("table", { staticClass: "admin-table" }, [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", {
                                        staticClass: "admin-table__play",
                                      }),
                                      _c("th", {
                                        staticClass: "admin-table__status-call",
                                      }),
                                      _c("th", {
                                        domProps: {
                                          textContent: _vm._s(_vm.$t("Start")),
                                        },
                                      }),
                                      _c("th", {
                                        domProps: {
                                          textContent: _vm._s(_vm.$t("End")),
                                        },
                                      }),
                                      _c("th", {
                                        domProps: {
                                          textContent: _vm._s(_vm.$t("Total")),
                                        },
                                      }),
                                      _c("th", {
                                        domProps: {
                                          textContent: _vm._s(_vm.$t("Date")),
                                        },
                                      }),
                                      _c("th", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("Made by")
                                          ),
                                        },
                                      }),
                                    ]),
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.calls,
                                      function (callItem, callIndex) {
                                        return _c("tr", { key: callIndex }, [
                                          _c("td", [
                                            _c("a", {
                                              staticClass: "btn-table-play",
                                              attrs: { href: "#" },
                                            }),
                                          ]),
                                          _c(
                                            "td",
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  name:
                                                    "ic-" +
                                                    callItem.type +
                                                    "-call",
                                                  width: "16",
                                                  height: "16",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("td", {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.filters.time(callItem.start)
                                              ),
                                            },
                                          }),
                                          _c("td", {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.filters.time(callItem.end)
                                              ),
                                            },
                                          }),
                                          _c("td", {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.filters.duration(
                                                  callItem.duration
                                                )
                                              ),
                                            },
                                          }),
                                          _c("td", {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.filters.date(callItem.start)
                                              ),
                                            },
                                          }),
                                          _c("td", {
                                            domProps: {
                                              textContent: _vm._s(
                                                callItem.client
                                                  ? callItem.client.name
                                                  : ""
                                              ),
                                            },
                                          }),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "tabs-page__content-item",
                            class: { active: _vm.activeTab === "vouchers" },
                          },
                          [
                            _c("div", {
                              staticClass: "h2 d-none d-md-block",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Vouchers")),
                              },
                            }),
                            _c("div", { staticClass: "voucher-tabs" }, [
                              _c("button", {
                                staticClass: "voucher-tab",
                                class: {
                                  active: _vm.voucherTab === "benefits",
                                },
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Benefits")),
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.voucherTab = "benefits"
                                  },
                                },
                              }),
                            ]),
                            _vm.voucherTab === "benefits"
                              ? _c(
                                  "div",
                                  { staticClass: "voucher-list" },
                                  [
                                    _vm.checkPermissionPath(
                                      "compensation.create"
                                    )
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "benefits",
                                            on: { click: _vm.newCompensation },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "compensation-name flex a-center mb0",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "add-btn mie12",
                                                  },
                                                  [
                                                    _c(
                                                      "svg",
                                                      {
                                                        attrs: {
                                                          width: "20",
                                                          height: "20",
                                                          viewBox: "0 0 20 20",
                                                          fill: "none",
                                                          xmlns:
                                                            "http://www.w3.org/2000/svg",
                                                        },
                                                      },
                                                      [
                                                        _c("rect", {
                                                          attrs: {
                                                            x: "8.75",
                                                            width: "2.5",
                                                            height: "20",
                                                            rx: "1.25",
                                                            fill: "#48B26B",
                                                          },
                                                        }),
                                                        _c("rect", {
                                                          attrs: {
                                                            y: "11.25",
                                                            width: "2.5",
                                                            height: "20",
                                                            rx: "1.25",
                                                            transform:
                                                              "rotate(-90 0 11.25)",
                                                            fill: "#48B26B",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c("span", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t("New benefit")
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._l(
                                      _vm.compensations,
                                      function (compensation) {
                                        return _c(
                                          "button",
                                          {
                                            key: compensation.id,
                                            staticClass: "benefits",
                                            class: {
                                              active:
                                                _vm.compensationSelected &&
                                                _vm.compensationSelected.id ===
                                                  compensation.id,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showCompensationPopup2(
                                                  compensation
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "flex w100 bet a-center",
                                              },
                                              [
                                                _c("span", [
                                                  _c("span", {
                                                    staticClass: "benefit-name",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        compensation.name
                                                      ),
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "benefit-created",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            _vm.$t("Created")
                                                          ) +
                                                          ":\n                          " +
                                                          _vm._s(
                                                            _vm.filters.date(
                                                              compensation.created_at,
                                                              "DD/MM/YYYY"
                                                            )
                                                          ) +
                                                          "\n                          " +
                                                          _vm._s(
                                                            compensation.created_at ===
                                                              _vm.$t("Now")
                                                              ? ""
                                                              : _vm.$t("at")
                                                          ) +
                                                          "\n                          " +
                                                          _vm._s(
                                                            _vm.filters.time(
                                                              compensation.created_at
                                                            )
                                                          ) +
                                                          "\n                        "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "benefit-created",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            _vm.$t("By:")
                                                          ) +
                                                          "\n\n                          " +
                                                          _vm._s(
                                                            compensation.created_by
                                                          ) +
                                                          "\n                        "
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c("span", {
                                                  class: [
                                                    "status-pending",
                                                    "status-active",
                                                    "status-redeemed",
                                                    "status-rejected",
                                                  ][compensation.status],
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        [
                                                          "Pending approval",
                                                          "Active",
                                                          "Redeemed",
                                                          "Rejected",
                                                        ][compensation.status]
                                                      )
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    _c("div", { staticClass: "pager-wrap" }, [
                                      _c("div", { staticClass: "count-row" }, [
                                        _c("div", {
                                          staticClass: "count-row__title",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Rows per page:")
                                            ),
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "count-row-num" },
                                          [
                                            _c("select-box", {
                                              attrs: {
                                                unsort: "",
                                                openToUp: "",
                                                widthHeader: "50px",
                                                width: "100px",
                                                options:
                                                  _vm.itemsPerPageOptions.map(
                                                    function (i) {
                                                      return {
                                                        label: "" + i,
                                                        value: i,
                                                      }
                                                    }
                                                  ),
                                              },
                                              model: {
                                                value:
                                                  _vm.rowsPerPageCompensations,
                                                callback: function ($$v) {
                                                  _vm.rowsPerPageCompensations =
                                                    $$v
                                                },
                                                expression:
                                                  "rowsPerPageCompensations",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _c("div", { staticClass: "pager" }, [
                                        _c("div", {
                                          staticClass: "pager__text",
                                          domProps: {
                                            textContent: _vm._s(_vm.pageName),
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "pager__arr" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "pager__prev",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.setPage(-1)
                                                  },
                                                },
                                              },
                                              [
                                                _c("svg-icon", {
                                                  staticClass: "ic-stroke",
                                                  attrs: {
                                                    name: "ic-arrow-prev",
                                                    width: "8",
                                                    height: "14",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "button",
                                              {
                                                staticClass: "pager__next",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.setPage(1)
                                                  },
                                                },
                                              },
                                              [
                                                _c("svg-icon", {
                                                  staticClass: "ic-stroke",
                                                  attrs: {
                                                    name: "ic-arrow-next",
                                                    width: "8",
                                                    height: "14",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "client-detail__bottom" }, [
                      _vm.checkPermissionPath("clients.edit_client_details")
                        ? _c(
                            "button",
                            { staticClass: "btn", on: { click: _vm.save } },
                            [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Save")),
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "button",
                        {
                          staticClass: "btn-call",
                          on: { click: _vm.makeCall },
                        },
                        [
                          _c("svg-icon", {
                            staticClass: "ic-white",
                            attrs: {
                              name: "ic-calls",
                              width: "24",
                              height: "24",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              !_vm.isNewMember && _vm.activeTab === "client_details"
                ? _c("div", { staticClass: "client-comment" }, [
                    _c("div", { staticClass: "client-comment__content" }, [
                      _c("div", {
                        staticClass: "client-comment__title",
                        domProps: { textContent: _vm._s(_vm.$t("Comments")) },
                      }),
                      _c(
                        "div",
                        { staticClass: "client-comment__list" },
                        _vm._l(_vm.comments, function (comment, commIndex) {
                          return _c(
                            "div",
                            {
                              key: commIndex,
                              staticClass: "client-comment-item",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "client-comment-item__content" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "client-comment-item__top" },
                                    [
                                      comment.user
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "client-comment-item__name",
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    comment.user.first_name
                                                  ) +
                                                  "\n                      " +
                                                  _vm._s(
                                                    comment.user.last_name
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "client-comment-item__date",
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.filters.date(
                                                  comment.created_at,
                                                  "DD/MM/YYYY"
                                                )
                                              ) +
                                              " at\n                      " +
                                              _vm._s(
                                                _vm.filters.time(
                                                  comment.created_at
                                                )
                                              ) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("div", {
                                    staticClass: "client-comment-item__text",
                                    domProps: {
                                      textContent: _vm._s(comment.content),
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "client-comment-item__more" },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      name: "ic-more",
                                      width: "4",
                                      height: "20",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                    _c("div", { staticClass: "client-comment__field" }, [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.makeComment.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "form-comment" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.newComment,
                                  expression: "newComment",
                                },
                              ],
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t("Add comment"),
                                title: _vm.$t("Add comment"),
                                required: "",
                              },
                              domProps: { value: _vm.newComment },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.newComment = $event.target.value
                                },
                              },
                            }),
                            _c(
                              "button",
                              { attrs: { type: "submit" } },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    name: "ic-big-arrow",
                                    width: "24",
                                    height: "24",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
        _vm.popoupCompensation
          ? _c("compensation-edit-dialog", {
              attrs: { compensation: _vm.newMemberBenefitInEdit },
              on: { save: _vm.saveNewCompensation },
            })
          : _vm._e(),
        _vm.popoupCompensation2
          ? _c("compensation-edit-dialog", {
              attrs: { compensation: _vm.compensationSelected },
              on: {
                save: _vm.saveNewCompensation2,
                close: _vm.saveNewCompensation2,
                closeload: _vm.closeAndLoad,
              },
            })
          : _vm._e(),
        !!_vm.currentOrder && _vm.showOrderDetailsPopup
          ? _c("order-details-popup", {
              attrs: { order: _vm.currentOrder, show: !!_vm.currentOrder },
              on: {
                closed: _vm.closeOrderDetails,
                displayCanceledOrder: _vm.displayCanceledOrder,
                cancelOrder: _vm.cancelOrder,
                statusChanged: _vm.changeOrderStatus,
              },
            })
          : _vm._e(),
        !!_vm.currentAddress
          ? _c("edit-address-dialog", {
              attrs: { address: _vm.currentAddress, show: "" },
              on: { closed: _vm.closeAddressEditing, saved: _vm.saveAddress },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }