var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      staticClass: "select-position",
      attrs: { width: "420px" },
      on: { close: _vm.close },
    },
    [
      _c(
        "div",
        { staticClass: "popup__content pb40", style: { height: "550px" } },
        [
          _c("div", { staticClass: "form-new-user" }, [
            _c("div", { staticClass: "form-site" }, [
              _c("div", { staticClass: "form-site__field" }, [
                _c(
                  "div",
                  { staticClass: "form-site__select-position" },
                  [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.$t("Position")) },
                    }),
                    _c(
                      "select-box",
                      _vm._b(
                        {
                          attrs: {
                            viewId: "",
                            showVal: "",
                            name: "",
                            options: _vm.employee.employee_positions.map(
                              function (p) {
                                return { value: p.id, label: p.name }
                              }
                            ),
                          },
                          model: {
                            value: _vm.position,
                            callback: function ($$v) {
                              _vm.position = $$v
                            },
                            expression: "position",
                          },
                        },
                        "select-box",
                        _vm.$attrs,
                        false
                      )
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "filter-date-container" },
                [
                  _vm.dateRange.start
                    ? _c("form-site-field-date", {
                        staticClass: "start1",
                        attrs: { title: _vm.$t("From") + _vm.$t("Date") },
                        model: {
                          value: _vm.dateRange.start,
                          callback: function ($$v) {
                            _vm.$set(_vm.dateRange, "start", $$v)
                          },
                          expression: "dateRange.start",
                        },
                      })
                    : _vm._e(),
                  _vm.dateRange.startHour
                    ? _c("select-box", {
                        ref: "timepicker1",
                        staticClass: "t-center timepicker1",
                        attrs: {
                          width: "100px",
                          autocomplete: "",
                          options: _vm.listHours,
                          placeholder: "00:00",
                        },
                        model: {
                          value: _vm.dateRange.startHour,
                          callback: function ($$v) {
                            _vm.$set(_vm.dateRange, "startHour", $$v)
                          },
                          expression: "dateRange.startHour",
                        },
                      })
                    : _vm._e(),
                  _vm.dateRange.startHour
                    ? _c("Vue-timepicker", {
                        staticClass: "timepicker",
                        attrs: {
                          "manual-input": "",
                          "hide-clear-button": "",
                          format: "HH:mm",
                        },
                        model: {
                          value: _vm.dateRange.startHour,
                          callback: function ($$v) {
                            _vm.$set(_vm.dateRange, "startHour", $$v)
                          },
                          expression: "dateRange.startHour",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "switch-wrap inline-flex a-center" },
                [
                  _c("div", {
                    staticClass: "switch-wrap__label mie12",
                    domProps: {
                      textContent: _vm._s(_vm.$t("Edit shift end time")),
                    },
                  }),
                  _c("swich-button", {
                    model: {
                      value: _vm.showEndTime,
                      callback: function ($$v) {
                        _vm.showEndTime = $$v
                      },
                      expression: "showEndTime",
                    },
                  }),
                ],
                1
              ),
              _vm.showEndTime
                ? _c(
                    "div",
                    {
                      staticClass: "filter-date-container",
                      style: { "margin-top": "20px" },
                    },
                    [
                      _vm.dateRange.end
                        ? _c("form-site-field-date", {
                            staticClass: "end1",
                            attrs: {
                              title: _vm.$t("to") + " " + _vm.$t("Date"),
                            },
                            model: {
                              value: _vm.dateRange.end,
                              callback: function ($$v) {
                                _vm.$set(_vm.dateRange, "end", $$v)
                              },
                              expression: "dateRange.end",
                            },
                          })
                        : _vm._e(),
                      _vm.dateRange.endHour
                        ? _c("select-box", {
                            ref: "timepicker2",
                            staticClass: "t-center timepicker2",
                            attrs: {
                              width: "100px",
                              autocomplete: "",
                              options: _vm.listHours,
                              placeholder: "00:00",
                            },
                            model: {
                              value: _vm.dateRange.endHour,
                              callback: function ($$v) {
                                _vm.$set(_vm.dateRange, "endHour", $$v)
                              },
                              expression: "dateRange.endHour",
                            },
                          })
                        : _vm._e(),
                      _vm.dateRange.endHour
                        ? _c("Vue-timepicker", {
                            staticClass: "timepicker",
                            attrs: {
                              "manual-input": "",
                              "hide-clear-button": "",
                              format: "HH:mm",
                            },
                            model: {
                              value: _vm.dateRange.endHour,
                              callback: function ($$v) {
                                _vm.$set(_vm.dateRange, "endHour", $$v)
                              },
                              expression: "dateRange.endHour",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "save_record" }, [
                _c("button", { staticClass: "btn", on: { click: _vm.save } }, [
                  _c("span", {
                    domProps: { textContent: _vm._s(_vm.$t("Save")) },
                  }),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }