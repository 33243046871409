var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cities-input" },
    [
      _c("vue-select", {
        attrs: {
          label: "name",
          reduce: function (city) {
            return city.name
          },
          filterable: false,
          options: _vm.options,
          dir: _vm.dir,
        },
        on: { search: _vm.onSearch },
        scopedSlots: _vm._u([
          {
            key: "no-options",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
          {
            key: "option",
            fn: function (ref) {
              var name = ref.name
              return [
                _c("div", { staticClass: "d-center" }, [
                  _vm._v("\n        " + _vm._s(name) + "\n      "),
                ]),
              ]
            },
          },
          {
            key: "selected-option",
            fn: function (ref) {
              var name = ref.name
              return [
                _c("div", { staticClass: "selected d-center" }, [
                  _vm._v("\n        " + _vm._s(name) + "\n      "),
                ]),
              ]
            },
          },
        ]),
        model: {
          value: _vm.val,
          callback: function ($$v) {
            _vm.val = $$v
          },
          expression: "val",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }