var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: { title: _vm.xml ? "XML log" : "JSON log" },
      on: { close: _vm.close },
    },
    [
      _c("div", { staticClass: "popup__content" }, [
        _c("div", { staticClass: "log-order" }, [
          _c("div", {
            staticClass: "log-order__num",
            domProps: {
              textContent: _vm._s(_vm.$t("Order") + " #" + _vm.order.unique_id),
            },
          }),
          _c(
            "div",
            { staticClass: "json-view" },
            [
              _vm.json
                ? _c("vue-json-pretty", {
                    attrs: { path: "res", data: _vm.json },
                  })
                : _vm._e(),
              _vm.xml
                ? _c("xml-viewer", { attrs: { xml: _vm.xml } })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }