var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-site__field",
      class: {
        "form-site__field_short": _vm.short,
        "form-site__field__sub": _vm.sub,
        "form-site__field_helf": _vm.helf,
      },
    },
    [
      _c("span", {
        staticClass: "form-site__label",
        class: { head: _vm.head },
        domProps: {
          textContent: _vm._s(
            _vm.$t(_vm.title) +
              (_vm.optional ? " (" + _vm.$t("Optional") + ")" : "")
          ),
        },
      }),
      _vm.required
        ? _c("span", {
            staticClass: "t-red form-site__label mis4",
            domProps: { textContent: _vm._s("*") },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "form-site__input",
          style: _vm.error
            ? "border: 1px solid red; border-radius: 1000px;"
            : "",
        },
        [
          _vm.sign
            ? _c("div", {
                staticClass: "input-label__note",
                domProps: { textContent: _vm._s(_vm.sign) },
              })
            : _vm._e(),
          _vm.type === "textarea"
            ? _c("textarea", {
                ref: "inp",
                attrs: {
                  placeholder: _vm.placeholder,
                  dir: _vm.ltr ? "ltr" : "",
                  disabled: _vm.disabled,
                  readonly: _vm.readonly,
                },
                domProps: { value: _vm.value },
                on: { input: _vm.input, blur: _vm.blur },
              })
            : _c("input", {
                ref: "inp",
                class: {
                  pie0: !_vm.sub,
                  pis60: _vm.sign,
                  head: _vm.head,
                  errrequired: _vm.errrequired,
                  bgwhite: _vm.bgwhite,
                },
                attrs: {
                  placeholder: _vm.placeholder,
                  dir: _vm.ltr ? "ltr" : "",
                  type: _vm.type,
                  disabled: _vm.disabled,
                  readonly: _vm.readonly,
                },
                domProps: { value: _vm.value },
                on: { input: _vm.input, blur: _vm.blur },
              }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }