var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-font-container" }, [
    _vm.nameFromServer
      ? _c("div", { staticClass: "input-font" }, [
          _c("div", {
            staticClass: "font-name",
            domProps: { textContent: _vm._s(_vm.nameFromServer) },
          }),
          _c("button", { on: { click: _vm.removeFont } }, [
            _c(
              "svg",
              {
                attrs: {
                  width: "26",
                  height: "26",
                  viewBox: "0 0 26 26",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("circle", {
                  attrs: { cx: "13", cy: "13", r: "13", fill: "white" },
                }),
                _c("g", [
                  _c("path", {
                    attrs: {
                      d: "M9.3877 17.2125C9.3877 17.8775 9.92634 18.4162 10.5914 18.4162H15.4062C16.0713 18.4162 16.6099 17.8775 16.6099 17.2125V9.99023H9.3877V17.2125Z",
                      fill: "#FD6779",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      d: "M15.1056 8.18583L14.5037 7.58398H11.4945L10.8926 8.18583H8.78613V9.38954H17.212V8.18583H15.1056Z",
                      fill: "#FD6779",
                    },
                  }),
                ]),
                _c("defs", [
                  _c("clipPath", { attrs: { id: "clip0" } }, [
                    _c("rect", {
                      attrs: {
                        width: "10.8333",
                        height: "10.8333",
                        fill: "white",
                        transform: "translate(7.58301 7.58398)",
                      },
                    }),
                  ]),
                ]),
              ]
            ),
          ]),
        ])
      : _c("div", { staticClass: "input-font" }, [
          _c("div", {
            staticClass: "font-name",
            domProps: { textContent: _vm._s(_vm.type) },
          }),
          _c("label", [
            _c("input", {
              staticClass: "hide",
              attrs: { type: "file", accept: "." + _vm.type },
              on: { change: _vm.uploadFont },
            }),
            _c(
              "svg",
              {
                attrs: {
                  width: "26",
                  height: "26",
                  viewBox: "0 0 26 26",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("circle", {
                  attrs: { cx: "13", cy: "13", r: "13", fill: "white" },
                }),
                _c("g", [
                  _c("path", {
                    attrs: {
                      d: "M14.8748 9.37674C14.898 9.35974 14.854 9.39668 14.8748 9.37674C14.6123 9.54996 14.3643 9.43689 14.2498 9.32194L13.4712 8.57601V13.6968C13.4839 14.024 13.4217 14.3767 12.9995 14.3768C12.5772 14.3863 12.5283 14.0597 12.5283 13.7322C12.5278 13.7721 12.5216 13.6929 12.5283 13.7322V8.57601L12.0358 9.1493L11.8327 9.35233C11.5811 9.60412 11.3352 9.53228 11.1247 9.32194C10.8912 9.08853 11.1445 8.73193 11.4372 8.43924L12.6872 7.18924C12.9388 6.93739 13.1519 6.93768 13.4038 7.18924L14.8748 8.57601C15.1215 8.8326 15.1873 9.06424 14.8748 9.37674Z",
                      fill: "#82D361",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      d: "M17.8337 15.3887V11.5215C17.8337 11.5215 17.8337 11.4957 17.8337 11.4828C17.7724 10.516 17.2117 9.95527 16.2223 9.91338H14.6368C14.0941 9.91338 13.9371 10.1253 13.9371 10.4378C13.9371 10.7503 14.1834 10.7535 14.6368 10.7535H16.1933C16.4673 10.7535 17.0331 11.2153 17.0621 11.5215V15.4378C17.0621 16.0628 16.61 16.2342 16.1246 16.2342H9.86406C9.37898 16.2342 8.93713 15.7503 8.93713 15.35V11.4828C8.93713 11.1315 9.56435 10.7503 9.77705 10.7503H11.3884C11.9018 10.7503 12.0621 10.7503 12.0621 10.4378C12.0621 9.94216 11.7496 9.91016 11.3884 9.91016H9.77705C8.81025 9.91016 8.20762 10.516 8.16572 11.5215V15.4144C8.14065 16.2648 8.80969 16.9745 9.66008 16.9996C9.6851 17.0004 9.71014 17.0005 9.73515 17H16.2642C17.1292 17.0021 17.8322 16.3027 17.8343 15.4377C17.8344 15.4213 17.8341 15.405 17.8337 15.3887Z",
                      fill: "#82D361",
                    },
                  }),
                ]),
                _c("defs", [
                  _c("clipPath", { attrs: { id: "clip0" } }, [
                    _c("rect", {
                      attrs: {
                        width: "10",
                        height: "10",
                        fill: "white",
                        transform: "translate(8 7)",
                      },
                    }),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }