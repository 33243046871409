import { render, staticRenderFns } from "./format-currency.vue?vue&type=template&id=fe071df0&"
import script from "./format-currency.vue?vue&type=script&lang=ts&"
export * from "./format-currency.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/dorshoshan/Documents/DEV/Atmos-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fe071df0')) {
      api.createRecord('fe071df0', component.options)
    } else {
      api.reload('fe071df0', component.options)
    }
    module.hot.accept("./format-currency.vue?vue&type=template&id=fe071df0&", function () {
      api.rerender('fe071df0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/table/cells/format-currency.vue"
export default component.exports