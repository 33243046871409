var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "checkbox-select" },
    _vm._l(_vm.options, function (option, i) {
      return _c(
        "li",
        {
          key: i,
          class: { active: _vm.optionsSelected.includes(option.value) },
          on: {
            click: function ($event) {
              _vm.isDisabled ? "" : _vm.$emit("set-active", option.value)
            },
          },
        },
        [
          _vm.isCircle
            ? _c("span", {
                staticClass: "circle",
                class: { active: _vm.optionsSelected.includes(option.value) },
              })
            : option.imgUrl
            ? _c("svg-icon", {
                staticClass: "second-fill",
                class: { active: _vm.optionsSelected.includes(option.value) },
                attrs: { name: "ic-check", width: "24", height: "17.63" },
              })
            : _c(
                "svg",
                {
                  attrs: {
                    width: "20",
                    height: "21",
                    viewBox: "0 0 20 20",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("rect", {
                    class: {
                      active: _vm.optionsSelected.includes(option.value),
                    },
                    attrs: {
                      x: "0.5",
                      y: "0.5",
                      width: "19",
                      height: "19",
                      rx: "1.5",
                    },
                  }),
                  _c("transition", { attrs: { name: "fade" } }, [
                    _vm.optionsSelected.includes(option.value)
                      ? _c("path", {
                          attrs: {
                            d: "M7.75634 15.7559C7.4317 15.7559 7.10673 15.632 6.85908 15.3842L2.37175 10.8969C1.87608 10.4012 1.87608 9.59771 2.37175 9.10204C2.86742 8.60637 3.67093 8.60637 4.1666 9.10204L7.75634 12.6918L15.8334 4.61486C16.3291 4.11934 17.1328 4.11919 17.6281 4.61486C18.1239 5.11053 18.1239 5.91423 17.6281 6.40971L8.65378 15.3842C8.40594 15.6319 8.08112 15.7559 7.75634 15.7559Z",
                          },
                        })
                      : _vm._e(),
                  ]),
                ],
                1
              ),
          option.imgUrl
            ? _c("img", {
                class: { active: _vm.optionsSelected.includes(option.value) },
                attrs: { src: option.imgUrl, alt: "Icon" },
              })
            : _vm._e(),
          _c("span", {
            staticClass: "m-i-start-1ch",
            domProps: { textContent: _vm._s(_vm.$t(option.label)) },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }