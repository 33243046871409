var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-site__field" }, [
    _c("label", {
      staticClass: "form-site__label",
      attrs: { for: "address_name" },
      domProps: { textContent: _vm._s(_vm.$t(_vm.title)) },
    }),
    _c(
      "div",
      { staticClass: "form-site__select-bank" },
      [
        _vm.options.length
          ? _c(
              "select-box",
              _vm._b(
                {
                  attrs: {
                    viewId: "",
                    showVal: "",
                    showSearch: "",
                    searchByValue: "",
                    name: "",
                    options: _vm.options,
                    value: _vm.value,
                  },
                  on: { input: _vm.input },
                },
                "select-box",
                _vm.$attrs,
                false
              )
            )
          : _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.textModel,
                  expression: "textModel",
                },
              ],
              attrs: { type: "text" },
              domProps: { value: _vm.textModel },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.textModel = $event.target.value
                },
              },
            }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }