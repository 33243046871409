var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c(
      "div",
      { staticClass: "subtype-payments full-screen" },
      [
        _c("main", [
          _c("section", { staticClass: "container" }, [
            _c("div", { staticClass: "page" }, [
              _c("div", { staticClass: "page__main" }, [
                _c(
                  "div",
                  { staticClass: "subtype-payments-head" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "page__back",
                        attrs: { to: "../settings" },
                      },
                      [
                        _c("svg-icon", {
                          attrs: {
                            name: "ic-close-table-arrow",
                            width: "34",
                            height: "34",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("h1", {
                      domProps: {
                        textContent: _vm._s(_vm.$t("Subtype payments")),
                      },
                    }),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn_pink",
                        attrs: { type: "button" },
                        on: { click: _vm.createSubtypePayment },
                      },
                      [
                        _c("svg-icon", {
                          attrs: { name: "ic-plus", width: "16", height: "16" },
                        }),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("New Subtype payment"))),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "page__content" },
                [
                  _c(
                    "table-list",
                    _vm._b(
                      {
                        on: {
                          "on-click-line": _vm.openDialogFromTable,
                          changeOptions: _vm.changeOptions,
                        },
                      },
                      "table-list",
                      _vm.dataTable,
                      false
                    )
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _vm.popup
          ? _c(
              "base-dialog",
              {
                staticClass: "subtype-payments-dialog",
                attrs: {
                  width: "800px",
                  title: _vm.$t("Edit Subtype payment"),
                },
                on: { close: _vm.closeEditDialog },
              },
              [
                _c(
                  "div",
                  { staticClass: "popup__content" },
                  [
                    _vm.currentSubtypePayment
                      ? [
                          _c("div", { staticClass: "form-site" }, [
                            _c(
                              "div",
                              { staticClass: "form-site__group name-edit" },
                              [
                                _c("div", { staticClass: "form-site__field" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "form-site__label d-md-block d-none",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("Name")))]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "form-site__input" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.currentSubtypePayment.name,
                                            expression:
                                              "currentSubtypePayment.name",
                                          },
                                        ],
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.currentSubtypePayment.name,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.currentSubtypePayment,
                                              "name",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "form-site__field" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "form-site__label d-md-block d-none",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("Select payment type"))
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "form-site__input" },
                                    [
                                      _c("select-box", {
                                        attrs: {
                                          options: _vm.paymentTypesOptions,
                                          "empty-option": _vm.$t(
                                            "Select payment type"
                                          ),
                                        },
                                        model: {
                                          value:
                                            _vm.currentSubtypePayment
                                              .payment_type,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.currentSubtypePayment,
                                              "payment_type",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "currentSubtypePayment.payment_type",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _c("branches-tab", {
                            attrs: {
                              availability:
                                _vm.currentSubtypePayment.availability,
                            },
                          }),
                        ]
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "popup-btn pb40" },
                      [
                        _vm.currentSubtypePayment &&
                        _vm.currentSubtypePayment.id
                          ? _c("remove-btn", {
                              on: { click: _vm.deleteSubtypePayment },
                            })
                          : _vm._e(),
                        _c(
                          "button",
                          {
                            staticClass: "btn",
                            attrs: { type: "button" },
                            on: { click: _vm.saveCurrentSubtypePayment },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("Save")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }