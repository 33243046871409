var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        staticClass: "popup-trans",
        class: { popupOn: _vm.popupOn },
        attrs: {
          "data-dialog-num": _vm.dialogNum,
          "data-dialog-count": _vm.countDialogs,
        },
      },
      [
        _c("backdrop", {
          staticClass: "transitionOpacity",
          attrs: { zIndex: 110 + _vm.dialogNum * 10 },
          on: {
            emitClose: function ($event) {
              return _vm.$emit("close")
            },
          },
        }),
        _c("div", { staticClass: "header-back d-flex d-md-none" }, [
          _c(
            "button",
            {
              staticClass: "header__back",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [
              _c(
                "span",
                { staticClass: "header-company" },
                [
                  _c("svg-icon", {
                    staticClass: "ic-white",
                    attrs: { name: "ic-arrow-left", width: "16", height: "12" },
                  }),
                  _c("span", {
                    domProps: { textContent: _vm._s(_vm.$t("Back")) },
                  }),
                ],
                1
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "tabs-page-popup" }, [
          _c(
            "div",
            {
              staticClass: "popup-wrap transitionOpacity",
              class: { inblur: !_vm.latest },
              style: {
                width: _vm.mobile ? "" : _vm.width,
                "z-index": 110 + _vm.dialogNum * 10,
              },
            },
            [
              _c(
                "div",
                { staticClass: "popup" },
                [
                  _c("close-popup-btn", {
                    attrs: { top: "12px", end: "12px" },
                    on: {
                      close: function ($event) {
                        return _vm.$emit("close")
                      },
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "tabs-page" },
                    [
                      _vm._t("top"),
                      _vm.tabList[0].label
                        ? _c(
                            "div",
                            { staticClass: "tabs-page__list" },
                            _vm._l(_vm.tabList, function (tab) {
                              return _c("button", {
                                key: tab.label ? tab.label : tab,
                                staticClass: "tabs-page__item",
                                class: {
                                  active:
                                    _vm.tabMode ===
                                    (tab.label ? tab.label : tab),
                                },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(tab.label ? tab.label : tab)
                                  ),
                                },
                                on: {
                                  click: [
                                    function ($event) {
                                      _vm.tabMode = tab.label ? tab.label : tab
                                    },
                                    function ($event) {
                                      return _vm.performClick(tab.click)
                                    },
                                  ],
                                },
                              })
                            }),
                            0
                          )
                        : _c(
                            "div",
                            { staticClass: "tabs-page__list" },
                            _vm._l(_vm.tabList, function (tab) {
                              return _c("button", {
                                key: tab,
                                staticClass: "tabs-page__item",
                                class: { active: _vm.tabMode === tab },
                                domProps: { textContent: _vm._s(_vm.$t(tab)) },
                                on: {
                                  click: function ($event) {
                                    _vm.tabMode = tab
                                  },
                                },
                              })
                            }),
                            0
                          ),
                      _c(
                        "div",
                        { staticClass: "tabs-page__content" },
                        _vm._l(_vm.tabList, function (tab, i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              staticClass: "tabs-page__content-item",
                              class: {
                                active:
                                  _vm.tabMode === (tab.label ? tab.label : tab),
                              },
                            },
                            [_vm._t("startTab"), _vm._t("tab" + i)],
                            2
                          )
                        }),
                        0
                      ),
                    ],
                    2
                  ),
                  _vm._t("btns"),
                ],
                2
              ),
            ]
          ),
        ]),
        _vm._t("popups"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }