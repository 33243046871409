var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showed
    ? _c("footer", { staticClass: "footer" }, [
        _c("div", { staticClass: "call-panel" }, [
          _vm.profile
            ? _c("div", { staticClass: "call-panel-logo" }, [
                _vm.profile.restaurant && _vm.profile.restaurant.logo
                  ? _c("div", { staticClass: "call-panel-logo__img" }, [
                      _c("img", {
                        attrs: { src: _vm.profile.restaurant.logo, alt: "" },
                      }),
                    ])
                  : _vm._e(),
                _vm.profile.restaurant
                  ? _c("div", { staticClass: "call-panel-logo__info" }, [
                      _vm.profile.restaurant.name
                        ? _c("div", {
                            staticClass: "call-panel-logo__name",
                            domProps: {
                              textContent: _vm._s(_vm.profile.restaurant.name),
                            },
                          })
                        : _vm._e(),
                      _vm.profile.branch
                        ? _c("div", {
                            staticClass: "call-panel-logo__address",
                            domProps: {
                              textContent: _vm._s(_vm.profile.branch.name),
                            },
                          })
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _c("div", { staticClass: "call-panel__content" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.client,
                    expression: "client",
                  },
                ],
                staticClass: "call-panel-user",
              },
              [
                _c("user-avatar", {
                  attrs: {
                    user: _vm.client,
                    "img-class": "call-panel-user__photo",
                    size: 48,
                  },
                }),
                _vm.client
                  ? _c("div", { staticClass: "call-panel-user__info" }, [
                      _c("div", { staticClass: "call-panel-user__head" }, [
                        _vm.client.name
                          ? _c("button", {
                              domProps: {
                                textContent: _vm._s(_vm.client.name),
                              },
                              on: { click: _vm.clientDetails },
                            })
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { staticClass: "call-panel__info" }, [
              _c("div", { staticClass: "call-panel__phone" }, [
                _vm.call
                  ? _c("span", {
                      domProps: { textContent: _vm._s(_vm.call.phone) },
                    })
                  : _vm._e(),
              ]),
              _c("div", {
                staticClass: "call-panel__time",
                domProps: {
                  textContent: _vm._s(_vm.filters.duration(_vm.callTime)),
                },
              }),
            ]),
            _c("div", { staticClass: "call-panel__action" }, [
              _c(
                "div",
                { staticClass: "call-panel-btn" },
                [
                  _c("keypad"),
                  _c(
                    "div",
                    {
                      staticClass:
                        "call-panel-btn__item call-panel-btn__item_red",
                      on: { click: _vm.hangUp },
                    },
                    [
                      _c("svg-icon", {
                        attrs: { name: "ic-calls", width: "20", height: "20" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      staticClass:
                        "call-panel-btn__item call-panel-btn__item_dark-orange",
                      on: { click: _vm.callMuteToggle },
                    },
                    [
                      _c("svg-icon", {
                        attrs: {
                          name: "ic-microphone",
                          width: "20",
                          height: "22",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      staticClass:
                        "call-panel-btn__item call-panel-btn__item_orange",
                      on: { click: _vm.sendSms },
                    },
                    [
                      _c("svg-icon", {
                        attrs: { name: "ic-mail", width: "24", height: "16" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "call-panel-btn__item",
                      on: { click: _vm.callRefer },
                    },
                    [
                      _c("svg-icon", {
                        attrs: {
                          name: "ic-forward",
                          width: "20",
                          height: "17",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "button",
                { on: { click: _vm.newOrder } },
                [
                  _c("span", {
                    domProps: { textContent: _vm._s(_vm.$t("New order")) },
                  }),
                  _c("svg-icon", {
                    staticClass: "ic-white",
                    attrs: {
                      name: "ic-arrow-right",
                      width: "16",
                      height: "12",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }