var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isActive || _vm.isOffMode
    ? _c("div", [
        _vm.isActive && !_vm.isOffMode
          ? _c("span", {
              domProps: {
                textContent: _vm._s(
                  _vm.filters.date(_vm.emergency.to_time, "HH:mm DD/MM")
                ),
              },
            })
          : _vm._e(),
        _c("img", { attrs: { src: _vm.imgUrl, alt: _vm.$t("Eemergency") } }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }