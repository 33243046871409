var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    { staticClass: "editmakeline", on: { close: _vm.close } },
    [
      _c("div", { staticClass: "popup__content" }, [
        _c("div", { staticClass: "form-new-user" }, [
          _c(
            "form",
            {
              staticClass: "form-site-wrap",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.save.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "form-site" }, [
                _c("div", { staticClass: "form-site__content" }, [
                  _c(
                    "div",
                    { staticClass: "form-site__field-wrap" },
                    [
                      _c("form-site-field-text", {
                        attrs: { focus: "", title: "Name" },
                        model: {
                          value: _vm.makeLineCopy.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.makeLineCopy, "name", $$v)
                          },
                          expression: "makeLineCopy.name",
                        },
                      }),
                      _c("button", { attrs: { type: "submit" } }),
                      !_vm.mobile
                        ? _c("form-site-field-select-box", {
                            attrs: {
                              title: "Type",
                              options: ["Paper", "Stickers", "KDS"].map(
                                function (label, value) {
                                  return {
                                    label: label,
                                    value: value,
                                  }
                                }
                              ),
                              widthHeader: "240px",
                              width: "240px",
                            },
                            model: {
                              value: _vm.makeLineCopy.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.makeLineCopy, "type", $$v)
                              },
                              expression: "makeLineCopy.type",
                            },
                          })
                        : _vm._e(),
                      _vm.mobile
                        ? _c("form-site-field-select-box", {
                            attrs: {
                              title: "Type",
                              options: ["Paper", "Stickers", "KDS"].map(
                                function (label, value) {
                                  return {
                                    label: label,
                                    value: value,
                                  }
                                }
                              ),
                            },
                            model: {
                              value: _vm.makeLineCopy.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.makeLineCopy, "type", $$v)
                              },
                              expression: "makeLineCopy.type",
                            },
                          })
                        : _vm._e(),
                      _vm.makeLineCopy.type !== 2 && !_vm.mobile
                        ? _c("form-site-field-select-box", {
                            attrs: {
                              title: "Template",
                              widthHeader: "240px",
                              width: "240px",
                              options: _vm.templateOptions,
                            },
                            model: {
                              value: _vm.makeLineCopy.template,
                              callback: function ($$v) {
                                _vm.$set(_vm.makeLineCopy, "template", $$v)
                              },
                              expression: "makeLineCopy.template",
                            },
                          })
                        : _vm._e(),
                      _vm.makeLineCopy.type !== 2 && _vm.mobile
                        ? _c("form-site-field-select-box", {
                            attrs: {
                              title: "Template",
                              options: _vm.templateOptions,
                            },
                            model: {
                              value: _vm.makeLineCopy.template,
                              callback: function ($$v) {
                                _vm.$set(_vm.makeLineCopy, "template", $$v)
                              },
                              expression: "makeLineCopy.template",
                            },
                          })
                        : _vm._e(),
                      _vm.makeLineCopy.type !== 2 && !_vm.mobile
                        ? _c("form-site-field-select-box", {
                            attrs: {
                              title: "Reverse",
                              options: ["No", "Yes"].map(function (
                                label,
                                value
                              ) {
                                return {
                                  label: label,
                                  value: !!value,
                                }
                              }),
                              widthHeader: "240px",
                              width: "240px",
                            },
                            model: {
                              value: _vm.makeLineCopy.reverse,
                              callback: function ($$v) {
                                _vm.$set(_vm.makeLineCopy, "reverse", $$v)
                              },
                              expression: "makeLineCopy.reverse",
                            },
                          })
                        : _vm._e(),
                      _vm.makeLineCopy.type !== 2 && _vm.mobile
                        ? _c("form-site-field-select-box", {
                            attrs: {
                              title: "Reverse",
                              options: ["No", "Yes"].map(function (
                                label,
                                value
                              ) {
                                return {
                                  label: label,
                                  value: !!value,
                                }
                              }),
                            },
                            model: {
                              value: _vm.makeLineCopy.reverse,
                              callback: function ($$v) {
                                _vm.$set(_vm.makeLineCopy, "reverse", $$v)
                              },
                              expression: "makeLineCopy.reverse",
                            },
                          })
                        : _vm._e(),
                      !_vm.mobile
                        ? _c("form-site-field-select-box", {
                            attrs: {
                              title: _vm.$t("Primary language"),
                              options: ["Hebrew", "English"].map(function (
                                label,
                                value
                              ) {
                                return {
                                  label: label,
                                  value: value,
                                }
                              }),
                              widthHeader: "240px",
                              width: "240px",
                            },
                            model: {
                              value: _vm.makeLineCopy.primary_language,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.makeLineCopy,
                                  "primary_language",
                                  $$v
                                )
                              },
                              expression: "makeLineCopy.primary_language",
                            },
                          })
                        : _vm._e(),
                      _vm.mobile
                        ? _c("form-site-field-select-box", {
                            attrs: {
                              title: _vm.$t("Primary language"),
                              options: ["Hebrew", "English"].map(function (
                                label,
                                value
                              ) {
                                return {
                                  label: label,
                                  value: value,
                                }
                              }),
                            },
                            model: {
                              value: _vm.makeLineCopy.primary_language,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.makeLineCopy,
                                  "primary_language",
                                  $$v
                                )
                              },
                              expression: "makeLineCopy.primary_language",
                            },
                          })
                        : _vm._e(),
                      _vm.makeLineCopy.type === 2 &&
                      _vm.makeLineCopy.kds_settings
                        ? [
                            !_vm.mobile
                              ? _c("form-site-field-select-box", {
                                  attrs: {
                                    title: _vm.$t("Station Type"),
                                    widthHeader: "240px",
                                    width: "240px",
                                    options: ["Prepare", "Make", "Pack"].map(
                                      function (label, value) {
                                        return {
                                          label: label,
                                          value: value,
                                        }
                                      }
                                    ),
                                  },
                                  model: {
                                    value:
                                      _vm.makeLineCopy.kds_settings
                                        .station_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.makeLineCopy.kds_settings,
                                        "station_type",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "makeLineCopy.kds_settings.station_type",
                                  },
                                })
                              : _vm._e(),
                            _vm.mobile
                              ? _c("form-site-field-select-box", {
                                  attrs: {
                                    title: _vm.$t("Station Type"),
                                    options: ["Prepare", "Make", "Pack"].map(
                                      function (label, value) {
                                        return {
                                          label: label,
                                          value: value,
                                        }
                                      }
                                    ),
                                  },
                                  model: {
                                    value:
                                      _vm.makeLineCopy.kds_settings
                                        .station_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.makeLineCopy.kds_settings,
                                        "station_type",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "makeLineCopy.kds_settings.station_type",
                                  },
                                })
                              : _vm._e(),
                            !_vm.mobile
                              ? _c("form-site-field-select-box", {
                                  attrs: {
                                    title: _vm.$t("Make style"),
                                    widthHeader: "240px",
                                    width: "240px",
                                    options: [
                                      "H Shelves",
                                      "V Shelves",
                                      "Hold",
                                    ].map(function (label, value) {
                                      return {
                                        label: label,
                                        value: value,
                                      }
                                    }),
                                  },
                                  model: {
                                    value:
                                      _vm.makeLineCopy.kds_settings.make_style,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.makeLineCopy.kds_settings,
                                        "make_style",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "makeLineCopy.kds_settings.make_style",
                                  },
                                })
                              : _vm._e(),
                            _vm.mobile
                              ? _c("form-site-field-select-box", {
                                  attrs: {
                                    title: _vm.$t("Make style"),
                                    options: [
                                      "H Shelves",
                                      "V Shelves",
                                      "Hold",
                                    ].map(function (label, value) {
                                      return {
                                        label: label,
                                        value: !!value,
                                      }
                                    }),
                                  },
                                  model: {
                                    value:
                                      _vm.makeLineCopy.kds_settings.make_style,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.makeLineCopy.kds_settings,
                                        "make_style",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "makeLineCopy.kds_settings.make_style",
                                  },
                                })
                              : _vm._e(),
                            _c("form", { staticClass: "form-site__field" }, [
                              _c("div", {
                                staticClass: "form-site__label mt2",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Orders display")),
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "form-site__input mb20 flex" },
                                [
                                  _c("div", { staticClass: "input-radio" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.makeLineCopy.kds_settings
                                              .orders_display,
                                          expression:
                                            "makeLineCopy.kds_settings.orders_display",
                                        },
                                      ],
                                      attrs: {
                                        id: "od2",
                                        type: "radio",
                                        name: "radio",
                                      },
                                      domProps: {
                                        value: 2,
                                        checked: _vm._q(
                                          _vm.makeLineCopy.kds_settings
                                            .orders_display,
                                          2
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.makeLineCopy.kds_settings,
                                            "orders_display",
                                            2
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-radio__label",
                                        attrs: { for: "od2" },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "input-radio__mark",
                                        }),
                                        _c("span", {
                                          staticClass: "input-radio__text",
                                          domProps: { textContent: _vm._s(2) },
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "input-radio" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.makeLineCopy.kds_settings
                                              .orders_display,
                                          expression:
                                            "makeLineCopy.kds_settings.orders_display",
                                        },
                                      ],
                                      attrs: {
                                        id: "od4",
                                        type: "radio",
                                        name: "radio",
                                      },
                                      domProps: {
                                        value: 4,
                                        checked: _vm._q(
                                          _vm.makeLineCopy.kds_settings
                                            .orders_display,
                                          4
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.makeLineCopy.kds_settings,
                                            "orders_display",
                                            4
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-radio__label",
                                        attrs: { for: "od4" },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "input-radio__mark",
                                        }),
                                        _c("span", {
                                          staticClass: "input-radio__text",
                                          domProps: { textContent: _vm._s(4) },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "form-site__field" }),
                            _c("form", { staticClass: "form-site__field" }, [
                              _c("div", {
                                staticClass: "form-site__label mt2",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Shelves")),
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "form-site__input mb20 flex" },
                                [
                                  _c("div", { staticClass: "input-radio" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.makeLineCopy.kds_settings
                                              .shelves,
                                          expression:
                                            "makeLineCopy.kds_settings.shelves",
                                        },
                                      ],
                                      attrs: {
                                        id: "s4",
                                        type: "radio",
                                        name: "radio",
                                      },
                                      domProps: {
                                        value: 4,
                                        checked: _vm._q(
                                          _vm.makeLineCopy.kds_settings.shelves,
                                          4
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.makeLineCopy.kds_settings,
                                            "shelves",
                                            4
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-radio__label",
                                        attrs: { for: "s4" },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "input-radio__mark",
                                        }),
                                        _c("span", {
                                          staticClass: "input-radio__text",
                                          domProps: { textContent: _vm._s(4) },
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "input-radio" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.makeLineCopy.kds_settings
                                              .shelves,
                                          expression:
                                            "makeLineCopy.kds_settings.shelves",
                                        },
                                      ],
                                      attrs: {
                                        id: "s8",
                                        type: "radio",
                                        name: "radio",
                                      },
                                      domProps: {
                                        value: 8,
                                        checked: _vm._q(
                                          _vm.makeLineCopy.kds_settings.shelves,
                                          8
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.makeLineCopy.kds_settings,
                                            "shelves",
                                            8
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-radio__label",
                                        attrs: { for: "s8" },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "input-radio__mark",
                                        }),
                                        _c("span", {
                                          staticClass: "input-radio__text",
                                          domProps: { textContent: _vm._s(8) },
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "input-radio" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.makeLineCopy.kds_settings
                                              .shelves,
                                          expression:
                                            "makeLineCopy.kds_settings.shelves",
                                        },
                                      ],
                                      attrs: {
                                        id: "s12",
                                        type: "radio",
                                        name: "radio",
                                      },
                                      domProps: {
                                        value: 12,
                                        checked: _vm._q(
                                          _vm.makeLineCopy.kds_settings.shelves,
                                          12
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.makeLineCopy.kds_settings,
                                            "shelves",
                                            12
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-radio__label",
                                        attrs: { for: "s12" },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "input-radio__mark",
                                        }),
                                        _c("span", {
                                          staticClass: "input-radio__text",
                                          domProps: { textContent: _vm._s(12) },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _c("form", { staticClass: "form-site__field" }, [
                              _c("div", {
                                staticClass: "form-site__label mt2",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("Shelves columns")
                                  ),
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "form-site__input mb20 flex" },
                                [
                                  _c("div", { staticClass: "input-radio" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.makeLineCopy.kds_settings
                                              .shelves_cols,
                                          expression:
                                            "makeLineCopy.kds_settings.shelves_cols",
                                        },
                                      ],
                                      attrs: {
                                        id: "sc2",
                                        type: "radio",
                                        name: "radio",
                                      },
                                      domProps: {
                                        value: 2,
                                        checked: _vm._q(
                                          _vm.makeLineCopy.kds_settings
                                            .shelves_cols,
                                          2
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.makeLineCopy.kds_settings,
                                            "shelves_cols",
                                            2
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-radio__label",
                                        attrs: { for: "sc2" },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "input-radio__mark",
                                        }),
                                        _c("span", {
                                          staticClass: "input-radio__text",
                                          domProps: { textContent: _vm._s(2) },
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "input-radio" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.makeLineCopy.kds_settings
                                              .shelves_cols,
                                          expression:
                                            "makeLineCopy.kds_settings.shelves_cols",
                                        },
                                      ],
                                      attrs: {
                                        id: "sc3",
                                        type: "radio",
                                        name: "radio",
                                      },
                                      domProps: {
                                        value: 3,
                                        checked: _vm._q(
                                          _vm.makeLineCopy.kds_settings
                                            .shelves_cols,
                                          3
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.makeLineCopy.kds_settings,
                                            "shelves_cols",
                                            3
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-radio__label",
                                        attrs: { for: "sc3" },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "input-radio__mark",
                                        }),
                                        _c("span", {
                                          staticClass: "input-radio__text",
                                          domProps: { textContent: _vm._s(3) },
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "input-radio" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.makeLineCopy.kds_settings
                                              .shelves_cols,
                                          expression:
                                            "makeLineCopy.kds_settings.shelves_cols",
                                        },
                                      ],
                                      attrs: {
                                        id: "sc4",
                                        type: "radio",
                                        name: "radio",
                                      },
                                      domProps: {
                                        value: 4,
                                        checked: _vm._q(
                                          _vm.makeLineCopy.kds_settings
                                            .shelves_cols,
                                          4
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.makeLineCopy.kds_settings,
                                            "shelves_cols",
                                            4
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-radio__label",
                                        attrs: { for: "sc4" },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "input-radio__mark",
                                        }),
                                        _c("span", {
                                          staticClass: "input-radio__text",
                                          domProps: { textContent: _vm._s(4) },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-site__field switch-wrap active flex bet",
                              },
                              [
                                _c("span", {
                                  staticClass: "swich-name mie12",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("Allow reshuffle?")
                                    ),
                                  },
                                }),
                                _c("swich-button", {
                                  model: {
                                    value:
                                      _vm.makeLineCopy.kds_settings
                                        .allow_reshuffle,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.makeLineCopy.kds_settings,
                                        "allow_reshuffle",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "makeLineCopy.kds_settings.allow_reshuffle",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-site__field switch-wrap active flex bet",
                              },
                              [
                                _c("span", {
                                  staticClass: "swich-name mie12",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Select cook")),
                                  },
                                }),
                                _c("swich-button", {
                                  model: {
                                    value:
                                      _vm.makeLineCopy.kds_settings.select_cook,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.makeLineCopy.kds_settings,
                                        "select_cook",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "makeLineCopy.kds_settings.select_cook",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("form", { staticClass: "form-site__field" }, [
                              _c("div", {
                                staticClass: "form-site__label mt2",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("Bump dependency")
                                  ),
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "form-site__input mb20 flex" },
                                [
                                  _c("div", { staticClass: "input-radio" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.makeLineCopy.kds_settings
                                              .bump_dependency,
                                          expression:
                                            "makeLineCopy.kds_settings.bump_dependency",
                                        },
                                      ],
                                      attrs: {
                                        id: "bdp",
                                        type: "radio",
                                        name: "radio",
                                      },
                                      domProps: {
                                        value: 0,
                                        checked: _vm._q(
                                          _vm.makeLineCopy.kds_settings
                                            .bump_dependency,
                                          0
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.makeLineCopy.kds_settings,
                                            "bump_dependency",
                                            0
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-radio__label",
                                        attrs: { for: "bdp" },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "input-radio__mark",
                                        }),
                                        _c("span", {
                                          staticClass: "input-radio__text",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Prepare")
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "input-radio" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.makeLineCopy.kds_settings
                                              .bump_dependency,
                                          expression:
                                            "makeLineCopy.kds_settings.bump_dependency",
                                        },
                                      ],
                                      attrs: {
                                        id: "bdm",
                                        type: "radio",
                                        name: "radio",
                                      },
                                      domProps: {
                                        value: 1,
                                        checked: _vm._q(
                                          _vm.makeLineCopy.kds_settings
                                            .bump_dependency,
                                          1
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.makeLineCopy.kds_settings,
                                            "bump_dependency",
                                            1
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-radio__label",
                                        attrs: { for: "bdm" },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "input-radio__mark",
                                        }),
                                        _c("span", {
                                          staticClass: "input-radio__text",
                                          domProps: {
                                            textContent: _vm._s(_vm.$t("Make")),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "input-radio" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.makeLineCopy.kds_settings
                                              .bump_dependency,
                                          expression:
                                            "makeLineCopy.kds_settings.bump_dependency",
                                        },
                                      ],
                                      attrs: {
                                        id: "bdb2b",
                                        type: "radio",
                                        name: "radio",
                                      },
                                      domProps: {
                                        value: 2,
                                        checked: _vm._q(
                                          _vm.makeLineCopy.kds_settings
                                            .bump_dependency,
                                          2
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.makeLineCopy.kds_settings,
                                            "bump_dependency",
                                            2
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-radio__label",
                                        attrs: { for: "bdb2b" },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "input-radio__mark",
                                        }),
                                        _c("span", {
                                          staticClass: "input-radio__text",
                                          domProps: {
                                            textContent: _vm._s(_vm.$t("B2B")),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-site__field switch-wrap active flex bet",
                              },
                              [
                                _c("span", {
                                  staticClass: "swich-name mie12",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Must bump?")),
                                  },
                                }),
                                _c("swich-button", {
                                  model: {
                                    value:
                                      _vm.makeLineCopy.kds_settings.must_bump,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.makeLineCopy.kds_settings,
                                        "must_bump",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "makeLineCopy.kds_settings.must_bump",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("form", { staticClass: "form-site__field" }, [
                              _c("div", {
                                staticClass: "form-site__label mt2",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Multi screens")),
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "form-site__input mb20 flex" },
                                [
                                  _c("div", { staticClass: "input-radio" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.makeLineCopy.kds_settings
                                              .multi_screens,
                                          expression:
                                            "makeLineCopy.kds_settings.multi_screens",
                                        },
                                      ],
                                      attrs: {
                                        id: "msto",
                                        type: "radio",
                                        name: "radio",
                                      },
                                      domProps: {
                                        value: 0,
                                        checked: _vm._q(
                                          _vm.makeLineCopy.kds_settings
                                            .multi_screens,
                                          0
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.makeLineCopy.kds_settings,
                                            "multi_screens",
                                            0
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-radio__label",
                                        attrs: { for: "msto" },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "input-radio__mark",
                                        }),
                                        _c("span", {
                                          staticClass: "input-radio__text",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Timed Order")
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "input-radio" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.makeLineCopy.kds_settings
                                              .multi_screens,
                                          expression:
                                            "makeLineCopy.kds_settings.multi_screens",
                                        },
                                      ],
                                      attrs: {
                                        id: "msdih",
                                        type: "radio",
                                        name: "radio",
                                      },
                                      domProps: {
                                        value: 1,
                                        checked: _vm._q(
                                          _vm.makeLineCopy.kds_settings
                                            .multi_screens,
                                          1
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.makeLineCopy.kds_settings,
                                            "multi_screens",
                                            1
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-radio__label",
                                        attrs: { for: "msdih" },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "input-radio__mark",
                                        }),
                                        _c("span", {
                                          staticClass: "input-radio__text",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Dine in holds")
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-site__field switch-wrap active flex bet",
                              },
                              [
                                _c("span", {
                                  staticClass: "swich-name mie12",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("Show preparation ahead?")
                                    ),
                                  },
                                }),
                                _c("swich-button", {
                                  model: {
                                    value:
                                      _vm.makeLineCopy.kds_settings
                                        .show_pre_ahead,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.makeLineCopy.kds_settings,
                                        "show_pre_ahead",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "makeLineCopy.kds_settings.show_pre_ahead",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      (_vm.makeLineCopy.template === 1 ||
                        _vm.makeLineCopy.template === 0) &&
                      _vm.makeLineCopy.summary_triggers
                        ? [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  width: "100%",
                                  "justify-content": "center",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "availability-check-list rp5 w100",
                                  },
                                  _vm._l(_vm.typesEnumValues, function (k) {
                                    return _c(
                                      "label",
                                      {
                                        key: _vm.typeEnumToLowerStr(k),
                                        staticClass: "availability-check",
                                        class: {
                                          active:
                                            _vm.selected ===
                                            _vm.typeEnumToLowerStr(k),
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.key = k
                                            _vm.selected =
                                              _vm.typeEnumToLowerStr(k)
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.selected ===
                                                _vm.typeEnumToLowerStr(k),
                                              expression:
                                                "selected === typeEnumToLowerStr(k)",
                                            },
                                          ],
                                          attrs: {
                                            name: "availability-check-v",
                                            width: "16",
                                            height: "12",
                                          },
                                        }),
                                        _c("svg-icon", {
                                          staticClass: "icon",
                                          attrs: {
                                            name:
                                              "ic-availability-" +
                                              _vm.typeEnumToLowerStr(k),
                                            width: "30",
                                            height: "25",
                                          },
                                        }),
                                        _c("span", {
                                          staticClass: "input-check__text",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(_vm.typeEnumToStr(k))
                                            ),
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                ),
                              ]
                            ),
                            _vm.count >= 1
                              ? _c(
                                  "div",
                                  { staticClass: "table-container w100" },
                                  [
                                    _vm.selected
                                      ? _c("table", [
                                          _c("thead", [
                                            _c(
                                              "tr",
                                              _vm._l(
                                                [
                                                  "open",
                                                  "in_preparation",
                                                  "ready",
                                                  "on_delivery",
                                                  "closed",
                                                  "canceled",
                                                ],
                                                function (km, i) {
                                                  return _c("td", {
                                                    key: km + i,
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t(
                                                          _vm.capitalize(km)
                                                        )
                                                      ),
                                                    },
                                                  })
                                                }
                                              ),
                                              0
                                            ),
                                          ]),
                                          _c("tbody", [
                                            _c(
                                              "tr",
                                              _vm._l(
                                                [
                                                  "open",
                                                  "in_preparation",
                                                  "ready",
                                                  "on_delivery",
                                                  "closed",
                                                  "canceled",
                                                ],
                                                function (ki, i) {
                                                  return _c("td", { key: ki }, [
                                                    _c(
                                                      "label",
                                                      [
                                                        _c("checkbox", {
                                                          attrs: {
                                                            name: ki,
                                                            value:
                                                              _vm.makeLineCopy.summary_triggers[
                                                                _vm.selected
                                                              ].includes(i),
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              _vm.inputTrigger(
                                                                i,
                                                                _vm.selected
                                                              )
                                                              _vm.count += 1
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ])
                                                }
                                              ),
                                              0
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "form-site__field make-line_checkboxes",
                        },
                        [
                          _c("div", { staticClass: "input-check" }, [
                            _c(
                              "label",
                              { staticClass: "input-check__label" },
                              [
                                _c("checkbox", {
                                  attrs: { name: "is_free" },
                                  model: {
                                    value: _vm.makeLineCopy.print_left_to_pay,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.makeLineCopy,
                                        "print_left_to_pay",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "makeLineCopy.print_left_to_pay",
                                  },
                                }),
                                _c("span", {
                                  staticClass: "input-check__text",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("printLeft")),
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm.makeLineCopy.template === 3
                            ? _c("div", { staticClass: "input-check" }, [
                                _c(
                                  "label",
                                  { staticClass: "input-check__label" },
                                  [
                                    _c("checkbox", {
                                      attrs: { name: "is_free" },
                                      model: {
                                        value:
                                          _vm.makeLineCopy.print_pizza_symbol,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.makeLineCopy,
                                            "print_pizza_symbol",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "makeLineCopy.print_pizza_symbol",
                                      },
                                    }),
                                    _c("span", {
                                      staticClass: "input-check__text",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Pizza Extras Selection")
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "input-check" }, [
                            _c(
                              "label",
                              { staticClass: "input-check__label" },
                              [
                                _vm.makeLineCopy.template == 2
                                  ? _c("checkbox", {
                                      attrs: { name: "is_free" },
                                      model: {
                                        value: _vm.makeLineCopy.should_cut,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.makeLineCopy,
                                            "should_cut",
                                            $$v
                                          )
                                        },
                                        expression: "makeLineCopy.should_cut",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.makeLineCopy.template == 2
                                  ? _c("span", {
                                      staticClass: "input-check__text",
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Cut")),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "input-check" }, [
                            _c(
                              "label",
                              { staticClass: "input-check__label" },
                              [
                                _vm.makeLineCopy.type == 1
                                  ? _c("checkbox", {
                                      attrs: { name: "is_free" },
                                      model: {
                                        value: _vm.makeLineCopy.cat_print_label,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.makeLineCopy,
                                            "cat_print_label",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "makeLineCopy.cat_print_label",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.makeLineCopy.type == 1
                                  ? _c("span", {
                                      staticClass: "input-check__text",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Cut per product")
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c("div", [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("print only that paid"))),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "form-site__field make-line_checkboxes",
                          },
                          [
                            _c("div", { staticClass: "input-check" }, [
                              _c(
                                "label",
                                { staticClass: "input-check__label" },
                                [
                                  _c("checkbox", {
                                    attrs: { name: "print_only_paid" },
                                    model: {
                                      value: _vm.makeLineCopy.print_only_paid,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.makeLineCopy,
                                          "print_only_paid",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "makeLineCopy.print_only_paid",
                                    },
                                  }),
                                  _c("span", {
                                    staticClass: "input-check__text",
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("store")),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "input-check" }, [
                              _c(
                                "label",
                                { staticClass: "input-check__label" },
                                [
                                  _c("checkbox", {
                                    attrs: { name: "print_only_paid" },
                                    model: {
                                      value:
                                        _vm.makeLineCopy.print_only_paid_online,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.makeLineCopy,
                                          "print_only_paid_online",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "makeLineCopy.print_only_paid_online",
                                    },
                                  }),
                                  _c("span", {
                                    staticClass: "input-check__text",
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("online")),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ],
                    2
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "form-site__btn md-none" },
                [
                  _vm.makeLineCopy.id
                    ? _c("remove-btn", { on: { click: _vm.removeMakeLine } })
                    : _c("div"),
                  _c("button", {
                    staticClass: "btn",
                    attrs: { type: "submit" },
                    domProps: { textContent: _vm._s(_vm.$t("Save")) },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
                _c(
                  "button",
                  { staticClass: "save-btn", attrs: { type: "submit" } },
                  [
                    _c("svg-icon", {
                      staticClass: "ic-edit",
                      attrs: { name: "ic-save-btn", width: "93", height: "93" },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }