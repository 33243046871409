var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-dialog", { on: { close: _vm.close } }, [
    _c("div", { staticClass: "popup__content" }, [
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("div", { staticClass: "areas-edit" }, [
            _c("div", { staticClass: "areas-edit__content" }, [
              _c("div", { staticClass: "areas-edit__sect" }, [
                _c("div", { staticClass: "areas-edit__list" }, [
                  _c("div", { staticClass: "areas-edit__field" }, [
                    _c("div", {
                      staticClass: "areas-edit__label",
                      domProps: { textContent: _vm._s(_vm.$t("Table number")) },
                    }),
                    _c(
                      "div",
                      { staticClass: "input-number" },
                      [
                        _c("form-site-field-text", {
                          model: {
                            value: _vm.table_number,
                            callback: function ($$v) {
                              _vm.table_number = $$v
                            },
                            expression: "table_number",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "areas-edit__field" }, [
                    _c("div", {
                      staticClass: "areas-edit__label",
                      domProps: {
                        textContent: _vm._s(_vm.$t("Number of dinners")),
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "input-number" },
                      [
                        _c("form-site-field-text", {
                          model: {
                            value: _vm.number_of_dinners,
                            callback: function ($$v) {
                              _vm.number_of_dinners = $$v
                            },
                            expression: "number_of_dinners",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "areas-edit__btn" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn_border",
                  attrs: { href: "#" },
                  on: { click: _vm.remove },
                },
                [
                  _c("svg-icon", {
                    attrs: { name: "ic-bin", width: "20", height: "17" },
                  }),
                  _c("span", {
                    domProps: { textContent: _vm._s(_vm.$t("DELETE")) },
                  }),
                ],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "btn",
                  attrs: { type: "submit" },
                  on: { click: _vm.save },
                },
                [
                  _c("span", {
                    domProps: { textContent: _vm._s(_vm.$t("Save")) },
                  }),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }