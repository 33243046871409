var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.show
        ? _c(
            "base-dialog",
            {
              attrs: { hideCloseBar: "", width: "1017px" },
              on: { close: _vm.close },
            },
            [
              _c("div", { staticClass: "order-detail" }, [
                _c("div", { staticClass: "order-detail-top" }, [
                  _vm.user
                    ? _c("div", { staticClass: "order-detail-user" }, [
                        _c(
                          "button",
                          { on: { click: _vm.goToUser } },
                          [
                            _c("user-avatar", {
                              attrs: {
                                user: _vm.user,
                                size: 80,
                                "img-class": "order-detail-user__photo",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "order-detail-user__info" }, [
                          _c(
                            "div",
                            { staticClass: "order-detail-user__name" },
                            [
                              _c("button", {
                                domProps: {
                                  textContent: _vm._s(_vm.user.name),
                                },
                                on: { click: _vm.goToUser },
                              }),
                            ]
                          ),
                          _vm.user.phone
                            ? _c(
                                "button",
                                {
                                  staticClass: "order-detail-user__phone",
                                  on: { click: _vm.dial },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      name: "ic-calls",
                                      width: "12",
                                      height: "12",
                                    },
                                  }),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(_vm.user.phone),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                  _c("div", [
                    _c("div", { staticClass: "order-detail-top__num" }, [
                      _vm.isAdmin
                        ? _c("div", [
                            _vm._v(
                              _vm._s(_vm.$t("Id")) +
                                " " +
                                _vm._s(_vm.order.unique_id)
                            ),
                          ])
                        : _vm._e(),
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.$t("Branch id")) +
                            " " +
                            _vm._s(_vm.order.branch_order_id)
                        ),
                      ]),
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.$t("Daily id")) +
                            " " +
                            _vm._s(_vm.order.daily_order_id)
                        ),
                      ]),
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.$t("Z Number")) +
                            " " +
                            _vm._s(_vm.order.zed_id)
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "order-detail-top__branch-name" },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("Branch")) +
                            "\n              " +
                            _vm._s(
                              _vm.he || !_vm.order.branch.name_en
                                ? _vm.order.branch_name
                                : _vm.order.branch.name_en
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticStyle: { "text-align": "center" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "order-detail-status",
                        class:
                          "select-color color-" +
                          _vm.mockOrderService.statusesClasses[_vm.order.status]
                            .class +
                          " " +
                          _vm.headerClass,
                      },
                      [
                        _c("div", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                [
                                  "Opened",
                                  "in_preparation",
                                  "Ready",
                                  "in_delivery",
                                  "Closed",
                                  "Canceled",
                                  "unresolved_payment",
                                ][_vm.order.status]
                              )
                            ),
                          },
                        }),
                        _vm.order && _vm.order.arrival_time && _vm.order.date
                          ? _c("arrival-time", {
                              attrs: {
                                startDate: _vm.order.date,
                                arrivalDate: _vm.order.arrival_time,
                              },
                              on: {
                                "set-class": function (mode) {
                                  return (_vm.headerClass = "mode-" + mode)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "order-detail-main" }, [
                  _c("div", { staticClass: "tabs-page active" }, [
                    _c("div", { staticClass: "tabs-page__list" }, [
                      _c("div", {
                        staticClass: "tabs-page__item",
                        class: { active: _vm.activeTab === 1 },
                        domProps: { textContent: _vm._s(_vm.$t("Details")) },
                        on: {
                          click: function ($event) {
                            _vm.activeTab = 1
                          },
                        },
                      }),
                      _c("div", {
                        staticClass: "tabs-page__item",
                        class: { active: _vm.activeTab === 2 },
                        domProps: { textContent: _vm._s(_vm.$t("Payments")) },
                        on: {
                          click: function ($event) {
                            _vm.activeTab = 2
                          },
                        },
                      }),
                      _c(
                        "button",
                        {
                          staticClass: "tabs-page__item open-items",
                          on: { click: _vm.openHistoryItems },
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "28",
                                height: "28",
                                viewBox: "0 0 28 28",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                staticClass: "main-fill",
                                attrs: {
                                  d: "M6.29771 21.9197C6.13986 21.7674 6.04229 21.5568 6.04223 21.3245L6.04227 14.0131C6.04227 13.548 6.43288 13.1709 6.91462 13.171C7.39627 13.171 7.7868 13.548 7.78692 14.013L7.78673 20.4825L14.4889 20.4824C14.9706 20.4825 15.3611 20.8594 15.3611 21.3243C15.3613 21.7894 14.9707 22.1665 14.4887 22.1664L6.91422 22.1665C6.67349 22.1664 6.45551 22.0721 6.29771 21.9197Z",
                                },
                              }),
                              _c("path", {
                                staticClass: "main-fill",
                                attrs: {
                                  d: "M21.6984 6.0783C21.8562 6.23067 21.9538 6.44121 21.9539 6.6735L21.9538 13.985C21.9538 14.4501 21.5632 14.8271 21.0815 14.827C20.5998 14.827 20.2093 14.4501 20.2092 13.985L20.2094 7.51559L13.5072 7.51564C13.0255 7.51555 12.635 7.13862 12.635 6.67372C12.6348 6.2086 13.0254 5.83156 13.5073 5.83167L21.0819 5.83158C21.3226 5.83167 21.5406 5.92598 21.6984 6.0783Z",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "tabs-page__content" }, [
                      _c(
                        "div",
                        {
                          staticClass: "tabs-page__content-item",
                          class: { active: _vm.activeTab === 1 },
                        },
                        [
                          _c("div", { staticClass: "tabs-page__history" }, [
                            _c(
                              "div",
                              {
                                staticClass: "history-list-wrap",
                                class: {
                                  "has-scroll":
                                    _vm.orderCartItems &&
                                    _vm.orderCartItems.length > 3,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "history-list disable-scrollbars",
                                  },
                                  [
                                    _c("history-order-item", {
                                      attrs: {
                                        order: _vm.order,
                                        inorder: "",
                                        "show-order-info": false,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "history-list-wrap__arr" },
                                  [
                                    _c("svg-icon", {
                                      staticClass: "icon ic-arrow-down",
                                      attrs: {
                                        name: "ic-arrow-down",
                                        width: "14",
                                        height: "8",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm.order.type == 0
                              ? _c(
                                  "div",
                                  { staticClass: "history-list-result" },
                                  [
                                    _c("div", {
                                      staticClass: "history-list-result__title",
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Delivery")),
                                      },
                                    }),
                                    _vm.order.cart &&
                                    _vm.order.cart.delivery_price >= 0 &&
                                    _vm.restaurantCurrencyInfo == null
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "history-list-result__val",
                                          },
                                          [
                                            _vm._v(
                                              "\n                      ₪" +
                                                _vm._s(
                                                  _vm.order.cart.delivery_price
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.order.cart &&
                                    _vm.order.cart.delivery_price < 0 &&
                                    _vm.he &&
                                    _vm.restaurantCurrencyInfo == null
                                      ? _c("div", {
                                          staticClass:
                                            "history-list-result__val",
                                          domProps: {
                                            textContent: _vm._s(
                                              "₪" +
                                                _vm.order.cart.delivery_price *
                                                  -1 +
                                                "-"
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.order.cart &&
                                    _vm.order.cart.delivery_price < 0 &&
                                    !_vm.he &&
                                    _vm.restaurantCurrencyInfo == null
                                      ? _c("div", {
                                          staticClass:
                                            "history-list-result__val",
                                          domProps: {
                                            textContent: _vm._s(
                                              "-₪" +
                                                _vm.order.cart.delivery_price *
                                                  -1
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.order.cart &&
                                    _vm.order.cart.delivery_price >= 0 &&
                                    _vm.restaurantCurrencyInfo !== null
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "history-list-result__val",
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.restaurantCurrencySymbol
                                                ) +
                                                _vm._s(
                                                  _vm.order.cart.delivery_price
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.order.cart &&
                                    _vm.order.cart.delivery_price < 0 &&
                                    _vm.he &&
                                    _vm.restaurantCurrencyInfo !== null
                                      ? _c("div", {
                                          staticClass:
                                            "history-list-result__val",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.restaurantCurrencySymbol +
                                                _vm.order.cart.delivery_price *
                                                  -1 +
                                                "-"
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.order.cart &&
                                    _vm.order.cart.delivery_price < 0 &&
                                    !_vm.he &&
                                    _vm.restaurantCurrencyInfo !== null
                                      ? _c("div", {
                                          staticClass:
                                            "history-list-result__val",
                                          domProps: {
                                            textContent: _vm._s(
                                              "-" +
                                                _vm.restaurantCurrencySymbol +
                                                _vm.order.cart.delivery_price *
                                                  -1
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            _vm.order.sum >= 0
                              ? _c(
                                  "div",
                                  { staticClass: "history-list-result" },
                                  [
                                    _c("div", {
                                      staticClass: "history-list-result__title",
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Total")),
                                      },
                                    }),
                                    _vm.restaurantCurrencyInfo == null
                                      ? _c("div", {
                                          staticClass:
                                            "history-list-result__val",
                                          staticStyle: { "text-align": "end" },
                                          attrs: { dir: "ltr" },
                                          domProps: {
                                            textContent: _vm._s(
                                              "₪" + _vm.order.sum
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.restaurantCurrencyInfo !== null
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "history-list-result__val",
                                            staticStyle: {
                                              "text-align": "end",
                                            },
                                            attrs: { dir: "ltr" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.restaurantCurrencySymbol
                                              ) + _vm._s(_vm.order.sum)
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "history-list-result" },
                                  [
                                    _c("div", {
                                      staticClass: "history-list-result__title",
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Total")),
                                      },
                                    }),
                                    _vm.restaurantCurrencyInfo == null
                                      ? _c("div", {
                                          staticClass:
                                            "history-list-result__val",
                                          staticStyle: { "text-align": "end" },
                                          attrs: { dir: "ltr" },
                                          domProps: {
                                            textContent: _vm._s(
                                              "-₪" + _vm.order.sum * -1
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.restaurantCurrencyInfo !== null
                                      ? _c("div", {
                                          staticClass:
                                            "history-list-result__val",
                                          staticStyle: { "text-align": "end" },
                                          attrs: { dir: "ltr" },
                                          domProps: {
                                            textContent: _vm._s(
                                              "-" +
                                                _vm.restaurantCurrencySymbol +
                                                _vm.order.sum * -1
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "tabs-page__content-item",
                          class: { active: _vm.activeTab === 2 },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "pay-list" },
                            _vm._l(
                              _vm.order.payments,
                              function (payment, payIndex) {
                                return _c(
                                  "div",
                                  {
                                    key: payIndex,
                                    staticClass: "pay-list__item",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "pay-list__content" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "pay-list__icon" },
                                          [
                                            payment.type === 6
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/multipass.png",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 13
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/haat.svg",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 16
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/cheque.svg",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 15
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/mishloha.png",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 17
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/bank-transfer.svg",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 0
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/money.svg",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 19
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/kashcash.png",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 20
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/businesslogic.png",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            [1, 3, 5, 12, 26].includes(
                                              payment.type
                                            )
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/credit-card.svg",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 4 ||
                                            payment.type === 18
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/compensation_points.svg",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 2
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/stars.svg",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 6
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/multipass.png",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            [7].includes(payment.type)
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/business_client.svg",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 8
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/cibus.png",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 9
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/tenbis.png",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 10
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/bit.png",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 11
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/goodi.png",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 14
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/wolt.png",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 24
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/uber_eats.png",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 25
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/deliveroo.png",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "pay-list__info" },
                                          [
                                            payment.name
                                              ? _c("div", {
                                                  staticClass: "pay-list__name",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      payment.name
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "pay-list__price",
                                                staticStyle: {
                                                  "text-align": "end",
                                                },
                                                attrs: { dir: "ltr" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                          " +
                                                    _vm._s(
                                                      _vm.restaurantCurrencySymbol
                                                    ) +
                                                    _vm._s(payment.amount) +
                                                    "\n                        "
                                                ),
                                              ]
                                            ),
                                            payment.tip_amount
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "pay-list__price_with_tip",
                                                    staticStyle: {
                                                      "text-align": "end",
                                                    },
                                                    attrs: { dir: "ltr" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          (" +
                                                        _vm._s(
                                                          _vm.restaurantCurrencySymbol
                                                        ) +
                                                        _vm._s(
                                                          payment.amount -
                                                            payment.tip_amount
                                                        ) +
                                                        " + " +
                                                        _vm._s(
                                                          _vm.restaurantCurrencySymbol
                                                        ) +
                                                        _vm._s(
                                                          payment.tip_amount
                                                        ) +
                                                        " " +
                                                        _vm._s(_vm.$t("Tip")) +
                                                        ")\n                        "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            payment.last_digits
                                              ? _c("div", {
                                                  staticClass: "pay-list__text",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t("Card ends with") +
                                                        " " +
                                                        payment.last_digits
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            _c("div", {
                                              staticClass: "pay-list__text",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    [
                                                      "Cash",
                                                      "Internet credit card",
                                                      "Points",
                                                      "Credit emv",
                                                      "__Compensation",
                                                      "Admin discount",
                                                      "Multipass",
                                                      "Business client",
                                                      "Cibus",
                                                      "Tenbis",
                                                      "Bit",
                                                      "Goodi",
                                                      "credit_input",
                                                      "Haat delivery",
                                                      "Wolt",
                                                      "Mishloha ",
                                                      "Cheque",
                                                      "Bank transfer",
                                                      "Gift card",
                                                      "Kashcash",
                                                    ][payment.type]
                                                  )
                                                ),
                                              },
                                            }),
                                            _vm.paymentMetaTerminalId(payment)
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "pay-list__text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "charged at terminal"
                                                        )
                                                      ) +
                                                        ": " +
                                                        _vm._s(
                                                          _vm.paymentMetaTerminalId(
                                                            payment
                                                          )
                                                        )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            payment.business_client
                                              ? _c("div", {
                                                  staticClass: "pay-list__text",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.paymentMetaBusinessClientName(
                                                        payment
                                                      ) +
                                                        " - " +
                                                        _vm.paymentMetaBusinessClientPhone(
                                                          payment
                                                        )
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                    payment.amount - payment.refunded_amount &&
                                    payment.amount > 0
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn_small btn_green",
                                            on: {
                                              click: function ($event) {
                                                return _vm.refund(payment)
                                              },
                                            },
                                          },
                                          [
                                            _c("svg-icon", {
                                              staticClass:
                                                "m-i-end-1ch ic-white",
                                              attrs: {
                                                name: "ic-repeat",
                                                width: "9",
                                                height: "10",
                                              },
                                            }),
                                            _c("span", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t("Refund")
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _c("div", {
                                          staticClass: "refunded",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Refunded completely")
                                            ),
                                          },
                                        }),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "order-detail-form" }, [
                    _c("div", { staticClass: "order-detail-form__fields" }, [
                      _c("div", { staticClass: "order-detail-form__field" }, [
                        _c("div", {
                          staticClass: "order-detail-form__title",
                          domProps: { textContent: _vm._s(_vm.$t("Comments")) },
                        }),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.order.notes,
                              expression: "order.notes",
                            },
                          ],
                          attrs: {
                            placeholder: _vm.$t(
                              "Dressing on the side? No pickles? Let us know here"
                            ),
                          },
                          domProps: { value: _vm.order.notes },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.order, "notes", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "order-detail-form__field" }, [
                        _c("div", {
                          staticClass: "order-detail-form__title",
                          domProps: {
                            textContent: _vm._s(_vm.$t("Email for invoice")),
                          },
                        }),
                        _c("div", { staticClass: "order-detail-form__email" }, [
                          _vm.user
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.email,
                                    expression: "user.email",
                                  },
                                ],
                                attrs: {
                                  type: "email",
                                  placeholder: "example@email.com",
                                },
                                domProps: { value: _vm.user.email },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.user,
                                      "email",
                                      $event.target.value
                                    )
                                  },
                                },
                              })
                            : _c("input", {
                                attrs: {
                                  type: "email",
                                  placeholder: "example@email.com",
                                },
                              }),
                          _c(
                            "button",
                            { staticClass: "btn btn_small btn_green" },
                            [
                              _c("svg-icon", {
                                staticClass: "ic-white",
                                attrs: {
                                  name: "ic-repeat",
                                  width: "9",
                                  height: "10",
                                },
                              }),
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Send again")),
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "order-detail-form__count-wrap" },
                        [
                          _c("div", {
                            staticClass: "order-detail-form__title",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Number of diners")),
                            },
                          }),
                          _c("div", {
                            staticClass: "order-detail-form__count",
                            domProps: {
                              textContent: _vm._s(_vm.order.number_of_diners),
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "button-bottom" }, [
                  _c("div", { staticClass: "column-button" }, [
                    _c(
                      "button",
                      {
                        staticClass: "share",
                        attrs: { type: "button" },
                        on: { click: _vm.toggleOrderActionsDropdown },
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "shadow",
                          attrs: { name: "ic-more", width: "24", height: "24" },
                        }),
                      ],
                      1
                    ),
                    _c("span", {
                      staticClass: "column-span",
                      domProps: { textContent: _vm._s(_vm.$t("Actions")) },
                    }),
                    _vm.orderActionsDropdown
                      ? _c("div", { staticClass: "order-actions-dd" }, [
                          _c("div", { staticClass: "column-button" }, [
                            _c(
                              "button",
                              {
                                staticClass: "share",
                                attrs: { type: "button" },
                                on: { click: _vm.share },
                              },
                              [
                                _c("svg-icon", {
                                  staticClass: "shadow",
                                  attrs: {
                                    name: "ic-share",
                                    width: "24",
                                    height: "24",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("span", {
                              staticClass: "column-span",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Share")),
                              },
                            }),
                          ]),
                          _vm.showDragonButton
                            ? _c("div", { staticClass: "column-button" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "share",
                                    attrs: { type: "button" },
                                    on: { click: _vm.exportDragon },
                                  },
                                  [
                                    _c("svg-icon", {
                                      staticClass: "shadow",
                                      attrs: {
                                        name: "ic-dragon",
                                        width: "24",
                                        height: "24",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("span", {
                                  staticClass: "column-span",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Dragon")),
                                  },
                                }),
                              ])
                            : _vm._e(),
                          _vm.showDragonButton
                            ? _c("div", { staticClass: "column-button" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "share",
                                    attrs: { type: "button" },
                                    on: { click: _vm.resendToDragon },
                                  },
                                  [
                                    _c("svg-icon", {
                                      staticClass: "shadow",
                                      attrs: {
                                        name: "ic-dragon",
                                        width: "24",
                                        height: "24",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("span", {
                                  staticClass: "column-span",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("Resend to Dragon")
                                    ),
                                  },
                                }),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "column-button" }, [
                            _c(
                              "button",
                              {
                                attrs: { type: "button" },
                                on: { click: _vm.printLinkMini },
                              },
                              [
                                _c("svg-icon", {
                                  staticClass: "shadow",
                                  attrs: {
                                    name: "ic-miniprint",
                                    width: "48",
                                    height: "48",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("span", {
                              staticClass: "column-span",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Documents")),
                              },
                            }),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                  _c(
                    "button",
                    {
                      staticStyle: {
                        position: "fixed",
                        bottom: "20px",
                        left: "20px",
                      },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.showJson = true
                        },
                      },
                    },
                    [
                      _c("svg-icon", {
                        staticClass: "shadow",
                        attrs: { name: "ic-json", width: "48", height: "48" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn-circle_light-green",
                      staticStyle: {
                        padding: "12px 12px",
                        "border-radius": "1000px",
                      },
                      attrs: { type: "button" },
                      on: { click: _vm.reOrder },
                    },
                    [
                      _c("svg-icon", {
                        staticClass: "ic-white",
                        staticStyle: { margin: "3px" },
                        attrs: { name: "ic-repeat", width: "22", height: "24" },
                      }),
                      _c("span", {
                        domProps: { textContent: _vm._s(_vm.$t("Re-order")) },
                      }),
                    ],
                    1
                  ),
                  _vm.canCancelOrder
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-circle_red",
                          staticStyle: {
                            padding: "12px 12px",
                            "border-radius": "1000px",
                          },
                          attrs: { type: "button" },
                          on: { click: _vm.cancelOrder },
                        },
                        [
                          _c("svg-icon", {
                            staticClass: "ic-white",
                            attrs: {
                              name: "ic-bin",
                              width: "22",
                              height: "24",
                            },
                          }),
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(_vm.$t("Cancel order")),
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("button", {
                    staticClass: "btn",
                    staticStyle: { color: "#fff" },
                    attrs: { type: "button" },
                    domProps: {
                      textContent: _vm._s(_vm.$t("Order summary") + " >"),
                    },
                    on: { click: _vm.openOrderSummary },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "order-detail-mobile" }, [
                _c(
                  "div",
                  {
                    staticClass: "order-detail-top",
                    class: { "md-none": !_vm.user },
                  },
                  [
                    _vm.user
                      ? _c("div", { staticClass: "order-detail-user" }, [
                          _c(
                            "button",
                            { on: { click: _vm.goToUser } },
                            [
                              _c("user-avatar", {
                                attrs: {
                                  user: _vm.user,
                                  size: 60,
                                  "img-class": "order-detail-user__photo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "order-detail-user__info" },
                            [
                              _c(
                                "div",
                                { staticClass: "order-detail-user__name" },
                                [
                                  _c("button", {
                                    domProps: {
                                      textContent: _vm._s(_vm.user.name),
                                    },
                                    on: { click: _vm.goToUser },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "order-detail-user__club-icon",
                                    },
                                    [
                                      _c("svg-icon", {
                                        staticClass: "ic-white",
                                        attrs: {
                                          name: "ic-crown",
                                          width: "12",
                                          height: "12",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm.user.phone
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "order-detail-user__phone",
                                      on: { click: _vm.dial },
                                    },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          name: "ic-calls",
                                          width: "12",
                                          height: "12",
                                        },
                                      }),
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(_vm.user.phone),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _c("div", {
                      staticClass:
                        "order-detail-top__num d-none d-md-inline-block",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("Order") + " #" + _vm.order.unique_id
                        ),
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "order-detail-status d-none d-md-block" },
                      [
                        _c("div", {
                          staticClass: "order-detail-status__title",
                          domProps: { textContent: _vm._s(_vm.$t("Status")) },
                        }),
                        _c("colored-select-box", {
                          attrs: {
                            options: _vm.statuses,
                            placeholder: _vm.$t("Opened"),
                            "default-color": "green",
                          },
                          on: { input: _vm.statusChange },
                          model: {
                            value: _vm.order.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.order, "status", $$v)
                            },
                            expression: "order.status",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c("div", { staticClass: "order-detail-main" }, [
                  _c("div", { staticClass: "tabs-page active" }, [
                    _c("div", { staticClass: "tabs-page__list" }, [
                      _c("div", {
                        staticClass: "tabs-page__item",
                        class: { active: _vm.activeTab === 0 },
                        domProps: { textContent: _vm._s(_vm.$t("General")) },
                        on: {
                          click: function ($event) {
                            _vm.activeTab = 0
                          },
                        },
                      }),
                      _c("div", {
                        staticClass: "tabs-page__item",
                        class: { active: _vm.activeTab === 1 },
                        domProps: { textContent: _vm._s(_vm.$t("Details")) },
                        on: {
                          click: function ($event) {
                            _vm.activeTab = 1
                          },
                        },
                      }),
                      _c("div", {
                        staticClass: "tabs-page__item",
                        class: { active: _vm.activeTab === 2 },
                        domProps: { textContent: _vm._s(_vm.$t("Payments")) },
                        on: {
                          click: function ($event) {
                            _vm.activeTab = 2
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "tabs-page__content" }, [
                      _c(
                        "div",
                        {
                          staticClass: "tabs-page__content-item",
                          class: { active: _vm.activeTab === 0 },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "order-detail-form__fields" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "order-detail-form__count-wrap",
                                },
                                [
                                  _c("div", {
                                    staticClass: "order-detail-form__title",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Number of diners")
                                      ),
                                    },
                                  }),
                                  _c("div", {
                                    staticClass: "order-detail-form__count",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.order.number_of_diners
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "order-detail-form__field" },
                                [
                                  _c("div", {
                                    staticClass: "order-detail-form__title",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Email for invoice")
                                      ),
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "order-detail-form__email" },
                                    [
                                      _vm.user
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.user.email,
                                                expression: "user.email",
                                              },
                                            ],
                                            attrs: {
                                              type: "email",
                                              placeholder: "example@email.com",
                                            },
                                            domProps: { value: _vm.user.email },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.user,
                                                  "email",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          })
                                        : _c("input", {
                                            attrs: {
                                              type: "email",
                                              placeholder: "example@email.com",
                                            },
                                          }),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn_small btn_green",
                                        },
                                        [
                                          _c("svg-icon", {
                                            staticClass: "ic-white",
                                            attrs: {
                                              name: "ic-repeat",
                                              width: "9",
                                              height: "10",
                                            },
                                          }),
                                          _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t("Send again")
                                              ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "order-detail-form__field" },
                                [
                                  _c("div", {
                                    staticClass: "order-detail-form__title",
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Comments")),
                                    },
                                  }),
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.order.notes,
                                        expression: "order.notes",
                                      },
                                    ],
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "Dressing on the side? No pickles? Let us know here"
                                      ),
                                    },
                                    domProps: { value: _vm.order.notes },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.order,
                                          "notes",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm.xml || _vm.json
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex w100 bet a-center mb22",
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "h2",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.xml ? "XML log" : "JSON log"
                                        ),
                                      },
                                    }),
                                    _vm.navigatorShare
                                      ? _c("button", {
                                          staticClass: "btn",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("share log")
                                            ),
                                          },
                                          on: { click: _vm.shareJson },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "json-view" },
                                  [
                                    _vm.json
                                      ? _c("vue-json-pretty", {
                                          attrs: {
                                            path: "res",
                                            data: _vm.json,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.xml
                                      ? _c("xml-viewer", {
                                          attrs: { xml: _vm.xml },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "tabs-page__content-item",
                          class: { active: _vm.activeTab === 1 },
                        },
                        [
                          _c("div", { staticClass: "tabs-page__history" }, [
                            _c(
                              "div",
                              {
                                staticClass: "history-list-wrap",
                                class: {
                                  "has-scroll":
                                    _vm.orderCartItems &&
                                    _vm.orderCartItems.length > 3,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "history-list disable-scrollbars",
                                  },
                                  [
                                    _c("history-order-item", {
                                      attrs: {
                                        order: _vm.order,
                                        inorder: "",
                                        "show-order-info": false,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "history-list-wrap__arr" },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        name: "ic-arrow-down",
                                        width: "14",
                                        height: "8",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm.order.cart &&
                            _vm.order.cart.delivery_price &&
                            _vm.order.type == 0
                              ? _c(
                                  "div",
                                  { staticClass: "history-list-result" },
                                  [
                                    _c("div", {
                                      staticClass: "history-list-result__title",
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Delivery")),
                                      },
                                    }),
                                    _vm.order.cart &&
                                    _vm.order.cart.delivery_price >= 0 &&
                                    _vm.restaurantCurrencyInfo == null
                                      ? _c("div", {
                                          staticClass:
                                            "history-list-result__val",
                                          domProps: {
                                            textContent: _vm._s(
                                              "₪" +
                                                _vm.order.cart.delivery_price
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.order.cart &&
                                    _vm.order.cart.delivery_price < 0 &&
                                    _vm.he &&
                                    _vm.restaurantCurrencyInfo == null
                                      ? _c("div", {
                                          staticClass:
                                            "history-list-result__val",
                                          domProps: {
                                            textContent: _vm._s(
                                              "₪" +
                                                _vm.order.cart.delivery_price *
                                                  -1 +
                                                "-"
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.order.cart &&
                                    _vm.order.cart.delivery_price < 0 &&
                                    !_vm.he &&
                                    _vm.restaurantCurrencyInfo == null
                                      ? _c("div", {
                                          staticClass:
                                            "history-list-result__val",
                                          domProps: {
                                            textContent: _vm._s(
                                              "-₪" +
                                                _vm.order.cart.delivery_price *
                                                  -1
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.order.cart &&
                                    _vm.order.cart.delivery_price >= 0 &&
                                    _vm.restaurantCurrencyInfo !== null
                                      ? _c("div", {
                                          staticClass:
                                            "history-list-result__val",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.restaurantCurrencySymbol +
                                                _vm.order.cart.delivery_price
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.order.cart &&
                                    _vm.order.cart.delivery_price < 0 &&
                                    _vm.he &&
                                    _vm.restaurantCurrencyInfo !== null
                                      ? _c("div", {
                                          staticClass:
                                            "history-list-result__val",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.restaurantCurrencySymbol +
                                                _vm.order.cart.delivery_price *
                                                  -1 +
                                                "-"
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.order.cart &&
                                    _vm.order.cart.delivery_price < 0 &&
                                    !_vm.he &&
                                    _vm.restaurantCurrencyInfo !== null
                                      ? _c("div", {
                                          staticClass:
                                            "history-list-result__val",
                                          domProps: {
                                            textContent: _vm._s(
                                              "-" +
                                                _vm.restaurantCurrencySymbol +
                                                _vm.order.cart.delivery_price *
                                                  -1
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            _c("div", { staticClass: "history-list-result" }, [
                              _c("div", {
                                staticClass: "history-list-result__title",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Total")),
                                },
                              }),
                              _vm.order.sum < 0 &&
                              _vm.he &&
                              _vm.restaurantCurrencyInfo == null
                                ? _c(
                                    "div",
                                    { staticClass: "history-list-result__val" },
                                    [
                                      _vm._v(
                                        "\n                      ₪" +
                                          _vm._s(
                                            -1 * _vm.order.sum + _vm.tipAmount
                                          ) +
                                          "-\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.order.sum < 0 &&
                              _vm.he &&
                              _vm.restaurantCurrencyInfo !== null
                                ? _c(
                                    "div",
                                    { staticClass: "history-list-result__val" },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(-1 * _vm.order.sum) +
                                          "\n                      " +
                                          _vm._s(
                                            _vm.restaurantCurrencySymbol + "-"
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.order.sum < 0 &&
                              !_vm.he &&
                              _vm.restaurantCurrencyInfo == null
                                ? _c(
                                    "div",
                                    { staticClass: "history-list-result__val" },
                                    [
                                      _vm._v(
                                        "\n                      -₪" +
                                          _vm._s(-1 * _vm.order.sum) +
                                          "\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.order.sum >= 0 &&
                              !_vm.he &&
                              _vm.restaurantCurrencyInfo !== null
                                ? _c(
                                    "div",
                                    { staticClass: "history-list-result__val" },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(_vm.restaurantCurrencySymbol) +
                                          "\n                      " +
                                          _vm._s(_vm.order.sum) +
                                          "\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.order.sum >= 0 &&
                              _vm.he &&
                              _vm.restaurantCurrencyInfo !== null
                                ? _c(
                                    "div",
                                    { staticClass: "history-list-result__val" },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(_vm.order.sum) +
                                          "\n                      " +
                                          _vm._s(_vm.restaurantCurrencySymbol) +
                                          "\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.order.sum < 0 &&
                              !_vm.he &&
                              _vm.restaurantCurrencyInfo !== null
                                ? _c(
                                    "div",
                                    { staticClass: "history-list-result__val" },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            "-" + _vm.restaurantCurrencySymbol
                                          ) +
                                          "\n                      " +
                                          _vm._s(-1 * _vm.order.sum) +
                                          "\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "tabs-page__content-item",
                          class: { active: _vm.activeTab === 2 },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "pay-list" },
                            _vm._l(
                              _vm.order.payments,
                              function (payment, payIndex) {
                                return _c(
                                  "div",
                                  {
                                    key: payIndex,
                                    staticClass: "pay-list__item",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "pay-list__content" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "pay-list__icon" },
                                          [
                                            payment.type === 6
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/multipass.png",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 13
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/haat.svg",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 16
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/cheque.svg",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 15
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/mishloha.png",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 17
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/bank-transfer.svg",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 0
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/money.svg",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 19
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/kashcash.png",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            [1, 3, 5, 12, 26].includes(
                                              payment.type
                                            )
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/credit-card.svg",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 4 ||
                                            payment.type === 18
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/compensation_points.svg",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 2
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/stars.svg",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 6
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/multipass.png",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            [7].includes(payment.type)
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/business_client.svg",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 8
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/cibus.png",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 9
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/tenbis.png",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 10
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/bit.png",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 11
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/goodi.png",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 14
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/wolt.png",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 24
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/uber_eats.png",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.type === 25
                                              ? _c("img", {
                                                  attrs: {
                                                    src: "/assets/img/deliveroo.png",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "pay-list__info" },
                                          [
                                            payment.name
                                              ? _c("div", {
                                                  staticClass: "pay-list__name",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      payment.name
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.amount >= 0 &&
                                            _vm.he &&
                                            _vm.restaurantCurrencyInfo == null
                                              ? _c("div", {
                                                  staticClass:
                                                    "pay-list__price",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      "₪" + payment.amount
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.amount < 0 &&
                                            _vm.he &&
                                            _vm.restaurantCurrencyInfo == null
                                              ? _c("div", {
                                                  staticClass:
                                                    "pay-list__price",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      "₪" +
                                                        -1 * payment.amount +
                                                        "-"
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.amount < 0 &&
                                            !_vm.he &&
                                            _vm.restaurantCurrencyInfo == null
                                              ? _c("div", {
                                                  staticClass:
                                                    "pay-list__price",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      "-₪" + -1 * payment.amount
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.amount >= 0 &&
                                            _vm.he &&
                                            _vm.restaurantCurrencyInfo !== null
                                              ? _c("div", {
                                                  staticClass:
                                                    "pay-list__price",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.restaurantCurrencySymbol +
                                                        payment.amount
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.amount < 0 &&
                                            _vm.he &&
                                            _vm.restaurantCurrencyInfo !== null
                                              ? _c("div", {
                                                  staticClass:
                                                    "pay-list__price",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.restaurantCurrencySymbol +
                                                        -1 * payment.amount +
                                                        "-"
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.amount < 0 &&
                                            !_vm.he &&
                                            _vm.restaurantCurrencyInfo !== null
                                              ? _c("div", {
                                                  staticClass:
                                                    "pay-list__price",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      "-" +
                                                        _vm.restaurantCurrencySymbol +
                                                        -1 * payment.amount
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            payment.last_digits &&
                                            (payment.type === 1 ||
                                              payment.type === 3 ||
                                              payment.type === 12)
                                              ? _c("div", {
                                                  staticClass: "pay-list__text",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t("Card ends with") +
                                                        " " +
                                                        payment.last_digits
                                                    ),
                                                  },
                                                })
                                              : _c("div", {
                                                  staticClass: "pay-list__text",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        [
                                                          "Cash",
                                                          "Internet credit card",
                                                          "Points",
                                                          "Credit emv",
                                                          "__Compensation",
                                                          "Admin discount",
                                                          "Multipass",
                                                          "Business client",
                                                          "Cibus",
                                                          "Tenbis",
                                                          "Bit",
                                                          "Goodi",
                                                          "credit_input",
                                                          "Haat delivery",
                                                          "Wolt",
                                                          "Mishloha ",
                                                          "Cheque",
                                                          "Bank transfer",
                                                          "Gift card",
                                                          "Kashcash",
                                                        ][payment.type]
                                                      )
                                                    ),
                                                  },
                                                }),
                                            payment.business_client
                                              ? _c("div", {
                                                  staticClass: "pay-list__text",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.paymentMetaBusinessClientName(
                                                        payment
                                                      ) +
                                                        " - " +
                                                        _vm.paymentMetaBusinessClientPhone(
                                                          payment
                                                        )
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                    payment.amount - payment.refunded_amount &&
                                    payment.amount > 0
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn_small btn_green",
                                            on: {
                                              click: function ($event) {
                                                return _vm.refund(payment)
                                              },
                                            },
                                          },
                                          [
                                            _c("svg-icon", {
                                              staticClass:
                                                "m-i-end-1ch ic-white",
                                              attrs: {
                                                name: "ic-repeat",
                                                width: "9",
                                                height: "10",
                                              },
                                            }),
                                            _c("span", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t("Refund")
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _c("div", {
                                          staticClass: "refunded",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Refunded completely")
                                            ),
                                          },
                                        }),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "top-order-back d-md-none" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn-back d-md-none",
                    on: { click: _vm.close },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "16",
                          height: "12",
                          viewBox: "0 0 16 12",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M5.22151 11.4614C5.44719 11.6794 5.80247 11.6794 6.02815 11.4614C6.2462 11.2357 6.2462 10.8723 6.02815 10.6547L1.93805 6.56465L15.4353 6.56465C15.7499 6.56465 16 6.31458 16 5.99996C16 5.68533 15.7499 5.42713 15.4353 5.42713L1.93805 5.42713L6.02815 1.34517C6.2462 1.1195 6.2462 0.764212 6.02815 0.538538C5.80247 0.320487 5.44719 0.320487 5.22151 0.538538L0.163155 5.59689C-0.0548954 5.81494 -0.0548954 6.17785 0.163155 6.40353L5.22151 11.4614Z",
                            fill: "white",
                          },
                        }),
                      ]
                    ),
                    _c("span", { staticClass: "order-detail-top__num pie0" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("Id")) +
                            " " +
                            _vm._s(_vm.order.branch_order_id)
                        ),
                      ]),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("Daily id")) +
                            " " +
                            _vm._s(_vm.order.daily_order_id)
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "order-detail-status pis16",
                    class:
                      "select-color color-" +
                      _vm.mockOrderService.statusesClasses[_vm.order.status]
                        .class +
                      " " +
                      _vm.headerClass,
                  },
                  [
                    _c("div", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            [
                              "Opened",
                              "in_preparation",
                              "Ready",
                              "in_delivery",
                              "Closed",
                              "Canceled",
                              "unresolved_payment",
                            ][_vm.order.status]
                          )
                        ),
                      },
                    }),
                    _vm.order.date &&
                    _vm.order.arrival_time &&
                    _vm.order.status !== 4 &&
                    ((_vm.order.type
                      ? _vm.mockOrderService.statusesNoneDelivery
                      : _vm.mockOrderService.statuses)[4].label ===
                      "f_Closed" ||
                      (_vm.order.type
                        ? _vm.mockOrderService.statusesNoneDelivery
                        : _vm.mockOrderService.statuses)[4].label ===
                        "Canceled")
                      ? _c("arrival-time", {
                          attrs: {
                            startDate: _vm.order.date,
                            arrivalDate: _vm.order.arrival_time,
                          },
                          on: {
                            "set-class": function (mode) {
                              return (_vm.headerClass = "mode-" + mode)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "order-detail-form__btn d-md-none" },
                [
                  _c(
                    "button",
                    { staticClass: "share", on: { click: _vm.share } },
                    [
                      _c("svg-icon", {
                        staticClass: "shadow",
                        attrs: { name: "ic-share", width: "24", height: "24" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "button",
                    { on: { click: _vm.printLinkMini } },
                    [
                      _c("svg-icon", {
                        staticClass: "shadow",
                        attrs: {
                          name: "ic-miniprint",
                          width: "48",
                          height: "48",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "order-detail-form__log",
                      on: { click: _vm.openShowJson },
                    },
                    [
                      _c("svg-icon", {
                        staticClass: "shadow",
                        attrs: { name: "ic-json", width: "48", height: "48" },
                      }),
                    ],
                    1
                  ),
                  _vm.canCancelOrder
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-circle_red",
                          staticStyle: {
                            padding: "12px 12px",
                            "border-radius": "1000px",
                          },
                          attrs: { type: "button" },
                          on: { click: _vm.cancelOrder },
                        },
                        [
                          _c("svg-icon", {
                            staticClass: "ic-white",
                            attrs: {
                              name: "ic-bin",
                              width: "22",
                              height: "24",
                            },
                          }),
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(_vm.$t("Cancel order")),
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: { type: "button" },
                      on: { click: _vm.reOrder },
                    },
                    [
                      _c("svg-icon", {
                        staticClass: "ic-white",
                        attrs: { name: "ic-repeat", width: "22", height: "24" },
                      }),
                      _c("span", {
                        domProps: { textContent: _vm._s(_vm.$t("Re-order")) },
                      }),
                    ],
                    1
                  ),
                  _vm.showDragonButton
                    ? [
                        _c(
                          "button",
                          {
                            staticClass: "share",
                            attrs: { type: "button" },
                            on: { click: _vm.exportDragon },
                          },
                          [
                            _c("svg-icon", {
                              staticClass: "shadow",
                              attrs: {
                                name: "ic-dragon",
                                width: "24",
                                height: "24",
                              },
                            }),
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(_vm.$t("Dragon")),
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "share",
                            attrs: { type: "button" },
                            on: { click: _vm.resendToDragon },
                          },
                          [
                            _c("svg-icon", {
                              staticClass: "shadow",
                              attrs: {
                                name: "ic-dragon",
                                width: "24",
                                height: "24",
                              },
                            }),
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(_vm.$t("Resend to Dragon")),
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
      _vm.showJson
        ? _c("order-json-popup", {
            attrs: { order: _vm.order },
            on: {
              closed: function ($event) {
                _vm.showJson = false
              },
            },
          })
        : _vm._e(),
      _vm.popup
        ? _c("history-items-popup", {
            attrs: { order: _vm.order },
            on: { close: _vm.closeHistoryItems },
          })
        : _vm._e(),
      _vm.showDocuments
        ? _c("show-pdf-documents", {
            attrs: { documents: _vm.documents },
            on: {
              close: function ($event) {
                _vm.showDocuments = false
              },
            },
          })
        : _vm._e(),
      _vm.showDragonDetails
        ? _c("dragon-json-popup", {
            attrs: { response: _vm.dragontail },
            on: {
              closed: function ($event) {
                _vm.showDragonDetails = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }