var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "club-statistics-box flex col relative" }, [
    _c("div", { class: "to-end" }, [
      _c("header", { staticClass: "flex bet" }, [
        _c("span", { staticClass: "flex" }, [
          _c("span", {
            staticClass: "mie4",
            domProps: {
              textContent: _vm._s(_vm.$t("The best selling products")),
            },
          }),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "row-orders" },
        [
          _c("table-list", {
            attrs: {
              "table-headers": _vm.tableHeaders,
              items: _vm.tableItems,
              "hide-pagination": true,
              "custom-class": "items-statistic-table",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }