var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "time-clock-container flex a-center bet",
      attrs: { hidden: !_vm.isAdmin },
    },
    [
      _c(
        "div",
        {
          staticClass: "time-clock countdown__conten",
          attrs: {
            title: _vm.inBreak
              ? "In Break"
              : !!_vm.timerStartDT
              ? "Clocked in"
              : "Clocked out",
          },
        },
        [
          _c("span", {
            staticClass: "digit",
            domProps: { textContent: _vm._s(_vm.timerHour[0]) },
          }),
          _c("span", {
            staticClass: "digit",
            domProps: { textContent: _vm._s(_vm.timerHour[1]) },
          }),
          _c("span", [_vm._v(" : ")]),
          _c("span", {
            staticClass: "digit",
            domProps: { textContent: _vm._s(_vm.timerMin[0]) },
          }),
          _c("span", {
            staticClass: "digit",
            domProps: { textContent: _vm._s(_vm.timerMin[1]) },
          }),
          _c("span", [_vm._v(" : ")]),
          _c("span", {
            staticClass: "digit",
            domProps: { textContent: _vm._s(_vm.timerSec[0]) },
          }),
          _c("span", {
            staticClass: "digit",
            domProps: { textContent: _vm._s(_vm.timerSec[1]) },
          }),
        ]
      ),
      _c("button", {
        staticClass: "countdown__play",
        class: { active: !!_vm.timerStartDT },
        on: { click: _vm.toggleTimer },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }