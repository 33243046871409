var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-dialog-tabs", {
    staticClass: "edit-tag",
    attrs: { tabList: _vm.tabList, width: "614px" },
    on: { close: _vm.close },
    scopedSlots: _vm._u([
      {
        key: "tab0",
        fn: function () {
          return [
            _c("div", { staticClass: "popup__top" }, [
              _c(
                "div",
                {
                  staticClass: "header-back back d-flex d-md-none",
                  style: { "margin-right": "-25px", width: "103vw" },
                },
                [
                  _c(
                    "button",
                    { staticClass: "header__back", on: { click: _vm.close } },
                    [
                      _c(
                        "span",
                        { staticClass: "header-company" },
                        [
                          _c("svg-icon", {
                            staticClass: "ic-white",
                            attrs: {
                              name: "ic-arrow-left",
                              width: "16",
                              height: "12",
                            },
                          }),
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Back")) },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _c("div", { staticClass: "popup__head flex bet a-center" }, [
                _c("div", { staticClass: "lang lang_grey" }, [
                  _c("div", {
                    staticClass: "lang__item",
                    class: { active: _vm.english },
                    domProps: { textContent: _vm._s(_vm.$t("English")) },
                    on: {
                      click: function ($event) {
                        _vm.english = true
                      },
                    },
                  }),
                  _c("div", {
                    staticClass: "lang__item",
                    class: { active: !_vm.english },
                    domProps: { textContent: _vm._s(_vm.$t("Hebrew")) },
                    on: {
                      click: function ($event) {
                        _vm.english = false
                      },
                    },
                  }),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "switch-wrap inline-flex a-center",
                    style: {
                      display: "flex",
                      "justify-content": "end",
                      width: "100%",
                    },
                  },
                  [
                    _c("div", {
                      staticClass: "switch-wrap__label mie12",
                      domProps: { textContent: _vm._s(_vm.$t("Active")) },
                    }),
                    _c("swich-button", {
                      model: {
                        value: _vm.tagInEditCopy.active,
                        callback: function ($$v) {
                          _vm.$set(_vm.tagInEditCopy, "active", $$v)
                        },
                        expression: "tagInEditCopy.active",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "popup__content" }, [
              _c("div", { staticClass: "form-lang" }, [
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "form-site" }, [
                      _c("div", { staticClass: "form-site__content" }, [
                        _c(
                          "div",
                          { staticClass: "form-site__group" },
                          [
                            _vm.english
                              ? _c("form-site-field-text", {
                                  staticStyle: { width: "100%" },
                                  attrs: { title: _vm.$t("tag name") },
                                  model: {
                                    value: _vm.tagInEditCopy.name_en,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tagInEditCopy,
                                        "name_en",
                                        $$v
                                      )
                                    },
                                    expression: "tagInEditCopy.name_en",
                                  },
                                })
                              : _c("form-site-field-text", {
                                  staticStyle: { width: "100%" },
                                  attrs: { title: _vm.$t("tag name") },
                                  model: {
                                    value: _vm.tagInEditCopy.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tagInEditCopy, "name", $$v)
                                    },
                                    expression: "tagInEditCopy.name",
                                  },
                                }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "form-site__group" },
                          [
                            _vm.english
                              ? _c("form-site-field-text", {
                                  staticStyle: { width: "100%" },
                                  attrs: { title: "Menu name" },
                                  model: {
                                    value: _vm.tagInEditCopy.menu_name_en,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tagInEditCopy,
                                        "menu_name_en",
                                        $$v
                                      )
                                    },
                                    expression: "tagInEditCopy.menu_name_en",
                                  },
                                })
                              : _c("form-site-field-text", {
                                  staticStyle: { width: "100%" },
                                  attrs: { title: "Menu name" },
                                  model: {
                                    value: _vm.tagInEditCopy.menu_name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tagInEditCopy,
                                        "menu_name",
                                        $$v
                                      )
                                    },
                                    expression: "tagInEditCopy.menu_name",
                                  },
                                }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "form-site__group" },
                          [
                            _vm.english
                              ? _c("form-site-field-text", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    title: "Description",
                                    type: "textarea",
                                  },
                                  model: {
                                    value: _vm.tagInEditCopy.description_en,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tagInEditCopy,
                                        "description_en",
                                        $$v
                                      )
                                    },
                                    expression: "tagInEditCopy.description_en",
                                  },
                                })
                              : _c("form-site-field-text", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    title: "Description",
                                    type: "textarea",
                                  },
                                  model: {
                                    value: _vm.tagInEditCopy.description,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tagInEditCopy,
                                        "description",
                                        $$v
                                      )
                                    },
                                    expression: "tagInEditCopy.description",
                                  },
                                }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm.tagInEditCopy.id
                      ? _c("div", { staticClass: "filter-btn" }, [
                          _c("button", {
                            staticClass: "filter-btn__item",
                            class: { active: _vm.listType === "item" },
                            domProps: { textContent: _vm._s(_vm.$t("Items")) },
                            on: {
                              click: function ($event) {
                                return _vm.setListType("item")
                              },
                            },
                          }),
                          _c("button", {
                            staticClass: "filter-btn__item",
                            class: { active: _vm.listType === "extra" },
                            domProps: { textContent: _vm._s(_vm.$t("Extras")) },
                            on: {
                              click: function ($event) {
                                return _vm.setListType("extra")
                              },
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm.listType === "item"
                      ? _c(
                          "draggable",
                          _vm._b(
                            {
                              staticClass: "items",
                              on: {
                                end: function ($event) {
                                  return _vm.moveItemInTag($event)
                                },
                              },
                            },
                            "draggable",
                            _vm.draggableBind,
                            false
                          ),
                          _vm._l(_vm.itemsWithThisTag, function (item) {
                            return _c(
                              "div",
                              { key: item.id, staticClass: "darggable" },
                              [
                                _vm.itemsWithThisTag.length
                                  ? _c("div", { staticClass: "item" }, [
                                      _c("div", {
                                        staticClass: "item_name",
                                        domProps: {
                                          textContent: _vm._s(item.name),
                                        },
                                      }),
                                      item.makat
                                        ? _c(
                                            "div",
                                            { staticClass: "item_makat" },
                                            [
                                              _vm._v(
                                                "(" + _vm._s(item.makat) + ")"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "flex a-center j-end" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "new-group-res__menu",
                                            },
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  name: "ic-double-more",
                                                  width: "8",
                                                  height: "14",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm.listType === "extra"
                      ? _c(
                          "draggable",
                          _vm._b(
                            {
                              staticClass: "items",
                              on: {
                                end: function ($event) {
                                  return _vm.moveExtraInTag($event)
                                },
                              },
                            },
                            "draggable",
                            _vm.draggableBind,
                            false
                          ),
                          _vm._l(_vm.extrasWithThisTag, function (extra) {
                            return _c(
                              "div",
                              { key: extra.id, staticClass: "darggable" },
                              [
                                _vm.extrasWithThisTag.length
                                  ? _c("div", { staticClass: "item" }, [
                                      _c("div", {
                                        domProps: {
                                          textContent: _vm._s(extra.name),
                                        },
                                      }),
                                      extra.makat
                                        ? _c("div", [
                                            _vm._v(
                                              "(" + _vm._s(extra.makat) + ")"
                                            ),
                                          ])
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "flex a-center j-end" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "new-group-res__menu",
                                            },
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  name: "ic-double-more",
                                                  width: "8",
                                                  height: "14",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _c("button", {
                      attrs: { type: "submit" },
                      on: { click: _vm.save },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "tab2",
        fn: function () {
          return [
            _c("div", { staticClass: "popup__top" }, [
              _c("div", { staticClass: "popup__head flex bet a-center" }, [
                _vm.tagInEditCopy.name
                  ? _c("div", {
                      staticClass: "h1 mt20",
                      domProps: { textContent: _vm._s(_vm.tagInEditCopy.name) },
                    })
                  : _c("div", {
                      staticClass: "h1 mt20",
                      domProps: { textContent: _vm._s(_vm.$t("New tag")) },
                    }),
                _c(
                  "div",
                  { staticClass: "switch-wrap inline-flex a-center" },
                  [
                    _c("div", {
                      staticClass: "switch-wrap__label mie12",
                      domProps: { textContent: _vm._s(_vm.$t("Active")) },
                    }),
                    _c("swich-button", {
                      model: {
                        value: _vm.tagInEditCopy.active,
                        callback: function ($$v) {
                          _vm.$set(_vm.tagInEditCopy, "active", $$v)
                        },
                        expression: "tagInEditCopy.active",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "header-back back d-flex d-md-none",
                style: { "margin-right": "-25px", width: "103vw" },
              },
              [
                _c(
                  "button",
                  { staticClass: "header__back", on: { click: _vm.close } },
                  [
                    _c(
                      "span",
                      { staticClass: "header-company" },
                      [
                        _c("svg-icon", {
                          staticClass: "ic-white",
                          attrs: {
                            name: "ic-arrow-left",
                            width: "16",
                            height: "12",
                          },
                        }),
                        _c("span", {
                          domProps: { textContent: _vm._s(_vm.$t("Back")) },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            _c("availability-tab", {
              attrs: {
                id: !!_vm.tagInEditCopy.id,
                availability: _vm.tagInEditCopy.availability,
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "tab1",
        fn: function () {
          return [
            _c("div", { staticClass: "popup__top d-md-block d-none" }, [
              _c("div", { staticClass: "popup__head flex bet a-center" }, [
                _c("div", {
                  staticClass: "h1 mt20",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t(
                        _vm.tagInEditCopy.name
                          ? _vm.tagInEditCopy.name
                          : "New tag"
                      )
                    ),
                  },
                }),
                _c(
                  "div",
                  { staticClass: "switch-wrap inline-flex a-center" },
                  [
                    _c("div", {
                      staticClass: "switch-wrap__label mie12",
                      domProps: { textContent: _vm._s(_vm.$t("Active")) },
                    }),
                    _c("swich-button", {
                      model: {
                        value: _vm.tagInEditCopy.active,
                        callback: function ($$v) {
                          _vm.$set(_vm.tagInEditCopy, "active", $$v)
                        },
                        expression: "tagInEditCopy.active",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "header-back back d-flex d-md-none",
                style: { "margin-right": "-25px", width: "103vw" },
              },
              [
                _c(
                  "button",
                  { staticClass: "header__back", on: { click: _vm.close } },
                  [
                    _c(
                      "span",
                      { staticClass: "header-company" },
                      [
                        _c("svg-icon", {
                          staticClass: "ic-white",
                          attrs: {
                            name: "ic-arrow-left",
                            width: "16",
                            height: "12",
                          },
                        }),
                        _c("span", {
                          domProps: { textContent: _vm._s(_vm.$t("Back")) },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            _c("branches-tab", {
              attrs: {
                showOnlyThisBranch: !!_vm.branchId,
                availability: _vm.tagInEditCopy.availability,
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "tab3",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass: "header-back back d-flex d-md-none",
                style: { "margin-right": "-25px", width: "103vw" },
              },
              [
                _c(
                  "button",
                  { staticClass: "header__back", on: { click: _vm.close } },
                  [
                    _c(
                      "span",
                      { staticClass: "header-company" },
                      [
                        _c("svg-icon", {
                          staticClass: "ic-white",
                          attrs: {
                            name: "ic-arrow-left",
                            width: "16",
                            height: "12",
                          },
                        }),
                        _c("span", {
                          domProps: { textContent: _vm._s(_vm.$t("Back")) },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            _c("catalog-editor-edit-menu-setting-images", {
              attrs: { itemCopy: _vm.tagInEditCopy, type: "tag" },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "btns",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass:
                  "form-site__btn form-site__btn_sb d-md-flex d-none",
              },
              [
                _vm.tagInEditCopy.id
                  ? _c("remove-btn", { on: { click: _vm.remove } })
                  : _c("div", {}),
                _c("button", { staticClass: "btn", on: { click: _vm.save } }, [
                  _c("span", {
                    domProps: { textContent: _vm._s(_vm.$t("save")) },
                  }),
                ]),
              ],
              1
            ),
            _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
              _c(
                "button",
                { staticClass: "save-btn", on: { click: _vm.save } },
                [
                  _c("svg-icon", {
                    staticClass: "ic-edit",
                    attrs: { name: "ic-save-btn", width: "93", height: "93" },
                  }),
                ],
                1
              ),
              _vm.tagInEditCopy.id
                ? _c(
                    "button",
                    { staticClass: "bin-btn", on: { click: _vm.remove } },
                    [
                      _c("svg-icon", {
                        attrs: { name: "ic-bin", width: "18", height: "20" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }