var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "atmmos-promos-branches-tab" }, [
    _c("div", { staticClass: "new-item-info" }, [
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "form-site" },
            [
              !_vm.showOnlyThisBranch
                ? _c("div", { staticClass: "form-site-branches" }, [
                    _c("div", { staticClass: "form-site-search__field" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filterBranches,
                            expression: "filterBranches",
                          },
                        ],
                        staticClass: "pis36",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("Search..."),
                        },
                        domProps: { value: _vm.filterBranches },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.filterBranches = $event.target.value
                          },
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "form-site-search__btn" },
                        [
                          _c("svg-icon", {
                            staticClass: "ic-stroke",
                            attrs: {
                              name: "ic-search",
                              width: "19",
                              height: "19",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-site-branches__title" }, [
                      _c("div", {
                        staticClass: "form-site-branches__name",
                        domProps: {
                          textContent: _vm._s(_vm.$t("Branch name")),
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "all-branches",
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            gap: "10px",
                          },
                        },
                        [
                          _c("div", {
                            staticClass: "form-site-branches__name",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Select all")),
                            },
                          }),
                          _c("swich-button", {
                            attrs: {
                              value:
                                _vm.availability.branches.length == 1 &&
                                _vm.availability.branches[0] == 0,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.setAllBranches()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._l(_vm.branchesToShow, function (branch) {
                return [
                  _c(
                    "div",
                    {
                      key: branch.id,
                      staticClass: "form-site-branches__branch",
                    },
                    [
                      _c("div", {
                        staticClass: "form-site-branches__name",
                        domProps: {
                          textContent: _vm._s(
                            _vm.he || !branch.name_en
                              ? branch.name
                              : branch.name_en
                          ),
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "form-site-branches__swiches" },
                        [
                          _vm.affiliationPermissions
                            ? _c(
                                "div",
                                { staticClass: "form-site-branches__swich" },
                                [
                                  _c("swich-button", {
                                    attrs: {
                                      value:
                                        _vm.allBranches ||
                                        _vm.availability.branches.includes(
                                          branch.id
                                        ),
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.setBranches(
                                          $event,
                                          branch.id,
                                          true
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
          _vm.showOnlyThisBranch
            ? _c(
                "div",
                { staticClass: "form-site-branches" },
                [
                  _c("div", { staticClass: "form-site-search__field" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filterBranches,
                          expression: "filterBranches",
                        },
                      ],
                      staticClass: "pis36",
                      attrs: { type: "text", placeholder: _vm.$t("Search...") },
                      domProps: { value: _vm.filterBranches },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.filterBranches = $event.target.value
                        },
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "form-site-search__btn" },
                      [
                        _c("svg-icon", {
                          staticClass: "ic-stroke",
                          attrs: {
                            name: "ic-search",
                            width: "19",
                            height: "19",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "form-site-branches__title" }, [
                    _c("div", {
                      staticClass: "form-site-branches__name",
                      domProps: { textContent: _vm._s(_vm.$t("Branch name")) },
                    }),
                    _c("div", { staticClass: "form-site-branches__swiches" }, [
                      _vm.affiliationPermissions
                        ? _c("div", {
                            staticClass: "form-site-branches__swich",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Affiliation")),
                            },
                          })
                        : _vm._e(),
                      _vm.availability.branches_activity
                        ? _c("div", {
                            staticClass: "form-site-branches__swich",
                            domProps: { textContent: _vm._s(_vm.$t("Active")) },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-site-branches__branch" }, [
                    _c("div", {
                      staticClass: "form-site-branches__name",
                      domProps: { textContent: _vm._s(_vm.$t("All branches")) },
                    }),
                    _c("div", { staticClass: "form-site-branches__swiches" }, [
                      _vm.affiliationPermissions
                        ? _c(
                            "div",
                            { staticClass: "form-site-branches__swich" },
                            [
                              _c("swich-button", {
                                model: {
                                  value: _vm.allBranches,
                                  callback: function ($$v) {
                                    _vm.allBranches = $$v
                                  },
                                  expression: "allBranches",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.availability.branches_activity
                        ? _c(
                            "div",
                            { staticClass: "form-site-branches__swich" },
                            [
                              _c("swich-button", {
                                attrs: { disabled: !_vm.allBranches },
                                model: {
                                  value: _vm.allBranchesActive,
                                  callback: function ($$v) {
                                    _vm.allBranchesActive = $$v
                                  },
                                  expression: "allBranchesActive",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  [
                    _vm._l(_vm.branches, function (myBranch) {
                      return _vm.myOwnBranch === null
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                key: myBranch.id,
                                staticClass: "form-site-branches__branch",
                              },
                              [
                                _c("div", {
                                  staticClass: "form-site-branches__name",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.he || !myBranch.name_en
                                        ? myBranch.name
                                        : myBranch.name_en
                                    ),
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-site-branches__swiches",
                                  },
                                  [
                                    _vm.affiliationPermissions
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-site-branches__swich",
                                          },
                                          [
                                            _c("swich-button", {
                                              attrs: {
                                                value:
                                                  _vm.allBranches ||
                                                  _vm.availability.branches.includes(
                                                    myBranch.id
                                                  ),
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.setBranches(
                                                    $event,
                                                    myBranch.id,
                                                    true
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.availability.branches_activity
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-site-branches__swich",
                                          },
                                          [
                                            _c("swich-button", {
                                              attrs: {
                                                disabled: !(
                                                  _vm.allBranches ||
                                                  _vm.availability.branches.includes(
                                                    myBranch.id
                                                  )
                                                ),
                                                value:
                                                  _vm.allBranchesActive ||
                                                  _vm.availability.branches_activity.includes(
                                                    myBranch.id
                                                  ),
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.setBranches(
                                                    $event,
                                                    myBranch.id,
                                                    false
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                          ])
                        : _vm._e()
                    }),
                    _vm.myOwnBranch !== null
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              key: _vm.myOwnBranch.id,
                              staticClass: "form-site-branches__branch",
                            },
                            [
                              _c("div", {
                                staticClass: "form-site-branches__name",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.he || !_vm.myOwnBranch.name_en
                                      ? _vm.myOwnBranch.name
                                      : _vm.myOwnBranch.name_en
                                  ),
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "form-site-branches__swiches" },
                                [
                                  _vm.affiliationPermissions
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-site-branches__swich",
                                        },
                                        [
                                          _c("swich-button", {
                                            attrs: {
                                              value:
                                                _vm.allBranches ||
                                                _vm.availability.branches.includes(
                                                  _vm.myOwnBranch.id
                                                ),
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.setBranches(
                                                  $event,
                                                  _vm.myOwnBranch.id,
                                                  true
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.availability.branches_activity
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-site-branches__swich",
                                        },
                                        [
                                          _c("swich-button", {
                                            attrs: {
                                              disabled: !(
                                                _vm.allBranches ||
                                                _vm.availability.branches.includes(
                                                  _vm.myOwnBranch.id
                                                )
                                              ),
                                              value:
                                                _vm.allBranchesActive ||
                                                _vm.availability.branches_activity.includes(
                                                  _vm.myOwnBranch.id
                                                ),
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.setBranches(
                                                  $event,
                                                  _vm.myOwnBranch.id,
                                                  false
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ],
                ],
                2
              )
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }