var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rest-integrations full-screen" },
    [
      _c("main", [
        _c("section", { staticClass: "container" }, [
          _c("div", { staticClass: "page" }, [
            _c(
              "div",
              { staticClass: "page__main" },
              [
                _c(
                  "router-link",
                  { staticClass: "page__back", attrs: { to: "../settings" } },
                  [
                    _c("svg-icon", {
                      attrs: {
                        name: "ic-close-table-arrow",
                        width: "34",
                        height: "34",
                      },
                    }),
                  ],
                  1
                ),
                _c("h1", {
                  domProps: {
                    textContent: _vm._s(_vm.$t("Restaurant Integrations")),
                  },
                }),
                _c("section", { staticClass: "main-settings" }, [
                  _c("div", { staticClass: "main-settings__sect" }, [
                    _c("div", { staticClass: "main-settings__content" }, [
                      _c("div", { staticClass: "main-settings__form" }, [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "form-site" }, [
                              _c("div", { staticClass: "form-site__content" }, [
                                _c(
                                  "div",
                                  { staticClass: "club__list" },
                                  [
                                    _vm._l(_vm.apis, function (api) {
                                      return _c(
                                        "div",
                                        {
                                          key: api.id,
                                          staticClass: "club__item",
                                          on: {
                                            click: function ($event) {
                                              return _vm.editApi(api)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "club__data" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "club__text" },
                                                [
                                                  _c("div", {
                                                    staticClass: "club__name",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t(
                                                          _vm.capitalize(
                                                            api.meta_key
                                                          )
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }),
                                    _vm.atmosPromos
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "club__item",
                                            on: { click: _vm.goToAtmosClub },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "club__data" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "club__text" },
                                                  [
                                                    _c("div", {
                                                      staticClass: "club__name",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t("atmos club")
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm.popup
        ? _c("edit-rest-integration", {
            attrs: { api: _vm.apiInEdit },
            on: { save: _vm.saveIintegration },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }