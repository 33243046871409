var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: {
        title: _vm.currentAddress.id ? "Edit address" : "Add new address",
      },
      on: { close: _vm.close },
    },
    [
      _c("div", { staticClass: "popup__content" }, [
        _c("div", { staticClass: "form-new-address" }, [
          _c(
            "form",
            {
              staticClass: "form-site-wrap",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.save.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "form-site" }, [
                _c("div", { staticClass: "form-site__content" }, [
                  _c("div", { staticClass: "form-site__field-wrap" }, [
                    _c("div", { staticClass: "form-site__field" }, [
                      _c("label", {
                        staticClass: "form-site__label",
                        attrs: { for: "address_name" },
                        domProps: { textContent: _vm._s(_vm.$t("Name")) },
                      }),
                      _c("div", { staticClass: "form-site__input" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.currentAddress.name,
                              expression: "currentAddress.name",
                            },
                          ],
                          attrs: { id: "address_name", type: "text" },
                          domProps: { value: _vm.currentAddress.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.currentAddress,
                                "name",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-site__field" }, [
                      _c("label", {
                        staticClass: "form-site__label",
                        domProps: { textContent: _vm._s(_vm.$t("City")) },
                      }),
                      _c(
                        "div",
                        { staticClass: "form-site__input" },
                        [
                          _c("cities-input", {
                            model: {
                              value: _vm.currentAddress.city_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentAddress, "city_name", $$v)
                              },
                              expression: "currentAddress.city_name",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-site__field" }, [
                    _c("label", {
                      staticClass: "form-site__label",
                      domProps: { textContent: _vm._s(_vm.$t("Street")) },
                    }),
                    _c(
                      "div",
                      { staticClass: "form-site__input" },
                      [
                        _c("streets-input", {
                          attrs: { city: _vm.currentAddress.city_name },
                          model: {
                            value: _vm.currentAddress.street_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentAddress, "street_name", $$v)
                            },
                            expression: "currentAddress.street_name",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "form-site__field" }, [
                    _c("label", {
                      staticClass: "form-site__label",
                      attrs: { for: "address_number" },
                      domProps: { textContent: _vm._s(_vm.$t("Number")) },
                    }),
                    _c("div", { staticClass: "form-site__input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentAddress.number,
                            expression: "currentAddress.number",
                          },
                        ],
                        attrs: { id: "address_number", type: "number" },
                        domProps: { value: _vm.currentAddress.number },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.currentAddress,
                              "number",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-site__field" }, [
                    _c("label", {
                      staticClass: "form-site__label",
                      attrs: { for: "address_floor" },
                      domProps: { textContent: _vm._s(_vm.$t("Floor")) },
                    }),
                    _c("div", { staticClass: "form-site__input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentAddress.floor,
                            expression: "currentAddress.floor",
                          },
                        ],
                        attrs: { id: "address_floor", type: "number" },
                        domProps: { value: _vm.currentAddress.floor },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.currentAddress,
                              "floor",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-site__field" }, [
                    _c("label", {
                      staticClass: "form-site__label",
                      attrs: { for: "address_apartment" },
                      domProps: { textContent: _vm._s(_vm.$t("Apartment")) },
                    }),
                    _c("div", { staticClass: "form-site__input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentAddress.apartment,
                            expression: "currentAddress.apartment",
                          },
                        ],
                        attrs: { id: "address_apartment", type: "number" },
                        domProps: { value: _vm.currentAddress.apartment },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.currentAddress,
                              "apartment",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-site__btn flex bet w100" }, [
                _c("div"),
                _c(
                  "button",
                  { staticClass: "btn", attrs: { type: "submit" } },
                  [
                    !_vm.currentAddress.id
                      ? _c("span", {
                          domProps: { textContent: _vm._s(_vm.$t("Add")) },
                        })
                      : _vm._e(),
                    _vm.currentAddress.id
                      ? _c("span", {
                          domProps: { textContent: _vm._s(_vm.$t("Save")) },
                        })
                      : _vm._e(),
                    _c("svg-icon", {
                      staticClass: "ic-white",
                      attrs: {
                        name: "ic-arrow-right",
                        width: "16",
                        height: "12",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }