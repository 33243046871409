var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      staticClass: "edit-stock",
      attrs: { overflowVisible: "" },
      on: { close: _vm.close },
    },
    [
      _c("div", { staticClass: "popup__content" }, [
        _c("div", { staticClass: "form-new-user" }, [
          _c("form", { staticClass: "form-site-wrap" }, [
            _c("div", { staticClass: "form-site" }, [
              _c(
                "div",
                {
                  staticClass: "form-site__content",
                  staticStyle: { display: "flex", "flex-direction": "column" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "form-site__field-wrap",
                      staticStyle: { width: "100%", display: "flex" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "row",
                            "justify-content": "space-evenly",
                            width: "100%",
                            gap: "10px",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "form-site__field-wrap" },
                            [
                              _c("form-site-field-text", {
                                staticStyle: { width: "100%" },
                                attrs: { focus: "", title: "daily stock" },
                                model: {
                                  value: _vm.stockInEditCopy.daily_stock,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.stockInEditCopy,
                                      "daily_stock",
                                      $$v
                                    )
                                  },
                                  expression: "stockInEditCopy.daily_stock",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-site__field-wrap" },
                            [
                              _c("form-site-field-text", {
                                staticStyle: { width: "100%" },
                                attrs: { focus: "", title: "current stock" },
                                model: {
                                  value: _vm.stockInEditCopy.current_stock,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.stockInEditCopy,
                                      "current_stock",
                                      $$v
                                    )
                                  },
                                  expression: "stockInEditCopy.current_stock",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "form-site__btn md-none",
                      staticStyle: { "justify-content": "space-around" },
                    },
                    [
                      _vm.stockInEditCopy.item_id
                        ? _c("remove-btn", { on: { click: _vm.rremove } })
                        : _c("div"),
                      _c("button", {
                        staticClass: "btn",
                        attrs: { type: "submit" },
                        domProps: { textContent: _vm._s(_vm.$t("Save")) },
                        on: { click: _vm.save },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
                    _c(
                      "button",
                      {
                        staticClass: "save-btn",
                        attrs: { type: "submit" },
                        on: { click: _vm.save },
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "ic-edit",
                          attrs: {
                            name: "ic-save-btn",
                            width: "93",
                            height: "93",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }