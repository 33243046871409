var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      staticClass: "edit-problem-shift",
      attrs: { width: "450px" },
      on: { close: _vm.close },
    },
    [
      _c(
        "div",
        {
          style: {
            height: "480px",
            display: "flex",
            "justify-content": "center",
            padding: "20px",
          },
        },
        [
          _c("div", { staticClass: "popup__content pb40" }, [
            _c("div", { staticClass: "form-new-user" }, [
              _c("div", { staticClass: "form-site" }, [
                _c("div", { staticClass: "form-site__field" }, [
                  _c(
                    "div",
                    {
                      style: {
                        display: "flex",
                        "justify-content": "center",
                      },
                    },
                    [
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "filter-date-container" },
                          [
                            _vm.editShift
                              ? _c("form-site-field-date", {
                                  staticClass: "start1",
                                  style: {
                                    "margin-top": "-2px",
                                    width: "160px",
                                    "margin-right": "20px",
                                  },
                                  attrs: {
                                    disabled: !_vm.editShift,
                                    title: _vm.$t("From") + _vm.$t("Date"),
                                  },
                                  model: {
                                    value: _vm.selectedShift.actual_start,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedShift,
                                        "actual_start",
                                        $$v
                                      )
                                    },
                                    expression: "selectedShift.actual_start",
                                  },
                                })
                              : _vm._e(),
                            _vm.editShift == false
                              ? _c("form-site-field-date", {
                                  staticClass: "start1",
                                  attrs: {
                                    value: _vm.selectedShift.actual_start.slice(
                                      0,
                                      10
                                    ),
                                    disabled: !_vm.editShift,
                                    title: _vm.$t("From") + _vm.$t("Date"),
                                  },
                                })
                              : _vm._e(),
                            _c("Vue-timepicker", {
                              staticClass: "timepicker",
                              attrs: {
                                "manual-input": "",
                                "hide-clear-button": "",
                                format: "HH:mm",
                                disabled: !_vm.editShift,
                                value: _vm.selectedShift.actual_start,
                              },
                              model: {
                                value: _vm.startHour,
                                callback: function ($$v) {
                                  _vm.startHour = $$v
                                },
                                expression: "startHour",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "filter-date-container",
                            style: { "margin-top": "20px" },
                          },
                          [
                            _vm.editShift
                              ? _c("form-site-field-date", {
                                  staticClass: "end1",
                                  style: {
                                    "margin-top": "-2px",
                                    width: "160px",
                                    "margin-right": "20px",
                                  },
                                  attrs: {
                                    title: _vm.$t("to") + " " + _vm.$t("Date"),
                                    disabled: !_vm.editShift,
                                  },
                                  model: {
                                    value: _vm.selectedShift.actual_end,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedShift,
                                        "actual_end",
                                        $$v
                                      )
                                    },
                                    expression: "selectedShift.actual_end",
                                  },
                                })
                              : _vm._e(),
                            !_vm.editShift
                              ? _c("form-site-field-date", {
                                  staticClass: "end1",
                                  attrs: {
                                    value: _vm.selectedShift.actual_end.slice(
                                      0,
                                      10
                                    ),
                                    title: _vm.$t("to") + " " + _vm.$t("Date"),
                                    disabled: !_vm.editShift,
                                  },
                                })
                              : _vm._e(),
                            _c("Vue-timepicker", {
                              staticClass: "timepicker",
                              attrs: {
                                "manual-input": "",
                                disabled: !_vm.editShift,
                                "hide-clear-button": "",
                                format: "HH:mm",
                                value: _vm.selectedShift.actual_end,
                              },
                              model: {
                                value: _vm.endHour,
                                callback: function ($$v) {
                                  _vm.endHour = $$v
                                },
                                expression: "endHour",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "edit_record",
                            style: {
                              "margin-top": "50px",
                              "margin-bottom": "0px",
                            },
                          },
                          [
                            !_vm.editShift
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn",
                                    on: { click: _vm.edit },
                                  },
                                  [
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Edit")),
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm.editShift
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn",
                                    on: { click: _vm.save },
                                  },
                                  [
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Save")),
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }