var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    { attrs: { title: "Segments" }, on: { close: _vm.close } },
    [
      _c("div", { staticClass: "popup__content" }, [
        _c("div", { staticClass: "form-new-user" }, [
          _c(
            "form",
            {
              staticClass: "form-site-wrap",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.save.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "form-site" },
                [
                  _c("div", { staticClass: "new-segment" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newSegmentText,
                          expression: "newSegmentText",
                        },
                      ],
                      ref: "inp",
                      attrs: { placeholder: _vm.$t("E.g. food"), type: "text" },
                      domProps: { value: _vm.newSegmentText },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.newSegmentText = $event.target.value
                        },
                      },
                    }),
                    _c(
                      "button",
                      {
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.addSegment.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "12",
                              height: "12",
                              viewBox: "0 0 12 12",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("rect", {
                              attrs: {
                                x: "5.25",
                                width: "1.5",
                                height: "12",
                                rx: "0.75",
                                fill: "#ACACAC",
                              },
                            }),
                            _c("rect", {
                              attrs: {
                                y: "6.75",
                                width: "1.5",
                                height: "12",
                                rx: "0.75",
                                transform: "rotate(-90 0 6.75)",
                                fill: "#ACACAC",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _vm._l(_vm.segmentsCopy, function (segment, i) {
                    return _c(
                      "form",
                      {
                        key: segment.id || "i" + i,
                        staticClass: "segment",
                        on: {
                          click: function ($event) {
                            return _vm.setSegmentInEdit(i)
                          },
                        },
                      },
                      [
                        segment.inEdit
                          ? [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: segment.textInEdit,
                                    expression: "segment.textInEdit",
                                  },
                                ],
                                ref: "refSegment" + i,
                                refInFor: true,
                                attrs: { type: "text" },
                                domProps: { value: segment.textInEdit },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      segment,
                                      "textInEdit",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-green-small m0",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      segment.inEdit = false
                                      segment.name = segment.textInEdit
                                      _vm.$forceUpdate()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "icon",
                                      attrs: {
                                        width: "16",
                                        height: "12",
                                        viewBox: "0 0 16 12",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M15.2913 0.73686C14.9931 0.438625 14.5096 0.438625 14.2113 0.73686L5.06248 9.88579L1.54581 6.36913C1.24761 6.07089 0.76413 6.07092 0.465864 6.36913C0.167629 6.66733 0.167629 7.15081 0.465864 7.44904L4.5225 11.5056C4.82062 11.8038 5.30445 11.8036 5.60245 11.5056L15.2913 1.81681C15.5895 1.5186 15.5895 1.0351 15.2913 0.73686Z",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          : [
                              _c("span", {
                                domProps: { textContent: _vm._s(segment.name) },
                              }),
                            ],
                      ],
                      2
                    )
                  }),
                ],
                2
              ),
              _c("div", { staticClass: "form-site__btn md-none" }, [
                _c("button", {
                  staticClass: "btn",
                  attrs: { type: "submit" },
                  domProps: { textContent: _vm._s(_vm.$t("Save")) },
                }),
              ]),
              _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
                _c(
                  "button",
                  { staticClass: "save-btn", attrs: { type: "submit" } },
                  [
                    _c("svg-icon", {
                      staticClass: "ic-edit",
                      attrs: { name: "ic-save-btn", width: "93", height: "93" },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }