var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-combination" },
    [
      _c("base-dialog", { on: { close: _vm.close } }, [
        _c("div", { staticClass: "popup-top flex pt8" }, [
          _vm.english
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.combinationCopy.name_en,
                    expression: "combinationCopy.name_en",
                  },
                ],
                ref: "name",
                staticClass: "h1 w260 max-w100 mb0",
                attrs: { placeholder: _vm.$t("Name"), type: "text" },
                domProps: { value: _vm.combinationCopy.name_en },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.combinationCopy,
                      "name_en",
                      $event.target.value
                    )
                  },
                },
              })
            : _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.combinationCopy.name,
                    expression: "combinationCopy.name",
                  },
                ],
                ref: "name",
                staticClass: "h1 w260 max-w100 mb0",
                attrs: { placeholder: _vm.$t("Name"), type: "text" },
                domProps: { value: _vm.combinationCopy.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.combinationCopy, "name", $event.target.value)
                  },
                },
              }),
          _c("div", { staticClass: "lang lang_grey" }, [
            _c("button", {
              staticClass: "lang__item",
              class: { active: _vm.english },
              domProps: { textContent: _vm._s(_vm.$t("English")) },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  _vm.english = true
                },
              },
            }),
            _c("button", {
              staticClass: "lang__item",
              class: { active: !_vm.english },
              domProps: { textContent: _vm._s(_vm.$t("Hebrew")) },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  _vm.english = false
                },
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "popup-main" }, [
          _c(
            "div",
            { staticClass: "items" },
            [
              _vm.combinationCopy.items.length
                ? _c("div", {
                    staticClass: "h3",
                    domProps: { textContent: _vm._s(_vm.$t("Items")) },
                  })
                : _vm._e(),
              _c(
                "draggable",
                {
                  staticClass: "sidebar-menu__list",
                  attrs: { handle: ".handle-drag" },
                  model: {
                    value: _vm.combinationCopy.items,
                    callback: function ($$v) {
                      _vm.$set(_vm.combinationCopy, "items", $$v)
                    },
                    expression: "combinationCopy.items",
                  },
                },
                [
                  _vm._l(_vm.combinationCopy.items, function (item, index) {
                    return [
                      !item.from_tag
                        ? _c("div", { key: item.id, staticClass: "itme" }, [
                            _c("div", { staticClass: "img-name" }, [
                              _c("div", {
                                staticClass: "name",
                                domProps: {
                                  textContent: _vm._s(
                                    item.name +
                                      (item.makat ? "(" + item.makat + ")" : "")
                                  ),
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "settings" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "switch-wrap d-flex a-center mie12",
                                  staticStyle: { "flex-direction": "column" },
                                },
                                [
                                  _c("div", {
                                    staticClass: "switch-wrap__label",
                                    staticStyle: { "font-size": "smaller" },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Original price")
                                      ),
                                    },
                                  }),
                                  _c("swich-button", {
                                    attrs: { value: true },
                                    model: {
                                      value: item.original_price,
                                      callback: function ($$v) {
                                        _vm.$set(item, "original_price", $$v)
                                      },
                                      expression: "item.original_price",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "price" }, [
                                _vm.restaurantCurrencyInfo === null
                                  ? _c(
                                      "div",
                                      { staticClass: "input-label__note" },
                                      [
                                        _vm._v(
                                          "\n                      ₪\n                    "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.restaurantCurrencyInfo !== null
                                  ? _c(
                                      "div",
                                      { staticClass: "input-label__note" },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.restaurantCurrencySymbol
                                            ) +
                                            "\n                    "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item.price,
                                      expression: "item.price",
                                    },
                                  ],
                                  staticClass: "pis60",
                                  attrs: {
                                    placeholder: "0",
                                    type: "number",
                                    disabled: item.original_price,
                                  },
                                  domProps: { value: item.price },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        item,
                                        "price",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-icon btn-icon_light-red",
                                  staticStyle: { width: "50px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeItem(index)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "btn-icon__note",
                                      staticStyle: { background: "#fbecee" },
                                    },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          name: "ic-bin",
                                          width: "18",
                                          height: "20",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "handle-drag mis8" },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      name: "ic-double-more",
                                      width: "12",
                                      height: "20",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
              _vm.combinationCopy.tags.length
                ? _c("div", {
                    staticClass: "h3",
                    domProps: { textContent: _vm._s(_vm.$t("Tags")) },
                  })
                : _vm._e(),
              _c(
                "draggable",
                {
                  staticClass: "sidebar-menu__list",
                  attrs: { handle: ".handle-drag" },
                  model: {
                    value: _vm.combinationCopy.tags,
                    callback: function ($$v) {
                      _vm.$set(_vm.combinationCopy, "tags", $$v)
                    },
                    expression: "combinationCopy.tags",
                  },
                },
                _vm._l(_vm.combinationCopy.tags, function (tag, index) {
                  return _c("div", { key: tag.id, staticClass: "itme" }, [
                    _c("div", { staticClass: "img-name" }, [
                      _c("div", {
                        staticClass: "name",
                        domProps: {
                          textContent: _vm._s(
                            _vm.tagName(tag.id) + " (" + _vm.$t("Tag") + ")"
                          ),
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "settings" }, [
                      _c(
                        "div",
                        {
                          staticClass: "switch-wrap d-flex a-center mie12",
                          staticStyle: { "flex-direction": "column" },
                        },
                        [
                          _c("div", {
                            staticClass: "switch-wrap__label",
                            staticStyle: { "font-size": "smaller" },
                            domProps: {
                              textContent: _vm._s(_vm.$t("Combine")),
                            },
                          }),
                          _c("swich-button", {
                            attrs: { value: true },
                            model: {
                              value: _vm.combinationCopy.tags[index].combine,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.combinationCopy.tags[index],
                                  "combine",
                                  $$v
                                )
                              },
                              expression: "combinationCopy.tags[index].combine",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "switch-wrap d-flex a-center mie12",
                          staticStyle: { "flex-direction": "column" },
                        },
                        [
                          _c("div", {
                            staticClass: "switch-wrap__label",
                            staticStyle: { "font-size": "smaller" },
                            domProps: {
                              textContent: _vm._s(_vm.$t("Original price")),
                            },
                          }),
                          _c("swich-button", {
                            attrs: { value: true },
                            model: {
                              value:
                                _vm.combinationCopy.tags[index].original_price,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.combinationCopy.tags[index],
                                  "original_price",
                                  $$v
                                )
                              },
                              expression:
                                "combinationCopy.tags[index].original_price",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "price" }, [
                        _vm.restaurantCurrencyInfo === null
                          ? _c("div", { staticClass: "input-label__note" }, [
                              _vm._v(
                                "\n                    ₪\n                  "
                              ),
                            ])
                          : _vm._e(),
                        _vm.restaurantCurrencyInfo !== null
                          ? _c("div", { staticClass: "input-label__note" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.restaurantCurrencySymbol) +
                                  "\n                  "
                              ),
                            ])
                          : _vm._e(),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.combinationCopy.tags[index].price,
                              expression: "combinationCopy.tags[index].price",
                            },
                          ],
                          staticClass: "pis60",
                          attrs: {
                            placeholder: "0",
                            type: "number",
                            disabled:
                              _vm.combinationCopy.tags[index].original_price,
                          },
                          domProps: {
                            value: _vm.combinationCopy.tags[index].price,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.combinationCopy.tags[index],
                                "price",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "btn-icon btn-icon_light-red",
                          staticStyle: { width: "50px" },
                          on: {
                            click: function ($event) {
                              return _vm.removeTag(index)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "btn-icon__note",
                              staticStyle: { background: "#fbecee" },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-bin",
                                  width: "18",
                                  height: "20",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "handle-drag mis8" },
                        [
                          _c("svg-icon", {
                            attrs: {
                              name: "ic-double-more",
                              width: "12",
                              height: "20",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                }),
                0
              ),
            ],
            1
          ),
          _c("div", { staticClass: "line-more" }, [
            _c(
              "button",
              {
                staticClass: "line-more__content",
                on: { click: _vm.startAddItem },
              },
              [
                _c(
                  "div",
                  { staticClass: "line-more__icon" },
                  [
                    _c("svg-icon", {
                      attrs: { name: "ic-plus", width: "12", height: "12" },
                    }),
                  ],
                  1
                ),
                _c("span", {
                  staticClass: "line-more__text",
                  domProps: { textContent: _vm._s(_vm.$t("Add")) },
                }),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "popup-btn pb40 d-md-flex d-none" },
          [
            _vm.combinationCopy.id || _vm.combinationCopy.groupTmpId
              ? _c("remove-btn", { on: { click: _vm.remove } })
              : _vm._e(),
            _vm.combinationCopy.id
              ? _c(
                  "button",
                  {
                    staticClass: "btn-icon btn-icon_yellow",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.duplicate.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "btn-icon__note" },
                      [
                        _c("svg-icon", {
                          attrs: {
                            name: "ic-document",
                            width: "20",
                            height: "20",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", {
                      staticClass: "btn-icon__text flex a-center",
                      domProps: { textContent: _vm._s(_vm.$t("Duplicate")) },
                    }),
                  ]
                )
              : _c("div", {}),
            _c("button", {
              staticClass: "btn",
              domProps: { textContent: _vm._s(_vm.$t("save")) },
              on: { click: _vm.save },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
          _vm.combinationCopy.id || _vm.combinationCopy.groupTmpId
            ? _c(
                "button",
                { staticClass: "bin-btn", on: { click: _vm.remove } },
                [
                  _c("svg-icon", {
                    attrs: { name: "ic-bin", width: "18", height: "20" },
                  }),
                ],
                1
              )
            : _c("div"),
          _c(
            "button",
            { staticClass: "save-btn", on: { click: _vm.save } },
            [
              _c("svg-icon", {
                staticClass: "ic-edit",
                attrs: { name: "ic-save-btn", width: "112", height: "112" },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm.choseItemIsOpened
        ? _c("edit-combination-add-item", {
            attrs: {
              tags: _vm.combinationCopy.tags,
              categories: _vm.categories,
              items: _vm.items,
            },
            on: { selectItems: _vm.selectItems },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }