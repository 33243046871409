var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "search-form",
      class: { active: _vm.searchFormOpen },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.search.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "button",
        {
          staticClass: "search-form__btn",
          attrs: { type: "button" },
          on: { click: _vm.toggleSearchForm },
        },
        [
          _c("svg-icon", {
            staticClass: "ic-stroke",
            attrs: { name: "ic-search", width: "16", height: "16" },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "search-form__field" }, [
        _c("input", {
          directives: [
            {
              name: "debounce",
              rawName: "v-debounce",
              value: _vm.search,
              expression: "search",
            },
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchTerm,
              expression: "searchTerm",
            },
          ],
          ref: "searchTermInput",
          attrs: {
            type: "text",
            placeholder: _vm.$t("Search") + "...",
            name: "searchQuery",
            "aria-label": "Search",
          },
          domProps: { value: _vm.searchTerm },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.searchTerm = $event.target.value
            },
          },
        }),
        _c(
          "div",
          {
            staticClass: "search-form__close",
            on: { click: _vm.toggleSearchForm },
          },
          [
            _c("svg-icon", {
              attrs: { name: "ic-close", width: "13", height: "13" },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }