var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c(
      "span",
      { staticClass: "question-mark", on: { click: _vm.togglePopup } },
      [_vm._v("?")]
    ),
    _vm.showPopup
      ? _c(
          "span",
          { staticClass: "popup-overlay", on: { click: _vm.togglePopup } },
          [
            _c(
              "span",
              {
                staticClass: "popup-text",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "close-button",
                    on: { click: _vm.togglePopup },
                  },
                  [
                    _c("svg-icon", {
                      attrs: { name: "ic-close", width: "10", height: "10" },
                    }),
                  ],
                  1
                ),
                _vm.title
                  ? _c("span", { staticClass: "title" }, [
                      _vm._v("\n        " + _vm._s(_vm.title) + "\n      "),
                    ])
                  : _vm._e(),
                _vm.$slots.title
                  ? _c("span", { staticClass: "title" }, [_vm._t("title")], 2)
                  : _vm._e(),
                _vm._t("default"),
              ],
              2
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }