var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select select-client", class: { opened: _vm.opened } },
    [
      !_vm.opened
        ? _c("div", {
            staticClass: "select__head",
            domProps: {
              textContent: _vm._s(
                _vm.client
                  ? _vm.client.name + " " + _vm.client.phone
                  : _vm.search
              ),
            },
            on: { click: _vm.open },
          })
        : _c("div", { staticClass: "select__head" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.search,
                  expression: "search",
                },
              ],
              ref: "inp",
              attrs: { type: "text" },
              domProps: { value: _vm.search },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.search = $event.target.value
                  },
                  _vm.searchHandler,
                ],
                blur: _vm.exit,
              },
            }),
          ]),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.opened && _vm.clients.length,
              expression: "opened && clients.length",
            },
          ],
          staticClass: "option-container",
        },
        _vm._l(_vm.clients, function (currClient) {
          return _c(
            "li",
            {
              key: currClient.id,
              staticClass: "flex a-center",
              on: {
                click: function ($event) {
                  return _vm.select(currClient)
                },
              },
            },
            [
              _c("section", { staticClass: "flex a-center bet" }, [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(
                      currClient.name + " " + currClient.phone
                    ),
                  },
                }),
              ]),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }