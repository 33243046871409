var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "notices popup-trans" }, [
    _c(
      "main",
      [
        _c("section", { staticClass: "container" }, [
          _c("div", { staticClass: "page" }, [
            _c("div", { staticClass: "page__main" }, [
              _c("div", { staticClass: "page__top mb12 md-none" }, [
                _c("h1", {
                  domProps: { textContent: _vm._s(_vm.$t("System notices")) },
                }),
                _vm.checkPermissionPath("system_notices.full_access")
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn_pink",
                        attrs: { href: "#" },
                        on: { click: _vm.startCreatePopup },
                      },
                      [
                        _c("svg-icon", {
                          attrs: { name: "ic-plus", width: "16", height: "16" },
                        }),
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(_vm.$t("New notice")),
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div"),
              ]),
              _c(
                "button",
                {
                  staticClass: "mobile-btn-plus d-md-none",
                  on: { click: _vm.startCreatePopup },
                },
                [
                  _c("svg-icon", {
                    attrs: { name: "ic-plus", width: "16", height: "16" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "page__content" },
                [
                  _vm.isLoaded
                    ? [
                        _c(
                          "draggable",
                          {
                            staticClass: "popup-list",
                            attrs: { tag: "div", handle: ".handle-drag" },
                            on: { end: _vm.move },
                          },
                          [
                            _vm.popupList.length > 0
                              ? _c(
                                  "transition-group",
                                  { attrs: { name: "fade" } },
                                  [
                                    _vm._l(_vm.popupList, function (popup) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            key: popup.id,
                                            staticClass:
                                              "popup-item popup-item-none-details",
                                            on: {
                                              click: function ($event) {
                                                return _vm.editPopup(popup)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-site__field flex a-center",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "handle-drag",
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      attrs: {
                                                        name: "ic-double-more",
                                                        width: "12",
                                                        height: "20",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c("div", {
                                                  staticClass: "title m0 pis12",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      popup.name
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "btns-ctrl" },
                                              [
                                                _c("swich-button", {
                                                  staticClass: "mie8",
                                                  attrs: {
                                                    disabled:
                                                      !_vm.checkPermissionPath(
                                                        "system_notices.full_access"
                                                      ) &&
                                                      !_vm.checkPermissionPath(
                                                        "system_notices.active"
                                                      ),
                                                    value: popup.active,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.activePopup(
                                                        $event,
                                                        popup
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    }),
                                  ],
                                  2
                                )
                              : _c("span", {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "center",
                                    width: "100%",
                                    margin: "50px",
                                  },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("No data available")
                                    ),
                                  },
                                }),
                          ],
                          1
                        ),
                      ]
                    : _c(
                        "div",
                        { staticClass: "loader-container" },
                        _vm._l(6, function (index) {
                          return _c("skeleton-box", {
                            key: index * 1000,
                            style: { opacity: 1 / index },
                          })
                        }),
                        1
                      ),
                ],
                2
              ),
            ]),
          ]),
        ]),
        _vm.showCreatePopup && _vm.popupInEdit
          ? _c("edit-notices-dialog", {
              attrs: { popupData: _vm.popupInEdit },
              on: {
                close: function ($event) {
                  _vm.showCreatePopup = false
                },
                save: _vm.savePopup,
                remove: _vm.remove,
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }