var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      staticClass: "geoman-map",
      attrs: { width: "95vw" },
      on: { close: _vm.close },
    },
    [
      _c("div", { staticClass: "popup__content pt20" }, [
        _c("div", { staticClass: "form-new-user" }, [
          _c("div", {
            staticClass: "title-branch-name",
            domProps: {
              textContent: _vm._s(
                _vm.he
                  ? _vm.$store.getters.branch.name
                  : _vm.$store.getters.branch.name_en
              ),
            },
          }),
          _c("div", { staticClass: "map", attrs: { id: "map" } }),
          _c(
            "div",
            { staticClass: "flex md-none bet" },
            [
              _c("select-box", {
                staticClass: "select-map mt16",
                attrs: {
                  openToUp: "",
                  options: _vm.optionsDeliveryAreas,
                  value: _vm.id,
                },
                on: { input: _vm.changeMap },
              }),
              _c("div"),
              _c("div", { staticClass: "flex j-center form-site" }, [
                _c(
                  "div",
                  { staticClass: "form-site__content-contain mb0" },
                  [
                    _c("form-site-field-text", {
                      staticClass: "mie16 mb0",
                      attrs: {
                        sub: "",
                        title: "minimum order amount",
                        sign: "₪",
                      },
                      model: {
                        value: _vm.area.min,
                        callback: function ($$v) {
                          _vm.$set(_vm.area, "min", $$v)
                        },
                        expression: "area.min",
                      },
                    }),
                    _c("form-site-field-text", {
                      staticClass: "mie16 mb0",
                      attrs: { sub: "", title: "delivery cost", sign: "₪" },
                      model: {
                        value: _vm.area.price,
                        callback: function ($$v) {
                          _vm.$set(_vm.area, "price", $$v)
                        },
                        expression: "area.price",
                      },
                    }),
                    _c("form-site-field-text", {
                      staticClass: "mb0",
                      attrs: {
                        sub: "",
                        title: "estimated delivery time",
                        sign: _vm.$t("min"),
                      },
                      model: {
                        value: _vm.area.time,
                        callback: function ($$v) {
                          _vm.$set(_vm.area, "time", $$v)
                        },
                        expression: "area.time",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn mt16",
                  attrs: { type: "button" },
                  on: { click: _vm.save },
                },
                [
                  _c("span", {
                    domProps: { textContent: _vm._s(_vm.$t("Save")) },
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "client-detail__bottom flex d-md-none bet" },
            [
              _c(
                "button",
                { staticClass: "save-btn", on: { click: _vm.save } },
                [
                  _c("svg-icon", {
                    staticClass: "ic-edit",
                    attrs: { name: "ic-save-btn", width: "93", height: "93" },
                  }),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }