var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      staticClass: "user-reset-password",
      attrs: { width: "600px" },
      on: {
        close: function ($event) {
          return _vm.$emit("save")
        },
      },
    },
    [
      _c("div", { staticClass: "form-site" }, [
        _c("div", { staticClass: "form-site__field" }, [
          _c("span", {
            staticClass: "form-site__label",
            domProps: {
              textContent: _vm._s(
                _vm.$t(
                  "Create a strong password by including at least 6 characters with a mix of uppercase and lowercase letters, numbers, and a special character."
                )
              ),
            },
          }),
          _c("div", { staticClass: "form-site__input" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password",
                },
              ],
              staticClass: "mb8",
              attrs: { maxlength: 30, type: "text" },
              domProps: { value: _vm.password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.password = $event.target.value
                },
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "generate-password-wrapper" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-outline",
              attrs: { type: "button" },
            },
            [
              _c("span", {
                domProps: { textContent: _vm._s(_vm.$t("Generate password")) },
                on: { click: _vm.fillPassword },
              }),
            ]
          ),
        ]),
        _c("div", { staticClass: "form-site__btn" }, [
          _c("button", {
            staticClass: "btn btn_border",
            domProps: { textContent: _vm._s(_vm.$t("Cancel")) },
            on: {
              click: function ($event) {
                return _vm.$emit("save")
              },
            },
          }),
          _c("button", {
            staticClass: "btn",
            domProps: { textContent: _vm._s(_vm.$t("Save")) },
            on: { click: _vm.save },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }