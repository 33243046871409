var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "new-item-info__top" }, [
      _c("div", {
        staticClass: "new-item-info__head",
        domProps: {
          textContent: _vm._s(
            _vm.he ||
              !_vm.itemCopy.name_en ||
              _vm.itemCopy.name_en === "undefined"
              ? _vm.itemCopy.name
              : _vm.itemCopy.name_en
          ),
        },
      }),
    ]),
    _c(
      "div",
      { staticClass: "promotion-list" },
      _vm._l(_vm.itemCopy.promotions, function (promotion) {
        return _c(
          "div",
          {
            key: promotion.id,
            staticClass: "promotion-item",
            on: {
              click: function ($event) {
                return _vm.openPromotion(promotion)
              },
            },
          },
          [
            _c("div", {
              staticClass: "text",
              domProps: { textContent: _vm._s(promotion.name_he) },
            }),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }