var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showing
    ? _c(
        "base-dialog",
        { attrs: { width: "700px" }, on: { close: _vm.close } },
        [
          _c("div", { staticClass: "popup__top" }, [
            _c("div", { staticClass: "popup__head" }, [
              _c("div", {
                staticClass: "h1",
                domProps: { textContent: _vm._s(_vm.$t("Send SMS")) },
              }),
              _c("div", {
                staticClass: "h4",
                domProps: {
                  textContent: _vm._s(_vm.$t("to") + ": " + _vm.number),
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "popup__content pb40" }, [
            _c(
              "form",
              {
                staticClass: "form-site-wrap",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.save.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "form-site" }, [
                  _c("div", { staticClass: "form-site__content" }, [
                    _c("div", { staticClass: "form-site__field" }, [
                      _c("div", { staticClass: "form-site__label-wrap" }, [
                        _c("div", {
                          staticClass: "form-site__label",
                          domProps: { textContent: _vm._s(_vm.$t("Message")) },
                        }),
                        _c("div", {
                          staticClass: "form-site__msg-count",
                          domProps: {
                            textContent: _vm._s(_vm.content.length + "/134"),
                          },
                        }),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-site-msg" },
                        [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.content,
                                expression: "content",
                              },
                            ],
                            attrs: {
                              maxlength: "134",
                              placeholder: _vm.$t("Type your message"),
                            },
                            domProps: { value: _vm.content },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.content = $event.target.value
                              },
                            },
                          }),
                          _c("cs-emoji-picker", {
                            model: {
                              value: _vm.content,
                              callback: function ($$v) {
                                _vm.content = $$v
                              },
                              expression: "content",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-site__btn" }, [
                    _c(
                      "button",
                      { staticClass: "btn", attrs: { type: "submit" } },
                      [
                        _c("span", {
                          domProps: { textContent: _vm._s(_vm.$t("Send")) },
                        }),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }