var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-dialog-tabs", {
    staticClass: "edit-notices-dialog",
    attrs: {
      width: "672px",
      tabList: ["General info", "Branches", "Availability", "Images"],
    },
    on: { close: _vm.close },
    scopedSlots: _vm._u([
      {
        key: "tab0",
        fn: function () {
          return [
            _c("div", { staticClass: "item-header" }, [
              _c(
                "div",
                {
                  staticClass: "header-back back d-flex d-md-none",
                  style: { "margin-right": "-25px", width: "103vw" },
                },
                [
                  _c(
                    "button",
                    { staticClass: "header__back", on: { click: _vm.close } },
                    [
                      _c(
                        "span",
                        { staticClass: "header-company" },
                        [
                          _c("svg-icon", {
                            staticClass: "ic-white",
                            attrs: {
                              name: "ic-arrow-left",
                              width: "16",
                              height: "12",
                            },
                          }),
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Back")) },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _c("div", { staticClass: "title-item-header" }, [
                _c(
                  "div",
                  {
                    staticClass: "header-back back d-flex d-md-none",
                    style: { "margin-right": "-30px", width: "103vw" },
                  },
                  [
                    _c(
                      "button",
                      { staticClass: "header__back", on: { click: _vm.close } },
                      [
                        _c(
                          "span",
                          { staticClass: "header-company" },
                          [
                            _c("svg-icon", {
                              staticClass: "ic-white",
                              attrs: {
                                name: "ic-arrow-left",
                                width: "16",
                                height: "12",
                              },
                            }),
                            _c("span", {
                              domProps: { textContent: _vm._s(_vm.$t("Back")) },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "ctrl-item-header flex w100 bet a-center mb12",
                  },
                  [
                    _c("div", { staticClass: "lang" }, [
                      _c("button", {
                        staticClass: "lang__item",
                        class: { active: !_vm.english },
                        domProps: { textContent: _vm._s(_vm.$t("Hebrew")) },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            _vm.english = false
                          },
                        },
                      }),
                      _c("button", {
                        staticClass: "lang__item",
                        class: { active: _vm.english },
                        domProps: { textContent: _vm._s(_vm.$t("English")) },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            _vm.english = true
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "btn-active" }, [
                      _c(
                        "label",
                        [
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Active")) },
                          }),
                          _c("swich-button", {
                            attrs: {
                              disabled: !(
                                _vm.checkPermissionPath(
                                  "system_notices.active"
                                ) ||
                                _vm.checkPermissionPath(
                                  "system_notices.full_access"
                                )
                              ),
                            },
                            model: {
                              value: _vm.popupInEdit.active,
                              callback: function ($$v) {
                                _vm.$set(_vm.popupInEdit, "active", $$v)
                              },
                              expression: "popupInEdit.active",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "form-site__field" }, [
                  _c("div", {
                    staticClass: "form-site__label",
                    domProps: { textContent: _vm._s(_vm.$t("Name")) },
                  }),
                  _c("div", { staticClass: "form-site__input" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupInEdit.name,
                          expression: "popupInEdit.name",
                        },
                      ],
                      staticClass: "title-input",
                      attrs: {
                        disabled: !_vm.checkPermissionPath(
                          "system_notices.full_access"
                        ),
                        type: "text",
                      },
                      domProps: { value: _vm.popupInEdit.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.popupInEdit, "name", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-site__field" }, [
                  _c("div", {
                    staticClass: "form-site__label",
                    domProps: { textContent: _vm._s(_vm.$t("Title")) },
                  }),
                  _c("div", { staticClass: "form-site__input" }, [
                    _vm.english
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.popupInEdit.title_en,
                              expression: "popupInEdit.title_en",
                            },
                          ],
                          staticClass: "title-input",
                          attrs: {
                            disabled: !_vm.checkPermissionPath(
                              "system_notices.full_access"
                            ),
                            type: "text",
                          },
                          domProps: { value: _vm.popupInEdit.title_en },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.popupInEdit,
                                "title_en",
                                $event.target.value
                              )
                            },
                          },
                        })
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.popupInEdit.title,
                              expression: "popupInEdit.title",
                            },
                          ],
                          staticClass: "title-input",
                          attrs: {
                            disabled: !_vm.checkPermissionPath(
                              "system_notices.full_access"
                            ),
                            type: "text",
                          },
                          domProps: { value: _vm.popupInEdit.title },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.popupInEdit,
                                "title",
                                $event.target.value
                              )
                            },
                          },
                        }),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "flex a-center mt12 mb12",
                    style: {
                      "justify-content": "space-between",
                      width: "100%",
                    },
                  },
                  [
                    _c("div", { staticClass: "popup_message__checkboxes" }, [
                      _c(
                        "label",
                        [
                          _c("checkbox", {
                            attrs: {
                              disabled: !_vm.checkPermissionPath(
                                "system_notices.full_access"
                              ),
                              value: _vm.popupInEdit.type === 0,
                              name: "popup",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.setType(0)
                              },
                            },
                          }),
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Popup")) },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "label",
                        { staticClass: "mis8" },
                        [
                          _c("checkbox", {
                            attrs: {
                              disabled: !_vm.checkPermissionPath(
                                "system_notices.full_access"
                              ),
                              value: _vm.popupInEdit.type === 1,
                              name: "message",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.setType(1)
                              },
                            },
                          }),
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(_vm.$t("Message")),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm.popupInEdit.type == 1 && _vm.reload >= 1
                      ? _c("select-box", {
                          key: _vm.popupInEdit.category_id,
                          staticClass: "selectCategory",
                          attrs: {
                            width: "160px",
                            widthHeader: "160px",
                            unsort: "",
                            placeholder: _vm.$t("All"),
                            options: _vm.categoriesOptions.map(function (
                              label,
                              value
                            ) {
                              return {
                                label: _vm.$t(label),
                                value: value,
                              }
                            }),
                            showSearch: "",
                          },
                          on: { input: _vm.input },
                          model: {
                            value: _vm.popupInEdit.category_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.popupInEdit, "category_id", $$v)
                            },
                            expression: "popupInEdit.category_id",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "item-description" }, [
              _c("div", { staticClass: "form-site__field" }, [
                _c("div", {
                  staticClass: "form-site__label",
                  domProps: { textContent: _vm._s(_vm.$t("Notice content")) },
                }),
                _c(
                  "div",
                  { staticClass: "form-site__input" },
                  [
                    _vm.english
                      ? _c("quill-editor", {
                          attrs: { options: _vm.editorOption },
                          model: {
                            value: _vm.popupInEdit.text_en,
                            callback: function ($$v) {
                              _vm.$set(_vm.popupInEdit, "text_en", $$v)
                            },
                            expression: "popupInEdit.text_en",
                          },
                        })
                      : _c("quill-editor", {
                          attrs: { options: _vm.editorOption },
                          model: {
                            value: _vm.popupInEdit.text,
                            callback: function ($$v) {
                              _vm.$set(_vm.popupInEdit, "text", $$v)
                            },
                            expression: "popupInEdit.text",
                          },
                        }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "audience" }, [
              _c("div", { staticClass: "form-site__field" }, [
                _c("div", {
                  staticClass: "form-site__label",
                  domProps: { textContent: _vm._s(_vm.$t("Audience")) },
                }),
                _c("div", { staticClass: "form-site__input checkbox-list" }, [
                  _c(
                    "label",
                    { style: { display: "flex" } },
                    [
                      _c("checkbox", {
                        attrs: {
                          disabled: !_vm.checkPermissionPath(
                            "system_notices.full_access"
                          ),
                          name: "all",
                        },
                        on: {
                          input: function (ev) {
                            if (ev) {
                              ;[
                                "show_club_member",
                                "show_new_club_member",
                                "show_first_order",
                              ].forEach(function (key) {
                                _vm.popupInEdit[key] = true
                              })
                            }
                          },
                        },
                        model: {
                          value: _vm.popupInEdit.show_all,
                          callback: function ($$v) {
                            _vm.$set(_vm.popupInEdit, "show_all", $$v)
                          },
                          expression: "popupInEdit.show_all",
                        },
                      }),
                      _c("span", {
                        domProps: { textContent: _vm._s(_vm.$t("All")) },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "label",
                    {
                      class: { disabled: _vm.popupInEdit.show_all },
                      style: { display: "flex" },
                    },
                    [
                      _c("checkbox", {
                        attrs: {
                          disabled:
                            !_vm.checkPermissionPath(
                              "system_notices.full_access"
                            ) || _vm.popupInEdit.show_all,
                          name: "club-members",
                        },
                        model: {
                          value: _vm.popupInEdit.show_club_member,
                          callback: function ($$v) {
                            _vm.$set(_vm.popupInEdit, "show_club_member", $$v)
                          },
                          expression: "popupInEdit.show_club_member",
                        },
                      }),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("Club members")),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "label",
                    {
                      class: { disabled: _vm.popupInEdit.show_all },
                      style: { display: "flex" },
                    },
                    [
                      _c("checkbox", {
                        attrs: {
                          disabled:
                            !_vm.checkPermissionPath(
                              "system_notices.full_access"
                            ) || _vm.popupInEdit.show_all,
                          name: "new-club-members",
                        },
                        model: {
                          value: _vm.popupInEdit.show_new_club_member,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.popupInEdit,
                              "show_new_club_member",
                              $$v
                            )
                          },
                          expression: "popupInEdit.show_new_club_member",
                        },
                      }),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("New club members")),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "label",
                    {
                      class: { disabled: _vm.popupInEdit.show_all },
                      style: { display: "flex" },
                    },
                    [
                      _c("checkbox", {
                        attrs: {
                          disabled:
                            !_vm.checkPermissionPath(
                              "system_notices.full_access"
                            ) || _vm.popupInEdit.show_all,
                          name: "first-order",
                        },
                        model: {
                          value: _vm.popupInEdit.show_first_order,
                          callback: function ($$v) {
                            _vm.$set(_vm.popupInEdit, "show_first_order", $$v)
                          },
                          expression: "popupInEdit.show_first_order",
                        },
                      }),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("First order")),
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "appear" }, [
              _c("div", { staticClass: "form-site__field" }, [
                _c("div", {
                  staticClass: "form-site__label",
                  domProps: { textContent: _vm._s(_vm.$t("Appear")) },
                }),
                _c("div", { staticClass: "form-site__input checkbox-list" }, [
                  !_vm.branchId || _vm.branchId === "0"
                    ? _c(
                        "label",
                        { style: { display: "flex" } },
                        [
                          _c("checkbox", {
                            attrs: {
                              disabled: !_vm.checkPermissionPath(
                                "system_notices.full_access"
                              ),
                              name: "menu",
                            },
                            model: {
                              value: _vm.popupInEdit.home_page,
                              callback: function ($$v) {
                                _vm.$set(_vm.popupInEdit, "home_page", $$v)
                              },
                              expression: "popupInEdit.home_page",
                            },
                          }),
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(_vm.$t("Start order")),
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "label",
                    { style: { display: "flex" } },
                    [
                      _c("checkbox", {
                        attrs: {
                          disabled: !_vm.checkPermissionPath(
                            "system_notices.full_access"
                          ),
                          name: "start-order",
                        },
                        model: {
                          value: _vm.popupInEdit.order_start_page,
                          callback: function ($$v) {
                            _vm.$set(_vm.popupInEdit, "order_start_page", $$v)
                          },
                          expression: "popupInEdit.order_start_page",
                        },
                      }),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("appear__menu")),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "label",
                    { style: { display: "flex" } },
                    [
                      _c("checkbox", {
                        attrs: {
                          disabled: !_vm.checkPermissionPath(
                            "system_notices.full_access"
                          ),
                          name: "Payment",
                        },
                        model: {
                          value: _vm.popupInEdit.payment_page,
                          callback: function ($$v) {
                            _vm.$set(_vm.popupInEdit, "payment_page", $$v)
                          },
                          expression: "popupInEdit.payment_page",
                        },
                      }),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("appear__Payment")),
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "tab1",
        fn: function () {
          return [
            _c("div", { staticClass: "flex w100 bet a-center p12" }, [
              _c(
                "div",
                {
                  staticClass: "header-back back d-flex d-md-none",
                  style: { "margin-right": "-35px", width: "103vw" },
                },
                [
                  _c(
                    "button",
                    { staticClass: "header__back", on: { click: _vm.close } },
                    [
                      _c(
                        "span",
                        { staticClass: "header-company" },
                        [
                          _c("svg-icon", {
                            staticClass: "ic-white",
                            attrs: {
                              name: "ic-arrow-left",
                              width: "16",
                              height: "12",
                            },
                          }),
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Back")) },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _c("div", {
                staticClass: "h2 mb0",
                domProps: {
                  textContent: _vm._s(
                    _vm.he ? _vm.popupInEdit.title : _vm.popupInEdit.title_en
                  ),
                },
              }),
              _c("div", { staticClass: "btn-active mie12" }, [
                _c(
                  "label",
                  [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.$t("Active")) },
                    }),
                    _c("swich-button", {
                      attrs: {
                        disabled: !(
                          _vm.checkPermissionPath("system_notices.active") ||
                          _vm.checkPermissionPath("system_notices.full_access")
                        ),
                      },
                      model: {
                        value: _vm.popupInEdit.active,
                        callback: function ($$v) {
                          _vm.$set(_vm.popupInEdit, "active", $$v)
                        },
                        expression: "popupInEdit.active",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("branches-tab", {
              attrs: {
                activity: "",
                availability: _vm.popupInEdit.availability,
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "tab2",
        fn: function () {
          return [
            _c("div", { staticClass: "flex w100 bet a-center p12" }, [
              _c(
                "div",
                {
                  staticClass: "header-back back d-flex d-md-none",
                  style: { "margin-right": "-32px", width: "103vw" },
                },
                [
                  _c(
                    "button",
                    { staticClass: "header__back", on: { click: _vm.close } },
                    [
                      _c(
                        "span",
                        { staticClass: "header-company" },
                        [
                          _c("svg-icon", {
                            staticClass: "ic-white",
                            attrs: {
                              name: "ic-arrow-left",
                              width: "16",
                              height: "12",
                            },
                          }),
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Back")) },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _c("div", {
                staticClass: "h2 mb0",
                domProps: {
                  textContent: _vm._s(
                    _vm.he ? _vm.popupInEdit.title : _vm.popupInEdit.title_en
                  ),
                },
              }),
              _c("div", { staticClass: "btn-active mie12" }, [
                _c(
                  "label",
                  [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.$t("Active")) },
                    }),
                    _c("swich-button", {
                      attrs: {
                        disabled: !(
                          _vm.checkPermissionPath("system_notices.active") ||
                          _vm.checkPermissionPath("system_notices.full_access")
                        ),
                      },
                      model: {
                        value: _vm.popupInEdit.active,
                        callback: function ($$v) {
                          _vm.$set(_vm.popupInEdit, "active", $$v)
                        },
                        expression: "popupInEdit.active",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("availability-tab", {
              attrs: { availability: _vm.popupInEdit.availability },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "tab3",
        fn: function () {
          return [
            _c("div", { staticClass: "flex w100 bet a-center p12" }, [
              _c(
                "div",
                {
                  staticClass: "header-back back d-flex d-md-none",
                  style: { "margin-right": "-35px", width: "103vw" },
                },
                [
                  _c(
                    "button",
                    { staticClass: "header__back", on: { click: _vm.close } },
                    [
                      _c(
                        "span",
                        { staticClass: "header-company" },
                        [
                          _c("svg-icon", {
                            staticClass: "ic-white",
                            attrs: {
                              name: "ic-arrow-left",
                              width: "16",
                              height: "12",
                            },
                          }),
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Back")) },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _c("div", {
                staticClass: "h2 mb0",
                domProps: {
                  textContent: _vm._s(
                    _vm.he ? _vm.popupInEdit.title : _vm.popupInEdit.title_en
                  ),
                },
              }),
              _c("div", { staticClass: "btn-active mie12" }, [
                _c(
                  "label",
                  [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.$t("Active")) },
                    }),
                    _c("swich-button", {
                      attrs: {
                        disabled: !(
                          _vm.checkPermissionPath("system_notices.active") ||
                          _vm.checkPermissionPath("system_notices.full_access")
                        ),
                      },
                      model: {
                        value: _vm.popupInEdit.active,
                        callback: function ($$v) {
                          _vm.$set(_vm.popupInEdit, "active", $$v)
                        },
                        expression: "popupInEdit.active",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "main-settings__imgs" }, [
              !_vm.popupInEdit.type
                ? _c("div", { staticClass: "main-settings__img-item" }, [
                    _c("div", {
                      staticClass: "main-settings__label",
                      domProps: {
                        textContent: _vm._s(_vm.$t("Desktop image")),
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "edit-logo",
                        class: {
                          "edit-img__content_empty": !_vm.image_desktopToShow,
                        },
                        style: {
                          backgroundImage:
                            "url(" + _vm.image_desktopToShow + ")",
                        },
                      },
                      [
                        _vm.checkPermissionPath("system_notices.full_access")
                          ? _c(
                              "label",
                              { staticClass: "edit-logo__icon" },
                              [
                                _vm.image_desktopToShow
                                  ? _c(
                                      "button",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeImage(
                                              "image_desktop"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            name: "ic-plus",
                                            width: "22",
                                            height: "22",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : [
                                      _c("input", {
                                        attrs: {
                                          type: "file",
                                          name: "logo",
                                          accept: "image/*",
                                        },
                                        on: { change: _vm.enterPhotodesktop },
                                      }),
                                      _c("svg-icon", {
                                        attrs: {
                                          name: "ic-photo",
                                          width: "22",
                                          height: "22",
                                        },
                                      }),
                                    ],
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    ),
                  ])
                : _vm._e(),
              !_vm.popupInEdit.type
                ? _c("div", { staticClass: "main-settings__img-item" }, [
                    _c("div", {
                      staticClass: "main-settings__label",
                      domProps: { textContent: _vm._s(_vm.$t("Mobile image")) },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "edit-logo",
                        class: {
                          "edit-img__content_empty": !_vm.image_mobileToShow,
                        },
                        style: {
                          backgroundImage:
                            "url(" + _vm.image_mobileToShow + ")",
                        },
                      },
                      [
                        _vm.checkPermissionPath("system_notices.full_access")
                          ? _c(
                              "label",
                              { staticClass: "edit-logo__icon" },
                              [
                                _vm.image_mobileToShow
                                  ? _c(
                                      "button",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeImage(
                                              "image_mobile"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            name: "ic-plus",
                                            width: "22",
                                            height: "22",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : [
                                      _c("input", {
                                        attrs: {
                                          type: "file",
                                          name: "logo",
                                          accept: "image/*",
                                        },
                                        on: { change: _vm.enterPhotoMobail },
                                      }),
                                      _c("svg-icon", {
                                        attrs: {
                                          name: "ic-photo",
                                          width: "22",
                                          height: "22",
                                        },
                                      }),
                                    ],
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "btns",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass:
                  "form-site__btn form-site__btn_sb d-md-flex d-none",
              },
              [
                _vm.popupInEdit.id
                  ? _c("remove-btn", { on: { click: _vm.remove } })
                  : _c("div", {}),
                _c("button", {
                  staticClass: "btn",
                  attrs: { type: "submit" },
                  domProps: { textContent: _vm._s(_vm.$t("Save")) },
                  on: { click: _vm.save },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
              _c(
                "button",
                { staticClass: "save-btn", on: { click: _vm.save } },
                [
                  _c("svg-icon", {
                    staticClass: "ic-edit",
                    attrs: { name: "ic-save-btn", width: "93", height: "93" },
                  }),
                ],
                1
              ),
              _c(
                "button",
                { staticClass: "bin-btn", on: { click: _vm.remove } },
                [
                  _c("svg-icon", {
                    staticClass: "icon ic-bin",
                    attrs: { name: "ic-bin", width: "18", height: "20" },
                  }),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }