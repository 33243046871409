var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    { attrs: { width: "700px" }, on: { close: _vm.close } },
    [
      _c("div", { staticClass: "popup__content" }, [
        _c("div", { staticClass: "gift-card" }, [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "coupon-statistic" }, [
                _c("div", { staticClass: "form-site__content" }, [
                  _c(
                    "div",
                    {
                      staticClass: "form-site__group form-site__group_mob-full",
                    },
                    [
                      _c("div", { staticClass: "form-site__field" }, [
                        _c("div", {
                          staticClass: "form-site__label",
                          domProps: {
                            textContent: _vm._s(_vm.$t("Client name")),
                          },
                        }),
                        _c("div", { staticClass: "form-site__input" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.compensationCopy.client_name,
                                expression: "compensationCopy.client_name",
                              },
                            ],
                            staticClass: "pis24 w100",
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.compensationCopy.client_name,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.compensationCopy,
                                  "client_name",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-site__field" }, [
                        _c("div", {
                          staticClass: "form-site__label",
                          domProps: {
                            textContent: _vm._s(_vm.$t("Client phone")),
                          },
                        }),
                        _c("div", { staticClass: "form-site__input" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.compensationCopy.client_phone,
                                expression: "compensationCopy.client_phone",
                              },
                            ],
                            staticClass: "pis24 w100",
                            attrs: { type: "tel" },
                            domProps: {
                              value: _vm.compensationCopy.client_phone,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.compensationCopy,
                                  "client_phone",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-site__field" }, [
                        _c("div", {
                          staticClass: "form-site__label",
                          domProps: {
                            textContent: _vm._s(_vm.$t("Buyer phone")),
                          },
                        }),
                        _c("div", { staticClass: "form-site__input" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.compensationCopy.buyer_phone,
                                expression: "compensationCopy.buyer_phone",
                              },
                            ],
                            staticClass: "pis24 w100",
                            attrs: { type: "tel" },
                            domProps: {
                              value: _vm.compensationCopy.buyer_phone,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.compensationCopy,
                                  "buyer_phone",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-site__field" }, [
                        _c("div", {
                          staticClass: "form-site__label",
                          domProps: { textContent: _vm._s(_vm.$t("Amount")) },
                        }),
                        _c("div", { staticClass: "form-site__input" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.compensationCopy.amount,
                                expression: "compensationCopy.amount",
                              },
                            ],
                            staticClass: "pis24 w100",
                            attrs: { type: "number" },
                            domProps: { value: _vm.compensationCopy.amount },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.compensationCopy,
                                  "amount",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-site__field" },
                        [
                          _c("div", {
                            staticClass: "form-site__label",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "Branch name for realization compensation"
                                )
                              ),
                            },
                          }),
                          _vm.compensationCopy.status == -1
                            ? _c("select-box", {
                                key: _vm.compensationCopy.branch_id,
                                staticClass: "selectBranch",
                                attrs: {
                                  placeholder: _vm.$t("select"),
                                  width: "100%",
                                  widthHeader: "100%",
                                  unsort: "",
                                  showSearch: "",
                                  options: _vm.branches,
                                },
                                on: { input: _vm.input },
                                model: {
                                  value: _vm.compensationCopy.branch_id,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.compensationCopy,
                                      "branch_id",
                                      $$v
                                    )
                                  },
                                  expression: "compensationCopy.branch_id",
                                },
                              })
                            : _vm._e(),
                          _vm.compensationCopy.status != -1
                            ? _c("div", {
                                staticClass: "select__head",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$store.getters.branch.name
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "form-site__field" }, [
                        _vm.compensationCopy.status != -1
                          ? _c("div", {
                              staticClass: "form-site__label",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Reason")),
                              },
                            })
                          : _vm._e(),
                        _vm.compensationCopy.status != -1 &&
                        _vm.compensationCopy.reason !== -1 &&
                        _vm.compensationCopy.reason !== null &&
                        _vm.compensationCopy.reason.length > 0
                          ? _c("div", {
                              staticClass: "select__head",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.compensationCopy.reason
                                ),
                              },
                            })
                          : _vm._e(),
                        _vm.compensationCopy.status != -1 &&
                        (_vm.compensationCopy.reason == null ||
                          _vm.compensationCopy.reason.length === 0)
                          ? _c("div", {
                              staticClass: "select__head",
                              domProps: {
                                textContent: _vm._s(_vm.$t("has no reason")),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "wrap-compensation-status",
                    style: { "margin-top": "80px", display: "grid" },
                  },
                  [
                    _c("div", {
                      staticClass: "compensation-status",
                      class: [
                        "status-pending",
                        "status-active",
                        "status-redeemed",
                        "status-rejected",
                      ][_vm.compensationCopy.status],
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            [
                              "Pending approval",
                              "Active",
                              "Redeemed",
                              "Rejected",
                            ][_vm.compensationCopy.status]
                          )
                        ),
                      },
                    }),
                  ]
                ),
                !_vm.mobile
                  ? _c(
                      "div",
                      {
                        staticClass: "btns",
                        style: {
                          display: "flex",
                          "justify-content": "space-around",
                          width: "100%",
                        },
                      },
                      [
                        _vm.compensation.status !== 2 &&
                        _vm.compensation.status !== -1
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn_border",
                                style: {
                                  "margin-top": "50px",
                                  "margin-bottom": "-90px",
                                },
                                on: { click: _vm.deleteCompensation },
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Delete")),
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm.compensation.status === 1
                          ? _c(
                              "button",
                              {
                                staticClass: "btn submit-btn",
                                style: {
                                  "margin-top": "50px",
                                  "margin-bottom": "-90px",
                                },
                                on: { click: _vm.reedemCompensation },
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Reedem")),
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _vm.mobile
                  ? _c(
                      "div",
                      {
                        staticClass: "btns",
                        style: {
                          display: "flex",
                          "justify-content": "space-around",
                          width: "100%",
                        },
                      },
                      [
                        _vm.compensation.status !== 2 &&
                        _vm.compensation.status !== -1
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn_border",
                                style: { "margin-top": "50px" },
                                on: { click: _vm.deleteCompensation },
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Delete")),
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm.compensation.status === 1
                          ? _c(
                              "button",
                              {
                                staticClass: "btn submit-btn",
                                style: { "margin-top": "50px" },
                                on: { click: _vm.reedemCompensation },
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Reedem")),
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _vm.compensationCopy.status === -1 &&
                _vm.compensationCopy.reason === -1
                  ? _c("span", {
                      style: {
                        color: "#d60b52",
                        display: "flex",
                        "justify-content": "center",
                        "margin-top": "50px",
                        "font-weight": "bold",
                      },
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("Please select reason for compensation")
                        ),
                      },
                    })
                  : _vm._e(),
                _c("div", { staticClass: "form-site" }, [
                  _c(
                    "div",
                    {
                      staticClass: "form-site__btn flex md-none",
                      style: {
                        display: "flex",
                        "justify-content": "center",
                        "margin-bottom": "90px",
                      },
                    },
                    [
                      _vm.compensationCopy.id && !_vm.compensationCopy.status
                        ? _c("button", {
                            staticClass: "btn-icon btn-icon_light-red",
                            on: { click: _vm.cancel },
                          })
                        : _c("div"),
                      _vm.compensationCopy.status == -1
                        ? _c("button", {
                            staticClass: "btn btn-save",
                            attrs: { type: "submit" },
                            domProps: { textContent: _vm._s(_vm.$t("Save")) },
                            on: { click: _vm.save },
                          })
                        : _vm._e(),
                    ]
                  ),
                ]),
                _vm.compensationCopy.status === -1
                  ? _c(
                      "div",
                      { staticClass: "new-item__btn d-flex d-md-none" },
                      [
                        _c(
                          "button",
                          { staticClass: "save-btn", on: { click: _vm.save } },
                          [
                            _c("svg-icon", {
                              staticClass: "ic-edit",
                              attrs: {
                                name: "ic-save-btn",
                                width: "93",
                                height: "93",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]
          ),
        ]),
      ]),
      _vm.toReedem
        ? _c("reedemCompensationDialog", {
            attrs: { show: true, compensation: _vm.compensation },
            on: {
              close: function ($event) {
                return _vm.$emit("closeload")
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }