var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      staticClass: "history-items-popup",
      attrs: { width: "700px" },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "popup__content" },
        [
          _c("history-order-item", {
            attrs: {
              inorder: "",
              inPopup: "",
              order: _vm.order,
              "show-order-info": false,
            },
          }),
          _c("div", { staticClass: "history-list-result" }, [
            _c("div", {
              staticClass: "history-list-result__title",
              domProps: { textContent: _vm._s(_vm.$t("Total")) },
            }),
            !_vm.mobile &&
            _vm.he &&
            _vm.order.sum < 0 &&
            _vm.restaurantCurrencyInfo == null
              ? _c("div", {
                  staticClass: "history-list-result__val",
                  domProps: {
                    textContent: _vm._s("₪" + _vm.order.sum * -1 + "-"),
                  },
                })
              : _vm._e(),
            !_vm.mobile &&
            _vm.he &&
            _vm.order.sum >= 0 &&
            _vm.restaurantCurrencyInfo == null
              ? _c("div", {
                  staticClass: "history-list-result__val",
                  domProps: { textContent: _vm._s("₪" + _vm.order.sum) },
                })
              : _vm._e(),
            !_vm.mobile &&
            !_vm.he &&
            _vm.order.sum < 0 &&
            _vm.restaurantCurrencyInfo == null
              ? _c("div", {
                  staticClass: "history-list-result__val",
                  domProps: { textContent: _vm._s("-₪" + _vm.order.sum * -1) },
                })
              : _vm._e(),
            !_vm.mobile &&
            !_vm.he &&
            _vm.order.sum > 0 &&
            _vm.restaurantCurrencyInfo == null
              ? _c("div", {
                  staticClass: "history-list-result__val",
                  domProps: { textContent: _vm._s("₪" + _vm.order.sum) },
                })
              : _vm._e(),
            !_vm.mobile &&
            _vm.he &&
            _vm.order.sum < 0 &&
            _vm.restaurantCurrencyInfo !== null
              ? _c("div", {
                  staticClass: "history-list-result__val",
                  domProps: {
                    textContent: _vm._s(
                      _vm.restaurantCurrencySymbol + _vm.order.sum * -1 + "-"
                    ),
                  },
                })
              : _vm._e(),
            !_vm.mobile &&
            _vm.he &&
            _vm.order.sum >= 0 &&
            _vm.restaurantCurrencyInfo !== null
              ? _c("div", {
                  staticClass: "history-list-result__val",
                  domProps: {
                    textContent: _vm._s(
                      _vm.restaurantCurrencySymbol + _vm.order.sum
                    ),
                  },
                })
              : _vm._e(),
            !_vm.mobile &&
            !_vm.he &&
            _vm.order.sum < 0 &&
            _vm.restaurantCurrencyInfo !== null
              ? _c("div", {
                  staticClass: "history-list-result__val",
                  domProps: {
                    textContent: _vm._s(
                      "-" + _vm.restaurantCurrencySymbol + _vm.order.sum * -1
                    ),
                  },
                })
              : _vm._e(),
            !_vm.mobile &&
            !_vm.he &&
            _vm.order.sum > 0 &&
            _vm.restaurantCurrencyInfo !== null
              ? _c("div", {
                  staticClass: "history-list-result__val",
                  domProps: {
                    textContent: _vm._s(
                      _vm.restaurantCurrencySymbol + _vm.order.sum
                    ),
                  },
                })
              : _vm._e(),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }