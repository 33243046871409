var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _vm.chooseDocument
        ? _c(
            "div",
            { staticClass: "choose-document" },
            _vm._l(_vm.documents, function (document) {
              return _c("button", {
                key: document.document.id,
                staticClass: "documnet-to-choose",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      [
                        "Tax invoice",
                        "Receipt",
                        "Tax invoice receipt",
                        "Refund invoice",
                        "Proforma invoice",
                        "Delivery document",
                      ][document.document.document_type - 1]
                    ) +
                      " " +
                      _vm.$t("number") +
                      " " +
                      document.document.document_number
                  ),
                },
                on: {
                  click: function ($event) {
                    return _vm.setDocument(document)
                  },
                },
              })
            }),
            0
          )
        : _c("div", [
            _c("iframe", {
              attrs: { src: _vm.src, width: "560", height: "800" },
            }),
            _c("div", { staticClass: "form-site__btn" }, [
              _c(
                "button",
                {
                  staticClass: "btn-icon btn-icon_yellow",
                  on: { click: _vm.print },
                },
                [
                  _c(
                    "span",
                    { staticClass: "btn-icon__note" },
                    [
                      _c("svg-icon", {
                        attrs: {
                          name: "ic-print-non-circle",
                          width: "40",
                          height: "40",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("span", {
                    staticClass: "btn-icon__text flex a-center",
                    domProps: { textContent: _vm._s(_vm.$t("Printing")) },
                  }),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "btn-icon btn-icon_yellow",
                  on: { click: _vm.validateDocument },
                },
                [
                  _c(
                    "span",
                    { staticClass: "btn-icon__note" },
                    [
                      _c("svg-icon", {
                        attrs: {
                          name: "ic-document-valid",
                          width: "25",
                          height: "25",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("span", {
                    staticClass: "btn-icon__text flex a-center",
                    domProps: {
                      textContent: _vm._s(_vm.$t("Document validation")),
                    },
                  }),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "btn-icon btn-icon_orange",
                  on: { click: _vm.download },
                },
                [
                  _c(
                    "span",
                    { staticClass: "btn-icon__note" },
                    [
                      _c("svg-icon", {
                        attrs: {
                          name: "ic-download",
                          width: "20",
                          height: "20",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("span", {
                    staticClass: "btn-icon__text flex a-center",
                    domProps: { textContent: _vm._s(_vm.$t("Download")) },
                  }),
                ]
              ),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }