var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "phone-fields" }, [
    _c(
      "div",
      { staticClass: "phone-country-prefix form-site__field" },
      [
        _c("vue-country-code", {
          attrs: {
            enabledCountryCode: true,
            preferredCountries: ["IL", "FR", "US"],
          },
          on: { onSelect: _vm.onCountryCodeSelected },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "phone form-site__field" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.comPhone,
            expression: "comPhone",
          },
        ],
        ref: "phone-input",
        attrs: {
          type: "tel",
          placeholder: _vm.$t("Phone"),
          required: _vm.required,
        },
        domProps: { value: _vm.comPhone },
        on: {
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.$emit("enter")
          },
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.comPhone = $event.target.value
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }