var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kds-tab", staticStyle: { height: "400px" } },
    [
      _c(
        "div",
        { staticClass: "new-item-info" },
        [
          _vm.colorPickerOpen
            ? _c("color-picker", {
                staticClass: "color-picker",
                class: { collapse: !_vm.colorPickerOpen },
                staticStyle: {
                  position: "absolute",
                  left: "230px",
                  "z-index": "1",
                },
                attrs: { gradient: false },
                on: { close: _vm.closeColorPicker },
                model: {
                  value:
                    _vm.kdsSettings[["text_color", "border_color"][_vm.index]],
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.kdsSettings,
                      ["text_color", "border_color"][_vm.index],
                      $$v
                    )
                  },
                  expression:
                    "kdsSettings[['text_color', 'border_color'][index]]",
                },
              })
            : _vm._e(),
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "form-site" }, [
                _c("br"),
                _c("label", {
                  staticClass: "kds-titles",
                  domProps: { textContent: _vm._s(_vm.$t("Durations")) },
                }),
                _c(
                  "div",
                  { staticClass: "times-row" },
                  _vm._l(["Preparation", "Make", "Package"], function (key, i) {
                    return _c("div", { key: i, staticClass: "times" }, [
                      _c("div", { staticClass: "time" }, [
                        _c("label", {
                          staticClass: "kds-second-titles",
                          domProps: { textContent: _vm._s(_vm.$t(key)) },
                        }),
                        _c("div", { staticClass: "duration-input" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.kdsSettings[
                                    [
                                      "preparation_duration",
                                      "make_duration",
                                      "pack_duration",
                                    ][i]
                                  ],
                                expression:
                                  "\n                    kdsSettings[\n                      ['preparation_duration', 'make_duration', 'pack_duration'][i]\n                    ]\n                  ",
                              },
                            ],
                            staticClass: "input-label row-label",
                            attrs: { type: "number", id: "appt", name: "appt" },
                            domProps: {
                              value:
                                _vm.kdsSettings[
                                  [
                                    "preparation_duration",
                                    "make_duration",
                                    "pack_duration",
                                  ][i]
                                ],
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.kdsSettings,
                                  [
                                    "preparation_duration",
                                    "make_duration",
                                    "pack_duration",
                                  ][i],
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _c("span", {
                            staticStyle: { "margin-inline-start": "8px" },
                            domProps: {
                              textContent: _vm._s(_vm.$t("Minutes")),
                            },
                          }),
                        ]),
                      ]),
                    ])
                  }),
                  0
                ),
                _c("br"),
                _c(
                  "div",
                  {
                    staticClass: "colors-div",
                    staticStyle: {
                      "justify-content": "space-around",
                      margin: "10px 0",
                    },
                  },
                  [
                    _c("label", {
                      staticClass: "kds-titles",
                      domProps: { textContent: _vm._s(_vm.$t("Colors")) },
                    }),
                    _c(
                      "div",
                      { staticClass: "kdss" },
                      _vm._l(["text_color", "border_color"], function (key, i) {
                        return _c(
                          "div",
                          {
                            key: "colors" + i,
                            staticClass: "times",
                            staticStyle: { margin: "0 20px" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "row",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "time",
                                    staticStyle: { margin: "10px" },
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "kds-second-titles",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t(_vm.capitalize(key))
                                        ),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "theme-color__content",
                                        staticStyle: { width: "110%" },
                                      },
                                      [
                                        _c("button", {
                                          staticClass: "theme-color__fill",
                                          style: {
                                            background:
                                              _vm.kdsSettings[
                                                ["text_color", "border_color"][
                                                  i
                                                ]
                                              ],
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.index = i
                                              _vm.colorPickerOpen =
                                                !_vm.colorPickerOpen
                                            },
                                          },
                                        }),
                                        _c("div", {
                                          staticClass: "theme-color__text",
                                          staticStyle: {
                                            display: "inline-block",
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.kdsSettings[
                                                ["text_color", "border_color"][
                                                  i
                                                ]
                                              ]
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-site__field flex a-center",
                                    staticStyle: {
                                      "align-self": "center",
                                      margin: "20px 20px 0",
                                    },
                                  },
                                  [
                                    _c("label", { staticClass: "flex" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.kdsSettings[
                                                ["bold", "border"][i]
                                              ],
                                            expression:
                                              "kdsSettings[['bold', 'border'][i]]",
                                          },
                                        ],
                                        key: i + "cb",
                                        staticClass: "kds-checkbox",
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.kdsSettings[
                                              ["bold", "border"][i]
                                            ]
                                          )
                                            ? _vm._i(
                                                _vm.kdsSettings[
                                                  ["bold", "border"][i]
                                                ],
                                                null
                                              ) > -1
                                            : _vm.kdsSettings[
                                                ["bold", "border"][i]
                                              ],
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.kdsSettings[
                                                  ["bold", "border"][i]
                                                ],
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.kdsSettings,
                                                    ["bold", "border"][i],
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.kdsSettings,
                                                    ["bold", "border"][i],
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.kdsSettings,
                                                ["bold", "border"][i],
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", {
                                        key: i + "span",
                                        staticClass: "input-check__text",
                                        domProps: {
                                          textContent: _vm._s(
                                            i == 0
                                              ? _vm.$t("Bold")
                                              : _vm.$t("Border")
                                          ),
                                        },
                                      }),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
                _vm.extras
                  ? _c("div", [
                      _c("label", {
                        staticClass: "kds-titles",
                        domProps: {
                          textContent: _vm._s(_vm.$t("Extras view type")),
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "times-row",
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between",
                            margin: "0 20px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "time",
                              staticStyle: {
                                margin: "10px",
                                display: "flex",
                                "flex-direction": "row",
                              },
                            },
                            [
                              _c("div", { staticClass: "input-radio" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.kdsSettings["extras_view"],
                                      expression: "kdsSettings['extras_view']",
                                    },
                                  ],
                                  key: "list-extra",
                                  attrs: {
                                    id: "radioDesktop",
                                    type: "radio",
                                    name: "radio",
                                  },
                                  domProps: {
                                    value: true,
                                    checked: _vm._q(
                                      _vm.kdsSettings["extras_view"],
                                      true
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.kdsSettings,
                                        "extras_view",
                                        true
                                      )
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "input-radio__label",
                                    class: { active: _vm.extrasView },
                                    attrs: { for: "radioDesktop" },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "input-radio__mark",
                                    }),
                                    _c("span", {
                                      staticClass: "input-radio__text",
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("List")),
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "input-radio",
                                  staticStyle: { margin: "0 30px" },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.extrasView,
                                        expression: "extrasView",
                                      },
                                    ],
                                    attrs: {
                                      id: "radioMobile",
                                      type: "radio",
                                      name: "radio",
                                    },
                                    domProps: {
                                      value: false,
                                      checked: _vm._q(_vm.extrasView, false),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.extrasView = false
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      key: "columns-extra",
                                      staticClass: "input-radio__label",
                                      class: { active: !_vm.extrasView },
                                      attrs: { for: "radioMobile" },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "input-radio__mark",
                                      }),
                                      _c("span", {
                                        staticClass: "input-radio__text",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("Columns")
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }