var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "base-dialog",
        { attrs: { width: "500px" }, on: { close: _vm.close } },
        [
          _c("div", { staticClass: "popup" }, [
            _c("div", { staticClass: "popup__top" }, [
              _c("img", {
                staticClass: "popup__bg-top d-md-none",
                attrs: { src: "/assets/img/bg-mobile-head.svg", alt: "" },
              }),
              _c("div", { staticClass: "popup__login d-md-none" }, [
                _c("img", {
                  attrs: { src: "/assets/img/Atmos-logo-white.svg", alt: "" },
                }),
              ]),
              _c("div", { staticClass: "popup__head" }, [
                _c("div", {
                  staticClass: "h1",
                  domProps: {
                    textContent: _vm._s(_vm.$t("Enter verification code")),
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "popup__content" }, [
              _c(
                "form",
                {
                  ref: "form",
                  staticClass: "sms-form-wrap",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.login.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "sms-form" }, [
                    _c("div", { staticClass: "sms-form__content" }, [
                      _c(
                        "div",
                        { staticClass: "sms-form__field" },
                        [
                          _c("code-input", {
                            on: { change: _vm.codeChanged, end: _vm.endWrite },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "sms-form__btn" }, [
                        _c("button", {
                          staticClass: "btn",
                          attrs: { type: "submit" },
                          domProps: { textContent: _vm._s(_vm.$t("Continue")) },
                        }),
                        _c("div", [
                          _c("button", {
                            staticClass: "sms-form__btn-label",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("Didn't got the code?")
                              ),
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.resendCode.apply(null, arguments)
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }