var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "select-box",
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c(
        "header",
        _vm._b(
          { ref: "header", on: { click: _vm.toggleSelect } },
          "header",
          _vm.bindHeader,
          false
        ),
        [
          _vm.autocomplete
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isOpened,
                      expression: "isOpened",
                    },
                  ],
                  staticClass: "autocomplete",
                },
                [
                  _c("filter-by", {
                    attrs: {
                      styles: {
                        width: "calc(" + _vm.width + " - 57px)",
                        maxWidth: _vm.maxWidth,
                      },
                      isSelectOpen: _vm.isOpened,
                      filterBy: _vm.filterBy,
                    },
                    on: { emitFilter: _vm.setFilter },
                  }),
                ],
                1
              )
            : _vm._e(),
          !_vm.autocomplete || !_vm.isOpened
            ? _c(
                "section",
                { staticClass: "flex col j-start a-start p0" },
                [
                  _c(
                    "div",
                    { staticClass: "flex a-center" },
                    [
                      _vm._t("logo"),
                      _vm.translate && _vm.value === 9
                        ? _c("span", {
                            staticClass: "trim",
                            style: { color: _vm.currColor },
                            domProps: {
                              textContent: _vm._s(
                                _vm.viewValue
                                  ? _vm.value
                                  : _vm.$t(_vm.valueLabel)
                              ),
                            },
                          })
                        : _c("span", {
                            staticClass: "trim",
                            style: { color: _vm.currColor },
                            domProps: {
                              textContent: _vm._s(
                                _vm.viewValue
                                  ? _vm.value
                                  : _vm.$t(_vm.valueLabel)
                              ),
                            },
                          }),
                      _vm.viewId &&
                      (typeof _vm.value === "string" ||
                        typeof _vm.value === "number")
                        ? _c("span", {
                            style:
                              ((_obj = { color: _vm.currColor, opacity: 0.5 }),
                              (_obj["padding-inline-start"] = "10px"),
                              _obj),
                            domProps: { textContent: _vm._s(_vm.value) },
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._t("time-ago"),
                ],
                2
              )
            : _vm._e(),
          _vm.iconListHeader
            ? _c(
                "icon-list",
                _vm._b({}, "icon-list", _vm.iconListHeader, false)
              )
            : _vm._e(),
          _c("svg-icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.autocomplete,
                expression: "!autocomplete",
              },
            ],
            staticClass: "m-i-start-1ch",
            class: { isOpened: _vm.isOpened },
            attrs: {
              fill: _vm.currColor,
              name: "ic-arrow-down",
              width: "14",
              height: "9",
            },
          }),
        ],
        1
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.disabled && _vm.isOpened,
                expression: "!disabled && isOpened",
              },
            ],
            staticClass: "option-container",
            class: {
              "fit-content": _vm.isFit,
              openToUp: _vm.openToUp,
              noneMaxHeight: _vm.noneMaxHeight,
            },
            style: _vm.ulStyle,
          },
          [
            _vm.showSearch && _vm.isOpened
              ? _c(
                  "li",
                  { staticClass: "filter-by-container" },
                  [
                    _c("filter-by", {
                      attrs: {
                        styles: {
                          width: "calc(" + _vm.width + " - 57px)",
                          maxWidth: "calc(" + _vm.maxWidth + " - 80px)",
                        },
                        isSelectOpen: _vm.isOpened,
                        filterBy: _vm.filterBy,
                      },
                      on: { emitFilter: _vm.setFilter },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showPlaceholder &&
            (_vm.placeholder.includes(_vm.filterBy.term) ||
              _vm.placeholder.includes(_vm.filterBy.conversionTerm))
              ? _c(
                  "li",
                  {
                    staticClass: "flex a-center",
                    on: {
                      click: function () {
                        _vm.value.splice(0)
                      },
                    },
                  },
                  [
                    _vm.isArray
                      ? _c(
                          "checkbox2",
                          {
                            attrs: {
                              isActive: !_vm.value.length,
                              required: _vm.required,
                            },
                          },
                          [
                            _c("span", {
                              class: { red: !_vm.value.length },
                              domProps: {
                                textContent: _vm._s(_vm.placeholder),
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._l(_vm.optionsForShow, function (option, optionIndex) {
              return _c(
                "li",
                {
                  key: optionIndex,
                  ref: "focus",
                  refInFor: true,
                  staticClass: "flex a-center",
                  class: {
                    active: _vm.optionSelectedIdxs.includes(optionIndex),
                    focus: _vm.optionFocusedIdx === optionIndex,
                    disable: option.disable,
                  },
                  on: {
                    click: function () {
                      if (!option.disable) {
                        _vm.select(option)
                      }
                    },
                  },
                },
                [
                  option.label && option.sub
                    ? _c("div", { staticClass: "sub-item" })
                    : _vm._e(),
                  _vm.isArray
                    ? _c(
                        "checkbox2",
                        {
                          staticClass: "flex a-center select-box-option",
                          attrs: {
                            isActive:
                              _vm.optionSelectedIdxs.includes(optionIndex),
                            required: _vm.required,
                          },
                        },
                        [
                          _c("section", [
                            _vm.isImage(option.img)
                              ? _c("img", {
                                  attrs: { src: option.img, alt: "" },
                                })
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                class: {
                                  selected:
                                    _vm.optionSelectedIdxs.includes(
                                      optionIndex
                                    ),
                                },
                              },
                              [
                                _vm.translate
                                  ? _c("span", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t(
                                            option.label ||
                                              (typeof option.label === "string"
                                                ? ""
                                                : option)
                                          )
                                        ),
                                      },
                                    })
                                  : _c("span", {
                                      domProps: {
                                        textContent: _vm._s(
                                          option.label ||
                                            (typeof option.label === "string"
                                              ? ""
                                              : option)
                                        ),
                                      },
                                    }),
                                _vm.showVal
                                  ? _c("span", {
                                      staticClass: "id",
                                      domProps: {
                                        textContent: _vm._s(
                                          (option.value2 &&
                                          option.value2 !== option.value
                                            ? option.value2 + " - "
                                            : "") + (option.value || "")
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ]
                      )
                    : _c(
                        "section",
                        { staticClass: "select-box-option flex a-center bet" },
                        [
                          _c("section", [
                            _vm.isImage(option.img)
                              ? _c("img", {
                                  attrs: { src: option.img, alt: "" },
                                })
                              : _vm._e(),
                            _vm.translate
                              ? _c("span", {
                                  class: {
                                    selected:
                                      _vm.optionSelectedIdxs.includes(
                                        optionIndex
                                      ),
                                  },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        option.label ||
                                          (typeof option.label === "string"
                                            ? ""
                                            : option)
                                      )
                                    ),
                                  },
                                })
                              : _c("span", {
                                  class: {
                                    selected:
                                      _vm.optionSelectedIdxs.includes(
                                        optionIndex
                                      ),
                                  },
                                  domProps: {
                                    textContent: _vm._s(
                                      option.label ||
                                        (typeof option.label === "string"
                                          ? ""
                                          : option)
                                    ),
                                  },
                                }),
                            _vm.showVal
                              ? _c("span", {
                                  staticClass: "id",
                                  domProps: {
                                    textContent: _vm._s(
                                      (option.value2 &&
                                      option.value2 !== option.value
                                        ? option.value2 + " - "
                                        : "") + (option.value || "")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                          option.emergency
                            ? _c("icon-list", {
                                staticClass: "flex j-end",
                                attrs: {
                                  emergency: option.emergency,
                                  branch_order_settings:
                                    option.branch_order_settings,
                                  isCredit: option.isCredit,
                                },
                              })
                            : _vm._e(),
                          _vm.optionSelectedIdxs.includes(optionIndex)
                            ? _c("svg-icon", {
                                staticClass: "m-i-end-1ch second-fill",
                                class: {
                                  "order-start": option.branch_order_settings,
                                },
                                attrs: {
                                  name: "ic-check",
                                  width: "21",
                                  height: "16",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                ],
                1
              )
            }),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }