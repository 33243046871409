var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-dialog", { on: { close: _vm.close } }, [
    _c("div", { staticClass: "popup__top" }, [
      _c("div", { staticClass: "popup__head flex a-center bet" }, [
        _vm.posCopy.device_type === 2
          ? _c("div", {
              staticClass: "h1 mis0 mt20",
              domProps: { textContent: _vm._s(_vm.$t("Kiosk settings")) },
            })
          : _c("div", {
              staticClass: "h1 mis0 mt20",
              domProps: { textContent: _vm._s(_vm.$t("POS settings")) },
            }),
        _c(
          "div",
          { staticClass: "mb20" },
          [
            _c("div", {
              staticClass: "form-site__label",
              domProps: { textContent: _vm._s(_vm.$t("Active")) },
            }),
            _c("swich-button", {
              model: {
                value: _vm.posCopy.active,
                callback: function ($$v) {
                  _vm.$set(_vm.posCopy, "active", $$v)
                },
                expression: "posCopy.active",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "popup__content" }, [
      _c("div", { staticClass: "form-new-user" }, [
        _c(
          "form",
          {
            staticClass: "form-site-wrap",
            on: {
              submit: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c("div", { staticClass: "form-site" }, [
              _c("div", { staticClass: "form-site__content" }, [
                _c("div", { staticClass: "form-site__field-wrap" }, [
                  _c("div", { staticClass: "form-site__field" }, [
                    _c("div", {
                      staticClass: "form-site__label",
                      domProps: { textContent: _vm._s(_vm.$t("POS number")) },
                    }),
                    _c("div", { staticClass: "form-site__input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.posCopy.id,
                            expression: "posCopy.id",
                          },
                        ],
                        attrs: { disabled: "", type: "text" },
                        domProps: { value: _vm.posCopy.id },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.posCopy, "id", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-site__field" }, [
                    _c("div", {
                      staticClass: "form-site__label",
                      domProps: { textContent: _vm._s(_vm.$t("POS name")) },
                    }),
                    _c("div", { staticClass: "form-site__input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.posCopy.name,
                            expression: "posCopy.name",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.posCopy.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.posCopy, "name", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "w100 flex j-center" }, [
                  _c("button", {
                    staticClass: "btn btn_border",
                    domProps: { textContent: _vm._s(_vm.$t("Get new key")) },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.newKey.apply(null, arguments)
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _vm.posCopy.device_type === 2
              ? _c("div", { staticClass: "kiosk-actions" }, [
                  _c("div", {
                    staticClass: "kiosk-actions__title",
                    domProps: { textContent: _vm._s(_vm.$t("Kiosk actions")) },
                  }),
                  _c("div", { staticClass: "kiosk-actions__content" }, [
                    _c("div", { staticClass: "kiosk-actions__item" }, [
                      _c("div", { staticClass: "kiosk-actions__item_btn" }, [
                        _c("button", {
                          staticClass: "btn btn_border",
                          attrs: { type: "button" },
                          domProps: { textContent: _vm._s(_vm.$t("Restart")) },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.action("restart_pos")
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "kiosk-actions__item" }, [
                      _c("div", { staticClass: "kiosk-actions__item_btn" }, [
                        _c("button", {
                          staticClass: "btn btn_border",
                          attrs: { type: "button" },
                          domProps: { textContent: _vm._s(_vm.$t("Stop")) },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.action("stop_pos")
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "kiosk-actions__item" }, [
                      _c("div", { staticClass: "kiosk-actions__item_btn" }, [
                        _c("button", {
                          staticClass: "btn btn_border",
                          attrs: { type: "button" },
                          domProps: { textContent: _vm._s(_vm.$t("Start")) },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.action("start_pos")
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "kiosk-actions__item" }, [
                      _c("div", { staticClass: "kiosk-actions__item_btn" }, [
                        _c("button", {
                          staticClass: "btn btn_border",
                          attrs: { type: "button" },
                          domProps: { textContent: _vm._s(_vm.$t("Update")) },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.action("update_pos")
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "kiosk-actions__item" }, [
                      _c("div", { staticClass: "kiosk-actions__item_btn" }, [
                        _c("button", {
                          staticClass: "btn btn_border",
                          attrs: { type: "button" },
                          domProps: { textContent: _vm._s(_vm.$t("Lock")) },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.action("lock_screen", "kiosk")
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "kiosk-actions__item" }, [
                      _c("div", { staticClass: "kiosk-actions__item_btn" }, [
                        _c("button", {
                          staticClass: "btn btn_border",
                          attrs: { type: "button" },
                          domProps: { textContent: _vm._s(_vm.$t("Unlock")) },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.action("unlock_screen", "kiosk")
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "form-site__btn md-none pb0 pt20" },
              [
                _c("remove-btn", {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("removePos")
                    },
                  },
                }),
                _c("button", {
                  staticClass: "btn",
                  domProps: { textContent: _vm._s(_vm.$t("Save")) },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
              _c(
                "button",
                { staticClass: "save-btn", attrs: { type: "submit" } },
                [
                  _c("svg-icon", {
                    staticClass: "ic-edit",
                    attrs: { name: "ic-save-btn", width: "93", height: "93" },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _c("div", { staticClass: "flex j-center w100 mt20" }, [
          _c("div", { staticClass: "form-site__field" }, [
            _c("div", {
              staticClass: "form-site__label",
              domProps: { textContent: _vm._s(_vm.$t("Created at")) },
            }),
            _c("div", { staticClass: "form-site__input" }, [
              _vm._v(
                "\r\n                        " +
                  _vm._s(
                    _vm.filters.date(_vm.posCopy.created_at, "DD/MM/YYYY")
                  ) +
                  " " +
                  _vm._s(_vm.$t("at")) +
                  " " +
                  _vm._s(_vm.filters.time(_vm.posCopy.created_at)) +
                  "\r\n                    "
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }