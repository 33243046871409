var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "extras-arrangement-dialog" },
    [
      _c(
        "base-dialog",
        { attrs: { title: "Extras Arrangement" }, on: { close: _vm.close } },
        [
          _c("div", { staticClass: "popup-main" }, [
            _c("div", { staticClass: "popup-content" }, [
              _c(
                "div",
                { staticClass: "popup-content__list" },
                [
                  _c(
                    "draggable",
                    {
                      attrs: { handle: ".btn-handle" },
                      model: {
                        value: _vm.extras,
                        callback: function ($$v) {
                          _vm.extras = $$v
                        },
                        expression: "extras",
                      },
                    },
                    _vm._l(_vm.extras, function (extra, extraIndex) {
                      return _c(
                        "div",
                        { key: extraIndex, staticClass: "popup-content__item" },
                        [
                          _c(
                            "div",
                            { staticClass: "popup-content__item__info" },
                            [
                              _c("input", {
                                staticClass: "index-change-input",
                                attrs: { type: "number" },
                                domProps: { value: extraIndex },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeIndex(extraIndex, $event)
                                  },
                                },
                              }),
                              _c("span", {
                                staticClass: "popup-content__item__title",
                                domProps: { textContent: _vm._s(extra.name) },
                              }),
                              _c("span", [
                                _vm._v(" - ("),
                                _c("span", {
                                  staticClass: "popup-content__item__makat",
                                  domProps: {
                                    textContent: _vm._s(extra.makat),
                                  },
                                }),
                                _vm._v(")"),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "popup-content__item__controls" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn-move btn-up",
                                  on: {
                                    click: function ($event) {
                                      return _vm.moveUp(extraIndex)
                                    },
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      name: "ic-arrow-up",
                                      width: "16px",
                                      height: "16px",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-move btn-down",
                                  on: {
                                    click: function ($event) {
                                      return _vm.moveDown(extraIndex)
                                    },
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      name: "ic-arrow-down",
                                      width: "16px",
                                      height: "16px",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "button",
                                { staticClass: "btn-handle" },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      name: "ic-double-more",
                                      width: "16px",
                                      height: "16px",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "popup-btn" }, [
            _c("button", { staticClass: "btn", on: { click: _vm.save } }, [
              _vm._v(_vm._s(_vm.$t("Save"))),
            ]),
            _c(
              "button",
              { staticClass: "btn btn-outline", on: { click: _vm.close } },
              [_vm._v(_vm._s(_vm.$t("Close")))]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }