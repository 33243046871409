var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "new-item__info" }, [
    _c(
      "div",
      { staticClass: "swich-block" },
      [
        _c("div", { staticClass: "swich-block__info" }, [
          _c("div", {
            staticClass: "swich-block__title",
            domProps: { textContent: _vm._s(_vm.$t("Active")) },
          }),
        ]),
        _c("swich-button", {
          attrs: { disabled: !_vm.allBranchesPermissions },
          model: {
            value: _vm.itemCopy.active,
            callback: function ($$v) {
              _vm.$set(_vm.itemCopy, "active", $$v)
            },
            expression: "itemCopy.active",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "swich-block" },
      [
        _c("div", { staticClass: "swich-block__info" }, [
          _c("div", {
            staticClass: "swich-block__title",
            domProps: {
              textContent: _vm._s(_vm.$t("catalog-editor-edit-item Popup")),
            },
          }),
        ]),
        _c("swich-button", {
          attrs: { disabled: !!_vm.mostNewPopup },
          model: {
            value: _vm.itemCopy.popup,
            callback: function ($$v) {
              _vm.$set(_vm.itemCopy, "popup", $$v)
            },
            expression: "itemCopy.popup",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "swich-block" },
      [
        _c("div", { staticClass: "swich-block__info" }, [
          _c("div", {
            staticClass: "swich-block__title",
            domProps: { textContent: _vm._s(_vm.$t("combine")) },
          }),
        ]),
        _c("swich-button", {
          attrs: { disabled: !_vm.allBranchesPermissions },
          model: {
            value: _vm.itemCopy.combine,
            callback: function ($$v) {
              _vm.$set(_vm.itemCopy, "combine", $$v)
            },
            expression: "itemCopy.combine",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "swich-block" },
      [
        _c("div", { staticClass: "swich-block__info" }, [
          _c("div", {
            staticClass: "swich-block__title",
            domProps: { textContent: _vm._s(_vm.$t("Require password")) },
          }),
        ]),
        _c("swich-button", {
          model: {
            value: _vm.itemCopy.admin_approval_required,
            callback: function ($$v) {
              _vm.$set(_vm.itemCopy, "admin_approval_required", $$v)
            },
            expression: "itemCopy.admin_approval_required",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "swich-block" },
      [
        _c("div", { staticClass: "swich-block__info" }, [
          _c("div", {
            staticClass: "swich-block__title",
            domProps: { textContent: _vm._s(_vm.$t("Client's comments")) },
          }),
        ]),
        _c("swich-button", {
          model: {
            value: _vm.itemCopy.clients_comments,
            callback: function ($$v) {
              _vm.$set(_vm.itemCopy, "clients_comments", $$v)
            },
            expression: "itemCopy.clients_comments",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "swich-block" },
      [
        _c("div", { staticClass: "swich-block__info" }, [
          _c("div", {
            staticClass: "swich-block__title",
            domProps: { textContent: _vm._s(_vm.$t("Require name")) },
          }),
        ]),
        _c("swich-button", {
          attrs: { disabled: !!_vm.mostrequireName },
          model: {
            value: _vm.itemCopy.require_name,
            callback: function ($$v) {
              _vm.$set(_vm.itemCopy, "require_name", $$v)
            },
            expression: "itemCopy.require_name",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "swich-block" },
      [
        _c("div", { staticClass: "swich-block__info" }, [
          _c("div", {
            staticClass: "swich-block__title",
            domProps: { textContent: _vm._s(_vm.$t("Collect points")) },
          }),
        ]),
        _c("swich-button", {
          model: {
            value: _vm.itemCopy.collect_points,
            callback: function ($$v) {
              _vm.$set(_vm.itemCopy, "collect_points", $$v)
            },
            expression: "itemCopy.collect_points",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "swich-block" },
      [
        _c("div", { staticClass: "swich-block__info" }, [
          _c("div", {
            staticClass: "swich-block__title",
            domProps: {
              textContent: _vm._s(_vm.$t("Participate in the discount")),
            },
          }),
        ]),
        _c("swich-button", {
          model: {
            value: _vm.itemCopy.calculate_discount_price,
            callback: function ($$v) {
              _vm.$set(_vm.itemCopy, "calculate_discount_price", $$v)
            },
            expression: "itemCopy.calculate_discount_price",
          },
        }),
      ],
      1
    ),
    _vm.itemCopy.item_type === 1
      ? _c(
          "div",
          { staticClass: "item-setting p0", class: { open: _vm.closedScaled } },
          [
            _c(
              "button",
              {
                staticClass: "item-setting__head flex bet w100",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.closedScaled = !_vm.closedScaled
                  },
                },
              },
              [
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticClass: "item-setting__icon" },
                    [
                      _c("svg-icon", {
                        attrs: {
                          name: "ic-settings",
                          width: "24",
                          height: "24",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", {
                    staticClass: "item-setting__title",
                    domProps: { textContent: _vm._s(_vm.$t("Scaled")) },
                  }),
                ]),
                _c(
                  "div",
                  { staticClass: "item-setting__arr" },
                  [
                    _c("svg-icon", {
                      attrs: {
                        name: "ic-arrow-down",
                        width: "14",
                        height: "9",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c("div", { staticClass: "item-setting__content" }, [
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "form-site" }, [
                    _c("div", { staticClass: "form-site__content" }, [
                      _c("div", {
                        staticClass: "form-site__group-title",
                        domProps: {
                          textContent: _vm._s(_vm.$t("Scaling settings")),
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "form-site__group" },
                        [
                          _c("form-site-field-text", {
                            attrs: {
                              title: "Minimum",
                              sign: _vm.$t("g"),
                              placeholder: "0",
                              type: "number",
                            },
                            model: {
                              value: _vm.itemCopy.minimum,
                              callback: function ($$v) {
                                _vm.$set(_vm.itemCopy, "minimum", $$v)
                              },
                              expression: "itemCopy.minimum",
                            },
                          }),
                          _c("form-site-field-text", {
                            attrs: {
                              title: "Maximum",
                              sign: _vm.$t("g"),
                              placeholder: "0",
                              type: "number",
                            },
                            model: {
                              value: _vm.itemCopy.maximum,
                              callback: function ($$v) {
                                _vm.$set(_vm.itemCopy, "maximum", $$v)
                              },
                              expression: "itemCopy.maximum",
                            },
                          }),
                          _c("form-site-field-text", {
                            attrs: {
                              title: "Steps",
                              sign: _vm.$t("g"),
                              placeholder: "0",
                              type: "number",
                            },
                            model: {
                              value: _vm.itemCopy.step,
                              callback: function ($$v) {
                                _vm.$set(_vm.itemCopy, "step", $$v)
                              },
                              expression: "itemCopy.step",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "form-site__field pt30 flex a-center",
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "flex" },
                                [
                                  _c("checkbox", {
                                    staticClass: "mie12",
                                    attrs: {
                                      trueValue: 1,
                                      falseValue: 0,
                                      name: "show-price",
                                    },
                                    model: {
                                      value: _vm.itemCopy.display_grams,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.itemCopy,
                                          "display_grams",
                                          $$v
                                        )
                                      },
                                      expression: "itemCopy.display_grams",
                                    },
                                  }),
                                  _c("div", {
                                    staticClass: "form-site__label",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Show price for 100 grams")
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("div", {
                        staticClass: "form-site__group-title",
                        domProps: {
                          textContent: _vm._s(_vm.$t("Units settings")),
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "form-site__group" },
                        [
                          _c("form-site-field-text", {
                            attrs: {
                              title: "Minimum",
                              placeholder: "0",
                              type: "number",
                            },
                            model: {
                              value: _vm.itemCopy.minimum_units,
                              callback: function ($$v) {
                                _vm.$set(_vm.itemCopy, "minimum_units", $$v)
                              },
                              expression: "itemCopy.minimum_units",
                            },
                          }),
                          _c("form-site-field-text", {
                            attrs: {
                              title: "Maximum",
                              placeholder: "0",
                              type: "number",
                            },
                            model: {
                              value: _vm.itemCopy.maximum_units,
                              callback: function ($$v) {
                                _vm.$set(_vm.itemCopy, "maximum_units", $$v)
                              },
                              expression: "itemCopy.maximum_units",
                            },
                          }),
                          _c("form-site-field-text", {
                            attrs: {
                              title: "Price",
                              placeholder: "0",
                              type: "number",
                            },
                            model: {
                              value: _vm.itemCopy.price_per_unit,
                              callback: function ($$v) {
                                _vm.$set(_vm.itemCopy, "price_per_unit", $$v)
                              },
                              expression: "itemCopy.price_per_unit",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]
        )
      : _vm._e(),
    _vm.allFilters.length
      ? _c(
          "div",
          {
            staticClass: "item-setting p0",
            class: { open: _vm.filtersScaled },
          },
          [
            _c(
              "button",
              {
                staticClass: "item-setting__head flex bet w100",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.filtersScaled = !_vm.filtersScaled
                  },
                },
              },
              [
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticClass: "item-setting__icon" },
                    [
                      _c("svg-icon", {
                        staticClass: "icon ic-settings",
                        attrs: {
                          name: "ic-settings",
                          width: "24",
                          height: "24",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", {
                    staticClass: "item-setting__title",
                    domProps: { textContent: _vm._s(_vm.$t("Filters")) },
                  }),
                ]),
                _c(
                  "div",
                  { staticClass: "item-setting__arr" },
                  [
                    _c("svg-icon", {
                      attrs: {
                        name: "ic-arrow-down",
                        width: "14",
                        height: "9",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "item-setting__content" },
              _vm._l(_vm.allFilters, function (filter) {
                return _c(
                  "button",
                  {
                    key: filter.id,
                    staticClass: "cat-filter__item",
                    class: { active: _vm.itemCopy.filters.includes(filter.id) },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.toggleFilter(filter.id)
                      },
                    },
                  },
                  [
                    filter.image
                      ? _c("div", { staticClass: "cat-filter__icon" }, [
                          _c("img", {
                            attrs: {
                              height: "16",
                              width: "16",
                              src:
                                "" +
                                _vm.fileServer +
                                _vm.restaurantId +
                                "/images/filters/sm-" +
                                filter.image,
                              alt: "",
                            },
                          }),
                        ])
                      : _vm._e(),
                    _c("div", {
                      staticClass: "cat-filter__text",
                      domProps: {
                        textContent: _vm._s(
                          _vm.he || !filter.name_en
                            ? filter.name
                            : filter.name_en
                        ),
                      },
                    }),
                  ]
                )
              }),
              0
            ),
          ]
        )
      : _vm._e(),
    _vm.allTags.length
      ? _c(
          "div",
          { staticClass: "item-setting p0", class: { open: _vm.closedTags } },
          [
            _c(
              "button",
              {
                staticClass: "item-setting__head flex bet w100",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.closedTags = !_vm.closedTags
                  },
                },
              },
              [
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticClass: "item-setting__icon" },
                    [
                      _c("svg-icon", {
                        staticClass: "icon ic-settings",
                        attrs: {
                          name: "ic-settings",
                          width: "24",
                          height: "24",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", {
                    staticClass: "item-setting__title",
                    domProps: { textContent: _vm._s(_vm.$t("Tags")) },
                  }),
                ]),
                _c(
                  "div",
                  { staticClass: "item-setting__arr" },
                  [
                    _c("svg-icon", {
                      attrs: {
                        name: "ic-arrow-down",
                        width: "14",
                        height: "9",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c("div", { staticClass: "item-setting__content" }, [
              _c(
                "div",
                {
                  staticClass: "tags-itm__header",
                  on: {
                    click: function ($event) {
                      return _vm.$refs.searchtag.focus()
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "tag-list-has-tag" },
                    _vm._l(_vm.hasTags, function (tag) {
                      return _c(
                        "div",
                        {
                          key: tag.id,
                          staticClass: "tags-itm__has-tag",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                        },
                        [
                          _c("div", {
                            staticClass: "tags-itm__name pie8",
                            domProps: {
                              textContent: _vm._s(
                                _vm.he ? tag.name : tag.name_en
                              ),
                            },
                          }),
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.toggleTag(tag.id)
                                },
                              },
                            },
                            [_vm._v("×")]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchTags,
                        expression: "searchTags",
                      },
                    ],
                    ref: "searchtag",
                    staticClass: "tags-itm__search p12",
                    attrs: { type: "text", placeholder: _vm.$t("Search...") },
                    domProps: { value: _vm.searchTags },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.searchTags = $event.target.value
                      },
                    },
                  }),
                ]
              ),
              _c(
                "div",
                { staticClass: "tags-itm__tag-list" },
                _vm._l(_vm.tagsFilterSearch, function (tag) {
                  return _c("button", {
                    key: tag.id,
                    staticClass: "tags-itm__tag-list_tag",
                    domProps: {
                      textContent: _vm._s(_vm.he ? tag.name : tag.name_en),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.toggleTag(tag.id)
                      },
                    },
                  })
                }),
                0
              ),
            ]),
          ]
        )
      : _vm._e(),
    _vm.itemCopy.item_type === 3
      ? _c(
          "div",
          { staticClass: "item-setting p0", class: { open: _vm.closedScaled } },
          [
            _c(
              "button",
              {
                staticClass: "item-setting__head flex bet w100",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.closedScaled = !_vm.closedScaled
                  },
                },
              },
              [
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticClass: "item-setting__icon" },
                    [
                      _c("svg-icon", {
                        attrs: {
                          name: "ic-settings",
                          width: "24",
                          height: "24",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", {
                    staticClass: "item-setting__title",
                    domProps: { textContent: _vm._s(_vm.$t("Pizza")) },
                  }),
                ]),
                _c(
                  "div",
                  { staticClass: "item-setting__arr" },
                  [
                    _c("svg-icon", {
                      attrs: {
                        name: "ic-arrow-down",
                        width: "14",
                        height: "9",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c("div", { staticClass: "item-setting__content" }, [
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "form-site" }, [
                    _c(
                      "div",
                      { staticClass: "form-site__content" },
                      [
                        _c("div", {
                          staticClass: "form-site__group-title",
                          domProps: { textContent: _vm._s(_vm.$t("Sizes")) },
                        }),
                        _vm._l(
                          _vm.itemCopy.available_sizes,
                          function (size, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "form-site__group border-none",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "form-site__field-third" },
                                  [
                                    _c("div", {
                                      staticClass: "form-site__label",
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Size")),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "form-site__input" },
                                      [
                                        _c("div", {}, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: size.name,
                                                expression: "size.name",
                                              },
                                            ],
                                            attrs: { type: "text" },
                                            domProps: { value: size.name },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  size,
                                                  "name",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-site__field-third" },
                                  [
                                    _c("div", {
                                      staticClass: "form-site__label",
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Price")),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "form-site__input" },
                                      [
                                        _c("div", {}, [
                                          _vm.restaurantCurrencyInfo === null
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "input-label__note",
                                                },
                                                [_vm._v("₪")]
                                              )
                                            : _vm._e(),
                                          _vm.restaurantCurrencyInfo !== null
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "input-label__note",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.restaurantCurrencySymbol
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: size.price,
                                                expression: "size.price",
                                              },
                                            ],
                                            staticClass: "pis60",
                                            attrs: {
                                              placeholder: "0",
                                              type: "number",
                                            },
                                            domProps: { value: size.price },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  size,
                                                  "price",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-icon btn-icon_light-red",
                                    staticStyle: { width: "50px" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.removePizzaSize(index)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "btn-icon__note mt10",
                                        staticStyle: { background: "#fbecee" },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            name: "ic-bin",
                                            width: "18",
                                            height: "20",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        _c("div", { staticClass: "line-more" }, [
                          _c(
                            "button",
                            {
                              staticClass: "line-more__content",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.addPizzaSize.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "line-more__icon" },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      name: "ic-plus",
                                      width: "12",
                                      height: "12",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("span", {
                                staticClass: "line-more__text",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Add")),
                                },
                              }),
                            ]
                          ),
                        ]),
                      ],
                      2
                    ),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "item-setting__content" }, [
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "form-site" }, [
                    _c(
                      "div",
                      { staticClass: "form-site__content" },
                      [
                        _c("div", {
                          staticClass: "form-site__group-title",
                          domProps: {
                            textContent: _vm._s(_vm.$t("Slicing method")),
                          },
                        }),
                        _c("select-box", {
                          attrs: {
                            width: "250px",
                            openToUp: "",
                            unsort: "",
                            options: _vm.optionsPizzaSplits,
                          },
                          model: {
                            value: _vm.itemCopy.slicing_method,
                            callback: function ($$v) {
                              _vm.$set(_vm.itemCopy, "slicing_method", $$v)
                            },
                            expression: "itemCopy.slicing_method",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }