var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-box-list" }, [
    _c(
      "div",
      { staticClass: "text-box", style: { background: _vm.background } },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.inputText,
              expression: "inputText",
            },
          ],
          staticClass: "inp",
          attrs: { placeholder: _vm.placeholder, type: "text" },
          domProps: { value: _vm.inputText },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.add.apply(null, arguments)
            },
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.inputText = $event.target.value
            },
          },
        }),
        _c("button", { on: { click: _vm.add } }, [
          _c(
            "svg",
            {
              attrs: {
                width: "40",
                height: "40",
                viewBox: "0 0 40 40",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("circle", {
                attrs: { cx: "20", cy: "20", r: "20", fill: "#F6F6F6" },
              }),
              _c("rect", {
                attrs: {
                  x: "19.0625",
                  y: "12.5",
                  width: "1.875",
                  height: "15",
                  rx: "0.9375",
                  fill: "#ACACAC",
                },
              }),
              _c("rect", {
                attrs: {
                  x: "12.5",
                  y: "20.9375",
                  width: "1.875",
                  height: "15",
                  rx: "0.9375",
                  transform: "rotate(-90 12.5 20.9375)",
                  fill: "#ACACAC",
                },
              }),
            ]
          ),
        ]),
      ]
    ),
    _c(
      "div",
      { staticClass: "list" },
      _vm._l(_vm.list, function (item, index) {
        return _c("div", { key: item, staticClass: "item" }, [
          _c("span", { domProps: { textContent: _vm._s(item) } }),
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.remove(index)
                },
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "16",
                    height: "16",
                    viewBox: "0 0 16 16",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("circle", {
                    attrs: { cx: "8", cy: "8", r: "8", fill: "#FAFAFA" },
                  }),
                  _c("rect", {
                    attrs: {
                      x: "3",
                      y: "7",
                      width: "10",
                      height: "2",
                      rx: "1",
                      fill: "#979797",
                    },
                  }),
                ]
              ),
            ]
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }