var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-dialog", { on: { close: _vm.close } }, [
    _c("div", { staticClass: "popup__top" }, [
      _c("div", { staticClass: "popup__head" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.area.name,
              expression: "area.name",
            },
          ],
          staticClass: "h1",
          attrs: { type: "text", placeholder: _vm.$t("Name") },
          domProps: { value: _vm.area.name },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.area, "name", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _c("div", { staticClass: "popup__content" }, [
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("div", { staticClass: "areas-edit" }, [
            _c(
              "div",
              { staticClass: "areas-edit__top" },
              [
                _c("checkbox", {
                  attrs: { name: "smoke" },
                  model: {
                    value: _vm.area.smoking,
                    callback: function ($$v) {
                      _vm.$set(_vm.area, "smoking", $$v)
                    },
                    expression: "area.smoking",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "areas-edit__top-label" },
                  [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.$t("Smoking")) },
                    }),
                    _c("svg-icon", {
                      staticClass: "ic-stroke",
                      attrs: { name: "ic-smoking", width: "16", height: "10" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "areas-edit__content" }, [
              _c("div", { staticClass: "areas-edit__sect" }, [
                _c("div", { staticClass: "areas-edit__list" }, [
                  _c("div", { staticClass: "areas-edit__field" }, [
                    _c("div", {
                      staticClass: "areas-edit__label",
                      domProps: {
                        textContent: _vm._s(_vm.$t("Average sitting time")),
                      },
                    }),
                    _c("div", { staticClass: "input-number" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.area.sitting_time,
                            expression: "area.sitting_time",
                          },
                        ],
                        attrs: { type: "number" },
                        domProps: { value: _vm.area.sitting_time },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.area,
                              "sitting_time",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-site__input" }, [
                _c("div", { staticClass: "edit-img" }, [
                  _c("div", {
                    staticClass: "edit-img__label",
                    domProps: { textContent: _vm._s(_vm.$t("Area Picture")) },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "edit-img__content",
                      class: { "edit-img__content_empty": !_vm.imagePath },
                      style: "background-image: url(" + _vm.imagePath + ")",
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "edit-img__icon" },
                        [
                          _vm.imagePath
                            ? _c(
                                "button",
                                {
                                  staticClass: "edit-img__icon",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.removeImage()
                                    },
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      name: "ic-plus",
                                      width: "22",
                                      height: "22",
                                    },
                                  }),
                                ],
                                1
                              )
                            : [
                                _c("input", {
                                  attrs: { type: "file", accept: "image/*" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.enterImage($event)
                                    },
                                  },
                                }),
                                _c("svg-icon", {
                                  staticClass: "ic-plus",
                                  attrs: {
                                    name: "ic-photo",
                                    width: "22",
                                    height: "22",
                                  },
                                }),
                              ],
                        ],
                        2
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "areas-edit__btn" }, [
              _c(
                "button",
                { staticClass: "btn btn_border", on: { click: _vm.remove } },
                [
                  _c("svg-icon", {
                    attrs: { name: "ic-bin", width: "26", height: "17" },
                  }),
                  _c("span", {
                    domProps: { textContent: _vm._s(_vm.$t("Delete")) },
                  }),
                ],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "btn",
                  attrs: { type: "submit" },
                  on: { click: _vm.save },
                },
                [
                  _c("span", {
                    domProps: { textContent: _vm._s(_vm.$t("Save")) },
                  }),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }