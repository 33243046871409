var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: { title: "Copy catalog", overflowVisible: "" },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("div", { staticClass: "popup__content pb40" }, [
        _c(
          "form",
          {
            staticClass: "form-site-wrap",
            on: {
              submit: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c("div", { staticClass: "form-site" }, [
              _c(
                "div",
                { staticClass: "form-site__group" },
                [
                  _c("div", { staticClass: "form-site__field" }, [
                    _c("div", { staticClass: "form-site__input pt20 " }, [
                      _c("div", { staticClass: "input-radio" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.allCatalog,
                              expression: "allCatalog",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "buyradio",
                            id: "radio210",
                          },
                          domProps: {
                            value: true,
                            checked: _vm._q(_vm.allCatalog, true),
                          },
                          on: {
                            change: function ($event) {
                              _vm.allCatalog = true
                            },
                          },
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "input-radio__label",
                            attrs: { for: "radio210" },
                          },
                          [
                            _c("span", { staticClass: "input-radio__mark" }),
                            _c("span", {
                              staticClass: "input-radio__text",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Copy all Catalog")),
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-site__field" }, [
                    _c("div", { staticClass: "form-site__input pt20 " }, [
                      _c("div", { staticClass: "input-radio" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.allCatalog,
                              expression: "allCatalog",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "buyradio",
                            id: "radio2101",
                          },
                          domProps: {
                            value: false,
                            checked: _vm._q(_vm.allCatalog, false),
                          },
                          on: {
                            change: function ($event) {
                              _vm.allCatalog = false
                            },
                          },
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "input-radio__label",
                            attrs: { for: "radio2101" },
                          },
                          [
                            _c("span", { staticClass: "input-radio__mark" }),
                            _c("span", {
                              staticClass: "input-radio__text",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("Copy Catalog from order menu")
                                ),
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-site__field" }),
                  _c("form-site-field-select-box", {
                    attrs: {
                      maxHeight: "300px",
                      showSearch: _vm.menusOptions.length > 4,
                      isDisabled: _vm.allCatalog,
                      options: _vm.menusOptions,
                    },
                    model: {
                      value: _vm.copyFrom,
                      callback: function ($$v) {
                        _vm.copyFrom = $$v
                      },
                      expression: "copyFrom",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "form-site__btn md-none" }, [
              _c("button", {
                staticClass: "btn btn_border",
                domProps: { textContent: _vm._s(_vm.$t("Cancel")) },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$emit("close")
                  },
                },
              }),
              _c("button", {
                staticClass: "btn",
                domProps: { textContent: _vm._s(_vm.$t("Ok")) },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.save.apply(null, arguments)
                  },
                },
              }),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "new-item__btn flex d-md-none" }, [
        _c(
          "button",
          { staticClass: "save-btn", on: { click: _vm.save } },
          [
            _c("svg-icon", {
              staticClass: "ic-edit",
              attrs: { name: "ic-save-btn", width: "93", height: "93" },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }