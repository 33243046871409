var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", {
    staticStyle: { cursor: "pointer" },
    domProps: { textContent: _vm._s(_vm.data.points) },
    on: {
      click: function ($event) {
        $event.stopPropagation()
        return _vm.data.click.apply(null, arguments)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }