var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      staticClass: "kitchen-dialog",
      attrs: { overflowVisible: "" },
      on: { close: _vm.close },
    },
    [
      _c(
        "div",
        {
          staticClass: "popup__content",
          staticStyle: {
            display: "flex",
            "flex-direction": "column",
            padding: "10px",
          },
        },
        [
          _c("h3", { domProps: { textContent: _vm._s(_vm.$t("Kitchens")) } }),
          _vm.kitchens.length == 0
            ? _c("span", {
                staticClass: "no_data",
                domProps: { textContent: _vm._s(_vm.$t("No data available")) },
              })
            : _vm._e(),
          _vm._l(_vm.kitchens, function (kitchen) {
            return _c(
              "div",
              {
                key: kitchen.id,
                staticClass: "compensation",
                staticStyle: { padding: "15px" },
                on: {
                  click: function ($event) {
                    _vm.kitchenInEdit = kitchen
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "kitchen-dialog__component" },
                  [
                    _c("form-site-field-text", {
                      staticClass: "kitchen-dialog__component_name",
                      domProps: { textContent: _vm._s(kitchen.name) },
                    }),
                    _c(
                      "div",
                      { staticClass: "kitchen-dialog__component_active" },
                      [
                        _c("div", {
                          staticClass: "switch-wrap__label mie12",
                          domProps: { textContent: _vm._s(_vm.$t("Active")) },
                        }),
                        _c("swich-button", {
                          on: { change: _vm.save },
                          model: {
                            value: kitchen.active,
                            callback: function ($$v) {
                              _vm.$set(kitchen, "active", $$v)
                            },
                            expression: "kitchen.active",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            )
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }