var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    { attrs: { width: "700px" }, on: { close: _vm.close } },
    [
      _c("div", { staticClass: "popup__top" }, [
        _c(
          "div",
          { staticClass: "profile__author" },
          [
            _c("user-avatar", {
              attrs: {
                user: _vm.client,
                "img-class": "profile__photo",
                size: 80,
              },
            }),
            _c("div", { staticClass: "profile__info" }, [
              _c("div", { staticClass: "profile__head" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.clientName,
                      expression: "clientName",
                    },
                  ],
                  ref: "name",
                  attrs: {
                    form: "client-before-order",
                    type: "text",
                    placeholder: _vm.$t("Client name"),
                    required: "",
                  },
                  domProps: { value: _vm.clientName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.clientName = $event.target.value
                    },
                  },
                }),
              ]),
              _c(
                "div",
                { staticClass: "order-detail-user__phone" },
                [
                  _c("svg-icon", {
                    attrs: { name: "ic-calls", width: "12", height: "12" },
                  }),
                  _c("span", {
                    domProps: { textContent: _vm._s(_vm.client.phone) },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "popup__content" }, [
        _c("div", { staticClass: "form-new-user" }, [
          _c(
            "form",
            {
              staticClass: "form-site-wrap",
              attrs: { id: "client-before-order" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.startOrder.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "form-site" }, [
                _c("div", { staticClass: "form-site__content" }, [
                  _c("div", { staticClass: "form-site__field-wrap" }, [
                    _vm.client.birth && _vm.client.birth !== "0000-00-00"
                      ? _c("div", { staticClass: "form-site__field" }, [
                          _c("div", {
                            staticClass: "form-site__label",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Birthday")),
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "form-site__input" },
                            [
                              _c("date-picker", {
                                attrs: {
                                  popover: { visibility: "click" },
                                  "max-date": _vm.today,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var inputValue = ref.inputValue
                                        return [
                                          _c("input", {
                                            staticClass:
                                              "bg-white border px-2 py-1 rounded",
                                            attrs: {
                                              type: "text",
                                              disabled: "",
                                            },
                                            domProps: { value: inputValue },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3485293499
                                ),
                                model: {
                                  value: _vm.client.birth,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.client, "birth", $$v)
                                  },
                                  expression: "client.birth",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.client.wedding && _vm.client.wedding !== "0000-00-00"
                      ? _c("div", { staticClass: "form-site__field" }, [
                          _c("div", {
                            staticClass: "form-site__label",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Wedding day")),
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "form-site__input" },
                            [
                              _c("date-picker", {
                                attrs: {
                                  popover: { visibility: "click" },
                                  "max-date": _vm.today,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var inputValue = ref.inputValue
                                        return [
                                          _c("input", {
                                            staticClass:
                                              "bg-white border px-2 py-1 rounded",
                                            attrs: {
                                              type: "text",
                                              disabled: "",
                                            },
                                            domProps: { value: inputValue },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3485293499
                                ),
                                model: {
                                  value: _vm.client.wedding,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.client, "wedding", $$v)
                                  },
                                  expression: "client.wedding",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm.client.birth &&
              _vm.client.birth !== "0000-00-00" &&
              _vm.client.wedding &&
              _vm.client.wedding !== "0000-00-00"
                ? _c("div", { staticClass: "hr" })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "from-site flex statistic-boxes" },
                _vm._l(_vm.statistics, function (statistic) {
                  return _c("statistics-box", {
                    key: statistic.name,
                    attrs: { statistic: statistic },
                  })
                }),
                1
              ),
              _vm.notes.length
                ? _c(
                    "div",
                    { staticClass: "form-site__field mt12" },
                    [
                      _c("div", {
                        staticClass: "form-site__label",
                        domProps: { textContent: _vm._s(_vm.$t("_Notes")) },
                      }),
                      _vm._l(_vm.notes, function (note, index) {
                        return _c("div", {
                          key: index,
                          staticClass: "red",
                          domProps: { textContent: _vm._s(note) },
                        })
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _c("div", { staticClass: "form-site__btn" }, [
                _c(
                  "button",
                  { staticClass: "btn", attrs: { type: "submit" } },
                  [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(_vm.$t("__Start order")),
                      },
                    }),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }