var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "switch",
      class:
        ((_obj = {}),
        (_obj[_vm.className] = _vm.className),
        (_obj.disabled = _vm.disabled),
        _obj),
    },
    [
      _c("label", { staticClass: "switch__content" }, [
        _c("input", {
          attrs: { type: "checkbox", disabled: _vm.disabled },
          domProps: { checked: !!_vm.value },
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
            input: function ($event) {
              $event.stopPropagation()
              return _vm.input.apply(null, arguments)
            },
          },
        }),
        _c("div", { staticClass: "switch__background" }),
        _c("div", { staticClass: "switch__marker" }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }