var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-dialog-tabs", {
    staticClass: "employee-details",
    attrs: {
      width: "676px",
      tabList: ["Personal Information", "Roles", "Bank account"],
    },
    on: { close: _vm.close },
    scopedSlots: _vm._u([
      {
        key: "tab0",
        fn: function () {
          return [
            _c("div", { staticClass: "form-lang" }, [
              _c(
                "div",
                {
                  staticClass: "header-back back d-flex d-md-none",
                  style: { "margin-right": "-25px", width: "103vw" },
                },
                [
                  _c(
                    "button",
                    { staticClass: "header__back", on: { click: _vm.close } },
                    [
                      _c(
                        "span",
                        { staticClass: "header-company" },
                        [
                          _c("svg-icon", {
                            staticClass: "ic-white",
                            attrs: {
                              name: "ic-arrow-left",
                              width: "16",
                              height: "12",
                            },
                          }),
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Back")) },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _c("div", { staticClass: "form-site" }, [
                _c("div", { staticClass: "form-site__content" }, [
                  _c(
                    "div",
                    { staticClass: "form-site__group" },
                    [
                      _c("form-site-field-select-box", {
                        attrs: {
                          disabled: !_vm.permissionStatusEdit,
                          title: "Status",
                          options: [
                            "employee-Pending",
                            "Active",
                            "_Inactive",
                          ].map(function (label, value) {
                            return {
                              label: _vm.$t(label),
                              value: value,
                            }
                          }),
                        },
                        model: {
                          value: _vm.employee.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.employee, "status", $$v)
                          },
                          expression: "employee.status",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "form-site__field flex bet" },
                        _vm._l(
                          ["Student", "Foreign", "Pregnant"],
                          function (key) {
                            return _c("div", { key: key[0] }, [
                              _c("span", {
                                staticClass: "form-site__label",
                                domProps: { textContent: _vm._s(_vm.$t(key)) },
                              }),
                              _c(
                                "div",
                                { staticClass: "form-site__input mt8" },
                                [
                                  _c("swich-button", {
                                    model: {
                                      value:
                                        _vm.employee["is_" + key.toLowerCase()],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.employee,
                                          "is_" + key.toLowerCase(),
                                          $$v
                                        )
                                      },
                                      expression:
                                        "employee['is_' + key.toLowerCase()]",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          }
                        ),
                        0
                      ),
                      _vm._l(["First name", "Last name"], function (key) {
                        return _c("form-site-field-text", {
                          key: key,
                          attrs: {
                            disabled: !_vm.permissionPersonalInfoEdit,
                            required: "",
                            title: key,
                          },
                          model: {
                            value: _vm.employee[_vm.snakeCase(key)],
                            callback: function ($$v) {
                              _vm.$set(_vm.employee, _vm.snakeCase(key), $$v)
                            },
                            expression: "employee[snakeCase(key)]",
                          },
                        })
                      }),
                      _c("form-site-field-text", {
                        attrs: {
                          disabled: !_vm.permissionPersonalInfoEdit,
                          title: "Phone",
                        },
                        model: {
                          value: _vm.employee.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.employee, "phone", $$v)
                          },
                          expression: "employee.phone",
                        },
                      }),
                      _c("form-site-field-text", {
                        attrs: {
                          disabled: !_vm.permissionPersonalInfoEdit,
                          title: "Email",
                        },
                        model: {
                          value: _vm.employee.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.employee, "email", $$v)
                          },
                          expression: "employee.email",
                        },
                      }),
                      _c("form-site-field-text", {
                        attrs: {
                          disabled: !_vm.permissionPersonalInfoEdit,
                          title: "salary system code",
                        },
                        model: {
                          value: _vm.employee.salary_system_code,
                          callback: function ($$v) {
                            _vm.$set(_vm.employee, "salary_system_code", $$v)
                          },
                          expression: "employee.salary_system_code",
                        },
                      }),
                      _c("form-site-field-select-box", {
                        attrs: {
                          title: "Marital status",
                          unsort: "",
                          "max-width": "220px",
                          "max-height": "150px",
                          options: _vm.maritalStatuses,
                        },
                        model: {
                          value: _vm.employee.marital_status,
                          callback: function ($$v) {
                            _vm.$set(_vm.employee, "marital_status", $$v)
                          },
                          expression: "employee.marital_status",
                        },
                      }),
                      _c("form-site-field-date", {
                        attrs: {
                          disabled:
                            !_vm.permissionPersonalInfoEdit || _vm.disableDate,
                          maxDate: _vm.today,
                          required: "",
                          title: "Birthday",
                        },
                        model: {
                          value: _vm.employee.birthdate,
                          callback: function ($$v) {
                            _vm.$set(_vm.employee, "birthdate", $$v)
                          },
                          expression: "employee.birthdate",
                        },
                      }),
                      _c("form-site-field-text", {
                        attrs: { disabled: true, title: "ID number" },
                        model: {
                          value: _vm.employee.id_num,
                          callback: function ($$v) {
                            _vm.$set(_vm.employee, "id_num", $$v)
                          },
                          expression: "employee.id_num",
                        },
                      }),
                      _vm.startDateMin
                        ? _c("form-site-field-date", {
                            attrs: {
                              required: "",
                              disabled: !_vm.permissionPersonalInfoEdit,
                              minDate: _vm.startDateMin,
                              title: "Start date",
                            },
                            model: {
                              value: _vm.employee.start_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.employee, "start_date", $$v)
                              },
                              expression: "employee.start_date",
                            },
                          })
                        : _vm._e(),
                      _vm.employee.id && _vm.endDateMin
                        ? _c("form-site-field-date", {
                            attrs: {
                              disabled: !_vm.permissionPersonalInfoEdit,
                              minDate: _vm.endDateMin,
                              title: "End date",
                            },
                            model: {
                              value: _vm.employee.end_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.employee, "end_date", $$v)
                              },
                              expression: "employee.end_date",
                            },
                          })
                        : _vm._e(),
                      _c("form-site-field-text", {
                        attrs: { title: "Cost multiplier" },
                        model: {
                          value: _vm.employee.cost_multiplier,
                          callback: function ($$v) {
                            _vm.$set(_vm.employee, "cost_multiplier", $$v)
                          },
                          expression: "employee.cost_multiplier",
                        },
                      }),
                      _c("form-site-field-select-box", {
                        attrs: {
                          title: "Rest day",
                          unsort: "",
                          "max-width": "220px",
                          "max-height": "150px",
                          options: [
                            "Sunday",
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday",
                            "Saturday",
                          ].map(function (label, value) {
                            return { label: _vm.$t(label), value: value }
                          }),
                        },
                        model: {
                          value: _vm.employee.rest_day,
                          callback: function ($$v) {
                            _vm.$set(_vm.employee, "rest_day", $$v)
                          },
                          expression: "employee.rest_day",
                        },
                      }),
                      _c("form-site-field-text", {
                        attrs: { title: "Daily meal budget" },
                        model: {
                          value: _vm.employee.employee_discount_meal,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.employee,
                              "employee_discount_meal",
                              $$v
                            )
                          },
                          expression: "employee.employee_discount_meal",
                        },
                      }),
                      !_vm.employee.id
                        ? _c("div", { staticClass: "form-site__field" }, [
                            _c("span", {
                              staticClass: "form-site__label",
                              domProps: {
                                textContent: _vm._s(_vm.$t("password")),
                              },
                            }),
                            _c("span", {
                              staticClass: "t-red form-site__label mis4",
                              domProps: { textContent: _vm._s("*") },
                            }),
                            _c("div", { staticClass: "form-site__input" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.employee.password,
                                    expression: "employee.password",
                                  },
                                ],
                                staticClass: "mb8",
                                attrs: {
                                  type: "text",
                                  disabled: !_vm.manualPassword,
                                  minlength: _vm.requiredPasswordLength,
                                  maxlength: _vm.requiredPasswordLength,
                                },
                                domProps: { value: _vm.employee.password },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.employee,
                                      "password",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                { staticClass: "flex pis8" },
                                [
                                  _c("checkbox", {
                                    attrs: { name: "manual-password" },
                                    on: {
                                      input: function ($event) {
                                        _vm.employee.password =
                                          _vm.serverPassword
                                      },
                                    },
                                    model: {
                                      value: _vm.manualPassword,
                                      callback: function ($$v) {
                                        _vm.manualPassword = $$v
                                      },
                                      expression: "manualPassword",
                                    },
                                  }),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Manual setting")
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c("div", { staticClass: "form-site__field" }, [
                    _c("div", {
                      staticClass: "form-site__label",
                      domProps: { textContent: _vm._s(_vm.$t("Addresses")) },
                    }),
                    _c("div", { staticClass: "edit-info" }, [
                      _vm.employee.addresses.length
                        ? _c(
                            "div",
                            { staticClass: "edit-info__list" },
                            _vm._l(
                              _vm.employee.addresses,
                              function (address, adIndex) {
                                return _c(
                                  "div",
                                  {
                                    key: adIndex,
                                    staticClass: "edit-info__item",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "edit-info__info",
                                        on: {
                                          click: function ($event) {
                                            _vm.editAddress(
                                              address,
                                              Number(adIndex)
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "edit-info__title",
                                          domProps: {
                                            textContent: _vm._s(address.name),
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "edit-info__text" },
                                          [
                                            _c("address-to-span", {
                                              attrs: { address: address },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm.permissionPersonalInfoEdit
                                      ? _c(
                                          "div",
                                          { staticClass: "edit-info__btn" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "edit-info__del",
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    _vm.deleteAddress(
                                                      Number(adIndex)
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("svg-icon", {
                                                  attrs: {
                                                    name: "ic-close",
                                                    width: "10",
                                                    height: "10",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                      _vm.permissionPersonalInfoEdit
                        ? _c("div", { staticClass: "line-more" }, [
                            _c(
                              "button",
                              {
                                staticClass: "line-more__content",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.addAddress.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "line-more__icon" },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        name: "ic-plus",
                                        width: "12",
                                        height: "12",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("span", {
                                  staticClass: "line-more__text",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Add")),
                                  },
                                }),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "form-site__btn form-site__btn_sb d-none d-md-flex",
                },
                [
                  _vm.employee.id
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-icon btn-icon_light-orange",
                          on: {
                            click: function ($event) {
                              _vm.resetPasswordPopup = true
                            },
                          },
                        },
                        [
                          _c("span", {
                            staticClass: "btn-icon__text pie16 flex a-center",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Reset password")),
                            },
                          }),
                        ]
                      )
                    : _c("div"),
                  _vm.permissionPersonalInfoEdit
                    ? _c("button", {
                        staticClass: "btn",
                        domProps: { textContent: _vm._s(_vm.$t("save")) },
                        on: { click: _vm.save },
                      })
                    : _vm._e(),
                ]
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "tab1",
        fn: function () {
          return [
            _c("div", { staticClass: "form-lang" }, [
              _c(
                "div",
                {
                  staticClass: "header-back back d-flex d-md-none",
                  style: { "margin-right": "-25px", width: "103vw" },
                },
                [
                  _c(
                    "button",
                    { staticClass: "header__back", on: { click: _vm.close } },
                    [
                      _c(
                        "span",
                        { staticClass: "header-company" },
                        [
                          _c("svg-icon", {
                            staticClass: "ic-white",
                            attrs: {
                              name: "ic-arrow-left",
                              width: "16",
                              height: "12",
                            },
                          }),
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Back")) },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _c("div", { staticClass: "form-site" }, [
                _c(
                  "div",
                  { staticClass: "form-site__content" },
                  [
                    _c(
                      "div",
                      { staticClass: "form-site__group" },
                      [
                        _vm.restaurantCurrencyInfo == null
                          ? _c("form-site-field-text", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.checkPermissionPath(
                                    "employees.global_monthly"
                                  ),
                                  expression:
                                    "checkPermissionPath('employees.global_monthly')",
                                },
                              ],
                              attrs: {
                                disabled: !_vm.permissionPersonalInfoEdit,
                                placeholder: "0",
                                title: "Global monthly",
                                sign: "₪",
                              },
                              model: {
                                value: _vm.employee.global_monthly,
                                callback: function ($$v) {
                                  _vm.$set(_vm.employee, "global_monthly", $$v)
                                },
                                expression: "employee.global_monthly",
                              },
                            })
                          : _vm._e(),
                        _vm.restaurantCurrencyInfo !== null
                          ? _c("form-site-field-text", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.checkPermissionPath(
                                    "employees.global_monthly"
                                  ),
                                  expression:
                                    "checkPermissionPath('employees.global_monthly')",
                                },
                              ],
                              attrs: {
                                disabled: !_vm.permissionPersonalInfoEdit,
                                placeholder: "0",
                                title: "Global monthly",
                                sign: _vm.restaurantCurrencySymbol,
                              },
                              model: {
                                value: _vm.employee.global_monthly,
                                callback: function ($$v) {
                                  _vm.$set(_vm.employee, "global_monthly", $$v)
                                },
                                expression: "employee.global_monthly",
                              },
                            })
                          : _vm._e(),
                        _c("form-site-field-text", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.checkPermissionPath(
                                "employees.global_daily"
                              ),
                              expression:
                                "checkPermissionPath('employees.global_daily')",
                            },
                          ],
                          attrs: {
                            disabled: !_vm.permissionPersonalInfoEdit,
                            placeholder: "0",
                            title: "Global daily",
                            sign: _vm.restaurantCurrencySymbol || "₪",
                          },
                          model: {
                            value: _vm.employee.global_daily,
                            callback: function ($$v) {
                              _vm.$set(_vm.employee, "global_daily", $$v)
                            },
                            expression: "employee.global_daily",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._l(
                      _vm.employee.employee_positions,
                      function (position, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "position-payment",
                            on: {
                              click: function ($event) {
                                _vm.openPosition(position, Number(index))
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "position-payment__desplay" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "position-payment__desplay_text",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "position-payment__desplay_position",
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "mie4",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.positionBranchName(position)
                                            ),
                                          },
                                        }),
                                        _c("span", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.positionOptionLabel(position)
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                    position.rate != null
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "position-payment__desplay_wage",
                                          },
                                          [
                                            _vm.restaurantCurrencyInfo == null
                                              ? _c("span", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      "₪" +
                                                        position.rate / 100 +
                                                        " " +
                                                        _vm.$t("per ")
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm.restaurantCurrencyInfo !== null
                                              ? _c("span", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.restaurantCurrencySymbol +
                                                        position.rate / 100 +
                                                        " " +
                                                        _vm.$t("per ")
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            _c("span", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    position.rate_period === 1
                                                      ? "Hour"
                                                      : "Day"
                                                  )
                                                ),
                                              },
                                            }),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "position-payment__desplay_wage",
                                          },
                                          [
                                            _c("span", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t("Global salary")
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                    _vm.permissionPersonalInfoEdit
                      ? _c("div", { staticClass: "line-more" }, [
                          _c(
                            "button",
                            {
                              staticClass: "line-more__content",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.addPosition.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "line-more__icon" },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      name: "ic-plus",
                                      width: "12",
                                      height: "12",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("span", {
                                staticClass: "line-more__text",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Add")),
                                },
                              }),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "form-site__btn form-site__btn_sb d-none d-md-flex",
                },
                [
                  _vm.employee.id
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-icon btn-icon_light-orange",
                          on: {
                            click: function ($event) {
                              _vm.resetPasswordPopup = true
                            },
                          },
                        },
                        [
                          _c("span", {
                            staticClass: "btn-icon__text pie16 flex a-center",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Reset password")),
                            },
                          }),
                        ]
                      )
                    : _c("div"),
                  _vm.permissionPersonalInfoEdit
                    ? _c("button", {
                        staticClass: "btn",
                        domProps: { textContent: _vm._s(_vm.$t("save")) },
                        on: { click: _vm.save },
                      })
                    : _vm._e(),
                ]
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "tab2",
        fn: function () {
          return [
            _c("div", { staticClass: "form-lang" }, [
              _c(
                "div",
                {
                  staticClass: "header-back back d-flex d-md-none",
                  style: { "margin-right": "-25px", width: "103vw" },
                },
                [
                  _c(
                    "button",
                    { staticClass: "header__back", on: { click: _vm.close } },
                    [
                      _c(
                        "span",
                        { staticClass: "header-company" },
                        [
                          _c("svg-icon", {
                            staticClass: "ic-white",
                            attrs: {
                              name: "ic-arrow-left",
                              width: "16",
                              height: "12",
                            },
                          }),
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.$t("Back")) },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _c("div", { staticClass: "form-site" }, [
                _c("div", { staticClass: "form-site__content" }, [
                  _vm.employee.bank_accounts[0]
                    ? _c(
                        "div",
                        { staticClass: "form-site__group" },
                        [
                          _c("form-site-field-select-bank", {
                            attrs: {
                              isScroll: "",
                              maxHeight: "210px",
                              maxWidth: "240px",
                              title: "Bank id",
                              disabled: !_vm.permissionPersonalInfoEdit,
                            },
                            model: {
                              value: _vm.employee.bank_accounts[0].bank_id,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.employee.bank_accounts[0],
                                  "bank_id",
                                  $$v
                                )
                              },
                              expression: "employee.bank_accounts[0].bank_id",
                            },
                          }),
                          _c("form-site-field-select-bank-branch", {
                            attrs: {
                              isScroll: "",
                              bankId: _vm.employee.bank_accounts[0].bank_id,
                              maxHeight: "210px",
                              maxWidth: "240px",
                              title: "Branch",
                              disabled: !_vm.permissionPersonalInfoEdit,
                            },
                            model: {
                              value: _vm.employee.bank_accounts[0].branch,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.employee.bank_accounts[0],
                                  "branch",
                                  $$v
                                )
                              },
                              expression: "employee.bank_accounts[0].branch",
                            },
                          }),
                          _c("form-site-field-text", {
                            key: "Account",
                            attrs: {
                              disabled: !_vm.permissionPersonalInfoEdit,
                              maxHeight: "210px",
                              maxWidth: "240px",
                              title: "Account",
                            },
                            model: {
                              value: _vm.employee.bank_accounts[0].account,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.employee.bank_accounts[0],
                                  "account",
                                  $$v
                                )
                              },
                              expression: "employee.bank_accounts[0].account",
                            },
                          }),
                          _c("form-site-field-text", {
                            key: "Name",
                            attrs: {
                              disabled: !_vm.permissionPersonalInfoEdit,
                              maxHeight: "210px",
                              maxWidth: "240px",
                              title: "Name",
                            },
                            model: {
                              value: _vm.employee.bank_accounts[0].name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.employee.bank_accounts[0],
                                  "name",
                                  $$v
                                )
                              },
                              expression: "employee.bank_accounts[0].name",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "form-site__btn form-site__btn_sb d-none d-md-flex",
                },
                [
                  _vm.employee.id
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-icon btn-icon_light-orange",
                          on: {
                            click: function ($event) {
                              _vm.resetPasswordPopup = true
                            },
                          },
                        },
                        [
                          _c("span", {
                            staticClass: "btn-icon__text pie16 flex a-center",
                            domProps: {
                              textContent: _vm._s(_vm.$t("Reset password")),
                            },
                          }),
                        ]
                      )
                    : _c("div"),
                  _vm.permissionPersonalInfoEdit
                    ? _c("button", {
                        staticClass: "btn",
                        domProps: { textContent: _vm._s(_vm.$t("save")) },
                        on: { click: _vm.save },
                      })
                    : _vm._e(),
                ]
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "btns",
        fn: function () {
          return [
            _c("div", { staticClass: "new-item__btn d-flex d-md-none" }, [
              _vm.employee.id
                ? _c(
                    "button",
                    {
                      staticClass: "btn-icon btn-icon_light-orange",
                      on: {
                        click: function ($event) {
                          _vm.resetPasswordPopup = true
                        },
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "btn-icon__text pie16 flex a-center",
                        domProps: {
                          textContent: _vm._s(_vm.$t("Reset password")),
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _c(
                "button",
                { staticClass: "save-btn", on: { click: _vm.save } },
                [
                  _c("svg-icon", {
                    staticClass: "ic-edit",
                    attrs: { name: "ic-save-btn", width: "112", height: "112" },
                  }),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "popups",
        fn: function () {
          return [
            _vm.popup
              ? _c("attendance-dialog", {
                  attrs: { employee: _vm.employee },
                  on: { save: _vm.saveAttendance },
                })
              : _vm._e(),
            !!_vm.currentAddress
              ? _c("edit-address-dialog", {
                  attrs: {
                    address: _vm.currentAddress,
                    show: !!_vm.currentAddress,
                  },
                  on: {
                    closed: _vm.closeAddressEditing,
                    saved: _vm.saveAddress,
                  },
                })
              : _vm._e(),
            _vm.currPosition
              ? _c("position-payment", {
                  attrs: { value: _vm.currPosition },
                  on: {
                    remove: _vm.removePosition,
                    close: function ($event) {
                      _vm.currPosition = null
                    },
                    input: _vm.changePosition,
                  },
                })
              : _vm._e(),
            _vm.resetPasswordPopup
              ? _c("employee-reset-password", {
                  attrs: { employeeId: _vm.employee.id },
                  on: { save: _vm.resetPassword },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }