var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.editArea == false
        ? _c("base-dialog-tabs", {
            staticClass: "edit-delivery-area-dialog",
            attrs: { tabList: ["General", "Availability"] },
            on: { close: _vm.close },
            scopedSlots: _vm._u(
              [
                {
                  key: "tab0",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "popup__top" }, [
                        _c(
                          "div",
                          {
                            staticClass: "header-back back d-flex d-md-none",
                            style: { "margin-right": "-25px", width: "103vw" },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "header__back",
                                on: { click: _vm.close },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "header-company" },
                                  [
                                    _c("svg-icon", {
                                      staticClass: "ic-white",
                                      attrs: {
                                        name: "ic-arrow-left",
                                        width: "16",
                                        height: "12",
                                      },
                                    }),
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Back")),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "popup__head flex bet a-center" },
                          [
                            _vm.areaInEdit.id
                              ? _c("div", {
                                  staticClass: "h1 mt20",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("Edit delivery area")
                                    ),
                                  },
                                })
                              : _c("div", {
                                  staticClass: "h1 mt20",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("Delivery area")
                                    ),
                                  },
                                }),
                            _c(
                              "div",
                              {
                                staticClass: "switch-wrap inline-flex a-center",
                              },
                              [
                                _c("div", {
                                  staticClass: "switch-wrap__label mie12",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Active")),
                                  },
                                }),
                                _c("swich-button", {
                                  model: {
                                    value: _vm.areaInEdit.active,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.areaInEdit, "active", $$v)
                                    },
                                    expression: "areaInEdit.active",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "popup__content" }, [
                        _c("div", { staticClass: "form-lang" }, [
                          _c(
                            "form",
                            {
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "form-site" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "form-site__content" },
                                    [
                                      _c("form-site-field-text", {
                                        attrs: { title: "Area name" },
                                        model: {
                                          value: _vm.areaInEdit.name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.areaInEdit,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression: "areaInEdit.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "form-site__group" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-site__field" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "working-hours__item",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "input-radio" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.areaInEdit
                                                            .all_city,
                                                        expression:
                                                          "areaInEdit.all_city",
                                                      },
                                                    ],
                                                    attrs: {
                                                      id: "radio1",
                                                      type: "radio",
                                                    },
                                                    domProps: {
                                                      value: true,
                                                      checked: _vm._q(
                                                        _vm.areaInEdit.all_city,
                                                        true
                                                      ),
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.areaInEdit,
                                                          "all_city",
                                                          true
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "input-radio__label",
                                                      attrs: { for: "radio1" },
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "input-radio__mark",
                                                      }),
                                                      _c("span", {
                                                        staticClass:
                                                          "input-radio__text",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t("All city")
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "form-site__field" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "working-hours__item",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "input-radio" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.areaInEdit
                                                            .all_city,
                                                        expression:
                                                          "areaInEdit.all_city",
                                                      },
                                                    ],
                                                    attrs: {
                                                      id: "radio2",
                                                      type: "radio",
                                                    },
                                                    domProps: {
                                                      value: false,
                                                      checked: _vm._q(
                                                        _vm.areaInEdit.all_city,
                                                        false
                                                      ),
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.areaInEdit,
                                                          "all_city",
                                                          false
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "input-radio__label",
                                                      attrs: { for: "radio2" },
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "input-radio__mark",
                                                      }),
                                                      _c("span", {
                                                        staticClass:
                                                          "input-radio__text",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t(
                                                              "Mark on the map"
                                                            )
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.areaInEdit.all_city
                                    ? _c(
                                        "div",
                                        { staticClass: "form-site__content" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-site__field" },
                                            [
                                              _c("div", {
                                                staticClass: "form-site__label",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t("City")
                                                  ),
                                                },
                                              }),
                                              _c("select-city", {
                                                model: {
                                                  value: _vm.city,
                                                  callback: function ($$v) {
                                                    _vm.city = $$v
                                                  },
                                                  expression: "city",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("div", { staticClass: "hr" }),
                                  _c("div", {
                                    staticClass: "areas-edit__title",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Standard price")
                                      ),
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "form-site__content-contain",
                                    },
                                    [
                                      _vm.restaurantCurrencyInfo == null
                                        ? _c("form-site-field-text", {
                                            attrs: {
                                              sub: "",
                                              title: "minimum order amount",
                                              sign: "₪",
                                              disabled:
                                                !_vm.permissionEditMinimum,
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.valid($event, "min")
                                              },
                                            },
                                            model: {
                                              value: _vm.areaInEdit.min,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.areaInEdit,
                                                  "min",
                                                  $$v
                                                )
                                              },
                                              expression: "areaInEdit.min",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.restaurantCurrencyInfo !== null
                                        ? _c("form-site-field-text", {
                                            attrs: {
                                              sub: "",
                                              title: "minimum order amount",
                                              sign: _vm.restaurantCurrencySymbol,
                                              disabled:
                                                !_vm.permissionEditMinimum,
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.valid($event, "min")
                                              },
                                            },
                                            model: {
                                              value: _vm.areaInEdit.min,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.areaInEdit,
                                                  "min",
                                                  $$v
                                                )
                                              },
                                              expression: "areaInEdit.min",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.restaurantCurrencyInfo == null
                                        ? _c("form-site-field-text", {
                                            attrs: {
                                              sub: "",
                                              title: "delivery cost",
                                              sign: "₪",
                                              disabled:
                                                !_vm.permissionEditPrice,
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.valid(
                                                  $event,
                                                  "price"
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.areaInEdit.price,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.areaInEdit,
                                                  "price",
                                                  $$v
                                                )
                                              },
                                              expression: "areaInEdit.price",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.restaurantCurrencyInfo !== null
                                        ? _c("form-site-field-text", {
                                            attrs: {
                                              sub: "",
                                              title: "delivery cost",
                                              sign: _vm.restaurantCurrencySymbol,
                                              disabled:
                                                !_vm.permissionEditPrice,
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.valid(
                                                  $event,
                                                  "price"
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.areaInEdit.price,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.areaInEdit,
                                                  "price",
                                                  $$v
                                                )
                                              },
                                              expression: "areaInEdit.price",
                                            },
                                          })
                                        : _vm._e(),
                                      _c("form-site-field-text", {
                                        attrs: {
                                          sub: "",
                                          title: "estimated delivery time",
                                          sign: _vm.$t("min"),
                                          disabled:
                                            !_vm.permissionEditDeliveryTime,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.valid($event, "time")
                                          },
                                        },
                                        model: {
                                          value: _vm.areaInEdit.time,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.areaInEdit,
                                              "time",
                                              $$v
                                            )
                                          },
                                          expression: "areaInEdit.time",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  !(
                                    _vm.areaInEdit.dragon_time === null ||
                                    _vm.areaInEdit.dragon_time === undefined
                                  )
                                    ? _c("div", { staticClass: "hr" })
                                    : _vm._e(),
                                  !(
                                    _vm.areaInEdit.dragon_time === null ||
                                    _vm.areaInEdit.dragon_time === undefined
                                  )
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-site__content-contain",
                                          staticStyle: {
                                            "justify-content": "start",
                                            gap: "20px",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                gap: "5px",
                                              },
                                            },
                                            [
                                              _c("div", {
                                                staticClass: "form-site__label",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t("Dragon Check")
                                                  ),
                                                },
                                              }),
                                              _c("checkbox", {
                                                staticClass: "cb",
                                                attrs: { name: "cb" },
                                                model: {
                                                  value:
                                                    _vm.areaInEdit.check_dragon,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.areaInEdit,
                                                      "check_dragon",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "areaInEdit.check_dragon",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.areaInEdit.check_dragon
                                            ? _c("form-site-field-text", {
                                                attrs: {
                                                  short: "",
                                                  disabled: true,
                                                  title: "Dragon time",
                                                  sign: _vm.$t("min"),
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.valid(
                                                      $event,
                                                      "dragon_time"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.areaInEdit.dragon_time,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.areaInEdit,
                                                      "dragon_time",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "areaInEdit.dragon_time",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("div", { staticClass: "hr" }),
                                  _c("div", {
                                    staticClass: "areas-edit__title",
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Discounts")),
                                    },
                                  }),
                                  _vm._l(
                                    _vm.areaInEdit.price_variation,
                                    function (discount, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass:
                                            "form-site__content-contain",
                                        },
                                        [
                                          _vm.restaurantCurrencyInfo == null
                                            ? _c("form-site-field-text", {
                                                attrs: {
                                                  sub: "",
                                                  title: "order amount",
                                                  sign: "₪",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.validPriceVariation(
                                                      $event,
                                                      index,
                                                      "min"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: discount.min,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      discount,
                                                      "min",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "discount.min",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.restaurantCurrencyInfo !== null
                                            ? _c("form-site-field-text", {
                                                attrs: {
                                                  sub: "",
                                                  title: "order amount",
                                                  sign: _vm.restaurantCurrencySymbol,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.validPriceVariation(
                                                      $event,
                                                      index,
                                                      "min"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: discount.min,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      discount,
                                                      "min",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "discount.min",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.restaurantCurrencyInfo == null
                                            ? _c("form-site-field-text", {
                                                attrs: {
                                                  sub: "",
                                                  title: "delivery cost",
                                                  sign: "₪",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.validPriceVariation(
                                                      $event,
                                                      index,
                                                      "price"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: discount.price,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      discount,
                                                      "price",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "discount.price",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.restaurantCurrencyInfo !== null
                                            ? _c("form-site-field-text", {
                                                attrs: {
                                                  sub: "",
                                                  title: "delivery cost",
                                                  sign: _vm.restaurantCurrencySymbol,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.validPriceVariation(
                                                      $event,
                                                      index,
                                                      "price"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: discount.price,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      discount,
                                                      "price",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "discount.price",
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-site__field__delete",
                                            },
                                            [
                                              _c("bin-btn", {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removePriceVariation(
                                                      index
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _c("div", { staticClass: "line-more" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "line-more__content",
                                        on: { click: _vm.addDiscounts },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "line-more__icon" },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                name: "ic-plus",
                                                width: "12",
                                                height: "12",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("div", {
                                          staticClass: "line-more__text",
                                          domProps: {
                                            textContent: _vm._s(_vm.$t("Add")),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ],
                                2
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "tab1",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "header-back back d-flex d-md-none",
                          style: { "margin-right": "-25px", width: "103vw" },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "header__back",
                              on: { click: _vm.close },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "header-company" },
                                [
                                  _c("svg-icon", {
                                    staticClass: "ic-white",
                                    attrs: {
                                      name: "ic-arrow-left",
                                      width: "16",
                                      height: "12",
                                    },
                                  }),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Back")),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("availability-tab", {
                        attrs: {
                          id: !!_vm.areaInEdit.id,
                          availability: _vm.areaInEdit.availability,
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "btns",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "client-detail__bottom bet d-md-flex d-none",
                        },
                        [
                          _vm.areaInEdit.id
                            ? _c("remove-btn", {
                                staticStyle: { margin: "unset" },
                                on: { click: _vm.remove },
                              })
                            : _c("div", {}),
                          _c("button", {
                            staticClass: "btn",
                            attrs: { disabled: !_vm.permissionEdit },
                            domProps: { textContent: _vm._s(_vm.$t("save")) },
                            on: { click: _vm.save },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "new-item__btn d-flex d-md-none" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "save-btn",
                              on: { click: _vm.save },
                            },
                            [
                              _c("svg-icon", {
                                staticClass: "ic-edit",
                                attrs: {
                                  name: "ic-save-btn",
                                  width: "93",
                                  height: "93",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "bin-btn",
                              on: { click: _vm.remove },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-bin",
                                  width: "18",
                                  height: "20",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3144947355
            ),
          })
        : _vm._e(),
      _vm.editArea == true
        ? _c("base-dialog-tabs", {
            staticClass: "edit-delivery-area-dialog",
            attrs: {
              tabList: ["General", "Availability", "backup branches"],
              width: "700px",
            },
            on: { close: _vm.close },
            scopedSlots: _vm._u(
              [
                {
                  key: "tab0",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "popup__top" }, [
                        _c(
                          "div",
                          {
                            staticClass: "header-back back d-flex d-md-none",
                            style: { "margin-right": "-25px", width: "103vw" },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "header__back",
                                on: { click: _vm.close },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "header-company" },
                                  [
                                    _c("svg-icon", {
                                      staticClass: "ic-white",
                                      attrs: {
                                        name: "ic-arrow-left",
                                        width: "16",
                                        height: "12",
                                      },
                                    }),
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Back")),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "popup__head flex bet a-center" },
                          [
                            _vm.areaInEdit.id
                              ? _c("div", {
                                  staticClass: "h1 mt20",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("Edit delivery area")
                                    ),
                                  },
                                })
                              : _c("div", {
                                  staticClass: "h1 mt20",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("Delivery area")
                                    ),
                                  },
                                }),
                            _c(
                              "div",
                              {
                                staticClass: "switch-wrap inline-flex a-center",
                              },
                              [
                                _c("div", {
                                  staticClass: "switch-wrap__label mie12",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Active")),
                                  },
                                }),
                                _c("swich-button", {
                                  model: {
                                    value: _vm.areaInEdit.active,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.areaInEdit, "active", $$v)
                                    },
                                    expression: "areaInEdit.active",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "popup__content" }, [
                        _c("div", { staticClass: "form-lang" }, [
                          _c(
                            "form",
                            {
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "form-site" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "form-site__content" },
                                    [
                                      _c("form-site-field-text", {
                                        attrs: { title: "Area name" },
                                        model: {
                                          value: _vm.areaInEdit.name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.areaInEdit,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression: "areaInEdit.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "form-site__group" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-site__field" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "working-hours__item",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "input-radio" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.areaInEdit
                                                            .all_city,
                                                        expression:
                                                          "areaInEdit.all_city",
                                                      },
                                                    ],
                                                    attrs: {
                                                      id: "radio1",
                                                      type: "radio",
                                                    },
                                                    domProps: {
                                                      value: true,
                                                      checked: _vm._q(
                                                        _vm.areaInEdit.all_city,
                                                        true
                                                      ),
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.areaInEdit,
                                                          "all_city",
                                                          true
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "input-radio__label",
                                                      attrs: { for: "radio1" },
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "input-radio__mark",
                                                      }),
                                                      _c("span", {
                                                        staticClass:
                                                          "input-radio__text",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t("All city")
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "form-site__field" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "working-hours__item",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "input-radio" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.areaInEdit
                                                            .all_city,
                                                        expression:
                                                          "areaInEdit.all_city",
                                                      },
                                                    ],
                                                    attrs: {
                                                      id: "radio2",
                                                      type: "radio",
                                                    },
                                                    domProps: {
                                                      value: false,
                                                      checked: _vm._q(
                                                        _vm.areaInEdit.all_city,
                                                        false
                                                      ),
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.areaInEdit,
                                                          "all_city",
                                                          false
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "input-radio__label",
                                                      attrs: { for: "radio2" },
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "input-radio__mark",
                                                      }),
                                                      _c("span", {
                                                        staticClass:
                                                          "input-radio__text",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t(
                                                              "Mark on the map"
                                                            )
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.areaInEdit.all_city
                                    ? _c(
                                        "div",
                                        { staticClass: "form-site__content" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-site__field" },
                                            [
                                              _c("div", {
                                                staticClass: "form-site__label",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t("City")
                                                  ),
                                                },
                                              }),
                                              _c("select-city", {
                                                model: {
                                                  value: _vm.city,
                                                  callback: function ($$v) {
                                                    _vm.city = $$v
                                                  },
                                                  expression: "city",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.areaInEdit.all_city
                                    ? _c(
                                        "div",
                                        { staticClass: "form-site__content" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-site__field" },
                                            [
                                              _c("div", {
                                                staticClass: "form-site__label",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t("Cites")
                                                  ),
                                                },
                                              }),
                                              _c("select-cites", {
                                                attrs: {
                                                  areaCities:
                                                    _vm.areaInEdit.cities,
                                                },
                                                on: {
                                                  setCities: _vm.getCitites,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("div", { staticClass: "hr" }),
                                  _c("div", {
                                    staticClass: "areas-edit__title",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("Standard price")
                                      ),
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "form-site__content-contain",
                                    },
                                    [
                                      _vm.restaurantCurrencyInfo == null
                                        ? _c("form-site-field-text", {
                                            attrs: {
                                              sub: "",
                                              title: "minimum order amount",
                                              sign: "₪",
                                              disabled:
                                                !_vm.permissionEditMinimum,
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.valid($event, "min")
                                              },
                                            },
                                            model: {
                                              value: _vm.areaInEdit.min,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.areaInEdit,
                                                  "min",
                                                  $$v
                                                )
                                              },
                                              expression: "areaInEdit.min",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.restaurantCurrencyInfo !== null
                                        ? _c("form-site-field-text", {
                                            attrs: {
                                              sub: "",
                                              title: "minimum order amount",
                                              sign: _vm.restaurantCurrencySymbol,
                                              disabled:
                                                !_vm.permissionEditMinimum,
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.valid($event, "min")
                                              },
                                            },
                                            model: {
                                              value: _vm.areaInEdit.min,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.areaInEdit,
                                                  "min",
                                                  $$v
                                                )
                                              },
                                              expression: "areaInEdit.min",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.restaurantCurrencyInfo == null
                                        ? _c("form-site-field-text", {
                                            attrs: {
                                              sub: "",
                                              title: "delivery cost",
                                              sign: "₪",
                                              disabled:
                                                !_vm.permissionEditPrice,
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.valid(
                                                  $event,
                                                  "price"
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.areaInEdit.price,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.areaInEdit,
                                                  "price",
                                                  $$v
                                                )
                                              },
                                              expression: "areaInEdit.price",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.restaurantCurrencyInfo !== null
                                        ? _c("form-site-field-text", {
                                            attrs: {
                                              sub: "",
                                              title: "delivery cost",
                                              sign: _vm.restaurantCurrencySymbol,
                                              disabled:
                                                !_vm.permissionEditPrice,
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.valid(
                                                  $event,
                                                  "price"
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.areaInEdit.price,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.areaInEdit,
                                                  "price",
                                                  $$v
                                                )
                                              },
                                              expression: "areaInEdit.price",
                                            },
                                          })
                                        : _vm._e(),
                                      _c("form-site-field-text", {
                                        attrs: {
                                          sub: "",
                                          title: "estimated delivery time",
                                          sign: _vm.$t("min"),
                                          disabled:
                                            !_vm.permissionEditDeliveryTime,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.valid($event, "time")
                                          },
                                        },
                                        model: {
                                          value: _vm.areaInEdit.time,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.areaInEdit,
                                              "time",
                                              $$v
                                            )
                                          },
                                          expression: "areaInEdit.time",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  !(
                                    _vm.areaInEdit.dragon_time === null ||
                                    _vm.areaInEdit.dragon_time === undefined
                                  )
                                    ? _c("div", { staticClass: "hr" })
                                    : _vm._e(),
                                  !(
                                    _vm.areaInEdit.dragon_time === null ||
                                    _vm.areaInEdit.dragon_time === undefined
                                  )
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-site__content-contain",
                                          staticStyle: {
                                            "justify-content": "start",
                                            gap: "20px",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                gap: "10px",
                                              },
                                            },
                                            [
                                              _c("div", {
                                                staticClass: "form-site__label",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t("Dragon Check")
                                                  ),
                                                },
                                              }),
                                              _c("checkbox", {
                                                staticClass: "cb",
                                                attrs: { name: "cb" },
                                                model: {
                                                  value:
                                                    _vm.areaInEdit.check_dragon,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.areaInEdit,
                                                      "check_dragon",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "areaInEdit.check_dragon",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.areaInEdit.check_dragon
                                            ? _c("form-site-field-text", {
                                                attrs: {
                                                  short: "",
                                                  disabled: true,
                                                  title: "Dragon time",
                                                  sign: _vm.$t("min"),
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.valid(
                                                      $event,
                                                      "dragon_time"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.areaInEdit.dragon_time,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.areaInEdit,
                                                      "dragon_time",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "areaInEdit.dragon_time",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("div", { staticClass: "hr" }),
                                  _c("div", {
                                    staticClass: "areas-edit__title",
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Discounts")),
                                    },
                                  }),
                                  _vm._l(
                                    _vm.areaInEdit.price_variation,
                                    function (discount, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass:
                                            "form-site__content-contain",
                                        },
                                        [
                                          _vm.restaurantCurrencyInfo == null
                                            ? _c("form-site-field-text", {
                                                attrs: {
                                                  sub: "",
                                                  placeholder: "0",
                                                  title: "order amount",
                                                  sign: "₪",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.validPriceVariation(
                                                      $event,
                                                      index,
                                                      "min"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: discount.min,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      discount,
                                                      "min",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "discount.min",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.restaurantCurrencyInfo !== null
                                            ? _c("form-site-field-text", {
                                                attrs: {
                                                  sub: "",
                                                  placeholder: "0",
                                                  title: "order amount",
                                                  sign: _vm.restaurantCurrencySymbol,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.validPriceVariation(
                                                      $event,
                                                      index,
                                                      "min"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: discount.min,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      discount,
                                                      "min",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "discount.min",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.restaurantCurrencyInfo == null
                                            ? _c("form-site-field-text", {
                                                attrs: {
                                                  sub: "",
                                                  title: "delivery cost",
                                                  placeholder: "0",
                                                  sign: "₪",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.validPriceVariation(
                                                      $event,
                                                      index,
                                                      "price"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: discount.price,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      discount,
                                                      "price",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "discount.price",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.restaurantCurrencyInfo !== null
                                            ? _c("form-site-field-text", {
                                                attrs: {
                                                  sub: "",
                                                  placeholder: "0",
                                                  title: "delivery cost",
                                                  sign: _vm.restaurantCurrencySymbol,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.validPriceVariation(
                                                      $event,
                                                      index,
                                                      "price"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: discount.price,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      discount,
                                                      "price",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "discount.price",
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-site__field__delete",
                                            },
                                            [
                                              _c("bin-btn", {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removePriceVariation(
                                                      index
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _c("div", { staticClass: "line-more" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "line-more__content",
                                        on: { click: _vm.addDiscounts },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "line-more__icon" },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                name: "ic-plus",
                                                width: "12",
                                                height: "12",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("div", {
                                          staticClass: "line-more__text",
                                          domProps: {
                                            textContent: _vm._s(_vm.$t("Add")),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ],
                                2
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "tab1",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "header-back back d-flex d-md-none",
                          style: { "margin-right": "-25px", width: "103vw" },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "header__back",
                              on: { click: _vm.close },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "header-company" },
                                [
                                  _c("svg-icon", {
                                    staticClass: "ic-white",
                                    attrs: {
                                      name: "ic-arrow-left",
                                      width: "16",
                                      height: "12",
                                    },
                                  }),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("Back")),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("availability-tab", {
                        attrs: {
                          id: !!_vm.areaInEdit.id,
                          availability: _vm.areaInEdit.availability,
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "tab2",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "get-backup" }, [
                        _vm.new_backup_branch == false
                          ? _c(
                              "div",
                              [
                                _vm.branchesBackupMe.length > 0
                                  ? _c(
                                      "table-list",
                                      _vm._b(
                                        {
                                          on: {
                                            changeOptions: _vm.changeOptions,
                                            "on-click-line": _vm.openPopup2,
                                          },
                                        },
                                        "table-list",
                                        _vm.dataTable,
                                        false
                                      )
                                    )
                                  : _vm._e(),
                                !_vm.mobile
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "form-site__btn md-none",
                                        style: {
                                          display: "flex",
                                          width: "100%",
                                          "justify-content": "center",
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "line-left" }),
                                        _c(
                                          "div",
                                          { staticClass: "line-more" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "line-more__content",
                                                on: { click: _vm.newBackUp },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "line-more__icon",
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      attrs: {
                                                        name: "ic-plus",
                                                        width: "12",
                                                        height: "12",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c("div", {
                                                  staticClass:
                                                    "line-more__text",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        "create backup branches"
                                                      )
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c("div", { staticClass: "line-right" }),
                                _vm.mobile
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "form-site__btn",
                                        style: {
                                          display: "flex",
                                          width: "100%",
                                          "justify-content": "center",
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "line-left" }),
                                        _c(
                                          "div",
                                          { staticClass: "line-more" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "line-more__content",
                                                on: { click: _vm.newBackUp },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "line-more__icon",
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      attrs: {
                                                        name: "ic-plus",
                                                        width: "12",
                                                        height: "12",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c("div", {
                                                  staticClass:
                                                    "line-more__text",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        "create backup branches"
                                                      )
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c("div", {
                                          staticClass: "line-right",
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "header-back back d-flex d-md-none",
                            style: { "margin-right": "-25px", width: "103vw" },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "header__back",
                                on: { click: _vm.close },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "header-company" },
                                  [
                                    _c("svg-icon", {
                                      staticClass: "ic-white",
                                      attrs: {
                                        name: "ic-arrow-left",
                                        width: "16",
                                        height: "12",
                                      },
                                    }),
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(_vm.$t("Back")),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "btns",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "client-detail__bottom bet d-md-flex d-none",
                        },
                        [
                          _vm.areaInEdit.id
                            ? _c("remove-btn", {
                                staticStyle: { margin: "unset" },
                                on: { click: _vm.remove },
                              })
                            : _c("div", {}),
                          _c("button", {
                            staticClass: "btn",
                            attrs: { disabled: !_vm.permissionEdit },
                            domProps: { textContent: _vm._s(_vm.$t("save")) },
                            on: { click: _vm.save },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "new-item__btn d-flex d-md-none" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "save-btn",
                              on: { click: _vm.save },
                            },
                            [
                              _c("svg-icon", {
                                staticClass: "ic-edit",
                                attrs: {
                                  name: "ic-save-btn",
                                  width: "93",
                                  height: "93",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "bin-btn",
                              on: { click: _vm.remove },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-bin",
                                  width: "18",
                                  height: "20",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              993394885
            ),
          })
        : _vm._e(),
      _vm.new_backup_branch === true
        ? _c("create-new-backup-branch", {
            attrs: { areaInEdit: _vm.areaInEdit },
            on: { close: _vm.closeLoadAfterCreatingNewBackup },
          })
        : _vm._e(),
      _vm.popup2 === 1
        ? _c("edit-backuped-branch", {
            attrs: { branch: _vm.branchInEdit },
            on: {
              cancel: _vm.closeLoad,
              close: _vm.closeEditedBackupBranch,
              "close-and-load": _vm.closeLoad,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }