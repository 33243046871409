var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("checkbox", {
    staticClass: "checkbox-in-table",
    attrs: { name: "checkbox", value: _vm.value },
    on: {
      click: function ($event) {
        $event.stopPropagation()
      },
      input: _vm.input,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }