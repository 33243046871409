var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "order-category-items" }, [
    _c("div", { staticClass: "coupon-buy-drop", class: { open: _vm.isOpen } }, [
      _c("div", { staticClass: "coupon-buy-drop__head" }, [
        _c(
          "div",
          {
            staticClass: "input-check flex",
            on: {
              click: function ($event) {
                _vm.isOpen = !_vm.isOpen
              },
            },
          },
          [
            _c("div", { staticClass: "input-check__title" }, [
              _vm.icon
                ? _c("div", {
                    staticClass: "input-check__img icon",
                    style: "background-image: url(" + _vm.icon + ")",
                  })
                : _vm._e(),
              _c("div", { staticClass: "input-check__text" }, [
                _c("div", {
                  staticClass: "title-category",
                  domProps: {
                    textContent: _vm._s(
                      _vm.he ||
                        !_vm.category.name_en ||
                        _vm.category.name_en === "undefined"
                        ? _vm.category.name
                        : _vm.category.name_en
                    ),
                  },
                }),
                _c("div", {
                  staticClass: "selected",
                  domProps: {
                    textContent: _vm._s(
                      _vm.count +
                        "/" +
                        _vm.category.menu_item_ids.length +
                        " " +
                        _vm.$t("selected")
                    ),
                  },
                }),
              ]),
            ]),
          ]
        ),
        !_vm.categoryonly
          ? _c(
              "button",
              {
                staticClass: "coupon-buy-drop__arr",
                on: {
                  click: function ($event) {
                    _vm.isOpen = !_vm.isOpen
                  },
                },
              },
              [
                _c("svg-icon", {
                  attrs: { name: "ic-arrow-down", width: "14", height: "10" },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      !_vm.categoryonly
        ? _c("div", { staticClass: "coupon-buy-drop__content" }, [
            _c(
              "div",
              { staticClass: "coupon-buy-drop__list" },
              _vm._l(_vm.category.menu_item_ids, function (itemId) {
                return _c("order-item", {
                  key: itemId,
                  attrs: {
                    item: this.$store.getters["catalogEditor/getItemById"](
                      itemId
                    ),
                  },
                  on: { input: _vm.setValItem },
                })
              }),
              1
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }