var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "kds-names" }, [
    _c("div", { staticClass: "form-site" }, [
      _c("div", {
        staticClass: "kds-names__title",
        domProps: { textContent: _vm._s(_vm.$t("Name translate")) },
      }),
      _c(
        "div",
        { staticClass: "kds-names__inputs" },
        _vm._l(_vm.enabledLanguages, function (lang, langCode) {
          return _c("div", { key: langCode, staticClass: "kds-names__input" }, [
            _c("label", { attrs: { for: "kds-names__input-" + langCode } }, [
              _vm._v(_vm._s(lang)),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.valueModel[langCode],
                  expression: "valueModel[langCode]",
                },
              ],
              attrs: { id: "kds-names__input-" + langCode, type: "text" },
              domProps: { value: _vm.valueModel[langCode] },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.valueModel, langCode, $event.target.value)
                },
              },
            }),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }