var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      staticClass: "catalog-editor-menu-dialog-working-days",
      attrs: { width: "676px" },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("h1", {
        domProps: { textContent: _vm._s(_vm.$t("Specific days and times")) },
      }),
      _c("div", { staticClass: "popup__content" }, [
        _vm.futureOrderSettings
          ? _c("div", { staticClass: "popup__content" }, [
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c("div", { staticClass: "working-days" }, [
                    _c("div", { staticClass: "working-days__all" }, [
                      _c("div", { staticClass: "input-check" }, [
                        _c(
                          "label",
                          { staticClass: "input-check__label" },
                          [
                            _c("checkbox", {
                              attrs: { name: "always-available" },
                              model: {
                                value:
                                  _vm.futureOrderSettings.times
                                    .always_available,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.futureOrderSettings.times,
                                    "always_available",
                                    $$v
                                  )
                                },
                                expression:
                                  "futureOrderSettings.times.always_available",
                              },
                            }),
                            _c("span", {
                              staticClass: "input-check__text",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("__Always available")
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "working-days__list" },
                      _vm._l(
                        [
                          "sunday",
                          "monday",
                          "tuesday",
                          "wednesday",
                          "thursday",
                          "friday",
                          "saturday",
                        ],
                        function (day, dayIndex) {
                          return _c("day-working-hours", {
                            key: dayIndex,
                            attrs: {
                              always:
                                _vm.futureOrderSettings.times.always_available,
                              props: _vm.futureOrderSettings.times[day],
                            },
                            on: { save: _vm.saveDay },
                          })
                        }
                      ),
                      1
                    ),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
        _c("div", { staticClass: "popup__btn" }, [
          _c("button", {
            staticClass: "btn",
            domProps: { textContent: _vm._s(_vm.$t("Save")) },
            on: { click: _vm.save },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }